import { InputBase, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { allNullTypeCheck, isNullResponse } from '../../Commissions/commonComponents/UtilityFun';
import './usermanagementdashboard.css';
import UserEdit from '../UserEdit/UserEdit';
import { makeStyles } from "@material-ui/core/styles";
import vectorsearch from '../../Commissions/assets/vector-search.svg';                      // /assets/vector-search.svg';
import closesvg from '../../Commissions/assets/closeicon-small.svg';
import leftarrow from "../../Commissions/assets/leftarrow.svg";
import rightarrow from "../../Commissions/assets/rightarrow.svg";
import * as Constants from '../../../constants/Constants'
import greendot from '../../../assets/green-dot-status.svg';
import yellowdot from '../../../assets/yellow-dot-status.svg';
import graydot from '../../../assets/gray-dot-status.svg';
import mailIcon from '../../../assets/letter-icon.svg';
import editIton from '../../Commissions/assets/editIcon.svg';
import InviteNewUsers from '../InviteUsers/InviteNewUsers';
import ErrorBoundary from '../ErrorBoundary';
import { useDispatch, useSelector } from 'react-redux';
import { getApplicationUserList } from '../actions';
import { Oval } from 'react-loader-spinner';
import moment from 'moment';
import NewSnackbar from '../../utilities/NewSnackbar';

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(0.5)
    }
  },
  tabs: {

    "& .MuiTabs-indicator": {
      backgroundColor: "orange",
      height: 3,
    },
    "& .MuiTab-root.Mui-selected": {
      color: 'red'
    }
  },
  search: {
    position: 'relative',
    backgroundColor: "#FFFFFF",
    width: "175px",
    height: "29px",
    justifyContent:"space-between",
    borderRadius:'4px',
    // [theme.breakpoints.up("sm")]: {
    //   marginLeft: theme.spacing(3)
    //   // width: 'auto',
    // }
  },
  searchIcon: {
    // padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    paddingLeft:'6px',
    paddingTop:'5px',
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#8782D9"
  },
  searchIcon1: {
    // padding: theme.spacing(0, 2),
    height: "100%",
    // position: "absolute",
    float:'right',
    paddingRight:'4px',
    paddingTop:'4px',
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#8782D9"
  },
  inputRoot: {
    color: "inherit",
    position:'absolute',
    width:'175px',
    border:'1px solid #DDDDDD',
    borderRadius:'4px',
  },
  inputInput: {
    // paddingLeft:'26px',
    // paddingTop:'5px',
    // paddingRight:'5px',
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon,
    // paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch"
    }
  }
}));

export default function UserManagementDashBoard() {
     const [value, setValue] = useState(0);
    const [loader, setLoader] = useState(true);
    const [userListResponse, setUserListResponse] = useState([])
    const [userListTemp, setUserListTemp] = useState([]);
    const [selectedUserList, setSelectedUserList] = useState([])
    const classes = useStyles();
    const [numberOfPages, setNumberOfPages] = useState([]);
    const [pages, setPages] = useState([]);
    const [selectedItem, setSelectedItem] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [enableEdit, setStateEnableEdit] = useState(localStorage.getItem("edituser")==='true'? true:false);
    const [showPopUp, setShowPopUp] = useState(false)
    const [userInfo, setUserInfo] = useState({})
    const applicationUsersList = useSelector(state=>state.userDashboardReducer.applicationUserRes)
    const applicationUsersError = useSelector(state=>state.userDashboardReducer.applicationUserError)
    const dispatch = useDispatch();
    
    console.log("user list is : ",applicationUsersList,enableEdit, localStorage.getItem("edituser"));
    useEffect(()=>{
      dispatch(getApplicationUserList())
      if(localStorage.getItem("edituser")==='true'&& allNullTypeCheck(localStorage.getItem("selecteduserid"))){
        setUserInfo(localStorage.getItem("selecteduserid"));
      } 
    },[])
   
    useEffect(() => {
      // if(!distWorkFieldData.hasOwnProperty('status')){ 
      if (isNullResponse(applicationUsersList)){
          setUserListTemp(applicationUsersList.data?.users);
          setSelectedUserList(applicationUsersList.data?.users);
           setLoader(false);
          if (applicationUsersList?.data?.users.length > 0) {
            const numberOfPages = Math.ceil(
              applicationUsersList?.data?.users.length / 2  
            );
            setUserListResponse(applicationUsersList?.data?.users.slice(0, 2));
            setPages([]);
            for (let index = 1; index <= numberOfPages; index++) {
              var obj = {};
              obj["value"] = index.toString();
              obj["label"] = index.toString();
              pages.push(obj);
            }
            setNumberOfPages(pages);
            setSelectedItem(1);
            console.log("number of pages :", numberOfPages, pages);
          }
        }else{
           if(applicationUsersError){
            setLoader(false)
           }
        }

      }
    , [applicationUsersList]);

    useEffect(() => {
      setSelectedItem(1);
      console.log("result is ", userListResponse);
    }, [pages]);
    
    useEffect(() => {
      const start = selectedItem * 2 - 2;
      const end = selectedItem * 2;
      setUserListResponse(
        selectedUserList.slice(start, end));
    }, [selectedItem]);

    useEffect(()=>{
      if (selectedUserList?.length > 0) {
        const numberOfPages = Math.ceil(
          selectedUserList?.length / 2  
        );
        setUserListResponse(selectedUserList.slice(0, 2));
        setPages([]);
        for (let index = 1; index <= numberOfPages; index++) {
          var obj = {};
          obj["value"] = index.toString();
          obj["label"] = index.toString();
          pages.push(obj);
        }
        setNumberOfPages(pages);
        setSelectedItem(1);
      }else {
        setUserListResponse([]);
      }
    },[selectedUserList])
    const handleChange = (event, newValue) => {
      setValue(newValue);
      switch (newValue) {
        case 0:
          setSelectedUserList(userListTemp.filter(data => data.name.toLowerCase().includes(searchValue.toLowerCase())));
          break;
        case 1:
          setSelectedUserList(userListTemp.filter(data => (data.status.toLowerCase()===('Active'.toLowerCase()) && data.name.toLowerCase().includes(searchValue.toLowerCase()))));
          break;
        case 2:
          setSelectedUserList(userListTemp.filter(data => (data.status.toLowerCase().includes('Deactivated'.toLowerCase()) && data.name.toLowerCase().includes(searchValue.toLowerCase()))));
          break;
        case 3:
          setSelectedUserList(userListTemp.filter(data => (data.status.toLowerCase().includes('Pending to Accept'.toLowerCase()) && data.name.toLowerCase().includes(searchValue.toLowerCase()))));
          break;
        default: setSelectedUserList(userListTemp.filter(data => data.name.toLowerCase().includes(searchValue.toLowerCase())));
          break;
      }
    };

  const inviteUserAction=()=>{
    setShowPopUp(true)
  }
  const handleNumberChange = (e) => {
    console.log("selected values: ", e.target.value);
    setSelectedItem(e.target.value);
  };
  const handledUserSearch=(e)=>{
    console.log("selected name : ",e.target.value);
    setSearchValue(e.target.value);
    switch (value) {
        case 0:
          setSelectedUserList(userListTemp.filter(data => data.name.toLowerCase().includes(e.target.value.toLowerCase())));
          break;
        case 1:
          setSelectedUserList(userListTemp.filter(data => (data.status.toLowerCase()===('Activated'.toLowerCase())&&(data.name.toLowerCase().includes(e.target.value.toLowerCase())))));
          break;
        case 2:
          setSelectedUserList(userListTemp.filter(data => (data.status.toLowerCase().includes('Deactivated'.toLowerCase())&&(data.name.toLowerCase().includes(e.target.value.toLowerCase())))));
          break;
        case 3:
          setSelectedUserList(userListTemp.filter(data => (data.status.toLowerCase().includes('Pending to Accept'.toLowerCase())&&(data.name.toLowerCase().includes(e.target.value.toLowerCase())))));
          break;
        default: setSelectedUserList(userListTemp.filter(data => data.name.toLowerCase().includes(e.target.value.toLowerCase())));
          break;
      }
  }
  const handleLeftArrow=()=>{
    if(selectedItem>1){
      setSelectedItem(parseInt(selectedItem)-1);
    }
    console.log("selected value : ",selectedItem);
  }
  const handleRightArrow=()=>{
    console.log("selected vlaue and number of pages :",numberOfPages.length,selectedItem);
    if(selectedItem<numberOfPages.length){
      setSelectedItem(parseInt(selectedItem)+1);
    }
    console.log("selected value : ",selectedItem);
  }
  const getDotStatus=(key)=>{
    switch (key) {
      case Constants.USER_STATUS_ACTIVATED:
            return <img className="user-img-status" src={greendot} alt="reports" />
      case Constants.USER_STATUS_PENDING:
            return <img className="user-img-status" src={yellowdot} alt="reports" />
      case Constants.USER_STATUS_DEACTIVATED:
            return <img className="user-img-status" src={graydot} alt="reports" />  
      default:
            return ''
    }
  }
  const handleAction=(val)=>{
    localStorage.setItem("edituser",val);
    setStateEnableEdit(val);
  }
  const handleEditUser=(data)=>{
   setUserInfo(data)
   localStorage.setItem("selecteduserid",data);
   !enableEdit? handleAction(true): handleAction(false)
  }
  if (loader) {
      return (
        <div>
          <div id="center" style={{ position: "fixed", bottom: "40%", left: "48%" }}>
            <Oval
              type="Puff"
              position="fixed"
              secondaryColor="#E85557"
              color="#E85557"
              height={50}
              width={100}
              visible={true}
            />{" "}
          </div>
        </div>
      );
  } 
  else if (enableEdit === false) { 
  return (
    <ErrorBoundary>
    <div className='user-management-dashboard'>
        <div className='user-lato-style user-fs24-we700-lh29'>User Management</div>
        <div className="user-lato-style user-fsize14-weight400-lh20 menu-align-center">
            <div>
              <Tabs
                className="user-dis-style"
                value={value}
                // indicatorColor="secondary"
                TabIndicatorProps={{
                  style: { background: "#D93030", height: "2px"}
                }}
                select="true"
                textColor="primary"
                onChange={handleChange}
                aria-label="disabled tabs example"
              >
                <Tab className="user-lato-style user-fs14-we400-lh20" label="All"/>
                <Tab className="user-lato-style user-fs14-we400-lh20" label="Activated" />
                <Tab className="user-lato-style user-fs14-we400-lh20" label="Deactivated" />
                <Tab className="user-lato-style user-fs14-we400-lh20" label="Pending" />
              </Tabs>
              </div>
              <div className='user-header-style'>
                 <div >
                <div className={'user-search-input '+classes.search} style={{ float: "left" }}>
                    <div className={classes.searchIcon}>
                      <img className="report-search-icon" src={vectorsearch} alt="reports" />
                    </div>
                    <InputBase 
                      placeholder="Search here..."
                      className='user-input-search'
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      inputProps={{ "aria-label": "search", "className": "user-search-input-style"}}
                      onChange={handledUserSearch}
                    />
                    <div hidden={true} className={classes.searchIcon1}>
                    <img src={closesvg} alt="reports" />
                    </div>
                  </div> 
                 </div>
                 <div>
                  <div className="createrun"
                        onClick={inviteUserAction} >
                        <div className="createtitile">Invite New User</div>
                  </div>
                 </div>
              </div>
              
        </div>
        <div className="user-table-container">
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableCell
                    className="user-lato-style user-table-header"
                    component="th"
                    scope="row"
                    aria-sort='true'
                  >
                    {"Name"}
                  </TableCell>
                  <TableCell
                    className="user-lato-style user-table-header"
                    component="th"
                    scope="row"
                  >
                    {"Role"}
                  </TableCell>
                  <TableCell
                    className="user-lato-style user-table-header"
                    component="th"
                    scope="row"
                  >
                    {"Status"}
                  </TableCell>
                  <TableCell
                    className="user-lato-style user-table-header"
                    component="th"
                    scope="row"
                  >
                  </TableCell>
                </TableHead>
                <TableBody className='user-table-body'>
                  {isNullResponse(userListResponse) &&
                  userListResponse?.length > 0
                    ? userListResponse?.map((data, i) => {
                        return (
                          <TableRow key={i}>
                            <TableCell className='user-lato-style user-fs14-we400-lh20 user-data-height'>{data?.name}</TableCell>
                            <TableCell className='user-lato-style user-fs14-we400-lh20 user-data-height'>
                            {data?.roles?.length>0? data?.roles?.map((role,i)=>(i<data?.roles?.length-1)? role.name+', ': role.name):''}
                            </TableCell>
                            <TableCell className='user-lato-style user-fs14-we400-lh20 user-data-height'>
                              {getDotStatus(data?.status)}
                              {data?.status}</TableCell>
                            <TableCell className='user-lato-style user-fs14-we400-lh20 user-data-height'>
                            {data?.status=== Constants.USER_STATUS_ACTIVATED?
                             <div className='user-cell-display-style'>
                                <div style={{display:'flex',alignItem:'center'}}>{'last login on '+moment(data?.lastLogin).format('DD/MM/YYYY')}</div>
                                <div><img style={{paddingRight:'20px',cursor:'pointer'}} src={editIton} alt='img' onClick={()=>handleEditUser(data.id)}/></div>
                             </div>
                             :
                             <div className='user-cell-display-style'>
                                <div>
                                  <div><img src={mailIcon} alt='img'/><label className='user-resend-invite-style' >Resend Invite</label></div>
                                  <div>{'Invitation sent on'+data?.lastlogin}</div>
                                </div>
                                <div><img style={{paddingRight:'20px',cursor:'pointer'}} src={editIton} alt='img' onClick={()=>handleEditUser(data.id)}/></div>
                              </div>}
                              </TableCell>
                          </TableRow>
                        );
                      })
                    : ""}
                </TableBody>
              </Table>
            </TableContainer>
        </div>
        <div className='user-list-pagination'>
          {userListResponse?.length > 0 && userListResponse?.length > 0? (
            <div className="workfield-select-option">
              <span className="user-jumpto-style">Jump to Page:</span>
              {console.log("number of pages state: ", numberOfPages)}
              <select
                name="country"
                className="workfield-selectdropdown"
                onChange={handleNumberChange}
                value={selectedItem}
              >
                {numberOfPages.map((e, key) => {
                  return (
                    <option key={key} value={e.value}>
                      {e.label}
                    </option>
                  );
                })}
              </select>
              <div>
                <img
                  src={leftarrow}
                  alt=""
                  onClick={handleLeftArrow}
                  style={{ marginRight: "5px", cursor: "pointer" }}
                />{" "}
                <span className="user-pagination-style">
                  {selectedItem + " OF " + numberOfPages.length}
                </span>
                <img
                  src={rightarrow}
                  alt=""
                  onClick={handleRightArrow}
                  style={{ marginRight: "5px", cursor: "pointer" }}
                />{" "}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        {showPopUp && (
        <InviteNewUsers
          setShowPopUp={setShowPopUp}
        />
      )}
    </div>
    <NewSnackbar/>
    </ErrorBoundary>
  
  )} else if (enableEdit === true ) {
    return (
    <UserEdit onBack= {handleEditUser} userId = {userInfo}/> )
  } 
  return ""
}

