import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";
import { allNullTypeCheck } from "../../commonComponents/UtilityFun";

function Numbers(props) {
  const { _label, _name, _value, _type, _defaultValue, onChange, ...rest } = props;
  console.log('label, name',_label, _name)
  if(allNullTypeCheck(_label) && allNullTypeCheck(_name)){
  return (
    <div className='add-currency-popup-row'>
    <div className='input-container'>
      <label htmlFor={_name}>{_label}</label>
      <Field type='text' id={_name} onChange={(e)=>props.onChange(e,_type,_name,_label)} name={_name} value={_value} defaultValue={allNullTypeCheck(_value)? _value:_defaultValue} {...rest} />
      <ErrorMessage name={_name} component={TextError} />
    </div>
    </div>
  );
}
else return null
}

export default React.memo(Numbers);
