export const PRODUCTSSERVICES = 'PRODUCTS & SERVICES';
export const CUSTOMERS = 'CUSTOMERS';
export const PARTNERS = 'PARTNERS';
export const INSIGHTS = 'INSIGHTS';
export const COMPANY = 'COMPANY';
export const REQUEST_DEMO = 'REQUEST DEMO';
export const RESOURCES = 'Resources';
export const DEVELOPERS = 'Developers';
export const FORGOT_MAIL_SENT ='An email has been sent to this address. Please follow the instructions to reset your password.';
export const RESET_PASSWORD_MAIL='Please enter your email address. We will send you an email to reset your password.';
export const ACTIVE = 'active';
export const PENDING = 'pending';
export const DEACTIVATED = 'deactivated';
export const ALL = 'All';
export const USER_ACTIVESTATUS = 'Active';
export const USER_INACTIVESTATUS = 'Inactive';
export const GET = 'get';
export const POST = 'post';
export const PUT = 'put';
export const ENDPOINT_POST = 'endpoint_post';
export const VERBIAGE = 'An email has been sent to the collaborator so they can set up their login. You can manage their permissions now.';
export const RESETVERBIAGE = 'An email has been sent to this address. Please follow the instructions to reset your password.';
export const NAME_VALIDATION = 'Please enter name.';
export const EMAIL_VALIDATION = 'Please enter email.';
export const EMAILCHECK_VALIDATION = 'Please enter valid email.';
export const USERNAME_VALIDATION = 'Please enter user name.';
export const SENDINVITE_BUTTON = 'SEND INVITE';
export const CANCEL_BUTTON = 'CANCEL';
export const APPNAME_VALIDATION = 'App Name  already exist.';
export const CLIENT_DOMAIN_VALIDATION = 'Please enter valid client domain.';
export const DUBLICATE_INVITEALERT = 'Email address already exists. Please invite with a new email address.';
export const TRUE_STRING='true';
export const USER = 'user';
export const ADMIN = 'admin';
export const DEV = 'dev';
export const TEXT_ADMIN = 'Admin';
export const TEXT_USER = 'User';
export const TEXT_DEVELOPER = 'Developer';
export const INTEGRATION_ALL = 'All';
export const INTEGRATION_RESTRICTED = 'Restricted';
export const USER_ALL = 'All users';
export const USER_RESTRICTED = 'Restricted';
export const INTEGRATIONACCESS_VALIDATION = 'Please select at least one integration.';
export const UPDATE_MESSAGE = 'User updated successfully.';
export const ALERT_UPDATE_MESSAGE = 'User updated successfully.';
export const INTEGRATION_TYPE_ALERT = 'Please select at least one integration type.';
export const EVENTTYPE_VALIDATION = 'Please select at least one event type.';
export const INTEGRATION_INACTIVE = 'Inactive';
export const INTEGRATION_ACTIVE = 'Active';
export const FORGOT_INVALID_MAIL ='Please enter valid registered email ID.';
export const INTEGRATIONSTATUS_DISABLED = 'Disable';
export const INTEGRATIONSTATUS_ENABLED = 'Enable';
export const RED_COLOR = '#FFF6F6';
export const GRAY = '#FCFFF5';
export const BRANDING_TITLE = 'Flexcloud Settings';
export const BRANDING_SUCCESS = 'Branding Files Uploaded Successfully';
export const BRANDING_ERROR = 'Branding Files Uploaded Error:- ';
export const BRANDING_DETAILS_ERROR = 'Branding Files Uploaded Error:- ';
export const CLIENT_NAME = 'name';
export const BASEURL = 'baseURL';
export const COMPANY_LOGO = 'companyLogo';
export const SIGN_CERTIFICATE = 'signCertificate';
export const CUSTOM_CSS = 'customCSS';
export const CLIENTNAME = 'Client Name';
export const CLIENTNAME_ERROR = 'Client Name not entered.';
export const BASE_URL = 'Base URL';
export const BASEURL_ERROR = 'Base URL not entered.';
export const CLEAR = 'CLEAR';
export const UPLOAD = 'UPLOAD';
export const SAVE_CHANGES = 'SAVE CHANGES';
export const LOGO_SIZE = 'For optimal results, logo dimensions should be --x--';
export const COMPANYLOGO = 'Company Logo';
export const INVALID_FILETYPE = 'Invalid file type.';
export const NOFILE_SELECTED = 'No file selected.';
export const SIGNCERTIFICATE = 'Signing Certificate';
export const CUSTOMCSS = 'Custom CSS File';
export const SAVE = 'SAVE';
export const INTEGRATION_CREATED_SUCCESS = 'Integration created successfully.';
export const INTEGRATION_STATUS_ALERT = 'is already disabled.';
export const RENEWURL_VALIDATION = 'Please enter renew url.';
export const POSTLOGOUTURL_VALIDATION = 'Please enter post logout url.';
export const EMPTY_APPS = 'No integrations found.';
export const ALERT_RESENT_INVITE = 'Invitation re-sent successfully.';
export const ALERT_PROFILE_UPDATE = 'Profile updated successfully.';
export const ALERT_INTEGRATION_DESABLE = 'Status updated successfully.';
export const ALERT_USER_DESABLED = 'User deactivated successfully.';
export const VALIDATION_INTEGRATION_NAME = 'Please enter integration name.';
export const VALIDATION_DESCRIPTION_NAME = 'Please enter description.';
export const VALIDATION_INTEGRATION_NAME1 = 'Integration name should not contain your email address.';
export const VALIDATION_INTEGRATION_NAME_VALID = 'Integration name already exists.';
export const VALIDATION_INTEGRATION_NAME_SPACE = 'Integration name cannot include spaces.'; 
export const VALIDATION_ENTER_VALID_ENDPOINT = 'Please enter valid endpoint.'; 
export const VALIDATE_ENDPOINT = 'Please validate the endpoint.'
export const VALIDATION_ENDPOINT_SUCCESS = 'Verification successful.'; 
export const VALIDATION_ENTER_VALID_CALLBACKURL = 'Please enter valid call back url.'; 
export const VALIDATION_ENTER_ENDPOINT = 'Please enter endpoint.'; 
export const VALIDATION_ENTER_CALLBACKURL = 'Please enter call back url.'; 
export const VALIDATION_ENTER_VALID_URL = 'Please enter valid url.'; 
export const VALIDATION_CALLBACKURL = 'Call-Back-Url verification failed. Please check the URL and try again.';
export const VALIDATION_ENDPOINT_URL = 'Endpoint verification failed. Please check the URL and try again.';
export const VALIDATION_INTEGRATION_LENGTH = 'Max. of 30 characters allowed.';
export const VALIDATION_INTEGRATION_MIN_LENGTH = 'Integration name should contain at least 8 characters.';
export const VALIDATION_DESCRIPTION_LENGTH = 'Max. of 200 characters allowed.';
export const TEXT_DESCRIPTION_VALIDATION ='Please enter description.';

export const POPUP_MESSAGE_DISABLE_USER =  'Are you sure you want to disable - ';
export const POPUP_MESSAGE_DISABLE_INTEGRATION = 'Are you sure you want to delete this integration?';
export const POPUP_MESSAGE_INTEGRATION = 'It will not be recoverable and will erase any associated webhooks.'

export const TEXT_FLEXCLOUD_WELCOME_MESSAGE = 'Welcome to the FlexCloud platform!';
export const TEXT_CREATE_FIRST_INTEGRATION =  'Get started by creating your first integration.';
export const TEXT_POWERED_BY = 'Powered by';
export const TEXT_PORTAL_HOMEPAGE_HEADING = 'Let\'s get started. What would you like to do today?';
export const TEXT_INTEGRATION_CARD_DESCRIPTION = 'Create your own integrations. Gain access to our RESTful API’s, Webhooks for backend notifications and access to use our SDK.';
export const TEXT_EXPLORE_SDK_CARD_DESCRIPTION = 'The SDK module API allows you to customize and display a qualification module on your website using the InfoTrax WebSDK.';
export const TEXT_MANAGE_ACCESS_CARD_DESCRIPTION = 'Control user access to the FlexCloud portal, invite new users and manage all permission.';
export const TEXT_RESET_MAIL_CONFIRMATION = 'If you don\'t receive this email within a few minutes, please check your email\'s spam and junk filters.';
export const TEXT_ENDPOINT = 'endpoint';
export const TEXT_LABLE_ENDPOINT = 'Endpoint'
export const TEXT_CALLBACK_ENDPOINT = 'callbackurl';
export const TEXT_HTTPS_ENDPOINT = 'https://';
export const TEXT_HTTP_ENDPOINT = 'http://';
export const TEXT_NEW_INTEGRATION_BUTTON = 'NEW INTEGRATION';
export const TEXT_INTEGRATIONS = 'Integrations';
export const TEXT_ACTIVE_INTEGRATIONS = 'Active Integrations';
export const TEXT_INACTIVE_INTEGRATIONS = 'Deactivated Integrations';
export const TEXT_INTEGRATION_EDIT_BACKBUTTON = 'BACK';
export const TEXT_INTEGRATION_EDIT = 'Edit';
export const TEXT_DELETE_INTEGRATION_BUTTON ='DELETE INTEGRATION';
export const TEXT_GENERAL = 'General';
export const TEXT_CONFIGURATION = 'Configuration';
export const TEXT_CONFIRM = 'Confirm';
export const TEXT_EMBEDDEDFEATURES = 'Embed Features';
export const TEXT_WEBHOOKS = 'Webhooks';
export const TEXT_USERAUTHENTICATION = 'user';
export const TEXT_SERVERAUTHENTICATION = 'server';
export const TEXT_NEW_INTEGRATION_FIRSTHALF = 'Your new integration, ';
export const TEXT_NEW_INTEGRATION_SECONDHALF = ' has been created!';
export const TEXT_NEW_INTEGRATION_SECOND_HEADDING = 'You can use these credentials to access your integration:';
export const TEXT_NEW_INTEGRATION_EMBEDDED_INFO= 'From here, you can add optional items like Embedded Features or Webhooks.';
export const TEXT_NEW_INTEGRATION_FEATURES = 'Or you can stop here. You can always add these features later on.';
export const TEXT_UNDER_CONSTRUCTION = 'This page is under construction.';
// export const TEXT_EMAIL_REX = '^(([^<>()[]\\.,;:s@]+(.[^<>()[]\\.,;:s@]+)*)|(.+))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))';
export const TEXT_EMAIL_REX = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$"
export const TEXT_VALID_URL_FORMAT = '^(?:(?:https?|ftp)://)?(?:(?!(?:10|127)(?:.d{1,3}){3})(?!(?:169.254|192.168)(?:.d{1,3}){2})(?!172.(?:1[6-9]|2d|3[0-1])(?:.d{1,3}){2})(?:[1-9]d?|1dd|2[01]d|22[0-3])(?:.(?:1?d{1,2}|2[0-4]d|25[0-5])){2}(?:.(?:[1-9]d?|1dd|2[0-4]d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:.(?:[a-z\u00a1-\uffff]{2,})))(?::d{2,5})?(?:/S*)?';
export const TEXT_EMBEDDED_DESCRIPTION = 'Here you can select any optional features to include with your integration. Read more about these features';
export const TEXT_EMBEDDED_SUBHEADDING = 'Sample HTML code will be generated to the right.';
export const TEXT_EMBEDDED_OPTIONAL_FEATURES = 'Optional Features';
export const TEXT_EMBADDED_HEADTAG = 'Place this code within the <head /> tag of your HTML';
export const TEXT_EMBADDED_BODYTAG = 'Place this code within the <body /> of your HTML';
export const TEXT_EMBADDED = 'Embed Features';
export const TEXT_SELECTEDOPTION = '';
export const NEW_INTEGRATION_ALL_INTEGRATIONS_BUTTON = 'GO TO ALL INTEGRATIONS';
export const NEW_INTEGRATION_EMBEDDED_BUTTON = 'EMBEDDED FEATURES';
export const NEW_INTEGRATION_WEBHOOKS = 'WEBHOOKS';
export const NEW_INTEGRATION_COPY = 'copy';
export const NEW_INTEGRATION_AUTHORITY_URL = 'Client Authority URL';
export const NEW_INTEGRATION_DEV_TOKEN = 'Dev Token (Coming soon)';
export const NEW_INTEGRATION_DEVTOKEN_INFO = 'This is a temporary token for developer testing only. Token will expire in 4 hours.';
export const NEW_INTEGRATION_ID = 'Integration ID';
export const NEW_INTEGRATION_SECRET = 'Integration Secret';
export const PARAM_INTEGRATION_SECRET= 'integrationSecret'
export const PARAM_AUTHENTICATION= 'authentication'
export const ALERT_TECHNICAL_ERROR = 'Something went wrong, Please contact site administrator.';
export const INTEGRATION_NAME = 'Integration Name';
export const INTEGRATION_NAME_VALIDATION = 'Integration name is Available';
export const INTEGRATION_NAME_SPACE = 'Integration name cannot include spaces.';
export const INTEGRATION_DESCRIPTION = 'Description';
export const INTEGRATION_COLLABORATORS = 'Collaborators';
export const INTEGRATION_COLLABORATORS_PERMISSION = 'Give access to  individuals who can help manage this integration.';
export const NEXT_BUTTON = 'NEXT';
export const CONFIG_USER_AUTHENTICATION = 'User Authentication - “Coming soon”';
export const DEVTOKEN_COMINGSOON =' - “Coming soon”'
export const CONFIG_SERVER_AUTHENTICATION = 'Server Authentication';
export const AUTHENTICATION = 'Authentication (Oauth 2.0)';
export const CONFIG_CALLBACK_URL = 'Callback URL';
export const CONFIRM_AUTHENTICATION = 'Authentication';
export const CREATE_INTEGRATION_BUTTON = 'CREATE INTEGRATION';
export const HEADER_TAG = '<head><script /crossorigin="anonymous" src="https://unpkg.com/react@16/umd/react.development.js"></script><script crossorigin="anonymous" src="https://unpkg.com/react-dom@16/umd/react-dom.development.js"></script><script src="node_modules/@infotraxsdk/frontend_sdk_client_secret/dist/bundle.js"></script>"</head>';            
export const VARIABLE_TEXT_DATA_CLIENTSECRET = 'data-clientsecret="';
export const VARIABLE_TEXT_DATA_CLIENTID = 'data-clientid="';
export const VARIABLE_TEXT_DATA_CLIENTBASEURL = 'data-clientbaseurl="';
export const VARIABLE_TEXT_DATA_CLIENTAUTHORITY = 'data-clientauthority="';
export const VARIABLE_TEXT_DATA_CLIENTSCOPE = 'data-clientscope="/client/api"';
export const EMPTY_SPACE = '  ';
export const VARIABLE_TEXT_DATA_DIST_ID = 'data-distid="156932"';
export const VARIABLE_TEXT_DATA_THEMECOLOR = 'data-themecolor="#D33636"';
export const VARIABLE_GREATERTHAN = '>';
export const VARIABLE_LESSTHAN = '<';
export const VARIABLE_CLOUSELESSTHAN = '</';
export const VARIABLE_NEXTLINE='\n\n';
export const EMBEDDEDFEATURE_BUTTON_FINISH = 'FINISH';
export const TEXT_COPYTOCLIPBOARD = 'Copied to clipboard!';
export const TEXT_WEBHOOKS_DESCRIPTION = 'Please note that some Webhooks can take up to 5 minutes to activate.';
export const TEXT_WEBHOOKS_HEADDING = 'Webhooks set up for ';
export const TEXT_NEW_WEBHOOK_BUTTON = 'NEW WEBHOOK';
export const TEXT_BACK = 'BACK';
export const TEXT_ACTIVE_WEBHOOKS = 'Active Webhooks';
export const TEXT_DEACTIVATED_WEBHOOKS = 'Deactivated Webhooks';
export const TEXT_WEBHOOKS_SETUP_DESCRIPTION = 'Here you can set up optional webhooks to work with your integration.';
export const TEXT_PRIMARYKEY = 'Primary Key';
export const TEXT_SECONDARYKEY = 'Secondary Key';
export const SECONDARYKEY_MESSAGE = 'When Should I use a secondary key?';
export const SECONDARYKEY_DELETE_MESSAGE = 'Click to delete secondary key';
export const TEXT_WEBHOOKNAME = 'Webhook Name';
export const TEXT_WEBHOOKDESCRIPTION = 'Webhook Description';
export const TEXT_EVENTS = 'Events';
export const REGENERATE_BUTTON = 'REGENERATE';
export const GENERATE_BUTTON = 'GENERATE';
export const VALIDATE_BUTTON = 'VALIDATE';
export const CREATE_BUTTON = 'CREATE';
export const DELETE_BUTTON = 'DELETE';
export const RESET_TOKEN_BUTTON = 'RESET TOKEN';
export const RESET_SECRET_BUTTON = 'RESET SECRET';
export const WEBHOOK_PLACEHOLDER = 'Enter Name';
export const DESCRIPTION_PLACEHOLDER = 'Description';
export const EVENTS_PLACEHOLDER = 'Select Events';
export const ENDPOINT_PLACEHOLDER = 'Enter Valid Endpoint';
export const TEXT_CREATE_WEBHOOK = 'Create Webhook';
export const VALIDATION_WEBHOOK_NAME = 'Please enter webhook name.';
export const VALIDATION_WEBHOOK_DESCRIPTION ='Please enter webhook description.';
export const VALIDATION_WEBHOOK_ENDPOINT = 'Please enter webhook endpoint.';
export const VALIDATION_WEBHOOK_NAME_SPACE = 'Webhook name cannot include spaces.'; 
export const EMAIL_UNIQUE_KEY_CONSTRAINT = 'Violation of UNIQUE KEY constraint';
export const EMAIL_GENERAL_APPLICATION_ERROR = 'General application error.';
export const TEXT_EVENTSLIST = 'Select Which Events to Link to this Webhook';
export const CLOSE_BUTTON = "CLOSE"
export const SELECT_EVENTS_BUTTON = "SELECT EVENTS";
export const SAVEANDCLOSE_BUTTON = "SAVE AND CLOSE";
export const DONE = "DONE";
export const CHANGES_SAVED = "Changes Saved!";
export const TEXT_CONFIGURED = 'Configured :';
export const TEXT_LEAVE_CONFIRM_MESSAGE = 'Are you sure you want to leave this page?';
export const TEXT_LEAVE_UNSAVED_CHANGES  = 'You have unsaved changes that will be lost if you leave.';
export const LEAVE_CHANGES_BUTTON = 'LEAVE';
export const TEXT_PROFILE = 'Profile';
export const TEXT_LOGOUT = 'Logout'
export const TEXT_SWITCH_ACCOUT = 'Switch'
export const PARAM_NAME = 'name';
export const PARAM_DESCRIPTION = 'description';
export const PARAM_DESCRIPTION1 = 'Description';
export const PARAM_CALLBACK ='callback';
export const PARAM_COLLABORATORS ='collaborators';
export const PARAM_INTEGRATIONID = 'integrationID';
export const PARAM_COLLABORATORID = 'collaboratorID';
export const PARAM_OPTIONALFEATUREIDS = 'optionalfeatureid';
export const PARAM_ENDPOINTURL = 'endpoint';
export const PARAM_EVENTTYPES = 'eventtypes';
export const PARAM_PRIMARYKEY = 'primarykey';
export const PARAM_SECONDARYKEY = 'secondarykey';
export const PARAM_SCOPE = 'scope';
export const PARAM_EXPIRY = 'expiry';
export const EMBEDDED_FEATURES_SUCCESS = 'Embedded features updated successfully.';
export const INPUTELEMENT = 'InputOption';
export const DIDUPDATEMETHOD = 'componentMethod';
export const SELECTED_FEATURE_ALERT = 'Feature is already selected.';
export const PRIMARYKEY_ACTION = 'primarykey';
export const SECONDARYKEY_ACTION = 'secondarykey';
export const SECONDARYKEY_REGENERATE_ACTION = 'regeneratekey';
export const RESETSECRET_ACTION = 'integrationsecret';
export const SECONDARYKEY_DELETE = 'secondarykeydelete';
export const PARAM_CHILDREN = 'children';
export const PARAM_TYPE = 'type';
export const PARAM_STATUS = 'status';
export const TEXT_NEW_WEBHOOK = 'NewWebhook';
export const TEXT_EDIT_WEBHOOK = 'EditWebhook';
export const TEXT_CREATE_CONSTANT = 'create';
export const TEXT_EDIT_CONSTANT = 'edit';
export const PARAM_WEBHOOKID = 'webhookId'
export const TEXT_WEBHOOK_STATUS = 'Webhook Status'
export const PARAM_ACTIVE = 'active'
export const TEXT_EDIT_WEBHOOKS = 'Edit Webhook'
export const TEXT_LABLE_SETTINGS = 'Settings';
export const TEXT_LABLE_USERACCESS = 'User Access';
export const TEXT_LABLE_INTEGRATIONS = 'Integrations';
export const TEXT_LABLE_HOME = 'Home';
export const TEXT_LABLE_TABLEAU ='Tableau';
export const TEXT_LABLE_LEGACYAPIACCESS = 'Legacy API Access';
export const TEXT_LABLE_APIDOCS = 'API Docs';
export const TEXT_LABLE_SDKS = 'SDKs';
export const TEXT_LABLE_NEWINTEGRATION = 'New Integration';
export const TEXT_LABLE_ALLINTEGRATIONS = 'All Integrations';
export const TEXT_LABLE_APPROVED_EMAIL_DOMAINS = 'Approved E-mail Domains';
export const TEXT_EMAIL_TYPE = 'E-mail Type';
export const BUTTON_SETTING_ADD = 'ADD';
export const BUTTON_SETTING_GOTOEDITOR = 'GO TO EDITOR';
export const TEXT_LABLE_EMAIL_MANAGER = 'E-mail Manager';
export const TEXT_USERNAME = 'Name';
export const TEXT_USEREMAIL = 'Email';
export const TEXT_USERLASTLOGIN = 'Last Login';
export const TEXT_USERROLE = 'Role';
export const TEXT_USERSTATUS = 'User Status';
export const CANCELCHANGES_BUTTON ='CANCEL CHANGES';
export const RESET_PASSWORD_BUTTON = 'Reset Password';
export const TEXT_PASSWORD = 'Password';
export const TEXT_PROFILE_IMAGE = 'Profile Image';
export const FORMTEXT_PROFILE_IMAGE = 'Recommend image size of 32 X 32 jpg or png format ';
export const USERACCESS_STATUS = 'Status';
export const INVITE_NEW_USER_BUTTON = 'INVITE NEW USER';
export const TEXT_FILTER_BY = 'FILTER BY';
export const TEXT_MANAGE_USER_ACCESS = 'Manage User Access';
export const CREATE_NEW_INTEGRATION_BUTTON = 'CREATE NEW INTEGRATION';
export const TEXT_BUTTON_EXPLORESDKS = 'EXPLORE SDKs';
export const TEXT_BUTTON_MANAGE_ACCESS = 'MANAGE ACCESS';
export const TEXT_BUTTON_UPLOADIMAGE = 'Upload Image ';
export const TEXT_HERE = 'here.';
export const TEXT_HEADER = 'Atomic Health Force';
export const TEXT_EMAIL_TEMPLATE_VALIDATION = 'Please select atleast one email type';
export const EMAIL_TEMP_UPDATE = 'Selected Email Template updated successfully.'
export const NOT_CHANGE_CONTENT = '* Do not change the content enclosed within @';
export const USERTYPE_VALIDATION = 'Please select the user type.'
export const POSTDATA_TOKEN = {
    grant_type: 'client_credentials',
    client_id: 'A5126EED-65B4-4AD7-BA5D-F8D48874B65A',
    client_secret: 'FD0E9EEA0AE88B5E3004D2706D1A79C9AE2F7BDB',
    scope: '/commissions/get /distributors/get',
    // username: 'admin1',
    // password: 'Secure*1234',
    response_type: 'id_token',
    token_type: 'dev'
  }
  export const JWT_ISS =  process.env.REACT_APP_IDENTITY_BASE_URL+'/connect/token'
  export const DEV_TOKEN_MESSAGE = 'This is a pre-built curl command along with a developer token.'
export const TEXT_SUMMARY = 'Summary'
export const TEXT_EDIT_INTEGRATION = 'Edit Integration'
export const TEXT_INTEGRATION_TITLE = 'My Integrations'
export const TEXT_INTEGRATION_SUBJECT = 'This page contains all the integrations that are available to you. You can also create new integrations here.'
export const TEXT_DESCRIPTION_PLACEHOLDER = 'This is a brief description for the integration. Max character limit is 200 characters.'
export const TEXT_DEVTOKEN_SCOPES = "/commissions/get /distributors/get"
export const TEXT_DEVTOKEN_EXPIRY = 14400
export const TEXT_CURL_DEVTOKEN_EXPIRY = 3600
export const TEXT_INTEGRATION_ENABLE = 'Integration enabled successfully.'
export const TEXT_INTEGRATION_DISABLED = 'Integration disabled successfully.'
export const TEXT_INVALID_ENDPOINT_URL= 'Invalid Endpoint URL.'
export const TEXT_VALIDURL_SUCCESS = 'URL Validated Successfully.'
export const TEXT_INTEGRATION_NAME_INVALID = 'Integration Name Validation is Missing.'
export const TEXT_AUTHTYPE_ALERT =  'Authentication Type is missing.'
export const TEXT_INTEGRATION_NAME_VALID = 'Please enter valid integration name.'
export const TEXT_INTEGRATION_NAME_MANDATORY = 'Integration name is mandatory to delete integration.'
export const TEXT_INTEGRATION_DELETE_MANDATORY =  'Integration name and acceptance are mandatory to delete integration.'
export const TEXT_INTEGRATION_ACCEPTANCE = 'Integration acceptance is mandatory to delete integration.'
export const TEXT_INTEGRATION_DETAILS_UPDATE = 'Integration details updated successfully.'
export const TEXT_DELETE_THIS_INTEGRATION = 'YES, DELETE INTEGRATION'
export const USER_STATUS_ACTIVATED = 'Active'
export const USER_STATUS_DEACTIVATED = 'Deactivated'
export const USER_STATUS_PENDING = 'Pending to Accept'

export const SNACKBAR_TYPE = {
  success: 'success',
  info: 'info',
  warning: 'warning',
  error: 'error',
}
export const CM_PROCESS = {
  name: 'name',
  currentRecord: 'currentRecord',
  totalRecords: 'totalRecords',
  status : 'status',
  value: 'value'
}


