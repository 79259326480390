import { Fragment, useEffect, useState } from 'react'
import { allNullTypeCheck, isNullResponse } from '../../commonComponents/UtilityFun'
import parse from 'html-react-parser'
import Endpoints from '../../../utilities/Endpoints'
import './Earnings.css'
import { Oval } from "react-loader-spinner";
import { useSelector, useDispatch } from 'react-redux';
import { getSelectedReportParameters, REPORT_HTML_PARAMS_UPDATE, REPORT_PARAMS } from '../actions'
import actions from '../../../../redux/actions/CommonDataActions'
import * as Constant from '../../../../constants/Constants'
import { getCleintNameEnv } from '../../utilities/services'

export function RunMainContainer({ heading, category, displayType, reportsPams}) {
  const [tableRows, settableRows] = useState([])
  const [loader, setloader] = useState(true);
  const updatedParams = useSelector(state => state.reportsMainReducer.updatedReportParams)
  const dispatch = useDispatch()
  useEffect(()=>{
    postReportDataRequest(updatedParams);
  },[updatedParams])

  useEffect(()=>{
    console.log("response is : ",reportsPams);
    postReportDataRequest(reportsPams);
  },[reportsPams])

  const postReportDataRequest=(payloadData)=>{
      console.log("params is : ",localStorage.getItem("heading"),localStorage.getItem("category"))
      if(isNullResponse(payloadData)){
        setloader(true);
      fetch(`${getCleintNameEnv()}reports/${encodeURI(localStorage.getItem("category"))}:${encodeURI(localStorage.getItem("heading"))}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payloadData),
      })
        .then((response) => response.text())
        .then((data) => {
          setloader(false);
          console.log("response is : ",data.includes("<"));
          if(typeof(data)==='string'&& data.includes('<')){
            settableRows(data)
          }else{
            console.log("error response : ",data,'---',typeof(data))
            if(typeof(data)==='string'){
              let resp = JSON.parse(data);
              dispatch({ type: actions.SNACKBAR_SHOW, payload: {snackbarOpen: true, snackbarType:  Constant.SNACKBAR_TYPE.error, snackbarMessage: (typeof(resp)!=='string'&& resp.hasOwnProperty('messages'))? resp.messages[0]:resp}})
            }
          }
        }).catch((e)=>{
          console.log("Getting service error: ", e);
          dispatch({ type: actions.SNACKBAR_SHOW, payload: {snackbarOpen: true, snackbarType:  Constant.SNACKBAR_TYPE.error, snackbarMessage: (typeof(e)!=='string'&&typeof(e)==='object'&& e.hasOwnProperty('message'))? e.message:e}})
        setloader(false);
        })
  }
  }

  window.runReport=(type,params)=>{
    console.log("Click happen",type,params);
    postReportDataRequest(params);
    dispatch({ type: REPORT_PARAMS, payload: [] })
    dispatch({type:REPORT_HTML_PARAMS_UPDATE,payload:params})
    // htmlParamsUpdate(params)
  }
  
  if (loader) {
    return <div><div id="center" style={{ position: 'fixed', bottom: '35%', left: '55%' }}>
      <Oval type="Puff" position="fixed" secondaryColor="#E85557" color="#E85557" height={50} width={50} visible={true} /> </div></div>;
  }
  else
    return (<Fragment>
      <div className='print' hidden={true} id='print' style={{ width: '100%', margin:'10px'}}>
      {parse(tableRows?.toString())}
    </div>
    {/* <root.div className='print' id='print' style={{ width: '100%', margin:'10px'}}>
      <style>
      <link href="https://fonts.googleapis.com/css2?family=fantasy&display=swap" rel="stylesheet"/>
      <link href="https://fonts.googleapis.com/icon?family=Material+Icons"  rel="stylesheet"></link>
      </style>
      {parse(tableRows?.toString())}
    </root.div> */}
    {/* <div className='print' id='print' style={{width: '100%', marginTop:'10px', marginLeft:'5px'}}> {parse(tableRows?.toString())}</div> */}
    <div className='print' id='print' style={{width: '100%', margin:'10px'}} dangerouslySetInnerHTML={{__html: tableRows.toString()}}/>
    
    </Fragment>
    )
}

function htmlDecode(input) {
  var e = document.createElement('div');
  e.innerHTML = input;
  return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
}
function downloadCSV(csv, filename) {
  var csvFile;
  var downloadLink;

  csvFile = new Blob([csv], { type: "text/csv" });
  downloadLink = document.createElement("a");
  downloadLink.download = filename;
  downloadLink.href = window.URL.createObjectURL(csvFile);
  downloadLink.style.display = "none";
  document.body.appendChild(downloadLink);
  downloadLink.click();
}
function renderHTMLToDiv(data) {
  let html = data;
  let div = document.getElementById("report-preview");
  div.innerHTML = html;
}
