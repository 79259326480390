import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";
import { allNullTypeCheck, isNullResponse } from "../../commonComponents/UtilityFun";

function getLabel(parameter, value) {
  if (value) {
    return isNullResponse(parameter)? parameter?.find((c) => {
       // console.log("label comparing:...." + c._value + " to ....." + value, 'label.......', c._label);
      return c._value === value;
    })?._label:''
  }
  return null;
}

function getDefaultLabel(parameter) {
  return isNullResponse(parameter)? parameter?.find((c) => {
   // console.log("comparing defaults:" + c._value + " to " + c._isDefault);
    return c?._isDefault;
  })?._label:''
}
const validateFields=(vol1,vol2)=>{
  console.log("type is ",typeof(vol1),'--',typeof(vol2))
  if(allNullTypeCheck(vol2)){
    return vol1.toLowerCase()===vol2.toLowerCase()
  }else{
    return vol1===vol2
  }
  

}

function Select(props) {
  var { _label, _name, _choices, _type, _value, onChange, ...rest } = props;
  return (
    <div className='add-currency-popup-row'>
      <div className='input-container'>
        <label htmlFor={_name}>{_label}</label>
        {/* <Field
        type="text" placeholder="select" id={_label} name={_label} list={_label} {...rest}
      /> */}
        <Field as='select' name={_label} onChange={(e)=>props.onChange(e,_type,_name,_label)} className='select-width' {...rest}>
          {_choices.map((opt, index) => (
            <option
              key={index}
              value={opt._value}
              selected={validateFields(opt._value,_value)}
              label={getLabel(
                _choices,
                opt._value,
              ) || getDefaultLabel(opt)}
            />
          ))}
        </Field>
        {/* <datalist id={_label}>
        {_choices.map((opt,index) => {
          return <option
              value={opt._value}
              key={index}
            >
              {getLabel(_choices,opt._value)}
            </option>
        })}
      </datalist> */}
        <ErrorMessage name={_label} component={TextError} />
      </div>
    </div>
  );
}

export default React.memo(Select);
