import React from 'react';
import './CompleteIntegration.css';
import vector13 from '../../../assets/vector-13@2x.png';
import noList from '../../../assets/167337012-860712567818400-4952636326271892083-n@2x.png';
import developerToken from '../../../assets/developer-token-@2x.png';
import vector56 from '../../../assets/vector-56@2x.png';
import vector57 from '../../../assets/vector-57@2x.png';
import completeIntegrat from '../../../assets/completeIntegration.svg';
import PrimaryButtonDefault from '../PrimaryButtonDefault/index'
import { Input } from 'reactstrap';
import { copyToClipBoard, devtokenFormat, errorHandling } from '../../utilities/Services';
import * as Constants from '../../../constants/Constants';
import { auth } from '../../utilities/devToken'
import { Oval } from "react-loader-spinner";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import actions from '../../../redux/actions/CommonDataActions';
import actions1 from '../../../redux/actions/IntegrationPopUp';
import { postCreateIntegration, getIntegrationAPI, postDevToken } from '../../../redux/thunk/integrationAPIThunk';
import { integration } from '../../../redux/actions/integrationDataActions'
import AuthService from '../../server/AuthService';
import NewSnackbar from "../../utilities/NewSnackbar";

class CompleteIntegration extends React.Component {
    constructor(props) {
        super(props)
        this.handleClose = this.handleClose.bind(this)
       // this.viewIntegrations = this.viewIntegrations.bind(this)
        this.state = {
            showModal: this.props.value,
            integrationSecret: Constants.DEV_TOKEN_MESSAGE,
            showloader: true
        }
        this.api = auth
    }
    handleClose() {
        this.props.hideCompleteIntegrationPopup()
        window.location.pathname = "/portal"
    }
    viewIntegrations() {
        this.props.showViewIntegrationsSummary()
        this.props.hideCompleteIntegrationPopup()
       let item = localStorage.getItem("LandingIntegrations")
        if(item === "AllIntegrations"){
            localStorage.setItem("testintegration", null)
        }
        else{
            localStorage.setItem("testintegration", "summarypage")
        }
       // window.location.pathname = "/portal/allintegrations"
    }
    copyOption(copyValue) {
        // alert('Text-Copied')
        if (copyValue) {
            copyToClipBoard(copyValue, Constants.TEXT_COPYTOCLIPBOARD, 5000, Constants.RED_COLOR);
            this.props.showSnackbar({snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.info, snackbarMessage: Constants.TEXT_COPYTOCLIPBOARD})
        }
    }
    componentDidMount() {
       // this.getdevToken()
       console.log('current Integration Id details',this.props.currentIntegrationID)
       if (AuthService.isTokenValidCheck() === true) {
       if(this.props.currentIntegrationID !== null){
        this.props.callGetIntegrationAPI(this.props.currentIntegrationID);
       }
       localStorage.removeItem("AuthType")
      }
    }
    componentDidUpdate(nextProps) {

        if (this.props.getIntegrationResponse !== nextProps.getIntegrationResponse) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Get integration ", this.props.getIntegrationResponse) }
            if (this.props.getIntegrationResponse.error === false) {
                if (this.props.getIntegrationResponse.data.length > 0) {
                    // console.log("integration Details", this.props.getIntegrationResponse.data[0]); 
                    // this.getdevToken(this.props.getIntegrationResponse.data[0].integrationID, this.props.getIntegrationResponse.data[0].integrationSecret) 
                    this.props.updateCurrentIntegrationName(this.props.getIntegrationResponse.data[0].name)
                    let devToken_Params = {}
                    devToken_Params[Constants.RESETSECRET_ACTION] = this.props.getIntegrationResponse.data[0].integrationSecret
                    devToken_Params[Constants.PARAM_INTEGRATIONID] = this.props.getIntegrationResponse.data[0].integrationID
                    devToken_Params[Constants.PARAM_SCOPE] = Constants.TEXT_DEVTOKEN_SCOPES
                    devToken_Params[Constants.PARAM_EXPIRY] = Constants.TEXT_CURL_DEVTOKEN_EXPIRY
                    this.setState({ showloader: true });
                    this.props.generateDevToken(devToken_Params)
                }
            }
            this.setState({ showloader: false });
        } else if ((this.props.getIntegrationError !== nextProps.getIntegrationError)) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Get integration error ", this.props.getIntegrationError) }
            this.setState({ showloader: false });
            errorHandling(this.props.getIntegrationError);
        }
        if (this.props.devtokenResposne !== nextProps.devtokenResposne) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Devtoken Resposne ", this.props.devtokenResposne) }
            if (this.props.devtokenResposne.error === false) {
                if (this.props.devtokenResposne.data !== null && this.props.devtokenResposne.data !== '') {
                    // console.log("devtokenResposne Details", this.props.devtokenResposne.data.dev_token);
                    this.setState({ integrationSecret: devtokenFormat(this.props.devtokenResposne.data.dev_token) });
                }
                this.setState({ showloader: false });
            }
        } else if ((this.props.devtokenError !== nextProps.devtokenError)) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Devtoken Resposne error ", this.props.devtokenError) }
            this.setState({ showloader: false });
            errorHandling(this.props.devtokenError);
        }
    }
    async getdevToken(token, secret) {
        this.setState({showloader:true});
        const params = new URLSearchParams()
        params.append('grant_type', Constants.POSTDATA_TOKEN.grant_type)
        params.append('client_id', token)
        params.append('client_secret', secret)
        params.append('scope', Constants.POSTDATA_TOKEN.scope)
        // params.append('username', POSTDATA_TOKEN.username)
        // params.append('password', POSTDATA_TOKEN.password)
        params.append('response_type', Constants.POSTDATA_TOKEN.response_type)
        params.append('token_type', Constants.POSTDATA_TOKEN.token_type)
        await this.api.getAuthentication(params).then(response => {
            console.log(response)
            if (response) {
                this.setState({ integrationSecret: devtokenFormat(response), showloader: false })
            }
        }).catch(error => {
            this.setState({ showloader: false })
            this.props.showSnackbar({snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.warning, snackbarMessage: 'Something went Wrong !!'})
        })
    }
    render() {
        let loader = ''
        if (this.state.showloader) {
            loader = (<div id="center" style={{ position: 'fixed', bottom: '40%', left: '48%' }}>
                <Oval type="Puff" position="fixed"  secondaryColor="#E85557" color="#E85557" height={50} width={50} visible={true} /> </div>)
        }
        return (
            <div class="container-center-horizontal">
                <div className="frame-9-coi screen">
                    <img className="vector-1-coi" src={vector13} onClick={this.handleClose} alt='' style={{cursor: 'pointer'}} />
                    <h1 className="text-1-coi valign-text-middle lato-bold-mine-shaft-16px">
                        <span>
                            <span className="span0 lato-bold-persian-red-24px">
                                Awesome! <br /><br />
                            </span>
                            <span className="span1 lato-bold-fuscous-gray-18px ">Your integration has been created</span>
                            <span className="span2 lato-bold-fuscous-gray-24px">.</span>
                        </span>
                    </h1>
                    <img className="x16736553345831-9103775592473n" src={completeIntegrat} alt='' />
                    <p className="text-2-coi valign-text-middle paragraph-2-12pt-Bold-Lato">
                        Make your first call and retrieve the distributors list for your account using the developer token below. The
                        token will expire in 60 minutes.
                    </p>
                    <div className="overlap-group-coi border-1px-mine-shaft">
                        {/* <img
                    className="this-is-a-pre-b-developer-token"
                    src="https://anima-uploads.s3.amazonaws.com/projects/607ff5beb95afd36a6b5e6c3/releases/60814525f4556b65c033cbd7/img/this-is-a-pre-built-curl-command-along-with-a-developer-token-@2x.png"
                /> */}

                        <Input className="this-is-a-pre-b-developer-token" type="textarea" style={{ resize: 'none' }} value={this.state.integrationSecret} name="bodytags" />
                        <span style={{ color: 'white', fontSize: '1.4em', cursor: 'pointer' }} onClick={this.copyOption.bind(this, this.state.integrationSecret)}><i className="copy outline icon" /></span>

                    </div>
                    <div className="comp-line"> </div>
                    <div onClick={this.viewIntegrations.bind(this)}>
                        <PrimaryButtonDefault>VIEW INTEGRATION</PrimaryButtonDefault>
                    </div>
                    {loader}
                    <NewSnackbar/>
                </div>
            </div>
        );
    }
}
CompleteIntegration.propTypes = {
    params: PropTypes.any,
    backfunc: PropTypes.func,
};
const mapStateToProps = state => {
    return {
        currentIntegrationID: state.integrationDataReducer.currentIntegrationID,
        userDetails: state.userDataReducer.userDetails,
        userHasError: state.userDataReducer.userHasError,
        isFetchingData: state.commonDataReducer.isFetchingData,
        localChangesExist: state.commonDataReducer.localChangesExist,
        createIntegrationResponse: state.integrationDataReducer.createIntegrationResponse,
        createIntegrationError: state.integrationDataReducer.createIntegrationError,
        getIntegrationResponse : state.integrationDataReducer.getIntegrationResponse,
        getIntegrationError : state.integrationDataReducer.getIntegrationError,
        devtokenResposne: state.integrationDataReducer.devtokenResposne,
        devtokenError: state.integrationDataReducer.devtokenError,
    };
};
const mapDispatchToProps = dispatch => ({
    showLoader: () => dispatch({ type: actions.FETCHING_DATA_LOADER, payload: true }),
    hideLoader: () => dispatch({ type: actions.HIDE_DATA_LOADER, payload: false }),
    localChanges: (value) => dispatch({ type: actions.CHANGES_EXISTING, payload: value }),
    callCreateIntegrationAPI: (params) => dispatch(postCreateIntegration(params)),
    updateCurrentIntegrationID: (integrationID) => dispatch({ type: integration.CURRENT_INTEGRATIONID, payload: integrationID }),
    showCreateIntegrationPopup: () => dispatch({ type: actions1.SHOW_CREATE_INTEGRATIONS, payload: true }),
    hideCreateIntegrationPopup: () => dispatch({ type: actions1.HIDE_CREATE_INTEGRATIONS, payload: false }),
    showIntegrationNamePopup: () => dispatch({ type: actions1.SHOW_INTEGRATIONS_NAME, payload: true }),
    hideIntegrationNamePopup: () => dispatch({ type: actions1.HIDE_INTEGRATIONS_NAME, payload: false }),
    showCompleteIntegrationPopup: () => dispatch({ type: actions1.SHOW_COMPLETE_INTEGRATIONS, payload: true }),
    hideCompleteIntegrationPopup: () => dispatch({ type: actions1.HIDE_COMPLETE_INTEGRATIONS, payload: false }),
    showViewIntegrationsSummary: () => dispatch({ type: actions1.SHOW_COMPLETE_SUMMARY_INTEGRATIONS, payload: true }),
    hideViewIntegrationsSummary: () => dispatch({ type: actions1.HIDE_COMPLETE_SUMMARY_INTEGRATIONS, payload: false }),
    callGetIntegrationAPI:(integrationID) => dispatch(getIntegrationAPI(integrationID)),
    generateDevToken: (params) => dispatch(postDevToken(params)),
    showSnackbar: (params) => dispatch({ type: actions.SNACKBAR_SHOW, payload: params}),
    updateCurrentIntegrationName:(integrationName)=>dispatch({type: integration.CURRENT_INTEGRATION_NAME,payload:integrationName})
});

export default connect(mapStateToProps, mapDispatchToProps)(CompleteIntegration);
