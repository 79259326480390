import { JWT_ISS } from '../../../src/constants/Constants'
var axios = require('axios')
let loggingIn = false;
const waitingPromises = [];
let headerToken = ''
const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
}
class devToken {
  // eslint-disable-next-line no-useless-constructor
  constructor() {
  }
  async getAuthentication(params) {
    try {
      if (!loggingIn) {
        loggingIn = true;
        return new Promise((resolve, reject) => {
          axios.post(JWT_ISS, params, config)
            .then(function (response) {
              if (response) {
                headerToken = response.data.access_token
                console.log('header Token', headerToken)
                resolve(headerToken)
              }
              while (response !== null && waitingPromises.length > 0) {
                const waiting = waitingPromises.pop();
                waiting(response.data.access_token);
              }
              loggingIn = false;
            })
            .catch(function (error) {
              console.log(error);
              reject(error)
            });
        })

      }
      else {
        const waitPromise = new Promise((resolve) => {
          //   console.log("Storing waitingPromise...");
          waitingPromises.push(resolve);
        });
        return waitPromise;
      }
    } catch (error) {
      console.log(error);
    }
  }
}

export const auth = new devToken();
