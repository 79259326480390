import React from "react";
import "./TopMenu.css";
import Dotsfilled from "../../../assets/Nine_dots_filled1.svg"
import {Oval} from 'react-loader-spinner';
import { nameProfileText, valueValidation } from '../../utilities/Services';
import jwt from 'jwt-decode';
import AuthService from '../../server/AuthService';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import userProfile from "../../../assets/profile-icon1.png";
import { withRouter } from 'react-router-dom';

class TopMenu extends React.Component {
  constructor(props) {
    super(props);
    this.handlelogout = this.handlelogout.bind(this);
    this.state = {
      isLoader: true,
      clientName: 'Client Name',
      clientENV: 'Environment',
      logoName: 'CN',
      AnchorEl: ''
    }
    this.location = Object;
    this.history = Object;
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({ isLoader: false });
    }, 1000);
    this.getCleintNameEnv()
  }
  getCleintNameEnv() {
    const id_token = localStorage.getItem('id_token');
    if (id_token !== "undefined" && valueValidation(id_token)) {
      const client = jwt(id_token);
      this.setState({ logoName: nameProfileText(client.client_name) })
      this.setState({ clientName: client.client_name })
      if (client.iss.includes('dev')) {
        this.setState({ clientENV: 'Dev Environment' })
      } else if (client.iss.includes('test')) {
        this.setState({ clientENV: 'Test Environment' })
      }
      else if (client.iss.includes('stage')) {
        this.setState({ clientENV: 'Stage Environment' })
      }
      else {
        this.setState({ clientENV: 'Live Environment' })
      }
    }
    else {
      console.log('Token is empty')
    }
  }

  onChangeNotifications() {
    // alert('Notificaions Clicked!!')
  }
  profile() {
    // alert('Profile Clicked!!')
  }
  nineDotsClicked=()=> {
    // alert('Apps Not Available')
    localStorage.removeItem("appselect");
    window.location.pathname = "/"
  }
  handlelogout() {
    AuthService.logout();
  }
  handleClick = (event) => {
    console.log('event', event.currentTarget)
    this.setState({ AnchorEl: event.currentTarget })
  };
  handleClose = () => {
    // this.handlelogout()
    this.setState({ AnchorEl: null })
  }
  render() {
    const {
      flexcloud_Logo_Logo1,
      line40,
      overlapGroup1,
      atI42779255482,
      text6,
      text7,
      vector2,
      vector3,
      vector4,
      image4,
    } = this.props;
    let loader = ''
    if (this.state.isLoader) {
      loader = (<div id="center" style={{ position: 'fixed', bottom: '40%', left: '48%' }}>
        <Oval type="Puff" position="fixed"  secondaryColor="#E85557" color="#E85557" height={50} width={50} visible={true} /> </div>)
    }
    return (
      <div className="top-menu">
        {/* https://v2-dev-atomic.flexcloud.com/assets/img/main-logo.png */}
        {/* process.env.REACT_APP_IDENTITY_REDIRECT_URL+'/assets/img/main-logo.png' */}
        <div className="flex-row-122">
          <div className="col-10">
            <div className="group-topmenu">
              <img
                className="flex-cloudlogologo-1"
                src={flexcloud_Logo_Logo1} alt=""
              />
              <img className="line-40" src={line40} alt="" />
              <div className="group-2438">
                <div className="overlap-group1-top text-center" style={{ backgroundImage: `url(${overlapGroup1})` }}>
                  <div className="at-i42779255482 lato-bold-shark-14px">{this.state.logoName}</div>
                </div>
                <div className="flex-col-1">
                  <div className="text-61 lato-semi-bold-white-14px">{this.state.clientName}</div>
                  <div className="text-7 valign-text-middle lato-normal-white-13px">{this.state.clientENV}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-2">
            <div className="row" style={{ justifyContent: 'center' }}>
            <div className="col-sm-3"></div>
              <div className="col-sm-3">
                <div className="overlap-group2-1">
                  <img className="vector-6" src={Dotsfilled} alt='' onClick={()=>this.nineDotsClicked()} style={{cursor:'pointer'}}/>
                </div>
              </div>
              {/* <div className="col-sm-3">
                <div className="notification1">
                  <div className="overlap-group2" onClick={this.onChangeNotifications}>
                    <img className="vector-3" src={vector2} alt="vector-3" />
                    <img className="vector" src={vector3} alt="vector" />
                  </div>
                  <img className="vector-1" src={vector4} alt="vector-1" />
                </div>
              </div> */}
              <div className="col-sm-3">
                <div className="overlap-group2-1">
                  <img className="user-profile" src={userProfile} alt="" onClick={this.handleClick} style={{ cursor: 'pointer' }} />
                  <Menu style={{ marginTop: '35px' }}
                    id="simple-menu"
                    anchorEl={this.state.AnchorEl}
                    keepMounted
                    open={Boolean(this.state.AnchorEl)}
                    onClose={this.handleClose}
                  >
                    <MenuItem disabled={true}>User Profile</MenuItem>
                    <MenuItem disabled={true}>Developer Settings</MenuItem>
                    <MenuItem onClick={this.handlelogout}>Logout</MenuItem>
                  </Menu>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>{loader} </div>
      </div>
    );
  }
}

export default withRouter(TopMenu);
