import React, { Component } from 'react';
import Oidc, { User } from 'oidc-client';
import AuthService from './components/server/AuthService';
import PropTypes from 'prop-types';
 

export default class CallBack extends Component {
  componentDidMount() {
    return new Promise((resolve, reject) => {
      new Oidc.UserManager().signinRedirectCallback().then((user) => {
        if (user&&user.id_token) {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('redirect func called !!!');}
          let expiresAt = JSON.stringify(
            user.expires_in * 1000 + new Date().getTime()
          );
          localStorage.setItem('id_token', user.id_token);
          localStorage.setItem('loggedinUser', JSON.stringify(user));
          localStorage.setItem('expires_at', expiresAt);
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('callback user', JSON.parse(JSON.stringify(user)));}
          window.location = '/home';
          resolve(user.id_token);
        }else if(user){
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('call back else log ');}
          user =  AuthService.renewToken();
          resolve(user);
        }

      }).catch((e) => {            
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('redirect func faileds !!!');}
        localStorage.removeItem('id_token');
        window.location = '/home';
        console.error(e);
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log(User);}
        reject(e);
      });
    });


  }

  navigateToHome() {
    window.location = '/portal';
  }

  render() {
    return (
      <div className="container">
      </div>);
  }
}
CallBack.propTypes = {
  history:PropTypes.object,
};
