import React from 'react';
import './ProgressLine.css';
import progressdot from '../assets/dotgray.svg'
import { ProgressBar } from 'react-bootstrap';
import * as Constants from '../commonComponents/Constants'
import * as Constant from '../../../constants/Constants';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { qualRunExecussionAPICall, getRunStatusAPICall,qualRunResetAPICall, CURRENT_RUN_ID, qualRunStopAPICall, getCurrentRunProgramsAPI } from '../actions'
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import { ConfirmModel } from '../ConfirmModel/ConfirmModel';
import CommissionProcess from '../CommissionProcessing/CommissionProcess';
import { UndoConfirmModal } from '../UndoConfirm/UndoConfirmModal';
import greenTick from '../assets/greenrightmark.svg';
import AuthService from "../../../../src/components/server/AuthService";
import actions from '../../../redux/actions/CommonDataActions';
import NewSnackbar from "../../utilities/NewSnackbar";
import { allNullTypeCheck, isNullResponse } from '../commonComponents/UtilityFun';
import externalLink from '../assets/externalLinkgray.svg'
import { Tooltip } from '@material-ui/core';

let runInterval = null;
class ProgressLine extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            runQualResponseState: {},
            steps: [],
            currentRunStatus: '',
            nextStepState: 0,
            currentStep: 0,
            currentPhase: '',
            dropdownEnable: true,
            currentRunID: '',
            statusUpdate: [
                { name: Constants.BUTTON_TEXT_RUN_QUAL, serviceName: Constants.RUN_QUALIFICATION_SERVICE, undoActionName: undefined, undoServiceName:undefined, dropdownOptionNames :[{ name:Constants.BUTTON_TEXT_RUN_QUAL_CALC}], title: Constants.MODEL_TITLE_RUN_QUAL},
                { name: Constants.BUTTON_RUN_CALCULATION, serviceName: Constants.RUN_CALCULATION_SERVICE, undoActionName: undefined, undoServiceName:undefined, dropdownOptionNames :[{name:Constants.BUTTON_RE_RUN_QUAL},{name:Constants.BUTTON_RE_RUN_QUAL_CALC}], title: Constants.MODEL_TITLE_RUN_CALC },
                { name: Constants.BUTTON_TEXT_CERTIFY, serviceName: Constants.RUN_CERTIFICATION_SERVICE, undoActionName: Constants.BUTTON_UN_CERTIFY_NAME,undoServiceName: Constants.RUN_UNDO_CERTIFICATION_SERVICE, dropdownOptionNames :[{name:Constants.BUTTON_RE_RUN_QUAL},{name:Constants.BUTTON_RE_RUN_QUAL_CALC}], title: Constants.MODEL_TITLE_RUN_CERTIFY },
                { name: Constants.BUTTON_TEXT_DISBURS, serviceName: Constants.RUN_DISBURSEMENT_SERVICE, undoActionName: Constants.BUTTON_UNDO_DISBURSE_NAME,undoServiceName:Constants.RUN_UNDO_DISBURSEMENT_SERVICE, dropdownOptionNames :[{ name:Constants.BUTTON_UNDO_CERTIFY }], title: Constants.MODEL_TITLE_RUN_DISBURSE },
                { name: Constants.BUTTON_TEXT_MAKELIVE, serviceName: Constants.RUN_MAKELIVE_SERVICE, undoActionName: Constants.BUTTON_UNDO_LIVE_NAME, undoServiceName:Constants.RUN_UNDO_MAKELIVE_SERVICE, dropdownOptionNames :[{ name:Constants.BUTTON_UNDO_CERTIFY_AND_RESET }], title: Constants.MODEL_TITLE_RUN_MAKE_LIVE },
                { name: Constants.BUTTON_TEXT_MERGEAR, serviceName: Constants.RUN_MERGEACCOUNTSRECEIVABLE_SERVICE, undoActionName: Constants.BUTTON_UN_MERGE_AR_NAME, undoServiceName: Constants.RUN_UNDO_MERGEACCOUNTRECEIVABLE_SERVICE, dropdownOptionNames :[{ name:Constants.BUTTON_UNDO_MAKELIVE }], title: Constants.MODEL_TITLE_RUN_MERGEAR },
                { name: Constants.BUTTON_UNDO_MERGEAR, serviceName: Constants.RUN_NOTIFY_SERVICE, undoActionName: undefined, undoServiceName:Constants.RUN_UNDO_MERGEACCOUNTRECEIVABLE_SERVICE, dropdownOptionNames :[], title: Constants.MODEL_TITLE_UNDO_MERGEAR },
            ],
            displayRunStatusModel: false,
            displayCPModel: false,
            runStatusType: '',
            runStatusTypeConfirmBody: '',
            runStatusTypeConfirmBody1: '',
            runStatusTypeTitle: '',
            qualProgress: 0,
            minimizeQualProgress: 0,
            calcProgress: 0,
            certifyProgress: 0,
            disburseProgress: 0,
            makeLiveProgress: 0,
            margeARProgress: 0,
            notifyProgress: 0,
            runQualDisabled: false,
            enableUndoAction: true,
            updateRunStatus: 'Qualifications',
            displayUndoRunStatusModal: false,
            showMinmizeUI: false,
            minimiseQualProgress: 0,
            nextIndexValue: 0,
            runProgramsList: [],
            dropdownSelectedItem:"",
            autoExecuteCalc: false,
            autoExecuteCalcStep:false,
            selectedStep:0,
            rundetailsCall:true,
            okButtonText: '',
            undoStepExecution:false,
            changeStepCalc:true,
            timeOutVar:1,
            minimizeUIAutoHandle:0,
            undoStepAction:false,
            tempCache: '',
            countText: '',
            isDisableRunStep:false,
        }
        // this.qualRunHandler = this.qualRunHandler.bind(this);
        runInterval=null;
        this.closeSelectedRunProcessing = this.closeSelectedRunProcessing.bind(this);
        this.disaplySelectedRunProcessing = this.disaplySelectedRunProcessing.bind(this);
        this.stopCommissionProcessing = this.stopCommissionProcessing.bind(this);
        this.continueCommissionProcessing = this.continueCommissionProcessing.bind(this);
        this.handleUndoActions = this.handleUndoActions.bind(this);
        this.closeSelectedUndoRunModal = this.closeSelectedUndoRunModal.bind(this)
        this.closeCommisisonProcessModal = this.closeCommisisonProcessModal.bind(this)
    }
    disaplySelectedRunProcessing(actionType) {
        const { nextIndexValue, statusUpdate, displayRunStatusModel, displayCPModel, runStatusTypeTitle, dropdownSelectedItem, displayUndoRunStatusModal, selectedStep, tempCache} = this.state
        const{ commissionRunId } = this.props 
        if(!displayUndoRunStatusModal){
            if(actionType!== "Try again"){  this.setState({ displayRunStatusModel: false, displayCPModel: !displayCPModel, tempCache: '' }); }
        }
        else{
            if(actionType!== "Try again"){  this.setState({ displayUndoRunStatusModal: !displayUndoRunStatusModal, displayCPModel: !displayCPModel, tempCache: '' });}
        }
        console.log('service name and run id', nextIndexValue, statusUpdate[nextIndexValue]?.serviceName)
        switch (nextIndexValue) {
          case 0:
              if(dropdownSelectedItem===""){
                this.setState({ selectedStep: actionType!== "Try again"? nextIndexValue: selectedStep }, () => {});
                this.props.qualRunExecussion(commissionRunId, statusUpdate[0]?.serviceName, tempCache);
              }else {
                this.setState({ selectedStep: actionType!== "Try again"? 0: selectedStep }, () => {});
                this.props.qualRunExecussion(commissionRunId, statusUpdate[0]?.serviceName, tempCache);
                this.handleAutoExecution(nextIndexValue, dropdownSelectedItem);
              }
            break;
          case 1:
            console.log("called this block",dropdownSelectedItem,nextIndexValue);
            if(dropdownSelectedItem===""){
                
                this.setState({ selectedStep:  actionType!== "Try again"? nextIndexValue: selectedStep  }, () => {});
                this.props.qualRunExecussion(commissionRunId, statusUpdate[1]?.serviceName, tempCache);
              }else {
                this.setState({ selectedStep:  actionType!== "Try again"? 0: selectedStep  }, () => {});
                if(dropdownSelectedItem==="1"){
                    this.props.qualRunExecussion(commissionRunId, statusUpdate[0]?.serviceName, tempCache);
                }else if(dropdownSelectedItem==="2"){
                    this.props.qualRunExecussion(commissionRunId, statusUpdate[0]?.serviceName, tempCache);
                    this.handleAutoExecution(nextIndexValue, dropdownSelectedItem);
                }
              }
            break;
          case 2:
            if(dropdownSelectedItem===""){
                this.setState({ selectedStep:  actionType!== "Try again"? nextIndexValue: selectedStep }, () => {});
                this.props.qualRunExecussion(commissionRunId, statusUpdate[2]?.serviceName, tempCache);
              }else {
                this.setState({ selectedStep:  actionType!== "Try again"? 0: selectedStep  }, () => {});
                if(dropdownSelectedItem==="1"){
                this.props.qualRunExecussion(commissionRunId, statusUpdate[0]?.serviceName, tempCache);
                }else if(dropdownSelectedItem==="2"){
                this.props.qualRunExecussion(commissionRunId, statusUpdate[0]?.serviceName, tempCache);
                this.handleAutoExecution(nextIndexValue, dropdownSelectedItem);
                }
              }
            break;
          case 3:
            if(dropdownSelectedItem===""){
                this.setState({ selectedStep:  actionType!== "Try again"? nextIndexValue: selectedStep }, () => {});
                this.props.qualRunExecussion(commissionRunId, statusUpdate[3]?.serviceName, tempCache);
              }else if(dropdownSelectedItem==="1"){
                this.setState({ selectedStep: nextIndexValue-1, undoStepExecution:true }, () => {});
                this.props.qualRunExecussion(commissionRunId, statusUpdate[2]?.undoServiceName, tempCache);
              }
            break;
          case 4:
            if(dropdownSelectedItem===""){
                this.setState({ selectedStep:  actionType!== "Try again"? nextIndexValue: selectedStep  }, () => {});
                this.props.qualRunExecussion(commissionRunId, statusUpdate[4]?.serviceName, tempCache);
              }else if(dropdownSelectedItem==="1"){
                this.setState({ selectedStep: 0, undoStepExecution:true }, () => {});
                this.props.qualRunExecussion(commissionRunId, statusUpdate[2]?.undoServiceName, tempCache);
              }
            break;
          case 5:
            if(dropdownSelectedItem===""){
                this.setState({ selectedStep:  actionType!== "Try again"? nextIndexValue: selectedStep  }, () => {});
                this.props.qualRunExecussion(commissionRunId, statusUpdate[5]?.serviceName, tempCache);
              }else if(dropdownSelectedItem==="1"){
                this.setState({ selectedStep:  actionType!== "Try again"?  nextIndexValue-1: selectedStep, undoStepExecution:true }, () => {});
                this.props.qualRunExecussion(commissionRunId, statusUpdate[4]?.undoServiceName, tempCache);
              }
            break;
          case 6:
            if(dropdownSelectedItem===""){
                this.setState({ selectedStep:  actionType!== "Try again"?  nextIndexValue-1: selectedStep, undoStepExecution:true }, () => {});
                this.props.qualRunExecussion(commissionRunId, statusUpdate[6]?.undoServiceName, tempCache);
              }
            break;
          default:
            break;
        }

    }
    handleAutoExecution=(nextIndexValue, dropdownValue)=>{
        const { runStatusResponse } = this.props
        const { steps } = runStatusResponse?.data;
        if(nextIndexValue===0 && dropdownValue === "1"){
            this.setState({
                autoExecuteCalc: true
              });
        }else if (nextIndexValue===1 && dropdownValue === "2") {
            console.log("auto execution : ",steps[0]?.status.toLowerCase() ===
            "completed");
            this.setState({
              autoExecuteCalc: true,
              autoExecuteCalcStep:steps[0]?.status.toLowerCase() ===
                        "completed"? true : false,
            },()=>{});
        } else if(nextIndexValue!==1 && dropdownValue === "2"){
            this.setState({
                autoExecuteCalc: true
              });
        }
    }
    closeSelectedRunProcessing() {
        this.setState({ displayRunStatusModel: !this.state.displayRunStatusModel, dropdownSelectedItem: "" });
    }
    closeSelectedUndoRunModal() {
        this.setState({ displayUndoRunStatusModal: !this.state.displayUndoRunStatusModal, dropdownSelectedItem: "" });
    }
    componentDidMount() {
        console.log("selected run id : ",this.props.commissionRunId)
        this.setState({ nextStepState: 0, dropdownEnable : true });
        if(this.props.commissionRunId !== null){
            if (AuthService.isTokenValidCheck() === true) {
            this.props.getRunStatus(this.props.commissionRunId);
            this.props.getCurrentRunPrograms(this.props.commissionRunId)
            }
        }
    }
    componentWillReceiveProps(nextProps) {
        console.log("triggered : ", nextProps.commissionRunDetails);
        //this.setState({ commissionRunDetails: nextProps.commissionRunDetails.data });
    }
    stopCommissionProcessing(runID) {
        this.setState({ displayCPModel: !this.state.displayCPModel });
        this.props.qualRunStop(runID) 
    }
    continueCommissionProcessing() {
        this.setState({ displayCPModel: !this.state.displayCPModel, showMinmizeUI: !this.state.showMinmizeUI});
        console.log('current step', this.state.currentStep,'this.state.displayCPModel', this.state.displayCPModel, 'showMinmizeUI', this.state.showMinmizeUI)
        // if(this.state.currentStep === 7){
        //     this.setState({ showMinmizeUI: false});
        // }
    }
    closeCommisisonProcessModal(){
        this.setState({ displayCPModel: false, dropdownSelectedItem: "", undoStepExecution:false, autoExecuteCalc:false, autoExecuteCalcStep: false  });
    }
    hideMinmiseUI(){
        this.setState({ displayCPModel: !this.state.displayCPModel, showMinmizeUI: !this.state.showMinmizeUI, undoStepExecution:false });
    }
    notifyStatusRun(runID){
       // console.log('selected run id', runID)
        if(runID !== null && runID !== undefined){
          //  this.props.qualRunStop(runID) 
            this.props.qualRunReset(runID) 
            this.setState({nextIndexValue: 0})
        }
    }
    currentMinimizeProgressUpdate(steps, selectedStep){
        let percentProgress = 0;
        if (steps[selectedStep].status.toLowerCase() === 'processing') {
            this.setState({ minimizeQualProgress: 0 });
            if (steps[selectedStep].phases.length > 0) {
                for (let indexi = 0; indexi < steps[selectedStep]?.phases.length; indexi++) {
                    
                    if (steps[selectedStep]?.phases[indexi].status !== 'pending' && steps[selectedStep]?.phases[indexi].status !== 'completed' && steps[selectedStep]?.phases[indexi].status !== 'complete') {
                        percentProgress = allNullTypeCheck(steps[selectedStep]?.phases[indexi].currentRecord / steps[selectedStep]?.phases[indexi].totalRecords) ? Math.round((steps[selectedStep]?.phases[indexi].currentRecord / steps[selectedStep]?.phases[indexi].totalRecords) * 100) : 0
                        //console.log("each step progress is :", Math.round((steps[selectedStep]?.phases[indexi].currentRecord/steps[selectedStep]?.phases[indexi].totalRecords)*100));
                        this.setState({ minimizeQualProgress: percentProgress, countText: `${steps[selectedStep]?.phases[indexi].currentRecord} of ${steps[selectedStep]?.phases[indexi].totalRecords}`});
                    }
                    if (steps[selectedStep]?.phases[indexi].status === 'completed') {
                        percentProgress = 0 // percentProgress+percentProgress*indexi;
                        this.setState({ minimizeQualProgress: 100 });
                    }
                }
            }
        } 
    }
    componentDidUpdate(nextProps) {
        const { runQualResponseState, currentPhase, statusUpdate, autoExecuteCalc, nextStepState, autoExecuteCalcStep, changeStepCalc, timeOutVar, selectedStep,
            minimizeUIAutoHandle, isDisableRunStep,
            showMinmizeUI, undoStepAction, tempCache, displayRunStatusModel, displayCPModel } = this.state;
        const { runQualResponse, getRunStatus, runStatusResponse, runQualError, runStatusError, commissionRunDetails, runQualResetResposne, runQualStopResposne, commissionRunId, runQualStopError, runQualResetError, selectedRunPrgmResponse, selecteRunPrgmError } = this.props;
        if (runQualResponse !== nextProps.runQualResponse){
            if(!runQualResponse.hasOwnProperty('status')){ 
            console.log("Run Qual response : ", runQualResponse,runQualResponse.error);
            if(isNullResponse(runQualResponse?.data) && runQualResponse.error===false) {
                console.log("Run Qual response : ", runQualResponse, nextStepState, this.state.statusUpdate);
                this.setState({ runQualResponseState: runQualResponse.data , tempCache: '' });
                console.log("id : ", runQualResponseState);
                getRunStatus(runQualResponse?.data?.runId);
            }else {
                console.log("Run execution else block : ",runQualResponse?.data);
                if(Object.keys(runQualResponse?.data).length===0 && allNullTypeCheck(runQualResponse?.messages)) {
                    if(runQualResponse?.messages?.length > 0 && runQualResponse?.messages[0]?.includes("Try again")){
                        console.log("Try again block entered : ",runQualResponse?.data);
                        this.setState({ tempCache: runQualResponse?.data?.cacheKey})
                        if (AuthService.isTokenValidCheck() === true) {
                        setTimeout(()=>{
                            this.disaplySelectedRunProcessing("Try again");
                        },timeOutVar*1000)
                        
                        this.setState(prevState => {
                            return {timeOutVar: prevState.timeOutVar * 2}
                         })
                        }
                    }
                    console.log("Try again block entering failed : ",runQualResponse?.data);
                }
                console.log("Current step response and run id :",runQualResponse?.data,commissionRunId)
               // this.props.showSnackbar({snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.warning, snackbarMessage:'Current run step Response Empty'});
                getRunStatus(commissionRunId);
            }
        }else {
            if(runQualResponse.hasOwnProperty('status') && runQualResponse.status===202){ 
                this.setState({ tempCache: runQualResponse?.data?.cacheKey})
                if (AuthService.isTokenValidCheck() === true) {
                setTimeout(()=>{
                    this.disaplySelectedRunProcessing("Try again");
                },timeOutVar*1000)
                this.setState(prevState => {
                    return {timeOutVar: prevState.timeOutVar * 2}
                 })
               }
          }
        }
        }
        if (runQualError !== nextProps.runQualError) {
            console.log("Run Qual execution failed :", runQualError);
           if(runQualError?.data?.error === true){
            this.props.showSnackbar({snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: typeof(runQualError?.data?.messages[0])!=='string' && allNullTypeCheck(runQualError?.data?.messages[0]?.detail)?
             runQualError?.data?.messages[0]?.code+' - '+  runQualError?.data?.messages[0]?.detail: runQualError?.data?.messages[0]?.code+' - '+runQualError?.data?.messages[0]?.message})
            this.setState({displayCPModel: false})
           }else if(runQualError?.message) {
            this.setState({displayCPModel: false})
            this.props.showSnackbar({snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: runQualError?.message })
           }
        }
        if (runStatusResponse !== nextProps.runStatusResponse){
            //  && runStatusResponse?.data !== undefined && runStatusResponse?.data !== {} && runStatusResponse?.data !== null && runStatusResponse?.data !== '') {
            if(isNullResponse(runStatusResponse.data) && runStatusResponse.error===false){
                const { currentStep, nextStep, status, message } = runStatusResponse?.data?.runDetails;
                const { steps } = runStatusResponse?.data;
                console.log('Run qual Status Response : ', runStatusResponse, nextStepState, this.state.statusUpdate);
                console.log("runStatusResponse?.data?.runDetails?.currentStep : ",runStatusResponse?.data?.runDetails?.currentStep);
                console.log("Current execution run ID : ",runStatusResponse?.data?.runId);

                this.setState({
                    currentRunStatus: runStatusResponse?.data?.runDetails,
                    steps: runStatusResponse.data.steps,
                    currentStep: currentStep,
                    nextStepState: (nextStep === null && currentStep===null && currentPhase===null) ? 0 : (currentStep!==null && nextStep ===null)? currentStep : runStatusResponse?.data?.runDetails.nextStep === null? 0:runStatusResponse?.data?.runDetails.nextStep,
                    currentPhase: runStatusResponse?.data?.runDetails.currentPhase
                },()=>{});
               
                if (currentStep === 5 && nextStep === null && (status.toLowerCase() === 'completed'||status.toLowerCase() === 'complete')) {
                    console.log("called currentstep equalt 7 : ",currentStep);
                    this.setState({ updateRunStatus: message, enableUndoAction:false, showMinmizeUI: false,nextStepState:currentStep+1 })
                    this.props.handleRunActions(false);
                    steps.length>0 && steps.forEach((element,i) => {
                        console.log("status update is called : ",i);
                        if(i<=currentStep){
                            this.updateProgressStatus(i, runStatusResponse.data.steps);
                        }
                    });
                    // if(runInterval){
                    //     clearInterval(runInterval);
                    // }
                    
                } else if ((nextStep!==null) && (currentStep!==null) && currentStep >= 0 && currentStep <= 7) {
                    console.log("currentstep between 0 to 7: ",currentStep,!runInterval);
                    console.log("step result : ", steps[currentStep]?.name, autoExecuteCalcStep);
                    steps[currentStep]?.name === undefined ? this.setState({ updateRunStatus: message }) : this.setState({ updateRunStatus: message,enableUndoAction:true });
                    if( undoStepAction && steps[nextStep]?.status.toLowerCase() ==="pending"){
                    this.setState({ showMinmizeUI: false })
                    }
                    if( currentStep===0 && (steps[currentStep]?.status.toLowerCase() ===
                    "pending" || steps[currentStep]?.status.toLowerCase() === "processing" || steps[currentStep]?.status.toLowerCase()==='completed') && autoExecuteCalc){
                        
                        if(autoExecuteCalcStep && changeStepCalc){
                            console.log("processing state is ");
                            this.setState({ changeStepCalc: steps[currentStep]?.status.toLowerCase() ===
                             "pending" || steps[currentStep]?.status.toLowerCase() === "processing"? false: true },()=>{});
                        }else {
                            if(currentStep===0 && autoExecuteCalcStep){
                                if(steps[currentStep]?.status.toLowerCase()==='completed'){
                                    this.props.qualRunExecussion(commissionRunId, statusUpdate[1]?.serviceName, tempCache);
                                    this.setState({ autoExecuteCalc: false , autoExecuteCalcStep: false, changeStepCalc: false, minimizeUIAutoHandle: 1, selectedStep:1 });
                                }
                            }else {
                                if(autoExecuteCalc && steps[currentStep]?.status.toLowerCase()==='completed'){
                                    this.props.qualRunExecussion(commissionRunId, statusUpdate[1]?.serviceName, tempCache);
                                    this.setState({ autoExecuteCalc: false , autoExecuteCalcStep: false, changeStepCalc:true, minimizeUIAutoHandle: 1, selectedStep:1 });
                                }
                            }
                        }
                        steps.length>0 && steps.forEach((element,i) => {
                                this.updateProgressStatus(i, steps);
                        });
                    }else {
                        steps.length>0 && steps.forEach((element,i) => {
                                this.updateProgressStatus(i, steps);
                        });
                    }
                    if(steps[selectedStep].status === 'completed' || steps[selectedStep].status ==='complete'){
                        if(currentStep===0 && minimizeUIAutoHandle===0 && !autoExecuteCalc){
                          setTimeout(() => {
                              this.setState({ showMinmizeUI: false, isDisableRunStep:false});
                          }, 1000);
                        }else if(currentStep===0 && minimizeUIAutoHandle===1 && !autoExecuteCalc) {
                          setTimeout(() => {
                              this.setState({ showMinmizeUI: true, isDisableRunStep:false});
                          }, 1000);
                        }else if(currentStep > 0 && minimizeUIAutoHandle===1 && !autoExecuteCalc) {
                          setTimeout(() => {
                              this.setState({ showMinmizeUI: false, isDisableRunStep:false});
                          }, 1000);
                        }else if(currentStep > 0 && minimizeUIAutoHandle===0 && !autoExecuteCalc) {
                          setTimeout(() => {
                              this.setState({ showMinmizeUI: false, isDisableRunStep:false});
                          }, 1000);
  
                        }
                      }
                     if(allNullTypeCheck(currentStep) && steps[currentStep]?.status.toLowerCase() === "processing") {
                       if(displayRunStatusModel===false && displayCPModel===false){
                        this.setState({ showMinmizeUI: true, isDisableRunStep:true});
                        this.currentMinimizeProgressUpdate(steps, currentStep)
                        
                       }
                     }

                } else if (currentStep === null && nextStep === null && currentPhase === null) {
                    console.log("currentstep between: ",currentStep);
                    console.log("step result -1  : ", steps[nextStep]?.name,!runInterval);
                    steps.length>0 && steps.forEach((element,i) => {
                        console.log("2 status update is called : ",i);
                            this.updateProgressStatus(i, steps);
                    });
                    this.setState({ updateRunStatus: 'Qualifications', nextStepState: 0 });
                    if( undoStepAction && steps[0]?.status.toLowerCase() ==="pending"){
                        this.setState({ showMinmizeUI: false })
                        }
                }
                if (!runInterval) {
                    console.log('runInterval  : .setInterval()');
                    runInterval = setInterval(() => {
                        console.log('RunDetailsModal.setInterval()');
                        getRunStatus(runStatusResponse.data.runId);
                    }, 5000);
                }
            }
        }
        if (runStatusError !== nextProps.runStatusError) {
            if(runInterval){
                clearInterval(runInterval);
            }
            if(runStatusError?.data?.error === true){
                this.props.showSnackbar({snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: typeof(runStatusError?.data?.messages[0])!=='string'? runStatusError?.data?.messages[0]?.code+' - '+runStatusError?.data?.messages[0]?.detail:runStatusError?.data?.messages[0]})
                this.closeCommisisonProcessModal()
               }else if(runStatusError?.message) {
                this.props.showSnackbar({snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: runStatusError?.message })
                this.closeCommisisonProcessModal()
               }
        }
        if (runQualResetResposne !== nextProps.runQualResetResposne){
            if(isNullResponse(runQualResetResposne?.data)) {
                console.log("runQualResetResposne.data.id  : ", runQualResetResposne.data.id)
                if (AuthService.isTokenValidCheck() === true) {
                getRunStatus(runQualResetResposne.data.id);
                this.props.handleRunActions(true);
                this.setState({ qualProgress: 0, minimiseQualProgress: 0, certifyProgress: 0, disburseProgress: 0, makeLiveProgress: 0, notifyProgress: 0, showMinmizeUI: false, updateRunStatus: 'Qualification', runQualDisabled: false, dropdownEnable: true});
                this.props.showSnackbar({snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.success, snackbarMessage:'Run Reset Successfully'})
                if(runInterval){
                    clearInterval(runInterval);
                }
              }
            }
        } else if(runQualResetError !== nextProps.runQualResetError){
            console.log("runQualReset error Response: ", runQualResetResposne)
        }
        if (runQualStopResposne !== nextProps.runQualStopResposne){
            if(isNullResponse(runQualStopResposne?.data)) {
                console.log("runQualStopResposne.data.id  : ", runQualStopResposne.data.id)
                if (AuthService.isTokenValidCheck() === true) {
                getRunStatus(runQualStopResposne.data.id);
                }
            }
            this.props.showSnackbar({snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.info, snackbarMessage:'Run Stopped'})
        } else if(runQualStopError!== nextProps.runQualStopError){
            console.log("stop run qual error response: ", runQualStopError)
        }
        if(selectedRunPrgmResponse !== nextProps.selectedRunPrgmResponse){
            if(isNullResponse(selectedRunPrgmResponse?.data)) {
                console.log("selectedRunPrgmResponse  : ", selectedRunPrgmResponse.data) 
                this.setState({runProgramsList: selectedRunPrgmResponse.data?.programs})  
            }
        }else if(selecteRunPrgmError!==nextProps.selecteRunPrgmError){
            console.log("stop run qual error response: ", selecteRunPrgmError)
        }
    }
    componentWillUnmount(){
        console.log("progress line call back action taken: ",runInterval);
        if(runInterval){ clearInterval(runInterval); }
    }
    updateProgressStatus(currentStep, steps) {
        console.log("update progress status : ",currentStep);
        switch (currentStep) {
            case 0:
                if (steps[0].status === 'completed'|| steps[0].status ==='complete') {
                    this.setState({ qualProgress: 100 },()=>{
                        if(this.state.rundetailsCall){
                            this.props.controlRunDetails();
                            this.setState({rundetailsCall:false});
                        }
                    });
                }
                else{
                    this.setState({ qualProgress: 0 });
                }
                // this.setState({ dropdownEnable: false });
                break;
            case 1:
                if (steps[1].status === 'completed' || steps[1].status ==='complete') {
                    this.setState({ certifyProgress: 50 },()=>{
                        if(this.state.rundetailsCall){
                            this.props.controlRunDetails();
                            this.setState({rundetailsCall:false});
                        }
                    });
                }
                else{
                    this.setState({ certifyProgress: 0 });
                }
                // this.setState({ dropdownEnable: false });
                break;
            case 2:
                if (steps[2].status === 'completed' || steps[2].status ==='complete') {
                    this.setState({ certifyProgress: 100},()=>{
                        if(this.state.rundetailsCall){
                            this.props.controlRunDetails();
                            this.setState({rundetailsCall:false});
                        }
                    });
                }
                else{
                    if (steps[1].status === 'completed' || steps[1].status ==='complete') {
                        this.setState({ certifyProgress: 50, minimiseQualProgress: 100});
                    }else{
                        this.setState({ certifyProgress: 0, minimiseQualProgress: 0});
                    }
                }
                // this.setState({ dropdownEnable: false });
                break;
            case 3:
                if (steps[3].status === 'completed' || steps[3].status ==='complete') {
                    this.setState({ disburseProgress: 100 },()=>{
                        if(this.state.rundetailsCall){
                            this.props.controlRunDetails();
                            this.setState({rundetailsCall:false});
                        }
                    });
                }
                else{
                    this.setState({ disburseProgress: 0});
                }
                // this.setState({ dropdownEnable: false });
                break;
            case 4:
                if (steps[4].status === 'completed' || steps[4].status ==='complete') {
                    this.setState({ makeLiveProgress: 100 },()=>{
                        if(this.state.rundetailsCall){
                            this.props.controlRunDetails();
                            this.setState({rundetailsCall:false});
                        }
                    });
                }
                else{
                    this.setState({ makeLiveProgress: 0});
                }
                // this.setState({ dropdownEnable: false });
                break;
            case 5:
                if (steps[5].status === 'completed' || steps[5].status ==='complete') {
                    this.setState({ notifyProgress: 100 },()=>{
                        if(this.state.rundetailsCall){
                            this.props.controlRunDetails();
                            this.setState({rundetailsCall:false});
                        }
                    });
                    
                }
                else{
                    this.setState({ notifyProgress: 0});
                }
                break;
            case 6:
                if (steps[6].status === 'completed' || steps[6].status ==='complete') {
                    this.setState({ notifyProgress: 100 },()=>{
                        if(this.state.rundetailsCall){
                            this.props.controlRunDetails();
                            this.setState({rundetailsCall:false});
                        }
                    });
                }
                else{
                    this.setState({ notifyProgress: 0});
                }
                // this.setState({ dropdownEnable: false });
                break;
            case 7:
                if (steps[6].status !== 'pending'){
                    this.setState({ qualProgress: 100, certifyProgress: 100, disburseProgress: 100, makeLiveProgress: 100, notifyProgress: 50, notifyProgress: 100});
                    this.setState({ runQualDisabled: true});
                }
                break;
            default:
                return this.state;
        }

    }
    getNextStep() {
        return this.state.steps.findIndex((step) => {
            return (step.status.toLowerCase() === "pending");
        });
    }
    qualRunHandler = (runID, nextIndex)=> {
        console.log("commision details : ",runID,nextIndex);
        const { steps, statusUpdate } = this.state
        const {runDetails} = this.props
        let indexValue = null
        let stepModalBody1 = '', stepTitle = '', stepModalBody = '';
        this.setState({ undoStepAction:false },()=>{});
        switch (nextIndex) {
            case 0:
                indexValue = nextIndex;
                stepModalBody = 'Are you sure you want to initiate the ' + steps[0]?.name + ' process?'
                stepModalBody1 = 'Click Run to initiate the ' + runDetails?.qualificationProgram + ' program.'
                stepTitle = statusUpdate[0]?.title
                this.setState({ displayRunStatusModel: !this.state.displayRunStatusModel, nextIndexValue: indexValue,
                    runStatusTypeConfirmBody: stepModalBody,
                    runStatusTypeConfirmBody1: stepModalBody1,
                    runStatusTypeTitle: stepTitle,
                    okButtonText: 'Run'
                   });
            break;
            case 1:
                indexValue = nextIndex;
                stepModalBody = 'This will run the Calculation process.'
                stepModalBody1 = 'Calculation program: ' + runDetails?.calculationProgram 
                stepTitle = statusUpdate[1]?.title
                this.setState({ displayRunStatusModel: !this.state.displayRunStatusModel, nextIndexValue: indexValue,
                    runStatusTypeConfirmBody: stepModalBody,
                    runStatusTypeConfirmBody1: stepModalBody1,
                    runStatusTypeTitle: stepTitle,
                    okButtonText: 'Run'
                   });
            break;
            case 2:
                indexValue = nextIndex;
                stepModalBody = 'Certify ' + runDetails?.name +' ? '
                stepModalBody1 = 'Note: The qualification and calculation results cannot be changed after this step.'
                stepTitle = statusUpdate[2]?.title
                this.setState({ displayRunStatusModel: !this.state.displayRunStatusModel, nextIndexValue: indexValue,
                    runStatusTypeConfirmBody: stepModalBody,
                    runStatusTypeConfirmBody1: stepModalBody1,
                    runStatusTypeTitle: stepTitle,
                    okButtonText: 'Certify'
                   });
            break;
            case 3:
                indexValue = nextIndex;
                stepModalBody = 'Are you sure you want to initiate the ' + steps[3]?.name + ' process?'
                stepModalBody1 = 'Click Disburse to initiate the ' + runDetails?.disbursementProgram + ' program.'
                stepTitle =  statusUpdate[3]?.title
                this.setState({ displayRunStatusModel: !this.state.displayRunStatusModel, nextIndexValue: indexValue,
                    runStatusTypeConfirmBody: stepModalBody,
                    runStatusTypeConfirmBody1: stepModalBody1,
                    runStatusTypeTitle: stepTitle,
                    okButtonText: 'Disburse'
                   });
            break;
            case 4:
                indexValue = nextIndex;
                stepModalBody = 'The results of the commission run will be publish.'
                stepModalBody1 = 'Are you sure you want to publish ' +  runDetails?.name + ' ?'
                stepTitle = statusUpdate[4]?.title
                this.setState({ displayRunStatusModel: !this.state.displayRunStatusModel, nextIndexValue: indexValue,
                    runStatusTypeConfirmBody: stepModalBody,
                    runStatusTypeConfirmBody1: stepModalBody1,
                    runStatusTypeTitle: stepTitle,
                    okButtonText: 'Publish'
                   });
            break;
            case 5:
                indexValue = nextIndex;
                stepModalBody = 'The results of the commission run will be merged with the Accounts Receivable (AR).'
                stepModalBody1 = 'Are you sure you want to proceed?'
                stepTitle =   statusUpdate[5]?.title   
                this.setState({ displayRunStatusModel: !this.state.displayRunStatusModel, nextIndexValue: indexValue,
                    runStatusTypeConfirmBody: stepModalBody,
                    runStatusTypeConfirmBody1: stepModalBody1,
                    runStatusTypeTitle: stepTitle,
                    okButtonText: 'Run Merge'
                   });
            break;
            case 6:
                indexValue = nextIndex;
                stepModalBody = 'Un-merge will clear all the results of this run.'
                stepModalBody1 = 'Are you sure you want to un-merge the run?'
                stepTitle =  'Undo Merge AR'    
                this.setState({ displayUndoRunStatusModal: !this.state.displayUndoRunStatusModal, nextIndexValue: indexValue,
                    runStatusTypeConfirmBody: stepModalBody,
                    runStatusTypeConfirmBody1: stepModalBody1,
                    runStatusTypeTitle: stepTitle,
                    okButtonText: 'Un-Merge'
                   });
                this.setState({undoStepAction:true},()=>{});   
            break;
            default:
                break;
        }
        console.log("currentstep and statusupdate : ", this.state.nextStepState, this.state.statusUpdate);
    }
    dropdownRunHandler=(runID, nextIndex, dropdownItem)=>{
        console.log("commision details : ",runID,nextIndex);
        let indexValue = null
        const {runDetails} = this.props;
        this.setState({ dropdownSelectedItem:"", undoStepAction:false },()=>{});
        let stepModalBody1 = '', stepModalBody = ''
        switch (nextIndex) {
          case 0:
                indexValue = nextIndex;
                stepModalBody = 'Are you sure you want to initiate the Qualification and Calculation process?'
                stepModalBody1 = 'Click Run to initiate the ' + runDetails?.qualificationProgram + ' , ' + runDetails?.calculationProgram + ' programs.'
                this.setState({
                    displayRunStatusModel: !this.state.displayRunStatusModel, nextIndexValue: indexValue,
                    runStatusTypeConfirmBody: stepModalBody,
                    runStatusTypeConfirmBody1: stepModalBody1,
                    runStatusTypeTitle: 'Run Qualification & Calculation',
                    dropdownSelectedItem: dropdownItem,
                    okButtonText: 'Run',
                },()=>{});
            break;
          case 1:
              if(dropdownItem==="1"){
                indexValue=nextIndex;
                stepModalBody = 'Are you sure you want to initiate the Qualification process?'
                stepModalBody1 = 'Click Run to initiate the ' + runDetails?.qualificationProgram + ' programs.'
                this.setState({
                    displayRunStatusModel: !this.state.displayRunStatusModel, nextIndexValue: indexValue,
                    runStatusTypeConfirmBody: stepModalBody,
                    runStatusTypeConfirmBody1: stepModalBody1,
                    runStatusTypeTitle: 'Re-Run Qualification',
                    dropdownSelectedItem: dropdownItem,
                    okButtonText: 'Run',
                },()=>{});
              }else if(dropdownItem==="2") {
                indexValue=nextIndex;
                stepModalBody = 'Are you sure you want to initiate the Qualification and Calculation process?'
                stepModalBody1 = 'Click Run to initiate the ' + runDetails?.qualificationProgram + ' , ' + runDetails?.calculationProgram + ' programs.'
                this.setState({
                    displayRunStatusModel: !this.state.displayRunStatusModel, nextIndexValue: indexValue,
                    runStatusTypeConfirmBody: stepModalBody,
                    runStatusTypeConfirmBody1: stepModalBody1,
                    runStatusTypeTitle: 'Re-Run Qualification & Calculation',
                    dropdownSelectedItem: dropdownItem,
                    okButtonText: 'Run',
                },()=>{});
              }
            break;
          case 2:
            if(dropdownItem==="1"){
                indexValue=nextIndex;
                stepModalBody = 'Are you sure you want to initiate the Qualification  process?'
                stepModalBody1 = 'Click Run to initiate the ' + runDetails?.qualificationProgram + ' programs.'
                this.setState({
                    displayRunStatusModel: !this.state.displayRunStatusModel, nextIndexValue: indexValue,
                    runStatusTypeConfirmBody: stepModalBody,
                    runStatusTypeConfirmBody1: stepModalBody1,
                    runStatusTypeTitle: 'Re-Run Qualification',
                    dropdownSelectedItem: dropdownItem,
                    okButtonText: 'Run',
                },()=>{});
              }else {
                indexValue=nextIndex;
                stepModalBody = 'Are you sure you want to initiate the Qualification and Calculation process?'
                stepModalBody1 = 'Click Run to initiate the ' + runDetails?.qualificationProgram + ' , ' + runDetails?.calculationProgram + ' programs.'
                this.setState({
                    displayRunStatusModel: !this.state.displayRunStatusModel, nextIndexValue: indexValue,
                    runStatusTypeConfirmBody: stepModalBody,
                    runStatusTypeConfirmBody1: stepModalBody1,
                    runStatusTypeTitle: 'Re-Run Qualification & Calculation',
                    dropdownSelectedItem: dropdownItem,
                    okButtonText: 'Run',
                },()=>{});
              }
            break;
          case 3:
            indexValue=nextIndex;
            stepModalBody = 'The results from the current run will be lost and you will need to restart from the qual program.'
            stepModalBody1 = 'Are you sure you want to un-certify the run?'
            this.setState({
                displayUndoRunStatusModal: !this.state.displayUndoRunStatusModal, nextIndexValue: indexValue,
                runStatusTypeConfirmBody: stepModalBody,
                runStatusTypeConfirmBody1: stepModalBody1,
                runStatusTypeTitle: 'Decertify',
                dropdownSelectedItem: dropdownItem,
            },()=>{});
            this.setState({undoStepAction:true},()=>{});
            break;
          case 4:
            indexValue=nextIndex;
            stepModalBody = 'The results from the current run will be lost and you will need to restart from the qual program.'
            stepModalBody1 = 'Are you sure you want to un-certify/reset the run?'
            this.setState({
                displayUndoRunStatusModal: !this.state.displayUndoRunStatusModal, nextIndexValue: indexValue,
                runStatusTypeConfirmBody: stepModalBody,
                runStatusTypeConfirmBody1: stepModalBody1,
                runStatusTypeTitle: 'Decertify & Reset',
                dropdownSelectedItem: dropdownItem,
            },()=>{});
            this.setState({undoStepAction:true},()=>{});
            break;
          case 5:
            indexValue=nextIndex;
            stepModalBody = 'The run will be un-published and you will need to publish post-run the disburse program.'
            stepModalBody1 = 'Are you sure you want to un-publish ?'
            this.setState({
                displayUndoRunStatusModal: !this.state.displayUndoRunStatusModal, nextIndexValue: indexValue,
                runStatusTypeConfirmBody: stepModalBody,
                runStatusTypeConfirmBody1: stepModalBody1,
                runStatusTypeTitle: 'Undo Publish',
                dropdownSelectedItem: dropdownItem,
            },()=>{});
            this.setState({undoStepAction:true},()=>{});
            break;
          case 6:
            // indexValue=nextIndex;
            // stepModalBody = 'The results from the current run will be lost and you will need to restart from the qual program.'
            // stepModalBody1 = 'Are you sure you want to un-merge the run?'
            // this.setState({
            //     displayUndoRunStatusModal: !this.state.displayUndoRunStatusModal, nextIndexValue: indexValue,
            //     runStatusTypeConfirmBody: stepModalBody,
            //     runStatusTypeConfirmBody1: stepModalBody1,
            //     runStatusTypeTitle: 'Undo Merge AR',
            //     dropdownSelectedItem: dropdownItem,
            // },()=>{});
            break;

          default:
            break;
        }
        console.log("currentstep and statusupdate : ", this.state.nextStepState, this.state.statusUpdate);
    }
    handleUndoActions(runID,nextIndex) {
        this.setState({ displayUndoRunStatusModal: !this.state.displayUndoRunStatusModal });
        this.props.qualRunExecussion(runID, this.state.statusUpdate[nextIndex].undoServiceName);
    }
    handleNotify(runID, nextIndex){
        console.log("called handle Notify: ",runID,nextIndex)
    }
    truncateString(runName, index) {
        return runName.length > index ? runName.substring(0, index-1) + "..." : runName;
    }
    render() {
        const {
          dropdownEnable,
          statusUpdate,
          nextStepState,
          currentRunID,
          qualProgress,
          certifyProgress,
          disburseProgress,
          makeLiveProgress,
          notifyProgress,
          currentStep,
          updateRunStatus,
          enableUndoAction,
          displayRunStatusModel,
          runStatusType,
          runStatusTypeConfirmBody,
          runStatusTypeTitle,
          runStatusTypeConfirmBody1,
          displayCPModel,
          runQualDisabled,
          displayUndoRunStatusModal,
          showMinmizeUI,
          minimiseQualProgress,
          nextIndexValue,
          selectedStep,
          autoExecuteCalc,
          autoExecuteCalcStep,
          okButtonText,
          undoStepExecution,
          steps,
          undoStepAction,
          minimizeQualProgress,
          countText,
          isDisableRunStep
        } = this.state;
       
        const { commissionRunDetails, commissionRunId, runName} = this.props;
        let undotitle = nextStepState !== null && statusUpdate[nextStepState - 1]?.undoActionName !== undefined ? statusUpdate[nextStepState - 1]?.undoActionName : '';
        console.log("status result : ", statusUpdate[nextStepState]?.name);
        console.log("commission res: ",commissionRunDetails);
        return (
            <>
                <div className='progress-col-12'>
                    <div className='row col-10'>
                        <span className='run_status_styles'>{Constants.RUN_DETAILS_RUN_STATUS}</span><span className='run_status_details'>{updateRunStatus}</span>
                    </div>
                    <div className='undo-action-col-2 undo_rundetails_alignitems-center'>
                       {/* {enableUndoAction?<span className='undo-action-title' onClick={() => this.handleUndoActions(commissionRunId, nextStepState - 1)}>{nextStepState !== null && statusUpdate[nextStepState - 1]?.undoActionName !== undefined ? statusUpdate[nextStepState - 1]?.undoActionName : ''}</span>:''} */}
                    </div>
                </div>
                <div className="progress-col-12">
                    <div className=" col-10 row progress-container">
                        <div className="row progress-qual-root">
                            <div className='progress-item'>
                                <div>
                                {qualProgress>0? <img className="progress-dot" src={greenTick} alt='alt' />:<img className="progress-dot" src={progressdot} alt='alt' />}
                                </div>
                                <div className='col8 display-progress'>
                                    <ProgressBar percent={100} now={qualProgress} variant="success" />
                                </div>
                            </div>
                            <div>
                                <span className='progress-label'>Qualification</span>
                            </div>
                        </div>
                        <div className="row progress-calq-root">
                            <div className='progress-item'>
                                <div>
                                {certifyProgress>0? <img className="progress-dot" src={greenTick} alt='alt' />:<img className="progress-dot" src={progressdot} alt='alt' />}
                                </div>
                                <div className='display-progress certify-container'>
                                    <div className='certify-label-margintop'><span className='certify-label'>Certify</span></div>
                                    <div className="display-certify-paddingtop">
                                        <div className='display-certify'><ProgressBar percent={100} now={certifyProgress} variant="success" /></div>
                                        <div className="vl certify"></div>
                                    </div>

                                </div>
                            </div>
                            <div>
                                <span className='progress-label'>Calculation</span>
                            </div>
                        </div>
                        <div className="row progress-disburse-root">
                            <div className='progress-item'>
                                <div>
                                {disburseProgress>0? <img className="progress-dot" src={greenTick} alt='alt' />:<img className="progress-dot" src={progressdot} alt='alt' />}
                                </div>
                                <div className='display-progress'>
                                    <ProgressBar percent={100} now={disburseProgress} variant="success" />
                                </div>
                            </div>
                            <div>
                                <span className='progress-label' style={{paddingLeft: "26px"}}>Disburse</span>
                            </div>
                        </div>
                        <div className="row progress-makelive-root">
                            <div className='progress-item'>
                                <div>
                                {makeLiveProgress>0? <img className="progress-dot" src={greenTick} alt='alt' />:<img className="progress-dot" src={progressdot} alt='alt' />}
                                </div>
                                <div className='display-progress'>
                                    <ProgressBar percent={100} now={makeLiveProgress} variant="success" />
                                </div>
                            </div>
                            <div>
                                <span className='progress-label' style={{paddingLeft: "26px"}}>Publish</span>
                            </div>
                        </div>
                        <div className="row progress-margear-root">
                            <div className='progress-item'>
                                <div>
                                {notifyProgress>0? <img className="progress-dot" src={greenTick} alt='alt' />:<img className="progress-dot" src={progressdot} alt='alt' />}
                                </div>
                                <div className='progress-merge-display display-progress'>
                                    <ProgressBar percent={100} now={notifyProgress} variant="success" />
                                </div>
                                <div className='notify-block'>
                                    <div><span className='notify-display' onClick= {() => this.notifyStatusRun(commissionRunId)}>Complete</span></div>
                                   
                                    <div className="vl"></div>
                                </div>
                            </div>
                            <div>
                                <span className='progress-label'>Merge AR</span>
                            </div>
                        </div>
                    </div>
                    <div className="progress-col-2 rundetails_alignitems-center">
                        <Dropdown as={ButtonGroup} disabled={isDisableRunStep}>
                            <Button disabled={runQualDisabled} style={{}} className={runQualDisabled ? 'runQualdisabled' : (statusUpdate[nextStepState]?.dropdownOptionNames.length>0)  ? 'rundetails_button_title' : 'rundetails_only_button_title'} onClick={() => this.qualRunHandler(commissionRunId, nextStepState)} variant="success">
                                <span className="run_qual_title">{statusUpdate[nextStepState]?.name !== undefined ? statusUpdate[nextStepState]?.name : Constants.RUN_DETAILS_RUN_QUAL}</span>
                            </Button>

                            {dropdownEnable && statusUpdate[nextStepState]?.dropdownOptionNames.length>0? <>
                                <Dropdown.Toggle className='rundetails_dropdown_button' split variant="success" id="dropdown-split-basic" />
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => this.dropdownRunHandler(commissionRunId, nextStepState,"1")}>
                                        <span className="dropdown_run_qual_title">{statusUpdate[nextStepState]?.dropdownOptionNames !== undefined ? statusUpdate[nextStepState]?.dropdownOptionNames[0]?.name : Constants.BUTTON_TEXT_RUN_QUAL }</span>
                                        </Dropdown.Item>
                                        { statusUpdate[nextStepState]?.dropdownOptionNames?.length===2 && statusUpdate[nextStepState]?.dropdownOptionNames[1] ?
                                    <Dropdown.Item onClick={() => this.dropdownRunHandler(commissionRunId, nextStepState,"2")}>
                                        <span className="dropdown_run_qual_title">{statusUpdate[nextStepState]?.dropdownOptionNames !== undefined && statusUpdate[nextStepState]?.dropdownOptionNames[1] ? statusUpdate[nextStepState]?.dropdownOptionNames[1]?.name : Constants.BUTTON_TEXT_RUN_QUAL_CALC}</span>
                                        </Dropdown.Item>:''
                                    }
                                </Dropdown.Menu> </> : ''}
                        </Dropdown>
                    </div>
                    {displayRunStatusModel ? <ConfirmModel
                        title={runStatusTypeTitle}
                        bodyModelText={runStatusTypeConfirmBody}
                        bodyModelText1={runStatusTypeConfirmBody1}
                        enableModel={displayRunStatusModel}
                        okButtonTitle={okButtonText}
                        closeSelectedRun={this.closeSelectedRunProcessing.bind(this, runStatusType)}
                        selectRunStatus={this.disaplySelectedRunProcessing.bind(this, runStatusType)}
                    /> : ''}
                    {displayUndoRunStatusModal ? <UndoConfirmModal
                        title={undotitle !== '' ? undotitle : 'Confirm'}
                        bodyModelText={runStatusTypeConfirmBody}
                        bodyModelText1={runStatusTypeConfirmBody1}
                        enableModel={displayUndoRunStatusModal}
                        closeSelectedRun={this.closeSelectedUndoRunModal.bind(this, runStatusType)}
                        selectUndoRunStatus={this.disaplySelectedRunProcessing.bind(this, runStatusType)}
                    /> : ''}
                    
                    {displayCPModel ? <CommissionProcess
                        title={allNullTypeCheck(runStatusTypeTitle) ? runStatusTypeTitle : statusUpdate[currentStep]?.name }
                        enablecpmodel={displayCPModel}
                        closeModel={this.closeCommisisonProcessModal.bind(this)}
                        stopcommissionprocess={this.stopCommissionProcessing.bind(this, commissionRunId)}
                        continuecomprocess={this.continueCommissionProcessing.bind(this)}
                        selectedCurrentStep={selectedStep}
                        autoExecuteCalc={autoExecuteCalc}
                        undoStepExecution={undoStepExecution}
                        controlRunDetails ={()=> this.props.controlRunDetails() }
                    /> : ''}
                    <NewSnackbar/>
                </div>
               {showMinmizeUI ? 
               <div className="row fixed-bottom">
                        <div className="col-10">&nbsp;</div>
                        <div className='col-2 cm-minmize-style'>
                            <div className="cm-minmize-ui">
                                <div className="row" style={{ marginLeft: '10px' }}>
                                    <div className="col-7 cm-minmise-uititle-start" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                        {this.truncateString(runName, 15)}
                                        {/* {runName} */}
                                    </div>
                                    <div className="col-4 cm-minmise-uititle" onClick={this.continueCommissionProcessing.bind(this)} style={{ paddingLeft: '0px' }}>
                                        <img className="" src={externalLink} alt='alt' />
                                    </div>
                                    <div className="row cpm-min-text" >
                                        <div className="col-12 cpm-min-text">
                                            Processing : {this.truncateString(updateRunStatus, 15)}
                                        </div>
                                    </div>
                                    <div className="row" style={{ paddingLeft: '0px', marginTop: '5px' }}>
                                        <div className='display-progress'>
                                            <ProgressBar className='cpmin-progress' percent={100} now={minimizeQualProgress} variant="success" />
                                        </div>
                                        <div className="mincp-percent">
                                            {minimizeQualProgress}%
                                        </div>
                                        <div className='col-3'></div>
                                        <Tooltip title= {countText} placement="bottom" arrow={true}>
                                        <div className='col-9 records-count-text'>
                                        {countText}
                                        </div>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : ''}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        runQualResponse: state.commissionRunDetailsReducer.runQualResponse,
        runStatusResponse: state.commissionRunDetailsReducer.runStatusResponse,
        runStatusError: state.commissionRunDetailsReducer.runStatusError,
        runQualError: state.commissionRunDetailsReducer.runQualError,
        commissionRunDetails: state.commissionRunReducer.commissionRunDetails,
        runQualStopResposne: state.commissionRunDetailsReducer.runQualStopResposne,
        runQualStopError: state.commissionRunDetailsReducer.runQualStopError,
        runQualResetResposne: state.commissionRunDetailsReducer.runQualResetResposne,
        runQualResetError: state.commissionRunDetailsReducer.runQualResetError,
        selectedRunPrgmResponse: state.commissionRunDetailsReducer.selectedRunPrgmResponse,
        selecteRunPrgmError: state.commissionRunDetailsReducer.selecteRunPrgmError
    }
}

const mapDispatchToProps = (dispatch) => ({
    qualRunExecussion: (runID, serviceName, cachekey) => dispatch(qualRunExecussionAPICall(runID, serviceName, cachekey)),
    getRunStatus: (runID) => dispatch(getRunStatusAPICall(runID)),
    qualRunStop: (runID) => dispatch(qualRunStopAPICall(runID)),
    qualRunReset: (runID) => dispatch(qualRunResetAPICall(runID)),
    showSnackbar: (params) => dispatch({ type: actions.SNACKBAR_SHOW, payload: params}),
    getCurrentRunPrograms:(runID) => dispatch(getCurrentRunProgramsAPI(runID)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProgressLine))

