export const WAVIERS_DATA_FETCHING = 'WAVIERS_DATA_FETCHING';
export const WAVIERS_DATA_SUCCESS = 'WAVIERS_DATA_SUCCESS';
export const WAVIERS_DATA_ERROR = 'WAVIERS_DATA_ERROR';
  
  const initialState = {
    data: [],
    error: {},
    fetching: false,
    success: false
  }
  
  export default function waviersReducer (state, action) {
    if (typeof state === 'undefined') {
      return initialState
    }
    switch (action.type) {
      case WAVIERS_DATA_FETCHING:
        return { ...state, fetching: true }
      case WAVIERS_DATA_SUCCESS:
        return {
          ...state,
          fetching: false,
          success: true,
          data: action.payload
        }
      case WAVIERS_DATA_ERROR:
        return {
          ...state,
          fetching: false,
          success: false,
          data: action.payload
        }
  
      default:
        return state
    }
  }
  