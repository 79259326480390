// Function to convert the JSON(Array of objects) to CSV.
export const generateCSV = (JSONData, ReportTitle, showLabel) => {
  // Variable to store the final csv data
  var csv_data = [];
  // console.log("json data", JSONData[0].name )
  var nameCSV = JSONData[0].name + " , " + JSONData[1].name;
  // console.log("nameCsv", nameCSV);
  // Get each row data
  var rows = document.getElementsByTagName("tr");
  // console.log("headers", rows)
  for (var i = 0; i < rows.length; i++) {
    // Get each column data
    var cols = rows[i].querySelectorAll(
      "td,th, .comprun-firest-elment-name> div"
    );
    //console.log("headers", cols)
    // Stores each csv row data
    var csvrow = [];
    let displayVal = []
    for (var j = 0; j < cols.length; j++) {
      // Get the text data of each cell
      // of a row and push it to csvrow
     
      let tempText = ''
      console.log("cols data ", cols[j].innerText)
      if (cols[j].innerText.includes(",") && cols[j].innerText.includes("\n")) {
        tempText = cols[j].innerText;
        tempText = tempText.replaceAll(",", "");
        if (tempText.includes(nameCSV)){
          tempText= nameCSV;
        }
       console.log("temp Text", tempText);
        csvrow.push(tempText);
      } else {
        let textToDisplay = cols[j].innerText ;
       // console.log("enters to the else loop", textToDisplay) 
        if(!cols[j].innerText.includes("\n")){
          
          
        if (i > 1) {
          if (j > 1) {   
           // console.log(JSONData[0].name, JSONData[1].name, " jsondata  names")        
             // let val = j-1  
            
             
             if(textToDisplay.includes(",")){
              textToDisplay = textToDisplay.replaceAll(",", "");
             }
             displayVal.push(textToDisplay)
             console.log("array to diaply", displayVal)
            // console.log("textToDisplay",JSONData[0].name, JSONData[1].name, textToDisplay)
             if(displayVal[0].indexOf(JSONData[1].name) > -1){ 
              displayVal[0] = JSONData[1].name
              for(var idx = 0; idx < displayVal.length; idx++ ) {
             // console.log( JSONData[0].name, " jsondata O names", cols[j].innerText)           
              textToDisplay= ", "+ displayVal[idx] 
              }
             } else if (displayVal[0].indexOf(JSONData[0].name) > -1){
              displayVal[0] = JSONData[0].name
              for(var idx = 0; idx < displayVal.length; idx++ ) {
            //  console.log( JSONData[1].name, " jsondata 1 names", cols[j].innerText)
              textToDisplay= displayVal[idx] + ", "
              }
             } else {
              console.log("enters to the text in else loop", textToDisplay) 
               //textToDisplay= textToDisplay + ", "
             }
            
              console.log("enters to the text", textToDisplay)  
            }
           // csvrow.push(tempText);
          }
        }
        csvrow.push(textToDisplay);
      }
    
      if (i === 0) {
        if (j > 1) {
          csvrow.push(" ");
        }
      }

      for (var index in csvrow) {
       //  console.log("csvrow name", nameCSV, csvrow[index])
        // if (csvrow[index].includes(JSONData[0].name) ) {
        //   csvrow[index] = JSONData[0].name;
        // } else if( csvrow[index].includes(JSONData[1].name)) {
        //   csvrow[index] = JSONData[1].name;
        // } else if(csvrow[index].includes(JSONData[0].name)) {

        // }
        if (csvrow[index].includes("\n")) {
          if (csvrow[index].includes("%")) {
            csvrow[index] = csvrow[index].replace("\n", "");
          }
          csvrow[index] = csvrow[index].replace("\n", " ,");
        }
      }
    }
    if (csvrow.includes("Total")) {
      csvrow.unshift(" ");
    }

    // Combine each column value with comma
    csv_data.push(csvrow.join(","));
  }

  // Combine each row data with new line character
  csv_data = csv_data.join("\r\n");

  //Generate a file name
  var fileName = "MyReport_";
  //this will remove the blank-spaces from the title and replace it with an underscore
  fileName += ReportTitle.replace(/ /g, "_");

  //Initialize file format you want csv or xls
  var uri = "data:text/csv;charset=utf-8," + escape(csv_data);

  // Now the little tricky part.
  // you can use either>> window.open(uri);
  // but this will not work in some browsers
  // or you will not get the correct file extension

  //this trick will generate a temp <a /> tag
  var link = document.createElement("a");
  link.href = uri;

  //set the visibility hidden so it will not effect on your web-layout
  link.style = "visibility:hidden";
  link.download = fileName + ".csv";
  //this part will append the anchor tag and remove it after automatic click
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
// downloadCSVFile(csv_data);
export const generateMatrixCSV =(volMatrixData, fields, fileName) =>{
 
    console.log("came to download", volMatrixData)
    // Variable to store the final csv data
    // var array = typeof objArray != 'object' ? JSON.parse(volMatrixData) : volMatrixData;
    var str = '';
    var line = '';
    for (var index in fields) {
       var value = fields[index].displayName + "";
       line += '"' + value.replace(/"/g, '""') + '",';
    }
    line = line.slice(0, -1);
    str += line + '\r\n';
    
    for (var i = 0; i < volMatrixData.length; i++) {
      
        let val= volMatrixData[i]
        var line = '';
      
        for (var index in volMatrixData[i]) {
           var value = volMatrixData[i][index] + "";
          line += '"' + value.replace(/"/g, '""') + '",';

          console.log("line", line)
        }
      
      line = line.slice(0, -1);
      line= line.split(",")
      line.unshift(line[line.length-1]) 
      line.splice((line.length-1), 1) 
      line = line.join(",")
      console.log("final line", line)
      str += line + '\r\n';
    }
    
    
    //var fileName = fileName;
    //this will remove the blank-spaces from the title and replace it with an underscore
    //fileName += fileName.replace(/ /g, "_");
  
    //Initialize file format you want csv or xls
    var uri = "data:text/csv;charset=utf-8," + escape(str);
  
    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension
  
    //this trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;
  
    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";
    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
