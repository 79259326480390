import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {Oval} from "react-loader-spinner";
import { Modal } from "react-bootstrap";
import vector13 from '../../../assets/vector-13@2x.png';
import '../AddCompareRun/AddCompareModal.css'
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Radio from '@material-ui/core/Radio';
import { COMMISSION_SELECTED_RUN, getCommPreviousRunDetails } from "../actions";
import leftarrow from "../assets/leftarrow.svg";
import rightarrow from "../assets/rightarrow.svg";
import {getMonthDays}  from '../../utilities/Services'
import '../CommissionRunDetails/PreviousRunsQualModal.css'
import { allNullTypeCheck, isNullResponse } from "../commonComponents/UtilityFun";
import * as Constant from '../../../constants/Constants';
import actions from '../../../redux/actions/CommonDataActions';
import NewSnackbar from "../../utilities/NewSnackbar";
import Moment from 'moment';

const styles = theme => ({
    tableRow: {
     '&&:hover': {
        backgroundColor: '#0CB5F3',
      },
     },
  });
const fields = [
    { name: "Type", displayName: "Type", inputFilterable: true, sortable: true },
    {
        name: "name",
        displayName: "Name",
        inputFilterable: false,
        exactFilterable: true,
        sortable: true,
    },
    {
        name: "createdOn",
        displayName: "Created On",
        inputFilterable: false,
        exactFilterable: true,
        sortable: true,
    }

];

class PreviousRunsQualModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoaderResult: true,
            showRemoveColModal: true,
            hideModal: false,
            createdRun: null,
            currentRunDetails: '',
            showRunDetails: false,
            disableNewRun: true,
            commissionRunState: [],
            selectedCommissionType: 'Primary',
            commddionRunDataObj: [], 
            commissionPeriodData: null,
            commissionPeriods: [],
            selectedCommissionPeriodIndex: 0,
            rowstatus: false,
            selectedCommissionRun:'',
            runsWaitTime:1
        }
    }

    componentDidMount() {
        const {previousRunsArray} = this.props
        this.setState({ commddionRunDataObj: [] });
        if(allNullTypeCheck(previousRunsArray)){
            this.setState({showLoaderResult:true})
            previousRunsArray.map((data)=>{
              this.props.getcommissionRunDetails(data?.runId);
            })
          }
    }
    componentDidUpdate(nextProps) {
        const {commissionRunState, runsWaitTime} = this.state
        const {commissionPreviousRunDetails, commissionPreviousRunDetailsError, previousRunsArray} = this.props;
        if (commissionPreviousRunDetails !== nextProps.commissionPreviousRunDetails){
			this.setState({ showLoaderResult: false });
          if(!commissionPreviousRunDetails.hasOwnProperty('status')){ 
			if(isNullResponse(commissionPreviousRunDetails.data) && commissionPreviousRunDetails.error===false){
                console.log("comm run details", commissionPreviousRunDetails.data);
                commissionRunState.push(commissionPreviousRunDetails.data)
                this.setState({commissionRunState: commissionRunState})
		    } else if(Object.keys(commissionPreviousRunDetails?.data).length===0 && allNullTypeCheck(commissionPreviousRunDetails?.messages)) {
                if(commissionPreviousRunDetails?.messages?.length > 0 && commissionPreviousRunDetails?.messages[0]?.includes("Try again...")){
                    console.log("Try again block entered : ",commissionPreviousRunDetails?.data);
                    this.setState({showLoaderResult:true})
                    setTimeout(() => {
                        if(allNullTypeCheck(previousRunsArray)){
                            previousRunsArray.map((data)=>{
                              this.props.getcommissionRunDetails(data?.runId);
                            })
                          }
                    }, 1000 * runsWaitTime);
                    this.setState(prevState => {
                    return {runsWaitTime: prevState.runsWaitTime * 2}
                    })
                    }
            }
            }else {
                if(commissionPreviousRunDetails.hasOwnProperty('status') && commissionPreviousRunDetails.status===202){ 
                    this.setState({showLoaderResult:true})
                    setTimeout(() => {
                        if(allNullTypeCheck(previousRunsArray)){
                            previousRunsArray.map((data)=>{
                              this.props.getcommissionRunDetails(data?.runId);
                            })
                          }
                    }, 1000 * runsWaitTime);
                    this.setState(prevState => {
                    return {runsWaitTime: prevState.runsWaitTime * 2}
                    })
            }
          }
		} else if (commissionPreviousRunDetailsError !== nextProps.commissionPreviousRunDetailsError) {
            this.setState({ showLoaderResult: false });
            if(commissionPreviousRunDetailsError.error==true){
                this.props.showSnackbar({snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: typeof(commissionPreviousRunDetailsError?.messages[0])!=='string'? commissionPreviousRunDetailsError?.messages[0]?.code+' - '+commissionPreviousRunDetailsError?.messages[0]?.detail:commissionPreviousRunDetailsError?.messages[0]})
            }
        }
      }
    hideModal() {
        this.setState({ hideModal: true, showRemoveColModal: false })
        this.props.addCompareBackfunc()
    }
    confirmModal() {
        this.setState({ showLoaderResult: true })
        this.hideModal()
        this.props.selectedRun(this.state.selectedCommissionRun);
    }
    radioSelected(commissionRun, event) {
      console.log("add compare selected run : ",event.target.value,commissionRun.runId);
      // this.props.selectedRun(commissionRun.id);
        this.setState({rowstatus : event.target.value, disableNewRun: false,selectedCommissionRun:commissionRun.runId});
    }
    render() {
        let loader;
        const { showRemoveColModal, hideModal, showLoaderResult, disableNewRun, commissionRunState} = this.state;
        const {commissionPeriod} = this.props
        if (showLoaderResult) {
            loader = <div id="center" style={{ position: "fixed", bottom: "40%", left: "48%" }}>
                <Oval type="Puff" position="fixed"  secondaryColor="#E85557" color="#E85557" height={50} width={50} visible={true} duration={3000} /> 
            </div>
        }
        return (
            <div >
                <Modal dialogClassName="comparerun-my-modal" show={showRemoveColModal} onHide={hideModal}>
                    <Modal.Header>
                        <div className="addcmpr-flex-row-1-ei">
                            <div className="add-cmnr-header-name cmnr-h3-header-3-18pt-bold-lato">Available Commission Runs</div>
                            <img className="addcmpr-vector-ei" src={vector13} onClick={this.hideModal.bind(this)} alt="" />
                        </div>
                    </Modal.Header>
                    <Modal.Body style={{height: commissionRunState.length > 0 ?'': '450px'}}>
                      <div className="col">
                            <div className="row add-cmnr-runtype">
                                <div className="cm-type-title">Current Period</div>
                            </div>
                            <div className="row previousruns-type-select">
                                <div className="col-6">
                                    <div className="cd-text-temp" style={{textDecorationLine: 'none'}}>
                              <span
                                name="periodvalues"
                                variant="contained"
                              >
                                {commissionPeriod}
                              </span>
                            </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="row add-table-heading">Select a run from the list below to run qualification</div>
                                <TableContainer className="add-comparerun-model-container">
                                    <Table sx={{ minWidth: 450}} aria-label="simple table" >
                                        <TableHead>
                                            {fields.map((row) => (
                                                <TableCell
                                                    className="runmanager-table-header"
                                                    component="th"
                                                    scope="row"
                                                    style={{backgroundColor: '#DDDDDD'}}
                                                >
                                                    {row.displayName}
                                                </TableCell>
                                            ))}
                                        </TableHead>
                                        <TableBody>
                                            {commissionRunState.length > 0 
                                                ? commissionRunState.map(
                                                    (commissionrun, i) => (
                                                        <TableRow key={commissionrun.name} hover>
                                                            <TableCell  className="addcomparerun-data addcmpr-table">
                                                                <div className="row add-item-style">
                                                                <Radio
                                                                    value={commissionrun.name}
                                                                    defaultSelected={false}
                                                                    checked={commissionrun.name !== this.state.rowstatus ? false : true}
                                                                    onChange={this.radioSelected.bind(this, commissionrun)}
                                                                />
                                                                {commissionrun.runType === 'Analysis' ? 'A': 'P'}
                                                                </div>
                                                            </TableCell>
                                                            <TableCell className="addcmpr-runmanager-name addcmpr-table">
                                                                {commissionrun.name}
                                                            </TableCell>
                                                            <TableCell className="addcmpr-runmanager-name addcmpr-table">
                                                                {Moment(commissionrun.created).format('MM/DD/YYYY') !== 'Invalid date' ? Moment(commissionrun.created).format('MM/DD/YYYY') : ''}
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                )
                                                : (
                                                    <TableRow>
                                                      <TableCell
                                                        colSpan={4}
                                                        align="center"
                                                        height={260}
                                                        className="norunscell infotext-style"
                                                      >
                                                        <span>No previous runs available for this run</span>
                                                      </TableCell>
                                                    </TableRow>
                                                  )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="row">
                            <div disabled={disableNewRun} style={{ opacity: disableNewRun === true ? 0.3 : 1.0, backgroundColor: disableNewRun === true ? '#3333' : '#D93030', cursor: 'pointer'}} className="cmnr-create-run" onClick={this.confirmModal.bind(this)}>
                                <div className="add-run-type-btn" style={{ color: disableNewRun === true ? 'gray' : 'white'}}>OK</div>
                            </div>
                        </div>
                    </Modal.Footer>
                    {loader}
                    <NewSnackbar/>
                </Modal>
            </div>
        );                                            
    }
}
PreviousRunsQualModal.propTypes = {
    defaultvalues: PropTypes.any,
    addCompareBackfunc: PropTypes.func
}
const mapStateToProps = state => {
    return {
        commissionRunList: state.commissionRunReducer.commissionRunList,
        commissionPreviousRunDetails: state.commissionRunReducer.commissionPreviousRunDetails,
        commissionPreviousRunDetailsError : state.commissionRunReducer.commissionPreviousRunDetailsError,
    }
};
const mapDispatchToProps = dispatch => ({
    selectedRunIndex: (selectedRun) =>
      dispatch({ type: COMMISSION_SELECTED_RUN, payload: selectedRun }),
    getcommissionRunDetails: (runID) => dispatch(getCommPreviousRunDetails(runID)),
    showSnackbar: (params) => dispatch({ type: actions.SNACKBAR_SHOW, payload: params}),
})
export default connect(mapStateToProps, mapDispatchToProps)(PreviousRunsQualModal);