import { user as userActions } from '../actions/userDataActions';

// reducer with initial state
const initialState = {
  userDetails: {},
  userHasError: '',
  collaboratorsDetails: {},
  collaboratorsHasError: '',
  getCollaboratorsDetails: {},
  getCollaboratorsHasError: '',
  deleteCollaboratorsDetails: {},
  deleteCollaboratorsHasError: '',
  postCollaboratorsDetails: {},
  postCollaboratorsHasError: '',
  inviteUserHasError: '',
  inviteUserResponse: {},
  getUserInfo: {},
  getUserError: '',
  confirmEmailResponse: {},
  confirmEmailHasError: '',
  resetEmailResponse: {},
  resetEmailHasError: '',
  editUserResponse: {},
  editUserError: '',
  resendInviteResponse: [],
  resendInviteError: '',
  userProfileResponse: {},
  userProfileError: '',
  editProfileResponse: {},
  editProfileError: '',
  deleteUserResponse: {},
  deleteUserError: '',
  userRolesResponse: {},
  userRolesError: ''


};

export default function userDataReducer(state, action) {
  if (typeof state === 'undefined') {
    return initialState;
  }
  switch (action.type) {
    case userActions.USERLIST_SUCCESS:
      return { ...state, userDetails: action.payload };
    case userActions.USERLIST_HASERROR:
      return { ...state, userHasError: action.payload };
    case userActions.INVITEUSER_SUCCESS:
      return { ...state, inviteUserResponse: action.payload };
    case userActions.INVITEUSER_HASERROR:
      return { ...state, inviteUserHasError: action.payload };
    case userActions.GETUSER_SUCCESS:
      return { ...state, getUserInfo: action.payload };
    case userActions.GETUSER_HASERROR:
      return { ...state, getUserError: action.payload };
    case userActions.CONFIRMEMAIL_SUCCESS:
      return { ...state, confirmEmailResponse: action.payload };
    case userActions.CONFIRMEMAIL_HASERROR:
      return { ...state, confirmEmailHasError: action.payload };
    case userActions.EDITUSER_SUCCESS:
      return { ...state, editUserResponse: action.payload };
    case userActions.EDITUSER_HASERROR:
      return { ...state, editUserError: action.payload };
    case userActions.RESENDINVITE_SUCCESS:
      return { ...state, resendInviteResponse: action.payload };
    case userActions.RESENDINVITE_HASERROR:
      return { ...state, resendInviteError: action.payload };
    case userActions.RESETMAIL_SUCCESS:
      return { ...state, resetEmailResponse: action.payload };
    case userActions.RESETMAIL_HASERROR:
      return { ...state, resetEmailHasError: action.payload };
    case userActions.GETUSER_PROFILE_SUCCESS:
      return { ...state, userProfileResponse: action.payload };
    case userActions.GETUSER_PROFILE_HASERROR:
      return { ...state, userProfileError: action.payload };
    case userActions.EDIT_PROFILE_SUCCESS:
      return { ...state, editProfileResponse: action.payload };
    case userActions.EDIT_PROFILE_HASERROR:
      return { ...state, editProfileError: action.payload };
    case userActions.DELETE_USER_SUCCESS:
      return { ...state, deleteUserResponse: action.payload };
    case userActions.DELETE_USER_HASERROR:
      return { ...state, deleteUserError: action.payload };
    case userActions.GETUSER_ROLES_SUCCESS:
      return { ...state, userRolesResponse: action.payload };
    case userActions.GETUSER_ROLES_HASERROR:
      return { ...state, userRolesError: action.payload };
    case userActions.COLLABORATORSLIST_SUCCESS:
      return { ...state, collaboratorsDetails: action.payload };
    case userActions.COLLABORATORSLIST_HASERROR:
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('collaborators Network error :', { ...state, collaboratorsHasError: action.payload }); }
      return { ...state, collaboratorsHasError: action.payload };
    case userActions.GET_COLLABORATORS_SUCCESS:
      return { ...state, getCollaboratorsDetails: action.payload };
    case userActions.GET_COLLABORATORS_HASERROR:
      return { ...state, getCollaboratorsHasError: action.payload };
    case userActions.REMOVE_COLLABORATORS_SUCCESS:
      return { ...state, deleteCollaboratorsDetails: action.payload };
    case userActions.REMOVE_COLLABORATORS_HASERROR:
      return { ...state, deleteCollaboratorsHasError: action.payload };
    case userActions.POST_COLLABORATORS_SUCCESS:
      return { ...state, postCollaboratorsDetails: action.payload };
    case userActions.POST_COLLABORATORS_HASERROR:
      return { ...state, postCollaboratorsHasError: action.payload };
    default:
      return state;
  }
}