import {
  PERIODS_FETCHING,
  PERIODS_SUCCESS,
  PERIODS_ERROR
} from '../actions'

const initialState = {
  data: {data:{types:[]}},
  error: {},
  fetching: false,
  success: false
}

export default function periodsReducer (state, action) {
  if (typeof state === 'undefined') {
    return initialState
  }
  switch (action.type) {
    case PERIODS_FETCHING:
      return { ...state, fetching: true }
    case PERIODS_SUCCESS:
      return { ...state, fetching: false, success: true, data: action.payload }
    case PERIODS_ERROR:
      return {
        ...state,
        fetching: false,
        success: false,
        error: action.payload
      }
    default:
      return state
  }
}
