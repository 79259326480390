const actions = { 
    SHOW_CREATE_INTEGRATIONS:'SHOW_CREATE_INTEGRATIONS',
    HIDE_CREATE_INTEGRATIONS : 'HIDE_CREATE_INTEGRATIONS',
    SHOW_INTEGRATIONS_NAME: 'SHOW_INTEGRATIONS_NAME',
    HIDE_INTEGRATIONS_NAME : 'HIDE_INTEGRATIONS_NAME',
    SHOW_COMPLETE_INTEGRATIONS:'SHOW_COMPLETE_INTEGRATIONS',
    HIDE_COMPLETE_INTEGRATIONS : 'HIDE_COMPLETE_INTEGRATIONS',
    SHOW_TOP_INTEGRATIONS:'SHOW_TOP_INTEGRATIONS',
    HIDE_TOP_INTEGRATIONS : 'HIDE_TOP_INTEGRATIONS',
    SHOW_COMPLETE_SUMMARY_INTEGRATIONS:'SHOW_COMPLETE_SUMMARY_INTEGRATIONS',
    HIDE_COMPLETE_SUMMARY_INTEGRATIONS : 'HIDE_COMPLETE_SUMMARY_INTEGRATIONS',
    SHOW_SUMMARY_ITEM: 'SHOW_SUMMARY_ITEM',
    HIDE_SUMMARY_ITEM: 'HIDE_SUMMARY_ITEM',
    SHOW_EDITINTEGRATION_ITEM: 'SHOW_EDITINTEGRATION_ITEM',
    HIDE_EDITINTEGRATION_ITEM: 'HIDE_EDITINTEGRATION_ITEM',
    SHOW_INTEGRAION_ITEM: 'SHOW_INTEGRAION_ITEM',
    HIDE_INTEGRAION_ITEM: 'HIDE_INTEGRAION_ITEM',
    SHOW_AUTHTYPE: 'SHOW_AUTHTYPE',
    HIDE_AUTHTYPE: 'HIDE_AUTHTYPE'
  };
  export default actions;