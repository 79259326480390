import React from "react";
// import addIcon from '../../assets/samplecme.svg';
import AuthService from '../../../../src/components/server/AuthService';
// import '../CommissionsRunManager/CommissionRunManager.css'
import {Oval} from 'react-loader-spinner';
import { withRouter } from 'react-router-dom';
import Component2 from "../Component2";
import './CommissionTools.css'
import compareruns from "../assets/compareruns.svg";
import searchdistributor from "../assets/searchdistributor.svg";
import DistResearchModel from "./DistResearchModel";

class CommissionTools extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isResearchModelDisplay:false,
    }
  }
  componentDidMount() {

  }
  compareRunClick() {
    this.props.history.push({
      pathname: "/commissiontools/comparerun",
    });
  }
  handleDistributor(){
    this.setState({isResearchModelDisplay:!this.state.isResearchModelDisplay});
  }
  handleClose(){
    this.setState({isResearchModelDisplay:!this.state.isResearchModelDisplay})
  }
  
  render() {
    const { isResearchModelDisplay } = this.state;
    if (AuthService.isAuthenticated()) {
      return (
        <div className="row com-overlap-group-1">
          <div className="col-8" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <Component2 mainText='Commissions Tools' typeText={''} />
            <div className="rectangle-255"></div>
            <div className="flex">
              <div className="col-8 cmt-title">Commissions Tools</div>
              <div className="row" style={{ marginTop: '10px' }}>
                <div className="col-9">
                  <div>
                  <img
                    className=""
                    src={searchdistributor}
                    style = {{cursor:"pointer"}}
                    alt="" onClick={this.handleDistributor.bind(this)}
                  />
                  <img
                    className=""
                    src={compareruns}
                    style = {{cursor:"pointer"}}
                    alt="" onClick={this.compareRunClick.bind(this)}
                  />
                  </div>
                </div>
                </div>
            </div>
            {isResearchModelDisplay?<DistResearchModel
              handleClose={this.handleClose.bind(this)}
            
            />:''}
          </div>
        </div>
      );
    }
    return <div><div id="center" style={{ position: 'fixed', top: '40%', left: '48%' }}>
      <Oval type="Puff" position="fixed"  secondaryColor="#E85557" color="#E85557" height={50} width={100} visible={true} /> </div></div>;
  }
}

export default withRouter(CommissionTools);