import AuthService from '../../components/server/AuthService';
import { singletonAxiosInstance } from '../../components/utilities/Server';
import Endpoints from '../../components/utilities/Endpoints';
import {integration as integrationActions} from '../actions/integrationDataActions';


const server = singletonAxiosInstance;
const options = {
  Authorization: 'Bearer ' + localStorage.getItem('id_token')
    
};
const statCodes = {
  SUCCESS: 200,
  CREATE_SUCCESS:201,
  unAuthorized: 403
};
export const getIntegrationsList=()=>{
  return dispatch => {      
    if(AuthService.isTokenValid()===true){
      server.get(Endpoints.integration, '', options,{timeout:20000}).then((data) => {     
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('calling integration list endpoint',Endpoints.integration);}
        if (data.status === statCodes.SUCCESS) {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log("integration redux thunk response :",data.data); }
            dispatch({type:integrationActions.INTEGRATIONS_SUCCESS,payload:data.data});
          }else{
            dispatch({type:integrationActions.INTEGRATIONS_HASERROR,payload:data});
          }
      }).catch((error) => {
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}       
        if(!error.response){
          dispatch({type:integrationActions.INTEGRATIONS_HASERROR,payload:error});            
        }else{
          dispatch({type:integrationActions.INTEGRATIONS_HASERROR,payload:error.response});            
        }
       
      });
    }else{
      const user = AuthService.signinSilentToken();
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log('signinSilentToken user :',user);}
      if(user){
       localStorage.setItem("id_token", user.id_token);        
       server.get(Endpoints.integration, "", options,{timeout:10000}).then((data) => {     
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log("calling integration list endpoint",Endpoints.integration); }       
        if (data.status === statCodes.SUCCESS) {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log("integration redux thunk response :",data.data); }
            dispatch({type:integrationActions.INTEGRATIONS_SUCCESS,payload:data.data});
          }else{
            dispatch({type:integrationActions.INTEGRATIONS_HASERROR,payload:data});
          }
        }).catch((error) => {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}       
          if(!error.response){
            dispatch({type:integrationActions.INTEGRATIONS_HASERROR,payload:error});            
          }else{
            dispatch({type:integrationActions.INTEGRATIONS_HASERROR,payload:error.response});            
          }
        });
      }else{
        AuthService.loginPage();
      }
    }     
  };
};

export const getIntegrationAPI=(integrationID)=>{
  return dispatch => {      
    if(AuthService.isTokenValid()===true){
      server.get(Endpoints.integration+'/'+integrationID, '', options).then((data) => {     
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log('calling get integration',Endpoints.integration); }        
        if (data.status === statCodes.SUCCESS) {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log("integration redux thunk response :",data.data); }
            dispatch({type:integrationActions.GETINTEGRATION_SUCCESS,payload:data.data});
          }else{
            dispatch({type:integrationActions.GETINTEGRATION_HASERROR,payload:data});
          }
      }).catch((error) => {
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}       
        if(!error.response){
          dispatch({type:integrationActions.GETINTEGRATION_HASERROR,payload:error});            
        }else{
          dispatch({type:integrationActions.GETINTEGRATION_HASERROR,payload:error.response});            
        }
        
      });
    }else{
      const user = AuthService.signinSilentToken();
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log('signinSilentToken user :',user);}
      if(user){
        localStorage.setItem('id_token', user.id_token);        
        server.get(Endpoints.integration+'/'+integrationID, '', options).then((data) => {     
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log('calling integration list endpoint',Endpoints.integration); }       
          if (data.status === statCodes.SUCCESS) {
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log('integration redux thunk response :',data.data); }
            dispatch({type:integrationActions.GETINTEGRATION_SUCCESS,payload:data.data});
          }else{
            dispatch({type:integrationActions.GETINTEGRATION_HASERROR,payload:data});
          }
        }).catch((error) => {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}        
          if(!error.response){
            dispatch({type:integrationActions.GETINTEGRATION_HASERROR,payload:error});            
          }else{
            dispatch({type:integrationActions.GETINTEGRATION_HASERROR,payload:error.response});            
          }           
        });
      }else{
        AuthService.loginPage();
      }
    }     
  };
};



export const postEndPointValidation=(params)=>{
  return dispatch => {    
    if(AuthService.isTokenValid()===true){
      server.post(Endpoints.endpointValidation,'', params, options).then((data) => {                          
        if (data.status === statCodes.SUCCESS) {   
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('EndPoint validation res :',data); }        
          dispatch({type:integrationActions.ENDPOINTRESULT_SUCCESS,payload:data.data});
        }else{
          dispatch({type:integrationActions.ENDPOINTRESULT_HASERROR,payload:data});
        }
      }).catch((error) => {
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log('Catch Error : ', error);}         
        if(!error.response){
          dispatch({type:integrationActions.ENDPOINTRESULT_HASERROR,payload:error}); 
        }else{
          dispatch({type:integrationActions.ENDPOINTRESULT_HASERROR,payload:error.response}); 
        }                  
      });
    }else{
      const user = AuthService.signinSilentToken();
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log('signinSilentToken user :',user);}
      if(user){
        localStorage.setItem('id_token', user.id_token); 
        server.post(Endpoints.endpointPost,'', params, options).then((data) => {                      
          if (data.status === statCodes.SUCCESS) {   
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log('endpoint validation res :',data.data);}         
            dispatch({type:integrationActions.ENDPOINTRESULT_SUCCESS,payload:data.data});
          }else{
            dispatch({type:integrationActions.ENDPOINTRESULT_HASERROR,payload:data});
          }
        }).catch((error) => {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log('Catch Error : ', error);}        
          if(!error.response){
            dispatch({type:integrationActions.ENDPOINTRESULT_HASERROR,payload:error}); 
          }else{
            dispatch({type:integrationActions.ENDPOINTRESULT_HASERROR,payload:error.response}); 
          }             
        });
      }       
    }   
  };  
};

export const postCallBackUrlValidation=(params)=>{
  return dispatch => {    
    if(AuthService.isTokenValid()===true){
      server.post(Endpoints.endpointPost,'', params, options).then((data) => {                          
        if (data.status === statCodes.SUCCESS) {   
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log('callback validation res :',data);}         
          dispatch({type:integrationActions.CALLBACKURL_SUCCESS,payload:data.data});
        }else{
          dispatch({type:integrationActions.CALLBACKURL_HASERROR,payload:data});
        }
      }).catch((error) => {
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log('Catch Error : ', error.response);}         
        dispatch({type:integrationActions.CALLBACKURL_HASERROR,payload:error.response});            
      });
    }else{
      const user = AuthService.signinSilentToken();
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('signinSilentToken user :',user);}
      if(user){
        localStorage.setItem('id_token', user.id_token); 
        server.post(Endpoints.endpointPost,'', params, options).then((data) => {                      
          if (data.status === statCodes.SUCCESS) {   
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log('callback validation res :',data.data);}
            dispatch({type:integrationActions.CALLBACKURL_SUCCESS,payload:data.data});
          }else{
            dispatch({type:integrationActions.CALLBACKURL_HASERROR,payload:data});
          }
        }).catch((error) => {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error.response);}
          dispatch({type:integrationActions.CALLBACKURL_HASERROR,payload:error.response});            
        });
      }       
    }   
  };
};
export const getEventTypesList=()=>{
  return dispatch => {      
    if(AuthService.isTokenValid()===true){
      server.get(Endpoints.eventTypes, '', options).then((data) => {     
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('calling eventypes list endpoint',Endpoints.eventTypes);}
        if (data.status === statCodes.SUCCESS) {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log("integration redux thunk response :",data.data); }
            dispatch({type:integrationActions.EVENTSTYPELIST_SUCCESS,payload:data.data});                
          }else{
            dispatch({type:integrationActions.EVENTSTYPELIST_HASERROR,payload:data});
          }
      }).catch((error) => {
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}          
        dispatch({type:integrationActions.EVENTSTYPELIST_HASERROR,payload:error.response});            
      });
    }else{
      const user = AuthService.signinSilentToken();
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('signinSilentToken user :',user);}
      if(user){
       localStorage.setItem("id_token", user.id_token);        
       server.get(Endpoints.eventTypes, "", options).then((data) => {     
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log("calling eventtypes list endpoint",Endpoints.eventTypes);}
        if (data.status === statCodes.SUCCESS) {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log("integration redux thunk response :",data.data); }
            dispatch({type:integrationActions.EVENTSTYPELIST_SUCCESS,payload:data.data});              
          }else{
            dispatch({type:integrationActions.EVENTSTYPELIST_HASERROR,payload:data});
          }
        }).catch((error) => {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}          
          dispatch({type:integrationActions.EVENTSTYPELIST_HASERROR,payload:error.response});            
        });
      }else{
        AuthService.loginPage();
      }
    }     
  };
};

export const postCreateIntegration=(params)=>{
  return dispatch => {    
    if(AuthService.isTokenValid()===true){
      server.post(Endpoints.integration,'', params, options).then((data) => {                          
        if (data.status === statCodes.SUCCESS) {   
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('CreateIntegration res :',data);}
          dispatch({type:integrationActions.CREATEINTEGRATION_SUCCESS,payload:data.data});
        }else{
          dispatch({type:integrationActions.CREATEINTEGRATION_HASERROR,payload:data});
        }
      }).catch((error) => {
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}
        if(!error.response){
          dispatch({type:integrationActions.CREATEINTEGRATION_HASERROR,payload:error}); 
        }else{
          dispatch({type:integrationActions.CREATEINTEGRATION_HASERROR,payload:error.response}); 
        }
                   
      });
    }else{
      const user = AuthService.signinSilentToken();
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('signinSilentToken user :',user);}
      if(user){
        localStorage.setItem('id_token', user.id_token); 
        server.post(Endpoints.integration,'', params, options).then((data) => {                      
          if (data.status === statCodes.SUCCESS) {   
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('CreateIntegration res :',data.data);}
            dispatch({type:integrationActions.CREATEINTEGRATION_SUCCESS,payload:data.data});
          }else{
            dispatch({type:integrationActions.CREATEINTEGRATION_HASERROR,payload:data});
          }
        }).catch((error) => {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}
          if(!error.response){
            dispatch({type:integrationActions.CREATEINTEGRATION_HASERROR,payload:error}); 
          }else{
            dispatch({type:integrationActions.CREATEINTEGRATION_HASERROR,payload:error.response}); 
          }            
        });
      }       
    }   
  };
};

export const postCreateWebhook=(params)=>{
  return dispatch => {    
    if(AuthService.isTokenValid()===true){
      server.post(Endpoints.webhook,'', params, options).then((data) => {                          
        if (data.status === statCodes.SUCCESS) {   
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Createwebhook res :',data);}
          dispatch({type:integrationActions.WEBHOOK_SUCCESS,payload:data.data});
        }else{
          dispatch({type:integrationActions.WEBHOOK_HASERROR,payload:data});
        }
      }).catch((error) => {
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error.response);}
        dispatch({type:integrationActions.WEBHOOK_HASERROR,payload:error.response});            
      });
    }else{
      const user = AuthService.signinSilentToken();
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('signinSilentToken user :',user);}
      if(user){
        localStorage.setItem('id_token', user.id_token); 
        server.post(Endpoints.webhook,'', params, options).then((data) => {                      
          if (data.status === statCodes.SUCCESS) {   
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('CreateWebhook res :',data.data);}
            dispatch({type:integrationActions.WEBHOOK_SUCCESS,payload:data.data});
          }else{
            dispatch({type:integrationActions.WEBHOOK_HASERROR,payload:data});
          }
        }).catch((error) => {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error.response);}
          dispatch({type:integrationActions.WEBHOOK_HASERROR,payload:error.response});            
        });
      }       
    }   
  };
};


export const putIntegrationStatus=(integrationID,params)=>{
  return dispatch => {      
    if(AuthService.isTokenValid()===true){
      server.put(Endpoints.integrationStatus+'/'+integrationID, params, options).then((data) => {     
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('calling EditintegrationStatus list endpoint',Endpoints.integrationStatus);}
        if (data.status === statCodes.SUCCESS) {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('integration redux thunk response :',data.data); }
          dispatch({type:integrationActions.INTEGRATION_STATUS_SUCCESS,payload:data.data});
        }else{
          dispatch({type:integrationActions.INTEGRATION_STATUS_HASERROR,payload:data});
        }
      }).catch((error) => {
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}       
        dispatch({type:integrationActions.INTEGRATION_STATUS_HASERROR,payload:error.response});            
      });
    }else{
      const user = AuthService.signinSilentToken();
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('signinSilentToken user :',user);}
      if(user){
        localStorage.setItem('id_token', user.id_token);        
        server.put(Endpoints.integrationStatus+'/'+integrationID, params, options).then((data) => {     
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('calling editIntegrationStatus list endpoint',Endpoints.integrationStatus);}
          if (data.status === statCodes.SUCCESS) {
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('integration redux thunk response :',data.data); }
            dispatch({type:integrationActions.INTEGRATION_STATUS_SUCCESS,payload:data.data});
          }else{
            dispatch({type:integrationActions.INTEGRATION_STATUS_HASERROR,payload:data});
          }
        }).catch((error) => {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}        
          dispatch({type:integrationActions.INTEGRATION_STATUS_HASERROR,payload:error.response});            
        });
      }else{
        AuthService.loginPage();
      }
    }     
  };
};

export const deleteIntegrationAPICall=(integrationID,params)=>{  
  return dispatch => {      
    if(AuthService.isTokenValid()===true){
      server.delete(Endpoints.deleteIntegration+'/'+integrationID, params, options).then((data) => {     
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('calling DeleteIntegrationStatus  endpoint',Endpoints.integrationStatus);}
        if (data.status === statCodes.SUCCESS) {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('integration redux thunk response :',data.data); }
          dispatch({type:integrationActions.INTEGRATION_DELETE_SUCCESS,payload:data.data});
        }else{
          dispatch({type:integrationActions.INTEGRATION_DELETE_HASERROR,payload:data});
        }
      }).catch((error) => {
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}       
        dispatch({type:integrationActions.INTEGRATION_DELETE_HASERROR,payload:error.response});            
      });
    }else{
      const user = AuthService.signinSilentToken();
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('signinSilentToken user :',user);}
      if(user){
        localStorage.setItem('id_token', user.id_token);        
        server.delete(Endpoints.deleteIntegration+'/'+integrationID, params, options).then((data) => {     
          if (data.status === statCodes.SUCCESS) {
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('integration redux thunk response :',data.data); }
            dispatch({type:integrationActions.INTEGRATION_DELETE_SUCCESS,payload:data.data});
          }else{
            dispatch({type:integrationActions.INTEGRATION_DELETE_HASERROR,payload:data});
          }
        }).catch((error) => {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}        
          dispatch({type:integrationActions.INTEGRATION_DELETE_HASERROR,payload:error.response});            
        });
      }else{
        AuthService.loginPage();
      }
    }     
  };
};

export const getOptionalFeatures=()=>{
  return dispatch => {      
    if(AuthService.isTokenValid()===true){
      server.get(Endpoints.optionalFeatures, '', options).then((data) => {     
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('calling optionalfeatures list endpoint',Endpoints.eventTypes);}
        if (data.status === statCodes.SUCCESS) {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log("integration redux thunk response :",data.data); }
            dispatch({type:integrationActions.OPTIONAL_FEATURE_SUCCESS,payload:data.data});                
          }else{
            dispatch({type:integrationActions.OPTIONAL_FEATURE_HASERROR,payload:data});
          }
      }).catch((error) => {
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}          
        if(!error.response){
          dispatch({type:integrationActions.OPTIONAL_FEATURE_HASERROR,payload:error});  
        }else{
          dispatch({type:integrationActions.OPTIONAL_FEATURE_HASERROR,payload:error.response});  
        }                  
      });
    }else{
      const user = AuthService.signinSilentToken();
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('signinSilentToken user :',user);}
      if(user){
       localStorage.setItem("id_token", user.id_token);        
       server.get(Endpoints.optionalFeatures, "", options).then((data) => {     
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log("calling optionalFeatures list endpoint",Endpoints.eventTypes);}
        if (data.status === statCodes.SUCCESS) {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log("integration redux thunk response :",data.data); }
            dispatch({type:integrationActions.OPTIONAL_FEATURE_SUCCESS,payload:data.data});              
          }else{
            dispatch({type:integrationActions.OPTIONAL_FEATURE_HASERROR,payload:data});
          }
        }).catch((error) => {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}          
          if(!error.response){
            dispatch({type:integrationActions.OPTIONAL_FEATURE_HASERROR,payload:error});  
          }else{
            dispatch({type:integrationActions.OPTIONAL_FEATURE_HASERROR,payload:error.response});  
          }           
        });
      }else{
        AuthService.loginPage();
      }
    }     
  };
};


export const postOptionalFeatures=(params)=>{
  return dispatch => {    
    if(AuthService.isTokenValid()===true){
      server.post(Endpoints.optionalFeaturesPost,'', params, options).then((data) => {                          
        if (data.status === statCodes.SUCCESS) {   
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('CreateOptionalFeatures res :',data);}
          dispatch({type:integrationActions.FEATURES_POST_SUCCESS,payload:data.data});
        }else{
          dispatch({type:integrationActions.FEATURES_POST_HASERROR,payload:data});
        }
      }).catch((error) => {
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}
        if(!error.response){
          dispatch({type:integrationActions.FEATURES_POST_HASERROR,payload:error});            
        }else{
          dispatch({type:integrationActions.FEATURES_POST_HASERROR,payload:error.response});            
        }
        
      });
    }else{
      const user = AuthService.signinSilentToken();
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('signinSilentToken user :',user);}
      if(user){
        localStorage.setItem('id_token', user.id_token); 
        server.post(Endpoints.optionalFeaturesPost,'', params, options).then((data) => {                      
          if (data.status === statCodes.SUCCESS) {   
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('CreateOptionalFeatures res :',data.data);}
            dispatch({type:integrationActions.FEATURES_POST_SUCCESS,payload:data.data});
          }else{
            dispatch({type:integrationActions.FEATURES_POST_HASERROR,payload:data});
          }
        }).catch((error) => {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}
          if(!error.response){
            dispatch({type:integrationActions.FEATURES_POST_HASERROR,payload:error});            
          }else{
            dispatch({type:integrationActions.FEATURES_POST_HASERROR,payload:error.response});            
          }           
        });
      }       
    }   
  };
};

export const getGenerateKeyAPI=()=>{
  return dispatch => {      
    if(AuthService.isTokenValid()===true){
      server.get(Endpoints.generateKey, '', options).then((data) => {     
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('calling generateKey endpoint',Endpoints.generateKey);}
        if (data.status === statCodes.SUCCESS) {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log("integration redux thunk response :",data.data); }
            dispatch({type:integrationActions.GENERATEKEY_SUCCESS,payload:data.data});                
          }else{
            dispatch({type:integrationActions.GENERATEKAY_HASERROR,payload:data});
          }
      }).catch((error) => {
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}          
        if(!error.response){
          dispatch({type:integrationActions.GENERATEKAY_HASERROR,payload:error});
        }else{
          dispatch({type:integrationActions.GENERATEKAY_HASERROR,payload:error.response});
        }
                    
      });
    }else{
      const user = AuthService.signinSilentToken();
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('signinSilentToken user :',user);}
      if(user){
       localStorage.setItem("id_token", user.id_token);        
       server.get(Endpoints.generateKey, "", options).then((data) => {     
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log("calling generateKey endpoint",Endpoints.generateKey);}
        if (data.status === statCodes.SUCCESS) {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log("integration redux thunk response :",data.data); }
            dispatch({type:integrationActions.GENERATEKEY_SUCCESS,payload:data.data});              
          }else{
            dispatch({type:integrationActions.GENERATEKAY_HASERROR,payload:data});
          }
        }).catch((error) => {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}          
          if(!error.response){
            dispatch({type:integrationActions.GENERATEKAY_HASERROR,payload:error});
          }else{
            dispatch({type:integrationActions.GENERATEKAY_HASERROR,payload:error.response});
          }          
        });
      }else{
        AuthService.loginPage();
      }
    }     
  };
}; 
export const getEventsListAPI=()=>{
  return dispatch => {      
    if(AuthService.isTokenValid()===true){
      server.get(Endpoints.webhookEvents, '', options).then((data) => {     
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('calling Events list endpoint',Endpoints.webhookEvents);}
        if (data.status === statCodes.SUCCESS) {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log("integration redux thunk response :",data.data); }
            dispatch({type:integrationActions.EVENTSTYPELIST_SUCCESS,payload:data.data});                
          }else{
            dispatch({type:integrationActions.EVENTSTYPELIST_HASERROR,payload:data});
          }
      }).catch((error) => {
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}          
        if(!error.response){
          dispatch({type:integrationActions.EVENTSTYPELIST_HASERROR,payload:error});            
        }else{
          dispatch({type:integrationActions.EVENTSTYPELIST_HASERROR,payload:error.response});            
        }
        
      });
    }else{
      const user = AuthService.signinSilentToken();
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('signinSilentToken user :',user);}
      if(user){
       localStorage.setItem("id_token", user.id_token);        
       server.get(Endpoints.webhookEvents, "", options).then((data) => {     
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log("calling eventslist endpoint",Endpoints.webhookEvents);}
        if (data.status === statCodes.SUCCESS) {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log("integration redux thunk response :",data.data); }
            dispatch({type:integrationActions.EVENTSTYPELIST_SUCCESS,payload:data.data});              
          }else{
            dispatch({type:integrationActions.EVENTSTYPELIST_HASERROR,payload:data});
          }
        }).catch((error) => {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}          
          if(!error.response){
            dispatch({type:integrationActions.EVENTSTYPELIST_HASERROR,payload:error});            
          }else{
            dispatch({type:integrationActions.EVENTSTYPELIST_HASERROR,payload:error.response});            
          }           
        });
      }else{
        AuthService.loginPage();
      }
    }     
  };
};
export const postWebhookAPI=(params)=>{
  return dispatch => {    
    if(AuthService.isTokenValid()===true){
      server.post(Endpoints.postWebhooks,'', params, options).then((data) => {                          
        if (data.status === statCodes.SUCCESS) {   
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Create webhooks res :',data);}
          dispatch({type:integrationActions.WEBHOOK_SUCCESS,payload:data.data});
        }else{
          dispatch({type:integrationActions.WEBHOOK_HASERROR,payload:data});
        }
      }).catch((error) => {
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);         }
        if(!error.response){
          dispatch({type:integrationActions.WEBHOOK_HASERROR,payload:error});            
        }else{
          dispatch({type:integrationActions.WEBHOOK_HASERROR,payload:error.response});            
        }
       
      });
    }else{
      const user = AuthService.signinSilentToken();
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('signinSilentToken user :',user);}
      if(user){
        localStorage.setItem('id_token', user.id_token); 
        server.post(Endpoints.optionalFeaturesPost,'', params, options).then((data) => {                      
          if (data.status === statCodes.SUCCESS) {   
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Create webhooks res :',data.data);}
            dispatch({type:integrationActions.WEBHOOK_SUCCESS,payload:data.data});
          }else{
            dispatch({type:integrationActions.WEBHOOK_HASERROR,payload:data});
          }
        }).catch((error) => {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}
          if(!error.response){
            dispatch({type:integrationActions.WEBHOOK_HASERROR,payload:error});            
          }else{
            dispatch({type:integrationActions.WEBHOOK_HASERROR,payload:error.response});            
          }
        });
      }       
    }   
  };
};
//v3 changes for webhooks to pass both webhook ID and Integration ID
export const getWebhookIntegrationAPI=(integrationID, webhookID)=>{
  return dispatch => {      
    if(AuthService.isTokenValid()===true){
      server.get(Endpoints.webhooks+'/'+integrationID+'/'+webhookID, '', options).then((data) => {     
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log('calling webhooks get integration',Endpoints.integration); }        
        if (data.status === statCodes.SUCCESS) {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log("integration webhooks redux thunk response :",data.data); }
            dispatch({type:integrationActions.GETWEBHOOK_INTEGRATION_SUCCESS,payload:data.data});
          }else{
            dispatch({type:integrationActions.GETWEBHOOK_INTEGRATION_HASERROR,payload:data});
          }
      }).catch((error) => {
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}       
        if(!error.response){
          dispatch({type:integrationActions.GETWEBHOOK_INTEGRATION_HASERROR,payload:error});            
        }else{
          dispatch({type:integrationActions.GETWEBHOOK_INTEGRATION_HASERROR,payload:error.response});            
        }
        
      });
    }else{
      const user = AuthService.signinSilentToken();
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log('signinSilentToken user :',user);}
      if(user){
        localStorage.setItem('id_token', user.id_token);        
        server.get(Endpoints.webhooks+'/'+integrationID+'/'+webhookID, '', options).then((data) => {     
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log('calling webhooks integration list endpoint',Endpoints.integration); }       
          if (data.status === statCodes.SUCCESS) {
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log('integration webhook redux thunk response :',data.data); }
            dispatch({type:integrationActions.GETWEBHOOK_INTEGRATION_SUCCESS,payload:data.data});
          }else{
            dispatch({type:integrationActions.GETWEBHOOK_INTEGRATION_HASERROR,payload:data});
          }
        }).catch((error) => {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}        
          if(!error.response){
            dispatch({type:integrationActions.GETWEBHOOK_INTEGRATION_HASERROR,payload:error});            
          }else{
            dispatch({type:integrationActions.GETWEBHOOK_INTEGRATION_HASERROR,payload:error.response});            
          }           
        });
      }else{
        AuthService.loginPage();
      }
    }     
  };
};
export const putIntegrationAPI=(integrationID,params)=>{
  return dispatch => {    
    if(AuthService.isTokenValid()===true){
      server.put(Endpoints.integration+'/'+integrationID,params,options,'').then((data) => {                          
        if (data.status === statCodes.SUCCESS) {   
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('CreateIntegration res :',data);}
          dispatch({type:integrationActions.EDIT_INTEGRATION_SUCCESS,payload:data.data});
        }else{
          dispatch({type:integrationActions.EDIT_INTEGRATION_HASERROR,payload:data});
        }
      }).catch((error) => {
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}
        if(!error.response){
          dispatch({type:integrationActions.EDIT_INTEGRATION_HASERROR,payload:error});              
        }else{
          dispatch({type:integrationActions.EDIT_INTEGRATION_HASERROR,payload:error.response});            
        }
        
      });
    }else{
      const user = AuthService.signinSilentToken();
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('signinSilentToken user :',user);}
      if(user){
        localStorage.setItem('id_token', user.id_token); 
        server.put(Endpoints.integration+'/'+integrationID, params, options).then((data) => {                      
          if (data.status === statCodes.SUCCESS) {   
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('CreateIntegration res :',data.data);}
            dispatch({type:integrationActions.EDIT_INTEGRATION_SUCCESS,payload:data.data});
          }else{
            dispatch({type:integrationActions.EDIT_INTEGRATION_HASERROR,payload:data});
          }
        }).catch((error) => {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}
          if(!error.response){
            dispatch({type:integrationActions.EDIT_INTEGRATION_HASERROR,payload:error});              
          }else{
            dispatch({type:integrationActions.EDIT_INTEGRATION_HASERROR,payload:error.response});            
          }
        });
      }       
    }   
  };
};
export const putWebhookAPI=(integrationID,webhookID,params)=>{
  return dispatch => {    
    if(AuthService.isTokenValid()===true){
      server.put(Endpoints.webhooks+'/'+integrationID+'/'+webhookID,params,options,'').then((data) => {                          
        if (data.status === statCodes.SUCCESS) {   
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Put webhook res :',data);}
          dispatch({type:integrationActions.EDIT_WEBHOOK_SUCCESS,payload:data.data});
        }else{
          dispatch({type:integrationActions.EDIT_WEBHOOK_HASERROR,payload:data});
        }
      }).catch((error) => {
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}
        if(!error.response){
          dispatch({type:integrationActions.EDIT_WEBHOOK_HASERROR,payload:error});            
        }else{
          dispatch({type:integrationActions.EDIT_WEBHOOK_HASERROR,payload:error.response});            
        }
        
      });
    }else{
      const user = AuthService.signinSilentToken();
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('signinSilentToken user :',user);}
      if(user){
        localStorage.setItem('id_token', user.id_token); 
        server.put(Endpoints.webhooks+'/'+integrationID+'/'+webhookID,params,options,'').then((data) => {                      
          if (data.status === statCodes.SUCCESS) {   
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Put webhook res :',data.data);}
            dispatch({type:integrationActions.EDIT_WEBHOOK_SUCCESS,payload:data.data});
          }else{
            dispatch({type:integrationActions.EDIT_WEBHOOK_HASERROR,payload:data});
          }
        }).catch((error) => {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}
          if(!error.response){
            dispatch({type:integrationActions.EDIT_WEBHOOK_HASERROR,payload:error});            
          }else{
            dispatch({type:integrationActions.EDIT_WEBHOOK_HASERROR,payload:error.response});            
          }           
        });
      }       
    }   
  };
};

export const getIntegrationName=(name)=>{
  return dispatch => {    
    if(AuthService.isTokenValid()===true){
      server.get(Endpoints.integrationNameValidation+'/'+ name, '', options).then((data) => {                          
        if (data.status === statCodes.SUCCESS) {   
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Integration Name res :',data);}
          dispatch({type:integrationActions.POST_INTEGRATION_NAME_SUCCESS,payload:data.data});
        }else{
          dispatch({type:integrationActions.POST_INTEGRATION_NAME_ERROR,payload:data});
        }
      }).catch((error) => {
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}
        if(!error.response){
          dispatch({type:integrationActions.POST_INTEGRATION_NAME_ERROR,payload:error}); 
        }else{
          dispatch({type:integrationActions.POST_INTEGRATION_NAME_ERROR,payload:error.response}); 
        }
                   
      });
    }else{
      const user = AuthService.signinSilentToken();
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('signinSilentToken user :',user);}
      if(user){
        localStorage.setItem('id_token', user.id_token); 
        server.get(Endpoints.integrationNameValidation+'/'+ name, '', options).then((data) => {                      
          if (data.status === statCodes.SUCCESS) {   
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Integration Name res :',data.data);}
            dispatch({type:integrationActions.POST_INTEGRATION_NAME_SUCCESS,payload:data.data});
          }else{
            dispatch({type:integrationActions.POST_INTEGRATION_NAME_ERROR,payload:data});
          }
        }).catch((error) => {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}
          if(!error.response){
            dispatch({type:integrationActions.POST_INTEGRATION_NAME_ERROR,payload:error}); 
          }else{
            dispatch({type:integrationActions.POST_INTEGRATION_NAME_ERROR,payload:error.response}); 
          }            
        });
      }       
    }   
  };
};

export const getActivityDetails=(integrationID)=>{
  return dispatch => {    
    if(AuthService.isTokenValid()===true){
      server.get(Endpoints.activities+'/'+ integrationID, '', options).then((data) => {                          
        if (data.status === statCodes.SUCCESS) {   
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('ACTIVITY_DETAILS res :',data);}
          dispatch({type:integrationActions.ACTIVITY_DETAILS_SUCCESS,payload:data.data});
        }else{
          dispatch({type:integrationActions.ACTIVITY_DETAILS_ERROR,payload:data});
        }
      }).catch((error) => {
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}
        if(!error.response){
          dispatch({type:integrationActions.ACTIVITY_DETAILS_ERROR,payload:error}); 
        }else{
          dispatch({type:integrationActions.ACTIVITY_DETAILS_ERROR,payload:error.response}); 
        }
                   
      });
    }else{
      const user = AuthService.signinSilentToken();
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('signinSilentToken user :',user);}
      if(user){
        localStorage.setItem('id_token', user.id_token); 
        server.get(Endpoints.activities+'/'+ integrationID, '', options).then((data) => {                      
          if (data.status === statCodes.SUCCESS) {   
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('ACTIVITY_DETAILS res :',data.data);}
            dispatch({type:integrationActions.ACTIVITY_DETAILS_SUCCESS,payload:data.data});
          }else{
            dispatch({type:integrationActions.ACTIVITY_DETAILS_ERROR,payload:data});
          }
        }).catch((error) => {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}
          if(!error.response){
            dispatch({type:integrationActions.ACTIVITY_DETAILS_ERROR,payload:error}); 
          }else{
            dispatch({type:integrationActions.ACTIVITY_DETAILS_ERROR,payload:error.response}); 
          }            
        });
      }       
    }   
  };
};

export const postDevToken=(params)=>{
  return dispatch => {    
    if(AuthService.isTokenValid()===true){
      server.post(Endpoints.generatedevtoken,'', params, options).then((data) => {                          
        if (data.status === statCodes.SUCCESS) {   
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Generate Devtoken res :',data);}
          dispatch({type:integrationActions.POST_DEVTOKEN_SUCCESS,payload:data.data});
        }else{
          dispatch({type:integrationActions.POST_DEVTOKEN_ERROR,payload:data});
        }
      }).catch((error) => {
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);         }
        if(!error.response){
          dispatch({type:integrationActions.POST_DEVTOKEN_ERROR,payload:error});            
        }else{
          dispatch({type:integrationActions.POST_DEVTOKEN_ERROR,payload:error.response});            
        }
       
      });
    }else{
      const user = AuthService.signinSilentToken();
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('signinSilentToken user :',user);}
      if(user){
        localStorage.setItem('id_token', user.id_token); 
        server.post(Endpoints.generatedevtoken,'', params, options).then((data) => {                      
          if (data.status === statCodes.SUCCESS) {   
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Generate devtoken res :',data.data);}
            dispatch({type:integrationActions.POST_DEVTOKEN_SUCCESS,payload:data.data});
          }else{
            dispatch({type:integrationActions.POST_DEVTOKEN_ERROR,payload:data});
          }
        }).catch((error) => {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}
          if(!error.response){
            dispatch({type:integrationActions.POST_DEVTOKEN_ERROR,payload:error});            
          }else{
            dispatch({type:integrationActions.POST_DEVTOKEN_ERROR,payload:error.response});            
          }
        });
      }       
    }   
  };
};
