import {
    BROWSER_BACK_BUTTON_HANDLETAB, 
    COMMISSIONRUNLIST_SUCCESS, 
    COMMISSIONRUNLIST_ERROR, 
    COMMISSIONRUNLOGS_SUCCESS, 
    COMMISSIONRUNLOGS_ERROR, 
    COMMISSIONSRUNDETAILS_SUCCESS,
    COMMISSIONSRUNDETAILS_ERROR,
    COMMISSION_SELECTED_RUN,
    COMMISSIONSELECTED_RUN_SUCCESS,
    COMMISSIONSELECTED_RUN_ERROR,
    COMMISSION_CREATE_RUN_SUCCESS,
    COMMISSION_CREATE_RUN_ERROR,
    COMMISSIONSRETAILPROFIT_SUCCESS,
    COMMISSIONSRETAILPROFIT_ERROR,
    COMMRUN_DELETERUN_ERROR,
    COMMRUN_DELETERUN_SUCCESS,
    COMMISSION_PREVIOUS_RUNDETAILS_SUCCESS,
    COMMISSION_PREVIOUS_RUNDETAILS_ERROR,
    SELECTEDRUN_LOGS_SUCCESS,
    SELECTEDRUN_LOGS_ERROR,
    PROGRAMDATA_SUCCESS,
    PROGRAMDATA_ERROR
} from "../actions"


const initialState = {
    commissionRunList:[],
    commissionRunListError: {},
    commissionRunUpdateLogs:[],
    commissionRunLogError:[],
    commissionRunDetails:[],
    commissionRunDetailsError: {},
    selectedCommissionRun:{},
    commissionSelectedRunDetaiils:[],
    commissionSelectedRunDError:{},
    createNewRunResposne: {},
    createNewRunError: {},
    retailProfitResposne:{},
    retailProfitError: {},
    deleteRunResponse:{},
    deleteRunError:{},
    commissionPreviousRunDetails: [],
    commissionPreviousRunDetailsError: {},
    selectedRunLogResponse:{},
    selectedRunLogError:{},
    programDataResponse:{},
    programDataError:{}
}

export default function commissionReducer(state,action){
    if (typeof state === 'undefined') {
        return initialState;
    }  
    switch (action.type) {
        case COMMISSIONRUNLIST_SUCCESS: 
            return { ...state,  commissionRunList : action.payload };
        case COMMISSIONRUNLIST_ERROR: 
            return { ...state,  commissionRunListError : action.payload };
        case COMMISSIONRUNLOGS_SUCCESS: 
            return { ...state,  commissionRunUpdateLogs : action.payload };
        case COMMISSIONRUNLOGS_ERROR: 
            return { ...state,  commissionRunLogError : action.payload };
        case COMMISSIONSRUNDETAILS_SUCCESS: 
            return { ...state,  commissionRunDetails : action.payload };
        case COMMISSIONSRUNDETAILS_ERROR:
            return { ...state, commissionRunDetailsError: action.payload };
        case COMMISSION_PREVIOUS_RUNDETAILS_SUCCESS:
            return { ...state, commissionPreviousRunDetails: action.payload };
        case COMMISSION_PREVIOUS_RUNDETAILS_ERROR:
            return { ...state, commissionPreviousRunDetailsError: action.payload };
        case COMMISSION_SELECTED_RUN:
            return { ...state,  selectedCommissionRun : action.payload };   
        case COMMISSIONSELECTED_RUN_SUCCESS: 
            return { ...state,  commissionSelectedRunDetaiils : action.payload };
        case COMMISSIONSELECTED_RUN_ERROR: 
            return { ...state,  commissionSelectedRunDError : {} };
        case COMMISSION_CREATE_RUN_SUCCESS: 
            return { ...state,  createNewRunResposne : action.payload };
        case COMMISSION_CREATE_RUN_ERROR: 
            return { ...state,  createNewRunError : action.payload };
        case COMMISSIONSRETAILPROFIT_SUCCESS: 
            return { ...state,  retailProfitResposne : action.payload };
        case COMMISSIONSRETAILPROFIT_ERROR: 
            return { ...state,   retailProfitError : {} };
        case COMMRUN_DELETERUN_SUCCESS:   
            return { ...state, deleteRunResponse:action.payload };
        case COMMRUN_DELETERUN_ERROR:   
            return { ...state, deleteRunError:action.payload };
        case SELECTEDRUN_LOGS_SUCCESS:   
            return { ...state, selectedRunLogResponse:action.payload };
        case SELECTEDRUN_LOGS_ERROR:   
            return { ...state, selectedRunLogError:action.payload };
        case PROGRAMDATA_SUCCESS:   
            return { ...state, programDataResponse:action.payload };
        case PROGRAMDATA_ERROR:   
            return { ...state, programDataError:action.payload };       
        default:
        return state;
    } 
}