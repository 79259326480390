import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Oval } from "react-loader-spinner";
import { Modal } from "react-bootstrap";
import vector13 from '../../../assets/vector-13@2x.png';
import '../AddCompareRun/AddCompareModal.css'
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { getVolumeFieldDetailsMatrixAPI } from "../actions";
import { numberWithCommas}  from '../../utilities/Services'
import { isNullResponse, allNullTypeCheck } from "../commonComponents/UtilityFun";
import * as Constants from '../../../constants/Constants';
import NewSnackbar from "../../utilities/NewSnackbar";
import actions from '../../../redux/actions/CommonDataActions';
import "./GeneralTab.css";
import "./AmountFields.css";
import { generateMatrixCSV } from '../CommToolCompareRun/generateCSV';
import exports from '../../../assets/exports.svg';
import RankMatrixModel from "./RankMatrixModel";

const fields = [
    { name: 'Paid Rank', displayName: "Dist ID", inputFilterable: true, sortable: true },
    { name: 'Bv', displayName: "Name", inputFilterable: false, exactFilterable: true, sortable: true },
    { name: 'Org Volume', displayName: "Amount", inputFilterable: false, exactFilterable: true, sortable: true },
    { name: 'Active Leg', displayName: "Level", inputFilterable: true, sortable: true }    
];

class VolRankMatrix extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoaderResult: true,
      showRemoveColModal: true,
      hideModal: false,      
      workFieldDetailsList: [],
      rankMatrixModel:false,
      distID:'',
      runsWaitTime:1
    };
  }

  componentDidMount() {
    const { distributorID, runID, volFieldID, detailKey} = this.props;
    console.log("vol props is ",distributorID,runID)
    if(allNullTypeCheck(distributorID) && allNullTypeCheck(runID) && allNullTypeCheck(detailKey)){
      this.props.getVolumeFieldDetailsMatrixAPI(runID, distributorID, detailKey);
    }else{
      this.setState({showLoaderResult:false});
    }
  }
  componentDidUpdate(nextProps) {
    const {
        volFieldDetailsList,
        volFieldDetailsError,
        distributorID, runID,
        detailKey
    } = this.props;
    const { runsWaitTime } = this.state;
    if (nextProps.volFieldDetailsList !== volFieldDetailsList) {
      this.setState({ showLoaderResult: false });
      console.log("work field details : ",volFieldDetailsList);
      if(!volFieldDetailsList.hasOwnProperty('status')){
      if (isNullResponse(volFieldDetailsList.data)) {
        if (volFieldDetailsList.error === false) {
          console.log("Runs sucess : ", volFieldDetailsList);
          this.setState({
            workFieldDetailsList: volFieldDetailsList?.data.volumeDetails,
          });
        } else {
          if (volFieldDetailsList.error === true) {
          this.props.showSnackbar({
            snackbarOpen: true,
            snackbarType: Constants.SNACKBAR_TYPE.error,
            snackbarMessage: typeof(volFieldDetailsList?.messages[0])!=='string'? volFieldDetailsList?.messages[0]?.code+' - '+volFieldDetailsList?.messages[0]?.detail:volFieldDetailsList?.messages[0],
          });
         }
        }
      } else if(Object.keys(volFieldDetailsList?.data).length===0 && allNullTypeCheck(volFieldDetailsList?.messages)) {
        if(volFieldDetailsList?.messages?.length > 0 && volFieldDetailsList?.messages[0]?.includes("Try again...")){
            console.log("Try again block entered : ",volFieldDetailsList?.data);
            this.setState({showLoaderResult: true})
            setTimeout(() => {
              this.props.getVolumeFieldDetailsMatrixAPI(runID, distributorID, detailKey);
            }, 1000 * runsWaitTime);
            this.setState(prevState => {
            return {runsWaitTime: prevState.runsWaitTime * 2}
            })
            }
      }
       else {
        if (volFieldDetailsList.error === true) {
        this.props.showSnackbar({
          snackbarOpen: true,
          snackbarType: Constants.SNACKBAR_TYPE.error,
          snackbarMessage: typeof(volFieldDetailsList?.messages[0])!=='string'? volFieldDetailsList?.messages[0]?.code+' - '+volFieldDetailsList?.messages[0]?.detail:volFieldDetailsList?.messages[0],
        });
       }
      }
    }else {
      if(volFieldDetailsList.hasOwnProperty('status') && volFieldDetailsList.status===202){
        this.setState({showLoaderResult: true})
        setTimeout(() => {
          this.props.getVolumeFieldDetailsMatrixAPI(runID, distributorID, detailKey);
        }, 1000 * runsWaitTime);
        this.setState(prevState => {
        return {runsWaitTime: prevState.runsWaitTime * 2}
        })
     }
    }
    }

    if (nextProps.volFieldDetailsError !== volFieldDetailsError) {
      this.setState({ showLoaderResult: false });
      console.log("Error details : ",volFieldDetailsError);
      if (volFieldDetailsError?.data?.error === true) {
          this.props.showSnackbar({
            snackbarOpen: true,
            snackbarType: Constants?.SNACKBAR_TYPE?.error,
            snackbarMessage: typeof(volFieldDetailsError?.data?.messages[0])!=='string'? volFieldDetailsError?.data?.messages[0]?.code+' - '+volFieldDetailsError?.data?.messages[0]?.detail:volFieldDetailsError?.data?.messages[0],
          });
        }
          
    }
  }  
  
  hideModal() {
    this.setState({ hideModal: true, showRemoveColModal: false });
    this.props.closefunction()
  }
  confirmModal() {
    this.setState({ showLoaderResult: true });
    this.hideModal();
    // this.props.selectedRun(this.state.selectedCommissionRun);
  }
  // handleClick = (event) => {
  //   if (allNullTypeCheck(event.currentTarget)) {
  //     this.setState({
  //       anchorEl: event.currentTarget,
  //       displayPerioPop: true,
  //       periodValue: "",
  //     });
  //   }
  // };
  // handleClose = () => {
  //   this.setState({
  //     anchorEl: null,
  //     displayPerioPop: false,
  //   });
  // };
  generateVolumeCSV = () =>{
    const { workFieldDetailsList } = this.state
    generateMatrixCSV(workFieldDetailsList, fields, "VolumeFieldDetails")
  }
  
  // handleKeyPress = (event) => {
  //   if (event.key === "Enter") {
  //     this.onJumpSelectPeriod();
  //   }
  // };
  rankMatrixModel=(distId)=>{
    this.setState({rankMatrixModel:true, distID: distId });
  }
  render() {
    let loader;
    const {
      showRemoveColModal,   
      showLoaderResult,
      workFieldDetailsList,
      rankMatrixModel,
      distID,
    } = this.state;
    const { distributorID, runID, volFieldLabel, detailKey } = this.props;
   
    if (showLoaderResult) {
      loader = (
        <div
          id="center"
          style={{ position: "fixed", bottom: "40%", left: "48%" }}
        >
          <Oval
            type="Puff"
            position="fixed"
            secondaryColor="#E85557"
            color="#E85557"
            height={50}
            width={50}
            timeout={3000}
          />
        </div>
      );
    }
    return (
      <div>
        <Modal
          dialogClassName="workfield-my-modal"
          show={showRemoveColModal}
          enforceFocus={false}
        >
          <Modal.Header>
            <div className="addcmpr-flex-row-1-ei" style={{minWidth:"455px"}}>
              <div className="add-cmnr-header-name cmnr-h3-header-3-18pt-bold-lato">
               {volFieldLabel} Details
              </div>
              <img
                className="addcmpr-vector-ei"
                src={vector13}
                onClick={this.hideModal.bind(this)}
                alt=""
              />
            </div>
          </Modal.Header>
          <Modal.Body>
          <div className="col-12 workfield-cm-table-style" >
             <TableContainer component={Paper} style= {{maxHeight: "360px"}}>
             <Table stickyHeader sx={{ minWidth: 800 }} aria-label="simple table" className='general-table-left'>
                 <TableHead >
                     {fields.map((row) => (
                         <TableCell className={(row.displayName === "Name") || (row.displayName === "Dist ID")? "volrankmatrix-table-header": "downline-table-header"} style= {{textAlign: (row.displayName === "Amount")? "right !important" : "left !important"}}  component="th" scope="row">
                             {row.displayName}
                         </TableCell>
                     ))}
                 </TableHead>
                 <TableBody>
                     {workFieldDetailsList?.length > 0 ? workFieldDetailsList?.map((workField,i) => (
                         <TableRow key={i}>
                             <TableCell className='data-text-style rank-distid-style' align="right" onClick={()=>this.rankMatrixModel(workField?.distributorId)}>{workField?.distributorId}</TableCell>
                             <TableCell className='data-text-style' align="left">{workField?.distributorName}</TableCell>
                             <TableCell className='data-text-style' align="right">{numberWithCommas((workField?.amount).toFixed(2))}</TableCell>
                             <TableCell className='data-text-style' align="right">{workField?.level}</TableCell>                            
                         </TableRow>
                     )) :
                         ''
                     }
                     
                 </TableBody>
             </Table>
         </TableContainer>
        </div>
          </Modal.Body>
         
          <Modal.Footer>
          <div className="add-run-type-btn  mr-auto"  style={{ color: '#D93030', cursor: "pointer", align:"left" }} onClick={this.generateVolumeCSV.bind(this)}><img
                src={exports}                
                alt=""
              /></div>
            {/* <div className="row">
            
              <div style={{ backgroundColor: "#D93030" , cursor: "pointer",height: "30px", right: "0px", left: "50px",bottom: "0px" }} className=" col-6 cmnr-create-run" onClick={this.confirmModal.bind(this)}> 
               <div className="add-run-type-btn" style={{ color: 'white'}}>OK</div>              
              </div>
            </div> */}
          </Modal.Footer>
          
          <NewSnackbar />
          {loader}
        </Modal>
        {rankMatrixModel?<RankMatrixModel 
         distributorID ={distID}
         runID = {runID}
         detailKey = {'PAID-RANK'}
        closefunction={()=>this.setState({rankMatrixModel:false})}/>:''}
      </div>
    );
  }
}
VolRankMatrix.propTypes = {
  defaultvalues: PropTypes.any,
  addCompareBackfunc: PropTypes.func,
};
const mapStateToProps = (state) => {
  return {
    volFieldDetailsList: state.distributorResearchReducer.volFieldDetailsList,
    volFieldDetailsError: state.distributorResearchReducer.volFieldDetailsError,
  };
};
const mapDispatchToProps = dispatch => ({
  getVolumeFieldDetailsMatrixAPI: (runID, distributorID, volFieldID) => dispatch(getVolumeFieldDetailsMatrixAPI(runID, distributorID, volFieldID)),
  showSnackbar: (params) => dispatch({ type: actions.SNACKBAR_SHOW, payload: params}),
})
export default connect(mapStateToProps, mapDispatchToProps)(VolRankMatrix);