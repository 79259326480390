import React, { Component } from 'react';
import "./CreateIntegrations.css";
import "../../../styleguide.css";
// import { Button } from 'reactstrap';
import { Oval } from "react-loader-spinner";
import { connect } from 'react-redux';
import * as Constants from '../../../constants/Constants';
import actions from '../../../redux/actions/CommonDataActions';
import actions1 from '../../../redux/actions/IntegrationPopUp';
import vector13 from '../../../assets/vector-13@2x.png';
import line42 from '../../../assets/line-42@1x.png';
import vector14 from '../../../assets/vector-14@2x.png';
import vector15 from '../../../assets/vector-15@2x.png';
import line22 from '../../../assets/line-22@2x.png';
import vector29 from '../../../assets/vector-29@2x.png';
import vector30 from '../../../assets/vector-30@2x.png';
import vector31 from '../../../assets/vector-31@2x.png';
import plainvectorcircle from '../../../assets/plainvectorcircle.svg';
import vector343 from '../../../assets/vector343.svg'
import NewSnackbar from "../../utilities/NewSnackbar";
import radiobtn from "../../../assets/new-radiobtn.svg"
import radiobtnselect from "../../../assets/new-radioselect.svg"
import Button from '@material-ui/core/Button';
class CreateIntegration extends Component {
  constructor(props) {
    super(props)
    this.handleClose = this.handleClose.bind(this)
    this.integrationName = this.integrationName.bind(this)
    this.selectAuth = this.selectAuth.bind(this)
    this.state = {
      show: this.props.val,
      showIntegrationModal: false,
      selectAuthType: false,
      nextButton: true
    }
  }
  componentDidMount(){
    let type = localStorage.getItem("AuthType")
    if(type === "true"){
      this.setState({
        selectAuthType: true, nextButton: false
      })
    }
    else{
      this.setState({
        selectAuthType: false
      })
    }
  }
  componentDidUpdate(nextProps) {
    console.log("next props", nextProps)
    if (this.props.isIntegrationNameModel !== nextProps.isIntegrationNameModel) {
      console.log("valueprops", this.props.showIntegrationNamePopup)
      this.setState({ showIntegrationModal: this.props.showIntegrationNamePopup() })
    } else {
      console.log("next props in else", nextProps, this.props.isIntegrationNameModel)
    }
  }

  handleClose() {
    localStorage.removeItem("AuthType")
    this.props.hideCreateIntegrationPopup()
  }
  integrationName() {
    if(this.state.selectAuthType === true){
      this.props.showIntegrationNamePopup()
      this.props.hideCreateIntegrationPopup()
    }
    else{
      this.props.showSnackbar({snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.error, snackbarMessage: Constants.TEXT_AUTHTYPE_ALERT})
    }
  
  }
  selectAuth() {
    this.setState({
      selectAuthType: true, nextButton: false
    })
  }

  render() {
    let loader = ''
    if (this.props.isFetchingData) {
      loader = (<div id="center" style={{ position: 'fixed', bottom: '40%', left: '48%' }}>
        <Oval type="Puff" position="fixed"  secondaryColor="#E85557" color="#E85557" height={50} width={50} visible={true} /> </div>)
    }
    return (
      <div>
        <div className="container-center-horizontal">
          <form className="frame-5 screen" name="form1" action="form1" method="post">
            <div className="flex-row-ci">
              <div className="text-1-ci valign-text-middle lato-normal-mine-shaft-18px">Create a New Integration</div>

              <img className="vector-3-ci" src={vector13} onClick={this.handleClose.bind(this)} style={{cursor: 'pointer'}}/>

            </div>
            <div className="overlap-group-3">
              <div className="overlap-group-1">
                <div className="rectangle-2333"></div>
                <img
                  className="line-45"
                  src={line42}
                />
                <img
                  className="line-46"
                  src={line42}
                />
                <div className="text-7-ci valign-text-middle lato-bold-mine-shaft-14px" style= {{fontWeight:"700"}}>Authentication Method</div>
                <img
                  className="vector-ci"
                  src={plainvectorcircle}
                />
                <img
                  className="vector-2-ci"
                  src={vector343}
                />
                <img
                  className="vector-1-ci"
                  src={vector343}
                />
                <div className="text-ci valign-text-middle lato-normal-granite-gray-14px">Complete Integration</div>
                <div className="integration-name valign-text-middle lato-normal-granite-gray-14px">Integration Name</div>
                <div className="number-2-ci lato-bold-malachite-12px">1</div>
                <div className="number-1-ci lato-bold-white-12px">2</div>
                <div className="number-ci lato-bold-white-12px">3</div>
                <img
                  className="line-22"
                  src={line22}
                />
                <img
                  className="line-47"
                  src={line22}
                />
              </div>
              <div style={{backgroundColor: "#F1F1F1"}}>
                <p className="text-2-ci valign-text-middle lato-normal-fuscous-gray-14px">
                <span>
                  <span>
                    We recommend an authentication method based on the type of app you’ve chosen.
                <br />
                You can change the authentication method below.{" "}
                  </span>
                  <span className="span1-ci paragraph-1--14pt-regular-lato">Learn more</span>
                </span>
              </p>
              <h1 className="text-3-ci valign-text-middle lato-bold-mine-shaft-28px">Authentication Method</h1>
              <div className="overlap-group-ci" onClick={this.selectAuth}>
                <div className="rectangle-283-ci border-1px-seashell"></div>
                <div className="text-5-ci valign-text-middle lato-bold-granite-gray-18px">Server Authentication</div>
                <img className="radio-selection-cs" src={radiobtn}></img>
                <p className="text-4-ci lato-normal-granite-gray-14px">
                  Allows your integration to authenticate directly to Infotrax using a secure web token instead of user
                  credentials.
                 </p>
              </div>
              {this.state.selectAuthType ? <div class="overlap-group-ci-1 border-1px-mine-shaft">
              {this.state.selectAuthType ? <img className="radio-selection-cs" src={radiobtnselect} /> 
                : <img className="radio-selection-cs" src={radiobtn}></img>}
                <div className="text-5-ci valign-text-middle lato-bold-granite-gray-18px">Server Authentication</div>
                <div className="group-2720" style={{ backgroundImage: `url(${vector29})` }}>
                <p className="text-4-ci lato-normal-granite-gray-14px">
                  Allows your integration to authenticate directly to Infotrax using a secure web token instead of user
                  credentials.
                 </p>
                </div>
              </div> : null}
              <div className="overlap-group-2 border-1px-seashell" style={{cursor: 'auto', opacity: 0.7}} aria-disabled={true}>
                <img className="radio-selection-cs" src={radiobtn} />
                <div className="user-authentication valign-text-middle lato-bold-granite-gray-18px">
                  User Authentication
            </div>
                <p className="text-6 lato-normal-granite-gray-14px">
                  Requires Infotrax users to login with username and password to authorize your integration
            </p>
              </div>
            </div>
            </div> 
            {loader}
            <div className="flex-row-in">
            <Button disabled={this.state.nextButton} style={{backgroundColor: this.state.selectAuthType === true ? '#D93030': '#DDDDDD', marginLeft: '800px', width: '64px', height: '30px',  color: 'white', fontSize: '12px', fontWeight:700, fontFamily: 'Lato', lineHeight: '14px'}} 
                variant="contained" onClick={this.integrationName}>NEXT</Button>
            {/* <Button disabled={this.state.nextButton} className="primary-medium-default-in-next-button" onClick={this.integrationName} style={{paddingLeft: "13px", paddingTop: "5px", fontWeight: "700", fontSize: '12px', letterSpacing: '0.8px'}}>NEXT</Button> */}
              {/* <div className="button-i527103263 valign-text-middle 9 style={{ color: "white" }} onClick={this.integrationName}>NEXT</div> */}
              {/* {this.state.showIntegrationModal? <IntegrationName val = {this.state.showIntegrationModal} ></IntegrationName>: null} */}
            </div>
            {/* <PrimaryMediumDefault val= {this.state.showIntegrationModal}>NEXT</PrimaryMediumDefault> */}
          </form>
        </div>
        <NewSnackbar/>
      </div>
    );
  }
}
CreateIntegration.propTypes = {
};
const mapStateToProps = state => {
  return {
    isIntegrationNameModel: state.integrationPopUpReducers.isIntegrationNameModel,
    isAuthTypeSelect: state.integrationDataReducer.isAuthTypeSelect,
  }
};

const mapDispatchToProps = dispatch => ({
  showLoader: () => dispatch({ type: actions.FETCHING_DATA_LOADER, payload: true }),
  hideLoader: () => dispatch({ type: actions.HIDE_DATA_LOADER, payload: false }),
  showCreateIntegrationPopup: () => dispatch({ type: actions1.SHOW_CREATE_INTEGRATIONS, payload: true }),
  hideCreateIntegrationPopup: () => dispatch({ type: actions1.HIDE_CREATE_INTEGRATIONS, payload: false }),
  showIntegrationNamePopup: () => dispatch({ type: actions1.SHOW_INTEGRATIONS_NAME, payload: true }),
  hideIntegrationNamePopup: () => dispatch({ type: actions1.HIDE_INTEGRATIONS_NAME, payload: false }),
  showSnackbar: (params) => dispatch({ type: actions.SNACKBAR_SHOW, payload: params}),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateIntegration);
