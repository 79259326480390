import React from "react";
import "./SecondaryMediumDefaultBlack.css";

class SecondaryMediumDefaultBlack extends React.Component {
  render() {
    const { children } = this.props;

    return (
      <div className="secondary-medium-default-black">
        <div className="button-i5271033536 valign-text-middle lato-bold-mine-shaft-16px">{children}</div>
      </div>
    );
  }
}

export default SecondaryMediumDefaultBlack;
