import {FETCHING_APPSLIST,INVITE_POPUP } from '../actions/actions';

const initialState = {
  userObject:[],
  showPopup:false,
};
const appsreducer = (state, action) => {
  if (typeof state === 'undefined') {
    return initialState;
  }  
  switch (action.type) {
    case FETCHING_APPSLIST:
      return { ...state,  userObject : action.payload };  
    case INVITE_POPUP:    
      return {...state, showPopup : action.payload};
    default:
      return state;
  }
};

export default appsreducer;


