import React from 'react';
import '../CreateNewRun/TestNewrun.css'
import { Modal } from "react-bootstrap";
import vector13 from '../../../assets/vector-13@2x.png';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { COMMISSION_SELECTED_RUN, getCommRunDetails } from '../actions';
import AuthService from "../../../../src/components/server/AuthService";
import { Oval } from "react-loader-spinner";
import { allNullTypeCheck, isNullResponse } from '../commonComponents/UtilityFun';
import NewSnackbar from "../../utilities/NewSnackbar";
import * as Constant from '../../../constants/Constants';
import actions from '../../../redux/actions/CommonDataActions';
import Moment from 'moment';

class TestNewRun extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoaderResult: true,
            showRunSuccessColModal: true,
            hideRunsuccessModal: false,
            commissionRunDetailsData: [],
            runsWaitTime:1
        }
    }
    componentDidMount() {
        const { runId } = this.props;
        if (allNullTypeCheck(runId)) {
            console.log("TestNewRun run id : ",runId);
            this.props.getcommissionRunDetails(runId);
        }
        else {
            this.setState({ showLoaderResult: false });
        }
    }
    componentDidUpdate(nextProps) {
        const { commissionRunDetails, commissionRunDetailsError, runId } = this.props;
        const { runsWaitTime } = this.state;
        if (commissionRunDetails !== nextProps.commissionRunDetails) {
          if(!commissionRunDetails.hasOwnProperty('status')){ 
                console.log("response run details called : ",commissionRunDetails);
                    this.setState({ showLoaderResult: false });
                if (isNullResponse(commissionRunDetails.data) && commissionRunDetails.error===false) {
                    this.setState({
                        commissionRunDetailsData: commissionRunDetails.data,
                    });
                }else if(Object.keys(commissionRunDetails?.data).length===0 && allNullTypeCheck(commissionRunDetails?.messages)) {
                    if(commissionRunDetails?.messages?.length > 0 && commissionRunDetails?.messages[0]?.includes("Try again...")){
                        console.log("Try again block entered : ",commissionRunDetails?.data);
                        this.setState({ showLoader: true });
                        setTimeout(() => {
                            this.props.getcommissionRunDetails(runId);
                        }, 1000 * runsWaitTime);
                        this.setState(prevState => {
                        return {runsWaitTime: prevState.runsWaitTime * 2}
                    })
                    }
                }
                else {
                    if(commissionRunDetails.error===true){
                        this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: typeof(commissionRunDetails?.messages[0])!=='string'? commissionRunDetails?.messages[0]?.code+' - '+commissionRunDetails?.messages[0]?.detail:commissionRunDetails?.messages[0] })
                    }
                
                }
            }else {
                if(commissionRunDetails.hasOwnProperty('status') && commissionRunDetails.status===202){ 
                    this.setState({ showLoader: true });
                    setTimeout(() => {
                        this.props.getcommissionRunDetails(runId);
                    }, 1000 * runsWaitTime);
                    this.setState(prevState => {
                    return {runsWaitTime: prevState.runsWaitTime * 2}
                })
              }
            } 
        } else if (commissionRunDetailsError !== nextProps.commissionRunDetailsError) {
            this.setState({ showLoaderResult: false });
            if (commissionRunDetailsError?.error===true) {
                this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: typeof(commissionRunDetailsError?.messages[0])!=='string'? commissionRunDetailsError?.messages[0]?.detail:commissionRunDetailsError?.messages[0] })
            }
        }
    }
    closeRunSuccessModal() {
        this.setState({ hideRunsuccessModal: true, showRunSuccessColModal: false })
        window.location.reload(false)
    }
    hideRunScuccesModal() {
        const { commissionRunDetails } = this.props;
        console.log("TestnewRun run details : ",commissionRunDetails.data);
        if (isNullResponse(commissionRunDetails.data) && commissionRunDetails.error!==true) {
            this.props.selectedRunIndex(commissionRunDetails?.data?.runId);
            this.props.history.push({
                pathname: '/rundetails',
                state: {
                    runid: commissionRunDetails?.data?.runId
                }
            });
        }
        this.setState({ hideRunsuccessModal: true, showRunSuccessColModal: false })
    }
    render() {
        const { showRunSuccessColModal, hideRunsuccessModal, commissionRunDetailsData, showLoaderResult } = this.state;
        if (AuthService.isAuthenticated()) {
            if (showLoaderResult) {
                return <div>
                    <div
                        id="center"
                        style={{ position: "fixed", top: "40%", left: "48%" }}
                    >
                        <Oval
                            type="Puff"
                            position="fixed"  secondaryColor="#E85557"
                            color="#E85557"
                            height={50}
                            width={100}
                            visible={true}
                        />{" "}
                    </div>
                </div>
            }
            else {
                return (
                    <Modal dialogClassName="viewrun-my-modal" show={showRunSuccessColModal} onHide={hideRunsuccessModal}>

                        <div className="rcs-a-run-created-successfully" data-id="802:8518">
                            <Modal.Header>
                                <div className="rcs-flex-row-1-ei">
                                    <div className="rcs-header-name cmnr-h3-header-3-18pt-bold-lato">Run Created Successfully</div>
                                    <img className="rcs-vector3-ei" src={vector13} onClick={this.closeRunSuccessModal.bind(this)} alt="" />
                                </div>
                            </Modal.Header>
                            <div className="rcs-flex-row-1" data-id="an|Zq0Z4seo">
                                <div className="rcs-group-2858" data-id="802:8520">
                                    <div className="rcs-run-type paragraph-2-12pt-lato">
                                        Run Type:
                                    </div>
                                    <div className="rcs-preliminary descvalue-style">
                                        {commissionRunDetailsData?.runType}
                                    </div>
                                </div>
                                <div className="rcs-group-2857" data-id="802:8523">
                                    <div className="rcs-name valign-text-middle paragraph-2-12pt-lato" data-id="802:8524">
                                        Name:
                                    </div>
                                    <div className="rcs-newrun1 descvalue-style" data-id="802:8525">
                                        {commissionRunDetailsData?.name}
                                    </div>
                                </div>
                            </div>
                            <div className="rcs-description valign-text-middle paragraph-2-12pt-lato">
                                Description:
                            </div>
                            <p className="rcs-text-5 description-style" data-id="802:8551">
                                {commissionRunDetailsData?.description}
                            </p>
                            <div className="rcs-flex-row-2" data-id="an|ZU8O7LPs">
                                <div className="rcs-group-2852" data-id="802:8529">
                                    <div className="rcs-created-on valign-text-middle paragraph-2-12pt-lato">
                                        Created on:
                                    </div>
                                    <div className="rcs-phone descvalue-style" data-id="802:8531">
                                        { Moment(commissionRunDetailsData?.created).format('MM/DD/YYYY HH:mm:ss') }
                                    </div>
                                </div>
                                {/* <div className="rcs-group-2853" data-id="802:8535">
                                <div className="rcs-status valign-text-middle paragraph-2-12pt-lato" data-id="802:8536">
                                    Status:
                                </div>
                                <div className="rcs-live-on-02142021 descvalue-style" data-id="802:8537">
                                   Created
                                </div>
                            </div> */}
                            </div>
                            <div className="rcs-group-2854" data-id="802:8526">
                                <div className="rcs-text-1 valign-text-middle paragraph-2-12pt-lato" data-id="802:8527">
                                    Qualification Program:
                                </div>
                                <div className="rcs-icebon descvalue-style" data-id="802:8528">
                                    {commissionRunDetailsData?.qualificationProgram}
                                </div>
                            </div>
                            <div className="rcs-group-2855" data-id="802:8532">
                                <div className="rcs-text-2 valign-text-middle paragraph-2-12pt-lato" data-id="802:8533">
                                    Calculation Program:
                                </div>
                                <div className="icebon descvalue-style" data-id="802:8534">
                                    {commissionRunDetailsData?.calculationProgram}
                                </div>
                            </div>
                            <div className="rcs-group-2856" data-id="802:8548">
                                <div className="rcs-text-4 valign-text-middle paragraph-2-12pt-lato" data-id="802:8549">
                                    Disbursement Program:
                                </div>
                                <div className="rcs-icebon descvalue-style" data-id="802:8550">
                                    {commissionRunDetailsData?.disbursementProgram}
                                </div>
                            </div>
                        </div>
                        <Modal.Footer>
                            <div className="row">
                                <div className="rcs-button" data-id="802:8539" onClick={this.hideRunScuccesModal.bind(this)}>
                                    <div className="rcs-button-1 valign-text-middle" data-id="I802:8539;4337:6250">
                                        OPEN RUN
                                    </div>
                                </div>
                            </div>
                        </Modal.Footer>
                        <NewSnackbar />
                    </Modal>
                );
            }
        }
    }
}
TestNewRun.propTypes = {
    rundetails: PropTypes.any,
}
const mapStateToProps = (state) => {
    return {
        commissionRunDetails: state.commissionRunReducer.commissionRunDetails,
        commissionRunDetailsError: state.commissionRunReducer.commissionRunDetailsError
    }
}

const mapDispatchToProps = (dispatch) => ({
    selectedRunIndex: (selectedRun) => dispatch({ type: COMMISSION_SELECTED_RUN, payload: selectedRun }),
    getcommissionRunDetails: (runID) => dispatch(getCommRunDetails(runID)),
    showSnackbar: (params) => dispatch({ type: actions.SNACKBAR_SHOW, payload: params }),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TestNewRun));



