import React,{useRef} from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'

function Input (props) {
  const inputRef = useRef(null);

  const { _label, _name, _type, ...rest } = props
  const togglePassword = (e,id) => {
    inputRef.current.type = inputRef.current.type === "text" ? "password" : "text";
    // let element = document.getElementById(id)
    // element.type = element.type === "text" ? "password" : "text";
    let imgSrc = e.target.src;
    e.target.src = imgSrc.includes("eye-closed") ? imgSrc.replace("eye-closed","eye") : imgSrc.replace("eye","eye-closed")
    
  }
  return _type === 'password' ? (
    <div className='form-control'>
      <label htmlFor={_name}>{_label}</label>
      <div>
        <Field innerRef={inputRef} id={_name} name={_name} type={_type} {...rest} />
        <span>
          <img id="eye-icon" onClick={(e)=>togglePassword(e,_name)} src='./eye-closed.png' alt='' />
        </span>
      </div>
      <ErrorMessage name={_name} component={TextError} />
    </div>
  ) : (
    <div className='form-control'>
      <label htmlFor={_name}>{_label}</label>
      <Field id={_name} name={_name} type={_type} {...rest} />
      <ErrorMessage name={_name} component={TextError} />
    </div>
  )
}

export default Input
