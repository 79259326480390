
import './ConfirmModel.css';
import vector13 from '../../../assets/vector-13@2x.png';
import { Modal} from "react-bootstrap";
import { allNullTypeCheck } from '../commonComponents/UtilityFun';

export const ConfirmModel = (props) =>{
    const { title, bodyModelText, bodyModelText1, enableModel, closeSelectedRun, selectRunStatus, okButtonTitle} = props;
    return(
    <Modal show={enableModel} onHide={false} >
      <Modal.Header>
          <div className="flex-row-modal-confirm">
            {/* <div className='row'> */}
              {/* <div className='col-9' style={{width: '100%', left: '10px'}}> */}
                <div className="header-name-modal h3-header-3-18pt-bold-lato">{title}</div>
              {/* </div> */}
              {/* <div className='div-cust col-3'> */}
                <img className="close-vector-3-ei" src={vector13} onClick={closeSelectedRun} alt="" />
              {/* </div> */}
            {/* </div> */}
          </div>
      </Modal.Header>
      <Modal.Body style={{height: '140px'}}>
        <div className=" paragraph-1-14pt-regular-lato" style={{marginTop: '25px'}}>{bodyModelText}</div>
        <div className=" paragraph-1-14pt-regular-lato">{bodyModelText1}</div>
        </Modal.Body>
      <Modal.Footer style={{marginTop: "0px"}}>
        <div className= "row">
        <div className="cmcolb-no-btn column" onClick={closeSelectedRun}>
        <div className="colb-no-text">CANCEL</div>
        </div>
        <div className="confirm-yes-btn column" onClick={selectRunStatus}>
          <div className="confirm-yes-text">{allNullTypeCheck(okButtonTitle)? okButtonTitle : 'OK'}</div>
        </div>
        </div>
      </Modal.Footer>
    </Modal>
    )
}