export const SINGLE_PERIODS_FETCHING = 'SINGLE_PERIODS_FETCHING';
export const SINGLE_PERIODS_SUCCESS = 'SINGLE_PERIODS_SUCCESS';
export const SINGLE_PERIODS_ERROR = 'SINGLE_PERIODS_ERROR';
export const SINGLE_PERIODS_PUT_SUCCESS = 'SINGLE_PERIODS_PUT_SUCCESS';
export const SINGLE_PERIODS_PUT_ERROR = 'SINGLE_PERIODS_PUT_ERROR';


const initialState = {
  singlePeriodData: {
    "periodType": "",
    "period": "",
    "startDate": "",
    "endDate": "",
    "name": "",
    "abbreviation": "",
    "bonusCheckDate": "",
    "bonusCompleted": false
    },
  singlePeriodDataError:{}, 
  singlePeriodPutError:{},
  error: {},
  fetching: false,
  success: false
}

export default function singlePeriodsReducer (state, action) {
  if (typeof state === 'undefined') {
    return initialState
  }
  switch (action.type) {
    case SINGLE_PERIODS_FETCHING:
      return { ...state, fetching: true }
    case SINGLE_PERIODS_SUCCESS:
      console.log("state single", {...state.singlePeriodData ,...action.payload})
      return {
        ...state,
        fetching: false,
        success: true,
        singlePeriodData: action.payload
      }
    case SINGLE_PERIODS_ERROR:
      return {
        ...state,
        fetching: false,
        success: false,
        singlePeriodDataError: action.payload
      }
     case SINGLE_PERIODS_PUT_SUCCESS: 
          return {
            ...state,
            fetching: false,
            success: true,
            singlePeriodData: action.payload
          }
    case SINGLE_PERIODS_PUT_ERROR:
          return {
            ...state,
            fetching: false,
            success: false,
            singlePeriodPutError: action.payload
          }
    default:
      return state
  }
}
