export const integration = {
  INTEGRATIONS_SUCCESS : 'INTEGRATIONS_SUCCESS',
  INTEGRATIONS_HASERROR : 'INTEGRATIONS_HASERROR',
  ENDPOINTRESULT_SUCCESS : 'ENDPOINTVALIDATION_SUCCESS',
  ENDPOINTRESULT_HASERROR : 'ENDPOINTRESULT_HASERROR',
  CALLBACKURL_SUCCESS : 'CALLBACKURL_SUCCESS',
  CALLBACKURL_HASERROR :'CALLBACKURL_HASERROR',
  EVENTSTYPELIST_SUCCESS : 'EVENTSTYPELIST_SUCCESS',
  EVENTSTYPELIST_HASERROR :'EVENTSTYPELIST_HASERROR',
  CREATEINTEGRATION_SUCCESS : 'CREATEINTEGRATION_SUCCESS',
  CREATEINTEGRATION_HASERROR : 'CREATEINTEGRATION_HASERROR',
  GETINTEGRATION_SUCCESS : 'GETINTEGRATION_SUCCESS',
  GETINTEGRATION_HASERROR : 'GETINTEGRATION_HASERROR',
  INTEGRATION_STATUS_SUCCESS : 'INTEGRATION_STATUS_SUCCESS',
  INTEGRATION_STATUS_HASERROR : 'INTEGRATION_STATUS_HASERROR',
  INTEGRATION_DELETE_SUCCESS : 'INTEGRATION_DELETE_SUCCESS',
  INTEGRATION_DELETE_HASERROR:'INTEGRATION_DELETE_HASERROR',
  OPTIONAL_FEATURE_SUCCESS : 'OPTIONAL_FEATURES_SUCCESS',
  OPTIONAL_FEATURE_HASERROR : 'OPTIONAL_FEATURE_HASERROR',
  FEATURES_POST_SUCCESS : 'FEATURES_POST_SUCCESS',
  FEATURES_POST_HASERROR : 'FEATURES_POST_HASERROR',
  CURRENT_INTEGRATIONID : 'CURRENT_INTEGRATIONID',
  CURRENT_INTEGRATION_NAME : 'CURRENT_INTEGRATION_NAME',
  CURRENT_INTEGRATION_RES : 'CURRENT_INTEGRATION_RES',
  GENERATEKEY_SUCCESS : 'GENERATEKEY_SUCCESS',
  GENERATEKAY_HASERROR : 'GENERATEKAY_HASERROR',
  EDIT_INTEGRATION_SUCCESS : 'EDIT_INTEGRATION_SUCCESS',
  EDIT_INTEGRATION_HASERROR : 'EDIT_INTEGRATION_HASERROR',
  POST_INTEGRATION_NAME_SUCCESS: 'POST_INTEGRATION_NAME_SUCCESS',
  POST_INTEGRATION_NAME_ERROR: 'POST_INTEGRATION_NAME_ERROR',
  POST_DEVTOKEN_SUCCESS: 'POST_DEVTOKEN_SUCCESS',
  POST_DEVTOKEN_ERROR: 'POST_DEVTOKEN_ERROR',
  ACTIVITY_DETAILS_SUCCESS: 'ACTIVITY_DETAILS_SUCCESS',
  ACTIVITY_DETAILS_ERROR: 'ACTIVITY_DETAILS_ERROR',
  WEBHOOK_SUCCESS : 'WEBHOOK_SUCCESS',
  WEBHOOK_HASERROR : 'WEBHOOK_HASERROR',
  EDIT_WEBHOOK_SUCCESS:'EDIT_WEBHOOK_SUCCESS',
  EDIT_WEBHOOK_HASERROR:'EDIT_WEBHOOK_HASERROR',
  GETWEBHOOK_INTEGRATION_SUCCESS: 'GETWEBHOOK_INTEGRATION_SUCCESS',
  GETWEBHOOK_INTEGRATION_HASERROR: 'GETWEBHOOK_INTEGRATION_HASERROR'
};
  