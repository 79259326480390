import React,{Suspense} from "react";
// import addIcon from '../../assets/samplecme.svg';
import AuthService from '../../../../src/components/server/AuthService';
// import '../CommissionsRunManager/CommissionRunManager.css'
import { Oval } from 'react-loader-spinner';
import { withRouter } from 'react-router-dom';
import Component2 from "../Component2";
import './settings.css'
import compareruns from "../assets/compareruns.svg";
import searchdistributor from "../assets/searchdistributor.svg";
import LoaderComponent from "./LoaderComponent";
const SettingsContainer = React.lazy(()=> import("./SettingsContainer"));

class SettingsHome extends React.Component {
  constructor(props) {
    super(props)

    this.state = {

    }
  }
  componentDidMount() {

  }
  compareRunClick() {
    this.props.history.push({
      pathname: "/comparerun",
    });
  }
  render() {
    if (AuthService.isAuthenticated()) {
      return (
        <div className="row"  style={{ display: 'flex','alignItems':'center',flexDirection:'column' }}>
          <div className="col-12" style={{maxWidth:'2000px'}}>
            <Component2 text19={"Settings"}/>
            <div className="rectangle-255"></div>
            <div className="flex">
              <div className="col-8 cmt-title">Settings</div>
              <div className="row" style={{ marginTop: '10px' }}>
                <div className="col-12 flex-container settings-container" >
                <Suspense fallback={<LoaderComponent/>}>
                  <SettingsContainer/>
                </Suspense>
                   
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return <div><div id="center" style={{ position: 'fixed', top: '40%', left: '48%' }}>
      <Oval type="Puff" position="fixed"  secondaryColor="#E85557" color="#E85557" height={50} width={100} visible={true} /> </div></div>;
  }
}

export default withRouter(SettingsHome);