import React from 'react';
import '../ViewRunLog/ViewRunLog.css'
import PropTypes from 'prop-types';
import AuthService from '../../../../src/components/server/AuthService';
import '../CommissionsRunManager/CommissionRunManager.css';
import { Oval } from 'react-loader-spinner';
import { connect } from 'react-redux';
import { Modal } from "react-bootstrap";
import closeIcon from '../assets/closeicon.svg';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withRouter } from 'react-router-dom';
import { getDistributorQualification, getRankMatrixInfoAPI } from '../actions';
import * as Constant from '../../../constants/Constants';
import { allNullTypeCheck, isNullResponse } from '../commonComponents/UtilityFun';
import NewSnackbar from "../../utilities/NewSnackbar";
import actions from '../../../redux/actions/CommonDataActions';
import { numberWithCommas } from "../../utilities/Services";
import vector13 from '../../../assets/vector-13@2x.png';
import "./GeneralTab.css";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import nodatafound from "../assets/nodatafound.svg";

let initialValue = 0.0;

const fields = [
    { name: 'ChequeNo', displayName: "Paid Rank", inputFilterable: true, sortable: true },
    { name: 'DistID', displayName: "Bv", inputFilterable: false, exactFilterable: true, sortable: true },
    { name: 'Name', displayName: "Org Volume", inputFilterable: false, exactFilterable: true, sortable: true },
    { name: 'CountryCode', displayName: "Active Leg", inputFilterable: true, sortable: true },
    { name: 'ChequeDate', displayName: "Org Volume", inputFilterable: false, exactFilterable: true, sortable: true },
    { name: 'DistType', displayName: "Org Volume-1", inputFilterable: false, exactFilterable: true, sortable: true },
];
let shortageSuccess=false;
const columns = [
    { id: 'name', label: 'Name', minWidth: 170 },
    { id: 'code', label: 'ISO\u00a0Code', minWidth: 100 },
    {
      id: 'population',
      label: 'Population',
      minWidth: 170,
      align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'size',
      label: 'Size\u00a0(km\u00b2)',
      minWidth: 170,
      align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'density',
      label: 'Density',
      minWidth: 170,
      align: 'right',
      format: (value) => value.toFixed(2),
    },
  ];
class RankMatrixModel extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            rankMatrixListState: [],
            selectedRunInfo: [],
            showLoaderResult: true,
            rankSelectionType:'',
            fieldMatrixListState:[],
            disableNewRun:true,
            showRemoveColModal: true,
            tabValue: 0,
            voluemListState:[],
            shortageSuccess:false,
            currentRank:'',
            rankName:'',
            runsWaitTime:1,
            retryDelayTime:1
            
        }
        //  this.viewLogTableDisplay = this.viewLogTableDisplay.bind(this);
    }

    componentDidMount() {
        const { distributorID, runID, detailKey } = this.props;
        console.log("props values :",distributorID,runID, detailKey)
        if (allNullTypeCheck(distributorID) && allNullTypeCheck(runID) && allNullTypeCheck(detailKey)) {
            this.setState({showLoaderResult: true, rankSelectionType:"option1"});
            this.props.getRankMatrix(runID, detailKey);
            this.props.getDistributorQualList(distributorID, runID, detailKey);
        }
    }
    componentDidUpdate(nextProps) {
        const { rankMatrixDetailsList, rankMatrixDetailsError, distributorQualList, distributorQualError, distributorID, runID, detailKey   } = this.props;
        const { showLoaderResult, currentRank, tabValue, rankMatrixListState, fieldMatrixListState, runsWaitTime, retryDelayTime } = this.state;
        if (rankMatrixDetailsList !== nextProps.rankMatrixDetailsList){
            console.log("response is : ",rankMatrixDetailsList);
            this.setState({showLoaderResult: false })
            if(!rankMatrixDetailsList.hasOwnProperty('status')){ 
            if(isNullResponse(rankMatrixDetailsList?.data) && rankMatrixDetailsList?.error === false) {
                if(tabValue===0 && isNullResponse(rankMatrixDetailsList?.data) && allNullTypeCheck(distributorQualList?.data?.rankId)){
                    this.setState({ rankMatrixListState : rankMatrixDetailsList?.data?.ranks?.filter((elment)=>((elment.rankId===distributorQualList?.data?.rankId+1)||(elment.rankId===distributorQualList?.data?.rankId))), fieldMatrixListState : rankMatrixDetailsList?.data?.qualificationFields },()=>{});
                    console.log("after response : ",rankMatrixListState, fieldMatrixListState);
                } else {
                    this.setState({rankMatrixListState : rankMatrixDetailsList?.data?.ranks, fieldMatrixListState : rankMatrixDetailsList?.data?.qualificationFields});
                    console.log("else  response : ",rankMatrixListState, fieldMatrixListState);
                }
            } else if(Object.keys(rankMatrixDetailsList?.data).length===0 && allNullTypeCheck(rankMatrixDetailsList?.messages)) {
                if(rankMatrixDetailsList?.messages?.length > 0 && rankMatrixDetailsList?.messages[0]?.includes("Try again...")){
                    console.log("Try again block entered : ",rankMatrixDetailsList?.data);
                    this.setState({showLoaderResult: true})
                    setTimeout(() => {
                        this.props.getRankMatrix(runID, detailKey);
                    }, 1000 * runsWaitTime);
                    this.setState(prevState => {
                    return {runsWaitTime: prevState.runsWaitTime * 2}
                    })
                    }
            }
           else if(rankMatrixDetailsList.error === true){
              this.setState({showLoaderResult: false });
                this.props.showSnackbar({snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: rankMatrixDetailsList?.messages[0]?.code +' - '+rankMatrixDetailsList?.messages[0]?.detail})
            }
        }else {
            if(rankMatrixDetailsList.hasOwnProperty('status') && rankMatrixDetailsList.status===202){
                this.setState({showLoaderResult: true})
                setTimeout(() => {
                    this.props.getRankMatrix(runID, detailKey);
                }, 1000 * runsWaitTime);
                this.setState(prevState => {
                return {runsWaitTime: prevState.runsWaitTime * 2}
                })
          }
        }
        }
        else if(rankMatrixDetailsError !== nextProps.rankMatrixDetailsError){
            this.setState({showLoaderResult: false });
            console.log("error is : ",rankMatrixDetailsError)
            if(rankMatrixDetailsError?.data?.error === true){
                this.props.showSnackbar({snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: typeof(rankMatrixDetailsError?.data?.messages[0])!== 'string'? rankMatrixDetailsError?.data?.messages[0]?.code +' - '+rankMatrixDetailsError?.data?.messages[0]?.detail : rankMatrixDetailsError?.data?.messages[0]})
               }
        }
        if (distributorQualList !== nextProps.distributorQualList){
            console.log("response  for distbutor : ",tabValue, distributorQualList, rankMatrixDetailsList?.data?.ranks, rankMatrixDetailsList?.data?.qualificationFields);
            this.setState({showLoaderResult: false });
            if(!distributorQualList.hasOwnProperty('status')){ 
                if(isNullResponse(distributorQualList?.data) && distributorQualList?.error === false) {
                    this.setState({ voluemListState: distributorQualList?.data?.qualificationValues, currentRank: distributorQualList?.data?.rankId, rankName: distributorQualList?.data?.label },()=>{});
                    if(tabValue===0 && isNullResponse(rankMatrixDetailsList?.data) && allNullTypeCheck(distributorQualList?.data?.rankId)){
                        this.setState({ rankMatrixListState : rankMatrixDetailsList?.data?.ranks?.filter((elment)=>((elment.rankId===distributorQualList?.data?.rankId+1)||(elment.rankId===distributorQualList?.data?.rankId))), fieldMatrixListState : rankMatrixDetailsList?.data?.qualificationFields },()=>{});
                        console.log("after response : ",rankMatrixListState, fieldMatrixListState);
                    } else {
                        this.setState({rankMatrixListState : rankMatrixDetailsList?.data?.ranks, fieldMatrixListState : rankMatrixDetailsList?.data?.qualificationFields});
                        console.log("else  response : ",rankMatrixListState, fieldMatrixListState);
                    }
                    
                } else if(Object.keys(distributorQualList?.data).length===0 && allNullTypeCheck(distributorQualList?.messages)) {
                    if(distributorQualList?.messages?.length > 0 && distributorQualList?.messages[0]?.includes("Try again...")){
                        console.log("Try again block entered : ",distributorQualList?.data);
                        this.setState({showLoaderResult: true});
                        setTimeout(() => {
                            this.props.getDistributorQualList(distributorID, runID, detailKey);
                        }, 1000 * retryDelayTime);
                        this.setState(prevState => {
                        return {retryDelayTime: prevState.retryDelayTime * 2}
                        })
                        }
                }
            else if(distributorQualList.error === true){
                this.setState({showLoaderResult: false });
                    this.props.showSnackbar({snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: distributorQualList?.messages[0]?.code +' - '+distributorQualList?.messages[0]?.detail})
                }
        }else {
            if(distributorQualList.hasOwnProperty('status') && distributorQualList.status===202){
                this.setState({showLoaderResult: true});
                setTimeout(() => {
                    this.props.getDistributorQualList(distributorID, runID, detailKey);
                }, 1000 * retryDelayTime);
                this.setState(prevState => {
                return {retryDelayTime: prevState.retryDelayTime * 2}
                })
           }
        }
        }
        else if(distributorQualError !== nextProps.distributorQualError){
            this.setState({showLoaderResult: false });
            console.log("error is : ",distributorQualError)
            if(distributorQualError?.data?.error === true){
                this.props.showSnackbar({snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: typeof(distributorQualError?.data?.messages[0])!== 'string'? distributorQualError?.data?.messages[0]?.code +' - '+distributorQualError?.data?.messages[0]?.detail : distributorQualError?.data?.messages[0]})
               }
        }
    }

    hideModal() {
        this.props.closefunction()
    }
      confirmModal() {
        this.setState({ showLoaderResult: true });
        this.hideModal();
        this.props.selectedRun(this.state.selectedCommissionRun);
      }  
    // handleOnChange=(e)=>{
    //     const { rankMatrixListState, rankSelectionType } = this.state;
    //     const { rankMatrixDetailsList, currentRankID } = this.props;
    //     this.setState({rankSelectionType : e.target.value},()=>{});
    //     console.log("selected value is : ",e.target.value);
    //      if(isNullResponse(rankMatrixDetailsList?.data)){
    //          if(e.target.value === "option2"){
    //             this.setState({rankMatrixListState : rankMatrixDetailsList?.data?.ranks?.filter((elment)=>(elment.rankId>currentRankID))},()=>{});
    //          }else {
    //             this.setState({rankMatrixListState : rankMatrixDetailsList?.data?.ranks});
    //          }
    //      }
    // }
    handleTabChange = async (event, newValue) => {
		const { rankMatrixDetailsList } = this.props;
        const { currentRank } = this.state;
        console.log("current ID is :",currentRank);
		this.setState({ tabValue: newValue});
        if(isNullResponse(rankMatrixDetailsList?.data)){
            if(newValue === 0){
               this.setState({rankMatrixListState : rankMatrixDetailsList?.data?.ranks?.filter((elment)=>((elment.rankId===currentRank+1)||(elment.rankId===currentRank)))},()=>{});
            }else {
               this.setState({rankMatrixListState : rankMatrixDetailsList?.data?.ranks});
            }
        }
	};
    colorDesider=(data, qualVal)=>{
        const { voluemListState } = this.state;
        let resultIs = false;
        // console.log("map list is ",data,qualVal,voluemListState);
        voluemListState.length>0 && voluemListState.forEach((volume,i)=>{
          if(volume?.label===qualVal?.label){
            //   console.log("result is ",parseFloat(volume?.value),parseFloat(data?.qualificationValues[data?.qualificationValues?.findIndex((val) =>{
            //     return val?.name === qualVal?.name
            // })]?.value),parseFloat(volume?.value) <= parseFloat(data?.qualificationValues[data?.qualificationValues?.findIndex((val) =>{
            //     return val?.name === qualVal?.name
            // })]?.value))
           if(parseFloat(volume?.value) >= parseFloat(data?.qualificationValues[data?.qualificationValues?.findIndex((val) =>{
                    return val?.name === qualVal?.name
                })]?.value)){
                    resultIs = true;
             }
         }})
         return resultIs;
        
    }
    getShotageResult=(value,lable,index)=>{
        let resultIs='',valCheck=0;
        const { rankMatrixListState, fieldMatrixListState } = this.state;
        if(rankMatrixListState?.length > 0 ){
            rankMatrixListState?.forEach((data, i)=>{
                fieldMatrixListState.forEach((qualVal,j)=>{
                    if(lable===qualVal.label){
                        // console.log("value check :",lable,value,parseFloat(data?.qualificationValues[data?.qualificationValues?.findIndex((val) =>{
                        //     return val?.name === qualVal?.name
                        //  })]?.value))
                        if(parseFloat(value) < parseFloat(data?.qualificationValues[data?.qualificationValues?.findIndex((val) =>{
                            return val?.name === qualVal?.name
                         })]?.value)){
                            if(valCheck < (parseFloat(data?.qualificationValues[data?.qualificationValues?.findIndex((val) =>{
                                return val?.name === qualVal?.name
                             })]?.value)-parseFloat(value))){
                                valCheck = (parseFloat(data?.qualificationValues[data?.qualificationValues?.findIndex((val) =>{
                                    return val?.name === qualVal?.name
                                 })]?.value)-parseFloat(value))
                                 shortageSuccess=true;
                             }
                            }else {
                            resultIs = ''
                         }
                    }
            })
        })
        return valCheck!==0? index<3? valCheck?.toFixed(2): valCheck :resultIs;
    }else { 
        return '';
    }
 }

    render() {
        let loader;
        const { showLoaderResult,rankMatrixListState, 
            fieldMatrixListState, disableNewRun,
            showRemoveColModal,
            tabValue,
            voluemListState,
            rankName,
            currentRank,
         } = this.state;
         const { detailKey } = this.props;
        if (AuthService.isAuthenticated()) {
        if (showLoaderResult) {
            loader = <div id="center" style={{ position: "fixed", bottom: "40%", left: "48%" }}>
                <Oval
                    type="Puff"
                    position="fixed"  secondaryColor="#E85557"
                    color="#E85557"
                    height={50}
                    width={50}
                    timeout={3000}
                />
            </div>
        }
            return (
                <div>
                <Modal
                dialogClassName="rankmatrix-modal"
                show={showRemoveColModal}
                enforceFocus={false}
                >
                <Modal.Header className="rank-matrix-model-header">
                    <div className="rank-matrix-header">
                    <div className="rank-matrix-header-name cmnr-h3-header-3-18pt-bold-lato">
                        Rank Matrix
                    </div>
                    <img
                        className="addcmpr-vector-ei"
                        src={vector13}
                        onClick={this.hideModal.bind(this)}
                        alt=""
                    />
                    </div>
                </Modal.Header>
                <Modal.Body style={{ height: "400px" }}>
                <div className='row rank-matrix-model-body'>
                <div className='col-12'>
                <div className="flex">
                    <div className="rowalign-center" style={{ height: '35px' }}>
                    <div className="col-8 h2title" style={{top:"30px"}}>
                           <div>
                           <div style={{marginTop:'25px'}}> 
                               <div className='rank-matrix-details'><label className='rank-matrix-label'>{"Distributor ID"}</label><span className='rank-matrix-values'>{this.props?.distributorID}</span></div>
                               <div className='rank-matrix-details'><label className='rank-matrix-label'>{"Name"}</label><span className='rank-matrix-values'>{rankName}</span></div>
                               <div className='rank-matrix-details'><label className='rank-matrix-label'>{"Current Rank"}</label><span className='rank-matrix-values'>{currentRank}</span></div>
                           </div>
                           <div className="row add-item-style rank-radio-container">
                           <div>
                            <Tabs
                                className="rank-matrix-tabs"
                                value={tabValue}
                                indicatorColor="primary"
                                select
                                textColor="primary"
                                onChange={this.handleTabChange}
                                aria-label="disabled tabs example"
                                >
                                <Tab className='rankmatrix-button' label="Next Rank" />
                                <Tab className='rankmatrix-button' label="All Ranks" />
                            </Tabs>
                            </div>
                          </div>
                        </div>
                        </div>
                        <div className="col-4 padding-right-0">
                        </div>
                    </div>
                    {/* <img className="updatelg-close-icon" onClick={()=>this.props.closefunction()} src={closeIcon} alt='' style={{ cursor: 'pointer' }} /> */}
                    
                    <hr
                            className='rank-horizontal-line'
                                style={{
                                color:'black',
                                border: '1px solid #DDDDDD',
                                height: '0px',

                                }}
                            />
                    <div className="col-12 rank-matrix-table-model-style" style={{display:"flex"}} >
                    
                      {/* <div className="col-3 rankmatrix-left-tabcontainer"> */}
                        {/* <TableContainer component={Paper} className = 'rank-matrix-height'>
                        {fieldMatrixListState?.length > 0 ? <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table" className='general-table-left'>
                                <TableHead  className='retailpf-table-header2'>
                                    <TableRow>
                                        <TableCell className='rankmatrix-table-header' component="th" scope="row">
                                            {"Paid Rank"}
                                        </TableCell>
                                    </TableRow >
                                    {rankMatrixListState?.length > 0? <TableRow>
                                            <TableCell style={{ top: 40 }} className='rank-matrix-left-myqualcell my-qual-row'>{"My Qual"}</TableCell>
                                    </TableRow>:''}                                                   
                                </TableHead>
                                <TableBody>      
                                    {rankMatrixListState?.length > 0 ?
                                        rankMatrixListState?.map((data, i)=>
                                            <TableRow key={i}>
                                            <TableCell className='rank-matrix-left-tabelcell'>{ allNullTypeCheck(data?.label)? data?.label: data?.rankId}</TableCell>
                                        </TableRow>
                                            )
                                        :''
                                    }
                                {(rankMatrixListState?.length > 0 && tabValue===1)? <TableRow>
                                        <TableCell className='rank-matrix-left-tabelcell shortage-style'>{"Shortage"}</TableCell>
                                </TableRow>:''}
                                </TableBody>
                            </Table>:''}
                            </TableContainer>
                            </div>
                            <hr className='line'/> */}
                            {/* <div className="col-12 distres-general-rightcontainer"> */}
                            <TableContainer component={Paper} className = 'rank-matrix-height'>
                            {fieldMatrixListState?.length > 0 ? <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table" className='general-table-left'>
                                <TableHead  className='rank-matrix-table-header'>
                                    <TableRow>
                                    <TableCell className='rankmatrix-table-header rank-border-linestyle' component="th" scope="row">
                                            {detailKey}
                                        </TableCell>
                                    {fieldMatrixListState?.length > 0? fieldMatrixListState?.map((row,i) => (
                                        (i <= voluemListState?.length)? <TableCell className='rank-matrix-table-header' component="th" scope="row">
                                            {row.label}
                                        </TableCell>:''
                                    )):''}
                                    </TableRow>
                                    <TableRow>
                                    {voluemListState?.length > 0 ? <TableCell style={{ top: 40 }} className='rank-matrix-left-myqualcell my-qual-row rank-border-linestyle'>{"My Qual"}</TableCell>:''}
                                        {voluemListState?.length > 0 ?
                                        voluemListState?.map((data,k)=>
                                        <TableCell  style={{ top: 40 }} className='rank-matrix-tabelcell my-qual-row'>{data.value}</TableCell>):''}
                                    </TableRow>                                                      
                                </TableHead>
                                <TableBody>       
                                    {rankMatrixListState?.length > 0 ?
                                        rankMatrixListState?.map((data, i)=>
                                            <TableRow key={i}>
                                                 <TableCell className='rank-matrix-left-tabelcell rank-border-linestyle'>{ allNullTypeCheck(data?.label)? data?.label: data?.rankId}</TableCell>
                                            {fieldMatrixListState.map((qualVal,j)=>
                                            <TableCell className={this.colorDesider(data, qualVal)? 'rank-matrix-tabelcell-green' : 'rank-matrix-tabelcell shortage-value-style'}>
                                                {data?.qualificationValues[data?.qualificationValues?.findIndex((val) =>{
                                               return val?.name === qualVal?.name
                                            })]?.value}</TableCell>)}
                                        </TableRow>
                                            )
                                        :''
                                    }
                                   {tabValue===0? <TableRow className='rank-matrix-tabelcell shortage-value-stylesuccess' style={{backgroundColor:"rgba(255, 58, 58, 0.08)"}}>
                                   {voluemListState?.length > 0 ?<TableCell className='rank-matrix-left-tabelcell shortage-style rank-shotage-border-linestyle'><span>{"Shortage"}</span></TableCell>:''}
                                    {voluemListState?.length > 0 ?
                                        voluemListState?.map((data,k)=>
                                        <TableCell className='rank-matrix-tabelcell shortage-value-stylesuccess'>{this.getShotageResult(data.value,data.label,k)}</TableCell>):''}
                                    </TableRow> :''}
                                </TableBody>
                            </Table>: <div className="row noreports data-text-style">
                            {/* <img src={noreports} alt='' /> */}
                           {showLoaderResult? loader : <TableCell colSpan={9} align='center' className="no-data-image-rankmatrix">
                                                <img src={nodatafound} alt="" />
                                            </TableCell>}
                        </div>}
                        </TableContainer>
                        {/* </div> */}
                        {/* {rankMatrixListState?.length === 0 ? <div className="row noreports data-text-style">
                            {/* <img src={noreports} alt='' /> */}
                            {/* No Data Available */}
                        {/* </div> : ''} */}
                        {loader}
                        <NewSnackbar/>
                    </div>
                </div>
                </div>
            </div>
            </Modal.Body>
                <Modal.Footer>
                    <div className="row">
            
                        <div style={{ backgroundColor: "#D93030" , cursor: "pointer",height: "30px", right: "0px", left: "50px",bottom: "0px" }} className=" col-6 cmnr-create-run" onClick={this.hideModal.bind(this)}> 
                        <div className="add-run-type-btn" style={{ color: 'white'}}>OK</div>              
                        </div>
                    </div>
                </Modal.Footer>
                {loader}
                </Modal>
            </div>
                );
            }
    }
}

RankMatrixModel.propTypes = {
    retprofitbackfunc: PropTypes.func
};
const mapStateToProps = (state) => {
    return {
        rankMatrixDetailsList: state.distributorResearchReducer.rankMatrixDetailsList,
        rankMatrixDetailsError: state.distributorResearchReducer.rankMatrixDetailsError,
        distributorQualList:state.distributorResearchReducer.distributorQualList,
        distributorQualError:state.distributorResearchReducer.distributorQualError,
    }
}

const mapDispatchToProps = (dispatch) => ({
    getRankMatrix: (selectedRunID, detailKey) => dispatch(getRankMatrixInfoAPI(selectedRunID, detailKey)),
    showSnackbar: (params) => dispatch({ type: actions.SNACKBAR_SHOW, payload: params}),
    getDistributorQualList:(distID,runID, detailKey) => dispatch(getDistributorQualification(distID, runID, detailKey))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RankMatrixModel));