import React, { Component } from "react";
import { Oval } from "react-loader-spinner";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { Icon } from 'semantic-ui-react';
import actions from "../../../redux/actions/CommonDataActions";
import actions1 from '../../../redux/actions/IntegrationPopUp';
import { copyToClipBoard, errorHandling } from '../../utilities/Services';
import { getIntegrationAPI, getGenerateKeyAPI, getWebhookIntegrationAPI, postDevToken, getActivityDetails } from '../../../redux/thunk/integrationAPIThunk'
import { getCollaboratorsListapi } from '../../../redux/thunk/userManagementAPIThunk';
import { integration } from '../../../redux/actions/integrationDataActions'
import AuthService from '../../server/AuthService';
import * as Constants from '../../../constants/Constants';
import "./ViewSummary.css";
import copy from '../../../assets/copy.svg';
import line28 from '../../../assets/line-28@1x.png';
import vector85 from "../../../assets/vector-85@2x.png";
import rectangle117 from "../../../assets/rectangle-117-1@2x.png";
import Activity from "../ActivityCard/index";
import IntegrationDetails from '../IntegrationDetails/index';
import EditIntegration from '../EditIntegration/index';
import vector70 from '../../../assets/vector-70@2x.png';
import vector71 from '../../../assets/vector-71@2x.png';
import vector112 from '../../../assets/vector-112@2x.png';
import vector74 from '../../../assets/vector-74@2x.png';
import vector75 from '../../../assets/vector-75@2x.png';
import pencil from '../../../assets/pencil.svg';
import backarrow from '../../../assets/backarrow.svg';
import addred from '../../../assets/addnewwebhk.svg';
import eyeIcon from '../../../assets/eyeIcon.svg';
import info from '../../../assets/info.svg';
import rectangle285 from '../../../assets/rectangle-285@2x.png';
import { Tooltip } from '@material-ui/core';
//import rectangle11
import AddWebhook from '../AddWebhook/index';
import NewSnackbar from "../../utilities/NewSnackbar";

class ViewSummary extends Component {
  constructor(props) {
    super(props);
    this.showEditIntegration = this.showEditIntegration.bind(this)
    this.editIntegrationComponentBack = this.editIntegrationComponentBack.bind(this);
    //this.editWebhookEventHandle = this.editWebhookEventHandle.bind(this)
    this.state = {
      Name: 'NAME HERE',
      integrationResult: 'success',
      showPassword: false,
      devToken: '',
      integrationID: '',
      integrationSecret: '',
      showLoaderViewResult: true,
      showSecretKey: false,
      secretKey: '***********************************************',
      tempDevToken: '',
      developerToken: '',
      editIntegration: false,
      showSummary: true,
      webhooksList: [],
      showPopup: false,
      webhookType: Constants.TEXT_NEW_WEBHOOK,
      selectedWebhook: {},
      isWebhookCreated: false,
      snackbarOpen: false,
      snackbarType: '',
      snackbarMessage: '',
      count: 0,
      deactiveWebhooks: [],
      activeWebhooks: []
    };
  }
  componentDidMount() {
    console.log("I ID", this.props.params);
    if (AuthService.isTokenValidCheck() === true) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("current integrationID : ", this.props.currentIntegrationID) }
      this.setState({ showLoaderViewResult: true });
      this.props.callGetIntegrationAPI(this.props.params);
      //this.props.callAPIGetActivityDetails(this.props.params);
      // this.props.showViewIntegrationsShowSummary()
    }
  }
  devTokenCall(){
    console.log("this.props.getIntegrationResponse.data", this.props.getIntegrationResponse.data);
    let devToken_Params = {}
    if(this.props.getIntegrationResponse.data.length > 0){
    devToken_Params[Constants.RESETSECRET_ACTION] = this.props.getIntegrationResponse.data[0].integrationSecret
    devToken_Params[Constants.PARAM_INTEGRATIONID] = this.props.getIntegrationResponse.data[0].integrationID
    devToken_Params[Constants.PARAM_SCOPE]  = Constants.TEXT_DEVTOKEN_SCOPES
    devToken_Params[Constants.PARAM_EXPIRY] =  Constants.TEXT_DEVTOKEN_EXPIRY
    this.props.generateDevToken(devToken_Params)
    }
  }
  componentWillUnmount(){
    this.props.getIntegrationResponse.data = []
  }
  componentDidUpdate(nextProps) {

    if (this.props.getIntegrationResponse !== nextProps.getIntegrationResponse) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Get integration ", this.props.getIntegrationResponse) }
      if (this.props.getIntegrationResponse.error === false) {
        if (this.props.getIntegrationResponse.data && this.props.getIntegrationResponse.data.length > 0) {
          console.log("integration Details", this.props.getIntegrationResponse.data[0]);
          this.setState({ integrationID: this.props.getIntegrationResponse.data[0].integrationID, count : this.state.count+1});
          this.setState({ integrationSecret: this.props.getIntegrationResponse.data[0].integrationSecret });
          this.setState({ Name: this.props.getIntegrationResponse.data[0].name });
          this.props.integrationResponse(this.props.getIntegrationResponse.data[0]);
          this.props.callgetWebhookIntegrationAPI(this.props.getIntegrationResponse.data[0].integrationID);
          console.log("this.state.count",this.state.count)
          if(this.state.count < 1){
            this.devTokenCall()
          }
        }
        this.setState({ showLoaderViewResult: false });
      }
    } else if ((this.props.getIntegrationError !== nextProps.getIntegrationError)) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Get integration error ", this.props.getIntegrationError) }
      this.setState({ showLoaderViewResult: false });
      errorHandling(this.props.getIntegrationError);
    }
    if (this.props.devtokenResposne !== nextProps.devtokenResposne) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Devtoken Resposne ", this.props.devtokenResposne) }
      if (this.props.devtokenResposne.error === false) {
        if (this.props.devtokenResposne.data !== null && this.props.devtokenResposne.data !== '') {
         // console.log("devtokenResposne Details", this.props.devtokenResposne.data.dev_token);
          this.setState({ tempDevToken: this.props.devtokenResposne.data.dev_token });
          this.setState({ developerToken: this.props.devtokenResposne.data.dev_token });
        }
        this.setState({ showLoaderViewResult: false });
      }
    } else if ((this.props.devtokenError !== nextProps.devtokenError)) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Devtoken Resposne error ", this.props.devtokenError) }
      this.setState({ showLoaderViewResult: false });
      errorHandling(this.props.devtokenError);
    }
    if (this.props.editIntegrationResponse !== nextProps.editIntegrationResponse) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Edit integration ", this.props.editIntegrationResponse) }
      if (this.props.editIntegrationResponse.error === false) {
      //  console.log('edit integration res', this.props.editIntegrationResponse.data, this.state.showPassword)
        let switchState = localStorage.getItem('switchstate');
       // console.log('details switchState', switchState)
        if (switchState === "true" && this.state.showSummary === true) {
          this.props.showSnackbar({snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.success, snackbarMessage: Constants.TEXT_INTEGRATION_ENABLE})
        }
        else {
          if (switchState === "false" && this.state.showSummary === true) {
            this.props.showSnackbar({snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.success, snackbarMessage: Constants.TEXT_INTEGRATION_DISABLED})
          }
        }
      } else {
        this.props.showSnackbar({snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.warning, snackbarMessage: Constants.ALERT_TECHNICAL_ERROR})
      }
    } else if ((this.props.editIntegrationError !== nextProps.editIntegrationError)) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Integration Enable/Disable Error ", this.props.editIntegrationError) }
      this.setState({ showLoaderViewResult: false });
      errorHandling(this.props.editIntegrationError)
    }
    if (this.props.getwebhookIntegrationResposne !== nextProps.getwebhookIntegrationResposne) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Get webhook integration ", this.props.getwebhookIntegrationResposne) }
      this.setState({ showLoaderViewResult: false })
      if (this.props.getwebhookIntegrationResposne.error === false) {
        if (this.props.getwebhookIntegrationResposne.data.length > 0) {
          //REF:- https://www.cluemediator.com/sort-an-array-by-date-in-javascript
         const sortedwebhooks = this.props.getwebhookIntegrationResposne.data.slice().sort((a, b) => b.modifiedDate > Date.now ? 1: -1);
         this.setState({ webhooksList: sortedwebhooks });
         console.log('sortedwebhook', sortedwebhooks)
         this.props.getwebhookIntegrationResposne.data.forEach((webhook) => {
          if (webhook.active === 1) {
            this.state.activeWebhooks.push(webhook);
          } else if (webhook.active === 0) {
            this.state.deactiveWebhooks.push(webhook);
          }
        });
        }
      }
    } else if ((this.props.getwebhookIntegrationError !== nextProps.getwebhookIntegrationError)) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Get integration error ", this.props.getIntegrationError) }
      this.setState({ showLoaderViewResult: false })
      errorHandling(this.props.getwebhookIntegrationError);
    }
  }
  copyOption(copyValue) {
    if (copyValue) {
      copyToClipBoard(copyValue, Constants.TEXT_COPYTOCLIPBOARD, 5000, Constants.RED_COLOR);
    }
  }
  generateSecretKey() {
    let length = 32;
    let charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let retVal = '';
    const crypto = window.crypto || window.msCrypto;
    let array = new Uint32Array(1);
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(crypto.getRandomValues(array) * n));
    }
    return retVal;
  }
  handleBackAction() {
    this.props.showTopIntegrationOption()
    this.props.showViewIntegrationsShowSummary()
    if (this.props.options !== null && this.props.options === 'LandingPage') {
      this.props.backfunc('Landingpage', this.props.params);
    }
    else {
      this.props.backfunc(Constants.TEXT_SUMMARY, this.props.params);
    }
  }
  onTogglePassword() {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword,
    }));
  }
  copyID(copyValue) {
    // alert('Text-Copied')
    if (copyValue) {
      copyToClipBoard(copyValue, Constants.TEXT_COPYTOCLIPBOARD, 5000, Constants.RED_COLOR);
      this.props.showSnackbar({snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.info, snackbarMessage: Constants.TEXT_COPYTOCLIPBOARD})
    }
  }
  copySecret(value) {
    // alert('Text-Copied')
    if (value) {
      copyToClipBoard(value, Constants.TEXT_COPYTOCLIPBOARD, 5000, Constants.RED_COLOR);
      this.props.showSnackbar({snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.info, snackbarMessage: Constants.TEXT_COPYTOCLIPBOARD})
    }
  }
  copyToken(value) {
    // alert('Text-Copied')
    if (value) {
      copyToClipBoard(value, Constants.TEXT_COPYTOCLIPBOARD, 5000, Constants.RED_COLOR);
      this.props.showSnackbar({snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.info, snackbarMessage: Constants.TEXT_COPYTOCLIPBOARD})
    }
  }
  changeSecretView() {
    if (this.state.showSecretKey === true) {
      this.setState({
        showSecretKey: false
      })
    } else {
      this.setState({
        showSecretKey: true
      })
    }
  }
  showEditIntegration() {
    this.props.hideViewIntegrationsSummary()
    this.props.showEditIntegrationItem()
    this.setState({ editIntegration: true, showSummary: false })
  }
  editIntegrationComponentClick() {
    console.log('edit clicked')
  }
  editIntegrationComponentBack(item, props) {
    this.props.showLoader()
    console.log('edit clicked back', item, props)
    this.props.hideEditIntegrationItem()
    this.props.showViewIntegrationsShowSummary()
    if (item === Constants.TEXT_EDIT_INTEGRATION) {
      this.setState({ showSummary: true, editIntegration: false, showLoaderViewResult: true, count: 0})
      this.props.callGetIntegrationAPI(this.props.params);
    }
  }

  editWebhookEventHandle(webhook) {
    //alert('webhookname',webhook.name)
    this.setState({
      showPopup: !this.state.showPopup
    });   
    this.setState({selectedWebhook:webhook});    
    this.setState({webhookType:Constants.TEXT_EDIT_WEBHOOK})  
  }
  togglePopupWebhook() {
   this.setState({deactiveWebhooks:[], activeWebhooks: []}); 
    this.setState({
      showPopup: !this.state.showPopup
    });
   // this.props.showLoader();
    this.setState({isWebhookCreated:true, selectedWebhook:{}, webhookType:Constants.TEXT_NEW_WEBHOOK});
    this.props.callgetWebhookIntegrationAPI(this.state.integrationID);
  }
  openAddWebhook() {
    this.setState({
      showPopup: !this.state.showPopup
    });
  }

  isUpper(str) {
    return !/[a-z]/.test(str) && /[A-Z]/.test(str);
  }
  truncate(str) {
   if(this.isUpper(str)){
    return  str.length > 20 ? str.substring(0, 30) : str
   }else
    return str.length > 25 ? str.substring(0, 30) : str;
  }
  render() {
    // const { showPassword } = this.state;
    if (this.state.showLoaderViewResult) {
      return <div id="center" style={{ position: "fixed", top: "40%", left: "48%" }}>
        <Oval
          type="Puff"
          position="fixed"  secondaryColor="#E85557"
          color="#E85557"
          height={50}
          width={50}
          timeout={5000}
        />
      </div>
    }

    return (
      <div>
        {this.state.showSummary ? <div className="wdth">
          {/* <div className=""> */}
          <div>
            {/* <img src={backarrow} style={{cursor: 'pointer', marginTop: '10px', marginLeft: '4px' }} alt="" onClick={this.handleBackAction.bind(this)}/>
            <h1
              className="title valign-text-middle title lato-bold-mine-shaft-32px"
              style={{ marginTop: '-27px', marginLeft: '21px' }}
            >
              {"Summary"}
            </h1> */}
            <div className='row group2733' style={{ marginLeft: "4px", marginTop: '10px'}} >
                <img src={backarrow} style={{ cursor: 'pointer', height: '17px', width:'17px', marginTop: '7px'}} alt="" onClick={this.handleBackAction.bind(this)}/>
                <div className="edit-integration-13">Summary</div>
              </div>
            <div className="row" style={{ marginTop: "15px" }}>
              <p className="text-1-date lato-normal-mine-shaft-14px" style={{ marginLeft: "30px" }}>
                This page contains all the summary of integration. You can also edit
                your integration here.
              </p>
              <div className='row group2733' style={{ marginLeft: "290px",  cursor: 'pointer' }} onClick={this.showEditIntegration.bind(this)}>
                <img src={pencil} style={{ width: "16px", height: '16px' }} alt="" />
                <div className="edit-integration-1">EDIT INTEGRATION</div>
              </div>
            </div>

            <IntegrationDetails tempIntegrationID={this.props.params} />
            <div className="row" style={{ float: "left", width: "47%", height: "280px", marginLeft: "10px", marginTop: "20px" }}>
              <div className="group-2745">
                <div className="overlap-group1-vs border-1px-seashell">
                  <div className="security lato-bold-mine-shaft-18px">Security</div>
                  <div className="group-270">
                    <Group2606
                      integrationId="Integration ID" dataToDisplay="This is the unique identifier assigned to your integration."
                      moreInformation1Props={group2606Data.moreInformation1Props}
                    />
                    <div className="group-269">
                      <div className="overlap-group1-2-vs" >
                        <div
                          className="text-4-vs valign-text-middle courier-regular-normal-fuscous-gray-14px"
                          style={{ marginRight: "90px", fontSize: "12px" }}>
                          {this.state.integrationID}
                        </div>
                        <div onClick={this.copyID.bind(this, this.state.integrationID)}>
                          <Group2543
                            group2543={rectangle117}
                            vector2={vector74}
                            vector3={vector75}
                            className="group-2542"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="group-270">
                    <Group2608
                      secretKey="Secret Key" dataToDisplay="This is a secret key value used to ensure only authorized applications that know this secret may use this integration."
                      moreInformation1Props={group2608Data.moreInformation1Props}
                    />
                    <div className="group-269" style={{ width: "70%" }}>
                      <div className="overlap-group1-2-vs" >
                        <div className="text-3-vs valign-text-middle" style={{ fontSize: "12px", marginTop: '4px', marginRight: this.state.showSecretKey ? '9px': ''}}>
                          {this.state.showSecretKey ? this.state.integrationSecret : this.state.secretKey}
                        </div>
                        {this.state.showSecretKey ? <i aria-hidden="true" class="eye big icon" style={{ fontSize: '1.3em', color: '#666666', cursor: 'pointer' }} onClick={this.changeSecretView.bind(this)}></i> :
                             <img className="vector-7-vs" src={eyeIcon} alt='' onClick={this.changeSecretView.bind(this)} style={{ cursor: 'pointer'}}/> }
                        <div onClick={this.copySecret.bind(this, this.state.integrationSecret)}>

                          <Group2543
                            group2543="https://anima-uploads.s3.amazonaws.com/projects/607ff5beb95afd36a6b5e6c3/releases/60814525f4556b65c033cbd7/img/rectangle-117-1@2x.png"
                            vector2="https://anima-uploads.s3.amazonaws.com/projects/607ff5beb95afd36a6b5e6c3/releases/60814525f4556b65c033cbd7/img/vector-74@2x.png"
                            vector3="https://anima-uploads.s3.amazonaws.com/projects/607ff5beb95afd36a6b5e6c3/releases/60814525f4556b65c033cbd7/img/vector-75@2x.png"
                            className="group-2542"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <p
                    className="text-2 lato-normal-granite-gray-14px"
                    style={{ minWidth: "390px", marginTop: "20px", marginLeft: "15px" }}
                  >
                    This is a temporary token for developer testing only. Token will
                    expire in 4 hours.
                  </p>
                  <div className="group-270">
                    <Group2610
                      developerToken="Developer Token" dataToDisplay="This is a temporary token for developer testing only. The token will expire in 4 hours."
                      moreInformation1Props={group2610Data.moreInformation1Props}
                    />
                    <div className="group-2697">
                      <div className="overlap-group1-2-vs" style={{
                        overflow: "hidden", whiteSpace: "nowrap",
                        backgroundImage: `url("https://anima-uploads.s3.amazonaws.com/projects/607ff5beb95afd36a6b5e6c3/releases/60814525f4556b65c033cbd7/img/rectangle-214@2x.png")`,
                      }}>
                        {/* <img
                      className="rectangle-110"
                      src="https://anima-uploads.s3.amazonaws.com/projects/607ff5beb95afd36a6b5e6c3/releases/60814525f4556b65c033cbd7/img/vector-1@2x.png"
                    /> */}
                        <div className="text-5-vs valign-text-middle courier-regular-normal-fuscous-gray-14px" style={{ fontSize: "12px", marginLeft: '-4px', marginTop: '-4px' }}>
                          {this.state.tempDevToken !== null ? (this.state.tempDevToken).substr(0,55): ''}
                        </div>
                        <div onClick={this.copyToken.bind(this, this.state.developerToken)}>
                          <Group2543
                            group2543="https://anima-uploads.s3.amazonaws.com/projects/607ff5beb95afd36a6b5e6c3/releases/60814525f4556b65c033cbd7/img/rectangle-117-1@2x.png"
                            className="group-2542"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <img
                    className="line-29"
                    src={line28}
                    alt="" />
                  <p className="text-1-content valign-text-middle lato-normal-fuscous-gray-14px">
                    <span style={{marginBottom: '5px'}}>
                      <span className="span lato-normal-fuscous-gray-14px">
                        Use these integration credentials to
                      </span>
                      <span className="span lato-medium-fuscous-gray-14px">
                        &nbsp;
                      </span>
                      <span className="span lato-bold-fuscous-gray-14px">
                        access Infotrax APIs from your application
                      </span>
                      <span className="span lato-medium-fuscous-gray-14px">. </span>
                      <span className="span lato-normal-fuscous-gray-14px">
                        They are also available in subsequent tabs on this page.
                      </span>
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <div className="row" style={{ width: "50%", marginTop: "20px" }}>
              <Activity integrationId={this.props.params} />
              <div className='group-2744'>
                <div className='overlap-group-ac-lswbh border-1px-seashell'>
                  <div className='smry-whk-title-text lato-bold-mine-shaft-18px'>Active Webhooks
                   <div className="text-new-webhook-sm h4-header-4--16pt-bold-lato" style={{cursor: 'pointer'}} onClick={this.openAddWebhook.bind(this)}>
                  <img src={addred} className="wbh-add-btn-css" alt=""/> 
                   <p style={{marginTop: '-16px', fontSize: '1.0em'}}>ADD A NEW WEBHOOK</p>
                   </div>
                   </div>
                  <div className="wbhrow-div-style" style={{overflowY: this.state.webhooksList.length > 4 ? 'scroll': 'hidden',scrollbarWidth: 'thin'}}>
                  {this.state.activeWebhooks.length > 0 && this.state.webhooksList.length > 0 ?
                      this.state.webhooksList.map((integration, key) => {
                        return <div key={key}>
                          { integration.active ? <div className="flex-row-77-wbh1 row" style={{height: '47px', marginTop: this.state.webhooksList.length > 1 ? '10px': ''}}>
                       <div className="overlap-group-sumwl-one border-1px-alto" style={{border: "1px solid #DDDDDD", cursor: 'pointer', boxSizing:'border-box', boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.05)'}} onClick={this.editWebhookEventHandle.bind(this, integration)}>
                   <div className="webhook-name valign-text-middle h4-webhk-title-css">{this.truncate(integration.name)}</div>
                 </div>
                 </div>: '' }  
                 </div> 
                     })
                    : <div style={{ marginTop: "94px", marginLeft: "37%", color: "#555555" }}> No Active Webhooks Yet.</div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> : null}
        <NewSnackbar/>
        {this.state.editIntegration ? <EditIntegration func={this.editIntegrationComponentClick} params={this.state.integrationID} backfunc={this.editIntegrationComponentBack} /> : null}
        {this.state.showPopup ? <AddWebhook val={this.state.showPopup}
           webhookType={this.state.webhookType}
           selectedWebhook ={this.state.selectedWebhook}
           name={this.state.Name}
           integrationID = {this.state.integrationID}
           closePopup={this.togglePopupWebhook.bind(this)}
            /> : null}
      </div>
    );
  }
}
ViewSummary.propTypes = {
  func: PropTypes.func,
  params: PropTypes.any,
  backfunc: PropTypes.func,
}
const mapStateToProps = state => {
  return {
    userDetails: state.userDataReducer.userDetails,
    userHasError: state.userDataReducer.userHasError,
    isFetchingData: state.commonDataReducer.isFetchingData,
    localChangesExist: state.commonDataReducer.localChangesExist,
    createIntegrationResponse: state.integrationDataReducer.createIntegrationResponse,
    createIntegrationError: state.integrationDataReducer.createIntegrationError,
    getIntegrationResponse: state.integrationDataReducer.getIntegrationResponse,
    getIntegrationError: state.integrationDataReducer.getIntegrationError,
    currentIntegrationID: state.integrationDataReducer.currentIntegrationID,
    collaboratorsDetails: state.userDataReducer.collaboratorsDetails,
    collaboratorsHasError: state.userDataReducer.collaboratorsHasError,
    generateKeyResponse: state.integrationDataReducer.generateKeyResponse,
    generateKeyHasError: state.integrationDataReducer.generateKeyH,
    isShowTopIntegrationOption: state.integrationPopUpReducers.isShowTopIntegrationOption,
    editIntegrationResponse: state.integrationDataReducer.editIntegrationResponse,
    editIntegrationError: state.integrationDataReducer.editIntegrationError,
    isEditIntegrationItem: state.integrationDataReducer.isEditIntegrationItem,
    getwebhookIntegrationResposne: state.integrationDataReducer.getwebhookIntegrationResposne,
    getwebhookIntegrationError: state.integrationDataReducer.getwebhookIntegrationError,
    devtokenResposne: state.integrationDataReducer.devtokenResposne,
    devtokenError: state.integrationDataReducer.devtokenError,
  };
};
const mapDispatchToProps = dispatch => ({
  showLoader: () => dispatch({ type: actions.FETCHING_DATA_LOADER, payload: true }),
  hideLoader: () => dispatch({ type: actions.HIDE_DATA_LOADER, payload: false }),
  callGetIntegrationAPI: (integrationID) => dispatch(getIntegrationAPI(integrationID)),
  getCollaboratorsList: () => dispatch(getCollaboratorsListapi()),
  callGenerateKeyAPI: () => dispatch(getGenerateKeyAPI()),
  showTopIntegrationOption: () => dispatch({ type: actions1.SHOW_TOP_INTEGRATIONS, payload: true }),
  hideTopIntegrationOption: () => dispatch({ type: actions1.HIDE_TOP_INTEGRATIONS, payload: false }),
  integrationResponse: (integrationResponse) => dispatch({ type: integration.CURRENT_INTEGRATION_RES, payload: integrationResponse }),
  showEditIntegrationItem: () => dispatch({ type: actions1.SHOW_EDITINTEGRATION_ITEM, payload: true }),
  hideEditIntegrationItem: () => dispatch({ type: actions1.HIDE_EDITINTEGRATION_ITEM, payload: false }),
  hideViewIntegrationsSummary: () => dispatch({ type: actions1.HIDE_COMPLETE_SUMMARY_INTEGRATIONS, payload: false }),
  showViewIntegrationsShowSummary: () => dispatch({ type: actions1.SHOW_COMPLETE_SUMMARY_INTEGRATIONS, payload: false }),
  callgetWebhookIntegrationAPI: (integrationID) => dispatch(getWebhookIntegrationAPI(integrationID, '')),
  generateDevToken: (params) => dispatch(postDevToken(params)),
  showSnackbar: (params) => dispatch({ type: actions.SNACKBAR_SHOW, payload: params}),
  callAPIGetActivityDetails:(integrationID)=>dispatch(getActivityDetails(integrationID)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewSummary)

function Group2606(props) {
  const { integrationId, moreInformation1Props, dataToDisplay } = props;

  return (
    <div className="group-2606">
      <div className="integration-id-vs valign-text-middle lato-medium-fuscous-gray-16px">
        {integrationId}
      </div>
      <MoreInformation1
        vector2={moreInformation1Props.vector2}
        vector3={moreInformation1Props.vector3}
        vector4={moreInformation1Props.vector4}
        toolTipTitle={dataToDisplay}
      />
    </div>
  );
}

function MoreInformation1(props) {
  const { vector2, vector3, vector4, className, toolTipTitle } = props;

  return (
    <div className={`more-information1-2 ${className || ""}`}>
      <div className="overlap-group1-mi">
        <Tooltip title={toolTipTitle} placement="top" arrow>
          <img
            className="vector-2-vs"
            src={info}
          />
        </Tooltip>
        {/* <img className="vector-3-vs" src={vector2} />
        <img className="vector-vs" src={vector3} />
        <img className="vector-1-vs" src={vector4} /> */}
      </div>
    </div>
  );
}

function Group2543(props) {
  const { group2543, vector2, vector3, className } = props;

  return (
    <div className={`group-2543 ${className || ""}`} style={{ cursor: 'pointer'}}>
      <div className="copy1">
        <div className="overlap-group1-1-vs">
          <img className="vector-6-vs" src={copy} alt="" />
        </div>
      </div>
    </div>
  );
}

function Group2608(props) {
  const { secretKey, moreInformation1Props, dataToDisplay } = props;

  return (
    <div className="group-2608">
      <div className="secret-key valign-text-middle lato-medium-fuscous-gray-16px">
        {secretKey}
      </div>
      <MoreInformation1
        vector2={moreInformation1Props.vector2}
        vector3={moreInformation1Props.vector3}
        vector4={moreInformation1Props.vector4}
        className="more-information1-1"
        toolTipTitle={dataToDisplay}
      />
    </div>
  );
}

function Group2610(props) {
  const { developerToken, moreInformation1Props, dataToDisplay } = props;

  return (
    <div className="group-2610">
      <div className="developer-token valign-text-middle">{developerToken}</div>
      <MoreInformation1
        vector2={moreInformation1Props.vector2}
        vector3={moreInformation1Props.vector3}
        vector4={moreInformation1Props.vector4}
        className="more-information1"
        toolTipTitle={dataToDisplay}
      />
    </div>
  );
}

const moreInformation1Data = {
  vector2: vector70,
  vector3: vector71,
  vector4: vector112,
};

const group2606Data = {
  moreInformation1Props: moreInformation1Data,
};

const moreInformation12Data = {
  vector2:
    "https://anima-uploads.s3.amazonaws.com/projects/607ff5beb95afd36a6b5e6c3/releases/60814525f4556b65c033cbd7/img/vector-70@2x.png",
  vector3:
    "https://anima-uploads.s3.amazonaws.com/projects/607ff5beb95afd36a6b5e6c3/releases/60814525f4556b65c033cbd7/img/vector-71@2x.png",
  vector4:
    "https://anima-uploads.s3.amazonaws.com/projects/607ff5beb95afd36a6b5e6c3/releases/60814525f4556b65c033cbd7/img/vector-95@2x.png",
};

const group2608Data = {
  moreInformation1Props: moreInformation12Data,
};

const moreInformation13Data = {
  vector2:
    "https://anima-uploads.s3.amazonaws.com/projects/607ff5beb95afd36a6b5e6c3/releases/60814525f4556b65c033cbd7/img/vector-117@2x.png",
  vector3:
    "https://anima-uploads.s3.amazonaws.com/projects/607ff5beb95afd36a6b5e6c3/releases/60814525f4556b65c033cbd7/img/vector-118@2x.png",
  vector4:
    "https://anima-uploads.s3.amazonaws.com/projects/607ff5beb95afd36a6b5e6c3/releases/60814525f4556b65c033cbd7/img/vector-119@2x.png",
};

const group2610Data = {
  moreInformation1Props: moreInformation13Data,
};
