import React, { Component } from "react";
import { connect } from "react-redux";
import actions from "../../../redux/actions/CommonDataActions";
import actions1 from '../../../redux/actions/IntegrationPopUp';
import { copyToClipBoard, errorHandling, valueValidation } from '../../utilities/Services';
import { getIntegrationAPI, deleteIntegrationAPICall } from '../../../redux/thunk/integrationAPIThunk'
import { putIntegrationAPI, getIntegrationName } from '../../../redux/thunk/integrationAPIThunk';
import { getCollaboratorsListapi, getCollaboratorsByIntegrationID, deletCollaboratorsByIntegrationID, postCollaborators } from '../../../redux/thunk/userManagementAPIThunk';
import { integration } from '../../../redux/actions/integrationDataActions'
import AuthService from '../../server/AuthService';
import './EditIntegration.css';
import styled from 'styled-components';
import line42 from '../../../assets/line-42@1x.png';
import line43 from '../../../assets/line-43@2x.png';
import SecurityInfo from '../SecurityInfo/index';
import WebhooksInfo from '../WebhooksInfo/index';
import PropTypes from 'prop-types';
import * as Constants from '../../../constants/Constants';
import { Oval } from "react-loader-spinner";
import { Modal, Button } from "react-bootstrap";
import jwt from 'jwt-decode';
import { Input } from 'reactstrap';
import ConfirmDeleteIntegrtion from "../ConfirmDeleteIntegration/index";
import ellipse12 from "../../../assets/vector-29@2x.png";
import vectorsearch from "../../../assets/vector-search.svg";
import backarrow from '../../../assets/backarrow.svg';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import vector13 from '../../../assets/vector-13@2x.png';
import NewSnackbar from "../../utilities/NewSnackbar";
import swarning from '../../../assets/small-warning.svg';
import checksuccess from '../../../assets/check-success.svg';
import ellipse37 from '../../../assets/ellipse-37@2x.png';
import path255 from '../../../assets/closebtn.svg';
import integrationinfo from '../../../assets/infoimage.svg';
import { Tooltip } from '@material-ui/core';

class EditIntegration extends Component {
  constructor(props) {
    super(props);
    this.handleComponentSecurityBack = this.handleComponentSecurityBack.bind(this)
   // this.autoComplete = React.createRef();
    //this.validation = this.validation.bind(this)
    this.state = {
      showButton: true,
      isBasicInfo: true,
      isSecurityInfo: false,
      isWebhooksInfo: false,
      showLoaderResult: true,
      integrationName: '',
      integrationTempName: '',
      description: '',
      integrationDate: '',
      collaborators: [],
      integrationID: '',
      showRemoveColModal: false,
      showDeleteIntModal: false,
      options: [],
      selected: [],
      deleteConfirm: false,
      checkedValue: false,
      nameLengthValidation: false,
      nameUniqueValidation: false,
      nameEmailValidation: false,
      nameSpaceValidation: false,
      integrationCollaborators: [],
      nextButton: true,
      selectCollaboratorName: 'Test Collaborator',
      collaboratorID: '',
      saveCollaborators: [],
      addCollaborators: false,
      searchText: '',
      tempIntegrationName: '',
      tempDescription: '',
      integrationExist: false,
      activeDeactive: '',
      existingName: '',
      nameChanged: false,
      nameCharecterLength: 0,
      descriptionMaxVlue: '',
      nameMaxVlue: '',
      descriptionTextLength: 0,
      nameInputcolor: '',
      descrInputColor: '',
      checkIntegrationColor: '',
      namelengthValidationShow: false,
      nameUniqueShow: false
    }
  }
  
  componentDidMount() {
    // this.props.hideTopIntegrationOption()
    console.log("Edit Int ID", this.props.params);
    if (AuthService.isTokenValidCheck() === true) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("current integrationID : ", this.props.currentIntegrationID) }
      this.setState({ showLoaderResult: true });
      if(this.props.params !== null && this.props.params !== ''){
        this.props.callGetIntegrationAPI(this.props.params);
      }
      this.props.getCollaboratorsList();
    }
  }
  componentDidUpdate(nextProps) {
    if (this.props.getIntegrationResponse !== nextProps.getIntegrationResponse) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Get integration ", this.props.getIntegrationResponse) }
      if (this.props.getIntegrationResponse.error === false) {
        if(this.props.getIntegrationResponse.data){
        //  console.log("Edit integration Details", this.props.getIntegrationResponse.data[0]);
          this.setState({ integrationID: this.props.getIntegrationResponse.data[0].integrationID });
          this.setState({ description: this.props.getIntegrationResponse.data[0].description, descriptionTextLength: this.props.getIntegrationResponse.data[0].description.length});
          this.setState({ tempDescription: this.props.getIntegrationResponse.data[0].description });
          this.setState({ integrationName: this.props.getIntegrationResponse.data[0].name, nameCharecterLength:this.props.getIntegrationResponse.data[0].name.length });
          this.setState({ tempIntegrationName: this.props.getIntegrationResponse.data[0].name });
          this.setState({ collaborators: this.props.getIntegrationResponse.data[0].collaborators })
          this.setState({ integrationDate:  this.props.getIntegrationResponse.data[0].createdDate })
          this.setState({ activeDeactive:  this.props.getIntegrationResponse.data[0].active})
          this.props.integrationResponse(this.props.getIntegrationResponse.data[0]);
          if (AuthService.isTokenValidCheck() === true) {
          this.props.updateCurrentIntegrationID(this.props.getIntegrationResponse.data[0].integrationID)
          }
          if(this.props.getIntegrationResponse.data[0].description.length === 200){
            this.setState({ descriptionMaxVlue: true })
          } if(this.props.getIntegrationResponse.data[0].name.length === 30){
            this.setState({ nameMaxVlue: true })
          }
        }
      }
      this.setState({ showLoaderResult: false });
    } else if ((this.props.getIntegrationError !== nextProps.getIntegrationError)) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Get integration error ", this.props.getIntegrationError) }
      this.setState({ showLoaderResult: false });
      errorHandling(this.props.getIntegrationError);
    }
    if (this.props.collaboratorsDetails !== nextProps.collaboratorsDetails) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('NewGeneric collaboratorsDetails', this.props.collaboratorsDetails.data); }
      if ((this.props.collaboratorsDetails.error === false) && (this.props.collaboratorsDetails.data.length > 0)) {
        this.setState({ options: [] })
        if (AuthService.isTokenValidCheck() === true) {
        this.props.getCollaboratorDetails(this.props.params)
        }
  
      } else if (this.props.collaboratorsDetails.error) {
        this.props.showSnackbar({snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.warning, snackbarMessage: Constants.ALERT_TECHNICAL_ERROR})
      }
      //this.props.hideLoader();
    } else if (this.props.collaboratorsHasError !== nextProps.collaboratorsHasError) {
      //this.props.hideLoader();           
      errorHandling(this.props.collaboratorsHasError);
    }
    if (this.props.editIntegrationResponse !== nextProps.editIntegrationResponse) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Integration details updated", this.props.getIntegrationResponse) }
      if (this.props.editIntegrationResponse.error === false) {
         this.setState({ showLoaderResult: false});
         if (this.props.editIntegrationResponse.data) {
          if (AuthService.isTokenValidCheck() === true) {
          this.props.callGetIntegrationAPI(this.props.getIntegrationResponse.data[0].integrationID);
          }
          this.props.showSnackbar({snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.success, snackbarMessage: Constants.TEXT_INTEGRATION_DETAILS_UPDATE})
        }
      }
    } else if ((this.props.editIntegrationError !== nextProps.editIntegrationError)) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("editIntegrationError ", this.props.getIntegrationError) }
      errorHandling(this.props.editIntegrationError);
    }
    if (this.props.integrationDeleteResponse !== nextProps.integrationDeleteResponse) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Get integration ", this.props.getIntegrationResponse) }
      if (this.props.integrationDeleteResponse.error === false) {
        this.setState({ showLoaderResult: false });
       // console.log('integrationDelete Resposne', this.props.integrationDeleteResponse)
        this.setState({ deleteConfirm: true, showDeleteIntModal: false })
      }
      else {
        this.props.showSnackbar({snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.error, snackbarMessage: this.props.integrationDeleteResponse.messages[0]})
      }
    } else if ((this.props.integrationDeleteError !== nextProps.integrationDeleteError)) {
      this.setState({ showLoaderResult: false });
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("integrationDeleteError ", this.props.integrationDeleteError) }
      errorHandling(this.props.integrationDeleteError);
    }
    if (this.props.integrationNameResposne !== nextProps.integrationNameResposne) {
      this.setState({ showLoaderResult: false });
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Create integration Response : ", this.props.createIntegrationResponse); }
      if (this.props.integrationNameResposne.error === false) {
        if (this.props.integrationNameResposne.data && this.props.integrationNameResposne.data.status === 'true') {
          console.log('integration name res', this.props.integrationNameResposne.data)
          // tempNegativeAlert(Constants.INTEGRATION_NAME_VALIDATION, 5000, Constants.GRAY);
          this.setState({ nameUniqueValidation: false, existingName: this.props.getIntegrationResponse.data[0].name, checkIntegrationColor: true}) 
        }
        else {
          this.setState({ nameUniqueValidation: true, nextButton: false, tempIntegrationName: this.state.integrationName, nameInputcolor: '#27ae60', checkIntegrationColor: false});
          //  tempPositiveAlert(Constants.INTEGRATION_NAME_VALIDATION, 5000, Constants.GRAY);
        }
      } else if (this.props.integrationNameResposne.error) {
        this.setState({ nameUniqueValidation: false });
        this.props.showSnackbar({snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.warning, snackbarMessage: Constants.ALERT_TECHNICAL_ERROR})
      }

    } else if (this.props.integrationNameError !== nextProps.integrationNameError) {
      this.setState({ showLoaderResult: false });
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Integration Name Res Error : ", this.props.integrationNameError); }
      errorHandling(this.props.integrationNameError);
    }
    if (this.props.getCollaboratorsDetails !== nextProps.getCollaboratorsDetails) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("getCollaboratorsDetails ", this.props.getCollaboratorsDetails) }
      if (this.props.getCollaboratorsDetails.error === false) {
      //  console.log('getCollaboratorsDetails', this.props.getCollaboratorsDetails)
       this.setState({ showLoaderResult: false });
        if(this.props.getCollaboratorsDetails.data && this.props.getCollaboratorsDetails.data.length > 0 && this.props.collaboratorsDetails.data.length>0){
          try {
            this.setState({integrationCollaborators : this.props.getCollaboratorsDetails.data})
            this.setState({showLoaderResult: false})
            this.setState({ options: [], selected: []})
            let  tempCollaborators = []
            let result = ''
            var onlyInA = this.props.collaboratorsDetails.data.filter(this.comparer(this.props.getCollaboratorsDetails.data));
            var onlyInB = this.props.getCollaboratorsDetails.data.filter(this.comparer(this.props.collaboratorsDetails.data));
            result = onlyInA.concat(onlyInB);
           // console.log('result is', result)
            if(result.length > 0)
            {
              result.forEach((users) => {
              let data = { value: users.userID, text: users.name};
                if (Constants.TEXT_INTEGRATION_EDIT === 'Edit') {
                    tempCollaborators.push(data);
                  if (this.props.getIntegrationResponse.data[0].createdBy !== undefined) {
                   // console.log('tempCollaborators',tempCollaborators)
                    this.setState({ options: tempCollaborators });
                  }
                }
              })
            }
          } catch (error) {
            console.log('error',error)
          }
        }
        else{
          if(this.props.collaboratorsDetails.data && this.props.collaboratorsDetails.data.length > 0){
          this.setState({showLoaderResult: false})
          this.setState({ options: [] })
          let duplicateResult = ''
          let  tempCollaborators = []
          this.props.collaboratorsDetails.data.forEach((users, i) => {
            duplicateResult = false;
             this.props.collaboratorsDetails.data.forEach((user, j) => {
              if ((i !== j) && (this.props.collaboratorsDetails.data[i].userID === user.userID)) {
              //  console.log('duplicate users : ', user.name);
                duplicateResult = true;
                return false;
              }
            });
            if (!duplicateResult) {
              let data = { value: users.userID, text: users.name};
              if (Constants.TEXT_INTEGRATION_EDIT === 'Edit') {
                  tempCollaborators.push(data);
                if (this.props.getIntegrationResponse.data[0].createdBy !== undefined) {
                 // console.log('tempCollaborators',tempCollaborators)
                  this.setState({ options: tempCollaborators.filter(item => item.value !== this.props.getIntegrationResponse.data[0].createdBy) });
                }
                return this.state.options;
              } 
            }
          });
        }
      }
    }
    } else if ((this.props.getCollaboratorsHasError !== nextProps.getCollaboratorsHasError)) {
      this.setState({showLoaderResult: false})
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("getCollaboratorsDetails error ", this.props.getCollaboratorsHasError) }
      errorHandling(this.props.getCollaboratorsHasError);
    }
    if (this.props.deleteCollaboratorsDetails !== nextProps.deleteCollaboratorsDetails) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("deleteCollaboratorsDetails ", this.props.deleteCollaboratorsDetails) }
      if (this.props.deleteCollaboratorsDetails.error === false) {
        if(this.props.deleteCollaboratorsDetails.data){
        Object.values(this.state.integrationCollaborators).forEach((users, i) =>{
          if(users.userID === this.state.collaboratorID){
            let data = { value: this.state.collaboratorID, text: this.state.selectCollaboratorName };
             this.state.options.push(data);
            let index = this.state.integrationCollaborators.indexOf(users)
            this.state.integrationCollaborators.splice(index,1)
            this.setState({ integrationCollaborators: this.state.integrationCollaborators, options: this.state.options});
          }
        })
          this.setState({ showLoaderResult: false});
        }
      }
    } else if ((this.props.deleteCollaboratorsHasError !== nextProps.deleteCollaboratorsHasError)) {
      this.hideModal()
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("deleteCollaboratorsHasError error ", this.props.deleteCollaboratorsHasError) }
      errorHandling(this.props.deleteCollaboratorsHasError);
    }
    if (this.props.postCollaboratorsDetails  !== nextProps.postCollaboratorsDetails) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("postCollaboratorsDetails ", this.props.postCollaboratorsDetails) }
      if (this.props.postCollaboratorsDetails.error === false) {
       // console.log('postcollaborator resposne', this.props.postCollaboratorsDetails)
        if(this.props.postCollaboratorsDetails.data){
        //  console.log('this.state.tempCollaborators', this.props.collaboratorsDetails.data, 'cid', this.state.selected)
          this.props.collaboratorsDetails.data.forEach((users, i) => {
          if ((users.userID === this.state.selected)) {
            let data = {userID: users.userID, name: users.name, userName: users.userName, roleID: users.roleID, roleName: users.roleName }
           // console.log('current user pushed : ', data);
            this.state.integrationCollaborators.push(data)
            this.setState({integrationCollaborators: this.state.integrationCollaborators});
          }
          })
         this.setState({ showLoaderResult: false});
        }
      }
    } else if ((this.props.postCollaboratorsHasError !== nextProps.postCollaboratorsHasError)) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("postCollaboratorsHasError error ", this.props.postCollaboratorsHasError) }
      errorHandling(this.props.postCollaboratorsHasError);
    }
  }
  //Two compare arrays and filter duplicate values 
  comparer(otherArray){
      return function(current){
        return otherArray.filter(function(other){
          return other.userID === current.userID
      }).length === 0;
    }
  }
  handleComponentSecurityBack(params) {
    // this.props.securityfunc(params);
    console.log('security options', params)
  }
  handleBackAction() {
    //  this.props.showTopIntegrationOption()
    // this.props.backfunc(Constants.TEXT_SUMMARY,this.props.params);
    this.props.backfunc(Constants.TEXT_EDIT_INTEGRATION, 'testing');
  }
  basicIntegrations() {
    this.setState({ selectTitle: "198px", setTitleWidth: "63px" })
    this.setState({ isBasicInfo: true, isSecurityInfo: false, isWebhooksInfo: false })
  }
  webhooksIntegrations() {
    console.log('deactivate list')
    this.setState({ selectTitle: "375px", setTitleWidth: "75px" })
    this.setState({ isBasicInfo: false, isWebhooksInfo: true, isSecurityInfo: false })
  }
  securityIntegrations() {
    // window.location.href = '/portal';
    this.setState({ selectTitle: "280px", setTitleWidth: "80px" })
    this.setState({ isSecurityInfo: true, isBasicInfo: false, isWebhooksInfo: false })
  }
  showRemovePopup(event) {
    console.log('event',event)
    this.setState({ showRemoveColModal: true, selectCollaboratorName: event.name,
    collaboratorID: event.userID })
  }

  hideModal() {
    this.setState({ hideModal: true, showRemoveColModal: false, showDeleteIntModal: false })
  }
  confirmModal() {
   // alert("val")
   if(this.state.collaboratorID !== null && this.state.integrationID !== null){
    this.setState({showLoaderResult: true})
    if (AuthService.isTokenValidCheck() === true) {
    this.props.removeCollaborator(this.state.integrationID,this.state.collaboratorID)
    }
    this.hideModal()
   }
  }
  deleteIntegration() {
    this.setState({ showDeleteIntModal: true })
  }
  nameProfileText = (str) => {
    let initials = ''
    if(str.split(" ").length > 1){
      const fullName = str.split(' ');
      initials =  fullName.shift().charAt(0) + fullName.pop().charAt(0);
    }
    else{
      const fullName = str.split(' ');
      initials =  fullName.shift().charAt(0)
    }
    return initials.toUpperCase();
  }
  //to call delete integration API
  deleteIntegrationAPI(e) {
    let params = {
      type: "general"
    };
    this.setState({ showLoaderResult: true })
    if(!this.validation()){
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log("validation failed : ");}
      //e.preventDefault();
    }else {
      if (this.state.checkedValue === true && this.state.integrationName === this.state.integrationTempName && this.state.integrationTempName.length > 0) {
        this.props.callIntegrationStatus(this.state.integrationID, params)
      }
    }    
  }
  validation(){
    if(this.state.checkedValue === false && this.state.integrationTempName.length === 0 ){
      this.props.showSnackbar({snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.error, snackbarMessage: Constants.TEXT_INTEGRATION_DELETE_MANDATORY})
      return false;
    } else {
      if(this.state.integrationTempName.length === 0) {
        this.props.showSnackbar({snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.error, snackbarMessage: Constants.TEXT_INTEGRATION_NAME_MANDATORY})
          return false;
        } else {  
          if(this.state.integrationName !== this.state.integrationTempName.trim()){
            this.props.showSnackbar({snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.error, snackbarMessage: Constants.TEXT_INTEGRATION_NAME_VALID})
            return false;   
          }
      } 
      if(this.state.checkedValue === false && this.state.integrationName === this.state.integrationTempName.trim() ){
        this.props.showSnackbar({snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.error, snackbarMessage: Constants.TEXT_INTEGRATION_ACCEPTANCE})
        return false;       
      } 
    }  
    return true;
    }    
    
  //onChange={(e, data) =>{props.setCurrentSelectedValues(e, data)}}
  userHandleChange(e, data) {
   try {
     if(data !== null){
    if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('event ', e); }
    if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('selected integrations ', data.value); }
    if(data.value !== null){
    this.setState({ selected: data.value, searchText: data.value});
    this.updateCollaborators(data.value)   
    // let autoComplete =  this.autoComplete
    // setTimeout( () => autoComplete && autoComplete.setState({ searchText: "" }), 500 )
    }
   }
   } catch (error) {
     console.log(error)
   }
  }
  onaddCollaborator = (event) => {
    console.log('add collab event selected', event)
  }
  endofDescription = (event) => {
    console.log('event ateget desc', event.target.value.length)
    var value = event.target.value;
    if(value.length > 0){
      this.setState({ descrInputColor: '#27ae60'})
    }
    else{
      this.setState({ descrInputColor: ''})
    }
  }

  oneditDescriptionChange = (event) => {
    // console.log('target value',e.target)
    var value = event.target.value;
    if (value.length > 200) {
      // tempNegativeAlert(Constants.TEXT_DESCRIPTION_PLACEHOLDER,5000,Constants.RED_COLOR);
      return value
    }
    else {
      if(this.state.integrationName){
        this.setState({ description: value, nextButton: false, showButton: true, descriptionTextLength: value.length})
      }else {
        this.setState({ description: value, nextButton: false, showButton: false, descriptionTextLength: value.length})
      }
      if(value.length === 200){
        this.setState({descriptionMaxVlue: true})
    }
    else{
        this.setState({descriptionMaxVlue: false})
    }
    }

  }
  clearinput = (event) => {
    //  console.log('clear')
    this.setState({
        integrationName: '', 
        nameUniqueValidation: false,
        nameLengthValidation: false,
        nameCharecterLength: 0,
        nameInputcolor: '',
        namelengthValidationShow: false, nameUniqueShow: false
    })
    
 }
  onEndNameChange = (event) => {
    var value = event.target.value.trim();
    console.log('value end input',value.length)
    if (value.trim().length > 7) {
      this.setState({ showLoaderResult: true });
      this.props.nameIntegrationAPI(value.trim())
    }
  }

  oneditIntegrationNameEdit = (event) => {
    var value = event.target.value.trim();
    this.setState({showButton: true, nameInputcolor:''})
    const id_token = localStorage.getItem('id_token');
    let client=null;
    var username=null;
    if(id_token !== "undefined" && valueValidation(id_token)){
       client = jwt(id_token);
       username = client.username
    }
    console.log('cleint',username, 'value',value)
    if(value.trim().length <= 30 && valueValidation(client)&& valueValidation(username)){
    this.setState({ integrationName: value , nameChanged: true, nameCharecterLength: value.length })
    if (value.trim().length > 0) {
      this.setState({namelengthValidationShow: true})
      if (value.trim().length > 7) {
        this.setState({
          nameLengthValidation: true,
          nameUniqueShow: true
        });
        if(value.trim() === username) {
          this.setState({nameEmailValidation: false, nextButton: true})
        } else{
          this.setState({nameEmailValidation: true, nextButton: false})
        }
      } else {
        this.setState({
          nameLengthValidation: false,
          nameUniqueValidation: false,
          nameEmailValidation: false,
          nameSpaceValidation: false,
          nextButton: true,
          nameUniqueShow: false
        });
      }
      if(value.length === 30){
        this.setState({nameMaxVlue: true})
    }else{
        this.setState({nameMaxVlue: false})
    }

    }
    if (value.trim().indexOf(' ') >= 0) {
      this.setState({
        nameSpaceValidation: false,nextButton: true
      });
    }
    else {
      if (value.trim().length > 0) {
        this.setState({
          nameSpaceValidation: true,nextButton: false
        });

      }
      else {
        this.setState({
          nameSpaceValidation: false,nextButton: true
        });
      }
    }
    if (value.trim().length === 0) {
      this.setState({
        nameLengthValidation: false,
        nameUniqueValidation: false,
        nameEmailValidation: false,
        nameSpaceValidation: false,
        nextButton: true,
        checkIntegrationColor: '',
        namelengthValidationShow: false
      })
    }
  }
  }
  copyOption(copyValue) {
    // alert('Text-Copied')
    if (copyValue) {
      copyToClipBoard(copyValue, Constants.TEXT_DESCRIPTION_PLACEHOLDER, 3000, Constants.RED_COLOR);
    }
  }
  onSelectConfirmValue = (event) => {
    if (this.state.checkedValue === true) {
      this.setState({ checkedValue: false })
    } else {
      this.setState({ checkedValue: true })
    }

  }
  cancelChanges(e) {
    e.preventDefault();
    this.setState({
      description: this.state.tempDescription,
      integrationName: this.props.getIntegrationResponse.data[0].name, nextButton: true, descrInputColor: '', nameInputcolor: '', checkIntegrationColor: '', namelengthValidationShow: false, nameUniqueShow: false,
      descriptionTextLength: this.state.tempDescription.length,
      nameCharecterLength: this.props.getIntegrationResponse.data[0].name.length
    })
  }
  saveChanges(e) {
    if(!this.validations()){
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log("validation failed : ");}
      e.preventDefault();
    }else{
          let generalParams = {};
          generalParams[Constants.PARAM_INTEGRATIONID] = this.state.integrationID;
          generalParams[Constants.PARAM_NAME] = this.state.integrationName;
          generalParams[Constants.PARAM_DESCRIPTION] = this.state.description;
          generalParams[Constants.PARAM_COLLABORATORS] = "" //this.state.saveCollaborators.toString();
          generalParams[Constants.PARAM_TYPE] = 'general';
          generalParams[Constants.PARAM_STATUS] = this.state.activeDeactive
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("General Params :", generalParams); }
          this.props.callIntegrationStatusUpdate(this.state.integrationID, generalParams);
          this.props.updateCurrentIntegrationName(this.state.integrationName)
          this.setState({showButton: false, tempDescription: this.state.description, descrInputColor: '', nameInputcolor: '', checkIntegrationColor: '', namelengthValidationShow: false, nameUniqueShow: false})
    } 
  }
  nameValidations(){
    let actualName = this.state.integrationName.trim()
      let changedName = this.state.tempIntegrationName.trim()
      if(this.state.nameUniqueValidation === false)
      {
        changedName = this.state.existingName.trim()
      }
      else{
        changedName = this.state.tempIntegrationName.trim()
      }
      if (actualName.toLocaleLowerCase() !== changedName.toLocaleLowerCase() && this.props.getIntegrationResponse.data[0].name !== actualName) {
        //  this.setState({nameInputcolor: '#d93030'})
          return false;
      }
      return true
  }
  validations(){
     if(this.state.nameChanged === true){
      if (this.state.integrationName.trim() === '' || this.state.integrationName.trim().length === 0) {
        this.props.showSnackbar({snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.error, snackbarMessage: Constants.VALIDATION_INTEGRATION_NAME})
        this.setState({nameInputcolor: '#d93030'})
        return false;
      }
      if (this.state.integrationName.trim().length < 8 ) {
        this.props.showSnackbar({snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.error, snackbarMessage: Constants.VALIDATION_INTEGRATION_MIN_LENGTH})
        this.setState({nameInputcolor: '#d93030'})
        return false;
      }
      const id_token = localStorage.getItem('id_token');
      if(id_token !== "undefined" && valueValidation(id_token)){
        let client = jwt(id_token);
        var username = client.username
        if (this.state.integrationName.trim() === username) {
          this.props.showSnackbar({snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.error, snackbarMessage: Constants.VALIDATION_INTEGRATION_NAME1})
          this.setState({nameInputcolor: '#d93030'})
          return false;
        }
      }
      if (this.state.integrationName.trim().indexOf(' ') >= 0) {
        this.props.showSnackbar({snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.error, snackbarMessage: Constants.INTEGRATION_NAME_SPACE})
         return false;
       }
      let actualName = this.state.integrationName.trim()
      let changedName = this.state.tempIntegrationName.trim()
      if(this.state.nameUniqueValidation === false)
      {
        changedName = this.state.existingName.trim()
      }
      else{
        changedName = this.state.tempIntegrationName.trim()
      }
      if (actualName.toLocaleLowerCase() !== changedName.toLocaleLowerCase() && this.props.getIntegrationResponse.data[0].name !== actualName) {
          this.props.showSnackbar({snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.error, snackbarMessage: Constants.VALIDATION_INTEGRATION_NAME_VALID})
          this.setState({nameInputcolor: '#d93030'})
          return false;
      }
     }
      if (this.state.description.trim().length === 0) {
        this.props.showSnackbar({snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.error, snackbarMessage: Constants.VALIDATION_DESCRIPTION_NAME})
        this.setState({descrInputColor: '#d93030'})
        return false;
      }
    return true
  }
  updateCollaborators(userID){
    if(userID){
      let generalParams = {};
      generalParams[Constants.PARAM_INTEGRATIONID] = this.state.integrationID;
      generalParams[Constants.PARAM_COLLABORATORID] = userID;
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("collaborator update Params :", generalParams); }
      Object.values(this.state.options).forEach(ele =>{
      if(ele.value === userID){
        let index = this.state.options.indexOf(ele)
        this.state.options.splice(index,1)
        this.setState({ options: this.state.options });
      }})
    //  console.log('optios selected', this.state.options)
    // alert(this.state.options)
    if (AuthService.isTokenValidCheck() === true) {
     this.props.postCollaboratorDetails(this.state.integrationID, userID, generalParams);
    }
     this.setState({ showLoaderResult: true});
    }else{
      alert('invalid collaborator ID')
    }
  }
  getIntegrationName(event) {
    let value = event.target.value
    //this.setState({integrationTempName: value})
    if (this.state.integrationName === value && value.length === this.state.integrationName) {
      return value
    }
    else {
      this.setState({ integrationTempName: value })
    }
  }

  handleUpdateInput = (searchText) => {
    const result = searchText.trim();
    this.setState({
      searchText: result,
    });
  };

  handleNewRequest = () => {
    this.setState({
      searchText: '',
    });
    alert('test')
  };
  
  render() {
    let loader;
    let date = ''
    var time = ''
    if(this.state.integrationDate !== null &&  this.state.integrationDate !== ''){
     date = this.state.integrationDate.split(" ")
     time = new Date(this.state.integrationDate);
    time = time.toLocaleTimeString().replace(/:\d+ /, ' '); // current time, e.g. "1:54 PM"
    //console.log('timestamp', time)
    }
    if (this.state.showLoaderResult) {
      loader = <div id="center" style={{ position: "fixed", bottom: "40%", left: "48%" }}>
        <Oval
          type="Puff"
          position="fixed"  secondaryColor="#E85557"
          color="#E85557"
          height={50}
          width={50}
          timeout={3000}
        />
      </div>
    }
    return (
      
      <div className="wdth" >
        {/* <div className="row"> */}
        {/* <h1 className="title column valign-text-middle title lato-bold-mine-shaft-32px" onClick={this.handleBackAction.bind(this)} style={{ cursor: "pointer" }}>{"< Edit Integration"} </h1> */}
        <div className='row group2733' style={{ marginLeft: "6px", marginTop: '9px'}} >
                <img src={backarrow} style={{ cursor: 'pointer', height: '17px', width: '17px', marginTop: '7px'}} alt="" onClick={this.handleBackAction.bind(this)}/>
                <div className="edit-integration-13">Edit Integration</div>
              </div>
        {/* </div> */}
        <div className="row" style={{ marginTop: "10px" }}>
          <p
            className="text-1-date lato-normal-mine-shaft-14px"
            style={{ marginLeft: "37px" }}
          >
            You can edit your integration here.
          </p>
        </div>
        <div>
          <Line42 src={line42} />
          <Line43 src={line43} style={{ left: this.state.selectTitle, width: this.state.setTitleWidth, cursor: 'pointer' }} />
          <div>
            <div className="webhooks valign-text-middle lato-normal-fuscous-gray-14px" onClick={this.webhooksIntegrations.bind(this)}>
              {this.state.isWebhooksInfo ? <span style={{ color: "#d93030", fontWeight: "700", cursor: 'pointer' }}>  Webhooks  </span> :
                <span style={{ color: "#555555", fontWeight: "400", cursor: 'pointer' }}>  Webhooks </span>}

            </div>
            <div className="basic valign-text-middle lato-bold-persian-red-14px" onClick={this.basicIntegrations.bind(this)}>
              {this.state.isBasicInfo ? <span style={{ color: "#d93030", fontWeight: "700", cursor: 'pointer' }}>  Basic Info  </span> :
                <span style={{ color: "#555555", fontWeight: "400", cursor: 'pointer' }}>  Basic Info</span>}

            </div>
            <div className="security-ed valign-text-middle lato-normal-fuscous-gray-14px" onClick={this.securityIntegrations.bind(this)}>
              {this.state.isSecurityInfo ? <span style={{ color: "#d93030", fontWeight: "700", cursor: 'pointer' }}>   Security </span> :
                <span style={{ color: "#555555", fontWeight: "400", cursor: 'pointer' }}>  Security </span>}
            </div>
          </div>
        </div>
        {this.state.isBasicInfo === true ? <div className="frame-2367">
          <div className="overlap-group4-ed">
            <div className="x1560348 component" style={{borderColor: this.state.nameInputcolor }}>
              <Input type="search" maxLength={30} style={{ height: '38px', border: 'white'}} placeholder="Integration Name" defaultValue={this.state.integrationName} name="integrationNames" onChange={this.oneditIntegrationNameEdit.bind(this)}
                onInputCapture={this.onEndNameChange} itemType="text" />
                 
              {/* <div className=""></div> */}
            </div>
            <img
              className="path-22545"
              src={path255}
              alt="" onClick={this.clearinput} style={{ cursor: "pointer" }} />
            <div className="place11 paragraph-1--14pt-regular-lato" style={{color: this.state.nameMaxVlue === true ? '#d93030': ''}}>{this.state.nameCharecterLength}</div>
            {<div className="place12 paragraph-1--14pt-regular-lato" >/30</div>}
            
            <div className="text-editname-valid valign-text-middle lato-regular-normal-granite-gray-12px">
            {this.state.namelengthValidationShow === true ? <span style={{ color: this.state.nameLengthValidation === true ? "green" : "#d93030" }}>Contains min. 8 & max. 30 characters</span> : ''}
             </div>
            {this.state.namelengthValidationShow === true ? <img className="ellipse-378" src={this.state.nameLengthValidation === true ? checksuccess : ellipse37} alt='' /> : ''}
            {/* <div className="text-editname-valid valign-text-middle lato-regular-normal-granite-gray-12px">
              {this.state.checkIntegrationColor === false ? <span style={{ color: this.state.nameUniqueValidation === true ? "green" : "#db2828" }}>Integration name is unique</span> : <span style={{ color: this.state.nameUniqueValidation === true ? "green" : "#db2828" }}>Integration name is unique</span>}
            </div> */}
             <div className="text-editname-valid-11 valign-text-middle lato-regular-normal-granite-gray-12px">
            {this.state.nameUniqueShow === true ? <span style={{ color: this.state.nameUniqueValidation === true ? "green" : [( this.nameValidations() ? 'green': 'red')] }}>Integration name is unique</span> : ''}
             </div>
            {this.state.nameUniqueShow === true ? <img className="ellipse-3789" src={this.state.nameUniqueValidation === true ? checksuccess : [( this.nameValidations() ? checksuccess: swarning)]} alt='' /> : ''}
            {/* <Input >Input text</Input3> */}
            <div className="general-info valign-text-middle h3-header-3--18pt-bold-lato" style={{fontSize: "16px"}}>General Info</div>
            <p className="text-2-ed paragraph-1--14pt-regular-lato">
              Update the general information about your integration.
            </p>
            <div className="collaborator h3-header-3--18pt-bold-lato" style={{fontSize: "16px"}}>Collaborator</div>
            <div className="place paragraph-1--14pt-regular-lato">Name</div>
             <Tooltip title='Integration Name' placement="top" arrow>
            <img className="place" style={{marginLeft: '45px'}} src={integrationinfo} alt='' />
            </Tooltip>
            <div className="edit-description paragraph-1--14pt-regular-lato">Description</div>
            <div className="date-created paragraph-1--14pt-regular-lato">Date Created</div>
            <p className="text-3-ed paragraph-1--14pt-regular-lato">
              Give access to&nbsp;&nbsp;individuals who can help manage this integration.
              <br />
              <br />
              NOTE: All collaborators will have the same access right as the owner of the integration.
            </p>
            <img
              className="line-49"
              src={line42} alt=""
            />
            <div className="overlap-group2-ed border-1px-primary" style={{borderColor: this.state.descrInputColor}}>
              {/* <textarea className="text-5-ed paragraph-1--14pt-regular-lato" value = {this.state.description}/>               */}
              <Input type="textarea" maxLength={200} style={{ marginLeft: '-15px', border: '#dee2e6', width: '407px', resize: 'none', height: '100px',
               fontFamily: '"Lato", Helvetica' }} className="text-5-ed"
                placeholder={Constants.TEXT_DESCRIPTION_PLACEHOLDER}
                defaultValue={this.state.description} name="integration description" onChange={this.oneditDescriptionChange} onInputCapture={this.endofDescription}
                itemType="textarea" />
            </div>
            <div className="place111 paragraph-1--14pt-regular-lato" style={{color: this.state.descriptionMaxVlue === true ? '#d93030': ''}}>{this.state.description.length > 0? this.state.descriptionTextLength: ''}</div>
            {this.state.description.length > 0 ? <div className="place122 paragraph-1--14pt-regular-lato" >/200</div>: '' }
            {this.state.showButton ? <div className="cancel-btn" aria-disabled={this.state.nextButton} hidden={this.state.nextButton}> <button disabled={this.state.nextButton} style={{ background: 'white', width: "80px", height: "30px", marginRight: "10px", borderRadius: "4px", border: "1px solid #9a8f8f", fontWeight: "700", fontSize: '12px', lineHeight: '20px' }} onClick={this.cancelChanges.bind(this)}>CANCEL</button>
              <button disabled={this.state.nextButton} style={{ width: "80px", height: "30px", backgroundColor: "#d93030", color: "white", borderRadius: "4px", border: "1px solid #f7f1f1", fontWeight: "600", fontSize: '12px', lineHeight: '20px', letterSpacing:'0.4px' }} onClick={this.saveChanges.bind(this)}>SAVE</button></div> : null}
             {this.state.integrationDate !== null &&  this.state.integrationDate !== '' ? <p className="text-6-ed paragraph-1--14pt-regular-lato">{(date[0] + " " + date[1] + " '" + date[2].substring(2)).replace(","," ")+ " at " + time}</p>: ''} 
            <div className="flex-col-6">
              <div className="text-7-ed valign-text-middle h4-header-4--16pt-bold-lato">Existing collaborators</div>
            </div>
            <div className="text-11-ed">Add new collaborator</div>
            <div className="">
              <div className="rectangle-267 border-1px-grey-3"></div>
              {/* <Dropdown id="integrationdropdown" 
              className="text-12-ed valign-text-middle paragraph-1--14pt-regular-lato"   
              placeholder="Search and add by name or email"
              fluid 
              search 
              selection
              allowAdditions 
              upward
              options={this.state.options ? this.state.options: null}
              onChange={this.userHandleChange.bind(this)}
              value= {''}
              closeOnChange={false} /> */}
              <div className="text-12-ed valign-text-middle paragraph-1--14pt-regular-lato">
            <Autocomplete
           // ref={this.autoComplete}
            freeSolo
            id="free-solo-2-demo"
            options={this.state.options.map((option) => option)}
           // value={(option, value) =>  this.state.selected ? option.text : option.text = ''}
            onChange={this.userHandleChange.bind(this)}
           onUpdateInput={this.handleUpdateInput}
           onNewRequest={this.handleNewRequest}
            onClose={(e, data) =>{this.userHandleChange.bind(e, data)}}
            
           // onNewRequest={this.handleNewRequest}
            getOptionSelected={(option, value) => option.text === value.text}
            getOptionLabel={(option) => option.text}
            style={{ width: 350, height: 45, marginBottom: 50, fontWeight: 200, marginLeft: 10, fontSize: 1.2}}
            renderInput={(params) => (
              <TextField 
                {...params}
                label="Search and add collaborators"
                margin="normal"
                variant="standard"
                style={{height : '30px', fontFamily: 'Lato', fontSize: '0.7rem'}}
                InputProps={{ ...params.InputProps, disableUnderline: true, type: 'search'}}
              />
            )}
          />
          </div>
              {/* <Layer12/> */}
          <div className="layer-1-ed">
            <div className="overlap-group3-ed">
            <img className="vector-1-ed" src={vectorsearch} alt=""/>
          </div>
         </div>
         </div>
        </div>
          <div className="flex-row-new-1">
          {this.state.integrationCollaborators.length > 0 ?
              <div class="table-container" style={{scrollbarWidth: 'thin'}}>
                <table className="table-b">
                <tbody>
                  {this.state.integrationCollaborators.map((item, key) => {
                    let name = this.nameProfileText(item.name)
                    return <tr key={key}>
                        <td>
                          <div className="overlap-group1-top text-center" style={{ backgroundImage: `url(${ellipse12})`, width: '40px', height: '40px', marginLeft: '10px', alignItems: 'center', marginTop: '10px' }}>
                            <div className="col-name-space">{name}</div>
                            <div className="valign-text-middle paragraph-1--14pt-regular-lato" style={{ marginTop: '-25px', marginLeft: '50px' }}>{item.userName}</div>
                            <div className="col-dev-css">{item.roleName}</div>
                            <div className="remove-btn-style" style={{ cursor: 'pointer', marginTop: '-25px', marginLeft: '345px' }} onClick={this.showRemovePopup.bind(this, item)}>REMOVE
                            </div>
                          </div>
                        </td>
                      </tr>
                  })}
                  </tbody>
                </table>
              </div>
              :
              <div className="overlap-group1-1" style={{ width: '173px', marginLeft: '130px' }}>
                <span> No collaborators added yet.</span>
              </div>}
          </div>
          <div className="flex-col-5">
            <img className="line-50" src={line42} alt=""/>
            <div className="flex-row-ed">
              <div className="flex-col-3">
                <div className="delete-integration valign-text-middle h3-header-3--18pt-bold-lato" style={{fontSize: "16px"}}>
                  Delete Integration
                </div>
                <p className="text-4-ed paragraph-1--14pt-regular-lato">
                  This is delete all the settings and information related to your integration. Any content created by the
                  integration will remain until deleted by the user or admin.
                </p>
              </div>
              <div className="x15661857 component" onClick={this.deleteIntegration.bind(this)}>
                <div className="button-i156618576-VFFmuO valign-text-middle" >DELETE INTEGRATION</div>
              </div>
              {/* <Component5></Component5> */}
            </div>
          </div>
        </div> : null}
        <NewSnackbar/>
        {loader}
        {this.state.isSecurityInfo === true ? <div className="frame-2367"><SecurityInfo securityfunc={this.handleComponentSecurityBack} title={"securityInfo"}/></div> : null}
        {this.state.isWebhooksInfo === true ? <WebhooksInfo /> : null}
        {this.state.showRemoveColModal === true ? 
        <Modal show={this.state.showRemoveColModal} onHide={this.state.hideModal}  >
          <Modal.Header>
          <div className="flex-row-1-ei">
            <div className="header-name h3-header-3-18pt-bold-lato">Remove collaborator?</div>
            <img className="vector-3-ei" src={vector13} onClick={this.hideModal.bind(this)} alt=""/>
            </div>
          </Modal.Header>
          <Modal.Body style={{height: '140px'}}>
            <div className=" paragraph-1-14pt-regular-lato" style={{marginTop: '46px'}}> Are you sure you want to remove {this.state.selectCollaboratorName} from the collaborators list? </div></Modal.Body>
          <Modal.Footer style={{marginTop: "0px"}}>
            <div className= "row">
            <div className="colb-no-btn column" onClick={this.hideModal.bind(this)}>
            <div className="colb-no-text">NO</div>
            </div>
            <div className="colb-yes-btn column" onClick={this.confirmModal.bind(this)}>
              <div className="colb-yes-text">YES</div>
            </div>
            </div>
          </Modal.Footer>
        </Modal> : null}
        {this.state.showDeleteIntModal === true ? <Modal show={this.state.showDeleteIntModal} onHide={this.state.hideModal} >
          <Modal.Header >
          <div className="flex-row-2-ei">
            <div className="delete-integration-1 valign-text-middle h3-header-3--18pt-bold-lato" style={{fontSize: "18px"}}>Delete Integration?</div>

            <img className="vector-3-ei" src={vector13} onClick={this.hideModal.bind(this)} alt=""/>

            </div>
          </Modal.Header>
          <Modal.Body style={{ padding: "2rem", height: "200px", paddingTop: "1px" }}>
            <div className="text-1-ei valign-text-middle paragraph-1--14pt-regular-lato">
              <div className="flex-row-ei">
                <span className="span-ei">Are you sure you want to delete the integration</span>
                <span className="span1-ei " style={{marginLeft:'5px', fontWeight:'bold'}}>{this.state.integrationName} ?</span>
              </div>
              <span className="span-ei" style={{marginTop: '10px'}}>
                This change is irreversible. Please type the full name of the integration to confirm.
              </span>
            </div>
            <div className="video1624317">
              {/* <div className="input-text-i1624317-22AHbZ valign-text-middle"> */}
              <input type="text" itemType="text" placeholder='Type Integration name' value={this.state.integrationTempName} onChange={this.getIntegrationName.bind(this)} className="input-text-integrationname" />
              {/* </div> */}
            </div>
            <div className="group-2703">
              {/* <div className="rectangle-262 border-1-2px-granite-gray"></div> */}
              <input type="checkbox" checked={this.state.checkedValue} onChange={this.onSelectConfirmValue.bind(this)} style={{ width: "18px", height: "18px" }} />
              <div className="text-1-dt valign-text-middle paragraph-1--14pt-regular-lato">I understand the consequences.</div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="video377147" onClick={this.deleteIntegrationAPI.bind(this, this.state.integrationID)}>
              <div className="button-i377147637-A2IF2B valign-text-middle" >{Constants.TEXT_DELETE_THIS_INTEGRATION}</div>
            </div>
          </Modal.Footer>
        </Modal> : null}
        {this.state.deleteConfirm === true ? <ConfirmDeleteIntegrtion value={this.state.deleteConfirm} /> : null}
      </div>
    )
  }
}
EditIntegration.propTypes = {
  func: PropTypes.func,
  params: PropTypes.any,
  backfunc: PropTypes.func,
}
const mapStateToProps = state => {
  return {
    isFetchingData: state.commonDataReducer.isFetchingData,
    collaboratorsDetails: state.userDataReducer.collaboratorsDetails,
    collaboratorsHasError: state.userDataReducer.collaboratorsHasError,
    currentIntegrationID: state.integrationDataReducer.currentIntegrationID,
    isViewIntegrationsShowSummary: state.integrationPopUpReducers.isViewIntegrationsShowSummary,
    getIntegrationResponse: state.integrationDataReducer.getIntegrationResponse,
    getIntegrationError: state.integrationDataReducer.getIntegrationError,
    integrationStatusResponse: state.integrationDataReducer.integrationStatusResponse,
    integrationStatusError: state.integrationDataReducer.integrationStatusError,
    integrationDeleteResponse: state.integrationDataReducer.integrationDeleteResponse,
    integrationDeleteError: state.integrationDataReducer.integrationDeleteError,
    integrationNameResposne: state.integrationDataReducer.integrationNameResposne,
    integrationNameError: state.integrationDataReducer.integrationNameError,
    getCollaboratorsDetails: state.userDataReducer.getCollaboratorsDetails,
    getCollaboratorsHasError: state.userDataReducer.getCollaboratorsHasError,
    deleteCollaboratorsDetails: state.userDataReducer.deleteCollaboratorsDetails,
    deleteCollaboratorsHasError: state.userDataReducer.deleteCollaboratorsHasError,
    postCollaboratorsDetails : state.userDataReducer.postCollaboratorsDetails,
    postCollaboratorsHasError : state.userDataReducer.postCollaboratorsHasError,
    editIntegrationResponse: state.integrationDataReducer.editIntegrationResponse,
    editIntegrationError: state.integrationDataReducer.editIntegrationError,
  }
};
const mapDispatchToProps = dispatch => ({
  showTopIntegrationOption: () => dispatch({ type: actions1.SHOW_TOP_INTEGRATIONS, payload: true }),
  hideTopIntegrationOption: () => dispatch({ type: actions1.HIDE_TOP_INTEGRATIONS, payload: false }),
  callGetIntegrationAPI: (integrationID) => dispatch(getIntegrationAPI(integrationID)),
  integrationResponse: (integrationResponse) => dispatch({ type: integration.CURRENT_INTEGRATION_RES, payload: integrationResponse }),
  callIntegrationStatus: (integrationID, params) => dispatch(deleteIntegrationAPICall(integrationID, params)),
  getCollaboratorsList: () => dispatch(getCollaboratorsListapi()),
  callIntegrationStatusUpdate: (integrationID, params) => dispatch(putIntegrationAPI(integrationID, params)),
  nameIntegrationAPI: (params) => dispatch(getIntegrationName(params)),
  updateCurrentIntegrationID: (integrationID) => dispatch({ type: integration.CURRENT_INTEGRATIONID, payload: integrationID }),
  getCollaboratorDetails: (integrationID) => dispatch(getCollaboratorsByIntegrationID(integrationID)), 
  removeCollaborator: (integrationID, collaboratorID) => dispatch(deletCollaboratorsByIntegrationID(integrationID, collaboratorID)),
  postCollaboratorDetails: (integrationID, collaboratorID, params) => dispatch(postCollaborators(integrationID, collaboratorID, params)),
  showSnackbar: (params) => dispatch({ type: actions.SNACKBAR_SHOW, payload: params}),
  updateCurrentIntegrationName:(integrationName)=>dispatch({type: integration.CURRENT_INTEGRATION_NAME,payload:integrationName})
})
export default connect(mapStateToProps, mapDispatchToProps)(EditIntegration);

const Line42 = styled.img`
  height: 2px;
  left: 188px;
  position: absolute;
  top: 158px;
  width: 990px;
`;
// left: 188px;
// width: 63px;
const Line43 = styled.img`
  height: 2px;
  position: absolute;
  top: 158px;
  width: 72px;
  left: 193px;
`;
