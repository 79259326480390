export const MULTIPLE_PERIODS_FETCHING = 'MULTIPLE_PERIODS_FETCHING';
export const MULTIPLE_PERIODS_SUCCESS = 'MULTIPLE_PERIODS_SUCCESS';
export const MULTIPLE_PERIODS_ERROR = 'MULTIPLE_PERIODS_ERROR';

const currentYear = new Date().getFullYear() 

const initialState = {
  multiData: {
    periodType: '',
    enterRecordsForThisPeriodType: false,
    numberOfPeriodsPerYear: '',
    startPeriod: '',
    startDate: '',
    endPeriod: '',
    bonusCheckDelayDays: ''
  }, 
  error: {},
  fetching: false,
  success: false
}

export default function multiplePeriodsReducer (state, action) {
  if (typeof state === 'undefined') {
    return initialState
  }
  switch (action.type) {
    case MULTIPLE_PERIODS_FETCHING:
      return { ...state, fetching: true }
    case MULTIPLE_PERIODS_SUCCESS:
      return {
        ...state,
        fetching: false,
        success: true,
        multiData: action.payload
      }
    case MULTIPLE_PERIODS_ERROR:
      return {
        ...state,
        fetching: false,
        success: false,
        data: action.payload
      }

    default:
      return state
  }
}
