import "./App.css";
import React from "react";
import PortalLanding from "../src/PortalLanding";
import CommissionsLanding from "./components/Commissions/CommissionsLanding/CommissionsLanding";
import AllIntegrations from "../src/components/portalv3/AllIntegrations/index";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import CallBack from '../src/CallBack';
import Renew from '../src/Renew';
import EditIntegration from "./components/portalv3/EditIntegration/index";
import Commissions from './components/homenav/Commissions'
import NotFound from "../src/Notfound";
import { gettokenScopeValidation } from "./components/utilities/Services";
import { allNullTypeCheck } from "./components/Commissions/commonComponents/UtilityFun";
let hashHistory = Router.hashHistory;

class App extends React.Component {

  render() {
  let selectApp = localStorage.getItem("appselect")
  let selectfilter = ''
  console.log("selected app : ",selectApp);
   if(selectApp === 'portal' && gettokenScopeValidation("/integrations")){
    selectfilter = PortalLanding
  }
  else if (selectApp === 'commissions' && gettokenScopeValidation("/commissions")){
    selectfilter = CommissionsLanding
  }
  else{
    if(allNullTypeCheck(selectApp)){
      selectfilter = Commissions
    }
  }
    return (
      <Router basename={'/'}  history={hashHistory}>
        <Switch>
            <Route strict exact path="/" component={ selectApp === '' || selectApp == null ? Commissions : selectfilter} />
            <Route path="/portal" exact component={((selectApp === 'portal') && gettokenScopeValidation("/integrations"))? PortalLanding: NotFound} />
            <Route strict exact path="/home/:flextype?" component={ selectApp === '' || selectApp == null ? Commissions : selectfilter} />
            <Route path="/editIntegration" component ={EditIntegration}/>
            <Route path="/callback" component={CallBack} />
            <Route path="/renew" component={Renew} />
            <Route path="/allintegrations" component={AllIntegrations} />
            <Route path="/commissions" component={((selectApp === 'commissions') && gettokenScopeValidation("/commissions"))? CommissionsLanding: NotFound}/>
            <Route component={NotFound}/>
            </Switch>
        </Router>
    );
  }
}
export default App;