import {
    RUNINFODIST_SUCCESS,
    RUNINFODIST_ERROR,
    DISTRIBUTOR_HISTORY_SUCCESS,
    DISTRIBUTOR_HISTORY_ERROR,
    DISTRIBUTOR_FLAGS_SUCCESS,
    DISTRIBUTOR_FLAGS_ERROR,
    DISTRIBUTOR_DATEMISC_SUCCESS,
    DISTRIBUTOR_DATEMISC_ERROR,
    DISTRIBUTOR_AMTFIELDS_SUCCESS,
    DISTRIBUTOR_AMTFIELDS_ERROR,
    DISTRIBUTOR_COUNTRYINFO_SUCCESS,
    DISTRIBUTOR_COUNTRYINFO_ERROR,
    DISTRIBUTOR_PAIDTO_SUCCESS,
    DISTRIBUTOR_PAIDTO_ERROR
} from "../actions"


const initialState = {
    commissionRunDistInfo: [],
    commissionRunDistInfoError: {},
    distHistoryData: [],
    distHistoryError: {},
    distFlagsData: [],
    distFlagsError: {},
    distDateMiscData: [],
    distDateMiscError: {},
    distAmtfieldsData: [],
    distAmtfieldsError: {},
    distCountryInfoData: [],
    distCountryInfoError: {},
    distPaidToData:[],
    distPaidToError: {}
}

export default function commissionDistResearch(state, action) {
    if (typeof state === 'undefined') {
        return initialState;
    }
    switch (action.type) {
        case RUNINFODIST_SUCCESS:
            return { ...state, commissionRunDistInfo: action.payload };
        case RUNINFODIST_ERROR:
            return { ...state, commissionRunDistInfoError: {} };
        case DISTRIBUTOR_HISTORY_SUCCESS:
            return { ...state, distHistoryData: action.payload };
        case DISTRIBUTOR_HISTORY_ERROR:
            return { ...state, distHistoryError: {} };
        case DISTRIBUTOR_FLAGS_SUCCESS:
            return { ...state, distFlagsData: action.payload };
        case DISTRIBUTOR_FLAGS_ERROR:
            return { ...state, distFlagsError: {} };
        case DISTRIBUTOR_DATEMISC_SUCCESS:
            return { ...state, distDateMiscData: action.payload };
        case DISTRIBUTOR_DATEMISC_ERROR:
            return { ...state, distDateMiscError: {} };
        case DISTRIBUTOR_AMTFIELDS_SUCCESS:
            return { ...state, distAmtfieldsData: action.payload };
        case DISTRIBUTOR_AMTFIELDS_ERROR:
            return { ...state, distAmtfieldsError: {} };
        case DISTRIBUTOR_COUNTRYINFO_SUCCESS:
            return { ...state, distCountryInfoData: action.payload };
        case DISTRIBUTOR_COUNTRYINFO_ERROR:
            return { ...state, distCountryInfoError: {} };
        case DISTRIBUTOR_PAIDTO_SUCCESS:
            return { ...state, distPaidToData: action.payload };
        case DISTRIBUTOR_PAIDTO_ERROR:
            return { ...state, distPaidToError: {} };
        default:
            return state;
    }
}