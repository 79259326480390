import activePeriodMaintenanceReducer from './activePeriodMaintenance'
import singlePeriodsReducer from './singlePeriods'
import multiplePeriodsReducer from './multiplePeriods'
import loadingStateReducer from './utilsReducer'
import waviersReducer from './wavierReducer'
import amWaviersReducer from './applyManageWavierReducer'
import currenciesReducer from './currenciesReducer'
import periodTypesReducer from './periodTypesReducer'
import programsReducer from './manageProgramsReducer'
import periodsReducer from './periodsReducer';

const settingsReducers = {
  activePeriodMaintenanceReducer,
  singlePeriodsReducer,
  multiplePeriodsReducer,
  loadingStateReducer,
  waviersReducer,
  amWaviersReducer,
  currenciesReducer,
  periodTypesReducer,
  programsReducer,
  periodsReducer
}

export default settingsReducers
