import React from 'react';
import '../ViewRunLog/ViewRunLog.css'
import PropTypes from 'prop-types';
import AuthService from '../../../../src/components/server/AuthService';
import '../CommissionsRunManager/CommissionRunManager.css';
import { Oval } from 'react-loader-spinner';
import { connect } from 'react-redux';
import { Modal } from "react-bootstrap";
import closeIcon from '../assets/closeicon.svg';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withRouter } from 'react-router-dom';
import { getRankMatrixInfoAPI } from '../actions';
import * as Constant from '../../../constants/Constants';
import { allNullTypeCheck, isNullResponse } from '../commonComponents/UtilityFun';
import NewSnackbar from "../../utilities/NewSnackbar";
import actions from '../../../redux/actions/CommonDataActions';
import { numberWithCommas } from "../../utilities/Services";
import vector13 from '../../../assets/vector-13@2x.png';
import "./GeneralTab.css";

let initialValue = 0.0;

const fields = [
    { name: 'ChequeNo', displayName: "Paid Rank", inputFilterable: true, sortable: true },
    { name: 'DistID', displayName: "Bv", inputFilterable: false, exactFilterable: true, sortable: true },
    { name: 'Name', displayName: "Org Volume", inputFilterable: false, exactFilterable: true, sortable: true },
    { name: 'CountryCode', displayName: "Active Leg", inputFilterable: true, sortable: true },
    { name: 'ChequeDate', displayName: "Org Volume", inputFilterable: false, exactFilterable: true, sortable: true },
    { name: 'DistType', displayName: "Org Volume-1", inputFilterable: false, exactFilterable: true, sortable: true },
];
class CustomRankMatrix extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedValue: 'Primary',
            selectPeriodVal: '00/0000',
            selectedCommissionPeriodIndex: 0,
            commissionTypes: [],
            commissionRuns: [],
            selectedCommissionType: 'Primary',
            commissionPeriodData: null,
            commissionPeriods: [],
            dataObj: [],
            previousDisabled: false,
            nextDisabled: false,
            anchorEl: false,
            rankMatrixListState: [],
            selectedRunInfo: [],
            showLoaderResult: false,
            disableNewRun:true,
            selectedRadioValue:'All Ranks',
            runsWaitTime:1
        }
        //  this.viewLogTableDisplay = this.viewLogTableDisplay.bind(this);
    }

    componentDidMount() {
        const { distributorID, runID } = this.props;
        if (allNullTypeCheck(distributorID) && allNullTypeCheck(runID)) {
            this.setState({showLoaderResult: true})
            this.props.getRankMatrix(runID);
        }
    }
    componentDidUpdate(nextProps) {
        const { rankMatrixDetailsList, rankMatrixDetailsError, runID } = this.props;
        const { runsWaitTime } = this.state;
        if (rankMatrixDetailsList !== nextProps.rankMatrixDetailsList){
            console.log("response is : ",rankMatrixDetailsList);
            this.setState({showLoaderResult: false });
            if(isNullResponse(rankMatrixDetailsList?.data) && rankMatrixDetailsList?.error === false) {
                this.setState({ rankMatrixListState: rankMatrixDetailsList?.data});
            } else if(Object.keys(rankMatrixDetailsList?.data).length===0 && allNullTypeCheck(rankMatrixDetailsList?.messages)) {
                if(rankMatrixDetailsList?.messages?.length > 0 && rankMatrixDetailsList?.messages[0]?.includes("Try again...")){
                    console.log("Try again block entered : ",rankMatrixDetailsList?.data);
                    this.setState({ showLoader: true });
                    setTimeout(() => {
                        this.props.getRankMatrix(runID);
                    }, 1000 * runsWaitTime);
                    this.setState(prevState => {
                      return {runsWaitTime: prevState.runsWaitTime * 2}
                   })
                }
            }
           else if(rankMatrixDetailsList.error === true){
              this.setState({showLoaderResult: false });
                this.props.showSnackbar({snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: typeof(rankMatrixDetailsList?.data?.messages[0])!== 'string'? rankMatrixDetailsList?.messages[0]?.code +' - '+rankMatrixDetailsList?.messages[0]?.detail: rankMatrixDetailsList?.messages[0] })
            }
        }
        else if(rankMatrixDetailsError !== nextProps.rankMatrixDetailsError){
            this.setState({showLoaderResult: false });
            console.log("error is : ",rankMatrixDetailsError)
            if(rankMatrixDetailsError?.data?.error === true){
                this.props.showSnackbar({snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: typeof(rankMatrixDetailsError?.data?.messages[0])!== 'string'? rankMatrixDetailsError?.data?.messages[0]?.code +' - '+rankMatrixDetailsError?.data?.messages[0]?.detail : rankMatrixDetailsError?.data?.messages[0]})
               }
        }
    }
    totalEarningsdisplay= () => {
		const { rankMatrixListState } = this.state;
        let totalValue = 0
        rankMatrixListState.map((commissionrun) => (
            totalValue = totalValue+ commissionrun.earnings
        ))
		return ( <div className='retailprofit-earnings' style={{marginRight: '15px'}}>Total Earnings : <span style={{marginLeft: '35px'}}>{totalValue?.toFixed(2)}</span>
        </div>)
    }
    hideModal() {
        // this.setState({ hideModal: true, showRemoveColModal: false });
        this.props.closefunction()
      }
      confirmModal() {
        this.setState({ showLoaderResult: true });
        this.hideModal();
        this.props.selectedRun(this.state.selectedCommissionRun);
      }  
    handleChange=(e)=>{


    }
    render() {
        let loader;
        const { showLoaderResult,rankMatrixListState, disableNewRun, selectedRadioValue } = this.state;
        if (AuthService.isAuthenticated()) {
        if (showLoaderResult) {
            loader = <div id="center" style={{ position: "fixed", bottom: "40%", left: "48%" }}>
                <Oval
                    type="Puff"
                    position="fixed"  secondaryColor="#E85557"
                    color="#E85557"
                    height={50}
                    width={50}
                    timeout={3000}
                />
            </div>
        }
            return (
                <div className='row com-overlap-group-1'>
                <div className='col-9'>
                <div className="flex">
                    <div className="rowalign-center" style={{ height: '80px' }}>
                    <div className="col-8 h2title" style={{top:"30px"}}>
                           <span>{"Rank Matrix"}</span>
                           <div>
                           <div style={{marginTop:'20px'}}>
                               <div className='rank-matrix-details'><lable className='rank-matrix-label'>{"DistributorID"}</lable><span className='rank-matrix-values'>{this.props?.distributorID}</span></div>
                               <div className='rank-matrix-details'><lable className='rank-matrix-label'>{"Name"}</lable><span className='rank-matrix-values'>{"Health Strategies"}</span></div>
                               <div className='rank-matrix-details'><lable className='rank-matrix-label'>{"Current Rank"}</lable><span className='rank-matrix-values'>{this.props?.currentRankID}</span></div>
                           </div>
                           <div className="row add-item-style rank-radio-container">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1"/>
                                <label class="form-check-label rank-matrix-radio-label" for="inlineRadio1">{"All Ranks"}</label>
                                </div>
                                <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2"/>
                                <label class="form-check-label rank-matrix-radio-label" for="inlineRadio2">{"Next Ranks"}</label>
                            </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-4 padding-right-0">
                        </div>
                    </div>
                    <img className="updatelg-close-icon" onClick={()=>this.props.closefunction()} src={closeIcon} alt='' style={{ cursor: 'pointer' }} />
                    <div className="col-12 cm-table-style" style={{display:"flex"}} >
                      <div className="col-3 rankmatrix-general-tabcontainer">
                        <TableContainer component={Paper} className = 'retail-height'>
                        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table" className='general-table-left'>
                                <TableHead  className='retailpf-table-header2'>
                                    {rankMatrixListState?.qualificationFields?.length > 0?
                                        <TableCell className='rankmatrix-table-header' component="th" scope="row">
                                            {this.props?.rankLabel}
                                        </TableCell>
                                    :''} 
                                </TableHead>
                                <TableBody>                                    
                                    {rankMatrixListState?.ranks?.length > 0 ?
                                        rankMatrixListState?.ranks?.map((data, i)=>
                                            <TableRow key={i}>
                                            <TableCell className='rank-matrix-left-tabelcell'>{data?.label}</TableCell>
                                        </TableRow>
                                            )
                                        :''
                                    }
                                </TableBody>
                            </Table>
                            </TableContainer>
                            </div>
                            <hr className='line'/>
                            <div className="col-9 distres-general-rightcontainer">
                            <TableContainer component={Paper} className = 'retail-height'>
                            {rankMatrixListState?.ranks?.length > 0 ? <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table" className='general-table-left'>
                                <TableHead  className='retailpf-table-header2'>
                                    {rankMatrixListState?.qualificationFields?.length > 0? rankMatrixListState?.qualificationFields?.map((row) => (
                                        <TableCell className='retailpf-table-header2' component="th" scope="row">
                                            {row.label}
                                        </TableCell>
                                    )):''}
                                </TableHead>
                                <TableBody>                             
                                    {rankMatrixListState?.ranks?.length > 0 ?
                                        rankMatrixListState?.ranks?.map((data, i)=>
                                            <TableRow key={i}>
                                            {rankMatrixListState?.qualificationFields.map((qualVal,j)=>
                                            <TableCell className='rank-matrix-tabelcell'>{data?.qualificationValues[data?.qualificationValues?.findIndex((val) =>{
                                               return val?.name === qualVal?.name
                                            })]?.value}</TableCell>)}
                                        </TableRow>
                                            )
                                        :''
                                    }
                                </TableBody>
                            </Table> : <div className="row noreports data-text-style">
                            {/* <img src={noreports} alt='' /> */}
                            No Data Available
                        </div> }
                        </TableContainer>
                        </div>
                        {/* {rankMatrixListState.length === 0 ? <div className="row noreports data-text-style">
                            {/* <img src={noreports} alt='' /> */}
                            {/* No Data Available */}
                        {/* </div> :  */}
                        {loader}
                        <NewSnackbar/>
                    </div>
                </div>
                </div>
            </div>
                );
        }
    }
}

CustomRankMatrix.propTypes = {
    retprofitbackfunc: PropTypes.func
};
const mapStateToProps = (state) => {
    return {
        rankMatrixDetailsList: state.distributorResearchReducer.rankMatrixDetailsList,
        rankMatrixDetailsError: state.distributorResearchReducer.rankMatrixDetailsError,
    }
}

const mapDispatchToProps = (dispatch) => ({
    getRankMatrix: (selectedRunID) => dispatch(getRankMatrixInfoAPI(selectedRunID)),
    showSnackbar: (params) => dispatch({ type: actions.SNACKBAR_SHOW, payload: params}),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomRankMatrix));