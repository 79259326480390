import React, { Component } from 'react';
import Oidc from 'oidc-client';

export default class Renew extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount() {
    new Oidc.UserManager().signinSilentCallback().then(() => {
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('signinSilentCallback successfull');}
    }).catch((e) => {
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log('redirect func faileds in renew token!!!');}
      console.error(e);
    });
  }
  render() {
    return (
      <div className="container">
      </div>);
  }
}