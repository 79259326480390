import AuthService from '../../components/server/AuthService';
import { singletonAxiosInstance } from '../../components/utilities/Server';
import Endpoints from '../../components/utilities/Endpoints';
import {user as userActions} from '../actions/userDataActions';



const server = singletonAxiosInstance;
const options = {
  Authorization: 'Bearer ' + localStorage.getItem('id_token')
    
};
const forgotoption = {    
  'Content-Type': 'application/x-www-form-urlencoded'

    
};

const statCodes = {
  SUCCESS: 200,
  CREATE_SUCCESS:201,
  unAuthorized: 403
};

export const getUserListapi = () => {
  return dispatch => {      
    if(AuthService.isTokenValid()===true){
      server.get(Endpoints.user, '', options).then((data) => {     
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('calling user list endpoint',Endpoints.user);}
        if (data.status === statCodes.SUCCESS) {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('user redux thunk response :',data.data); }
          dispatch({type:userActions.USERLIST_SUCCESS,payload:data.data});                
        }else{
          dispatch({type:userActions.USERLIST_HASERROR,payload:data});
        }
      }).catch((error) => {
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}       
        if(!error.response){
          dispatch({type:userActions.USERLIST_HASERROR,payload:error});            
        }else{
          dispatch({type:userActions.USERLIST_HASERROR,payload:error.response});            
        }
        
      });
    }else{
      const user = AuthService.signinSilentToken();
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('signinSilentToken user :',user);}
      if(user){
        localStorage.setItem('id_token', user.id_token);        
        server.get(Endpoints.user, '', options).then((data) => {     
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('calling user list endpoint',Endpoints.user);}
          if (data.status === statCodes.SUCCESS) {
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log("user redux thunk response :",data.data); }
              dispatch({type:userActions.USERLIST_SUCCESS,payload:data.data});                
            }else{
              dispatch({type:userActions.USERLIST_HASERROR,payload:data});
            }
        }).catch((error) => {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}
          if(!error.response){
            dispatch({type:userActions.USERLIST_HASERROR,payload:error});            
          }else{
            dispatch({type:userActions.USERLIST_HASERROR,payload:error.response});            
          }
        });
      }   
    }
  };
};

export const getCollaboratorsListapi = () => {
  return dispatch => {      
    if(AuthService.isTokenValid()===true){
      server.get(Endpoints.collaborators, '', options).then((data) => {  
        if (data.status === statCodes.SUCCESS) {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('success collaborators thunk response :',data.data); }
          dispatch({type:userActions.COLLABORATORSLIST_SUCCESS,payload:data.data});                
        }else{
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Failed collaborators list response',data.data); }
          dispatch({type:userActions.COLLABORATORSLIST_HASERROR,payload:data});
        }
      }).catch((error) => {          
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}               
        if (!error.response) {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error without response : ', error);}               
          dispatch({type:userActions.COLLABORATORSLIST_HASERROR,payload:error});          
        }else{
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error with response : ', error);}               
          dispatch({type:userActions.COLLABORATORSLIST_HASERROR,payload:error.response});   
        }
          
      });
    }else{
      const user = AuthService.signinSilentToken();
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('signinSilentToken user :',user);}
      if(user){
        localStorage.setItem('id_token', user.id_token);        
        server.get(Endpoints.collaborators, '', options).then((data) => {     
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('calling collaborators list endpoint',Endpoints.collaborators);}
          if (data.status === statCodes.SUCCESS) {
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log("collaborators redux thunk response :",data.data); }
              dispatch({type:userActions.COLLABORATORSLIST_SUCCESS,payload:data.data});                
            }else{
              dispatch({type:userActions.COLLABORATORSLIST_HASERROR,payload:data});
            }
        }).catch((error) => {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}               
          if (!error.response) {
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error without response : ', error);}               
            dispatch({type:userActions.COLLABORATORSLIST_HASERROR,payload:error});          
          }else{
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error with response : ', error);}               
            dispatch({type:userActions.COLLABORATORSLIST_HASERROR,payload:error.response});   
          }
        });
      }   
    }
  };
};

export const getCollaboratorsByIntegrationID = (integrationID) => {
  return dispatch => {      
    if(AuthService.isTokenValid()===true){
      server.get(Endpoints.getcollaborators+'/'+integrationID, '', options).then((data) => {  
        if (data.status === statCodes.SUCCESS) {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('success getcollaborators thunk response :',data.data); }
          dispatch({type:userActions.GET_COLLABORATORS_SUCCESS,payload:data.data});                
        }else{
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Failed getcollaborators list response',data.data); }
          dispatch({type:userActions.GET_COLLABORATORS_HASERROR,payload:data});
        }
      }).catch((error) => {          
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}               
        if (!error.response) {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error without response : ', error);}               
          dispatch({type:userActions.GET_COLLABORATORS_HASERROR,payload:error});          
        }else{
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error with response : ', error);}               
          dispatch({type:userActions.GET_COLLABORATORS_HASERROR,payload:error.response});   
        }
      });
    }else{
      const user = AuthService.signinSilentToken();
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('signinSilentToken user :',user);}
      if(user){
        localStorage.setItem('id_token', user.id_token);        
        server.get(Endpoints.getcollaborators+'/'+integrationID, '', options).then((data) => {     
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('calling getcollaborators endpoint',Endpoints.collaborators);}
          if (data.status === statCodes.SUCCESS) {
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log("getcollaborators redux thunk response :",data.data); }
              dispatch({type:userActions.GET_COLLABORATORS_SUCCESS,payload:data.data});                
            }else{
              dispatch({type:userActions.GET_COLLABORATORS_HASERROR,payload:data});
            }
        }).catch((error) => {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}               
          if (!error.response) {
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error without response : ', error);}               
            dispatch({type:userActions.GET_COLLABORATORS_HASERROR,payload:error});          
          }else{
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error with response : ', error);}               
            dispatch({type:userActions.GET_COLLABORATORS_HASERROR,payload:error.response});   
          }
        });
      }   
    }
  };
};

export const deletCollaboratorsByIntegrationID = (integrationID,collaboratorID) => {
  return dispatch => {      
    if(AuthService.isTokenValid()===true){
      server.delete(Endpoints.getcollaborators+'/'+integrationID+'/'+collaboratorID, '', options).then((data) => {  
        if (data.status === statCodes.SUCCESS) {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('remove success getcollaborators thunk response :',data.data); }
          dispatch({type:userActions.REMOVE_COLLABORATORS_SUCCESS,payload:data.data});                
        }else{
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Failed getcollaborators list response',data.data); }
          dispatch({type:userActions.REMOVE_COLLABORATORS_HASERROR,payload:data});
        }
      }).catch((error) => {          
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}               
        if (!error.response) {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error without response : ', error);}               
          dispatch({type:userActions.REMOVE_COLLABORATORS_HASERROR,payload:error});          
        }else{
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error with response : ', error);}               
          dispatch({type:userActions.REMOVE_COLLABORATORS_HASERROR,payload:error.response});   
        }
      });
    }else{
      const user = AuthService.signinSilentToken();
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('signinSilentToken user :',user);}
      if(user){
        localStorage.setItem('id_token', user.id_token);        
        server.delete(Endpoints.getcollaborators+'/'+integrationID+'/'+collaboratorID, '', options).then((data) => {     
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('calling remove collaborators endpoint',Endpoints.collaborators);}
          if (data.status === statCodes.SUCCESS) {
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log("remove collaborators redux thunk response :",data.data); }
              dispatch({type:userActions.REMOVE_COLLABORATORS_SUCCESS,payload:data.data});                
            }else{
              dispatch({type:userActions.REMOVE_COLLABORATORS_HASERROR,payload:data});
            }
        }).catch((error) => {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}               
          if (!error.response) {
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error without response : ', error);}               
            dispatch({type:userActions.REMOVE_COLLABORATORS_HASERROR,payload:error});          
          }else{
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error with response : ', error);}               
            dispatch({type:userActions.REMOVE_COLLABORATORS_HASERROR,payload:error.response});   
          }
        });
      }   
    }
  };
};

export const postCollaborators=(integrationID, collaboratorID, params)=>{
  return dispatch => {    
    if(AuthService.isTokenValid()===true){
      server.post(Endpoints.getcollaborators+'/'+integrationID+'/'+collaboratorID,'', params, options).then((data) => {                          
        if (data.status === statCodes.SUCCESS) {   
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('CreateIntegration res :',data);}
          dispatch({type:userActions.POST_COLLABORATORS_SUCCESS,payload:data.data});
        }else{
          dispatch({type:userActions.POST_COLLABORATORS_HASERROR,payload:data});
        }
      }).catch((error) => {
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}
        if(!error.response){
          dispatch({type:userActions.POST_COLLABORATORS_HASERROR,payload:error}); 
        }else{
          dispatch({type:userActions.POST_COLLABORATORS_HASERROR,payload:error.response}); 
        }
                   
      });
    }else{
      const user = AuthService.signinSilentToken();
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('signinSilentToken user :',user);}
      if(user){
        localStorage.setItem('id_token', user.id_token); 
        server.post(Endpoints.getcollaborators+'/'+integrationID+'/'+collaboratorID,'', params, options).then((data) => {                      
          if (data.status === statCodes.SUCCESS) {   
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('CreateIntegration res :',data.data);}
            dispatch({type:userActions.POST_COLLABORATORS_SUCCESS,payload:data.data});
          }else{
            dispatch({type:userActions.POST_COLLABORATORS_HASERROR,payload:data});
          }
        }).catch((error) => {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}
          if(!error.response){
            dispatch({type:userActions.POST_COLLABORATORS_HASERROR,payload:error}); 
          }else{
            dispatch({type:userActions.POST_COLLABORATORS_HASERROR,payload:error.response}); 
          }            
        });
      }       
    }   
  };
};
export const sendInviteAPI = (params) => {
  return dispatch => {    
    if(AuthService.isTokenValid()===true){
      server.post(Endpoints.user,'', params, options).then((data) => {     
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('calling method: if than'); }
        if (data.status === statCodes.SUCCESS) {   
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('if thunk invite user response',data);}
          dispatch({type:'INVITEUSER_SUCCESS',payload:data.data});
        }else{
          dispatch({type:'INVITEUSER_HASERROR',payload:data});
        }
      }).catch((error) => {
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}               
        if (!error.response) {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error without response : ', error);}               
          dispatch({type:userActions.INVITEUSER_HASERROR,payload:error});          
        }else{
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error with response : ', error);}               
          dispatch({type:userActions.INVITEUSER_HASERROR,payload:error.response});   
        }
      });
    }else{
      const user = AuthService.signinSilentToken();
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('signinSilentToken user :',user);}
      if(user){
        localStorage.setItem('id_token', user.id_token); 
        server.post(Endpoints.user,'', params, options).then((data) => {     
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('calling method: if than');}
          if (data.status === statCodes.SUCCESS) {   
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('thunk invite user response :',data.data); }
            dispatch({type:'INVITEUSER_SUCCESS',payload:data.data});
          }else{
            dispatch({type:'INVITEUSER_HASERROR',payload:data});
          }
        }).catch((error) => {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}
          if (!error.response) {
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error without response : ', error);}               
            dispatch({type:userActions.INVITEUSER_HASERROR,payload:error});          
          }else{
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error with response : ', error);}               
            dispatch({type:userActions.INVITEUSER_HASERROR,payload:error.response});   
          }            
        });
      }       
    }     
  };
};

export const resendInviteAPI = (userid) => {
  return dispatch => {    
    if(AuthService.isTokenValid()===true){
      server.get(Endpoints.resendInvite+'/'+userid,'', options).then((data) => {     
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('calling method: if than'); }
        if (data.status === statCodes.SUCCESS) {   
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('if thunk invite user response',data); }
          dispatch({type:userActions.RESENDINVITE_SUCCESS,payload:data.data});
        }else{
          dispatch({type:userActions.RESENDINVITE_HASERROR,payload:data});
        }
      }).catch((error) => {
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error); }
        if(!error.response){
          dispatch({type:userActions.RESENDINVITE_HASERROR,payload:error}); 
        }else{
          dispatch({type:userActions.RESENDINVITE_HASERROR,payload:error.response}); 
        }
                   
      });
    }else{
      const user = AuthService.signinSilentToken();
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('signinSilentToken user :',user);}
      if(user){
        localStorage.setItem('id_token', user.id_token); 
        server.get(Endpoints.resendInvite+'/'+userid,'', options).then((data) => {     
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('calling method: if than');}
          if (data.status === statCodes.SUCCESS) {   
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('thunk invite user response :',data.data); }
            dispatch({type:userActions.RESENDINVITE_SUCCESS,payload:data.data});
          }else{
            dispatch({type:userActions.RESENDINVITE_HASERROR,payload:data});
          }
        }).catch((error) => {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error); }
          if(!error.response){
            dispatch({type:userActions.RESENDINVITE_HASERROR,payload:error}); 
          }else{
            dispatch({type:userActions.RESENDINVITE_HASERROR,payload:error.response}); 
          }
        });
      }       
    }     
  };
};

export const confirmEmailAPI = () => {
  return dispatch => {    
    if(AuthService.isTokenValid()===true){
      server.get(Endpoints.confirm,'', options).then((data) => {     
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Confirmation mail : ',data); }
        if (data.status === statCodes.SUCCESS) {   
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('if thunk confirmemail user response',data);  }
          dispatch({type:'CONFIRMEMAIL_SUCCESS',payload:data.data});
        }else{
          dispatch({type:'CONFIRMEMAIL_HASERROR',payload:data});
        }
      }).catch((error) => {
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error); }
        if(!error.response){
          dispatch({type:'CONFIRMEMAIL_HASERROR',payload:error});  
        }else{
          dispatch({type:'CONFIRMEMAIL_HASERROR',payload:error.response});  
        }
                  
      });
    }else{
      const user = AuthService.signinSilentToken();
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('signinSilentToken user :',user);}
      if(user){
        localStorage.setItem('id_token', user.id_token); 
        server.get(Endpoints.confirm,'', options).then((data) => {     
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('confirmation mail res :',data); }
          if (data.status === statCodes.SUCCESS) {   
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('thunk confirmemail user response :',data.data); }
            dispatch({type:'CONFIRMEMAIL_SUCCESS',payload:data.data});
          }else{
            dispatch({type:'CONFIRMEMAIL_HASERROR',payload:data});
          }
        }).catch((error) => {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error); }
          if(!error.response){
            dispatch({type:'CONFIRMEMAIL_HASERROR',payload:error});  
          }else{
            dispatch({type:'CONFIRMEMAIL_HASERROR',payload:error.response});  
          }          
        });
      }       
    }     
  };
};

export const resetEmailAPI = (params) => {
  return dispatch => {    
    if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('called resetEmailAPI');}
    server.post(Endpoints.resetMail,'', params, forgotoption).then((data) => {      
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('reset mail : ',data);}
      if (data.status === statCodes.SUCCESS) {   
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('if thunk resetemail user response',data); }
        dispatch({type:userActions.RESETMAIL_SUCCESS,payload:data.data});
      }else{
        dispatch({type:userActions.RESETMAIL_HASERROR,payload:data});
      }
    }).catch((error) => {
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}
      if(!error.response){
        dispatch({type:userActions.RESETMAIL_HASERROR,payload:error});  
      }else{
        dispatch({type:userActions.RESETMAIL_HASERROR,payload:error.response});  
      }
                
    });
    
  };
};



export const getUserAPICall=(userid)=>{

  return dispatch => {      
    if(AuthService.isTokenValid()===true){
      server.get(Endpoints.user+'/'+userid, '', options).then((data) => {     
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('calling user list endpoint',Endpoints.user); }
        if (data.status === statCodes.SUCCESS) {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('user redux thunk response :',data.data); }
          dispatch({type:userActions.GETUSER_SUCCESS,payload:data.data});
        }else{
          dispatch({type:userActions.GETUSER_HASERROR,payload:data});
        }
      }).catch((error) => {
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}       
        if(!error.response){
          dispatch({type:userActions.GETUSER_HASERROR,payload:error});            
        }else{
          dispatch({type:userActions.GETUSER_HASERROR,payload:error.response});            
        }
       
      });
    }else{
      const user = AuthService.signinSilentToken();
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('signinSilentToken user :',user);}
      if(user){
        localStorage.setItem('id_token', user.id_token);        
        server.get(Endpoints.user+'/'+userid, '', options).then((data) => {     
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('calling user list endpoint',Endpoints.user);}
          if (data.status === statCodes.SUCCESS) {
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('user redux thunk response :',data.data); }
            dispatch({type:userActions.GETUSER_SUCCESS,payload:data.data});
          }else{
            dispatch({type:userActions.GETUSER_HASERROR,payload:data});
          }
        }).catch((error) => {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}        
          if(!error.response){
            dispatch({type:userActions.GETUSER_HASERROR,payload:error});            
          }else{
            dispatch({type:userActions.GETUSER_HASERROR,payload:error.response});            
          }
        });
      }else{
        AuthService.loginPage();
      }
    }     
  };
};


export const editUserAPICall=(userid,params)=>{
  return dispatch => {      
    if(AuthService.isTokenValid()===true){
      server.put(Endpoints.user+'/'+userid, params, options).then((data) => {     
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('calling user list endpoint',Endpoints.user); }
        if (data.status === statCodes.SUCCESS) {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('user redux thunk response :',data.data); }
          dispatch({type:userActions.EDITUSER_SUCCESS,payload:data.data});
        }else{
          dispatch({type:userActions.EDITUSER_HASERROR,payload:data});
        }
      }).catch((error) => {
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}       
        if(!error.response){
          dispatch({type:userActions.EDITUSER_HASERROR,payload:error});  
        }else{
          dispatch({type:userActions.EDITUSER_HASERROR,payload:error.response});  
        }
                  
      });
    }else{
      const user = AuthService.signinSilentToken();
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('signinSilentToken user :',user);}
      if(user){
        localStorage.setItem('id_token', user.id_token);        
        server.put(Endpoints.user+'/'+userid, params, options).then((data) => {     
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('calling user list endpoint',Endpoints.user); }
          if (data.status === statCodes.SUCCESS) {
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('user redux thunk response :',data.data); }
            dispatch({type:userActions.EDITUSER_SUCCESS,payload:data.data});
          }else{
            dispatch({type:userActions.EDITUSER_HASERROR,payload:data});
          }
        }).catch((error) => {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}        
          if(!error.response){
            dispatch({type:userActions.EDITUSER_HASERROR,payload:error});  
          }else{
            dispatch({type:userActions.EDITUSER_HASERROR,payload:error.response});  
          }
        });
      }else{
        AuthService.loginPage();
      }
    }     
  };
};

export const getUserProfileAPI=(userid)=>{
  return dispatch => {      
    if(AuthService.isTokenValid()===true){
      server.get(Endpoints.user+'/'+userid, '', options).then((data) => {     
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('calling user list endpoint',Endpoints.user); }
        if (data.status === statCodes.SUCCESS) {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('user redux thunk response :',data.data); }
          dispatch({type:userActions.GETUSER_PROFILE_SUCCESS,payload:data.data});
        }else{
          dispatch({type:userActions.GETUSER_PROFILE_HASERROR,payload:data});
        }
      }).catch((error) => {
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}   
        if(!error.response) {   
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log("response access ",error)   }
           dispatch({type:userActions.GETUSER_PROFILE_HASERROR,payload:error});
        }else{
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log("else response called ",error)}
          dispatch({type:userActions.GETUSER_PROFILE_HASERROR,payload:error.response});
        }            
      });
    }else{
      const user = AuthService.signinSilentToken();
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('signinSilentToken user :',user);}
      if(user){
        localStorage.setItem('id_token', user.id_token);        
        server.get(Endpoints.user+'/'+userid, '', options).then((data) => {     
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('calling user list endpoint',Endpoints.user); }
          if (data.status === statCodes.SUCCESS) {
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('user redux thunk response :',data.data); }
            dispatch({type:userActions.GETUSER_PROFILE_SUCCESS,payload:data.data});
          }else{
            dispatch({type:userActions.GETUSER_PROFILE_HASERROR,payload:data});
          }
        }).catch((error) => {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}        
          if(!error.response){
            dispatch({type:userActions.GETUSER_PROFILE_HASERROR,payload:error});            
          }else{
            dispatch({type:userActions.GETUSER_PROFILE_HASERROR,payload:error.response});            
          }
         
        });
      }else{
        AuthService.loginPage();
      }
    }     
  };
};

export const putProfilUpdateAPI=(User_Id,params)=>{
  return dispatch => {      
    if(AuthService.isTokenValid()===true){
      server.put(Endpoints.userProfile+'/'+User_Id, params, options).then((data) => {     
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('calling user list endpoint',Endpoints.user);}
        if (data.status === statCodes.SUCCESS) {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('user redux thunk response :',data.data); }
          dispatch({type:userActions.EDIT_PROFILE_SUCCESS,payload:data.data});
        }else{
          dispatch({type:userActions.EDIT_PROFILE_HASERROR,payload:data});
        }
      }).catch((error) => {
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}       
        if(!error.response){
          dispatch({type:userActions.EDIT_PROFILE_HASERROR,payload:error});            
        }else{
          dispatch({type:userActions.EDIT_PROFILE_HASERROR,payload:error.response});            
        }
       
      });
    }else{
      const user = AuthService.signinSilentToken();
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('signinSilentToken user :',user);}
      if(user){
        localStorage.setItem('id_token', user.id_token);        
        server.put(Endpoints.userProfile, params, options).then((data) => {     
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('calling user list endpoint',Endpoints.user); }
          if (data.status === statCodes.SUCCESS) {
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('user redux thunk response :',data.data); }
            dispatch({type:userActions.EDIT_PROFILE_SUCCESS,payload:data.data});
          }else{
            dispatch({type:userActions.EDIT_PROFILE_HASERROR,payload:data});
          }
        }).catch((error) => {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}        
          if(!error.response){
            dispatch({type:userActions.EDIT_PROFILE_HASERROR,payload:error});            
          }else{
            dispatch({type:userActions.EDIT_PROFILE_HASERROR,payload:error.response});            
          }         
        });
      }else{
        AuthService.loginPage();
      }
    }     
  };
};


export const deleteUserAPICall=(userid,params)=>{
  return dispatch => {      
    if(AuthService.isTokenValid()===true){
      server.delete(Endpoints.deleteUser+'/'+userid,'', options).then((data) => {     
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('calling user list endpoint',Endpoints.user,'params :',params); }
        if (data.status === statCodes.SUCCESS) {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('user redux thunk response :',data.data); }
          dispatch({type:userActions.DELETE_USER_SUCCESS,payload:data.data});
        }else{
          dispatch({type:userActions.DELETE_USER_HASERROR,payload:data});
        }
      }).catch((error) => {
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}       
       if(!error.response){
        dispatch({type:userActions.DELETE_USER_HASERROR,payload:error});            
       }else{
        dispatch({type:userActions.DELETE_USER_HASERROR,payload:error.response});            
       }
       
      });
    }else{
      const user = AuthService.signinSilentToken();
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('signinSilentToken user :',user);}
      if(user){
        localStorage.setItem('id_token', user.id_token);        
        server.delete(Endpoints.deleteUser+'/'+userid,'', options).then((data) => {     
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('calling user list endpoint',Endpoints.user);}
          if (data.status === statCodes.SUCCESS) {
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('user redux thunk response :',data.data); }
            dispatch({type:userActions.DELETE_USER_SUCCESS,payload:data.data});
          }else{
            dispatch({type:userActions.DELETE_USER_HASERROR,payload:data});
          }
        }).catch((error) => {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}        
          if(!error.response){
            dispatch({type:userActions.DELETE_USER_HASERROR,payload:error});            
           }else{
            dispatch({type:userActions.DELETE_USER_HASERROR,payload:error.response});            
           }
        });
      }else{
        AuthService.loginPage();
      }
    }     
  };
};

export const getRolesListAPI = (integrationID) => {
    return dispatch => {      
      if(AuthService.isTokenValid()===true){
        server.get(Endpoints.userRoles,{'integrationid':integrationID},options,'').then((data) => {     
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('calling user list endpoint',Endpoints.user);         }
          if (data.status === statCodes.SUCCESS) {
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('user redux thunk response :',data.data); }
            dispatch({type:userActions.GETUSER_ROLES_SUCCESS,payload:data.data});
          }else{
            dispatch({type:userActions.GETUSER_ROLES_HASERROR,payload:data});
          }
        }).catch((error) => {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}       
         if(!error.response){
          dispatch({type:userActions.GETUSER_ROLES_HASERROR,payload:error});            
         }else{
          dispatch({type:userActions.GETUSER_ROLES_HASERROR,payload:error.response});            
         }
          
        });
      }else{
        const user = AuthService.signinSilentToken();
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('signinSilentToken user :',user);}
        if(user){
          localStorage.setItem('id_token', user.id_token);        
          server.get(Endpoints.userRoles,{'integrationid':integrationID},options,'').then((data) => {     
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('calling user list endpoint',Endpoints.user);        }
            if (data.status === statCodes.SUCCESS) {
              if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('user redux thunk response :',data.data); }
              dispatch({type:userActions.GETUSER_ROLES_SUCCESS,payload:data.data});
            }else{
              dispatch({type:userActions.GETUSER_ROLES_HASERROR,payload:data});
            }
          }).catch((error) => {
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}        
            if(!error.response){
              dispatch({type:userActions.GETUSER_ROLES_HASERROR,payload:error});            
             }else{
              dispatch({type:userActions.GETUSER_ROLES_HASERROR,payload:error.response});            
             }
          });
        }else{
          AuthService.loginPage();
        }
      }     
    };
  };
  
    
