import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";
import './Runparams.css'
function CheckBox(props) {
  const { _label, _name,_type,onChange, ...rest } = props;
  if (_label) {
    return (<div className='checkbox-style'>
        <label htmlFor={_name} className="checkbox-group input-align"></label>
          <Field className='checkbox-text' id={_name} name={_name} type="checkbox" {...rest}/>
          {_label}
        <ErrorMessage name={_name} component={TextError} />
        </div>
    );
  }
  return (
    <div className='add-currency-popup-row'>
      <label htmlFor={_name} className="checkbox_label_wrapper" >{_label}</label>
        <Field id={_name} name={_name} onChange={(e)=>props.onChange(e,_type,_name,_label)} type="checkbox" {...rest}/>
      <ErrorMessage name={_name} component={TextError} />
      </div>
  );
}

export default React.memo(CheckBox);
