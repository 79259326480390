import React from "react";
import "./PrimaryButtonDefault.css";
import Frame8 from "../Frame8";
import CompleteIntegration from '../CompleteIntegration/index';
import ViewSummary from '../ViewSummary/index';

class PrimaryButtonDefault extends React.Component {
  constructor(){
    super()
    this.createIntegrationPopUp = this.createIntegrationPopUp.bind(this)
    this.state = {
      showModal: false
    }
   
  }
 // Child to Parent Using Callback
//   popupModelcallback = (data) => {
//     this.setState({showModal: data})
// integrationCallback = {this.popupModelcallback}
//   }
  createIntegrationPopUp(){
      this.setState({ showModal: true})
  }
  render() {
    const { children } = this.props;
    return (
      
      <div className="primary-button-default" style={{cursor: 'pointer', color: 'white'}}>
        <div className="button-i427563756 valign-text-middle" onClick={this.createIntegrationPopUp}>{children}</div>
        {this.state.showModal ? <CompleteIntegration val= {this.state.showModal}></CompleteIntegration>:null}
        </div>
    );
  }
}

export default PrimaryButtonDefault;
