import React from 'react';
import '../ViewRunLog/ViewRunLog.css'
import PropTypes from 'prop-types';
import AuthService from '../../../../src/components/server/AuthService';
import '../CommissionsRunManager/CommissionRunManager.css'
import  '../CommissionRunDetails/RetailProfit.css'
import { Oval } from 'react-loader-spinner';
import { connect } from 'react-redux';
import noreports from '../assets/norunreport.svg'
import closeIcon from '../assets/closeicon.svg';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withRouter } from 'react-router-dom';
import { getRetailProfit } from '../actions';
import * as Constant from '../../../constants/Constants';
import { allNullTypeCheck, isNullResponse } from '../commonComponents/UtilityFun';
import NewSnackbar from "../../utilities/NewSnackbar";
import actions from '../../../redux/actions/CommonDataActions';
import { numberWithCommas } from "../../utilities/Services";
import nodatafound from "../assets/nodatafound.svg";
import Moment from "moment";

let initialValue = 0.0;

const fields = [
    { name: 'ChequeNo', displayName: "Check No.", inputFilterable: true, sortable: true },
    { name: 'DistID', displayName: "Dist. ID", inputFilterable: false, exactFilterable: true, sortable: true },
    { name: 'Name', displayName: "Name", inputFilterable: false, exactFilterable: true, sortable: true },
    { name: 'CountryCode', displayName: "Country Code", inputFilterable: true, sortable: true },
    { name: 'ChequeDate', displayName: "Check Date", inputFilterable: false, exactFilterable: true, sortable: true },
    { name: 'DistType', displayName: "Disbursement Type", inputFilterable: false, exactFilterable: true, sortable: true },
    { name: 'EarningsAmount', displayName: "Earnings Amount", inputFilterable: false, exactFilterable: true, sortable: true },
    { name: 'Comments', displayName: "Comments", inputFilterable: false, exactFilterable: true, sortable: true },
    { name: 'PoolVol', displayName: "Pool Vol.", inputFilterable: false, exactFilterable: true, sortable: true },
    { name: 'PoolFunds', displayName: "Pool Funds", inputFilterable: false, exactFilterable: true, sortable: true },
];
class RetailProfit extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedValue: 'Primary',
            selectPeriodVal: '00/0000',
            selectedCommissionPeriodIndex: 0,
            commissionTypes: [],
            commissionRuns: [],
            selectedCommissionType: 'Primary',
            commissionPeriodData: null,
            commissionPeriods: [],
            dataObj: [],
            previousDisabled: false,
            nextDisabled: false,
            anchorEl: false,
            commissionRunState: [],
            selectedRunInfo: [],
            showLoaderResult: false,
            runsWaitTime:1
        }
        //  this.viewLogTableDisplay = this.viewLogTableDisplay.bind(this);
    }
    componentDidMount() {
        const { selectedCommissionRun, commissionTypeId, selectedRun, selectedPayoutName } = this.props;
        console.log("selected run :", selectedCommissionRun?.runId, 'commissionTypeId:', commissionTypeId, 'selectedPayoutName:', selectedPayoutName)
        if (selectedRun !== null && commissionTypeId !== null) {
            this.setState({showLoaderResult: true})
            this.props.getRetailProfitDetails(selectedRun, commissionTypeId);
        }
    }
    componentDidUpdate(nextProps) {
        const { retailProfitResposne, commissionSelectedRunDetaiils, retailProfitError , selectedPayoutName, commissionTypeId, selectedRun} = this.props;
        const { runsWaitTime } = this.state;
        console.log("retail response", retailProfitResposne, selectedPayoutName)
        if (retailProfitResposne !== nextProps.retailProfitResposne){
            this.setState({showLoaderResult: false });
         if(!retailProfitResposne.hasOwnProperty('status')){
            if(isNullResponse(retailProfitResposne.data) && retailProfitResposne.error===false) {
                this.setState({ commissionRunState: retailProfitResposne.data.earnings});
            } else if(Object.keys(retailProfitResposne?.data).length===0 && allNullTypeCheck(retailProfitResposne?.messages)) {
                if(retailProfitResposne?.messages?.length > 0 && retailProfitResposne?.messages[0]?.includes("Try again...")){
                    console.log("Try again block entered : ",retailProfitResposne?.data);
                    this.setState({showLoaderResult: true})
                    setTimeout(() => {
                        this.props.getRetailProfitDetails(selectedRun, commissionTypeId);
                    }, 1000 * runsWaitTime);
                    this.setState(prevState => {
                    return {runsWaitTime: prevState.runsWaitTime * 2}
                    })
                    }
            }
           else if(retailProfitError.error === true){
              this.setState({showLoaderResult: false });
                this.props.showSnackbar({snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: retailProfitError?.messages[0]?.code +' - '+retailProfitError?.messages[0]?.detail})
            }
        }else {
            if(retailProfitResposne.hasOwnProperty('status') && retailProfitResposne.status===202){
                this.setState({showLoaderResult: true})
                setTimeout(() => {
                    this.props.getRetailProfitDetails(selectedRun, commissionTypeId);
                }, 1000 * runsWaitTime);
                this.setState(prevState => {
                return {runsWaitTime: prevState.runsWaitTime * 2}
                })
           }
          }     
        }
        else if(retailProfitError !== nextProps.retailProfitError){
            this.setState({showLoaderResult: false });
            if(retailProfitError.data.error === true){
                this.props.showSnackbar({snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: retailProfitError?.data?.messages[0]})
               }
        }
        if (commissionSelectedRunDetaiils !== nextProps.commissionSelectedRunDetaiils){
            this.setState({showLoaderResult: false });
            if(isNullResponse(commissionSelectedRunDetaiils.data) && commissionSelectedRunDetaiils.error===false) {
                console.log("selected run info : ", commissionSelectedRunDetaiils);
                this.setState({ selectedRunInfo: commissionSelectedRunDetaiils.data});
            }
        }
    }
    totalEarningsdisplay= () => {
		const { commissionRunState} = this.state;
        let totalValue = 0
        commissionRunState.map((commissionrun) => (
            totalValue = totalValue+ commissionrun.earnings
        ))
		return ( <div className='retailprofit-earnings' style={{marginRight: '15px'}}>Total Earnings : <span style={{marginLeft: '35px'}}>{totalValue?.toFixed(2)}</span>
        </div>)
    }
    render() {
        const { retprofitbackfunc, periodType, commissionPeriod, retailProfitResposne } = this.props;
        let loader;
        const {showLoaderResult,commissionRunState} = this.state;
        if (AuthService.isAuthenticated()) {
        if (showLoaderResult) {
            loader = <div id="center" style={{ position: "fixed", bottom: "40%", left: "48%" }}>
                <Oval
                    type="Puff"
                    position="fixed"  secondaryColor="#E85557"
                    color="#E85557"
                    height={50}
                    width={50}
                    timeout={3000}
                />
            </div>
        }
            return (
                <div className="flex">
                    <div className="rowalign-center" style={{ height: '80px' }}>
                        <div className="col-8 h2title">
                            {this.props.selectedPayoutName}
                            {commissionRunState.length > 0 ?  <div className="retdetails">Records Read: <div style={{marginLeft: '5px', marginRight: '5px'}} className='retdetails1'> {retailProfitResposne?.data?.recordsRead} </div>  |  Record Processed: <div style={{marginLeft: '5px'}} className='retdetails1'>{retailProfitResposne?.data?.recordsProcessed} </div></div> : ''}
                        </div>
                        <div className="col-4 padding-right-0">
                            <div className="comm-type-period-display">
                                <div>
                                    <div className="runmanager-alignitems-left cm-type-title">
                                        <span className="alignitems-left cm-type-title">Period Type</span>
                                        <div className="alignitems-left title-styles">
                                            {periodType === '' || periodType === undefined ? 'PRIMARY' : periodType}
                                            {/* {"Primary"} */}
                                        </div>
                                    </div>
                                </div>
                                <div className="commission-period-margin">
                                    <div className="runmanager-alignitems-left cm-type-title">
                                        <span className="alignitems-left cm-type-title">Commission Period</span>
                                        <div className="alignitems-left title-styles">
                                            {commissionPeriod}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img className="updatelg-close-icon" onClick={retprofitbackfunc} src={closeIcon} alt='' style={{ cursor: 'pointer' }} />
                    <div className="col-12 cm-table-style" >
                        <TableContainer component={Paper} className = 'retail-height'>
                            <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table" className='general-table-left'>
                                <TableHead  className='retailpf-table-header2'>
                                    {fields.map((row) => (
                                        <TableCell className='retailpf-table-header2' component="th" scope="row">
                                            {row.displayName}
                                        </TableCell>
                                    ))}
                                </TableHead>
                                <TableBody>
                                    {commissionRunState.length > 0 ? commissionRunState.map((commissionrun) => (
                                        <TableRow key={commissionrun.name}>
                                            <TableCell className='data-text-style' align= "right">{commissionrun?.checkNumber}</TableCell>
                                            <TableCell className='data-text-style' align= "right">{commissionrun?.distributorId}</TableCell>
                                            <TableCell className='data-text-style' align= "right">{commissionrun?.name}</TableCell>
                                            <TableCell className='data-text-style' align= "right">{commissionrun?.countryCode}</TableCell>
                                            <TableCell className='data-text-style' align= "right">{Moment(commissionrun?.checkDate).format('MM/DD/YYYY') !== 'Invalid date' ? Moment(commissionrun?.checkDate).format('MM/DD/YYYY'): ''}</TableCell>
                                            <TableCell className='data-text-style' align= "right">{commissionrun?.disbursementType}</TableCell>
                                            <TableCell className='data-text-style' align= "right">{numberWithCommas(commissionrun?.earnings?.toFixed(2))}</TableCell>
                                            <TableCell className='data-text-style' align= "right">{commissionrun?.comments}</TableCell>
                                            <TableCell className='data-text-style' align= "right">{numberWithCommas(commissionrun?.poolVolume?.toFixed(2))}</TableCell>
                                            <TableCell className='data-text-style' align= "right">{numberWithCommas(commissionrun?.poolFunds?.toFixed(2))}</TableCell>
                                        </TableRow>
                                    )) :
                                        commissionRunState?.length === 0 ? (<TableRow className="no-data-image1">
                                            <TableCell colSpan={9} align='center' className="no-data-image">
                                                <img src={nodatafound} alt="" />
                                            </TableCell>
                                        </TableRow>) : ''}
                                    {commissionRunState.length > 0 ? 
                                        <TableRow >
                                            <TableCell className='runmanager-name'></TableCell>
                                            <TableCell className='retailprofit-earnings' colSpan={5} align='right'>  Total Earnings:</TableCell>                                            
                                            <TableCell  align='right' className='runmanager-data-lastRun'>{numberWithCommas(commissionRunState.reduce((previousValue, currentValue) =>
                                                previousValue + currentValue.earnings,
                                                initialValue).toFixed(2))}</TableCell>
                                            <TableCell className='runmanager-name' colSpan={2}></TableCell>
                                        </TableRow>
                                    :
                                        ''
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {loader}
                        <NewSnackbar/>
                    </div>
                </div>
            );
        }
    }
}

RetailProfit.propTypes = {
    retprofitbackfunc: PropTypes.func
};
const mapStateToProps = (state) => {
    return {
        selectedCommissionRun: state.commissionRunReducer.selectedCommissionRun,
        commissionSelectedRunDetaiils: state.commissionRunReducer.commissionSelectedRunDetaiils,
        retailProfitResposne: state.commissionRunReducer.retailProfitResposne,
        retailProfitError: state.commissionRunReducer.retailProfitError,
    }
}

const mapDispatchToProps = (dispatch) => ({
    getRetailProfitDetails: (selectedRunID, commTypeId) => dispatch(getRetailProfit(selectedRunID, commTypeId)),
    showSnackbar: (params) => dispatch({ type: actions.SNACKBAR_SHOW, payload: params}),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RetailProfit));