import React from "react";
import "./AllIntegrations.css";
import { Card, CardText} from "reactstrap";
import styled from 'styled-components';
import * as Constants from '../../../constants/Constants';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import actions from '../../../redux/actions/CommonDataActions';
import { getIntegrationsList } from '../../../redux/thunk/integrationAPIThunk'
import { getCollaboratorsByIntegrationID} from '../../../redux/thunk/userManagementAPIThunk';
import { errorHandling } from '../../../components/utilities/Services'
import { Oval } from 'react-loader-spinner';
import AuthService from '../../server/AuthService';
import vector13  from '../../../assets/vector-156@2x.png';
import vector14 from '../../../assets/vector-157@2x.png';
import line42 from '../../../assets/line-42@1x.png';
import line43 from '../../../assets/line-43@2x.png';
import actions1 from '../../../redux/actions/IntegrationPopUp';
import ViewSummary from '../ViewSummary/index'
import "../IntegrationManager/IntegrationManager.css";
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import NewSnackbar from "../../utilities/NewSnackbar";
import { integration } from '../../../redux/actions/integrationDataActions'

class AllIntegrations extends React.Component {
  constructor(props) {
    super(props)
    this.showCreateIntegration = this.showCreateIntegration.bind(this)
     this.handleComponentBack = this.handleComponentBack.bind(this); 
    this.state = {
      showCreateIntegrationPopUp: false,
      AppsList: [],
      TempAppsList: [],
      ActiveTempList: [],
      DeactiveTempList: [],
      selectTitle: '188px',
      setTitleWidth: '63px',
      setColor:'#d93030', //#555555
      isActiveSelected: true,
      isAllSelected: false,
      isDeactiveSelect: false,
      showSummaryPage: false,
      showIntegrations: true,
      results: '',
      IntegrationsID: '',
      options : [],
      integrationCollaborators: [],
      tempIntegrations: [],
      selectID: ''
    }
    this.selectSummary= PropTypes.object;
    this.wrapperRef = React.createRef();    
    this.location = Object;
    this.history = Object;    
  }
  componentDidMount() {
    this.props.showLoader();
    if (AuthService.isTokenValidCheck() === true) {
      //this.setState({ TempAppsList: this.state.AppsList });
      this.props.getIntegrations();
    }
  }
  componentDidUpdate(nextProps) {
    if(this.props.isIntegrationLandingModel !== nextProps.isIntegrationLandingModel){
      this.setState({ showCreateIntegrationPopUp: this.props.isIntegrationLandingModel})
    }
    if(this.props.isIntegrationConfirmModel !== nextProps.isIntegrationConfirmModel){
      if(this.props.isViewIntegrationsShowSummary === true){
        this.props.hideTopIntegrationOption()
        this.setState({showIntegrations: false, showSummaryPage: this.props.isViewIntegrationsShowSummary, IntegrationsID: this.props.currentIntegrationID})
      }
      else{
        this.setState({showIntegrations: true, showSummaryPage: this.props.isViewIntegrationsShowSummary})
      }
    }
    
    if (this.props.integrationList !== nextProps.integrationList) {
     // this.setState({  ActiveTempList: [], DeactiveTempList: [] })
      if ((this.props.integrationList.error===false) && (this.props.integrationList.data.length > 0)) {
        console.log('integrations list',this.props.integrationList.data)
        let tempArray = []
        this.props.integrationList.data.forEach(element => {
           element.newCollaborators = []
          if(element.active===1 ){
            if( this.state.ActiveTempList.indexOf(element) === -1){
              this.state.ActiveTempList.push(element);
            }
          }else{
            if(this.state.DeactiveTempList.indexOf(element) === -1){
            this.state.DeactiveTempList.push(element);           
            }
          }
          tempArray.push(element)           
           this.setState({ AppsList: tempArray})
           this.setState({TempAppsList: this.state.ActiveTempList, selectTitle: "270px", setTitleWidth: "69px", })        
          this.props.getCollaboratorsList(element.integrationID)
        });    
        this.props.hideLoader();  
      } else if(this.props.integrationList.error) {
        this.props.showSnackbar({snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.warning, snackbarMessage: Constants.ALERT_TECHNICAL_ERROR})       
      }   
      
    } else if (this.props.integrationError !== nextProps.integrationError) {
      this.props.hideLoader();
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log("integrationDetails", this.props.integrationError);}
      errorHandling(this.props.integrationError); 
    }
    if (this.props.getCollaboratorsDetails !== nextProps.getCollaboratorsDetails) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("getCollaboratorsDetails ", this.props.getCollaboratorsDetails) }
      if (this.props.getCollaboratorsDetails.error === false) {
        if (this.props.getCollaboratorsDetails.data &&  this.props.getCollaboratorsDetails.data.length > 0 && this.state.showSummaryPage === false) {
          this.state.AppsList.forEach((item) => {
            if(item.integrationID === this.props.getCollaboratorsDetails.data[0].integrationID){
              item.newCollaborators = this.props.getCollaboratorsDetails.data
             // console.log('newitems', item.newCollaborators)
            }
          })
          this.setState({ AppsList: this.state.AppsList})
        }
      }
    } else if ((this.props.getCollaboratorsHasError !== nextProps.getCollaboratorsHasError)) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("getCollaboratorsDetails error ", this.props.getCollaboratorsHasError) }
      errorHandling(this.props.getCollaboratorsHasError);
    }
  }
  nameProfileText = (str) => {
    let initials = ''
    if(str.split(" ").length > 1){
      const fullName = str.split(' ');
      initials =  fullName.shift().charAt(0) + fullName.pop().charAt(0);
    }
    else{
      const fullName = str.split(' ');
      initials =  fullName.shift().charAt(0)
    }
    return initials.toUpperCase();
  }
  showCreateIntegration() {
   // this.setState({ showCreateIntegrationPopUp: true })
    this.props.showCreateIntegrationPopup() 
  }
  editIntegration(integration) {
    this.props.hideTopIntegrationOption()
    this.props.showViewIntegrationsShowSummary()
   // console.log('integration', integration.integrationID)  
    this.setState({showSummaryPage: true, showIntegrations: false, IntegrationsID: integration.integrationID})
    this.props.updateCurrentIntegrationName(integration.name)
  //  console.log('showSummaryPage', this.state.showSummaryPage, this.state.showIntegrations)
  }
  activeIntegrations(){
    this.setState({TempAppsList: this.state.ActiveTempList, selectTitle: "270px", setTitleWidth: "69px", }) 
    this.setState({isAllSelected: false, isActiveSelected: true, isDeactiveSelect: false})
  }
  deActiveIntegrations(){
    this.setState({TempAppsList: this.state.DeactiveTempList, selectTitle: "374px", setTitleWidth: "112px"})
    this.setState({isAllSelected: false, isActiveSelected: false, isDeactiveSelect: true})
  }
  allIntegrations(){
    this.setState({TempAppsList: this.state.AppsList, selectTitle: "188px", setTitleWidth: "63px"})
    this.setState({isAllSelected: true, isActiveSelected: false, isDeactiveSelect: false})
  }
  handleComponentClick(name,params){
    switch (name) {
      case Constants.TEXT_SUMMARY:        
        break;  
        default:
        break;
    }
  }
  handleComponentBack(name,params){
   // console.log('name:',name,'params:',params)
    this.props.showTopIntegrationOption()
    this.setState({showIntegrations:true,integrationCollaborators: [], showSummaryPage: false, ActiveTempList: [], DeactiveTempList: [], TempAppsList:[], AppsList: [], isAllSelected: false, selectTitle: "271px",setTitleWidth: "80px", isDeactiveSelect: false, isActiveSelected: true});        
    if((name===Constants.TEXT_SUMMARY)) {
    this.props.showLoader()
    this.props.getIntegrations()
    this.props.hideViewIntegrationsSummary()
    }
  }
  render() {
    let loader = ''    
    if (this.props.isFetchingData) {
      loader =  <div id="center" style={{ position: "fixed", top: "40%", left: "48%" }}>
          <Oval
            type="Puff"
            position="fixed"  secondaryColor="#E85557"
            color="#E85557"
            height={50}
            width={50}
            timeout={30000}/>
        </div>
    }
    return (
      <div>
        {this.state.showIntegrations && this.state.AppsList.length > 0 ?  <div>
        <Line42 src= {line42}/>
        <Line43 src={line43} style={{left: this.state.selectTitle, width: this.state.setTitleWidth, cursor: 'pointer'}}/>
        <div>
        <div className="deactivated-2 valign-text-middle lato-normal-fuscous-gray-14px" onClick={this.deActiveIntegrations.bind(this)}>
          {this.state.isDeactiveSelect ? <span style={{color: "#d93030", fontWeight:"700" , cursor: 'pointer'}}>  Deactivated ({this.state.DeactiveTempList.length}) </span> : 
          <span style={{color: "#555555",  fontWeight:"400", cursor: 'pointer'}}>  Deactivated ({this.state.DeactiveTempList.length}) </span>}
        
    </div>
        <div className="all-8 valign-text-middle lato-bold-persian-red-14px" onClick={this.allIntegrations.bind(this)}>
        {this.state.isAllSelected ? <span style={{color: "#d93030", fontWeight:"700", cursor: 'pointer'}}>  All ({this.state.AppsList.length}) </span> : 
          <span style={{color: "#555555",  fontWeight:"400", cursor: 'pointer'}}>  All ({this.state.AppsList.length}) </span>}
          
    </div>
        <div className="active-5 valign-text-middle lato-normal-fuscous-gray-14px" onClick={this.activeIntegrations.bind(this)}>
        {this.state.isActiveSelected ? <span style={{color: "#d93030", fontWeight:"700", cursor: 'pointer'}}>   Active ({this.state.ActiveTempList.length}) </span> : 
          <span style={{color: "#555555",  fontWeight:"400", cursor: 'pointer'}}>  Active ({this.state.ActiveTempList.length}) </span>}
    </div>
    </div>
        <div className="wdth row">
          {/* <div className="overlap-group-al column" style={{ cursor: 'pointer'}}>
            <Card className="p-2 sectionBorder sectionBackground mt-5" onClick={this.showCreateIntegration} >
              <div className="text-1-ai paragraph-1--14pt-regular-lato ">
                Create New Integration
            </div>
              <div>               
                <img className="vector-13" src={vector13} alt='' />
                <img className="vector-14" src={vector14} alt= '' />
                <div className="ellipse-28 border-1px-persian-red"></div>
              </div>
            </Card>
          </div> */}
          {this.state.TempAppsList.length > 0 ?
                this.state.TempAppsList.map((integration, key) => {
                  let createdDate = integration.createdDate
                  let date = createdDate.split(" ")
                  let dateformat = date[0]+ " "+ date[1] + " '" + date[2].substring(2)
                  dateformat = dateformat.replace(",","")
           return (<div key={key} className="overlap-group-al column" style={{ cursor: 'pointer', opacity: integration.active ===1 ? 1 : 0.5 }}>
            <Card onClick={this.editIntegration.bind(this, integration)}
              className=" card p-2 sectionBorder sectionBackground mt-5"
              id="create_integration"  >
              <div className="integrationname lato-bold-mine-shaft-14px">
              {integration.name}
            </div>
              <CardText className="text-11 lato-normal-granite-gray-13px">
                {integration.description}
            </CardText>
              <div className="date-1 lato-normal-granite-gray-13px">
                {dateformat}  
            </div>           
              { 
                integration.newCollaborators !== undefined ?
                <div className="group-2728" style={{marginLeft: '15px'}}>
                <AvatarGroup max={3}>
                { integration.newCollaborators.map((item) => {
                  let name = ''
                  if(item !== null && item !== ''){
                    name = this.nameProfileText(item.name)
                  }
                  // return <Avatar alt={name} src="/static/images/avatar/1.jpg" />
                  return <Avatar src="/static/images/avatar/1.jpg">{name} </Avatar>
                })}
              </AvatarGroup>
              </div>
                 : ''}
            </Card>
            </div>)
            }): ''}        
        </div>
      </div>  : null}
       {this.state.showSummaryPage? <ViewSummary func={this.handleComponentClick} params={this.state.IntegrationsID} backfunc={this.handleComponentBack}/> : null}
       <NewSnackbar/>
       {this.state.showSummaryPage? '' : loader }
      </div>
    );
  }
}
AllIntegrations.propTypes = {
 // history: PropTypes.object,
 // location: PropTypes.object,
  pagetype: PropTypes.string,
  params: PropTypes.any,
  backfunc: PropTypes.func,
  options : PropTypes.any
};
const mapStateToProps = state => {
  return {
    isFetchingData: state.commonDataReducer.isFetchingData,
    integrationList: state.integrationDataReducer.integrationList,
    integrationError: state.integrationDataReducer.integrationError,
    collaboratorsDetails: state.userDataReducer.collaboratorsDetails,
    collaboratorsHasError: state.userDataReducer.collaboratorsHasError,
    isIntegrationLandingModel: state.integrationPopUpReducers.isIntegrationLandingModel,
    isShowTopIntegrationOption : state.integrationPopUpReducers.isShowTopIntegrationOption,
    currentIntegrationID : state.integrationDataReducer.currentIntegrationID, 
    isViewIntegrationsShowSummary : state.integrationPopUpReducers.isViewIntegrationsShowSummary,
    isIntegrationConfirmModel : state.integrationPopUpReducers.isIntegrationConfirmModel,
    getCollaboratorsDetails: state.userDataReducer.getCollaboratorsDetails,
    getCollaboratorsHasError: state.userDataReducer.getCollaboratorsHasError,
  }
};
const mapDispatchToProps = dispatch => ({
  showLoader: () => dispatch({ type: actions.FETCHING_DATA_LOADER, payload: true }),
  hideLoader: () => dispatch({ type: actions.HIDE_DATA_LOADER, payload: false }),
  showCreateIntegrationPopup: () => dispatch({ type: actions1.SHOW_CREATE_INTEGRATIONS, payload: true }),
  hideCreateIntegrationPopup: () => dispatch({ type: actions1.HIDE_CREATE_INTEGRATIONS, payload: false }),
  getIntegrations: () => dispatch(getIntegrationsList()),
  showTopIntegrationOption: () => dispatch({ type: actions1.SHOW_TOP_INTEGRATIONS, payload: true }),
  hideTopIntegrationOption: () => dispatch({ type: actions1.HIDE_TOP_INTEGRATIONS, payload: false }),
  hideViewIntegrationsSummary: () => dispatch({ type: actions1.HIDE_COMPLETE_SUMMARY_INTEGRATIONS, payload: false }),
  showViewIntegrationsShowSummary: () => dispatch({ type: actions1.SHOW_COMPLETE_SUMMARY_INTEGRATIONS, payload: false }), 
 // getCollaboratorsList: () => dispatch(getCollaboratorsListapi()),
  getCollaboratorsList: (integrationID) => dispatch(getCollaboratorsByIntegrationID(integrationID)), 
  updateCurrentIntegrationName:(integrationName)=>dispatch({type: integration.CURRENT_INTEGRATION_NAME,payload:integrationName})
});

export default connect(mapStateToProps, mapDispatchToProps)(AllIntegrations);

function Group2728(props) {
  const { image3, group2185Props, group2629Props, className } = props;

  return (
    <div className={`group-2728 ${className || ""}`}>
      <div className="overlap-group3-1">
        <Group2185>{group2185Props.children}</Group2185>
        <Group2629>{group2629Props.children}</Group2629>
        <img className="image-3" src={image3} alt=''/>
      </div>
    </div>
  );
}

function Group2185(props) {
  const { children, className } = props;

  return (
    <div className={`group-2185-1 ${className || ""}`}>
      <div className="overlap-group1-2 border-2px-curious-blue">
        <div className="text-12 lato-medium-curious-blue-14px">{children}</div>
      </div>
    </div>
  );
}

function Group2629(props) {
  const { children } = props;

  return (
    <div className="group-2629-1">
      <div className="overlap-group2-2 border-1px-white">
        <div className="st lato-medium-white-14px">{children}</div>
      </div>
    </div>
  );
}

const Line42 = styled.img`
  height: 2px;
  left: 188px;
  position: absolute;
  top: 196px;
  width: 990px;
`;
// left: 188px;
// width: 63px;
const Line43 = styled.img`
  height: 2px;
  position: absolute;
  top: 196px;
`;

const group2185Data = {
  children: "+3",
};

const group2629Data = {
  children: "ST",
};

const group2728Data = {
  group2185Props: group2185Data,
  group2629Props: group2629Data,
};

// const component1Data = {
//   text19: "My Integrations",
//   line42: "https://anima-uploads.s3.amazonaws.com/projects/607ff5beb95afd36a6b5e6c3/releases/60814525f4556b65c033cbd7/img/line-42@1x.png",
// };

// const group21852Data = {
//   children: "+3",
// };

// const group26292Data = {
//   children: "ST",
// };

// const group27282Data = {
//   group2185Props: group21852Data,
//   group2629Props: group26292Data,
// };

// const group21853Data = {
//   children: "+3",
// };

// const group26293Data = {
//   children: "ST",
// };

// const group2730Data = {
//   group2185Props: group21853Data,
//   group2629Props: group26293Data,
// };
