import React, { Suspense, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
// import ReportsNav from './ReportsNav/ReportsNav'
import reportsImg from '../../assets/reportsImg.svg'
import LoaderComponent from '../../Settings/LoaderComponent'
import "../../Settings/settings-dashboard.css"
import backarrow from "../../../../assets/backarrow.svg";
import printer from "../../assets/printer.svg";
import exportimg from "../../assets/exportimg.svg";
import threedots from "../../assets/three-dots.svg";
import { useSelector } from 'react-redux'
import NewSnackBar from "../../../utilities/NewSnackbar"
import { useDispatch } from 'react-redux';
// import ReportsMain from './ReportsMain'
import  CommRankAdvancement from './CommRankAdvancement'
import { RunMainContainer }  from './RunMainContainer'
import Component2 from '../../Component2'
import * as Constants from '../constants';
import { useLocation } from "react-router-dom";
import { REPORT_PARAMS } from '../actions'
import Endpoints from '../../../utilities/Endpoints'
import actions from '../../../../redux/actions/CommonDataActions'
import * as Constant from '../../../../constants/Constants'
import { getCleintNameEnv } from '../../utilities/services'

function NewTabNavContainer({ heading, reportsParams }) {
  const utilData = useSelector(state => state.loadingStateReducer)
  const paramsData = useSelector(state => state.reportsMainReducer.reportParamRes)
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [reportsPam, setReportsParams] = useState([]);
  const [paramRep, setParamRep] = useState([])

  console.log("response are : ",reportsParams,paramsData);
  const handleBackAction = () => {
    console.log("back handling :");
    history.push({
      pathname: '/reports',
      state: {
        heading: location.state.heading,
      }
    });
  }
  useEffect(()=>{
    // if(displayType==='External'){
    //   getSelectedReportParameters(localStorage.getItem("category"),localStorage.getItem("heading"))
      console.log("session storage values :",localStorage.getItem("category"),localStorage.getItem("heading"))
      fetch(`${getCleintNameEnv()}reports/${encodeURI(localStorage.getItem("category"))}:${encodeURI(localStorage.getItem("heading"))}/parameters`)
      .then((response) => response.json())
      .then((res) => {
        if (res) {
          // setloader(false);
          setReportsParams(res.data)
          // dispatch({ type: REPORT_PARAMS, payload: res.data })
        }
      }).catch((e) => {
        console.log("Getting service error: ", e);
        //  setloader(false);
      })
    // }
  },[])
  useEffect(() => {
    //  dispatch(getreportsdata())
    console.log("report parameters :", reportsParams);
    dispatch({ type: REPORT_PARAMS, payload: reportsParams })
  }, [])
  function Print() {
    //console.log('print');  
    //let printContents = document.getElementById('prints').innerHTML;
    var printContent = document.getElementById('print');
    var WinPrint = window.open('', '', 'width=900,height=650');
    WinPrint.document.write(printContent.innerHTML);
    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
    WinPrint.close();
  }
  const downloadCSV = () => {
    console.log("download csv file:");
    fetch(`${getCleintNameEnv()}reports/${encodeURI(localStorage.getItem("category"))}:${encodeURI(localStorage.getItem("heading"))}/csv`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(paramsData),
    }).then((response) => response.text())
      .then((res) => {
        //console.log("Parameters", res);
        console.log('CSV data', res);
        if (typeof (res) === 'string') {
          downloadReportCSV(res, `${localStorage.getItem("heading")}-report.csv`);
        } else {
          console.log("error response : ", res, '---', typeof (res))
          if (typeof (res) === 'string') {
            let resp = JSON.parse(res);
            dispatch({ type: actions.SNACKBAR_SHOW, payload: { snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: (typeof (resp) !== 'string' && resp.hasOwnProperty('messages')) ? resp.messages[0] : resp } })
          }
        }
      }).catch((e) => {
        console.log("Getting service error: ", e);
        dispatch({ type: actions.SNACKBAR_SHOW, payload: { snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: (typeof (e) !== 'string' && typeof (e) === 'object' && e.hasOwnProperty('message')) ? e.message : e } })
      })
  }
  function downloadReportCSV(csv, filename) {
    var csvFile;
    var downloadLink;

    csvFile = new Blob([csv], { type: "text/csv" });
    downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }
  const { reportsId } = useParams()
  const display = utilData.loading ? 'none' : 'block'
  useEffect(() => {
    // dispatch(getreportsdata())
  }, [reportsId])

  // const handleFilterResponse=(reportParams)=>{
  //   console.log("res params : ",reportParams)
  //     setParamRep(reportParams);
  // }
  useEffect(()=>{
    console.log("res params : ",paramRep)
    setReportsParams(paramRep)
  },[paramRep])


  return (
    <>
      <NewSnackBar />
      <div className="row" style={{ display: 'flex', 'alignItems': 'center', flexDirection: 'column' }}>
        <div className="col-12" style={{ maxWidth: '2000px' }}>
          {/* <Component2 mainText="Reports"  typeText={"RunDetails"}
                    detailsText={heading} /> */}
          <div className="flex" style={{ marginLeft: '15px', marginRight: '15px' }}>
            <div className='report-main-content-header'>
              <div className="col-8 cmt-title">
                {/* <img
                              src={backarrow}
                              style={{
                                cursor: "pointer",
                                height: "17px",
                                width: "17px",
                                marginTop: "7px",
                              }}
                              alt=""
                              //onClick={handleBackAction}
                            /> */}
                <span>{localStorage.getItem("heading")}</span>
              </div>
              <div>
                <img
                  src={printer}
                  style={{
                    cursor: "pointer",
                    height: "17px",
                    width: "17px",
                    marginTop: "7px",
                  }}
                  alt=""
                  onClick={Print}
                />
                <img
                  src={exportimg}
                  style={{
                    marginLeft: '10px',
                    cursor: "pointer",
                    height: "17px",
                    width: "17px",
                    marginTop: "7px",
                  }}
                  alt=""
                  onClick={downloadCSV}
                />
                <img
                      src={threedots}
                      style={{
                        marginLeft:'10px',
                        cursor: "pointer",
                        height: "17px",
                        width: "17px",
                        marginTop: "7px",
                      }}
                      alt=""
                      // onClick={handleBackAction}
                    />
              </div>
            </div>
            <div className="row">
              <div className="col-12 flex-container settings-container" >
                <Suspense fallback={<LoaderComponent />}>
                  <div className='reports-sidenav'>
                    <CommRankAdvancement details={Constants.REPORTS_LEFTNAV} heading={Constants.REPORT_LEFTNAV_TITLE} reportsPams={reportsPam} displayType={"External"} handleFilter={setParamRep}/>
                  </div>
                  <div className='reports-main' style={{ alignItems: "baseline" }}>
                    {utilData.loading && <LoaderComponent />}
                    {/* { reportsId && links[reportsId] ? <ReportsDashboard loading={utilData.loading} heading={links[reportsId].text} Component={links[reportsId].component} /> : <img src={reportsImg} alt="reports" />} */}
                    <RunMainContainer displayType={"External"} reportsParams={reportsParams} reportsPams={reportsPam}/>
                  </div>
                </Suspense>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const ReportsDashboard = ({ heading, Component }) => <Component heading={heading} />




export default NewTabNavContainer
