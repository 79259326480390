import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Oval } from "react-loader-spinner";
import { Modal } from "react-bootstrap";
import vector13 from '../../../assets/vector-13@2x.png';
import {
  getCommissionRunAPIList, COMMISSION_SELECTED_RUN,
  getCommissionTypeListAPI, getCommissionPeriodListAPI, DIST_RESEARCH_SELECTEDOPTIONS
} from "../actions";
import Dropdown from "react-bootstrap/Dropdown";
import leftarrow from "../assets/leftarrow.svg";
import rightarrow from "../assets/rightarrow.svg";
import './DistResearchModel.css'
import { withRouter } from 'react-router-dom';
import NewSnackbar from "../../utilities/NewSnackbar";
import * as Constants from '../../../constants/Constants';
import { allNullTypeCheck, isNullResponse } from "../commonComponents/UtilityFun";
import { getMonthDays } from '../../utilities/Services'
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import gotoarrow from "../assets/gotoarrow.svg";
import actions from '../../../redux/actions/CommonDataActions';
import Moment from 'moment';

const styles = theme => ({
  tableRow: {
    '&&:hover': {
      backgroundColor: '#0CB5F3',
    },
  },
});
const fields = [
  { name: "Type", displayName: "Type", inputFilterable: true, sortable: true },
  {
    name: "name",
    displayName: "Name",
    inputFilterable: false,
    exactFilterable: true,
    sortable: true,
  },
  {
    name: "createdOn",
    displayName: "Created On",
    inputFilterable: false,
    exactFilterable: true,
    sortable: true,
  },
  {
    name: "programs",
    displayName: "Programs",
    inputFilterable: false,
    sortable: true,
  },

];

class DistResearchModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoaderResult: true,
      showRemoveColModal: true,
      hideModal: false,
      createdRun: null,
      currentRunDetails: "",
      showRunDetails: false,
      disableNewRun: true,
      commissionRunState: [],
      commddionRunDataObj: [],
      commissionPeriodData: null,
      commissionPeriods: [],
      selectedCommissionPeriodIndex: 0,
      commissionTypes: [],
      selectedCommissionRun: "",
      selectedCommissionType: "",
      selectedRun: "Select Run",
      distIDValue: "",
      selectedCompletRun: "",
      enableAction: true,
      totalRuns: [],
      displayPerioPop: false,
      anchorEl: false,
      periodValue: "",
      selectedPeriod:'',
      retryDelayTime1:1,
      retryDelayTime:1,
      retryDelayTime2:1,
    };
  }

  componentDidMount() {
    const { periodtype, commissiontype } = this.props;
    this.props.getCommissionTypes();
    this.props.getCommissionRunList();
    console.log("selected values :",periodtype,commissiontype);
    if (allNullTypeCheck(periodtype) && allNullTypeCheck(commissiontype)) {
      this.setState(
        {
          selectedCommissionType: {
            title: this.capitalizeFirstLetter(periodtype.toString()),
            defaultPeriod: commissiontype,
          },
        },
        () => {}
      );
    }
  }
  componentDidUpdate(nextProps) {
    const {
      commissionRunList,
      commissionTypeList,
      commissionPeriodList,
      detailedRunId,
      periodtype,
      commissiontype,
      commissionPeriodError,
    } = this.props;
    const { selectedCommissionType, retryDelayTime, retryDelayTime2, retryDelayTime1, selectedPeriod } = this.state;
    if (commissionRunList !== nextProps.commissionRunList) {
      this.setState({ showLoaderResult: false });
      if(!commissionRunList.hasOwnProperty('status')){
      if (isNullResponse(commissionRunList.data)) {
        if (commissionRunList.error === false) {
          this.setState({ commissionRunState: [] });
          console.log(
            "run results : ",
            detailedRunId,
            commissionRunList.data?.runs
          );
          if (allNullTypeCheck(detailedRunId)) {
            let result = this.filterById(
              commissionRunList.data?.runs,
              detailedRunId?.runId
            );
            if (allNullTypeCheck(result)) {
              this.setState({
                selectedRun: result?.name,
                selectedCompletRun: result?.runId,
              });
            }
          } else {
            this.selectPeriodIndex(selectedCommissionType?.defaultPeriod);
          }
        } else {
          this.props.showSnackbar({
            snackbarOpen: true,
            snackbarType: Constants.SNACKBAR_TYPE.error,
            snackbarMessage: commissionRunList.messages[0],
          });
        }
      } else if(Object.keys(commissionRunList?.data).length===0 && allNullTypeCheck(commissionRunList?.messages)) {
        if(commissionRunList?.messages?.length > 0 && commissionRunList?.messages[0]?.includes("Try again...")){
            console.log("Try again block entered : ",commissionRunList?.data);
            setTimeout(() => {
              this.props.getCommissionRunList();
            }, 1000 * retryDelayTime);
            this.setState(prevState => {
              return {retryDelayTime: prevState.retryDelayTime * 2}
           })
        }
      }
    }else {
      if(commissionRunList.hasOwnProperty('status') && commissionRunList.status===202){ 
        setTimeout(() => {
          this.props.getCommissionRunList();
        }, 1000 * retryDelayTime);
        this.setState(prevState => {
          return {retryDelayTime: prevState.retryDelayTime * 2}
      })
      }
    }
    }
    if (commissionTypeList !== nextProps.commissionTypeList) {
      this.setState({ showLoaderResult: false });
      if(!commissionTypeList.hasOwnProperty('status')){ 
      if (isNullResponse(commissionTypeList.data) && commissionTypeList.error===false) {
        if(commissionTypeList?.data?.hasOwnProperty('periodTypes')){
        this.setState(
          { commissionTypes: commissionTypeList?.data?.periodTypes.filter(data=>data?.active===true) },
          () => {
            console.log(
              "commission Period success : ",
              commissionTypeList.data.periodTypes[0]?.title
            );
            if (
              allNullTypeCheck(periodtype) &&
              allNullTypeCheck(commissiontype)
            ) {
              if (
                commissionTypeList.data.periodTypes?.length > 0 &&
                allNullTypeCheck(commissionTypeList.data.periodTypes[0]?.title)
              ) {
                this.setState({selectedPeriod: periodtype.toString()});
                this.props.getCommissionPeriod(
                  this.capitalizeFirstLetter(periodtype.toString())
                );
              }
            } else {
              this.setState(
                {
                  selectedCommissionType: {
                    title: commissionTypeList?.data?.periodTypes[0]?.title,
                    defaultPeriod:
                      commissionTypeList?.data?.periodTypes[0]?.defaultPeriod,
                  },
                  selectedPeriod:commissionTypeList?.data?.periodTypes[0]?.title
                },
                () => {
                  this.props.getCommissionPeriod(
                    commissionTypeList?.data?.periodTypes[0]?.title
                  );
                }
              );
            }
          }
        );
       }
      } else if(Object.keys(commissionTypeList?.data).length===0 && allNullTypeCheck(commissionTypeList?.messages)) {
        if(commissionTypeList?.messages?.length > 0 && commissionTypeList?.messages[0]?.includes("Try again...")){
            console.log("Try again block entered : ",commissionTypeList?.data);
            setTimeout(() => {
              this.props.getCommissionTypes();
            }, 1000 * retryDelayTime2);
            this.setState(prevState => {
              return {retryDelayTime2: prevState.retryDelayTime2 * 2}
           })
        }
     }
    }else {
      if(commissionTypeList.hasOwnProperty('status') && commissionTypeList.status===202){ 
        this.setState({ showLoaderResult: true });
          setTimeout(() => {
            this.props.getCommissionTypes();
          }, 1000 * retryDelayTime2);
          this.setState(prevState => {
            return {retryDelayTime2: prevState.retryDelayTime2 * 2}
        })
      }
    }
    }
    if (commissionPeriodList !== nextProps.commissionPeriodList) {
      this.setState({ showLoaderResult: false });
      if(!commissionPeriodList.hasOwnProperty('status')){
        if (isNullResponse(commissionPeriodList.data) && commissionPeriodList?.error===false)
          {
          if(isNullResponse(commissionPeriodList?.data?.periods)){
          let commPeriods = null;
          commPeriods =
            commissionPeriodList?.data?.periods[
              this.state.selectedCommissionType?.title
                ?.toString()
                .toLocaleLowerCase()
            ];
          this.setState(
            {
              commissionPeriods: commPeriods,
            },
            () => {
              if (
                allNullTypeCheck(commPeriods) &&
                commPeriods?.length > 0 &&
                allNullTypeCheck(selectedCommissionType?.defaultPeriod)
              ) {
                this.selectPeriodIndex(selectedCommissionType?.defaultPeriod);
              } else {
                this.setState({ selectedCommissionPeriodIndex: 0 });
              }
            }
          );
          }
          else{
            this.setState({
              commissionPeriods: [],
            });
          }
        } else if(Object.keys(commissionPeriodList?.data).length===0 && allNullTypeCheck(commissionPeriodList?.messages)) {
          if(commissionPeriodList?.messages?.length > 0 && commissionPeriodList?.messages[0]?.includes("Try again...")){
              console.log("Try again block entered : ",commissionPeriodList?.data);
              setTimeout(() => {
                if (allNullTypeCheck(selectedPeriod)) {
                  this.props.getCommissionPeriod(selectedPeriod);
                }
              }, 1000 * retryDelayTime1);
              this.setState(prevState => {
                return {retryDelayTime1: prevState.retryDelayTime1 * 2}
            })
          }
        } else {
          console.log("Getting empty periods or error : ", commissionPeriodList);
          this.setState({
            commissionPeriods: [],
          });
        }
      } else {
        if(commissionPeriodList.hasOwnProperty('status') && commissionPeriodList.status===202){ 
          setTimeout(() => {
            if (allNullTypeCheck(selectedPeriod)) {
              this.props.getCommissionPeriod(selectedPeriod);
            }
          }, 1000 * retryDelayTime1);
          this.setState(prevState => {
            return {retryDelayTime1: prevState.retryDelayTime1 * 2}
        })
      }
    } 

    } else if (commissionPeriodError !== nextProps.commissionPeriodError) {
      console.log("Period list Error : ", commissionPeriodError);
    }
  }
  selectPeriodIndex(value) {
    const { totalRuns, commissionPeriods } = this.state;
    const { commissionRunList } = this.props;
    this.setState({ totalRuns: [], commissionRunState: [] }, () => {});
    console.log("commission periods : ", commissionPeriods);
    console.log("commission run list : ", commissionRunList);
    if (isNullResponse(commissionPeriods) && commissionPeriods.length > 0 && value !== '00/0000') {
      const indexValue = commissionPeriods.findIndex((data) => {
        return getMonthDays(data.name) === value;
      });
      console.log("selected index : ", indexValue, commissionRunList);
      if (allNullTypeCheck(indexValue) && indexValue !== -1) {
        this.setState({ selectedCommissionPeriodIndex: indexValue }, () => {});
        commissionRunList?.data?.runs?.filter((data) => {
          if (data.period === value && allNullTypeCheck(data?.lastRun)) {
            totalRuns.push(data);
          }
        });
        this.setState({ commissionRunState: [] }, () => {
          this.setState({ commissionRunState: totalRuns });
        });
      } else if (indexValue === -1) {
        this.setState({ selectedCommissionPeriodIndex: 0, commissionRunState: []}, () => {});
        // commissionRunList?.data?.runs?.filter((data) => {
        //   if (data.period === value && allNullTypeCheck(data?.lastRun)) {
        //     totalRuns.push(data);
        //   }
        // });
        // this.setState({ commissionRunState: [] }, () => {
        //   this.setState({ commissionRunState: totalRuns });
        // });
      }
    }else{
      this.setState({ commissionRunState: [], selectedCommissionPeriodIndex: 0 })
    }
  }
  updateSelectPeriod(index) {
    const { commissionPeriods } = this.state;
    if (allNullTypeCheck(commissionPeriods) && commissionPeriods.length > 0) {
      this.setState({ selectedRun: "Select Run", selectedCompletRun: "" });
      this.selectPeriodIndex(getMonthDays(commissionPeriods[index]?.name));
    }
  }
  handleClick = (event) => {
    if (allNullTypeCheck(event.currentTarget)) {
      this.setState({ anchorEl: event.currentTarget, displayPerioPop: true });
    }
  };
  handleClose = () => {
    this.setState({
      anchorEl: null,
      displayPerioPop: false,
    });
  };
  handlePeriodInput = (input) => {
    if (allNullTypeCheck(input.target)) {
      console.log("input is", input.target);
      const { value } = input.target;
      if (value) {
        this.setState({ periodValue: value.length === 3 && !value.includes("/")
        ? `${value.substring(0, 2)}/${value.substring(2)}`
        : value});
      } else {
        this.setState({ periodValue: "" });
      }
    }
  };
  onJumpSelectPeriod = () => {
    const { periodValue, commissionRunList } = this.state;
    this.setState({ anchorEl: false });
    if (periodValue) {
      this.selectPeriodIndex(periodValue);
    } else {
      this.setState({ periodValue: "" });
      this.setState({ commissionRunState: commissionRunList.data?.runs });
    }
  };
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  getNextPeriod() {
    this.setState((prevState, props) => ({
      selectedCommissionPeriodIndex:
        prevState.selectedCommissionPeriodIndex + 1,
    }));
    this.updateSelectPeriod(this.state.selectedCommissionPeriodIndex + 1);
  }
  getPreviousPeriod() {
    this.setState((prevState, props) => ({
      selectedCommissionPeriodIndex:
        prevState.selectedCommissionPeriodIndex - 1,
    }));
    this.updateSelectPeriod(this.state.selectedCommissionPeriodIndex + -1);
  }
  hideModal() {
    this.props.handleClose();
    this.setState({ hideModal: true, showRemoveColModal: false });
  }
  confirmModal() {
    const {
      distIDValue,
      selectedCommissionType,
      selectedRun,
      selectedCompletRun,
    } = this.state;
    this.setState({ showLoaderResult: false });
    this.hideModal();
    let selectedOpions = {
      distID: distIDValue,
      commissiontype: selectedCommissionType,
      commissionperiod:
        this.state.commissionPeriods.length > 0
          ? this.state.commissionPeriods[
              this.state.selectedCommissionPeriodIndex
            ].name
          : "",
      selectedRun: selectedCompletRun,
    };
    this.props.distResearchRunselection(selectedOpions);
    this.props.history.push({
      pathname: "/commissiontools/distributorresearch/1",
      state: {
        distID: distIDValue,
        periodtype: selectedCommissionType,
        periodDate:
          this.state.commissionPeriods.length > 0
            ? this.state.commissionPeriods[
                this.state.selectedCommissionPeriodIndex
              ].name
            : "",
        selectedRun: selectedCompletRun,
      },
    });
  }
  handleCommissionTypeChange = (value) => {
    console.log("Select comm type", value);
    this.setState({ selectedRun: "Select Run", selectedCompletRun: "" });
    const commissionObj = this.commissionsTypefindIndex(
      this.state.commissionTypes,
      value
    );
    console.log("rs: ", this.state.commissionTypes[commissionObj]);
    if (commissionObj !== -1) {
      this.setState({
        selectedCommissionType: this.state.commissionTypes[commissionObj],
        commissionRunState: [],
        selectedPeriod:value
      });
      this.props.getCommissionPeriod(value);
    } else {
      this.setState({ selectedPeriod:value });
      this.props.getCommissionPeriod(value);
    }
  };
  commissionsTypefindIndex = (commissiontypeList, value) => {
    return commissiontypeList.findIndex((periodType) => {
      return periodType.title === value;
    });
  };
  handleRunChange = (value) => {
    const { commissionRunState, selectedRun, distIDValue } = this.state;
    console.log("dropd values :", value);
    let result = this.filterById(commissionRunState, value);
    if (allNullTypeCheck(result)) {
      this.setState({
        selectedRun: result.name,
        selectedCompletRun: result.runId,
      });
      console.log(
        "selected result  : ",
        value !== "Select Run",
        distIDValue !== ""
      );
      if (value !== "Select Run" && distIDValue !== "") {
        this.setState({ enableAction: false });
      } else {
        this.setState({ enableAction: true });
      }
    }
  };
  filterById(jsonObject, id) {
    return jsonObject.filter(function (jsonObject) {
      return jsonObject["runId"] == id;
    })[0];
  }
  handleDistInput = (e) => {
    const { selectedRun, distIDValue } = this.state;
    this.setState({ distIDValue: e.target.value });
    console.log("selected id : ", distIDValue);
    console.log(
      "selected result  : ",
      selectedRun !== "Select Run",
      e.target.value !== ""
    );
    if (selectedRun !== "Select Run" && e.target.value !== "") {
      this.setState({ enableAction: false });
    } else {
      this.setState({ enableAction: true });
    }
  };
  truncate = (source, size) => {
    console.log("runs selected  : ", source, size);
    return source.toString().length > 12
      ? source.toString().slice(0, 10) + "…"
      : source.toString();
  };
  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.onJumpSelectPeriod();
    }
  };
  render() {
    let loader;
    const {
      showRemoveColModal,
      enableAction,
      showLoaderResult,
      displayPerioPop,
      selectedCommissionType,
      selectedRun,
      distIDValue,
      selectedCommissionPeriodIndex,
      anchorEl,
      commissionTypes,
      periodValue,
    } = this.state;
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    if (showLoaderResult) {
      loader = (
        <div
          id="center"
          style={{ position: "fixed", bottom: "60%", left: "48%" }}
        >
          <Oval
            type="Puff"
            position="fixed"
            secondaryColor="#E85557"
            color="#E85557"
            height={50}
            width={50}
            timeout={3000}
          />
        </div>
      );
    }
    return (
      <div>
        <Modal
          dialogClassName="dist-research-modal"
          show={showRemoveColModal}
          enforceFocus={false}
        >
          <Modal.Header>
            <div className="cmnr-flex-row-1-ei">
              <div className="add-cmnr-header-name cmnr-h3-header-3-18pt-bold-lato">
                Research
              </div>
              <img
                className="dist-vector-ei"
                src={vector13}
                onClick={this.hideModal.bind(this)}
                alt=""
              />
            </div>
          </Modal.Header>
          <Modal.Body style={{ height: "260px" }}>
            {!showLoaderResult ? (
              <div className="dist-research-container">
                <div className="dist-research-div-spacing col-11">
                  <div className="col-6 dist-element-group">
                    <label className="dist-research-model-lable col-10">
                      Distributor ID
                    </label>
                    <input
                      className="col-10 distres-id-options"
                      name="distid"
                      type="text"
                      placeholder="Enter Dist ID"
                      value={distIDValue}
                      autoComplete='off'
                      onChange={this.handleDistInput.bind(this)}
                    ></input>
                  </div>
                  {/* <Input type="text" class="form-control" id="nameInput" placeholder="Give a name to the run" style={{marginTop: '5px'}} value={this.state.runName} name="exampleFormControlInput1" onChange={this.oneditRunName.bind(this)}/> */}
                  <div className="col-6 dist-element-group">
                    <label className="dist-research-model-lable col-10">
                      Period Type
                    </label>
                    <div className="col-10 dist-research-inline-block">
                      <Dropdown
                        className="dist-research-model-dropdwon"
                        onSelect={this.handleCommissionTypeChange}
                        style={{
                          backgroundColor: "white",
                        }}
                      >
                        <Dropdown.Toggle
                          id="dropdown-custom-components"
                          title="Select Type"
                        >
                          {selectedCommissionType?.title}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dist-period-dropdown-menu">
                          {this.state.commissionTypes?.length > 0 &&
                            this.state.commissionTypes.map((item, i) => {
                              return (
                                <Dropdown.Item
                                  className="distperiod-primary-dropbtn"
                                  eventKey={item.title}
                                  key={i}
                                >
                                  {item.title}
                                </Dropdown.Item>
                              );
                            })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
                <div className="dist-research-div-spacing distres-period-container col-11">
                  <div className="col-6 dist-element-group">
                    <label
                      className="dist-research-model-lable col-10"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      Commission Period
                    </label>
                    <div className="col-10 dist-research-period">
                      <div
                        onClick={this.getPreviousPeriod.bind(this)}
                        disabled={
                          this.state.selectedCommissionPeriodIndex > 0
                            ? false
                            : true
                        }
                      >
                        <img
                          src={leftarrow}
                          alt=""
                          style={{ cursor: "pointer" }}
                        />{" "}
                      </div>
                      <span
                        aria-describedby={id}
                        name="periodvalues"
                        variant="contained"
                        onClick={this.handleClick}
                        style={{ marginBottom: "2px", cursor: "pointer" }}
                      >
                        {this.state.commissionPeriods?.length > 0
                          ? getMonthDays(
                              this.state.commissionPeriods[
                                this.state.selectedCommissionPeriodIndex
                              ]?.name
                            )
                          : "00/0000"}
                      </span>
                      <div
                        onClick={this.getNextPeriod.bind(this)}
                        disabled={
                          this.state.selectedCommissionPeriodIndex ===
                          this.state.commissionPeriods?.length - 1
                            ? true
                            : false
                        }
                      >
                        <img
                          src={rightarrow}
                          alt=""
                          style={{ cursor: "pointer" }}
                        />{" "}
                      </div>
                      {displayPerioPop ? (
                        <Popover
                          id={id}
                          open={open}
                          anchorEl={this.state.anchorEl}
                          onClose={this.handleClose.bind(this)}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                        >
                          <Typography sx={{ p: 2 }}>
                            {this.state.commissionPeriods?.length > 0 ? (
                              <div className="selectcpdetails">
                                <div className="cmperiod-select">
                                  Last Commission Period:{" "}
                                  {commissionTypes[0]?.lastCommissionPeriod}
                                </div>
                                <div className="cmperiod-select">
                                  Start Date:{" "}
                                    { Moment(this.state.commissionPeriods[
                                    selectedCommissionPeriodIndex
                                  ].start).format('MM/DD/YYYY')}
                                </div>
                                <div className="cmperiod-select">
                                  End Date:{" "}
                                  {
                                  Moment(this.state.commissionPeriods[
                                    selectedCommissionPeriodIndex
                                  ].end).format('MM/DD/YYYY')
                                 }
                                </div>
                                <div className="cmperiod-select">
                                  Default Check Date:{" "}
                                  {
                                   Moment(this.state.commissionPeriods[
                                    selectedCommissionPeriodIndex
                                  ].checkDate).format('MM/DD/YYYY')
                                  }{" "}
                                </div>
                                <div className="cmperiod-line"></div>
                                <div className="jumpto">
                                  Jump To:
                                  <input
                                    className="col-4 jumpperiod-options"
                                    name="periodvalue"
                                    type="text"
                                    placeholder="MM/YYYY"
                                    value={periodValue}
                                    maxLength={7}
                                    onChange={this.handlePeriodInput.bind(this)}
                                    onKeyPress={this.handleKeyPress}
                                  ></input>
                                  <img
                                    src={gotoarrow}
                                    style={{
                                      marginLeft: "8px",
                                      cursor: "pointer",
                                    }}
                                    alt=""
                                    onClick={this.onJumpSelectPeriod}
                                  />
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </Typography>
                        </Popover>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-6 dist-element-group">
                    <label className="dist-research-model-lable col-10">
                      Select Run
                    </label>
                    <div className="col-10 dist-research-inline-block">
                      <Dropdown
                        className="dist-research-model-selectrun dist"
                        onSelect={this.handleRunChange}
                        style={{
                          backgroundColor: "white",
                        }}
                        placeholder="Select Run"
                      >
                        <Dropdown.Toggle
                          id="dropdown-custom-components"
                          placeholder="Select Run"
                        >
                          <span
                            className={
                              selectedRun === "Select Run"
                                ? "distres-selectrun"
                                : ""
                            }
                          >
                            {" "}
                            {this.truncate(
                              selectedRun.toString(),
                              selectedRun.length
                            )}
                          </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dist-selectrun-dropdown-menu dist">
                          {/* {this.state.commissionTypes.map((item) => { */}
                          {this.state.commissionRunState.length > 0
                            ? this.state.commissionRunState.map(
                                (commissionrun, i) => {
                                  return (
                                    <Dropdown.Item
                                      className="selectrun-primary-dropbtn"
                                      eventKey={
                                        (commissionrun.name,
                                        commissionrun.runId)
                                      }
                                      key={i}
                                    >
                                      {commissionrun.name}
                                    </Dropdown.Item>
                                  );
                                }
                              )
                            : ""}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              loader
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="row">
              <div
                disabled={enableAction}
                className={
                  enableAction === true
                    ? "distres-model-disabled-runselection"
                    : "distres-model-runselection"
                }
                onClick={this.confirmModal.bind(this)}
              >
                <div
                  className={
                    enableAction === true
                      ? "distres-btn-disabled-title"
                      : "distres-btn-title"
                  }
                >
                  OK
                </div>
              </div>
            </div>
          </Modal.Footer>
          <NewSnackbar />

          {/* {loader} */}
        </Modal>
      </div>
    );
  }
}
DistResearchModel.propTypes = {
  defaultvalues: PropTypes.any,
  addCompareBackfunc: PropTypes.func,
};
const mapStateToProps = (state) => {
  return {
    commissionRunList: state.commissionRunReducer.commissionRunList,
    commissionTypeList: state.commissionToolsReducer.commissionTypeList,
    commissionPeriodList: state.commissionToolsReducer.commissionPeriodList,
    commissionPeriodError: state.commissionToolsReducer.commissionPeriodError,
  };
};
const mapDispatchToProps = dispatch => ({
  getCommissionRunList: () => dispatch(getCommissionRunAPIList()),
  getCommissionPeriod: (periodtype) => dispatch(getCommissionPeriodListAPI(periodtype)),
  selectedRunIndex: (selectedRun) =>
    dispatch({ type: COMMISSION_SELECTED_RUN, payload: selectedRun }),
  getCommissionTypes: () => dispatch(getCommissionTypeListAPI()),
  distResearchRunselection: (options) => dispatch({ type: DIST_RESEARCH_SELECTEDOPTIONS, payload: options }),
  showSnackbar: (params) => dispatch({ type: actions.SNACKBAR_SHOW, payload: params}),

})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DistResearchModel));