import React from "react";
import vectorsearch from "../../../../src/assets/vector-search.svg";
import "./PrimaryNavCE.css";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import reportvc from '../assets/layer2report.svg'
import commisisonlogo from '../assets/commissoinlogo.svg'
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { BROWSER_BACK_BUTTON_HANDLETAB } from '../actions'
import { gettokenScopeValidation } from "../../utilities/Services";

class CEPrimaryNav extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      value: 0,
      isRunManagerEnable: true,
      isToolsEnable: false,
      isReportsEnable: false,
      isSettingsnable: false
    }
  }
  componentDidMount(){
    if (window.location.pathname === '/home' || window.location.pathname === '/commissions/runmanager'|| window.location.pathname === '/commissions/commissiontools'||window.location.pathname === '/commissions/reports'|| window.location.pathname==='/commissions/settings') {       
      if(window.location.pathname ==='/commissions/runmanager' || window.location.pathname ==='/home' || window.location.pathname ==='/commissions/rundetails' ) {
        this.handleChange('',0);
      } else if(window.location.pathname === '/commissions/commissiontools'){
        this.handleChange('',1);
      } else if(window.location.pathname === '/commissions/reports') {
         this.handleChange('',2);
      } else if(window.location.pathname==='/commissions/settings') {
        this.handleChange('',3);
      }
    } 
  }

 handleChange = async (event, newValue) => {
    this.props.updateTabValue(newValue);
    //console.log('tab select value', newValue,event?.target?.innerText)
    switch (event?.target?.innerText) {
      case 'RUN MANAGER':
            this.props.history.push("/runmanager");
        break;
      case 'COMMISSIONS TOOLS':
        this.props.history.push("/commissiontools");
        break;
      case 'REPORTS':
        this.props.history.push("/reports");
        break;
      case 'SETTINGS':
        this.props.history.push("/settings");
        break;
      default: this.setState({ isRunManagerEnable: true, isToolsEnable: false, isReportsEnable: false, isSettingsnable: false })
        break;
    }
  }

  render() {
    const {
      vector6,
      vector7,
      vector8,
      tabValue,
    } = this.props;

    return (
      <div className="flex">
        <div className="primary-nav-1" style={{ marginTop: "0px" }}>
          <div className="flex-row-12">
            <div className="col-2">
              <div className="group-2457">
                <img
                  className="vector-1212"
                  src={commisisonlogo} alt=""
                />
                <div className="commission-title valign-text-middle asap-normal-mine-shaft-15px">{"COMMISSIONS ENGINE"}</div>
              </div>
            </div>
            <div className="col-8 lato-bold-granite-gray-14px menu-align-center">
              <Tabs
                className="commission-tabs-container"
                value={tabValue}
                indicatorColor="primary"
                select="true"
                textColor="primary"
                onChange={this.handleChange}
                aria-label="disabled tabs example"
              >
                {gettokenScopeValidation("/commissions") ? <Tab className="commission-landing-tabs" label="RUN MANAGER" /> : ''}
                {gettokenScopeValidation("/commissions") ? <Tab className="commission-landing-tabs" label="COMMISSIONS TOOLS"/> : ''}
                {gettokenScopeValidation("/reports") ? <Tab className="commission-landing-tabs" label="REPORTS" /> : ''}
                {gettokenScopeValidation("/commissions") ?<Tab className="commission-landing-tabs" label="SETTINGS" /> : ''}
              </Tabs>
            </div>
            <div className="col-2">
              {/* <div className="row" style={{ justifyContent: 'center' }}>
                <div className="col-sm-3">
                  <div className="overlap-group2-1">
                    <img className="vector-6" src={vectorsearch} alt='' />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="overlap-group3">
                    <img className="vector-10" src={vector6} alt='' />
                    <img className="vector-8" src={vector7} alt='' />
                    <img className="vector-7" src={vector8} alt='' />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="overlap-group2-1">
                    <img className="vector-6" src={reportvc} alt='' />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>       
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    tabValue: state.commissionReducer.tabValue,  
  };
};
const mapDispatchToProps = dispatch => ({
  updateTabValue:(value) => dispatch({ type: BROWSER_BACK_BUTTON_HANDLETAB, payload: value })
});

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(CEPrimaryNav));
