import {
    LOADING
    } from '../actions'
    
    const initialState = {loading:false}
    
export default function loadingStateReducer (state, action) {
    if (typeof state === 'undefined') {
    return initialState
    }
    switch (action.type) {
    case LOADING:
        return { loading: action.payload }
    default:
        return state
    }
}
    