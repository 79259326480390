
import './RecapRange.css';
import vector13 from '../../../assets/vector-13@2x.png';
import { Modal} from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown'
import leftarrow from '../assets/leftarrow.svg'
import rightarrow from '../assets/rightarrow.svg'

export const RecapRange = (props) =>{
    const { enableModel, closeSelectedPayout, selectPayout} = props;
    return(
    <Modal show={enableModel} onHide={false} >
      <Modal.Header>
      <div className="flex-row-1-ei">
        <div className="header-name h3-header-3-18pt-bold-lato">Recap Range</div>
        <img className="close-vector-3-ei" src={vector13} onClick={closeSelectedPayout} alt=""/>
        </div>
      </Modal.Header>
      <Modal.Body style={{height: '240px'}}>
      <div className="rowalign-center row" style={{ height: '80px' }}>
								<div className="col-9">
									<div className="row recap-type-title">
										Commission Period
										<div className="row col-12">
											<div className="recap-template" style={{ marginTop: '8px' }}>
												<div className='cd-text-temp'>
													<div onClick={''}>
														<img src={leftarrow} alt='' /> </div>
													<span name="periodvalues" variant="contained" onClick={''}>03/2021</span>
													<div onClick=''>
														<img src={rightarrow} alt='' /> </div>
												</div>
											</div>
										</div>
									</div>
								</div>
                                <div className="col-9" >
									<div className="row recap-type-title">
										Period Type
										<div className="row col-12">
											<Dropdown style={{ backgroundColor: 'white', marginTop: '8px' }}>
												<Dropdown.Toggle id="dropdown-custom-components" title="Select Type">
													PRIMARY
												</Dropdown.Toggle>
												<Dropdown.Menu className="period-dropdown-menu">
											    <Dropdown.Item className="primary-dropbtn">PRIMARY</Dropdown.Item>
												</Dropdown.Menu>
											</Dropdown>
											{/* <img src={dropdown1} alt='' /> */}
										</div>
									</div>
								</div>
							</div>
        </Modal.Body>
      <Modal.Footer style={{marginTop: "0px"}}>
        <div className= "row">
        <div className="cmcolb-no-btn column" onClick={closeSelectedPayout}>
        <div className="colb-no-text">CANCEL</div>
        </div>
        <div className="colb-yes-btn column" onClick={selectPayout}>
          <div className="colb-yes-text">OK</div>
        </div>
        </div>
      </Modal.Footer>
    </Modal>
    )
}