import React from 'react';
import PropTypes from "prop-types";
import { loadTimelineData } from 'anima-timeline';
import './SecurityInfo.css';
import { copyToClipBoard, errorHandling } from '../../utilities/Services';
import * as Constants from '../../../constants/Constants';
import { Oval } from "react-loader-spinner";
import { connect } from "react-redux";
import actions from "../../../redux/actions/CommonDataActions";
import { getIntegrationAPI, getGenerateKeyAPI, putIntegrationAPI, postDevToken } from '../../../redux/thunk/integrationAPIThunk'
import AuthService from '../../server/AuthService';
import Vector63 from '../../../assets/vector-63@2x.png';
import Vector64 from '../../../assets/vector-64@2x.png';
import Vector65 from '../../../assets/vector-65@2x.png';
import vector51 from '../../../assets/vector-51@2x.png';
import vector34 from '../../../assets/vector-34@2x.png';
import line42 from '../../../assets/line-42@1x.png';
import vector30 from '../../../assets/vector-30@2x.png';
import vector31 from '../../../assets/vector-31@2x.png';
import vector29 from '../../../assets/vector-29@2x.png';
import info from '../../../assets/info.svg';
import { Input } from 'reactstrap';
import { Tooltip } from '@material-ui/core';
import NewSnackbar from "../../utilities/NewSnackbar";
import copy from '../../../assets/copy.svg';
import plainvectorcircle from '../../../assets/plainvectorcircle.svg';
import radiobtnselect from "../../../assets/new-radioselect.svg"

class SecurityInfo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      integrationID: " ",
      secretKey: "***********************",
      integrationSecret: "",
      showSecretKey: false,
      tempDevToken: '',
      developerToken: "",
      authorityURL: process.env.REACT_APP_IDENTITY_BASE_URL,
      callbackURL: '',
      grantType: '',
      showLoaderResult: true,
      showButton: false,
      nextButton: false,
      tempSecret: '',
      integrationName: ''
    }
  }
  componentDidMount() {
    if (AuthService.isTokenValidCheck() === true) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("current integrationID : ", this.props.currentIntegrationID) }
      console.log('edit integration security details-', this.props.currentIntegrationRes)
      this.setState({
        integrationID: this.props.currentIntegrationRes.integrationID,
        integrationName: this.props.currentIntegrationRes.name,
        description: this.props.currentIntegrationRes.description,
        integrationSecret: this.props.currentIntegrationRes.integrationSecret,
        tempSecret: this.props.currentIntegrationRes.integrationSecret,
        grantType: this.props.currentIntegrationRes.grantType,
        callbackURL: this.props.currentIntegrationRes.callback,
        developerToken: localStorage.getItem('id_token')
      });
      this.props.callGetIntegrationAPI(this.props.currentIntegrationID)
      //  this.props.currentIntegrationID
    }
    setTimeout(() => {
      this.setState({ showLoaderResult: false })
    }, 1000);
  }

  resetSecretKey() {
    //this piece of code use to pass back any params if any edit fields in security details
    let params = {
      'authentication': 'server',
      'callback': this.state.callbackURL
    };
    this.props.securityfunc(params)
    //call api to reset secretkey
    this.setState({ showButton: true })
    this.props.callGenerateKeyAPI();
  }

  componentDidUpdate(nextProps) {
    if (this.props.generateKeyResponse !== nextProps.generateKeyResponse) {
      if (this.props.generateKeyResponse.error === false) {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("generateKeyResponse ", this.props.generateKeyResponse.data); }
        this.setState({ integrationSecret: this.props.generateKeyResponse.data.key });
        console.log('new secret key', this.props.generateKeyResponse.data.key)
      }
      else if (this.props.generateKeyResponse.error) {
        this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.warning, snackbarMessage: Constants.ALERT_TECHNICAL_ERROR })
      }
    } else if (this.props.generateKeyHasError !== nextProps.generateKeyHasError) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("call Endpoint Error : ", this.props.generateKeyHasError); }
      errorHandling(this.props.generateKeyHasError);
    }
    if (this.props.getIntegrationResponse !== nextProps.getIntegrationResponse) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Get integration ", this.props.getIntegrationResponse) }
      if (this.props.getIntegrationResponse.error === false) {
        if (this.props.getIntegrationResponse.data && this.props.getIntegrationResponse.data.length > 0) {
          console.log("integration Details", this.props.getIntegrationResponse.data[0]);
          this.setState({
            integrationID: this.props.getIntegrationResponse.data[0].integrationID,
            integrationName: this.props.getIntegrationResponse.data[0].name,
            description: this.props.getIntegrationResponse.data[0].description,
            integrationSecret: this.props.getIntegrationResponse.data[0].integrationSecret,
            tempSecret: this.props.getIntegrationResponse.data[0].integrationSecret,
            grantType: this.props.getIntegrationResponse.data[0].grantType,
            callbackURL: this.props.getIntegrationResponse.data[0].callback,
            // developerToken: localStorage.getItem('id_token')
          });
          let devToken_Params = {}
          devToken_Params[Constants.RESETSECRET_ACTION] = this.props.getIntegrationResponse.data[0].integrationSecret
          devToken_Params[Constants.PARAM_INTEGRATIONID] = this.props.getIntegrationResponse.data[0].integrationID
          devToken_Params[Constants.PARAM_SCOPE] = Constants.TEXT_DEVTOKEN_SCOPES
          devToken_Params[Constants.PARAM_EXPIRY] = Constants.TEXT_DEVTOKEN_EXPIRY
          this.props.generateDevToken(devToken_Params)
        }
      }
      this.setState({ showLoaderResult: false });
    } else if ((this.props.getIntegrationError !== nextProps.getIntegrationError)) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Get integration error ", this.props.getIntegrationError) }
      this.setState({ showLoaderResult: false });
      errorHandling(this.props.getIntegrationError);
    }
    if (this.props.devtokenResposne !== nextProps.devtokenResposne) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Devtoken Resposne ", this.props.devtokenResposne) }
      if (this.props.devtokenResposne.error === false) {
        if (this.props.devtokenResposne.data !== null && this.props.devtokenResposne.data !== '') {
          // console.log("devtokenResposne Details", this.props.devtokenResposne.data.dev_token);
          this.setState({ tempDevToken: this.props.devtokenResposne.data.dev_token });
          this.setState({ developerToken: this.props.devtokenResposne.data.dev_token });
        }
        this.setState({ showLoaderResult: false });
      }
    } else if ((this.props.devtokenError !== nextProps.devtokenError)) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Devtoken Resposne error ", this.props.devtokenError) }
      this.setState({ showLoaderResult: false });
      errorHandling(this.props.devtokenError);
    }
    if (this.props.editIntegrationResponse !== nextProps.editIntegrationResponse) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Get integration secret key ", this.props.getIntegrationResponse) }
      if (this.props.editIntegrationResponse.error === false) {
        this.setState({ showLoaderResult: false });
        this.setState({ showButton: false });
        if (this.props.editIntegrationResponse.data && this.props.title !== "securityInfo") {
          this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.success, snackbarMessage: Constants.TEXT_INTEGRATION_DETAILS_UPDATE })
        }
      }
    } else if ((this.props.editIntegrationError !== nextProps.editIntegrationError)) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("editIntegrationError ", this.props.getIntegrationError) }
      errorHandling(this.props.editIntegrationError);
    }
  }
  changeSecretView() {
    if (this.state.showSecretKey === true) {
      this.setState({
        showSecretKey: false
      })
    } else {
      this.setState({
        showSecretKey: true
      })
    }
  }
  saveChanges() {
    if (this.state.integrationSecret !== null) {
      let generalParams = {};
      generalParams[Constants.PARAM_INTEGRATIONID] = this.state.integrationID;
      generalParams[Constants.PARAM_NAME] = this.state.integrationName;
      generalParams[Constants.PARAM_DESCRIPTION] = this.state.description;
      generalParams[Constants.PARAM_COLLABORATORS] = "" //this.state.saveCollaborators.toString();
      generalParams[Constants.PARAM_TYPE] = 'configuration';
      generalParams[Constants.PARAM_STATUS] = 1
      generalParams[Constants.PARAM_AUTHENTICATION] = 'server';
      generalParams[Constants.PARAM_INTEGRATION_SECRET] = this.state.integrationSecret
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("General Params :", generalParams); }
      this.props.callIntegrationStatusUpdate(this.state.integrationID, generalParams);
      this.setState({ showLoaderResult: true })
    }
  }
  cancelChanges() {
    this.setState({ showButton: false, integrationSecret: this.state.tempSecret })
  }
  copyIntegrationID(copyValue) {
    if (copyValue) {
      copyToClipBoard(copyValue, Constants.TEXT_COPYTOCLIPBOARD, 5000, Constants.RED_COLOR);
      this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.info, snackbarMessage: Constants.TEXT_COPYTOCLIPBOARD })
    }
  }
  render() {
    let loader;
    if (this.state.showLoaderResult) {
      loader = <div id="center" style={{ position: "fixed", bottom: "40%", left: "48%" }}>
        <Oval
          type="Puff"
          position="fixed"  secondaryColor="#E85557"
          color="#E85557"
          height={50}
          width={50}
          timeout={3000}
        />
      </div>
    }
    return (
      <div className="security-details border-1px-grey-4">
        <div className="flex-row-1-si">
          <div className="flex-col-si">
            <div className="api-credentials valign-text-middle h3-header-3--18pt-bold-lato">API Credentials</div>
            <p style={{lineHeight: '19.4px'}} className="text-2-si paragraph-1--14pt-regular-lato">
              <span className="span paragraph-1--14pt-regular-lato">
                Use these keys to validate your integration with Infotrax.{" "}
              </span>
              <span className="span1-si paragraph-1--14pt-regular-lato">
                Learn more about API credential rotation.
                <br />
              </span>
              <span className="span paragraph-1--14pt-regular-lato">
                <br />
                Credentials for using OAuth 2.0 as your Authentication type.
              </span>
            </p>
          </div>
          <div className="flex-col-3-si">
            <div className="group-2750">
              <div className="integration-id-si valign-text-middle paragraph-1--14pt-regular-lato">Integration ID</div>
              <div className="layer-1-3">
                <div className="overlap-group-2-si">
                  <div className="overlap-group-3-si">
                    <Tooltip title="This is the unique identifier assigned to your integration." placement="top" arrow>
                      <img className="vector-security-vs" src={info} alt="" />
                    </Tooltip>
                    {/* <img className="vector-11-si" src={Vector1} alt=''/>
                  <img className="vector-7-si" src={Vector63} alt=''/>
                  <img className="vector-8-si" src={Vector64}alt=''/>
                  <img className="vector-13-si" src={Vector65} alt=''/> */}
                  </div>
                  {/* <img className="vector-7-si" src={Vector63} alt="" />
                  <img className="vector-8-si" src={Vector64} alt="" />
                  <img className="vector-9-si" src={Vector65} alt="" /> */}
                </div>
              </div>
            </div>
            <div className="code-copy-1 border-1px-grey-3 code-copy" style={{ overflow: "hidden", whiteSpace: "nowrap" }}>
              <div className="text-i166319241661 valign-text-middle paragraph-1--14pt-regular-lato" style={{ fontSize: "12px", width: "295px" }}>{this.state.integrationID}</div>
              <div className="frame-2378 code-copy" onClick={this.copyIntegrationID.bind(this, this.state.integrationID)}>
                <div className="copy1-si">
                  <div className="overlap-group-si">
                    <img className="vector-si" src={copy} alt='' />
                  </div>
                </div>
              </div>
            </div>
            <div className="group-2752">
              <div className="secret-key valign-text-middle paragraph-1--14pt-regular-lato">Secret Key</div>
              <Layer13
                vector2={Vector63}
                vector3={Vector64}
                vector4={Vector65} toolTipTitle="This is a secret key value used to ensure only authorized applications that know this secret may use this integration."
              />
            </div>
              <div className="code-copy-1 border-1px-grey-3 code-copy" style={{ overflow: "hidden", whiteSpace: "nowrap" }}>
              <div className="text-i166319241661 valign-text-middle paragraph-1--14pt-regular-lato" style={{ fontSize: "12px", width: "295px" }}>{this.state.showSecretKey ? this.state.integrationSecret : this.state.secretKey}</div>
              {this.state.showSecretKey ? <i aria-hidden="true" class="eye big icon" style={{ fontSize: '1.3em', marginLeft: '85px', color: '#666666', cursor: 'pointer' }} onClick={this.changeSecretView.bind(this)}></i> :
                <img
                  className="vector-14-si" style={{ cursor: 'pointer' }}
                  src={vector51} onClick={this.changeSecretView.bind(this)}
                  alt="" />}
              <div className="frame-2378-sec-1" onClick={this.copyIntegrationID.bind(this, this.state.integrationSecret)}>
                <div className="copy1-si">
                  <div className="overlap-group-si">
                    <img className="vector-si" src={copy} alt='' />
                  </div>
                </div>
              </div>
            </div>
            <p className="text-5 paragraph-1--14pt-regular-lato">
              This is a temporary token for developer testing only. Token will expire in 4 hours.
            </p>
            <div onClick={this.resetSecretKey.bind(this)} style={{ cursor: 'pointer' }}>
              <Component5102>RESET SECRET KEY</Component5102>
            </div>
            {/* <Group2757 developerToken="Developer Token" layer13Props={group2757Data.layer13Props} /> */}
            <div className="group-2757 ">
              <div className="developer-token valign-text-middle paragraph-1--14pt-regular-lato">Developer Token</div>
              <div className="overlap-group-3-si">
                <Tooltip title="This is a temporary token for developer testing only. The token will expire in 4 hours." placement="top" arrow>
                  <img className="vector-security-vs" src={info} alt="" />
                </Tooltip>
                {/* <img className="vector-11-si" src={Vector1} alt=''/>
                  <img className="vector-7-si" src={Vector63} alt=''/>
                  <img className="vector-8-si" src={Vector64}alt=''/>
                  <img className="vector-13-si" src={Vector65} alt=''/> */}
              </div>
            </div>
            <div className="code-copy-1 border-1px-grey-3 code-copy" style={{ overflow: "hidden", whiteSpace: "nowrap" }}>
              <div className="text-i166319241661 valign-text-middle paragraph-1--14pt-regular-lato" style={{ fontSize: "12px", width: "295px" }}>{this.state.developerToken}</div>
              <div className="frame-2378 code-copy" onClick={this.copyIntegrationID.bind(this, this.state.developerToken)}>
                <div className="copy1-si">
                  <div className="overlap-group-si">
                    <img className="vector-si" src={copy} alt='' />
                  </div>
                </div>
              </div>
            </div>
            {/* <Group2757 developerToken="Callback URL" layer13Props={group27572Data.layer13Props} className="group-2758" /> */}
            <div className="group-2757 group-2758">
              <div className="callback-url-tooltip valign-text-middle paragraph-1--14pt-regular-lato">Callback URL</div>

              <div className="overlap-group-3-si">
                <Tooltip title="For certain types of integrations this is the url the user’s browser will be directed back to after successful authentication by the Identity Server." placement="top" arrow>
                  <img className="vector-security-vs" src={info} alt="" />
                </Tooltip>
              </div>
            </div>
            {/* <div className="code-copy-2 border-1px-grey-3"> */}
            <Input type="text" className="code-copy-2 border-1px-grey-3" style={{ border: "1px solid #666666" }} placeholder="callback url" />
            {/* </div> */}
            <div className="group-2759">
              <div className="text-3-si valign-text-middle paragraph-1--14pt-regular-lato">Client Authority URL</div>
              <div className="layer-1-2">
                <div className="overlap-group-3-si">
                  <Tooltip title="This is the url of the Identity Server used for authentication and authorization for this instance of Flexcloud." placement="top" arrow>
                    <img className="vector-security-vs" src={info} alt="" />
                  </Tooltip>
                  {/* <img className="vector-11-si" src={Vector1} alt=''/>
                <img className="vector-7-si" src={Vector63} alt=''/>
                <img className="vector-8-si" src={Vector64}alt=''/>
                <img className="vector-13-si" src={Vector65} alt=''/> */}
                </div>
              </div>
            </div>
            {/* CopytoclipBoardforIntegrationID */}
            <div className="code-copy-1 border-1px-grey-3 code-copy" style={{ overflow: "hidden", whiteSpace: "nowrap" }}>
              <div className="text-i166319241661 valign-text-middle paragraph-1--14pt-regular-lato" style={{ fontSize: "12px", width: "295px" }}>{this.state.authorityURL}</div>
              <div className="frame-2378 code-copy" onClick={this.copyIntegrationID.bind(this, this.state.authorityURL)}>
                <div className="copy1-si">
                  <div className="overlap-group-si">
                    <img className="vector-si" src={copy} alt='' />
                  </div>
                </div>
              </div>
            </div>

            {this.state.showButton ? <div className="cancel-btn-si"> <button style={{ background: 'white', width: "120px", height: "40px", marginRight: "10px", borderRadius: "5px", border: "1px solid gray", fontWeight: "700", cursor: 'pointer' }} onClick={this.cancelChanges.bind(this)}>CANCEL</button>
              <button style={{ width: "100px", height: "40px", backgroundColor: "#d93030", color: "white", borderRadius: "5px", border: "1px solid gray", fontWeight: "700", cursor: 'pointer' }} onClick={this.saveChanges.bind(this)}>SAVE</button></div> : null}
          </div>
        </div>
        {/* <CodeCopy3 frame2378Props={codeCopy3Data.frame2378Props} /> */}
        <img className="line-49-si" src={line42} alt='' />
        <div className="flex-col-2-si">
          <div className="flex-row-si">
            <div className="flex-col-1-si">
              <div className="o-auth-type valign-text-middle h3-header-3--18pt-bold-lato">O-Auth Type</div>
              <p style={{lineHeight: '19.4px'}} className="text-1-si paragraph-1--14pt-regular-lato">
                <span className="span paragraph-1--14pt-regular-lato">
                  Choose how your app will authenticate to the Box APIs.{" "}
                </span>
                <span className="span1-si paragraph-1--14pt-regular-lato">Learn more</span>
              </p>
            </div>
            <Card2
            descriptionI166320="Allows your integration to authenticate directly to Infotrax using a secure web token instead of user credentials."
            titleI16632077160="Server Authentication"
            vector2={vector30}
            vector3={vector31}
          />
          </div>
          <Card
              descriptionI166320="Requires Infotrax users to login with username and password to authorise your integration"
              titleI16632074160="User Authentication"
            />
        </div>
        <NewSnackbar />
        {loader}
      </div>
    );
  }
}
SecurityInfo.propTypes = {
  localparams: PropTypes.any,
  securityfunc: PropTypes.func
};
const mapStateToProps = state => {
  return {
    currentIntegrationID: state.integrationDataReducer.currentIntegrationID,
    currentIntegrationRes: state.integrationDataReducer.currentIntegrationRes,
    getIntegrationResponse: state.integrationDataReducer.createIntegrationResponse,
    getIntegrationError: state.integrationDataReducer.createIntegrationError,
    integrationStatusResponse: state.integrationDataReducer.integrationStatusResponse,
    integrationStatusError: state.integrationDataReducer.integrationStatusError,
    generateKeyResponse: state.integrationDataReducer.generateKeyResponse,
    generateKeyHasError: state.integrationDataReducer.generateKeyHasError,
    editIntegrationResponse: state.integrationDataReducer.editIntegrationResponse,
    editIntegrationError: state.integrationDataReducer.editIntegrationError,
    devtokenResposne: state.integrationDataReducer.devtokenResposne,
    devtokenError: state.integrationDataReducer.devtokenError,
  };
};
const mapDispatchToProps = dispatch => ({
  callIntegrationStatusUpdate: (integrationID, params) => dispatch(putIntegrationAPI(integrationID, params)),
  callGetIntegrationAPI: (integrationID) => dispatch(getIntegrationAPI(integrationID)),
  callGenerateKeyAPI: () => dispatch(getGenerateKeyAPI()),
  generateDevToken: (params) => dispatch(postDevToken(params)),
  showSnackbar: (params) => dispatch({ type: actions.SNACKBAR_SHOW, payload: params }),
});
export default connect(mapStateToProps, mapDispatchToProps)(SecurityInfo);

class CodeCopy22 extends React.Component {
  copyIntegrationID(copyValue) {
    if (copyValue) {
      copyToClipBoard(copyValue, Constants.TEXT_COPYTOCLIPBOARD, 5000, Constants.RED_COLOR);
      this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.info, snackbarMessage: Constants.TEXT_COPYTOCLIPBOARD })
    }
  }
  render() {
    const { textI166319241661, className } = this.props;
    return (
      <div className={`code-copy-1 border-1px-grey-3 ${className || ""}`} style={{ overflow: "hidden", whiteSpace: "nowrap" }}>
        <div className="text-i166319241661 valign-text-middle paragraph-1--14pt-regular-lato" style={{ fontSize: "12px", width: "295px" }}>{textI166319241661}</div>
        <div className={`frame-2378 ${className || ""}`} onClick={this.copyIntegrationID.bind(this, textI166319241661)}>
          <div className="copy1-si">
            <div className="overlap-group-si">
              {/* <img
              className="vector-1-si"
              src="https://anima-uploads.s3.amazonaws.com/projects/607ff5beb95afd36a6b5e6c3/releases/60814525f4556b65c033cbd7/img/vector-1@2x.png"
            /> */}
              <img className="vector-si" src={copy} alt='' />
              {/* <img className="vector-2-si" src={vector3} /> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}


class Frame2378 extends React.Component {
  copyIntegrationID(copyValue) {
    if (copyValue) {
      copyToClipBoard(copyValue, Constants.TEXT_COPYTOCLIPBOARD, 5000, Constants.RED_COLOR);
      this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.info, snackbarMessage: Constants.TEXT_COPYTOCLIPBOARD })
    }
  }
  render() {
    const { className, value } = this.props;

    return (
      <div className={`frame-2378 ${className || ""}`} onClick={this.copyIntegrationID.bind(this, value)}>
        <div className="copy1-si">
          <div className="overlap-group-si">
            {/* <img
              className="vector-1-si"
              src="https://anima-uploads.s3.amazonaws.com/projects/607ff5beb95afd36a6b5e6c3/releases/60814525f4556b65c033cbd7/img/vector-1@2x.png"
            /> */}
            <img className="vector-si" src={copy} alt='' />
            {/* <img className="vector-2-si" src={vector3} /> */}
          </div>
        </div>
      </div>
    );
  }
}


class Layer13 extends React.Component {
  render() {
    const { className, toolTipTitle } = this.props;

    return (
      <div className={`layer-1-si ${className || ""}`}>
        <div className="overlap-group-1-si">
          <Tooltip title={toolTipTitle} placement="top" arrow>
            <img className="vector-security-vs" src={info} alt="" />
          </Tooltip>
        </div>
      </div>
    );
  }
}


class Component5102 extends React.Component {
  componentDidMount() {
    loadTimelineData(i3097705361126TimelineData);
    loadTimelineData(i30977053611266380TimelineData);
  }

  render() {
    const { children } = this.props;

    return (
      <div className="i309770-5361126 component component-wrapper not-ready">
        <div className="i309770-5361126-6380 component component-wrapper not-ready">
          <div className="button-i309770536-bGIy44 valign-text-middle">{children}</div>
        </div>
      </div>
    );
  }
}

const i3097705361126TimelineData = [
  {
    initial_state_name: "Property-1=Secondary-Property-2=Medium-Property-3=Default",
    root_element: ".i309770-5361126",
    states_flow: {
      "Property-1=Primary-Property-2=Large-Property-3=Default": {
        listeners: [],
        overrides: {},
      },
      "Property-1=Primary-Property-2=Large-Property-3=Disable": {
        listeners: [],
        overrides: {},
      },
      "Property-1=Primary-Property-2=Large-Property-3=Hover": {
        listeners: [],
        overrides: {},
      },
      "Property-1=Primary-Property-2=Medium-Property-3=Default": {
        listeners: [],
        overrides: {},
      },
      "Property-1=Primary-Property-2=Medium-Property-3=Disable": {
        listeners: [],
        overrides: {},
      },
      "Property-1=Primary-Property-2=Medium-Property-3=Hover": {
        listeners: [],
        overrides: {},
      },
      "Property-1=Secondary-Property-2=Large-Property-3=Default": {
        listeners: [],
        overrides: {},
      },
      "Property-1=Secondary-Property-2=Large-Property-3=Disable": {
        listeners: [],
        overrides: {},
      },
      "Property-1=Secondary-Property-2=Large-Property-3=Hover": {
        listeners: [],
        overrides: {},
      },
      "Property-1=Secondary-Property-2=Medium-Property-3=Default": {
        listeners: [],
        overrides: {},
      },
      "Property-1=Secondary-Property-2=Medium-Property-3=Disable": {
        listeners: [],
        overrides: {},
      },
      "Property-1=Secondary-Property-2=Medium-Property-3=Hover": {
        listeners: [],
        overrides: {},
      },
    },
  },
];
const i30977053611266380TimelineData = [
  {
    initial_state_name: "Property-1=Primary-Property-2=Medium-Property-3=Default",
    root_element: ".i309770-5361126-6380",
    states_flow: {
      "Property-1=Primary-Property-2=Large-Property-3=Default": {
        listeners: [],
        overrides: {},
      },
      "Property-1=Primary-Property-2=Large-Property-3=Disable": {
        listeners: [],
        overrides: {},
      },
      "Property-1=Primary-Property-2=Large-Property-3=Hover": {
        listeners: [],
        overrides: {},
      },
      "Property-1=Primary-Property-2=Medium-Property-3=Default": {
        listeners: [],
        overrides: {},
      },
      "Property-1=Primary-Property-2=Medium-Property-3=Disable": {
        listeners: [],
        overrides: {},
      },
      "Property-1=Primary-Property-2=Medium-Property-3=Hover": {
        listeners: [],
        overrides: {},
      },
      "Property-1=Secondary-Property-2=Large-Property-3=Default": {
        listeners: [],
        overrides: {},
      },
      "Property-1=Secondary-Property-2=Large-Property-3=Disable": {
        listeners: [],
        overrides: {},
      },
      "Property-1=Secondary-Property-2=Large-Property-3=Hover": {
        listeners: [],
        overrides: {},
      },
      "Property-1=Secondary-Property-2=Medium-Property-3=Default": {
        listeners: [],
        overrides: {},
      },
      "Property-1=Secondary-Property-2=Medium-Property-3=Disable": {
        listeners: [],
        overrides: {},
      },
      "Property-1=Secondary-Property-2=Medium-Property-3=Hover": {
        listeners: [],
        overrides: {},
      },
    },
  },
];


class Card extends React.Component {
  componentDidMount() {
    loadTimelineData(x16632074TimelineData);
  }

  render() {
    const { descriptionI166320, titleI16632074160 } = this.props;

    return (
      <div className="x16632074 component component-wrapper not-ready">
        <div className="description-i166320-LCm7Ku">{descriptionI166320}</div>
        <div className="title-i16632074160-LCm7Ku valign-text-middle">{titleI16632074160}</div>
        <div className="group-2720-q97ntV-11">
          <img
            className="vector-kpP02F-11"
            src={plainvectorcircle}
            alt='' />
          </div>
        </div>
    );
  }
}

const x16632074TimelineData = [
  {
    initial_state_name: "Property-1=Default",
    root_element: ".x16632074",
    states_flow: {
      "Property-1=Default": {
        listeners: [],
        overrides: {},
      },
      "Property-1=Selected": {
        listeners: [],
        overrides: {},
      },
    },
  },
];


class Card2 extends React.Component {
  componentDidMount() {
    loadTimelineData(x16632077TimelineData);
  }

  render() {
    const { descriptionI166320, titleI16632077160, vector2, vector3 } = this.props;

    return (
      <div className="x16632077 component component-wrapper not-ready">
        <div className="description-i166320-q97ntV">{descriptionI166320}</div>
        <div className="title-i16632077160-q97ntV valign-text-middle">{titleI16632077160}</div>
        <div className="group-2720-q97ntV">
          <img
            className="vector-kpP02F"
            src={radiobtnselect}
            alt='' />
          </div>
        </div>
    );
  }
}

const x16632077TimelineData = [
  {
    initial_state_name: "Property-1=Selected",
    root_element: ".x16632077",
    states_flow: {
      "Property-1=Default": {
        listeners: [],
        overrides: {},
      },
      "Property-1=Selected": {
        listeners: [],
        overrides: {},
      },
    },
  },
];

const frame2378Data = {
  vector2: "https://anima-uploads.s3.amazonaws.com/projects/607ff5beb95afd36a6b5e6c3/img/vector-34@2x.png",
  vector3: "https://anima-uploads.s3.amazonaws.com/projects/607ff5beb95afd36a6b5e6c3/img/vector-35@2x.png",
};

const codeCopy22Data = {
  frame2378Props: frame2378Data,
};

const frame23782Data = {
  vector2: "https://anima-uploads.s3.amazonaws.com/projects/607ff5beb95afd36a6b5e6c3/img/vector-34@2x.png",
  vector3: "https://anima-uploads.s3.amazonaws.com/projects/607ff5beb95afd36a6b5e6c3/img/vector-35@2x.png",
};

const codeCopy222Data = {
  frame2378Props: frame23782Data,
};



