import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Oval } from "react-loader-spinner";
import { Modal } from "react-bootstrap";
import vector13 from '../../../assets/vector-13@2x.png';
import '../AddCompareRun/AddCompareModal.css'
import './ChangeRunModal.css'
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Radio from '@material-ui/core/Radio';
import { getCommissionRunAPIList, COMMISSION_SELECTED_RUN, getCommissionPeriodListAPI} from "../actions";
import leftarrow from "../assets/leftarrow.svg";
import rightarrow from "../assets/rightarrow.svg";
import {getCommissionTypeListAPI} from "../../../components/Commissions/actions";
import Dropdown from "react-bootstrap/Dropdown";
import {getMonthDays, stringTruncate}  from '../../utilities/Services'
import {
  isNullResponse,
  allNullTypeCheck,
} from "../commonComponents/UtilityFun";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import gotoarrow from "../assets/gotoarrow.svg";
import * as Constants from "../../../constants/Constants";
import { Tooltip } from '@material-ui/core';
import Moment from 'moment';

const styles = (theme) => ({
  tableRow: {
    "&&:hover": {
      backgroundColor: "#0CB5F3",
    },
  },
});
const fields = [
  { name: "Type", displayName: "Type", inputFilterable: true, sortable: true },
  {
    name: "recordNo",
    displayName: "Record No.",
    inputFilterable: false,
    exactFilterable: true,
    sortable: true,
  },
  {
    name: "name",
    displayName: "Run Name",
    inputFilterable: false,
    exactFilterable: true,
    sortable: true,
  },

  {
    name: "description",
    displayName: "Description",
    inputFilterable: false,
    sortable: true,
  },
];

class ChangeRunModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoaderResult: false,
      showRemoveColModal: true,
      hideModal: false,
      createdRun: null,
      currentRunDetails: "",
      showRunDetails: false,
      disableNewRun: true,
      commissionRunState: [],
      selectedCommissionType: "",
      commddionRunDataObj: [],
      commissionPeriodData: null,
      commissionPeriods: [],
      selectedCommissionPeriodIndex: 0,
      rowstatus: false,
      selectedCommissionRun: "",
      commissionTypes: [],
      displayPeriodPop: false,
      anchorEl: false,
      periodValue: "",
      totalRuns: [],
      selectedType: 0,
      runsWaitTime:1,
      commTypeWaitTime:1,
      periodWaitTime:1
    };
  }

  componentDidMount() {
    this.props.getCommissionTypes();
    this.props.getCommissionRunList();
    if (allNullTypeCheck(this.props.commissionTypeValue.title)) {
      this.setState(
        { selectedCommissionType: this.props.commissionTypeValue },
        () => {
          this.props.getCommissionPeriod(this.props.commissionTypeValue.title);
        }
      );
    }
    this.setState({ commddionRunDataObj: [], showLoaderResult: true });
  }
  componentDidUpdate(nextProps) {
    const {
      commissionRunList,
      commissionTypeList,
      commissionPeriodList,
      commissionPeriodError,
    } = this.props;
    const { selectedCommissionType, runsWaitTime, commTypeWaitTime, periodWaitTime } = this.state;
    if (commissionRunList !== nextProps.commissionRunList) {
      console.log("commission type list : ", commissionRunList?.data);
      this.setState({ showLoaderResult: false });
      if(!commissionRunList.hasOwnProperty('status')){
      if (isNullResponse(commissionRunList.data)) {
        // this.setState({ commissionRunState: commissionRunList.data?.runs });
        this.selectPeriodIndex(selectedCommissionType?.defaultPeriod);
      } else if(Object.keys(commissionRunList?.data).length===0 && allNullTypeCheck(commissionRunList?.messages)) {
        if(commissionRunList?.messages?.length > 0 && commissionRunList?.messages[0]?.includes("Try again...")){
            console.log("Try again block entered : ",commissionRunList?.data);
            this.setState({ showLoader: true });
            setTimeout(() => {
              this.props.getCommissionRunList();
            }, 1000 * runsWaitTime);
            this.setState(prevState => {
              return {runsWaitTime: prevState.runsWaitTime * 2}
           })
        }
      }
    } else {
        if(commissionRunList.hasOwnProperty('status') && commissionRunList.status===202){ 
          this.setState({ showLoader: true });
            setTimeout(() => {
              this.props.getCommissionRunList();
            }, 1000 * runsWaitTime);
            this.setState(prevState => {
              return {runsWaitTime: prevState.runsWaitTime * 2}
           })
        }
      }
    }
    if (commissionTypeList !== nextProps.commissionTypeList) {
      console.log("commissionTypeList.data", commissionTypeList?.data);
      this.setState({ showLoaderResult: false });
      if(!commissionTypeList.hasOwnProperty('status')){ 
      if (isNullResponse(commissionTypeList.data)) {
        if(commissionTypeList?.data?.hasOwnProperty('periodTypes')){
          console.log("commissionTypeList.data", commissionTypeList.data);
          this.setState({
            commissionTypes: commissionTypeList?.data?.periodTypes.filter(data=>data.active===true),
          });
        }
      } else if(Object.keys(commissionTypeList?.data).length===0 && allNullTypeCheck(commissionTypeList?.messages)) {
        if(commissionTypeList?.messages?.length > 0 && commissionTypeList?.messages[0]?.includes("Try again...")){
            console.log("Try again block entered : ",commissionTypeList?.data);
            this.setState({ showLoader: true });
            setTimeout(() => {
              this.props.getCommissionTypes();
            }, 1000 * commTypeWaitTime);
            this.setState(prevState => {
              return {commTypeWaitTime: prevState.commTypeWaitTime * 2}
           })
        }
      }
    }else {
        if(commissionTypeList.hasOwnProperty('status') && commissionTypeList.status===202){ 
          this.setState({ showLoader: true });
          setTimeout(() => {
            this.props.getCommissionTypes();
          }, 1000 * commTypeWaitTime);
          this.setState(prevState => {
            return {commTypeWaitTime: prevState.commTypeWaitTime * 2}
         })
        }
      }
    }
    if (commissionPeriodList !== nextProps.commissionPeriodList) {
      console.log("commission type list : ", commissionPeriodList?.data);
      this.setState({ showLoaderResult: false });
      if(!commissionPeriodList.hasOwnProperty('status')){
      if (isNullResponse(commissionPeriodList?.data) && commissionPeriodList?.error===false) 
        {
        if(isNullResponse(commissionPeriodList?.data?.periods)){
        let periodData = null;
        let commPeriods = null;
        periodData = commissionPeriodList.data;
        this.setState({ commissionPeriodData: periodData });
        // commPeriods = [...periodData.periods.primary];
        console.log("selected period type ", selectedCommissionType);
        commPeriods =
          commissionPeriodList?.data?.periods[
            selectedCommissionType?.title?.toString()?.toLocaleLowerCase()
          ];
        this.setState(
          {
            commissionPeriods: commPeriods,
          },
          () => {
            if (
              commPeriods?.length > 0 &&
              allNullTypeCheck(selectedCommissionType?.defaultPeriod)
            ) {
              this.selectPeriodIndex(selectedCommissionType?.defaultPeriod);
            } else {
              this.setState({ selectedCommissionPeriodIndex: 0 });
            }
          }
        );
        }
        else{
          this.setState({
            commissionPeriods: [],
          });
        }
      } else if(Object.keys(commissionPeriodList?.data).length===0 && allNullTypeCheck(commissionPeriodList?.messages)) {
        if(commissionPeriodList?.messages?.length > 0 && commissionPeriodList?.messages[0]?.includes("Try again...")){
            console.log("Try again block entered : ",commissionPeriodList?.data);
            this.setState({
              commissionPeriods: [],
            });
            this.setState({ showLoader: true });
            setTimeout(() => {
              this.props.getCommissionPeriod(
                commissionTypeList?.data?.periodTypes[0]?.title
              );
            }, 1000 * periodWaitTime);
            this.setState(prevState => {
              return {periodWaitTime: prevState.periodWaitTime * 2}
           })
        }
      }
       else {
        console.log("Getting empty periods or error : ", commissionPeriodList);
        this.setState({
          commissionPeriods: [],
        });
      }
    } else {
      if(commissionPeriodList.hasOwnProperty('status') && commissionPeriodList.status===202){ 
        this.setState({
          commissionPeriods: [],
        });
        this.setState({ showLoader: true });
        setTimeout(() => {
          this.props.getCommissionPeriod(
            commissionTypeList?.data?.periodTypes[0]?.title
          );
        }, 1000 * periodWaitTime);
        this.setState(prevState => {
          return {periodWaitTime: prevState.periodWaitTime * 2}
       })
      }
    }
    } else if (commissionPeriodError !== nextProps.commissionPeriodError) {
      console.log("Period list Error : ", commissionPeriodError);
    }
  }
  // handleChangePeriod = (event) => {
  //   console.log("select period", event);
  //   this.setState({ selectPeriodVal: event });
  // };
  getNextPeriod() {
    console.log("getNextPeriod()");
    this.setState((prevState, props) => ({
      selectedCommissionPeriodIndex:
        prevState.selectedCommissionPeriodIndex + 1,
    }));
    // this.setState({ dataObj: rows })
    console.log("getNextPeriod()=" + this.state.selectedCommissionPeriodIndex);
    this.updateSelectPeriod(this.state.selectedCommissionPeriodIndex + 1);
  }
  getPreviousPeriod() {
    console.log("getPreviousPeriod()");
    console.log(
      "getPreviousindex",
      this.state.selectedCommissionPeriodIndex,
      "length comm periods",
      this.state.commissionPeriods?.length - 1
    );
    this.setState((prevState, props) => ({
      selectedCommissionPeriodIndex:
        prevState.selectedCommissionPeriodIndex - 1,
    }));
    console.log(
      "getPreviousPeriod()=" + this.state.selectedCommissionPeriodIndex
    );
    this.updateSelectPeriod(this.state.selectedCommissionPeriodIndex - 1);
  }
  hideModal() {
    this.setState({ hideModal: true, showRemoveColModal: false });
    this.props.changeRunsBackfunc();
  }
  confirmModal() {
    this.setState({ showLoaderResult: true });
    this.props.selectedRun(this.state.selectedCommissionRun);
    this.hideModal();
  }
  radioSelected(commissionRun, event) {
    console.log(
      "add compare selected run : ",
      event.target.value,
      commissionRun.id
    );
    // this.props.selectedRun(commissionRun.id);
    this.setState({
      rowstatus: event.target.value,
      disableNewRun: false,
      selectedCommissionRun: commissionRun.runId,
    });
  }
  truncate(str) {
    if (str?.length > 0) {
      return str.length > 25 ? str.substring(0, 25) + "..." : str;
    }
  }
  handleCommissionTypeChange = (value) => {
    console.log("Select comm type", value);
    const commissionObj = this.commissionsTypefindIndex(
      this.state.commissionTypes,
      value
    );
    console.log("rs: ", this.state.commissionTypes[commissionObj]);
    if (commissionObj !== -1) {
      this.setState(
        {
          selectedCommissionType: this.state.commissionTypes[commissionObj],
          commissionRunState: [], selectedType: commissionObj
        },
        () => {
          this.props.getCommissionPeriod(value);
        }
      );
    }
  };
  commissionsTypefindIndex = (commissiontypeList, value) => {
    return commissiontypeList.findIndex((periodType) => {
      return periodType.title === value;
    });
  };
  handleClick = (event) => {
    if (allNullTypeCheck(event.currentTarget)) {
      this.setState({
        anchorEl: event.currentTarget,
        displayPeriodPop: true,
        periodValue: "",
      });
    }
  };
  handleClose = () => {
    this.setState({
      anchorEl: null,
      displayPeriodPop: false,
    });
  };
  handlePeriodInput = (input) => {
    if (allNullTypeCheck(input.target)) {
      console.log("input is", input.target);
      const { value } = input.target;
      if (value) {
        this.setState({ periodValue: value.length === 3 && !value.includes("/")
        ? `${value.substring(0, 2)}/${value.substring(2)}`
        : value });
      } else {
        this.setState({ periodValue: "" });
      }
    }
  };
  onJumpSelectPeriod = () => {
    const { periodValue } = this.state;
    const { commissionRunList } = this.props;
    this.setState({ anchorEl: false });
    console.log("selcted period ", periodValue);
    if (periodValue) {
      this.selectPeriodIndex(periodValue);
    } else {
      this.setState({ periodValue: "" });
      this.setState({ commissionRunState: commissionRunList });
    }
  };
  updateSelectPeriod(index) {
    const { commissionPeriods } = this.state;
    if (commissionPeriods?.length > 0) {
      this.selectPeriodIndex(getMonthDays(commissionPeriods[index]?.name));
    }
  }
  selectPeriodIndex(value) {
    const { totalRuns, commissionPeriods } = this.state;
    const { commissionRunList } = this.props;
    this.setState({ totalRuns: [], commissionRunState: [] }, () => {});
    console.log("commission periods : ", commissionPeriods);
    console.log("commission run list : ", commissionRunList);
    if (isNullResponse(commissionPeriods) && commissionPeriods?.length > 0 && value !== '00/0000') {
      const indexValue = commissionPeriods.findIndex((data) => {
        return getMonthDays(data.name) === value;
      });
      console.log("selected index : ", indexValue, commissionRunList);
      if (allNullTypeCheck(indexValue) && indexValue !== -1) {
        this.setState({ selectedCommissionPeriodIndex: indexValue }, () => {});
        commissionRunList?.data?.runs?.filter((data) => {
          if (data.period === value && allNullTypeCheck(data?.lastRun)) {
            totalRuns.push(data);
          }
        });
        this.setState({ commissionRunState: [] }, () => {
          this.setState({ commissionRunState: totalRuns });
        });
      } else if (indexValue === -1) {
        this.setState({ selectedCommissionPeriodIndex: 0, commissionRunState: []}, () => {});
        // commissionRunList?.data?.runs?.filter((data) => {
        //   if (data.period === value && allNullTypeCheck(data?.lastRun)) {
        //     totalRuns.push(data);
        //   }
        // });
        // this.setState({ commissionRunState: [] }, () => {
        //   this.setState({ commissionRunState: totalRuns });
        // });
      }
    }else{
      this.setState({ commissionRunState: [], selectedCommissionPeriodIndex: 0 })
    }
  }
  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.onJumpSelectPeriod();
    }
  };
  render() {
    let loader;
    const {
      showRemoveColModal,
      hideModal,
      showLoaderResult,
      disableNewRun,
      selectedCommissionType,
      commissionTypes,
      displayPeriodPop,
      anchorEl,
      periodValue,
      selectedCommissionPeriodIndex,
      selectedType
    } = this.state;
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    if (showLoaderResult) {
      loader = (
        <div
          id="center"
          style={{ position: "fixed", bottom: "40%", left: "48%" }}
        >
          <Oval
            type="Puff"
            position="fixed"
            secondaryColor="#E85557"
            color="#E85557"
            height={50}
            width={50}
            timeout={3000}
          />
        </div>
      );
    }
    return (
      <div>
        <Modal
          dialogClassName="changerun-my-modal"
          show={showRemoveColModal}
          enforceFocus={false}
        >
          <Modal.Header>
            <div className="changerun-flex-row-1">
              <div className="add-cmnr-header-name cmnr-h3-header-3-18pt-bold-lato">
                Change Run
              </div>
              <img
                className="addcmpr-vector-ei"
                src={vector13}
                onClick={this.hideModal.bind(this)}
                alt=""
              />
            </div>
          </Modal.Header>
          <Modal.Body style={{ height: "485px" }}>
            <div class="col-12">
              {/* <div class="row add-cmnr-runtype">
                <div className="cm-type-title">Toggle between the periods to view the runs in that period</div>
              </div> */}
              <div class="row cmnr-type-select">
                <div class="col-6">
                  <div className="chrun-type-title">Commission Period</div>
                  <div className="chrun-text-temp1 period-style-type">
                    <div
                      onClick={this.getPreviousPeriod.bind(this)}
                      disabled={
                        this.state.selectedCommissionPeriodIndex > 0
                          ? false
                          : true
                      }
                    >
                      <img src={leftarrow} alt="" style={{
                        marginRight: "5px",
                        cursor: "pointer",
                      }} />{" "}
                    </div>
                    <span
                      className="change-run-period"
                      name="periodvalues"
                      variant="contained"
                      onClick={this.handleClick}
                    >
                      {this.state.commissionPeriods?.length > 0
                        ? getMonthDays(
                            this.state.commissionPeriods[
                              this.state.selectedCommissionPeriodIndex
                            ]?.name
                          )
                        : "00/0000"}
                    </span>
                    <div
                      onClick={this.getNextPeriod.bind(this)}
                      disabled={
                        this.state.selectedCommissionPeriodIndex ===
                        this.state.commissionPeriods?.length - 1
                          ? true
                          : false
                      }
                    >
                      <img src={rightarrow} alt=""
                        style={{
                          marginLeft: "5px",
                          cursor: "pointer",
                        }} />{" "}
                    </div>
                    {displayPeriodPop ? (
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={this.state.anchorEl}
                        onClose={this.handleClose.bind(this)}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                      >
                        <Typography sx={{ p: 2 }}>
                          {this.state.commissionPeriods?.length > 0 ? (
                            <div className="selectcpdetails">
                              <div className="cmperiod-select">
                                Last Commission Period:{" "}
                                {commissionTypes[selectedType]?.lastCommissionPeriod}
                              </div>
                              <div className="cmperiod-select">
                                Start Date:{" "}
                                 { Moment(this.state.commissionPeriods[
                                    selectedCommissionPeriodIndex
                                  ].start).format('MM/DD/YYYY')}
                              </div>
                              <div className="cmperiod-select">
                                End Date:{" "}
                                {
                                  Moment(this.state.commissionPeriods[
                                    selectedCommissionPeriodIndex
                                  ].end).format('MM/DD/YYYY')
                                }
                              </div>
                              <div className="cmperiod-select">
                                Default Check Date:{" "}
                                {
                                   Moment(this.state.commissionPeriods[
                                    selectedCommissionPeriodIndex
                                  ].checkDate).format('MM/DD/YYYY')
                                }{" "}
                              </div>
                              <div className="cmperiod-line"></div>
                              <div className="jumpto">
                                Jump To:
                                <input
                                  className="col-4 jumpperiod-options"
                                  name="periodvalue"
                                  type="text"
                                  placeholder="MM/YYYY"
                                  value={periodValue}
                                  maxLength={7}
                                  onChange={this.handlePeriodInput.bind(this)}
                                  onKeyPress={this.handleKeyPress}
                                ></input>
                                <img
                                  src={gotoarrow}
                                  style={{
                                    marginLeft: "8px",
                                    cursor: "pointer",
                                  }}
                                  alt=""
                                  onClick={this.onJumpSelectPeriod}
                                />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </Typography>
                      </Popover>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div class="col-4">
                  <div className="chrun-type-title change-run-period-marging">
                    Period Type
                  </div>
                  <div className="chrun-text-temp">
                    <div className="row col-12 alignitems-left">
                      <Dropdown
                        onSelect={this.handleCommissionTypeChange}
                        className="runmanger"
                      >
                        <Dropdown.Toggle
                          id="dropdown-custom-components"
                          title="Select Type"
                        >
                          {selectedCommissionType.title}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="period-dropdown-menu">
                          {commissionTypes.map((item) => {
                            return (
                              <Dropdown.Item
                                className="primary-dropbtn"
                                eventKey={item.title}
                              >
                                {item.title}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div
                  className="row add-table-heading"
                  style={{ fontWeight: "normal" }}
                >
                  Select a run from the list below :
                </div>
                <TableContainer component={Paper} style={{ height: 378 }}>
                  <Table sx={{ minWidth: 450 }} aria-label="simple table">
                    <TableHead>
                      {fields.map((row) => (
                        <TableCell
                          className="runmanager-table-header add-item-style1"
                          component="th"
                          scope="row"
                          style={{ backgroundColor: "#DDDDDD" }}
                        >
                          {row.displayName}
                        </TableCell>
                      ))}
                    </TableHead>
                    <TableBody>
                      {this.state.commissionRunState?.length > 0
                        ? this.state.commissionRunState.map(
                            (commissionrun, i) => (
                              <TableRow
                                key={commissionrun?.name}
                                hover
                                style={{ height: 25 }}
                              >
                                <TableCell className="addcmpr-table">
                                  <div className="row add-item-style1">
                                    <Radio
                                      value={commissionrun?.name}
                                      defaultSelected={false}
                                      checked={
                                        commissionrun?.name !==
                                        this.state.rowstatus
                                          ? false
                                          : true
                                      }
                                      onChange={this.radioSelected.bind(
                                        this,
                                        commissionrun
                                      )}
                                    />
                                    {commissionrun.runType === "Analysis"
                                      ? "A"
                                      : "P"}
                                  </div>
                                </TableCell>
                                <TableCell
                                  className="addcmpr-table"
                                  align={"center"}
                                >
                                  {commissionrun?.recordNumber}
                                </TableCell>
                                {commissionrun?.name?.length >= 15 ? <Tooltip title= {commissionrun?.name} placement="top" arrow={true}>
                            <TableCell className="addcmpr-runmanager-name addcmpr-table">
                              {stringTruncate(commissionrun?.name, 15)}
                            </TableCell>
                            </Tooltip> :  <TableCell className="addcmpr-runmanager-name addcmpr-table space-wrap">
                              {commissionrun?.name}
                            </TableCell>}
                            {commissionrun?.description?.length >= 15 ? <Tooltip title= {commissionrun?.description} placement="top" arrow={true}>
                            <TableCell className="addcmpr-runmanager-name addcmpr-table">
                              {stringTruncate(commissionrun?.description, 15)}
                            </TableCell>
                            </Tooltip> :  <TableCell className="addcmpr-runmanager-name addcmpr-table space-wrap">
                              {commissionrun?.description}
                            </TableCell>}
                              </TableRow>
                            )
                          )
                        : ""}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="row">
              <div
                disabled={disableNewRun}
                style={{
                  opacity: disableNewRun === true ? 0.3 : 1.0,
                  backgroundColor: disableNewRun === true ? "#3333" : "#D93030",
                }}
                className="cmnr-create-run"
                onClick={this.confirmModal.bind(this)}
              >
                <div
                  className="add-run-type-btn"
                  style={{
                    color: disableNewRun === true ? "gray" : "white",
                    cursor: "pointer",
                  }}
                >
                  SELECT RUN
                </div>
              </div>
            </div>
          </Modal.Footer>
          {loader}
        </Modal>
      </div>
    );
  }
}
ChangeRunModal.propTypes = {
  defaultvalues: PropTypes.any,
  changeRunsBackfunc: PropTypes.func,
};
const mapStateToProps = (state) => {
  return {
    commissionRunList: state.commissionRunReducer.commissionRunList,
    commissionTypeError: state.commissionToolsReducer.commissionTypeError,
    commissionTypeList: state.commissionToolsReducer.commissionTypeList,
    commissionPeriodList: state.commissionToolsReducer.commissionPeriodList,
    commissionPeriodError: state.commissionToolsReducer.commissionPeriodError,
  };
};
const mapDispatchToProps = dispatch => ({
  getCommissionRunList: () => dispatch(getCommissionRunAPIList()),
  getCommissionPeriod:(periodtype) =>  dispatch(getCommissionPeriodListAPI(periodtype)),
  selectedRunIndex: (selectedRun) =>
    dispatch({ type: COMMISSION_SELECTED_RUN, payload: selectedRun }),
  getCommissionTypes:() => dispatch(getCommissionTypeListAPI()),

})
export default connect(mapStateToProps, mapDispatchToProps)(ChangeRunModal);