import jwt from 'jwt-decode';
import Endpoints from './Endpoints';
// import AuthService from '../server/AuthService';
import copy from 'copy-to-clipboard';
import * as Constants from '../../constants/Constants'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { allNullTypeCheck } from '../Commissions/commonComponents/UtilityFun';

export const getApplicationId=()=>{
  if(localStorage.getItem('id_token') !== "undefined" && valueValidation(localStorage.getItem('id_token'))){
    const user = jwt(localStorage.getItem('id_token'));
    return user.application_id;
  }
}
export const gettokenScopeValidation = (scopeName) => {
  if(localStorage.getItem('id_token') !== "undefined" && valueValidation(localStorage.getItem('id_token'))){
  const user = jwt(localStorage.getItem('id_token'));
  let userscopes = user.user_scopes.split(' ');
  console.log("available scopes for this user : ",userscopes)
  let scopeexist = false;
  if (user) {
    if (user.user_scopes) {
      switch (scopeName) {

        case Endpoints.sdkScopeGet:
          userscopes.forEach((e) => {
            if ((e === scopeName) || (e === Endpoints.roleScope) || (e === Endpoints.portalScope)) {
              scopeexist = true;
            }
          });
          break;
        case Endpoints.userAccessScopeGet:
          userscopes.forEach((e) => {
            if ((e === scopeName) || (e === Endpoints.userScope) || (e === Endpoints.developerUserGet)) {
              scopeexist = true;
            }
          });
          break;
        case Endpoints.userAccessScopePost:
          userscopes.forEach((e) => {
            if ((e === scopeName) || (e === Endpoints.userScope)) {
              scopeexist = true;
            }
          });
          break;
        case Endpoints.userScope:
          userscopes.forEach((e) => {
            if ((e === scopeName) || (e === Endpoints.userScope)) {
              scopeexist = true;
            }
          });
          break;
        case Endpoints.developerUserPut:
          userscopes.forEach((e) => {
            if ((e === scopeName)) {
              scopeexist = true;
            }
          });
          break;
        case Endpoints.userAccessScopeDelete:
          userscopes.forEach((e) => {
            if ((e === scopeName) || (e === Endpoints.userScope)) {
              scopeexist = true;
            }
          });
          break;
        case Endpoints.userprofileScope:
          userscopes.forEach((e) => {
            if ((e === scopeName) || (e === Endpoints.userScope)) {
              scopeexist = true;
            }
          });
          break;
        case Endpoints.integrationScopeGet:
          userscopes.forEach((e) => {
            if ((e === scopeName) || (e === Endpoints.integrationScope)) {
              scopeexist = true;
            }
          });
          break;
        case Endpoints.integrationScopePost:
          userscopes.forEach((e) => {
            if ((e === scopeName) || (e === Endpoints.integrationScope)) {
              scopeexist = true;
            }
          });
          break;
        case Endpoints.integrationScopePut:
          userscopes.forEach((e) => {
            if ((e === scopeName) || (e === Endpoints.integrationScope)) {
              scopeexist = true;
            }
          });
          break;
        case Endpoints.integrationScope:
          userscopes.forEach((e) => {
            if ((e === scopeName)) {
              scopeexist = true;
            }
          });
          break;
        case Endpoints.developerIntegrationScopeDelete:
          userscopes.forEach((e) => {
            if ((e === scopeName)) {
              scopeexist = true;
            }
          });
          break;
        case Endpoints.developerWebhook:
          userscopes.forEach((e) => {
            if ((e === scopeName) || (e === Endpoints.integrationScope)) {
              scopeexist = true;
            }
          });
          break;
        case Endpoints.developerFeaturesGet:
          userscopes.forEach((e) => {
            if ((e === scopeName) || (e === Endpoints.integrationScope)) {
              scopeexist = true;
            }
          });
          break;
        case Endpoints.developerFeatures:
          userscopes.forEach((e) => {
            if ((e === scopeName) || (e === Endpoints.integrationScope)) {
              scopeexist = true;
            }
          });
          break;
        case Endpoints.TableuScope:
          userscopes.forEach((e) => {
            if ((e === scopeName)) {
              scopeexist = true;
            }
          });
          break;
        case Endpoints.brandingGet:
          userscopes.forEach((e) => {
            if ((e === scopeName) || (e === Endpoints.brandinPost)) {
              scopeexist = true;
            }
          });
          break;
        case Endpoints.settingsScope:
          userscopes.forEach((e) => {
            if ((e === scopeName)) {
              scopeexist = true;
            }
          });
          break;
        case Endpoints.dashboardSettingscope:
          userscopes.forEach((e) => {
            if ((e === scopeName)) {
              scopeexist = true;
            }
          });
          break;
        case Endpoints.commissionsScope:
          userscopes.forEach((e) => {
            if ((e === scopeName)) {
              scopeexist = true;
            }
          });
          break;
        case Endpoints.commissionSettings:
          userscopes.forEach((e) => {
            if ((e === scopeName)) {
              scopeexist = true;
            }
          });
          break;
        case Endpoints.reports:
          userscopes.forEach((e) => {
            if ((e === scopeName)) {
              scopeexist = true;
            }
          });
          break;
        default:
          userscopes.forEach((e) => {
            if ((e === scopeName)) {
              if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('scope is matching', scopeName); }
              scopeexist = true;
            }
          });
          break;
      }
    }
  }
  return scopeexist;
}
};

export const tempPositiveAlert = (msg, duration, selected_color) => {
  // if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('selected color : ', selected_color); }
  // msg = truncate(msg);
  // let el = document.createElement('div');
  // el.setAttribute('class', 'status-positive');
  // el.className = 'status-positive';
  // el.innerHTML = msg;
  // setTimeout(() => {
  //   el.parentNode.removeChild(el);
  // }, duration);
  // document.body.appendChild(el);
  msg = truncate(msg);
  toast.success(msg , {
    position: "top-right",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
  });
};
function truncate(source) {
  let size = 100;
  return source.length > size ? source.slice(0, size - 1) + '…' : source;
}

export const tempNegativeAlert = (msg, duration, selected_color) => {
  // if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('selected color : ', selected_color); }
  // let el = document.createElement('div');
  // el.setAttribute('class', 'status-negative');
  // el.className = 'status-negative';
  // el.innerHTML = msg;
  // setTimeout(() => {
  //   el.parentNode.removeChild(el);
  // }, duration);
  // document.body.appendChild(el);
  toast.warn(msg, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const copyToClipBoard = (message, copyValues, duration, selected_color) => {
  if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('selected color : ', selected_color); }
  let el = document.createElement('div');
  el.setAttribute('class', 'status-negative');
  el.className = 'copytoclipboard';
  el.innerHTML = copyValues;
  copy(message);
  setTimeout(() => {
    el.parentNode.removeChild(el);
  }, duration);
  document.body.appendChild(el);

  // toast.success(copyValues + ' - ' + message, {
  //   position: "bottom-right",
  //   autoClose: 1500,
  //   hideProgressBar: false,
  //   closeOnClick: true,
  //   pauseOnHover: false,
  //   draggable: false,
  //   progress: undefined,
  // });
};

export const errorHandling = (errorObject) => {
  try {
    if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Issue is : ', errorObject); }
    // tempNegativeAlert(Constants.ALERT_TECHNICAL_ERROR, 5000, Constants.RED_COLOR);  
    toast.warn(Constants.ALERT_TECHNICAL_ERROR + errorObject.data.messages[0], {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } catch (error) {
    toast.error(error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
 
};

export const valueValidation = (selectedValue) => {
  if (selectedValue !== null && selectedValue !== '' && selectedValue !== undefined) {
    return selectedValue;
  } else {
    return false;
  }
}

export const cleintNameEnvironment = (token) => {
  if (valueValidation(token)) {

  }
}
export const devtokenFormat = (token) => {
  let tokenFormat = `curl --location --request GET "${process.env.REACT_APP_BASE_URL}/v1/distributors/3" \
--header "Authorization: Bearer ${token}"`
  return tokenFormat
}

export const distStatusColor = (value) => {
  let distValue = ''
  switch (value) {
    case 'D':
      distValue = "#1B5E20"
      break;
    case 'S':
      distValue = "#E65100"
      break;
    case 'T':
      distValue = "#C62828"
      break;
    case 'C':
      distValue = "#1565C0"
      break;
    case 'P':
      distValue = "#7B1FA2"
      break;
    default:
      distValue = "#FFFFFF"
      break;
  }
  return distValue
}

export const  getMonthDays= (MonthYear) => {
  if(allNullTypeCheck(MonthYear)){
    var months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    var Value= MonthYear.split(" ");
    console.log('month number', Value) 
    if(months.indexOf(Value[0]) !== -1){
      var month = (months.indexOf(Value[0]) + 1);   
      // console.log('month number', month)   
      if(month < 10){
        month = '0'+ month
      }
      return month +'/'+ Value[1]
    }
    else{
      return '00/0000'
    }     
  }
  else 
  return '00/0000'
}

export const stringTruncate= (str, len) => {
  return str?.length > len ? str.substring(0, len) + '...' : str;
}
export const numberWithCommas=(num)=>{
  return allNullTypeCheck(num)? num?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ','):num;
}

export const getRankName = (selectedValue, dataArray) => {
  let eleName =''
  if (allNullTypeCheck(selectedValue) && allNullTypeCheck(dataArray) && dataArray.length>0) {
    dataArray.filter((ele) => {
      if(ele?.id === selectedValue){
        eleName = ele?.name         
      }
    })
  }
  return eleName;
}
export const nameProfileText = (str) => {
  let fullName = ''
  if(str.indexOf('-') !== -1){
   let strr =  str.split('-')
   fullName =  strr[1]?.substring(0, 2)
  }
  else{
   fullName= str.split(' ').map(item => item.substring(0, 2)).join(' ');
  }
  return fullName.toUpperCase();
}
