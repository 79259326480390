import React from 'react';
import './WebhooksInfo.css';
import AddWebhook from '../AddWebhook/index';
import Modal from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Card, Button } from 'semantic-ui-react';
import * as Constants from '../../../constants/Constants';
import PropTypes from 'prop-types';
import actions from '../../../redux/actions/CommonDataActions';
import { Oval } from "react-loader-spinner";
import { getIntegrationAPI, getWebhookIntegrationAPI } from '../../../redux/thunk/integrationAPIThunk';
import { errorHandling, valueValidation } from '../../utilities/Services';
import AuthService from '../../server/AuthService';
import Layer1inactivewebhook from "../../../assets/Layer1inactivewebhook.svg";
import addIcon from '../../../assets/addnewwebhk.svg';

class WebhooksInfo extends React.Component {
  constructor(props) {
    super(props)
    this.handleFinishWebhooks = this.handleFinishWebhooks.bind(this); 
    this.handleBackAction = this.handleBackAction.bind(this);
    this.state = {
      openWebhook: false,
      closeWebhook: false,
      activeResult: false,
      deactiveResult: false,
      showPopup: false,
      isWebhookCreated: false,
      WebhooksList: [],
      activeWebhooks: [],
      deactiveWebhooks: [],
      integrationName: '',
      webhookType: Constants.TEXT_NEW_WEBHOOK,
      selectedWebhook: {},
      isWebhooks: true,
    }
  }
  componentDidMount() {
    this.props.showLoader();
    if (AuthService.isTokenValidCheck() === true) {
      this.props.callgetWebhookIntegrationAPI(this.props.currentIntegrationID);
      this.setState({activeWebhooks: [], deactiveWebhooks: [], WebhooksList: []})
    }
  }
  componentDidUpdate(nextProps) {
    if (this.props.getwebhookIntegrationResposne !== nextProps.getwebhookIntegrationResposne) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Get webhook integration ", this.props.getwebhookIntegrationResposne) }
      this.props.hideLoader();
      if (this.props.getwebhookIntegrationResposne.error === false) {
        if (this.props.getwebhookIntegrationResposne.data.length > 0) {
                    //REF:- https://www.cluemediator.com/sort-an-array-by-date-in-javascript
         const sortedwebhooks = this.props.getwebhookIntegrationResposne.data.slice().sort((a, b) => b.modifiedDate > Date.now ? 1: -1);
         console.log('sortedwebhooks', sortedwebhooks)
         this.setState({ WebhooksList: sortedwebhooks });
          this.setState({ isWebhooks: false });
          this.props.getwebhookIntegrationResposne.data.forEach((webhook) => {
            if (webhook.active === 1) {
              this.state.activeWebhooks.push(webhook);
            } else if (webhook.active === 0) {
              this.state.deactiveWebhooks.push(webhook);
            }
          });
          console.log('active webhooks', this.state.activeWebhooks)
          console.log('deactive webhooks', this.state.deactiveWebhooks)
         // this.setState({ activeWebhooks : this.state.activeWebhooks, deactiveWebhooks: this.state.deactiveWebhooks})
        }
      }
    } else if ((this.props.getwebhookIntegrationError !== nextProps.getwebhookIntegrationError)) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Get integration error ", this.props.getIntegrationError) }
      this.props.hideLoader();
      errorHandling(this.props.getwebhookIntegrationError);
    }
  }
  handleFinishWebhooks() {
   // this.props.webhooksbackfunchandle(Constants.TEXT_WEBHOOKS, this.props.params);
  }
  handleBackAction() {
   // this.props.webhooksbackfunchandle(Constants.TEXT_WEBHOOKS, this.props.params);
  }
  truncate(str) {
    return str.length > 18 ? str.substring(0, 18) + '...' : str;
  }
  editEventHandle(webhook) {
    this.setState({
      showPopup: !this.state.showPopup
    });   
    this.setState({selectedWebhook:webhook});    
    this.setState({webhookType:Constants.TEXT_EDIT_WEBHOOK})  
  }
  openAddWebhook() {
    this.setState({
      showPopup: !this.state.showPopup
    });
  }
  togglePopupWebhook() {
    this.setState({activeWebhooks:[]});
    this.setState({deactiveWebhooks:[]});  
    this.setState({
      showPopup: !this.state.showPopup
    });
    this.props.showLoader();
    this.setState({isWebhookCreated:true});
    this.setState({selectedWebhook:{}});
    this.setState({webhookType:Constants.TEXT_NEW_WEBHOOK})
    if (AuthService.isTokenValidCheck() === true) {
      this.props.callgetWebhookIntegrationAPI(this.props.currentIntegrationID);
    }
  }

  render() {
    let loader = ''
    if (this.props.isFetchingData) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('loader called '); }
      loader = <div id="center" style={{ position: 'fixed', bottom: '40%', left: '48%' }}>
        <Oval
          type="Puff"
          position="fixed"  secondaryColor="#E85557"
          color="#E85557"
          height={50}
          width={50}
          timeout={5000}
        />
      </div>;
    }
    return (
      <div className="frame-2367" style={{height: this.state.isWebhooks === true ? '429px' : '910px'}}>
      <div className="frame-2385">
        <div className="flex-col-wi">
          <div className="events-webhooks valign-text-middle h3-header-3--18pt-bold-lato" style={{fontSize: "18px"}}>Events &amp; Webhooks</div>
          <p className="text-1-wi paragraph-1--14pt-regular-lato">
            <span className="span-wi paragraph-1--14pt-regular-lato">
              Webhooks are an easy and efficient way for Box to provide you with updates about what happens in a user’s
              account. <br />
              <br />
              <br />
              <br />
                         
              Webhooks are created and managed via our integrations. Check out the{" "}
            </span>            
            <span className="span1-wi paragraph-1--14pt-regular-lato">Webhooks Guide</span>            
            <span className="span paragraph-1--14pt-regular-lato">
              {" "}
              for more info.
              <br />
              <br />
              <br />
              <br />
                                  
              {/* Click on the ‘+’ icon to create a new webhook under this integration. */}
            </span>
          </p>
        </div>

        { this.state.isWebhooks === true ? <div className="add-webhook-card" onClick={this.openAddWebhook.bind(this)}>
          <div className="add-create-integration1">
            <div className="overlap-group-wi">
            <img className= "vector-1-wi" src ={addIcon} alt=''/>
              {/* <img
                className="vector-1-wi"
                src="https://anima-uploads.s3.amazonaws.com/projects/607ff5beb95afd36a6b5e6c3/releases/60814525f4556b65c033cbd7/img/vector-1@2x.png"
              alt=''/>
              <img className="vector-3-wi" src="https://anima-uploads.s3.amazonaws.com/projects/607ff5beb95afd36a6b5e6c3/img/vector-265@2x.png" alt=''/>
              <img className="vector-wi" src="https://anima-uploads.s3.amazonaws.com/projects/607ff5beb95afd36a6b5e6c3/img/vector-266@2x.png" alt=''/>
              <img className="vector-2-wi" src="https://anima-uploads.s3.amazonaws.com/projects/607ff5beb95afd36a6b5e6c3/img/vector-267@2x.png" alt=''/> */}
            </div>
          </div>
          <p className="text-2-wi h4-header-4--16pt-bold-lato" style={{fontSize: "14px"}} >ADD A NEW WEBHOOK</p>
        </div>
          : <div className="flex-col-1-wl">
            <div className="add-webhook-card-wl card-layot-1" onClick={this.openAddWebhook.bind(this)}>
              <div className="add-create-integration1-wl">
                <div className="overlap-group3-wl">
                  <img className= "vector-wl" src ={addIcon} alt=''/>
                  {/* <img
                    className="vector-wl"
                    src="https://anima-uploads.s3.amazonaws.com/projects/607ff5beb95afd36a6b5e6c3/img/vector-286@2x.png" alt=''/>
                  <img className="vector-1-wl" src="https://anima-uploads.s3.amazonaws.com/projects/607ff5beb95afd36a6b5e6c3/img/vector-265@2x.png" alt=''/>
                  <img className="vector-2-wl" src="https://anima-uploads.s3.amazonaws.com/projects/607ff5beb95afd36a6b5e6c3/img/vector-266@2x.png" alt=''/>
                  <img className="vector-3-wl" src="https://anima-uploads.s3.amazonaws.com/projects/607ff5beb95afd36a6b5e6c3/img/vector-267@2x.png" alt=''/>*/}
                </div> 
              </div>
              <div className="click-here-to-add-a h4-webhk-title-css">ADD A NEW WEBHOOK</div>
            </div>
            
            <div className="activated-webhooks valign-text-middle paragraph-1--14pt-bold-lato">Activated Webhooks</div>
            {this.state.activeWebhooks.length > 0 && this.state.WebhooksList.length > 0 ?
              this.state.WebhooksList.map((integration, key) => {
                return <div key={key}>
                  {integration.active ?
                    <div className="overlap-group-wl-1 border-1px-alto" style={{border: "1px solid #DDDDDD", cursor: 'pointer', boxSizing:'border-box', boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.05)'}} onClick={this.editEventHandle.bind(this, integration)}>
                      <div className="webhook-name valign-text-middle h4-webhk-title-css">{(integration.name).substr(0,30)}</div>
                    </div>: ''}
                </div> 
              }) : <div className="overlap-group-wl-1 border-1px-alto" style={{border: "1px solid #DDDDDD"}} >
              <div className="webhook-name-no valign-text-middle h4-webhk-title-css">No Active Webhooks Yet.</div>
            </div>}
            <div className="text-1-wl valign-text-middle">Disabled Webhooks</div>
            {this.state.deactiveWebhooks.length > 0 && this.state.WebhooksList.length > 0 ?
              this.state.WebhooksList.map((integration, key) => {
                return <div key={key}>
                  {(!integration.active) ?
                     <div className="overlap-group2-wl border-1px-alto" style={{border: "1px solid #DDDDDD", cursor: 'pointer', backgroundColor: '#DDDDDD', boxSizing:'border-box', boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.05)'}} onClick={this.editEventHandle.bind(this, integration)}>
                     <div className="webhook-name-4 valign-text-middle h4-webhk-title-css" style={{color:'gray'}}>{(integration.name).substr(0,30)}</div>
                     <div className="layer-1-wi">
                       <div className="overlap-group3-1-wl">
                        <img className="inactive-wb" src={Layer1inactivewebhook} alt=''/>
                       </div>
                     </div>
                   </div> : ''}
                </div>
              }) : <div className="overlap-group2-wl border-1px-alto" style={{border: "1px solid #DDDDDD", backgroundColor: '#DDDDDD', boxSizing:'border-box', boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.05)'}}>
                <div className="webhook-name-no valign-text-middle h4-webhk-title-css" style={{color:'gray'}}>
                No De-Active Webhooks Yet.
              </div>
            </div>}
           
            <div className="flex-row-wl">
              <div className="i16632163-5361126 component ">
              </div>
            </div>
          </div>}
        {this.state.showPopup ? <AddWebhook val={this.state.showPopup}
           webhookType={this.state.webhookType}
           selectedWebhook ={this.state.selectedWebhook}
           name={this.state.integrationName}
           integrationID = {this.props.currentIntegrationID}
           closePopup={this.togglePopupWebhook.bind(this)}
           webhooksbackfunchandle={this.handleBackAction.bind(this)}
           webhookhandleFinish={this.handleFinishWebhooks.bind(this)}
            /> : null}
        {loader}
      </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    isFetchingData: state.commonDataReducer.isFetchingData,
    currentIntegrationID: state.integrationDataReducer.currentIntegrationID,
    currentIntegrationRes: state.integrationDataReducer.currentIntegrationRes,
    getIntegrationResponse: state.integrationDataReducer.getIntegrationResponse,
    getIntegrationError: state.integrationDataReducer.getIntegrationError,
    getwebhookIntegrationResposne: state.integrationDataReducer.getwebhookIntegrationResposne,
    getwebhookIntegrationError: state.integrationDataReducer.getwebhookIntegrationError,
  };
};
const mapDispatchToProps = dispatch => ({
  showLoader: () => dispatch({ type: actions.FETCHING_DATA_LOADER, payload: true }),
  hideLoader: () => dispatch({ type: actions.HIDE_DATA_LOADER, payload: false }),
  callGetIntegrationAPI: (integrationID) => dispatch(getIntegrationAPI(integrationID)),
  callgetWebhookIntegrationAPI: (integrationID) => dispatch(getWebhookIntegrationAPI(integrationID, '')),

});

export default connect(mapStateToProps, mapDispatchToProps)(WebhooksInfo);

// class AddWebhookCard extends React.Component {
//   render() {
//     const { text2, addCreateIntegration1Props } = this.props;

//     return (
//       <div className="add-webhook-card border-1px-grey-3">
//         <AddCreateIntegration1
//           vector2={addCreateIntegration1Props.vector2}
//           vector3={addCreateIntegration1Props.vector3}
//           vector4={addCreateIntegration1Props.vector4}
//         />
//         <p className="text-2-wi h4-header-4--16pt-bold-lato">{text2}</p>
//       </div>
//     );
//   }
// }


// class AddCreateIntegration1 extends React.Component {
//   render() {
//     const { vector2, vector3, vector4 } = this.props;

//     return (
//       <div className="add-create-integration1">
//         <div className="overlap-group-wi">
//           <img
//             className="vector-1-wi"
//             src="https://anima-uploads.s3.amazonaws.com/projects/607ff5beb95afd36a6b5e6c3/releases/60814525f4556b65c033cbd7/img/vector-1@2x.png"
//           />
//           <img className="vector-3-wi" src={vector2} />
//           <img className="vector-wi" src={vector3} />
//           <img className="vector-2-wi" src={vector4} />
//         </div>
//       </div>
//     );
//   }
// }

// const addCreateIntegration1Data = {
//     vector2: "https://anima-uploads.s3.amazonaws.com/projects/607ff5beb95afd36a6b5e6c3/img/vector-265@2x.png",
//     vector3: "https://anima-uploads.s3.amazonaws.com/projects/607ff5beb95afd36a6b5e6c3/img/vector-266@2x.png",
//     vector4: "https://anima-uploads.s3.amazonaws.com/projects/607ff5beb95afd36a6b5e6c3/img/vector-267@2x.png",
// };

// const addWebhookCardData = {
//     addCreateIntegration1Props: addCreateIntegration1Data,
// };

