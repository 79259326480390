import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Oval } from "react-loader-spinner";
import { Modal } from "react-bootstrap";
import vector13 from '../../../assets/vector-13@2x.png';
import '../AddCompareRun/AddCompareModal.css'
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Radio from '@material-ui/core/Radio';
import { getCommissionRunAPIList, COMMISSION_SELECTED_RUN, getCommissionPeriodListAPI } from "../actions";
import leftarrow from "../assets/leftarrow.svg";
import rightarrow from "../assets/rightarrow.svg";
import {getMonthDays, stringTruncate}  from '../../utilities/Services'
import { isNullResponse, allNullTypeCheck } from "../commonComponents/UtilityFun";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import gotoarrow from "../assets/gotoarrow.svg";
import * as Constants from '../../../constants/Constants';
import Moment from 'moment';
import * as Constant from "../commonComponents/Constants";
import { Tooltip } from '@material-ui/core';

const styles = theme => ({
    tableRow: {
     '&&:hover': {
        backgroundColor: '#0CB5F3',
      },
     },
  });
const fields = [
    { name: "Type", displayName: "Type", inputFilterable: true, sortable: true },
    {
        name: "name",
        displayName: "Name",
        inputFilterable: false,
        exactFilterable: true,
        sortable: true,
    },
    {
        name: "createdOn",
        displayName: "Created On",
        inputFilterable: false,
        exactFilterable: true,
        sortable: true,
    },
    {
        name: "programs",
        displayName: "Programs",
        inputFilterable: false,
        sortable: true,
    },

];

class AddCompareModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoaderResult: true,
      showRemoveColModal: true,
      hideModal: false,
      createdRun: null,
      currentRunDetails: "",
      showRunDetails: false,
      disableNewRun: true,
      commissionRunState: [],
      selectedCommissionType: "Primary",
      commissionPeriods: [],
      selectedCommissionPeriodIndex: 0,
      rowstatus: false,
      selectedCommissionRun: "",
      displayPerioPop: false,
      anchorEl: false,
      commissionTypes: [],
      periodValue: "",
      totalRuns: [],
      commissionRunListState: [],
      retryDelayTime:1,
      retryDelayTime1:1
    };
  }

  componentDidMount() {
    const { title } = this.props?.commissionTypeValue;
    this.props.getCommissionRunList();
    if (title !== null) {
      this.props.getCommissionPeriod(title);
    }
  }
  componentDidUpdate(nextProps) {
    const {
      commissionRunList,
      commissionPeriodList,
      commissionTypeValue,
      commissionPeriodError,
    } = this.props;
    const { retryDelayTime, retryDelayTime1 } =  this.state;
    const { title } = this.props?.commissionTypeValue;
    if (nextProps.commissionRunList !== commissionRunList) {
      if(!commissionRunList.hasOwnProperty('status')){ 
        this.setState({ showLoaderResult: false });
        if (isNullResponse(commissionRunList?.data) && commissionRunList?.error===false) {
          if (commissionRunList.error === false) {
            console.log("Runs sucess : ", commissionRunList);
            this.setState({
              commissionRunListState: commissionRunList?.data?.runs,
            });
          } else {
            this.props.showSnackbar({
              snackbarOpen: true,
              snackbarType: Constants.SNACKBAR_TYPE.error,
              snackbarMessage: commissionRunList.messages[0],
            });
          }
        } else if(Object.keys(commissionRunList?.data).length===0 && allNullTypeCheck(commissionRunList?.messages)) {
          if(commissionRunList?.messages?.length > 0 && commissionRunList?.messages[0]?.includes("Try again...")){
              console.log("Try again block entered : ",commissionRunList?.data);
              setTimeout(() => {
                this.props.getCommissionRunList();
              }, 1000 * retryDelayTime);
              this.setState(prevState => {
                return {retryDelayTime: prevState.retryDelayTime * 2}
            })
          }
        }
      }else {
        if(commissionRunList.hasOwnProperty('status') && commissionRunList.status===202){ 
          setTimeout(() => {
            this.props.getCommissionRunList();
          }, 1000 * retryDelayTime);
          this.setState(prevState => {
            return {retryDelayTime: prevState.retryDelayTime * 2}
        })
        }
      }
    }
    if (nextProps.commissionPeriodList !== commissionPeriodList) {
      if(!commissionPeriodList.hasOwnProperty('status')){ 
        if (
          isNullResponse(commissionPeriodList.data) && commissionPeriodList.error === false)
          {
          this.setState({ showLoaderResult: false });
          if(isNullResponse(commissionPeriodList?.data?.periods)){
            let commPeriods = null;
            commPeriods =
              commissionPeriodList?.data?.periods[
                commissionTypeValue?.title?.toString()?.toLowerCase()
              ];
            console.log("periods with selected type :", commPeriods);
            this.setState(
              {
                commissionPeriods: commPeriods,
                // selectPeriodVal: getMonthDays(commPeriods[0].name),
              },
              () => {
                if (
                  commPeriods.length > 0 &&
                  allNullTypeCheck(commissionTypeValue?.defaultPeriod)
                ) {
                  this.selectPeriodIndex(commissionTypeValue?.defaultPeriod);
                } else {
                  this.setState({ selectedCommissionPeriodIndex: 0 });
                }
              }
            );
            console.log("commPeriods:", commPeriods);
            console.log("Typeof:" + typeof commPeriods);
            console.log(
              "name:" +
                commPeriods[this.state.selectedCommissionPeriodIndex]?.name
            );
            console.log(
              "start:" +
                commPeriods[this.state.selectedCommissionPeriodIndex]?.start
            );
          }else{
            this.setState({
              commissionPeriods: [],
            });
          }
        } else if(Object.keys(commissionPeriodList?.data).length===0 && allNullTypeCheck(commissionPeriodList?.messages)) {
          if(commissionPeriodList?.messages?.length > 0 && commissionPeriodList?.messages[0]?.includes("Try again...")){
              console.log("Try again block entered : ",commissionPeriodList?.data);
              setTimeout(() => {
                if (title !== null) {
                  this.props.getCommissionPeriod(title);
                }
              }, 1000 * retryDelayTime1);
              this.setState(prevState => {
                return {retryDelayTime1: prevState.retryDelayTime1 * 2}
            })
          }
        } else {
          console.log("Getting empty periods or error : ", commissionPeriodList);
          this.setState({
            commissionPeriods: [],
          });
        }
      } else {
        if(commissionPeriodList.hasOwnProperty('status') && commissionPeriodList.status===202){
          this.setState({ showLoaderResult: true });
          setTimeout(() => {
            if (title !== null) {
              this.props.getCommissionPeriod(title);
            }
          }, 1000 * retryDelayTime1);
          this.setState(prevState => {
            return {retryDelayTime1: prevState.retryDelayTime1 * 2}
        })
       }
    }  
    } else if (commissionPeriodError !== nextProps.commissionPeriodError) {
      console.log("Period list Error : ", commissionPeriodError);
    }
  }
  getNextPeriod() {
    console.log("getNextPeriod()");
    this.setState((prevState, props) => ({
      selectedCommissionPeriodIndex:
        prevState.selectedCommissionPeriodIndex + 1,
    }));
    console.log("getNextPeriod()=" + this.state.selectedCommissionPeriodIndex);
    this.updateSelectPeriod(this.state.selectedCommissionPeriodIndex + 1);
  }
  getPreviousPeriod() {
    console.log("getPreviousPeriod()");
    console.log(
      "getPreviousindex",
      this.state.selectedCommissionPeriodIndex,
      "length comm periods",
      this.state.commissionPeriods.length - 1
    );
    this.setState((prevState, props) => ({
      selectedCommissionPeriodIndex:
        prevState.selectedCommissionPeriodIndex - 1,
    }));
    console.log(
      "getPreviousPeriod()=" + this.state.selectedCommissionPeriodIndex
    );
    this.updateSelectPeriod(this.state.selectedCommissionPeriodIndex + -1);
  }
  filterById(jsonObject, id) {
    return jsonObject.filter(function (jsonObject) {
      return jsonObject["runId"] == id;
    })[0];
  }
  hideModal() {
    this.setState({ hideModal: true, showRemoveColModal: false });
    this.props.addCompareBackfunc();
  }
  confirmModal() {
    this.setState({ showLoaderResult: true });
    this.hideModal();
    this.props.selectedRun(this.state.selectedCommissionRun);
  }
  radioSelected(commissionRun, event) {
    console.log(
      "add compare selected run : ",
      event.target.value,
      commissionRun.runId
    );
    // this.props.selectedRun(commissionRun.id);
    this.setState({
      rowstatus: event.target.value,
      disableNewRun: false,
      selectedCommissionRun: commissionRun.runId,
    });
  }
  handleClick = (event) => {
    if (allNullTypeCheck(event.currentTarget)) {
      this.setState({
        anchorEl: event.currentTarget,
        displayPerioPop: true,
        periodValue: "",
      });
    }
  };
  handleClose = () => {
    this.setState({
      anchorEl: null,
      displayPerioPop: false,
    });
  };
  handlePeriodInput = (input) => {
    if (allNullTypeCheck(input.target)) {
      console.log("input is", input.target);
      const { value } = input.target;
      if (value) {
        this.setState({ periodValue: value.length === 3 && !value.includes("/")
        ? `${value.substring(0, 2)}/${value.substring(2)}`
        : value });
      } else {
        this.setState({ periodValue: "" });
      }
    }
  };
  onJumpSelectPeriod = () => {
    const { periodValue } = this.state;
    const { commissionRunList } = this.props;
    this.setState({ anchorEl: false });
    console.log("selcted period ", periodValue);
    if (periodValue) {
      this.selectPeriodIndex(periodValue);
    } else {
      this.setState({ periodValue: "" });
      this.setState({ commissionRunState: commissionRunList });
    }
  };

  selectPeriodIndex(value) {
    const { totalRuns, commissionPeriods } = this.state;
    const { commissionRunList } = this.props;
    this.setState({ totalRuns: [], commissionRunState: [] }, () => {});
    if (
      commissionPeriods.length > 0 &&
      isNullResponse(commissionRunList.data) && value !== '00/0000'
    ) {
      const indexValue = commissionPeriods.findIndex((data) => {
        return getMonthDays(data.name) === value;
      });
      console.log("selected index : ", indexValue, commissionRunList);
      if (allNullTypeCheck(indexValue) && indexValue !== -1) {
        this.setState({ selectedCommissionPeriodIndex: indexValue }, () => {});
        commissionRunList?.data?.runs?.filter((data) => {
          if (data.period === value && allNullTypeCheck(data?.lastRun)) {
            totalRuns.push(data);
          }
        });
        this.setState({ commissionRunState: [] }, () => {
          this.setState({ commissionRunState: totalRuns });
        });
      } else if (indexValue === -1) {
        return;
      }
    }
    else{
      this.setState({ commissionRunState: [], selectedCommissionPeriodIndex: 0 }, () => {});
    }
  }
  updateSelectPeriod(index) {
    const { commissionPeriods } = this.state;
    if (commissionPeriods.length > 0) {
      this.selectPeriodIndex(getMonthDays(commissionPeriods[index]?.name));
    }
  }
  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.onJumpSelectPeriod();
    }
  };
  render() {
    let loader;
    const {
      showRemoveColModal,
      hideModal,
      showLoaderResult,
      disableNewRun,
      commissionRunState,
      displayPerioPop,
      anchorEl,
      selectedCommissionPeriodIndex,
      periodValue,
    } = this.state;
    const { commissionTypeValue } = this.props;
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    if (showLoaderResult) {
      loader = (
        <div
          id="center"
          style={{ position: "fixed", bottom: "40%", left: "48%" }}
        >
          <Oval
            type="Puff"
            position="fixed"
            secondaryColor="#E85557"
            color="#E85557"
            height={50}
            width={50}
            timeout={3000}
          />
        </div>
      );
    }
    return (
      <div>
        <Modal
          dialogClassName="comparerun-my-modal"
          show={showRemoveColModal}
          enforceFocus={false}
        >
          <Modal.Header>
            <div className="addcmpr-flex-row-1-ei">
              <div className="add-cmnr-header-name cmnr-h3-header-3-18pt-bold-lato">
                Add run to compare
              </div>
              <img
                className="addcmpr-vector-ei"
                src={vector13}
                onClick={this.hideModal.bind(this)}
                alt=""
              />
            </div>
          </Modal.Header>
          <Modal.Body
            style={{ height: commissionRunState.length > 0 ? "" : "450px" }}
          >
            <div class="col">
              <div class="row add-cmnr-runtype">
                <div className="cm-type-title">
                  Toggle between the periods to view the runs in that period
                </div>
              </div>
              <div class="row addcmpr-type-select">
                <div class="col-6 period-style-type">
                  <div
                    className="cd-text-temp"
                    style={{ textDecorationLine: "none" }}
                  >
                    <div
                      onClick={this.getPreviousPeriod.bind(this)}
                      disabled={
                        this.state.selectedCommissionPeriodIndex > 0
                          ? false
                          : true
                      }
                    >
                      <img src={leftarrow} alt="" style={{ cursor: "pointer" }}/>{" "}
                    </div>
                    <span
                      className="compare-model-selected-period"
                      name="periodvalues"
                      variant="contained"
                      onClick={this.handleClick}
                      style={{cursor:'pointer'}}
                    >
                      {this.state.commissionPeriods.length > 0
                        ? getMonthDays(
                            this.state.commissionPeriods[
                              this.state.selectedCommissionPeriodIndex
                            ].name
                          )
                        : "00/0000"}
                    </span>
                    <div
                      onClick={this.getNextPeriod.bind(this)}
                      disabled={
                        this.state.selectedCommissionPeriodIndex ===
                        this.state.commissionPeriods.length - 1
                          ? true
                          : false
                      }
                    >
                      <img
                        src={rightarrow}
                        alt=""
                        style={{ cursor: "pointer" }}
                      />{" "}
                    </div>
                    {displayPerioPop ? (
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={this.state.anchorEl}
                        onClose={this.handleClose.bind(this)}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                      >
                        <Typography sx={{ p: 2 }}>
                          {this.state.commissionPeriods.length > 0 ? (
                            <div className="selectcpdetails">
                              <div className="cmperiod-select">
                                Last Commission Period:{" "}
                                {commissionTypeValue?.lastCommissionPeriod}
                              </div>
                              <div className="cmperiod-select">
                                Start Date:{" "}
                                { Moment(this.state.commissionPeriods[
                                    selectedCommissionPeriodIndex
                                  ].start).format('MM/DD/YYYY')
                                }
                              </div>
                              <div className="cmperiod-select">
                                End Date:{" "}
                                {
                                  Moment(this.state.commissionPeriods[
                                    selectedCommissionPeriodIndex
                                  ].end).format('MM/DD/YYYY')
                                 }
                              </div>
                              <div className="cmperiod-select">
                                Default Check Date:{" "}
                                 {
                                   Moment(this.state.commissionPeriods[
                                    selectedCommissionPeriodIndex
                                  ].checkDate).format('MM/DD/YYYY')
                                  }{" "}
                              </div>
                              <div className="cmperiod-line"></div>
                              <div className="jumpto">
                                Jump To:
                                <input
                                  className="col-4 jumpperiod-options"
                                  name="periodvalue"
                                  type="text"
                                  placeholder="MM/YYYY"
                                  value={periodValue}
                                  maxLength={7}
                                  onChange={this.handlePeriodInput.bind(this)}
                                  onKeyPress={this.handleKeyPress}
                                ></input>
                                <img
                                  src={gotoarrow}
                                  style={{
                                    marginLeft: "8px",
                                    cursor: "pointer",
                                  }}
                                  alt=""
                                  onClick={this.onJumpSelectPeriod}
                                />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </Typography>
                      </Popover>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div className="row add-table-heading">
                  Select a run from the list below to compare
                </div>
                <TableContainer className="add-comparerun-model-container">
                  <Table sx={{ minWidth: 450 }} aria-label="simple table">
                    <TableHead>
                      {fields.map((row) => (
                        <TableCell
                          className="runmanager-table-header"
                          component="th"
                          scope="row"
                          style={{ backgroundColor: "#DDDDDD" }}
                        >
                          {row.displayName}
                        </TableCell>
                      ))}
                    </TableHead>
                    <TableBody>
                      {commissionRunState.length > 0 ? (
                        commissionRunState.map((commissionrun, i) => (
                         commissionrun?.message?.toLocaleLowerCase() !== Constant.RUNSTATUS_NOT_STARTED.toLocaleLowerCase() 
                          && commissionrun?.message?.toLocaleLowerCase() !== Constant.RUNSTATUS_QUALIFICATION.toLocaleLowerCase() && commissionrun?.message?.toLocaleLowerCase() !== Constant.RUNSTATUS_QUALIFICATION_PHASE.toLocaleLowerCase() ? 
                          <TableRow key={commissionrun.name} hover>
                            <TableCell className="addcomparerun-data addcmpr-table">
                              <div className="row add-item-style">
                                <Radio
                                  value={commissionrun.name}
                                  defaultSelected={false}
                                  checked={
                                    commissionrun?.name !== this.state.rowstatus
                                      ? false
                                      : true
                                  }
                                  onChange={this.radioSelected.bind(
                                    this,
                                    commissionrun
                                  )}
                                />
                                {commissionrun?.runType === "Analysis"
                                  ? "A"
                                  : "P"}
                              </div>
                            </TableCell>
                            {commissionrun?.name?.length >= 15 ? <Tooltip title= {commissionrun?.name} placement="top" arrow={true}>
                            <TableCell className="addcmpr-runmanager-name addcmpr-table">
                              {stringTruncate(commissionrun?.name, 15)}
                            </TableCell>
                            </Tooltip> :  <TableCell className="addcmpr-runmanager-name addcmpr-table space-wrap">
                              {commissionrun?.name}
                            </TableCell>}
                            <TableCell className="addcmpr-runmanager-name addcmpr-table space-wrap">
                            {Moment(commissionrun?.created).format('MM/DD/YYYY') !== 'Invalid date' ? Moment(commissionrun?.created).format('MM/DD/YYYY') : ''}
                            </TableCell>
                            <TableCell className="addcmpr-runmanager-name addcmpr-table space-wrap">
                              {/* {commissionrun.calculationProgram} */}
                              {commissionrun?.qualificationProgram + ', '+
                                commissionrun?.calculationProgram + ', '+
                                commissionrun?.disbursementProgram}
                            </TableCell>
                          </TableRow> : ''
                        ))
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={4}
                            align="center"
                            height={260}
                            className="norunscell infotext-style"
                          >
                            <span>No Runs In This Period</span>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="row">
              <div
                disabled={disableNewRun}
                style={{
                  opacity: disableNewRun === true ? 0.3 : 1.0,
                  backgroundColor: disableNewRun === true ? "#3333" : "#D93030",
                  cursor: "pointer",
                }}
                className="cmnr-create-run"
                onClick={this.confirmModal.bind(this)}
              >
                <div
                  className="add-run-type-btn"
                  style={{ color: disableNewRun === true ? "gray" : "white" }}
                >
                  ADD RUN
                </div>
              </div>
            </div>
          </Modal.Footer>
          {loader}
        </Modal>
      </div>
    );
  }
}
AddCompareModal.propTypes = {
  defaultvalues: PropTypes.any,
  addCompareBackfunc: PropTypes.func,
};
const mapStateToProps = (state) => {
  return {
    commissionRunList: state.commissionRunReducer.commissionRunList,
    commissionPeriodList: state.commissionToolsReducer.commissionPeriodList,
    commissionPeriodError: state.commissionToolsReducer.commissionPeriodError,
  };
};
const mapDispatchToProps = dispatch => ({
    getCommissionRunList: () => dispatch(getCommissionRunAPIList()),
    selectedRunIndex: (selectedRun) =>
      dispatch({ type: COMMISSION_SELECTED_RUN, payload: selectedRun }),
    getCommissionPeriod:(periodtype) =>  dispatch(getCommissionPeriodListAPI(periodtype)),

})
export default connect(mapStateToProps, mapDispatchToProps)(AddCompareModal);