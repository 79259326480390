import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from "react-bootstrap";
import './AddWebhook.css';
import { Input } from 'reactstrap';
import { copyToClipBoard, errorHandling } from '../../utilities/Services';
import { connect } from 'react-redux';
import actions from '../../../redux/actions/CommonDataActions';
import { getGenerateKeyAPI, getEventsListAPI, postEndPointValidation, postWebhookAPI, putWebhookAPI } from '../../../redux/thunk/integrationAPIThunk';
import * as Constants from '../../../constants/Constants';
import EventsList from '../EventsList/index';
import vector13 from '../../../assets/vector-13@2x.png';
import line45 from '../../../assets/line-45@1x.png';
import vector1 from '../../../assets/vector-1@2x.png';
// import Switch from 'react-toggle-switch';
import Switch from '@mui/material/Switch';
import { Oval } from "react-loader-spinner";
import vector310 from '../../../assets/vector-310@2x.png';
import AuthService from '../../server/AuthService';
import rectangle285 from '../../../assets/rectangle-285@2x.png';
import path225 from '../../../assets/path-225@2x.png';
import copy from '../../../assets/copy.svg';
import externalLink from '../../../assets/externalLink.svg';
import info from '../../../assets/info.svg';
import NewSnackbar from "../../utilities/NewSnackbar";
import Button from '@material-ui/core/Button';

class AddWebhook extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      closeModal: false,
      openModal: this.props.val,
      endPointUrl: '',
      primaryKey: '',
      showEvents: false,
      eventsList: [],
      actionHandled: 'primarykey',
      selectedEventsList: [],
      validate: {
        validWebhookName: '',
        descriptionState: '',
        eventTypeState: '',
        endPointState: '',
      },
      isEventsEnabled: false,
      endpointServiceValidation: false,
      webhookShow: false,
      webhookSwitched: false,
      webhookName: '',
      description: '',
      integrationswitched: false,
      webhookTypeTitle: 'Add a New webhook',
      errorWebhookName: false,
      webhookNameValidation: false,
      secondaryKey: 'secondaryKey',
      endPointState: '',
      endpointValidationFeedBack: '',
      ednpointURLError: false,
      errorWebhookDescription: false,
      descriptionValidation: '',
      eventsName: [],
      tempValidateURL: '',
      nameCharecterLength: 0,
      nameMaxVlue: '',
      descriptionMaxVlue: '',
      descriptionTextLength: 0,
      buttonDiable: false,
      nameInputcolor: '#DDDDDD',
      descrInputColor: '#DDDDDD',
      endpointInputColor: '#DDDDDD',
      eventsInputColor: '#DDDDDD'
    }
  }
  componentDidMount() {
    if (this.props.webhookType === Constants.TEXT_NEW_WEBHOOK) {
      if (AuthService.isTokenValidCheck() === true) {
      this.props.callGenerateKeyAPI();
      this.props.callEventListAPI();
      this.setState({buttonDiable: false})
      }
     // console.log('New webhook')
    } else {
      console.log('edited', this.props.selectedWebhook)
      if (AuthService.isTokenValidCheck() === true) {
      this.props.callEventListAPI();
      }
      if (this.props.selectedWebhook !== null && this.props.selectedWebhook !== undefined) {
        this.setState({ webhookName: this.props.selectedWebhook.name, nameCharecterLength:this.props.selectedWebhook.name.length })
        this.setState({ primaryKey: this.props.selectedWebhook.primaryKey });
        this.setState({ description: this.props.selectedWebhook.description, descriptionTextLength: this.props.selectedWebhook.description.length });
        this.setState({ endPointUrl: this.props.selectedWebhook.endPoint });
        this.setState({ webhookTypeTitle: Constants.TEXT_EDIT_WEBHOOKS });
        this.setState({buttonDiable: false})
        this.setState({ tempValidateURL: this.props.selectedWebhook.endPoint })
        if (this.props.selectedWebhook.secondaryKey !== '' && this.props.selectedWebhook.secondaryKey !== undefined) {
          this.setState({ secondaryKeyDisplay: true })
          this.setState({ secondaryKey: this.props.selectedWebhook.secondaryKey });
        }
        this.setState({ endpointServiceValidation: true });
        this.setState({ selectedEventsList: this.props.selectedWebhook.eventTypeList.split(',') });

        if (this.props.selectedWebhook.active) {
          this.setState({ webhookShow: true })
          this.setState({ webhookSwitched: true })
        }
        if(this.props.selectedWebhook.description.length === 200){
          this.setState({ descriptionMaxVlue: true })
        } if(this.props.selectedWebhook.name.length === 30){
          this.setState({ nameMaxVlue: true })
        }
      }
    }
  }
  componentDidUpdate(nextProps) {
    if (this.props.createWebhookResponse !== nextProps.createWebhookResponse) {
      if (this.props.createWebhookResponse.error === false) {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Create webhook: ", this.props.createWebhookResponse.data); }
        this.props.closePopup();
         this.props.hideLoader();
      } else if (this.props.createWebhookResponse.error) {
        this.props.showSnackbar({snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.warning, snackbarMessage: Constants.ALERT_TECHNICAL_ERROR})
        this.setState({buttonDiable: false })
      }
    } else if ((this.props.createWebhookError !== nextProps.createWebhookError) && (this.props.createWebhookError)) {
      // this.props.hideLoader();
      this.setState({buttonDiable: false })
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("call Endpoint Error : ", this.props.createWebhookError); }
      // if ((this.props.createWebhookError.status === 401) || (this.props.createWebhookError.status === 403)) {
      //     tempNegativeAlert(Constants.ALERT_TECHNICAL_ERROR,5000,Constants.RED_COLOR);                     
      // } else if ((this.props.createWebhookError.status === 500) || (this.props.createWebhookError.status === 405) || (this.props.createWebhookError.status !== 200)) {
      //     tempNegativeAlert(Constants.ALERT_TECHNICAL_ERROR,5000,Constants.RED_COLOR);  
      // }
      errorHandling(this.props.createWebhookError);
    }
    if (this.props.editWebhookResponse !== nextProps.editWebhookResponse) {
      if (this.props.editWebhookResponse.error === false) {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Create webhook: ", this.props.editWebhookResponse.data); }
        this.props.closePopup();
         this.props.hideLoader();
      } else if (this.props.editWebhookResponse.error) {
        this.props.showSnackbar({snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.warning, snackbarMessage: Constants.ALERT_TECHNICAL_ERROR})
      }
    } else if ((this.props.editWebhookError !== nextProps.editWebhookError) && (this.props.editWebhookError)) {
      // this.props.hideLoader();
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("call Endpoint Error : ", this.props.editWebhookError); }
      errorHandling(this.props.editWebhookError);
    }
    if (this.props.eventsTypeResponse !== nextProps.eventsTypeResponse) {
      if (this.props.eventsTypeResponse.error === false) {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Events response : ", this.props.eventsTypeResponse.data); }
        // this.setState({eventsList:this.props.eventsTypeResponse.data})       
        this.props.eventsTypeResponse.data.forEach((event, i) => {
          let childrenlist = [];
          let parentEvent = { label: event.event_type_name, value: event.event_type_name, active: false };
          this.props.eventsTypeResponse.data.forEach((childEvent, j) => {
            if (event.event_type_name === childEvent.event_type_name) {
              let child = { label: childEvent.eventDescription, value: childEvent.eventType, active: false };
              childrenlist.push(child);
            }
          })
          //console.log("events response we got", childrenlist)
          parentEvent[Constants.PARAM_CHILDREN] = childrenlist;
          // if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log("Child Events  : ",parentEvent);}        
          if (this.state.eventsList.length > 0) {
            let eventExisting = false
            this.state.eventsList.forEach((existingEvent) => {
              if (event.event_type_name === existingEvent.label) {
                eventExisting = true;
              }
            })
            if (eventExisting === false) {
              this.state.eventsList.push(parentEvent);
            }
          } else {
            this.state.eventsList.push(parentEvent);
          }
        })

        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Selected db events : ", this.props); }
        if (this.props.selectedWebhook.eventTypeList !== "" && this.props.selectedWebhook.eventTypeList !== undefined) {
          this.state.eventsList.forEach((events) => {
            let childrenLenth = 0;
            events.children.forEach((childEvent) => {
              this.props.selectedWebhook.eventTypeList.split(',').forEach((dbEvent) => {
                if (dbEvent === childEvent.value) {
                  childEvent[Constants.ACTIVE] = true;
                  ++childrenLenth;
                }
              })
              if (childrenLenth === events.children.length) {
                events[Constants.ACTIVE] = true;
              }
            })

          })

          //  console.log("eventsListData", this.state.eventsList)
          this.state.eventsList.forEach(ele => {
            ele.children.forEach((child) => {
              if (child.active === true) {
                this.state.eventsName.push({ childValue: child.value, childName: child.label });
              }
            })
          })
          this.setState({ eventsName: this.state.eventsName })
          // console.log("selected events list after edit",   this.state.eventsName)
          // this.setState({selectedEventsList:this.state.eventsList});
        }
      } else if (this.props.eventsTypeResponse.error) {
        this.props.showSnackbar({snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.warning, snackbarMessage: Constants.ALERT_TECHNICAL_ERROR})
      }
    } else if (this.props.eventsTypeError !== nextProps.eventsTypeError) {
      // this.props.hideLoader();
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("call eventsTypeError Error : ", this.props.eventsTypeError); }
      errorHandling(this.props.eventsTypeError);
    }

    if (this.props.generateKeyResponse !== nextProps.generateKeyResponse) {
      if (this.props.generateKeyResponse.error === false) {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("generateKeyResponse ", this.props.generateKeyResponse.data); }
        if (this.state.actionHandled === Constants.PRIMARYKEY_ACTION) {
          this.setState({ primaryKey: this.props.generateKeyResponse.data.key });
        } else {
          this.setState({ secondaryKey: this.props.generateKeyResponse.data.key });
        }
        // this.props.hideLoader();
      } else if (this.props.generateKeyResponse.error) {
        this.props.showSnackbar({snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.warning, snackbarMessage: Constants.ALERT_TECHNICAL_ERROR})
      }
    } else if (this.props.generateKeyHasError !== nextProps.generateKeyHasError) {
      // this.props.hideLoader();
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("call Endpoint Error : ", this.props.generateKeyHasError); }
      // if ((this.props.generateKeyHasError.status === 401) || (this.props.generateKeyHasError.status === 403)) {
      //     tempNegativeAlert(Constants.ALERT_TECHNICAL_ERROR,5000,Constants.RED_COLOR);                     
      // } else if ((this.props.generateKeyHasError.status === 500) || (this.props.generateKeyHasError.status === 405) || (this.props.generateKeyHasError.status !== 200)) {
      //     tempNegativeAlert(Constants.ALERT_TECHNICAL_ERROR,5000,Constants.RED_COLOR);  
      // }
      errorHandling(this.props.generateKeyHasError);
    }
    if (this.props.endpointResponse !== nextProps.endpointResponse) {
      // const { validate } = this.state;
      if (this.props.endpointResponse.error === false) {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("endpointResposne ", this.props.endpointResponse.data); }
        if (this.props.endpointResponse.data === true) {
          this.setState({ showloaderState: false })
          this.props.showSnackbar({snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.success, snackbarMessage: Constants.TEXT_VALIDURL_SUCCESS})
          //validate.endPointState = 'has-success';
          // this.setState({endPointState: 'has-success'});
          this.setState({ endpointServiceValidation: true, tempValidateURL: this.state.endPointUrl, endpointInputColor: '#27ae60' });
          // this.setState({ ValidationFeedBack: Constants.VALIDATION_ENDPOINT_SUCCESS });          
        } else {
          this.setState({ showloaderState: false })
          //  this.setState({endPointState: 'has-danger'});
          // validate.endPointState = 'has-danger';
          // this.setState(validate);
          this.setState({ endpointServiceValidation: false, endpointInputColor: '#d93030'});
          // this.setState({ ValidationFeedBack: Constants.VALIDATION_ENTER_VALID_ENDPOINT });
          this.props.showSnackbar({snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.error, snackbarMessage: Constants.TEXT_INVALID_ENDPOINT_URL})
        }
      } else if (this.props.endpointResponse.error) {
        this.setState({ showloaderState: false })
        // validate.endPointState = 'has-danger';
        // this.setState(validate);
        this.setState({ endpointServiceValidation: false });
        // this.setState({ ValidationFeedBack: Constants.VALIDATION_ENTER_VALID_ENDPOINT });  
        this.props.showSnackbar({snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.warning, snackbarMessage: Constants.ALERT_TECHNICAL_ERROR})
      }
    } else if (this.props.endpointError !== nextProps.endpointError) {
      this.setState({ showloaderState: false })
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("call Endpoint Error : ", this.props.endpointError); }
      errorHandling(this.props.endpointError);
    }
  }

  closeAddWebhook() {
    this.setState({ closeModal: true, openModal: false })
    this.props.closePopup();
  }
  copyPrimaryKey(copyValue) {
    if (copyValue) {
      copyToClipBoard(this.state.primaryKey, 'Primary Key Copied', 3000, Constants.RED_COLOR);
      this.props.showSnackbar({snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.info, snackbarMessage: Constants.TEXT_COPYTOCLIPBOARD})
    }
  }
  validateEndURL(event) {
    // alert(value)
    if (this.state.endPointUrl === '' || this.state.endPointUrl.length === 0) {
      // console.log('this.state.endPointUrl', event)
      this.props.showSnackbar({snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.error, snackbarMessage: Constants.VALIDATION_ENTER_VALID_ENDPOINT})
      this.setState({ ednpointURLError: true, endpointInputColor: '#d93030' });
      this.setState({ endpointValidationFeedBack: Constants.VALIDATION_ENTER_VALID_ENDPOINT });
      return false;
    }
    if (this.state.endPointUrl.length > 0) {
      this.setState({ showloaderState: true })
      let params = {};
      let updatedEndpoint = ''
      if (this.state.endPointUrl.includes(Constants.TEXT_HTTPS_ENDPOINT)) {
        updatedEndpoint = this.state.endPointUrl.replace(Constants.TEXT_HTTPS_ENDPOINT, Constants.TEXT_HTTP_ENDPOINT);
        // this.setState({ endpoint : updatedEndpoint});            
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('http updated endpoint : ', this.state.endPointUrl, updatedEndpoint); }
      } else if (!this.state.endPointUrl.includes(Constants.TEXT_HTTP_ENDPOINT)) {
        updatedEndpoint = Constants.TEXT_HTTP_ENDPOINT + this.state.endPointUrl;
        //  this.setState({endpoint:Constants.TEXT_HTTP_ENDPOINT+this.state.endPointUrl});
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('updated endpoint : ', this.state.endPointUrl, updatedEndpoint); }
      } else {
        updatedEndpoint = this.state.endPointUrl;
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('else updated endpoint : ', updatedEndpoint); }
      }
      params[Constants.PARAM_ENDPOINTURL] = updatedEndpoint
      if (AuthService.isTokenValidCheck() === true) {
      this.props.callEndpointValidationAPI(params);
      }
    }
    // console.log('ednpointURLError', this.state.ednpointURLError)
  }
  oneditEndpointURL(event) {
    this.setState({ endPointUrl: event.target.value, ednpointURLError: false, endpointInputColor: '#DDDDDD' })
    console.log('existing url', this.state.tempValidateURL, 'actual update one', event.target.value, 'endpointurl', this.state.endPointUrl)
    if (this.state.tempValidateURL !== event.target.value) {
      this.setState({ endpointServiceValidation: false })
      console.log('endpoint need to validate')
    }
    else {
      this.setState({ endpointServiceValidation: true })
    }
  }
  showEventsData() {
    this.setState({ isEventsEnabled: true })
  }
  selectedEvents(selectedEvents) {
    this.setState({ eventsList: selectedEvents });
    console.log("selected events list", this.state.eventsName, selectedEvents)
    selectedEvents.forEach((event) => {
      event.children.forEach((child) => {
        if (child.active === true) {
          this.state.selectedEventsList.push(child.value);
          // childValue = child.label 
          const exists = this.state.eventsName.find(p => p.childValue === child.value);
          if (exists) { return; }
          else {
            this.state.eventsName.push({ childValue: child.value, childName: child.label });
          }
        } else {
          this.removeEvent(child.value)
        }
      })
    })
   if(this.state.selectedEventsList.length > 0){
     this.setState({eventsInputColor: '#27ae60'})
   }

    this.setState({ selectedEventsList: Array.from(new Set(this.state.selectedEventsList)) });
    this.setState({ eventsName: Array.from(new Set(this.state.eventsName)) })
  }
  regeneratePrimaryKey(actionType) {
    this.setState({ actionHandled: actionType })
    this.props.callGenerateKeyAPI();
  }

  handleEventsList() {
    // console.log("handle events list", this.props.selectedWebhook)   
    this.setState({
      isEventsEnabled: !this.state.isEventsEnabled
    });
    if (this.state.isEventsEnabled === false) {
      this.setState({ selectedEventsList: this.props.selectedWebhook.eventsList })
    }

  }
  removeEvent(eventId) {
    this.state.eventsName.forEach(ele => {
      if (ele.childValue === eventId) {
        let index = this.state.eventsName.indexOf(ele)
        this.state.eventsName.splice(index, 1)
      }
    })
    this.state.eventsList.forEach(data => {
      data.children.forEach(ele => {
        if (ele.value === eventId) {
          ele.active = false
          data.active = false
        }
      })
    })
    this.setState({ eventsName: this.state.eventsName })
  }

  integrationtoggleSwitch() {
    this.setState(prevState => {
      console.log('switch state', prevState)
      return {
        webhookSwitched: !prevState.webhookSwitched,
        webhookShow: !prevState.webhookShow
      };
    });
  }

  handleNewWebhook(e) {
    
    if (!this.validation()) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("validation failed : "); }
      e.preventDefault();
    } else {
      this.props.showLoader()
      this.setState({buttonDiable: true})
      let updatedEndpoint;
      let webhookParams = {};
      if (this.state.endPointUrl.includes(Constants.TEXT_HTTPS_ENDPOINT)) {
        updatedEndpoint = this.state.endPointUrl.replace(Constants.TEXT_HTTPS_ENDPOINT, Constants.TEXT_HTTP_ENDPOINT);
        // this.setState({ endpoint : updatedEndpoint});            
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('http updated endpoint : ', this.state.endPointUrl, updatedEndpoint); }
      } else if (!this.state.endPointUrl.includes(Constants.TEXT_HTTP_ENDPOINT)) {
        updatedEndpoint = Constants.TEXT_HTTP_ENDPOINT + this.state.endPointUrl;
        //  this.setState({endpoint:Constants.TEXT_HTTP_ENDPOINT+this.state.endPointUrl});
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('updated endpoint : ', this.state.endPointUrl, updatedEndpoint); }
      } else {
        updatedEndpoint = this.state.endPointUrl;
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('else updated endpoint : ', updatedEndpoint); }
      }
      let eventValue = []
      this.state.eventsName.forEach(ele => {
        eventValue.push(ele.childValue)
      })
      console.log("event Values final", eventValue.toString())
      webhookParams[Constants.PARAM_INTEGRATIONID] = this.props.integrationID;
      webhookParams[Constants.PARAM_NAME] = this.state.webhookName;
      webhookParams[Constants.PARAM_DESCRIPTION1] = this.state.description;
      webhookParams[Constants.PARAM_EVENTTYPES] = eventValue.toString();
      webhookParams[Constants.PARAM_ENDPOINTURL] = updatedEndpoint;
      webhookParams[Constants.PARAM_PRIMARYKEY] = this.state.primaryKey;
      //webhookParams[Constants.PARAM_SECONDARYKEY] = this.state.secondaryKey;   

      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Webhook edit parameters : ", webhookParams); }
      if (AuthService.isTokenValidCheck() === true) {
      this.props.callWebhookPostAPI(webhookParams);
     }
    }
  }
  handleEditWebhook(e) {

    if (!this.validation()) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("validation failed : "); }
      e.preventDefault();
    } else {
      this.props.showLoader()
      let updatedEndpoint;
      let webhookParams = {};
      if (this.state.endPointUrl.includes(Constants.TEXT_HTTPS_ENDPOINT)) {
        updatedEndpoint = this.state.endPointUrl.replace(Constants.TEXT_HTTPS_ENDPOINT, Constants.TEXT_HTTP_ENDPOINT);
        this.setState({ endPointUrl: updatedEndpoint });
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('http updated endpoint : ', this.state.endPointUrl, updatedEndpoint); }
      } else if (!this.state.endPointUrl.includes(Constants.TEXT_HTTP_ENDPOINT)) {
        updatedEndpoint = Constants.TEXT_HTTP_ENDPOINT + this.state.endPointUrl;
        this.setState({ endPointUrl: Constants.TEXT_HTTP_ENDPOINT + this.state.endPointUrl });
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('updated endpoint : ', this.state.endPointUrl, updatedEndpoint); }
      } else {
        updatedEndpoint = this.state.endPointUrl;
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('else updated endpoint : ', updatedEndpoint); }
      }
      let eventValue = []
      this.state.eventsName.forEach(ele => {
        eventValue.push(ele.childValue)
      })
      webhookParams[Constants.PARAM_INTEGRATIONID] = this.props.integrationID;
      webhookParams[Constants.PARAM_WEBHOOKID] = this.props.selectedWebhook.webhookId;
      webhookParams[Constants.PARAM_NAME] = this.state.webhookName;
      webhookParams[Constants.PARAM_DESCRIPTION1] = this.state.description;
      webhookParams[Constants.PARAM_EVENTTYPES] = eventValue.toString();// this.state.selectedEventsList.toString();
      webhookParams[Constants.PARAM_ENDPOINTURL] = updatedEndpoint;
      webhookParams[Constants.PARAM_PRIMARYKEY] = this.state.primaryKey;
      // webhookParams[Constants.PARAM_SECONDARYKEY] = ''; 
      webhookParams[Constants.PARAM_ACTIVE] = this.state.webhookShow ? 1 : 0;

      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Webhook parameters : ", webhookParams); }
      if (AuthService.isTokenValidCheck() === true) {
      this.props.callWebhookPutAPI(this.props.integrationID, this.props.selectedWebhook.webhookId, webhookParams);
      }
    }
  }
  validation() {
    if (this.state.webhookName === '' || this.state.webhookName.length === 0) {
      this.setState({ errorWebhookName: true, nameInputcolor: '#d93030'});
      this.setState({ webhookNameValidation: Constants.VALIDATION_WEBHOOK_NAME });
      this.props.showSnackbar({snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.error, snackbarMessage: Constants.VALIDATION_WEBHOOK_NAME})
      return false;
    }
    if (this.state.webhookName.indexOf(' ') >= 0) {
      this.setState({ errorWebhookName: true, nameInputcolor: '#d93030'});
      this.setState({ webhookName: this.state.webhookName });
      this.setState({ webhookNameValidation: Constants.VALIDATION_WEBHOOK_NAME_SPACE });
      this.props.showSnackbar({snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.error, snackbarMessage: Constants.VALIDATION_WEBHOOK_NAME_SPACE})
      return false;
    }
    if (this.state.eventsName.length === 0) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("valid events list   : ", this.state.selectedEventsList); }
       this.setState({ eventsInputColor: '#d93030' });
      this.props.showSnackbar({snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.error, snackbarMessage: Constants.EVENTTYPE_VALIDATION})
      return false;
    }
    if (this.state.endPointUrl === '' || this.state.endPointUrl.length === 0) {
      this.setState({ ednpointURLError: true });
      this.setState({ endpointValidationFeedBack: Constants.VALIDATION_ENTER_ENDPOINT });
      this.props.showSnackbar({snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.error, snackbarMessage: Constants.VALIDATION_ENTER_ENDPOINT})
      return false;
    } else if ((this.state.endpointServiceValidation === false) && (this.state.endPointUrl.length > 0)) {
      this.setState({ ednpointURLError: true });
      this.setState({ endpointValidationFeedBack: Constants.VALIDATE_ENDPOINT });
      this.props.showSnackbar({snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.error, snackbarMessage: Constants.VALIDATE_ENDPOINT})
      return false;
    }
    return true;
  }
  oneditIntegrationNameEdit = (event) => {
    var value = event.target.value;
    if (value.length <= 30) {
      this.setState({ webhookName: value, errorWebhookName: false, nameCharecterLength: value.length, nameInputcolor: '#DDDDDD'})
      if(value.length === 30){
        this.setState({nameMaxVlue: true, nameInputcolor: '#27ae60'})
      }else{
        if(value.length > 0){
          this.setState({nameMaxVlue: false, nameInputcolor: '#27ae60'})
        }
        if(value.indexOf(' ') >= 0){
          this.setState({ nameInputcolor: '#d93030'}) 
        }
      }
    }
  }
  oneditDescriptionChange = (event) => {
    // console.log('target value',e.target)
    var value = event.target.value;
    if (value.length > 200) {
      // tempNegativeAlert(Constants.TEXT_DESCRIPTION_PLACEHOLDER,5000,Constants.RED_COLOR);
      return value
    }
    else {
      this.setState({ description: value , descriptionTextLength: value.length, descrInputColor: '#DDDDDD'})
      if(value.length === 200){
        this.setState({descriptionMaxVlue: true, descrInputColor: '#27ae60'})
    }
    else{
      if(value.length > 0){
        this.setState({descriptionMaxVlue: false})
        this.setState({descrInputColor: '#27ae60'})
      }
    }
    }

  }
  render() {
    let loader = ''
    if (this.props.isFetchingData) {
      loader = <div id="center" style={{ position: 'fixed', top: '50%', left: '50%' }}>
        <Oval
          type="Puff"
          position="fixed"  secondaryColor="#E85557"
          color="#d93030"
          height={40}
          width={40}
          timeout={3000}
        />
      </div>;
    }
    let webhookSwitch = ''
    if (this.props.webhookType === Constants.TEXT_EDIT_WEBHOOK) {
      webhookSwitch = <div className="group-2628-wbhk">
        <div className="group-42-addwbhk">
          <div className="overlap-group1 swith-alignment">
            {/* <Col sm={5} style={{flex:'1'}} className="newGeneral-inputedit"> */}
            {/* <Switch onClick={this.integrationtoggleSwitch.bind(this)} on={this.state.webhookSwitched} >
              <i className="some-icon" />
            </Switch> */}
            <Switch onClick={this.integrationtoggleSwitch.bind(this)}
              id="waiver-edit-screen-switch" checked={this.state.webhookSwitched}>
                                  <i className="some-icon" />
            </Switch>                                  
          </div>
        </div>
        {this.state.webhookShow ? <div className="enable-edit-wbhk" style={{opacity: '1.0'}}> Enable </div> : <div className="enable-edit-wbhk">Disable </div>}
      </div>
    }
    else {
      webhookSwitch = <div className="group-2628-wbhk">
        <div className="group-42">
          <div className="group-42-addwbhk">
            {/* <Col sm={5} style={{flex:'1'}} className="newGeneral-inputedit"> */}
          </div>
        </div>
      </div>
    }
    return (
      <Modal show={this.state.openModal} onHide={this.state.closeModal}>
        <div className="container-center-horizontal">
          <form className="pop-up screen" name="form1" action="form1" method="post">
            <div className="flex-col-adw">
              <div className="flex-row-adw">
                <div className="add-a-new-webhook valign-text-middle h3-header-3--18pt-regular-lato" style={{ fontSize: "large" }}>
                  {this.state.webhookTypeTitle}
                </div>
                <div onClick={this.closeAddWebhook.bind(this)}>
                  <img className="vector-9-adw" src={vector13} alt="" />
                </div>
              </div>
              <img className="line-45-adw" src={line45} alt="" />
              <div className="flex-row-1-adw">
                <div className="place-adw paragraph-1--14pt-bold-lato" style={{opacity: this.state.webhookShow === false && this.props.webhookType === Constants.TEXT_EDIT_WEBHOOK ? '0.45': 'inherit'}}>Name</div>
                {webhookSwitch}
                <div className="endpoint-url valign-text-middle paragraph-1--14pt-bold-lato" style={{opacity: this.state.webhookShow === false && this.props.webhookType === Constants.TEXT_EDIT_WEBHOOK ? '0.45': 'inherit'}}>Endpoint URL</div>
                <div className="layer" style={{opacity: this.state.webhookShow === false && this.props.webhookType === Constants.TEXT_EDIT_WEBHOOK ? '0.45': 'inherit'}}>
                  <div className="overlap-group-adw">
                    <img className="vector-adw-11" src={info} alt='' />
                  </div>
                </div>
              </div>
              <div className="flex-row-2-adw" style={{opacity: this.state.webhookShow === false && this.props.webhookType === Constants.TEXT_EDIT_WEBHOOK ? '0.45': 'inherit'}}>
                {/* <Input123>Input text</Input123> */}
                <div className="x1720101 component" style={{ border: '1px solid #7b6f6f', borderColor: this.state.nameInputcolor}}>
                  <div className="text-1-DnNa1w valign-text-middle">
                    <Input type="search" placeholder="Input Text" value={this.state.webhookName} style={{ border: "none", width: "362px", paddingLeft: "0px", backgroundColor: this.state.webhookShow === false && this.props.webhookType === Constants.TEXT_EDIT_WEBHOOK ? 'white': 'inherit'}} name="integrationNames" onChange={this.oneditIntegrationNameEdit.bind(this)}
                      itemType="text" disabled={this.state.webhookShow === false && this.props.webhookType === Constants.TEXT_EDIT_WEBHOOK ? true : false}/>
                    {/* {this.state.errorWebhookName ? <i className="text-1-DnNa1w valign-text-middle" style={{ color: '#d93030', marginLeft: '145px', marginTop: '-11px' }}>{this.state.webhookNameValidation}</i> : ''} */}
                  </div>
                </div>
                <div className="webhook-name-leanth2 paragraph-1--14pt-regular-lato" style={{color: this.state.nameMaxVlue === true ? '#d93030': ''}}>{this.state.webhookName.length > 0 ? this.state.nameCharecterLength: ''}</div>
                {this.state.webhookName.length > 0 ? <div className="webhook-name-leanth1 paragraph-1--14pt-regular-lato" >/30</div>: '' }
                {/* <Input132>Input text</Input132> */}
                <div className="x17460 component" style={{ border: '1px solid #7b6f6f', borderColor:  this.state.endpointInputColor}}>
                  {/* <div className="text-2-gsrBWQ valign-text-middle"> */}
                  <Input type="text" itemType="text" placeholder="Input Text" value={this.state.endPointUrl} style={{ border: "none", width: "362px", backgroundColor: this.state.webhookShow === false && this.props.webhookType === Constants.TEXT_EDIT_WEBHOOK ? 'white': 'inherit' }} onChange={this.oneditEndpointURL.bind(this)} 
                  disabled={this.state.webhookShow === false && this.props.webhookType === Constants.TEXT_EDIT_WEBHOOK ? true : false} />
                  {/* {this.state.ednpointURLError ? <i  style={{ color: '#d93030', marginLeft: '500px', marginTop: '-11px' }}>{this.state.endpointValidationFeedBack}</i> : ''} */}
                  {/* </div> */}
                </div>
              </div>
            </div>
            <div className="flex-row-3-adw" style={{opacity: this.state.webhookShow === false && this.props.webhookType === Constants.TEXT_EDIT_WEBHOOK ? '0.45': 'inherit'}}>
              <div className="flex-col-1-adw">
                <div className="edit-description-adw paragraph-1--14pt-bold-lato">Description</div>
                <div className="overlap-group5-adw">
                  <div className="number-adw paragraph-2--12pt-bold-lato"></div>
                  <div className="rectangle-118 border-1px-alto" style={{ border: "1px solid", borderColor: this.state.descrInputColor }}>
                    {/* <p className="text-3-adw paragraph-1--14pt-regular-lato"> */}
                    <Input type="textarea" rows="5" cols="33" style={{ border: "none", height: '86px', paddingLeft: '0px', backgroundColor: this.state.webhookShow === false && this.props.webhookType === Constants.TEXT_EDIT_WEBHOOK ? 'white': 'inherit' }} className="text-3-adw paragraph-1--14pt-regular-lato" placeholder=" Webhook description is optional.(Click to edit)" value={this.state.description} name="integration description" onChange={this.oneditDescriptionChange.bind(this)}
                      itemType="textarea" disabled={this.state.webhookShow === false && this.props.webhookType === Constants.TEXT_EDIT_WEBHOOK ? true : false}/>
                    {/* {this.state.errorWebhookDescription ? <i className="number-adw paragraph-2--12pt-bold-lato" style={{ color: '#d93030', marginTop: '-30px', marginInlineStart: '50px'}}>{this.state.descriptionValidation}</i> : ''} */}
                  </div>
                  <div className="webhook-desc-leanth2 paragraph-1--14pt-regular-lato" style={{color: this.state.descriptionMaxVlue === true ? '#d93030': ''}}>{this.state.description.length > 0? this.state.descriptionTextLength: ''}</div>
                  {this.state.description.length > 0 ? <div className="webhook-desc-leanth1 paragraph-1--14pt-regular-lato" >/200</div>: '' }
                  {/* </p> */}
                </div>
                <div className="flex-row-4-adw">
                  <div className="primary-key valign-text-middle paragraph-1--14pt-bold-lato">Primary Key</div>
                  <div className={`layer`}>
                    <div className="overlap-group-adw">
                      <img className="vector-adw" src={info} alt='' />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-col-2-adw" style={{ cursor: 'pointer', opacity: this.state.webhookShow === false && this.props.webhookType === Constants.TEXT_EDIT_WEBHOOK ? '0.45': 'inherit'}} >
                <div className="i17463-5361126 component" onClick={this.validateEndURL.bind(this, this.state.endPointUrl)}>
                  <div className="i17463-5361126-6380 component">
                    <div className="button-i174635361-vlEK41 valign-text-middle" style={{ cursor: 'pointer'}}>VALIDATE</div>
                  </div>
                </div>
                <div className="flex-row-5-adw" >
                  <div className="event-subscription paragraph-1--14pt-regular-lato">Event Subscription</div>
                  <div className="external-link1">
                    <div className="overlap-group10-adw">
                      <img className="vector-10-adw" src={externalLink} alt="" />
                    </div>
                  </div>
                </div>
                <div className="group-2760" style={{ border: '1px solid #7b6f6f', borderRadius: '5px', borderColor: this.state.eventsInputColor }} onClick={this.showEventsData.bind(this)}>
                  <div className="left">
                    <div className="overlap-group7-adw">
                      <img className="vector-4-adw" src={vector1} alt="" />
                      <img className="vector-5-adw" src={vector310} alt="" />
                    </div>
                  </div>
                </div>
                <div className="flex-row-6-adw row">
                  {this.state.eventsName.map((element) => {
                    return (
                      <div className="overlap-group4-adw column">
                        <div className="event-name paragraph-2--12pt-regular-lato">{element.childName}</div>
                        <div>
                          <img className="rectangle-2" src={rectangle285} alt="" />
                          <img className="path-22" src={path225} alt='' onClick={this.removeEvent.bind(this, element.childValue)} />
                        </div>
                      </div>
                    )
                  })}
                </div>

              </div>
            </div>
            <div className="flex-col-3-adw">
              <div className="flex-row-7-adw" style={{opacity: this.state.webhookShow === false && this.props.webhookType === Constants.TEXT_EDIT_WEBHOOK ? '0.45': 'inherit'}}>
                <div className="code-copy-adw" style={{border: '1px solid #DDDDDD'}}>
                  <div className="text-i17 valign-text-middle code-14pt-bold-courier" style={{ fontSize: "12px", paddingLeft: "10px" }}>{this.state.primaryKey}</div>
                  {/* <Frame2378 vector2={frame2378Props.vector2} vector3={frame2378Props.vector3} /> */}
                  <div className="frame-2378-adw">
                    <div className="copy1-adw" style={{ cursor: "pointer" }} onClick={this.copyPrimaryKey.bind(this, this.state.primaryKey)}>
                      <div className="overlap-group7-1-adw">
                        <img className="vector-6-adw" src={copy} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <Component5172>REGENERATE</Component5172> */}
              <div className="i17450-5361126 component" style={{ cursor: 'pointer', opacity: this.state.webhookShow === false && this.props.webhookType === Constants.TEXT_EDIT_WEBHOOK ? '0.45': 'inherit' }} onClick={this.regeneratePrimaryKey.bind(this, Constants.PRIMARYKEY_ACTION)}>
                <div className="i17450-5361126-6380 component">
                  <div className="button-i174635361-vlEK41 valign-text-middle">REGENERATE</div>
                </div>
              </div>
              <div style={{ opacity: '0.45' }}>
                <div className="flex-row-8-adw">
                  <div className="secondary-key valign-text-middle paragraph-1--14pt-bold-lato">Secondary Key</div>
                  <div className="layer layer-1">
                    <div className="overlap-group-adw">
                      <img className="vector-adw" src={info} alt="" />
                    </div>
                  </div>
                  <div className="optional valign-text-middle paragraph-1--14pt-regular-lato">(Optional)</div>
                </div>
                <div className=" code-copy-adw code-copy code-copy-1-adw" style={{border: '1px solid #DDDDDD'}}>
                  <div className="text-i17 valign-text-middle code-14pt-bold-courier" style={{ fontSize: "12px", paddingLeft: "10px" }}>jfk287fojsdkj1sad4380shjxlse</div>
                  <div className="frame-2378-adw">
                    <div className="copy1-adw" style={{ cursor: "pointer" }}>
                      <div className="overlap-group7-1-adw">
                        <img className="vector-6-adw" src={copy} alt="" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="i17450-5361126 component">
                  <div className="i17450-5361126-6380 component">
                    <div className="button-i174505361-ix3X3c valign-text-middle">REGENERATE</div>
                  </div>
                </div> </div>
              <img className="line-46-adw" src={line45} alt="" />
              <div className="flex-row-9-adw">

                  <div className="i1720115-5361126-6380 component " onClick={this.closeAddWebhook.bind(this)} style={{ cursor: 'pointer' }}>
                    <div className="button-i1720115536-gR9vxS valign-text-middle">CANCEL</div>
                  </div>

                {this.props.webhookType === Constants.TEXT_EDIT_WEBHOOK ? <Button disabled={this.state.buttonDiable} style={{backgroundColor: '#d93030', marginLeft: '716px', width: '62px', height: '30px',  color: 'white', fontSize: '12px', fontWeight:700, fontFamily: 'Lato', lineHeight: '14px'}} 
                variant="contained" onClick={this.handleEditWebhook.bind(this)}>{Constants.SAVE}</Button>
                 :
                <Button disabled={this.state.buttonDiable} style={{backgroundColor: '#d93030', marginLeft: '716px', width: '62px', height: '30px',  color: 'white', fontSize: '12px', fontWeight:700, fontFamily: 'Lato', lineHeight: '14px'}} 
                variant="contained" onClick={this.handleNewWebhook.bind(this)}>ADD</Button>
                }
              </div>
            </div>
          </form>
          {loader}
          <NewSnackbar/>
          {this.state.isEventsEnabled ? <EventsList eventsList={this.state.eventsList} selectedEvents={this.selectedEvents.bind(this)} closePopup1={this.handleEventsList.bind(this)} /> : null}
        </div>
      </Modal>
    );
  }
}
AddWebhook.defaultProps = {
  closePopup: '',
};
AddWebhook.propTypes = {
  webhookhandleFinish: PropTypes.func,
  params: PropTypes.any,
  webhooksbackfunchandle: PropTypes.func,
  closePopup: PropTypes.any
}
const mapStateToProps = state => {
  return {
    isFetchingData: state.commonDataReducer.isFetchingData,
    currentIntegrationID: state.integrationDataReducer.currentIntegrationID,
    currentIntegrationRes: state.integrationDataReducer.currentIntegrationRes,
    eventsTypeResponse: state.integrationDataReducer.eventsTypeResponse,
    eventsTypeError: state.integrationDataReducer.eventsTypeError,
    generateKeyResponse: state.integrationDataReducer.generateKeyResponse,
    generateKeyHasError: state.integrationDataReducer.generateKeyHasError,
    endpointResponse: state.integrationDataReducer.endpointResponse,
    endpointError: state.integrationDataReducer.endpointError,
    createWebhookResponse: state.integrationDataReducer.createWebhookResponse,
    createWebhookError: state.integrationDataReducer.createWebhookError,
    editWebhookResponse: state.integrationDataReducer.editWebhookResponse,
    editWebhookError: state.integrationDataReducer.editWebhookError
  }
}
const mapDispatchToProps = dispatch => ({
  showLoader: () => dispatch({ type: actions.FETCHING_DATA_LOADER, payload: true }),
  hideLoader: () => dispatch({ type: actions.HIDE_DATA_LOADER, payload: false }),
  callGenerateKeyAPI: () => dispatch(getGenerateKeyAPI()),
  callEventListAPI: () => dispatch(getEventsListAPI()),
  callEndpointValidationAPI: (params) => dispatch(postEndPointValidation(params)),
  callWebhookPostAPI: (webhookparams) => dispatch(postWebhookAPI(webhookparams)),
  callWebhookPutAPI: (integrationID, webhookID, params) => dispatch(putWebhookAPI(integrationID, webhookID, params)),
  showSnackbar: (params) => dispatch({ type: actions.SNACKBAR_SHOW, payload: params}),
})
export default connect(mapStateToProps, mapDispatchToProps)(AddWebhook);

class Layer132 extends React.Component {
  render() {
    const { className } = this.props;

    return (
      <div className={`layer ${className || ""}`}>
        <div className="overlap-group-adw">
          <img
            className="vector-adw"
            src={info} alt= ''
          />
          {/* <img className="vector-1-adw" src={vector2} />
          <img className="vector-2-adw" src={vector3} />
          <img className="vector-3-adw" src={vector4} /> */}
        </div>
      </div>
    );
  }
}

