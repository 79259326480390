
const actions = {  
  SET_ID_TOKEN:'SET_ID_TOKEN',
  FETCHING_DATA_LOADER : 'FETCHING_DATA_LOADER',
  HIDE_DATA_LOADER : 'HIDE_DATA_LOADER',
  HEADER_DROPDWON_ACTION : 'HEADER_DROPDWON_ACTION',
  CHANGES_EXISTING : 'CHANGES_EXISTING',
  FETCHING_APPSLIST : 'FETCHING_APPSLIST',
  INVITE_POPUP : 'INVITE_POPUP',
  SELECTED_NAVITEM :'SELECTED_NAVITEM',
  REFRESHPAGE : 'REFRESHPAGE',
  SNACKBAR_SHOW: 'SNACKBAR_SHOW',
  SNACKBAR_HIDE: 'SNACKBAR_HIDE',
  FETCH_SELECTED_RUN_LIST: 'FETCH_SELECTED_RUN_LIST',
};
export default actions;

export const Auth = {
  USER_AUTHORIZED_SUCCESS : 'USER_AUTHORIZED_SUCCESS',  
  CLEAR_USER_DETAILS : 'CLEAR_USER_DETAILS',
  USER_AUTH_SET_DETAILS : 'USER_AUTH_SET_DETAILS',
  SHOW_SESSION_POP_UP : 'SHOW_SESSION_POP_UP',
  CLOSE_SESSION_POP_UP : 'CLOSE_SESSION_POP_UP',
  GET_USER_AUTH_DATA : 'GET_USER_AUTH_DATA',
  GET_USER_AUTH_DATA_SUCCESS : 'GET_USER_AUTH_DATA_SUCCESS'
};
