import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";

function TextArea(props) {

  const { _label, _name, _defaultValue, _value, _type, onChange, ...rest } = props;
  const onChangeValues=(e)=>{
    props.onChange(e,_type,_name,_label)
  }
  if(_label !== 'title' && _name !== 'title'){
  return (
    <div className='add-currency-popup-row'>
    <div className='input-container'>
      <label htmlFor={_name}>{_label}</label>
      <Field type='text' id={_name} onChange={onChangeValues} name={_name} value={_value} defaultValue={_defaultValue} {...rest}/>
      <ErrorMessage name={_name} component={TextError} />
    </div>
    </div>
  );
}
else return <div className='add-currency-popup-row'>
<div className='input-container'>
  <label htmlFor={_name}>{_label} : {_value}</label>
</div>
</div>
}

export default React.memo(TextArea);
