import React, { Fragment } from "react";
import addIcon from '../../assets/samplecme.svg';
import portalIcon from '../Commissions/assets/ceportalicon.svg';
import selectedMenu from '../Commissions/assets/selectedmenu.svg';
import commIcon from '../Commissions/assets/cecommicon.svg';
import AuthService from '../server/AuthService';
import flexcloudLogo from '../../assets/flexcloud-logo-logo-1@2x.png';
import Line40 from '../../assets/line-40@2x.png';
import ellipse12 from '../../assets/ellipse-12@2x.png';
import vector10 from '../../assets/vector-10@2x.png';
import vector12 from '../../assets/vector-12@2x.png';
import vector11 from '../../assets/vector-11@2x.png';
import image3 from '../../assets/image-3@2x.png';
import TopMenu from "../portalv3/TopMenu";
import '../homenav/Commissions.css'
import { gettokenScopeValidation, nameProfileText, valueValidation } from '../utilities/Services';
import appsdots from '../../assets/appsdots.svg';
import usermanageIcon from '../../assets/usermanagement.svg';
import usermngmtselected from '../../assets/usermngt-selected.svg';
import notificationIcon from '../../assets/notifications.svg';
import orgmanageIcon from '../../assets/orgmanagement.svg';
import notifSelected from '../../assets/notifications-selected.svg';
import orgmngmntselected from '../../assets/orgmngmt-selected.svg';
import appsSelected from '../../assets/appsdots-selected.svg';
import jwt from 'jwt-decode';
import {Oval} from 'react-loader-spinner';
import { Link, useParams, withRouter } from "react-router-dom";
import { allNullTypeCheck } from "../Commissions/commonComponents/UtilityFun";
import UserManagementDashBoard from "../usermanagement/usermanagementDashboard/UserManagementDashBoard";
// import PageNotFound from "../utilities/PageNotFound";
import { Box, Container, Grid } from '@mui/material';
import NotFound from "../../Notfound";

let selectedParam='';
class Commissions extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      clientName: '',
      clientENV: '',
      selectedParam : 'applications',
      userMngmt : false,
      notification:false,
      appsAction:false,
      orgmngmt:false,
      loader: true

    }
    this.className = 'menu';
  }
  // let className = 'menu';
  // if (this.props.isActive) {
  //   className += ' menu-active';
  // }
  
  componentDidMount() {
    this.getCleintNameEnv()
   // console.log("params is : ",this.props.location);
    this.getSelectedLinkType(allNullTypeCheck(this.props?.location?.search)? this.props.location?.search?.toString().split('=')[1]: selectedParam,true);
    this.setState({ selectedParam: allNullTypeCheck(this.props?.location?.search)? this.props.location?.search?.toString().split('=')[1]: selectedParam })
    if(!allNullTypeCheck(this.props?.location?.search)){
      this.setState({appsAction:true});
    }
    setTimeout(() => {
      this.setState({loader:false});
    }, 1000);
  }
  componentDidUpdate(nextProps){
    if(nextProps.location.search!==this.props.location.search && allNullTypeCheck(this.props.location.search)){
    //  console.log("called for prop changes: ",this.props.location);
      this.setState({selectedParam : allNullTypeCheck(this.props?.location?.search)? this.props.location?.search?.toString().split('=')[1]: selectedParam })
      this.getSelectedLinkType(allNullTypeCheck(this.props?.location?.search)? this.props.location?.search?.toString().split('=')[1]: selectedParam,true);
    }
  }
  getCleintNameEnv() {
    const id_token = localStorage.getItem('id_token');
    if (id_token !== "undefined" && valueValidation(id_token)) {
      const client = jwt(id_token);
      this.setState({ clientName: client.client_name })
      if (client.iss.includes('dev')) {
        this.setState({ clientENV: 'Dev Environment' })
      } else if (client.iss.includes('test')) {
        this.setState({ clientENV: 'Test Environment' })
      }
      else {
        this.setState({ clientENV: 'Live Environment' })
      }
    }
    else {
      console.log('Token is empty')
    }
  }
  navigareToPortal() {
    localStorage.setItem("appselect", "portal")
    window.location.pathname = "/portal"
  }
  navigareToCommissions() {
    window.location.pathname = '/commissions'
    localStorage.setItem("appselect", "commissions")
  }
  getSelectedLinkType=(param, actionChange)=>{
    console.log("selected param is : ",param);
    switch (param) {
      case "application":
          console.log("param is ",param)
          if(actionChange){
            this.setState({appsAction:true,notification:false,orgmngmt:false,userMngmt:false});
          }else{
            return (
              <Fragment>
              <div className="applications" style={{marginLeft:'5px'}}>Applications </div>
              <Grid xs={6} p={2} container>
                <Grid item className="client-apps-style">
                  {gettokenScopeValidation("/integrations") ? <img className="appcommissions" src={portalIcon} alt='' style={{ cursor: 'pointer' }} onClick={this.navigareToPortal} /> : null}
                </Grid>
                <Grid item className="client-apps-style">
                  {gettokenScopeValidation("/commissions") ? <img className="appcommissions" src={commIcon} alt='' style={{ cursor: 'pointer' }} onClick={this.navigareToCommissions} /> : ''}
                </Grid>
              </Grid>
            </Fragment>);
          }
          break;
      case "notifications":
           
            if(actionChange){
              this.setState({appsAction:false,notification:true,orgmngmt:false,userMngmt:false});
            }else{
              return (
                <Fragment>
               {this.clientNameCheck() ? <div className="applications">Notifications </div> : <NotFound/>}
                </Fragment>);
            }
            break;
      case 'usermanagement':
              if(actionChange){
                this.setState({appsAction:false,notification:false,orgmngmt:false,userMngmt:true});
              }else{
                return(
                  <Fragment>
                  {this.clientNameCheck() ? <UserManagementDashBoard/> : <NotFound/>}
                  </Fragment>
                )
              }
              break;
      case 'settings':
            if(actionChange){
              this.setState({appsAction:false,notification:false,orgmngmt:true,userMngmt:false});
            }else{
              // if(gettokenScopeValidation("/settings")){
                  return (
                    <Fragment>
                    {this.clientNameCheck() ? <div className="applications">Settings </div> : <NotFound/> }
                    </Fragment>);
              // }else {
              //   return (
              //     <PageNotFound message="Selected page not in your scope"/>
              //   )
              // }
            }
        break;
      default:
        return (
          <Fragment>
            <div className="applications" style={{marginLeft:'5px'}}>Applications </div>
            <Grid xs={6} p={2} container>
              <Grid item className="client-apps-style">
                {gettokenScopeValidation("/integrations") ? <img className="appcommissions" src={portalIcon} alt='' style={{ cursor: 'pointer' }} onClick={this.navigareToPortal} /> : null}
              </Grid>
              <Grid item className="client-apps-style">
                {gettokenScopeValidation("/commissions") ? <img className="appcommissions" src={commIcon} alt='' style={{ cursor: 'pointer' }} onClick={this.navigareToCommissions} /> : ''}
              </Grid>
            </Grid>
          </Fragment>);
    }

  }
  clientNameCheck =()=>{
    return (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('atomic') || process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost'))
  }
  render() {
    const { userMngmt, selectedParam, orgmngmt, appsAction, notification, loader } = this.state;
    const id_token = localStorage.getItem('id_token');
    if(loader){
      return <div><div id="center" style={{ position: 'fixed', top: '40%', left: '48%' }}>
    <Oval type="Puff" position="fixed"  secondaryColor="#E85557" color="#E85557" height={50} width={100} visible={true} /> </div></div>;
    }
    else
    if (AuthService.isAuthenticated()) {
      return (
        <div className="main">
          <TopMenu {...topMenuData} />
          <div className="row">
            <div className="col-3">
              <div className="rowheight row">
                <div className="col-3 " style={{ backgroundColor: "#F5F5F5", paddingRight: '0px'}}>
                <div class="container-landing title-geekscss">
                  <div className="geeks-testing">
                  <div className="centered-profiletxt">{ allNullTypeCheck(id_token) ? nameProfileText(jwt(id_token)?.client_name) : 'TE'} </div>
                  </div>
                  </div>
                </div>
                <div className="col-9" style={{ paddingLeft:'0px',paddingRight:'0px', backgroundColor:'white'}}>
                  <div className="row margin0px line-vc">
                    <div className="container-landing" style={{ paddingLeft:'15px',paddingRight:'0px' }}>
                      <img className="" src={selectedMenu} alt='' style={{ marginTop: '25px' }} />
                      <div className="centered-profiletxt1">{ allNullTypeCheck(id_token) ? nameProfileText(jwt(id_token)?.client_name) : 'TE'} </div>
                    </div>
                    <div className="padding0px">
                    <div className="flex row margin0px">
                      <div className="clinetnm">{this.state.clientName}</div>
                      <div className="clinetnm-1">{this.state.clientENV}</div>
                    </div>
                    </div>
                  </div>
                  {/* <div className="line-vc" style={{ marginTop: '10px' }}> </div> */}
                  <div className="flex-applications">
                    {/* <div className="flex-app-style" onClick={()=>
                      this.props.history.push({ pathname: '/home', search: 'application'})}>Applications</div> */}
                   {/* {gettokenScopeValidation("/identity/appscopes")?<Link className={ appsAction? "selected-flex-app-style" : "flex-app-style" } to={'/home?flextype=application'} ><img className="homenav-img-padding" src={appsAction? appsSelected:appsdots} alt='' />Applications </Link>:''}
                   {gettokenScopeValidation("/settings")?<Link className={ notification? "selected-flex-app-style" : "flex-app-style" } to={'/home?flextype=notifications'} ><img className="homenav-img-padding" src={ notification? notifSelected: notificationIcon} alt='' />Notifications </Link>:''}
                   {gettokenScopeValidation("/admin/settings")?<Link className={ orgmngmt? "selected-flex-app-style" : "flex-app-style" } to={'/home?flextype=settings'} ><img className="homenav-img-padding" src={orgmngmt? orgmngmntselected: orgmanageIcon} alt='' />Settings</Link>:''}
                   {gettokenScopeValidation("/identity/users")?<Link className={ userMngmt? "selected-flex-app-style" : "flex-app-style" } to={'/home?flextype=usermanagement'}><img className="homenav-img-padding" src={userMngmt? usermngmtselected:usermanageIcon} alt='' />User Management</Link>:''} */}
                   <Link className={ appsAction? "selected-flex-app-style" : "flex-app-style" } to={'/home?flextype=application'} ><img className="homenav-img-padding" src={appsAction? appsSelected:appsdots} alt='' />Applications </Link>
                   {this.clientNameCheck() ? <Link className={ notification? "selected-flex-app-style" : "flex-app-style" } to={'/home?flextype=notifications'} ><img className="homenav-img-padding" src={ notification? notifSelected: notificationIcon} alt='' />Notifications </Link> : ''}
                   {this.clientNameCheck() ? <Link className={ orgmngmt? "selected-flex-app-style" : "flex-app-style" } to={'/home?flextype=settings'} ><img className="homenav-img-padding" src={orgmngmt? orgmngmntselected: orgmanageIcon} alt='' />Settings</Link> : ''}
                   {this.clientNameCheck() ? <Link className={ userMngmt? "selected-flex-app-style" : "flex-app-style" } to={'/home?flextype=usermanagement'}><img className="homenav-img-padding" src={userMngmt? usermngmtselected:usermanageIcon} alt='' />User Management</Link> : ''}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-9 padding0px" style={{ backgroundColor: "#F5F5F5" }}>
              {/* <div className="applications">Applications </div>
              <img className="appportal" src={portalIcon} alt='' style={{ cursor: 'pointer' }} onClick={this.navigareToPortal} />
              <img className="appcommissions" src={commIcon} alt='' style={{ cursor: 'pointer' }} onClick={this.navigareToCommissions} /> */}
               {this.getSelectedLinkType(selectedParam)}
            </div>
            <div className="col-sm-3">
              </div>
          </div>
        </div>
      );
    }
    
  }
}

export default withRouter(Commissions);

const topMenuData = {
  flexcloud_Logo_Logo1: flexcloudLogo,
  line40: Line40,
  overlapGroup1: ellipse12,
  atI42779255482: "AT",
  text6: "Atomic Healthforce",
  text7: "Dev Environment",
  vector2: vector10,
  vector3: vector12,
  vector4: vector11,
  image4: image3,
};