import actions from '../actions/CommonDataActions';
// reducer with initial state
const initialState = {
  snackbarOpen: false,
  snackbarType: "success",
  snackbarMessage: ""
};

export default function snackBarDataReducer(state, action) {
  if (typeof state === 'undefined') {
    return initialState;
  }
  switch (action.type) {
    case actions.SNACKBAR_SHOW:
      return {
        ...state,
        snackbarOpen: action.payload.snackbarOpen,
        snackbarType: action.payload.snackbarType,
        snackbarMessage: action.payload.snackbarMessage
      };
    case actions.SNACKBAR_HIDE:
      return {
        ...state,
        snackbarOpen: false,
        snackbarType: '',
        snackbarMessage: ''
      };
    default:
      return state;
  }
}