import React, { useEffect, useState } from "react"
import { Modal } from "react-bootstrap";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import './user-common-styles.css';
import deleteIcon from '../Commissions/assets/deleteUser.svg';
import { isNullResponse } from "../Commissions/commonComponents/UtilityFun";
import { makeStyles } from "@material-ui/core/styles";
import closedtn from '../Commissions/assets/closeicon.svg';
import redDot from '../../assets/red-dot-status.svg';
import duoSecurity from '../../assets/duoSecuriyIcon.svg';
import arrowSymbol from '../../assets/arrowsymbol.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getloginHistoryAPI } from "./actions";
import { Oval } from "react-loader-spinner";
import DeleteUserPopup from "./DeleteUser/DeleteUser";


const useStyles = makeStyles({
    customTableContainer: {
      overflowX: "initial"
    }
  })

function UserSettingsModel({modelEnable,handleModel, userIDProp=3}){
    const classes = useStyles();
    const [loader, setLoader] = useState(modelEnable);
    const [userID, setUserID] = useState(userIDProp);
    const [enableModel, setEnableModel] = useState(modelEnable);
    const [showDeletePopup, setshowDeletePopup] = useState(false);
    const [userLoginList, setUserLoginList] = useState([
        {
           "type": "Credentials login",
           "IPAddress": "95.130.17.81",
           "client": "Chrome, Mac OS 10.15.7",
           "timeStamp": "2022-07-28T20:47:25Z"
       },
       {
           "type": "Credentials login",
           "IPAddress": "95.130.17.81",
           "client": "Chrome, Mac OS 10.15.7",
           "timeStamp": "2022-07-28T22:49:25Z"
       }
       ]);
      const loginHistoryRes = useSelector(state=> state.userSettingsReducer.loginHistoryResponse);
      const dispatch = useDispatch();
      useEffect(()=>{
        setUserID(userIDProp);
      },[userIDProp])
      useEffect(()=>{
        dispatch(getloginHistoryAPI(userID));
      },[])

      useEffect(()=>{
        setEnableModel(modelEnable)
      },[modelEnable])
      
    console.log("setting model is called : ",loginHistoryRes) 
    const handleModelLocal=()=>{
        setEnableModel(false)
        handleModel();
    }
    const deleteUser =()=>{
        setshowDeletePopup(true);
        //setEnableModel(!modelEnable)
    }
    const formatDate = (dateString) => {
        const options = { year: "numeric", month: "long", day: "numeric"}
        return new Date(dateString).toLocaleString() //toLocaleDateString(undefined, options)
    }
    if (loader) {
        return (
          <div>
            <div id="center" style={{ position: "fixed", bottom: "40%", left: "48%" }}>
              <Oval
                type="Puff"
                position="fixed"
                secondaryColor="#E85557"
                color="#E85557"
                height={50}
                width={100}
                visible={true}
              />{" "}
            </div>
          </div>
        );
    }
    return(
        <div>
            <div>
            <Modal dialogClassName="user-setting-model" show={enableModel} >
                    <Modal.Header>
                        <div className="user-display-space-between magin-left5px">
                           <div className="stlye-lato-we700-si18-li22-c33">Settings</div>
                           <div><img src={closedtn} onClick={handleModelLocal} alt=''/></div>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="user-header-dis">
                            <div className="user-display-space-between">
                                <div>
                                    <label className="stlye-lato-we700-si16-li19-c33">Multi Factor Authentication</label>
                                    <div className="user-multifactor-body body-multi-padding">
                                        <div className="user-display-space-between">
                                            <div>
                                                <div><img src={redDot} alt=''/> <label className="off-style">off</label></div>
                                                <div><label className="stlye-lato-we700-si14-li17-c33 duo-security-style">DUO Security</label></div>
                                            </div>
                                            <div><img src={duoSecurity} alt=''/></div>
                                        </div>
                                        <div><label className="user-duo-description">Use your DUO account for Multi Factor Authentication.</label></div>
                                        <div className="cmnr-create-newrun margin-20px" onClick={handleModelLocal}>
                                            <div className="cmnr-btn-title">set up <img style={{paddingLeft:'5px'}} src={arrowSymbol} alt=''/></div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                <label className="stlye-lato-we700-si16-li19-c33">Account Management</label>
                                    <div className="user-account-body body-multi-padding">
                                       <button className="delelte-button-style" onClick={deleteUser}>
                                            <img src={deleteIcon} alt="del"></img>
                                            <label className="delete-lato-we700-si12-li14-cff3 cursor-action">Delete Account</label>
                                        </button> 
                                        <span className="delete-desc-style">
                                        {"(Remove this user’s data if they requested that, if not please be aware that what has been deleted can never brought back.)"}
                                        </span>    
                                    </div>
                                    </div>
                            </div>  
                            <div>
                                <label className="stlye-lato-we700-si16-li19-c33">Login History</label>
                                <label className="recent-login-activity">Your recent login activity:</label>
                                <div className="user-logintable-container">
                                    <TableContainer className={{root: classes.customTableContainer}}>
                                    <Table stickyHeader>
                                        <TableHead>
                                        <TableCell
                                            className="user-lato-style user-table-header"
                                            component="th"
                                            scope="row"
                                            aria-sort='true'
                                        >
                                            {"Login Type"}
                                        </TableCell>
                                        <TableCell
                                            className="user-lato-style user-table-header"
                                            component="th"
                                            scope="row"
                                        >
                                            {"IP Address"}
                                        </TableCell>
                                        <TableCell
                                            className="user-lato-style user-table-header"
                                            component="th"
                                            scope="row"
                                        >
                                            {"Client"}
                                        </TableCell>
                                        </TableHead>
                                        <TableBody className='user-table-body'>
                                        {isNullResponse(userLoginList) &&
                                        userLoginList?.length > 0
                                            ? userLoginList?.map((data, i) => {
                                                return (
                                                <TableRow key={i}>
                                                    <TableCell className='user-lato-style user-fs14-we400-lh20 user-data-height'>
                                                        <div>{data?.type}</div><div>{'on '+formatDate(data?.timeStamp)}</div></TableCell>
                                                    <TableCell className='user-lato-style user-fs14-we400-lh20 user-data-height'>
                                                    {data?.IPAddress}
                                                    </TableCell>
                                                    <TableCell className='user-lato-style user-fs14-we400-lh20 user-data-height'>
                                                    {data.client}
                                                    </TableCell>
                                                </TableRow>
                                                );
                                            })
                                            : ""}
                                        </TableBody>
                                    </Table>
                                    </TableContainer>
                                </div>
                            </div> 
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div>
                            <div className="cmnr-create-newrun" onClick={handleModelLocal}>
                                <div className="cmnr-btn-title">close</div>
                            </div>
                        </div>
                    </Modal.Footer>
                    {loader}
                    {showDeletePopup && (<DeleteUserPopup setshowDeletePopup={setshowDeletePopup} userName={'Test'}/>)}
                </Modal>
                {/* <NewSnackbar /> */}
                </div>
        </div>
    )
}
export default UserSettingsModel