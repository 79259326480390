import React, { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "./ConfirmDeleteIntegration.css";
import deleteIntegration100X from '../../../assets/delete-integration-100x@2x.png';
import vector13 from '../../../assets/vector-13@2x.png';
import deletecheck from '../../../assets/deletecheck.svg'
class ConfirmDeleteIntegrtion extends React.Component {
  constructor() {
    super()
    this.state = {
      openModal: true,
      closeModal: false
    }

  }
  navigateTohome() {
    this.setState({ openModal: false, hideModal: true })
    window.location.pathname = '/portal'
  }
  render() {
    return (
      <Modal show={this.state.openModal} onHide={this.state.hideModal}>
        <div class="container-center-horizontal">
          <div className="delete-integration-pop-up-3 screen">
            <div className="delete-integration-pop-up-3-1">
              <div className="flex-row-cdi">
                <div
                  className="overlap-group3-cdi"
                  //   style={{ backgroundImage: `url(${vector13})` }}
                  onClick={this.navigateTohome.bind(this)} >
                  <img
                    className="vector-cdi"
                    src={vector13} alt="" />
                </div>
              </div>
              <div className="overlap-group-cdi">
                <img
                  className="delete-integration100x"
                  src={deletecheck} alt="" />

                <div className="text-cdi valign-text-middle">
                  Integration Deleted Successfully
                </div>
              </div>

              <div className="line-divcs">

              </div>

              <div className="overlap-group2-cdi" onClick={this.navigateTohome.bind(this)}>
                <div className="x17732125 component">
                  <div className="i17732125-63692 component ">
                    <div className="button-i177321256-IzgH2X valign-text-middle">
                      BACK TO MY INTEGRATIONS
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ConfirmDeleteIntegrtion;
