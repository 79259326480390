import { LOGINHISTORY_ERROR, LOGINHISTORY_SUCCESS } from "../actions"

const initialState = {
  loginHistoryResponse: {}  ,
  loginHistoryError:{},
  fetching: false,
  success: false,
  periodTypes:[]
}

export default function userSettingsReducer (state, action) {
  if (typeof state === 'undefined') {
    return initialState
  }
  switch (action.type) {
    case LOGINHISTORY_SUCCESS:
        return { ...state, loginHistoryResponse: action.payload }
    case LOGINHISTORY_ERROR:
      return { ...state, loginHistoryError: action.payload }
    default:
      return state
  }
}
