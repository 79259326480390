import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";

function Select1(props) {
  const { _label, _name, _choices, ...rest } = props;
  console.log('_choices',_choices)
 
  return (
    <div className="form-control">
     <label htmlFor={_name}>{_label}</label>
      
      {/* <Field as="select" id={name} name={name} {...rest}>
        <option value="" disabled >Select your option</option>
        {options.map((option, index) => (
          <option key={index} placeholder="select" value={option}>
            {option}
          </option>
        ))}
      </Field> */}
      <Field
        type="text" placeholder="select" id={_name} list={_label} name={_name} {...rest}
      />
  
      {/* <datalist id={_label}>
        {_choices.map((opt,index) => {
             console.log('optis check', opt)
          return (
            <option
              value={opt._label}
              key={index}
            >
              {opt._label}
            </option>
          )
        })}
      </datalist> */}
      {_choices.map((opt,index) => {
        // console.log('opt[0]._value', opt._label, 'iii', index)
    //   <option
    //                             key={index}
    //                             value={opt._label}
    //                             label={opt._label}
    //                           />
    <option key={index} placeholder="select" value={opt}>
            {opt}
          </option>
      })}
      <ErrorMessage name={_name} component={TextError} />
    </div>
  );
}

export default Select1;
