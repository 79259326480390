import React from "react";
import "./Component1.css";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import line42 from '../../../assets/line-42@1x.png';

class Component1 extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { text19, summary, edit, summarytext } = this.props;

    return (
      <div className="component-1">
        {/* <div className="text-9 lato-normal-fuscous-gray-14px">{text19}</div> */}
        {/* <Breadcrumb className='breadcrumb'>
          <Breadcrumb.Item className='text-9 lato-normal-fuscous-gray-14px'>{text19}</Breadcrumb.Item> */}
        {/* <Breadcrumb.Item className='text-9 lato-normal-fuscous-gray-14px'>Summary</Breadcrumb.Item> */}
        {/* <Breadcrumb.Item active>Data</Breadcrumb.Item> */}
        {/* </Breadcrumb> */}
        <div className="breadcrumb">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="new-breadcrumb-styles breadcrumb-item">{text19}</li>
              {summary === true ? <li className={summary === true && edit === false ? "new-breadcrumb-styles breadcrumb-item active1" : "new-breadcrumb-styles breadcrumb-item"}>{summarytext}</li> : ''}
              {edit === true ? <li className={edit === true ? "new-breadcrumb-styles breadcrumb-item active1" : "new-breadcrumb-styles breadcrumb-item"}>Edit Integration</li> : ''}
            </ol>
          </nav>
        </div>
        <img className="line-42-1" src={line42} alt='' />
      </div>
    );
  }
}

// const mapStateToProps = state => {

// }

// const mapDispatchToProps = dispatch => ({ })
export default Component1;
