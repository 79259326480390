import { Oval } from "react-loader-spinner";

const LoaderComponent = () => (
    <div id="center" style={{ background:"#FFFFFF", position: 'absolute', display: "flex", justifyContent: 'center', alignItems:'center', height:"100%", width:"100%",zIndex:"10" }}>
      <Oval
          type="Puff"
          position="fixed"  secondaryColor="#E85557"
          color="#E85557"
          height={50}
          width={50}
      />
    </div>
)


export default LoaderComponent