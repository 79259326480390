import { emailTemplate } from '../actions/emailTemplateDataActions';

// reducer with initial state
const initialState = {    
  emailTemplateListResponse : {},    
  emailTempListError : '',
  selectedEmailTypeResponse:{},
  selectedEmailTypeError:'',
  emailTempPutResponse:{},
  emailTempPutHasError:''
  
};

export default function emailTemplateDataReducer(state, action) {  
  if (typeof state === 'undefined') {
    return initialState;
  }    
  switch (action.type) {
    case emailTemplate.EMAILTEMPLATE_LIST_SUCCESS:      
      return { ...state, emailTemplateListResponse : action.payload  };
    case emailTemplate.EMAILTEMPLATE_LIST_HASERROR :
      return {...state, emailTempListError : action.payload };  
    case emailTemplate.SELECTED_EMAIL_TEMP_SUCCESS:      
      return { ...state, selectedEmailTypeResponse : action.payload  };
    case emailTemplate.SELECTED_EMAIL_TEMP_HASERROR :
      return {...state, selectedEmailTypeError : action.payload };
    case emailTemplate.EMAIL_TEMP_EDIT_SUCCESS:      
      return { ...state, emailTempPutResponse : action.payload  };
    case emailTemplate.EMAIL_TEMP_EDIT_HASERROR :
      return {...state, emailTempPutHasError : action.payload };  
    default:
      return state;
  }
}