import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import deleteIconPopup from '../../../components/Commissions/assets/deleterun.svg'
import closeIcon from '../../../components/Commissions/assets/closeicon.svg'
import './DeleteUSer.css'
import NewSnackbar from '../../utilities/NewSnackbar'
import actions from '../../../redux/actions/CommonDataActions'
import * as Constant from '../../../constants/Constants'

const DeleteUserPopup = ({ setshowDeletePopup,userName }) => {
  const dispatch = useDispatch()
  const deleteUserData = (val) => {
    if(val){
     //dispatch(deleteUserData(data))
   //  dispatch({type: actions.SNACKBAR_SHOW, payload: {}})
     dispatch({ type: actions.SNACKBAR_SHOW, payload: { snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.success, snackbarMessage: 'The user has been deleted'} });
     
     setTimeout(() => {
      setshowDeletePopup(false)
      dispatch({type: actions.SNACKBAR_SHOW, payload: {}})
     }, 1000);
    }
  }
    return (
      <div className='modal-popup'>
        <div className='main-content delete-popup'>
          <div className='popup-header'>
            <div className='heading'>Delete User</div>
            <img
              className='icon'
              onClick={() => setshowDeletePopup(false)}
              src={closeIcon}
              alt='X'
            />
          </div>
          <div className='popup-body popup-delete-user-body'>
            <div>
              <img src={deleteIconPopup} alt='' />
            </div>
            <div className='delete-popup-text'>
              <div>
                <b>Are you sure you want to delete the user ({userName})?</b>
              </div>
              
              <div>
                This action cannot be un-done. Click delete to confirm or click
                cancel.
              </div>
            </div>
          </div>
          <div className='popup-footer'>
            <button
              className='portal-button-2'
              onClick={() => setshowDeletePopup(false)}
            >
              CANCEL
            </button>
            <button
              className='portal-button-1'
              onClick={() => deleteUserData(true)}
            >
              DELETE USER
            </button>
          </div>
        </div>
        <NewSnackbar/>
      </div>
    )
  }

  export default DeleteUserPopup