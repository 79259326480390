export const DELETE_CONFIRMATION_TEXT = 'Are you sure you want to delete the run : ';
export const DELETE_ACTIONS_TEXT = 'This action cannot be un-done. Click delete to confirm or click cancel';
export const CLOSE_ACTION = 'closeaction';
export const OPEN_ACTION = 'openaction';
export const VIEW_LOG_DISPLAY = 'viewlog';
export const VIEW_RUN_LOG_DISPLAY = 'selectedrunlog';
export const LOG_TITLE = 'Update Log';
export const RUN_LOG_TITLE = 'Run Log';
export const RUN_DETAILS_RUN_STATUS = 'Run Status : ';

export const BUTTON_TEXT_RUN_QUAL = 'Run Qual';
export const RUN_DETAILS_RUN_QUAL = 'RUN QUAL';
export const BUTTON_RUN_CALCULATION = 'Run Calc';
export const RUN_DETAILS_RUN_CALC = 'CALCULATION';
export const BUTTON_TEXT_RUN_QUAL_CALC = 'Run Qual & Calc';
export const BUTTON_TEXT_CERTIFY = 'Run Certify';
export const BUTTON_TEXT_DISBURS = 'Run Disburse';
export const BUTTON_TEXT_MAKELIVE = 'Run Publish';
export const BUTTON_TEXT_COMPLETED = 'Complete'
export const BUTTON_TEXT_MERGEAR = 'Run Merge AR';
export const BUTTON_TEXT_NOTIFY = 'NOTIFY';
export const BUTTON_UN_CERTIFY_NAME = 'UN-CERTIFY';
export const BUTTON_UNDO_DISBURSE_NAME = 'UNDO DISBURSE';
export const BUTTON_UNDO_LIVE_NAME = 'UNDO LIVE';
export const BUTTON_UN_MERGE_AR_NAME = 'UN-MERGE AR';

export const BUTTON_RE_RUN_QUAL = 'Re-Run Qual';
export const BUTTON_RE_RUN_QUAL_CALC = 'Re-Run Qual & Calc';
export const BUTTON_RE_RUN_CALC = 'Re-Run Calc';
export const BUTTON_RE_RUN_CERTIFY = 'Re-Run Certify';
export const BUTTON_RE_RUN_DISBURSE = 'Re-Run Disburse';
export const BUTTON_RE_RUN_MAKELIVE = 'Re-Run Publish';
export const BUTTON_RE_RUN_MERGE_AR = 'Re-Run Merge AR';

export const BUTTON_UNDO_CERTIFY = 'Undo certify';
export const BUTTON_UNDO_CERTIFY_AND_RESET = 'Undo certify & reset';
export const BUTTON_UNDO_MAKELIVE = 'Undo Publish';
export const BUTTON_UNDO_MERGEAR = 'Undo Merge AR';

export const MODEL_TITLE_RUN_QUAL = 'Run Qualification';
export const MODEL_TITLE_RUN_CALC = 'Run Calculation';
export const MODEL_TITLE_RUN_CERTIFY = 'Run Certify';
export const MODEL_TITLE_RUN_DISBURSE = 'Run Disburse';
export const MODEL_TITLE_RUN_MAKE_LIVE = 'Run Publish';
export const MODEL_TITLE_RUN_MERGEAR = 'Run Merge AR';
export const MODEL_TITLE_UNDO_MERGEAR = 'Undo Merge AR'
export const MODEL_TITLE_RUN_COMPLETED = 'Run Completed';




export const RUN_QUALIFICATION_SERVICE = 'runQualification';
export const RUN_CALCULATION_SERVICE = 'runCalculation';
export const RUN_CERTIFICATION_SERVICE = 'runCertification';
export const RUN_UNDO_CERTIFICATION_SERVICE = 'undoCertification';
export const RUN_DISBURSEMENT_SERVICE = 'runDisbursement';
export const RUN_UNDO_DISBURSEMENT_SERVICE = 'undoDisbursement';
export const RUN_MAKELIVE_SERVICE = 'runMakeLive';
export const RUN_UNDO_MAKELIVE_SERVICE = 'undoMakeLive';
export const RUN_MERGEACCOUNTSRECEIVABLE_SERVICE = 'runMergeAccountsReceivable';
export const RUN_UNDO_MERGEACCOUNTRECEIVABLE_SERVICE = 'undoMergeAccountsReceivable';
export const RUN_NOTIFY_SERVICE = 'runNotify';

export const COMM_TOOLS_DESCRIPTION = 'Compare(preliminary and analysis) runs to compare different versions of commissions programs.'
export const COMMTOO_TEXT_ADD_RUN_TO_COMPARE = 'Add Run to compare';
export const COMMTOOL_ADD_RUN_COMPARE_INFO = 'Add runs above to view comparison ';
export const PRILIMINARY = 'Preliminary'
export const ANALYSIS = 'Analysis'
export const GOTO_COMPARETOOL = 'GO TO COMPARE TOOL'
export const RUNSTATUS_NOT_STARTED = 'Not started or running'
export const RUNSTATUS_QUALIFICATION_PHASE = 'Qualification Phase Finished'
export const RUNSTATUS_QUALIFICATION = 'Qualification Finished'
export const RUNSTATUS_CERTIFICATION = 'Certification Finished'
export const RUNSTATUS_COMPLETED = 'Run completed'
export const RUNSTATUS_DISBURSEMENT = 'Disbursement Finished'