import axiosRetry from 'axios-retry';
const axios = require('axios');

let instance = null;
/**
 * Provide Axios reference to outside classes and also have generic
 * network methods
 */
class Server {  
  constructor() {
    this.client = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
    });
    axiosRetry(this.client, { retries: 3 });
    if (instance) {
      return instance;
    } 
    instance = this;
    return instance;
    
  }

  /**
   * Generic network get method
   * @param  path 
   * @param params 
   */
  get(path, params, headers) {
    return this.client.get(path, {
      params: params,
      headers: headers
    });
  }

  /**
   * Generic network Post method
   * @param  path 
   * @param  data 
   * @param  params 
   */
  post(path, data, params, headers) {
    return this.client.post(path, data, {
      params: params,
      headers: headers
    });
  }

  /**
   *  Generic network Delete method
   * @param  path 
   * @param  id 
   * @param  params 
   */
  delete(path, data, headers) {
    return this.client.delete(path, {
      data: data,
      headers: headers
    });
  }

  /**
   * Generic network Post method
   * @param  path 
   * @param  params 
   */
  put(path, params, headers) {
    return this.client.put(path, params, {
      headers: headers
    }
    );
  }

  // getCache() {
  //   return cache;
  // }
}

export const singletonAxiosInstance = new Server();
