import React from 'react';
import './CommissionProcess.css';
import vector13 from '../../../assets/vector-13@2x.png';
import { Modal} from "react-bootstrap";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ProgressBar } from 'react-bootstrap';
import * as Constants from '../../../constants/Constants';
import { Oval } from 'react-loader-spinner';
import { allNullTypeCheck, isNullResponse } from '../commonComponents/UtilityFun';

class CommissionProcess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title : 'Commission Processing',
            qualProgress: 0,
            steps: [],
            currentProcess: '',
            currentRec: 0,
            currentrecord1: 0,
            totalRecords: 0,
            stepNames: [],
            selectedStep:0,
            loaderEnble:true,
            autoExecution:false,
        }
    }
    componentDidMount() {
        console.log('comm processing id :',this.props?.selectedCurrentStep,this.props.autoExecuteCalc);
        this.setState({selectedStep : this.props?.selectedCurrentStep, autoExecution: this.props.autoExecuteCalc});

    }
    stopComProcessmodel(){
        this.props.stopcommissionprocess()
    }
    continueComProcessmodel(){
        this.props.continuecomprocess()
    }
    componentDidUpdate(nextProps){
        const { runQualResponseState, steps, stepNames, selectedStep } = this.state;
        const { runQualResponse, runStatusResponse, runQualError, selectedCurrentStep, autoExecuteCalc, undoStepExecution } = this.props;

        if (runQualResponse !== nextProps.runQualResponse){
          if(isNullResponse(runQualResponse?.data) && runQualResponse?.error===false) {
            console.log("Commission Process Run Qual response : ", runQualResponse);
            this.setState({ runQualResponseState: runQualResponse.data });
          //  console.log("id : ", runQualResponseState);
          //  getRunStatus(runQualResponse.data.id);
          }
        }
        else if (runQualError !== nextProps.runQualError) { 
          console.log("Run Qual execution failed :", runQualError);
          this.setState({loaderEnble: false})
        }
        if (runStatusResponse !== nextProps.runStatusResponse){
            if(isNullResponse(runStatusResponse?.data) && runStatusResponse?.error===false){
              const { currentStep, nextStep, status } = runStatusResponse?.data?.runDetails;
              const { steps } = runStatusResponse?.data;

              this.setState({ currentStep: runStatusResponse.data.runDetails.currentStep !== -1|| runStatusResponse.data.runDetails.currentStep === null ?  0: runStatusResponse.data.runDetails.currentStep,  loaderEnble: true })
              this.setState({
                  currentRunStatus: runStatusResponse.data.runDetails,
                  steps: runStatusResponse.data.steps,
              });

              if (!undoStepExecution &&(currentStep===selectedStep) && (currentStep!==null) && (currentStep >= 0) && (currentStep <= 7)) {
              //  console.log('current step', steps[currentStep],'count', currentStep)
                let gparams={}, percentProgress=0;
                if(steps[selectedStep].status.toLowerCase()==='processing'){
                    this.setState({ qualProgress: 0, loaderEnble: false});
                    if(steps[selectedStep].phases.length > 0){
                      for (let indexi = 0; indexi < steps[selectedStep]?.phases.length; indexi++) {
                        gparams = {}
                        if(steps[selectedStep]?.phases[indexi].status !== 'pending' && steps[selectedStep]?.phases[indexi].status !== 'completed' && steps[selectedStep]?.phases[indexi].status !== 'complete'){
                          gparams[Constants.CM_PROCESS.name] = steps[selectedStep]?.phases[indexi].name
                          gparams[Constants.CM_PROCESS.currentRecord] = steps[selectedStep]?.phases[indexi].currentRecord
                          gparams[Constants.CM_PROCESS.totalRecords] = steps[selectedStep]?.phases[indexi].totalRecords 
                          gparams[Constants.CM_PROCESS.value] = 'YES'
                          stepNames.push(gparams)  
                          percentProgress = allNullTypeCheck(steps[selectedStep]?.phases[indexi].currentRecord/steps[selectedStep]?.phases[indexi].totalRecords) ? Math.round((steps[selectedStep]?.phases[indexi].currentRecord/steps[selectedStep]?.phases[indexi].totalRecords)*100): 0
                          //console.log("each step progress is :", Math.round((steps[selectedStep]?.phases[indexi].currentRecord/steps[selectedStep]?.phases[indexi].totalRecords)*100));
                          this.setState({ qualProgress: percentProgress });
                        }
                        if(steps[selectedStep]?.phases[indexi].status === 'completed'){
                          percentProgress = 0 // percentProgress+percentProgress*indexi;
                            this.setState({ qualProgress: 0 });
                        }
                      }
                    }
                    else{
                      gparams[Constants.CM_PROCESS?.name] = steps[selectedStep]?.name
                      gparams[Constants.CM_PROCESS?.value] = 'NO'
                      this.state.stepNames.push(gparams)
                    }
                  }else if(steps[selectedStep].status.toLowerCase()==='completed') {
                      gparams ={};
                      gparams[Constants.CM_PROCESS.name] = steps[selectedStep]?.name
                      gparams[Constants.CM_PROCESS.value] = 'NO'
                      this.state.stepNames.push(gparams)
                      this.updateProgressStatusProcess(selectedStep, runStatusResponse.data.steps);
                  }
                  
              }else if (undoStepExecution &&(nextStep===selectedStep) && (currentStep!==null) && (currentStep >= 0) && (currentStep <= 7)) {
                 this.setState({ qualProgress: 100, loaderEnble: false });
                 setTimeout(() => {
                  this.props.closeModel();
                }, 1000);
              
              } else if (undoStepExecution &&(nextStep===null) && (currentStep===null) && (selectedStep===0)) {
                this.setState({ qualProgress: 100, loaderEnble: false });
                setTimeout(() => {
                 this.props.closeModel();
               }, 1000);
             
             } else if(currentStep===null&&nextStep===null&&status.toLowerCase()!=="pending") {
                setTimeout(() => {
                  this.props.closeModel();
                }, 1000);
              }else {
                this.setState({ loaderEnble: true });
              }
              this.setState({stepNames: this.state.stepNames})
            //  console.log('this.state.stepNames', this.state.stepNames)
          }
        }
    }
    updateProgressStatusProcess(currentStep, steps) {
      const { selectedStep } = this.state;
      const { autoExecuteCalc } = this.props;
      switch (currentStep) {
          case 0:
            if (steps[0].status === 'completed'|| steps[0].status === 'complete') {

                if(!autoExecuteCalc){ this.props.controlRunDetails() };
              setTimeout(() => {
                this.setState({ qualProgress: 100, loaderEnble: false });
                setTimeout(()=>{
                    console.log("next step execution in progress : ");
                    if(autoExecuteCalc && currentStep < 1){
                      this.setState({
                        selectedStep: selectedStep + 1,
                        qualProgress: 0
                      },()=>{});
                    }
                  },5000)
              }, 1000);

            }
            break;
       
          case 1:
            if (steps[1].status === 'completed'|| steps[1].status === 'complete') {
                this.props.controlRunDetails();
              setTimeout(() => {
                this.setState({ qualProgress: 100, loaderEnble: false });
              }, 1000);
            }
            break;
       
          case 2:
              if (steps[2].status === 'completed'|| steps[2].status === 'complete') {
                  this.props.controlRunDetails();
                setTimeout(() => {
                  this.setState({ qualProgress: 100, loaderEnble: false });
                }, 1000);
              }
              break;
          case 3:
              if (steps[3].status === 'completed' || steps[2].status === 'complete') {
                this.props.controlRunDetails();
                setTimeout(() => {
                  this.setState({ qualProgress: 100, loaderEnble: false});
                }, 1000);
              }
              break;
          case 4:
              if (steps[4].status === 'completed' || steps[2].status === 'complete') {
                this.props.controlRunDetails();
                setTimeout(() => {
                  this.setState({ qualProgress: 100, loaderEnble: false });
                }, 1000);
              }
              break;
          case 5:
              if (steps[5].status === 'completed' || steps[2].status === 'complete') {
                this.props.controlRunDetails();
                setTimeout(() => {
                  this.setState({ qualProgress: 100, loaderEnble: false });
                }, 1000);
              }
              break;
          case 6:
              if (steps[6].status === 'completed' || steps[2].status === 'complete') {
                this.props.controlRunDetails();
                setTimeout(() => {
                  this.setState({ qualProgress: 100, loaderEnble: false });
                }, 1000);
              }
              break;
          
          default:
              return this.state;
      }
      console.log("autoExecution : ",this.props.autoExecuteCalc);
      if(!this.props.autoExecuteCalc && currentStep !== 0){
        setTimeout(()=>{
          this.setState({ loaderEnble: false },()=>{
            this.props.closeModel();
          });
        },5000)
      }else if(!this.state.autoExecution && currentStep ===0){
        setTimeout(()=>{
          this.setState({ loaderEnble: false },()=>{
            this.props.closeModel();
          });
        },5000)
      }
      
  }
  
    render() {
        const { title, enablecpmodel, closeModel, runStatusResponse} = this.props;
        const { qualProgress, steps, stepNames, loaderEnble} = this.state
        let dataobj, loader ;
        if(loaderEnble){
          loader = <div><div id="center" style={{ position: 'fixed', top: '45%', left: '48%' }}>
          <Oval type="Puff" position="fixed"  secondaryColor="#E85557" color="#E85557" height={50} width={50} visible={true}/> </div></div>
        }
        if(steps.length > 0 && stepNames.length >0){
          this.state.stepNames.map((item, key) => 
          {
          return  dataobj = item
          })
      
        }
        return (
      <Modal show={enablecpmodel} onHide={false} >
      <Modal.Header>
      <div className="flex-row-1-ei">
        <div className="header-name h3-header-3-18pt-bold-lato">{title}</div>
        <img className="cpvector-3-ei" src={vector13} alt="" onClick={closeModel}/>
        </div>
      </Modal.Header>
      <Modal.Body >
      <div style={{height: '230px'}}>
      <div className="cp-frame-2874">
        <div className="cp-group-2860">
          <div className="cp-bonus-period valign-text-middle lato-normal-fuscous-gray-14px">
            Bonus period:
          </div>
          <div className="cp-primary-032021 lato-bold-mine-shaft-14px">
          {runStatusResponse?.data?.period}
          </div>
        </div>
        <div className="cp-frame-28745">
          <div className="cp-bonus-period valign-text-middle lato-normal-fuscous-gray-14px">
            Period range:
          </div>
          <div className="cp-primary-032021 lato-bold-mine-shaft-14px">
          {allNullTypeCheck(runStatusResponse?.data?.periodStart) ?  runStatusResponse?.data?.periodStart +' to ' +runStatusResponse?.data?.periodEnd : ''}
          </div>
        </div>
        <div className="cp-group-2862">
          <div className="cp-processing valign-text-middle lato-normal-fuscous-gray-14px">
            Processing:
          </div>
          <div className="cp-building-bwt valign-text-middle lato-bold-mine-shaft-14px">
            {dataobj?.name}
         </div>
        </div>
      </div>
      <div className="cp-group-2865">
        <div className="cp-flex-row">
          <div className="cp-group-2863">
                      <div className="col">
                      <div className='cp-progress'>
                                <ProgressBar className="cp-progress-1" percent={100} now={qualProgress} variant="success" />
                            </div>
                      </div>
                    </div>
          <div className="cp-percent valign-text-middle" data-id="802:8066">
            {qualProgress}%
          </div>
        </div>
        <div className="cp-group-2864" data-id="802:8067">
         { dataobj?.value === 'YES' ? 
         <> <div className="cp-record-count valign-text-middle lato-normal-granite-gray-10px">
            Record Count:
          </div>
          <div className="cp-address valign-text-middle">
            <span>
              <span className="lato-normal-granite-gray-10px">{dataobj?.currentRecord}</span>
              <span className="lato-normal-granite-gray-10px"> of </span>
              <span className="lato-normal-granite-gray-10px">{dataobj?.totalRecords}</span>
            </span>
          </div> </>
          : ''} 
        </div>
      </div>
      {/* {steps.length >0 && steps[6]?.status !== 'completed' ? <p className="cp-text-1 valign-text-middle"> */}
        {/* Click Continue to minimize this dialogue box */}
        {/* <br /> */}
        {/* Click Stop to abort the run */}
      {/* </p> : '' } */}
      </div>
      </Modal.Body>
      <Modal.Footer style={{marginTop: "0px"}}>
        <div className= "row">
        {/* {steps.length >0 && steps[6]?.status !== 'completed' ? <div className="cpcolb-no-btn column" onClick={this.stopComProcessmodel.bind(this)}>
         <div className="colb-no-text">STOP</div> 
        </div> : ''} */}
        { steps.length >0 && steps[6]?.status === 'completed' ? <div className="cplb11-yes-btn column" onClick={this.continueComProcessmodel.bind(this)}> <div className="colb-yes-text">DONE</div> </div>: 
        <div className="cplb-yes-btn column" onClick={this.continueComProcessmodel.bind(this)}><div className="colb-yes-text">MINIMIZE</div> </div> }
        </div>
      </Modal.Footer>
      {loader}
    </Modal>
    )
   }
}
CommissionProcess.propTypes = {
    enablecpmodel: PropTypes.any,
    stopcommissionprocess: PropTypes.func,
    continuecomprocess: PropTypes.func
}
const mapStateToProps = (state) => {
    return {
        runQualResponse: state.commissionRunDetailsReducer.runQualResponse,
        runQualError: state.commissionRunDetailsReducer.runQualError,
        runStatusResponse: state.commissionRunDetailsReducer.runStatusResponse,
    }
}

export default connect(mapStateToProps)(withRouter(CommissionProcess))