import React from "react";
import AuthService from "../../../../src/components/server/AuthService";
import { connect } from "react-redux";
import { Oval } from "react-loader-spinner";
import { withRouter } from "react-router-dom";
import { getDistributorDateMisc } from "../../../components/Commissions/actions";
import actions from "../../../redux/actions/CommonDataActions";
import * as Constants from '../../../constants/Constants';
import { errorHandling, getRankName } from '../../utilities/Services';
import NewSnackbar from "../../utilities/NewSnackbar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import './GeneralTab.css';
import './historyTab.css';
import './DateMiscTab.css'
import '../commonComponents/Commoncss.css'
import eyeIcon from "../assets/eye-icon.svg";
import { ProgressBar } from 'react-bootstrap';
import progressdot from '../assets/progress-dot.svg'
import graycricle from '../assets/dotgray.svg'
import { Tooltip } from '@material-ui/core';
import { allNullTypeCheck, isNullResponse } from "../commonComponents/UtilityFun";
import Moment from 'moment';
import greenTick from '../assets/greenrightmark.svg';
import { getAllRanks } from "../actions";
import RankMatrixModel from "./RankMatrixModel";


class DateMiscTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoaderResult: false,
      datamisc: [],
      rankDates: [],
      otherDates: [],
      customRanks: [],
      rankData: [],
      dateMiscWaitTime: 1,
      enableMatrix:false,
      rankId:'',
      detailKey:'',
      ranksWaitTime:1
    };
  }
  componentDidMount() {
    const { distID, selectedRunID } = this.props;
    // console.log("History tab input props ", distID, selectedRunID);
    if (allNullTypeCheck(distID) && allNullTypeCheck(selectedRunID)) {
      this.setState({ showLoaderResult: true });
      this.props.getDistDateMiscDetails(selectedRunID, distID);
      this.props.getAllRanks()
    }
  }
  componentDidUpdate(nextProps) {
    const { distDateMiscData, distDateMiscError, distUplineDropdownList, distUplineDropdownError, distID, selectedRunID } = this.props;
    const {dateMiscWaitTime, ranksWaitTime} = this.state
    if (distDateMiscData !== nextProps.distDateMiscData) {
      this.setState({ showLoaderResult: false });
      if(!distDateMiscData.hasOwnProperty('status')){
      if (isNullResponse(distDateMiscData.data)) {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes("localhost")) {
          console.log("Get distDateMiscData ", distDateMiscData);
        }
        if (distDateMiscData.error === false) {
          console.log("distHistoryData Resposne", distDateMiscData.data);
          this.setState({
            datamisc: distDateMiscData.data,
            rankDates: distDateMiscData.data.rankDates,
            otherDates: distDateMiscData.data.otherDates,
            customRanks: distDateMiscData.data.customRanks,
          });
        } else {
          this.props.showSnackbar({
            snackbarOpen: true,
            snackbarType: Constants.SNACKBAR_TYPE.error,
            snackbarMessage: distDateMiscData.messages[0],
          });
        }
      }else if (Object.keys(distDateMiscData?.data).length === 0 && allNullTypeCheck(distDateMiscData?.messages)) {
        if (distDateMiscData?.messages?.length > 0 && distDateMiscData?.messages[0]?.includes("Try again...")) {
            console.log("Try again block entered : ", distDateMiscData?.data);
            this.setState({ showLoaderResult: true });
            setTimeout(() => {
              this.props.getDistDateMiscDetails(selectedRunID, distID);
            }, 1000 * dateMiscWaitTime);
            this.setState(prevState => {
                return { dateMiscWaitTime: prevState.dateMiscWaitTime * 2 }
            })
        }
      }
       else {
        this.props.showSnackbar({
          snackbarOpen: true,
          snackbarType: Constants.SNACKBAR_TYPE.error,
          snackbarMessage: distDateMiscData.messages[0],
        });
      }
    }else {
      if(distDateMiscData.hasOwnProperty('status') && distDateMiscData.status===202){
        this.setState({ showLoaderResult: true });
            setTimeout(() => {
              this.props.getDistDateMiscDetails(selectedRunID, distID);
            }, 1000 * dateMiscWaitTime);
            this.setState(prevState => {
                return { dateMiscWaitTime: prevState.dateMiscWaitTime * 2 }
            })
     }
    }
    } else if (distDateMiscError !== nextProps.distDateMiscError) {
      this.setState({ showLoaderResult: false });
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes("localhost")) {
        console.log("distDateMiscError ", distDateMiscError);
      }
      errorHandling(distDateMiscError);
    }
    if (distUplineDropdownList !== nextProps.distUplineDropdownList){
      this.setState({ showLoaderResult: false });
      if(!distUplineDropdownList.hasOwnProperty('status')){
      if(isNullResponse(distUplineDropdownList.data)) {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Get distCountryInfo ", distUplineDropdownList) }
          if (distUplineDropdownList.error === false) {
              console.log('Ranks Resposne', distUplineDropdownList.data.ranks)
              this.setState({ranksData: distUplineDropdownList?.data?.ranks})
              console.log('Ranks Resposne DAta in state', this.state.ranksData)                   
          }
          else {
              this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.error, snackbarMessage: distUplineDropdownList.messages[0] })
          }
      } else {
          this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.error, snackbarMessage: distUplineDropdownList.messages[0] })
      }
    }else {
      if(distUplineDropdownList.hasOwnProperty('status') && distUplineDropdownList.status===202){
        this.setState({ showLoaderResult: true });
            setTimeout(() => {
              this.props.getAllRanks()
            }, 1000 * ranksWaitTime);
            this.setState(prevState => {
                return { ranksWaitTime: prevState.ranksWaitTime * 2 }
            })
     }
    }
  } else if (distUplineDropdownError !== nextProps.distUplineDropdownError) {
      this.setState({ showLoaderResult: false });
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("distCountryInfoError ", distUplineDropdownError) }
      errorHandling(distUplineDropdownError);
  }
  }
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  handleMatrix=(id, rankLabel, detailKey)=>{
    // this.props.enableRankMatrix(id, rankLabel,'');
    this.setState({enableMatrix:true, rankId:id, detailKey: detailKey})
  }
  closefunction=()=>{
    this.setState({ enableMatrix:false });
  }
  render() {
    const { ranksData } = this.state
    if (AuthService.isAuthenticated()) {
      let loader;
      const {
        showLoaderResult,
        rankDates,
        otherDates,
        customRanks,
        datamisc,
        rankId,
        enableMatrix,
        detailKey,
      } = this.state;
      const { distID, selectedRunID } = this.props;
      if (showLoaderResult) {
        return(
          <div
            id="center"
            style={{ position: "fixed", bottom: "40%", left: "48%" }}
          >
            <Oval
                        type="Puff"
                        position="fixed"  secondaryColor="#E85557"
                        color="#E85557"
                        height={50}
                        width={50}
                        timeout={3000}
                    />
          </div>
        );
      }
      return (
        <div className="col-12 header-line distres-tabs-scroll">
          <>
            {rankDates.length > 0 ? (
              <>
                <div>
                  <div className="col title-display h4-header-16pt-bold-lato">
                  Rank Progress
                  </div>
                </div>
                <div className="datemisc-progress-col-12">
                  <div className="col-12 row">
                    {rankDates.map((rankDate,j) => (
                      <>
                        <div className="row datemisc-progress-qual-root">
                          <div className="datemisc-progress-item" style={{paddingLeft: rankDates.length-1 === j ? '0px' : ''}}>
                            <div>
                             {allNullTypeCheck(rankDate?.date) ? <Tooltip
                                title={Moment(rankDate?.date).format('MM/DD/YYYY')}
                                placement="top"
                                arrow
                              >
                                <img
                                  className="datemisc-progress-dot"
                                  src={greenTick}
                                  alt="alt"
                                />
                              </Tooltip> : <img
                                  className="datemisc-progress-dot"
                                  src={graycricle}
                                  alt="alt" ></img>} 
                            </div>
                            {(rankDates.length-1) !== j ? <div className="datemisc-display-progress">
                              <ProgressBar
                                percent={100}
                                now={
                                  rankDate?.date !== "" &&
                                  rankDate?.date !== null &&
                                  rankDate?.date !== ""
                                    ? 100
                                    : 0
                                }
                                variant="success"
                              />
                            </div> : <div className="datemisc-display-progress"> </div>}
                          </div>
                          <div>
                            <span className="datemisc-progress-label">
                              {rankDate?.abbreviation}
                            </span>
                          </div>
                        </div>{" "}
                      </>
                    ))}
                    {/* {rankDates.length > 0 ? <div className="row datemisc-progress-qual-root">
                          <div className="datemisc-progress-item" style={{paddingLeft: 0}}>
                            <div>
                              <img
                                  className="datemisc-progress-dot"
                                  src={progressdot}
                                  alt="alt"></img> 
                            </div>
                          </div>
                          <div>
                          </div>
                        </div>:''} */}
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            <div className="distres-generaltable">
              <div className="col-3 distres-general-tbcontainer">
                <TableContainer>
                  <Table
                    sx={{ minWidth: 650 }}
                    aria-label="simple table"
                    className="general-table-left"
                  >
                    <TableHead className="distres-generaltab-header">
                      <TableCell
                        className="runmanager-table-header"
                        component="th"
                        scope="row"
                        colSpan={4}
                      >
                        {"Other Dates"}
                      </TableCell>
                    </TableHead>
                    <TableBody className="distres-general-tbody">
                      {otherDates.length > 0
                        ? otherDates.map((otherdata) => (
                            <TableRow>
                              <TableCell className="data-text-style">
                                {this.capitalizeFirstLetter(otherdata?.label)}
                              </TableCell>
                              <TableCell
                                className="data-text-style"
                                align={"right"}
                              >
                                { Moment(otherdata?.date).format('MM/DD/YYYY HHH:mm:ss') !== 'Invalid date' ? Moment(otherdata?.date).format('MM/DD/YYYY') : '' }
                              </TableCell>
                            </TableRow>
                          ))
                        : ""}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <div className="col-3 distres-datamisc-rightcontainer">
                <TableContainer>
                  <Table
                    sx={{ minWidth: 650 }}
                    aria-label="simple table"
                    className="general-table-right"
                  >
                    <TableHead className="distres-generaltab-header">
                      <TableCell
                        className="runmanager-table-header1"
                        component="th"
                        scope="row"
                        colSpan={3}
                      >
                        {"Custom Ranks"}
                      </TableCell>
                    </TableHead>
                    <TableBody className="distres-general-tbody">
                      {customRanks.map((customRank) => (
                        <TableRow>
                          <TableCell className="data-text-style">
                            {customRank?.label}
                          </TableCell>
                          <Tooltip
                            title={customRank?.value}
                            placement="top"
                            arrow>
                          <TableCell
                            className="data-text-style"
                            align={"right"}
                          >
                            {getRankName(customRank?.value, ranksData)}
                          </TableCell>
                          </Tooltip>
                          <TableCell
                            className="data-text-style"
                            align={"right"}
                          >
                            {customRank?.hasDetail===true ? <img src={eyeIcon} onClick={()=>this.handleMatrix(customRank?.value, customRank?.label, customRank?.detailKey)} alt="" style={{cursor: 'pointer'}}/>: ''}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <div className="col-6 distres-datamisc-rightcontainer">
                <TableContainer>
                  <Table
                    sx={{ minWidth: 650 }}
                    aria-label="simple table"
                    className="general-table-right"
                  >
                    <TableHead className="distres-generaltab-header">
                      <TableCell
                        className="runmanager-table-header1"
                        component="th"
                        scope="row"
                        colSpan={4}
                      >
                        {"Miscellaneous"}
                      </TableCell>
                    </TableHead>
                    <TableBody className="distres-general-tbody">
                      <TableRow>
                        <TableCell className="data-text-style">
                          Leg Paid Rank:
                        </TableCell>
                        <Tooltip
                            title={datamisc?.legPaidRank}
                            placement="top"
                            arrow>
                        <TableCell className="data-text-style" align={"right"}>
                          {getRankName(datamisc?.legPaidRank, ranksData)}
                        </TableCell>
                        </Tooltip>
                        <TableCell className="tablecell-cust data-text-style">
                          Inactive Periods:
                        </TableCell>
                        <TableCell className="data-text-style" align={"right"}>
                          {datamisc?.inactivePeriods}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="data-text-style">
                          Leg End Rank:
                        </TableCell>
                        <Tooltip
                            title={datamisc?.legEndRank}
                            placement="top"
                            arrow>
                        <TableCell className="data-text-style" align={"right"}>
                          {getRankName(datamisc?.legEndRank, ranksData) }
                        </TableCell>
                        </Tooltip>
                        <TableCell className="tablecell-cust data-text-style">
                          Tree Level:
                        </TableCell>
                        <TableCell className="data-text-style" align={"right"}>
                          {datamisc?.treeLevel}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="data-text-style">
                          Disable Comm:
                        </TableCell>
                        <TableCell className="data-text-style" align={"right"}>
                          {datamisc?.disableCommunication === true ? "Y" : "N"}
                        </TableCell>
                        <TableCell className="tablecell-cust data-text-style">
                          Begin Seq:
                        </TableCell>
                        <TableCell className="data-text-style" align={"right"}>
                          {datamisc?.beginSequence}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="data-text-style">ENIU:</TableCell>
                        <TableCell className="data-text-style" align={"right"}>
                          {datamisc?.ENIU === true ? "Y" : "N"}
                        </TableCell>
                        <TableCell className="tablecell-cust data-text-style">
                          End Seq:
                        </TableCell>
                        <TableCell className="data-text-style" align={"right"}>
                          {datamisc?.endSequence}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="data-text-style">
                          Push to Sponsor:
                        </TableCell>
                        <TableCell className="data-text-style" align={"right"}>
                          {datamisc?.pushToSponsor === true ? "Y" : "N"}
                        </TableCell>
                        <TableCell className="tablecell-cust data-text-style">
                          Org Count:
                        </TableCell>
                        <TableCell className="data-text-style" align={"right"}>
                          {datamisc?.organizationCount}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="data-text-style">
                          Convert Balances:
                        </TableCell>
                        <TableCell className="data-text-style" align={"right"}>
                          {datamisc?.convertBalances === true ? "Y" : "N"}
                        </TableCell>
                        <TableCell className="tablecell-cust"></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="data-text-style">
                         Traversed:
                        </TableCell>
                        <TableCell className="data-text-style" align={"right"}>
                          {datamisc?.traversed === true ? "Y" : "N"}
                        </TableCell>
                        <TableCell className="tablecell-cust"></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>{" "}
          </>
          <NewSnackbar />
          {enableMatrix?<RankMatrixModel
            distributorID ={distID}
            runID = {selectedRunID}
            currentRankID={rankId}
            detailKey={detailKey}
          closefunction={()=>this.closefunction()}/>:''}
        </div>
      );
    } else {
      return (
        <div
          id="center"
          style={{ position: "fixed", bottom: "40%", left: "48%" }}
        >
          <Oval
            type="Puff"
            position="fixed" secondaryColor="#E85557"
            color="#E85557"
            height={50}
            width={50}
            timeout={3000}
          />
        </div>
      );
    }
  }
}
const mapStateToProps = (state) => {
    return {
        distDateMiscData: state.commissionDistResearch.distDateMiscData,
        distDateMiscError: state.commissionDistResearch.distDateMiscError,
        distUplineDropdownList: state.distributorResearchReducer.distUplineDropdownList,
        distUplineDropdownError: state.distributorResearchReducer.distUplineDropdownError
    };
};
const mapDispatchToProps = (dispatch) => ({
    getDistDateMiscDetails: (runID, distID) => dispatch(getDistributorDateMisc(runID, distID)),
    getAllRanks: () => dispatch(getAllRanks()),
    showSnackbar: (params) => dispatch({ type: actions.SNACKBAR_SHOW, payload: params }),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(DateMiscTab));
