import React from 'react';
import './ActivityCard.css';
import line156 from '../../../assets/vector-156@2x.png';
import line28 from '../../../assets/line-28@1x.png';
import { connect } from 'react-redux';
import * as Constants from '../../../constants/Constants';
import actions from '../../../redux/actions/CommonDataActions';
import { getActivityDetails} from '../../../redux/thunk/integrationAPIThunk';
import {errorHandling}  from '../../utilities/Services';
import AuthService from '../../server/AuthService';
import { Oval } from "react-loader-spinner";
import NewSnackbar from "../../utilities/NewSnackbar";

var xVal = 4
class Activity extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      activityData: [],
      tempActivity: [],
      disableViewMore: true,
      loadingData: false
    }
  }
  componentDidMount(){
    if (AuthService.isTokenValidCheck() === true) {
      this.setState({ loadingData:  true});
      this.props.callAPIGetActivityDetails(this.props.integrationId);
      xVal = 4
    }
  }
  componentDidUpdate(nextProps){
    if(this.props.getActivitiesResposne!==nextProps.getActivitiesResposne){
      if(this.props.getActivitiesResposne.error===false){
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log("getActivitiesResposne : ",this.props.getActivitiesResposne.data);}   
        if(this.props.getActivitiesResposne.data){
        if (this.props.getActivitiesResposne.data.length > 0) {
         // this.setState({ activityData: this.props.getActivitiesResposne.data })
         // console.log('this.props.getActivitiesResposne.data', this.props.getActivitiesResposne.data)
          this.props.getActivitiesResposne.data.forEach((item) => {
            let tempDate = item.timestamp
            var date1 = new Date().toLocaleDateString()
            var date2 = new Date(tempDate).toLocaleDateString()
            var time = new Date(tempDate);
            var date = new Date(date2);
            var value = ''
            if (date1 === date2) {
              time = time.toLocaleTimeString().replace(/:\d+ /, ' ');
             // console.log('Today', time)
              value =  time
            }
            else {
              var month = date.toLocaleString('en-US', { month: 'short' });
              var todayDate = date.getDate()
            //  console.log('Yesterday', month, todayDate)
              value = todayDate +' '+ month
            }
            item.timestamp = value
           // console.log('items', item)
            if(this.state.tempActivity.length < 5){
              console.log('items leangth')
             this.state.tempActivity.push(item)
            }
            this.state.activityData.push(item)
          this.setState({activityData : this.state.activityData, tempActivity: this.state.tempActivity})
         // console.log('actdata', this.state.activityData, 'temp', this.state.tempActivity)
          })
        }
        this.setState({loadingData: false})
      }  
      }
      else if (this.props.getActivitiesResposne.error===true){
        this.setState({loadingData: false})
       // alert(this.props.getActivitiesResposne.data)
       this.props.showSnackbar({snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.warning, snackbarMessage: this.props.getActivitiesResposne.messages[0]})
      }
    } else if ((this.props.getActivitiesError !== nextProps.getActivitiesError) && (this.props.getActivitiesError)) {
      this.setState({loadingData: false})
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log("getActivitiesError : ", this.props.getActivitiesError);}
      errorHandling(this.props.createWebhookError);
    }
  }
  getRefreshActivity(){
    this.componentDidMount()
   // this.props.showLoader()
   // this.props.callAPIGetActivityDetails(this.props.integrationId);
  }
  viewMoreDetails(){
    let size_li = this.state.activityData.length;
     console.log('total length', size_li)
   // console.log('xval bfr', xVal)
    xVal= (xVal <= size_li) ? xVal+5 : size_li;
    for (let index = xVal -4 ; index <= xVal; index++) {
     // console.log('index', index)
      if(index < size_li){
        console.log('xval aftr', index)
        const element = this.state.activityData[index];
        this.state.tempActivity.push(element)
        this.setState({tempActivity: this.state.tempActivity})
        if(index === size_li-1){
          this.setState({disableViewMore : false})
        }
      }
      else{
      //  console.log('View more Disables')
        this.setState({disableViewMore : false})
        xVal = 4
      }
      
    }
   
  }
  render() {
    let loader = ''
    if (this.state.loadingData) {
      loader =  <div id="center" style={{ position: "fixed", top: "40%", left: "48%" }}>
          <Oval
            type="Puff"
            position="fixed"  secondaryColor="#E85557"
            color="#E85557"
            height={50}
            width={50}
            timeout={1500}/>
        </div>
    }
    let finalObject = this.state.activityData[this.state.activityData.length - 1]
    return (
      <div className="group-2744">
        <div className="overlap-group-ac border-1px-seashell">
          <div className="lastest-activity lato-bold-mine-shaft-18px">Latest Activity</div>
          <div className="flex-row-ac">
          {this.state.tempActivity.length > 0 ?
          <div class="activity-table-css" style={{scrollbarWidth: 'thin'}}>
           <table>
            {this.state.tempActivity.map((item, key) => {
             let value = this.state.tempActivity[this.state.tempActivity.length - 1]
             return <tbody key={key}>
               <tr>
               <td>  
               <div className="overlap-group1-ac">
              { item.activity !== finalObject.activity && value.activity !== undefined ? <img className="line-21" src={line156}  alt=''/>: ''}  
              <div className="group-2632">
                <div className="ellipse-14 border-3px-white"></div>
                <div className="today valign-text-middle lato-normal-persian-red-13px" style={{marginLeft: '9px'}}>{item.timestamp}</div>
              </div>
              <div className="flex-col-ac">
              <p className="text-1-ac lato-normal-fuscous-gray-14px">{item.activity}</p>
            </div>
            </div>
               </td> 
              </tr>
              </tbody>
               })}
              </table>
              </div>
             :
             <div className="flex-col-1-ac" style={{width: '300px', marginLeft: '100px', marginTop:'100px'}}>
                 <span> Activity info not available yet.</span>
                </div>}
          </div>
          <div className="flex-col-1-ac">
            <img className="line-28" src={line28} alt= ''/>
            {this.state.activityData.length > 5 && this.state.disableViewMore === true ?  <div className="view-more-activity valign-text-middle lato-bold-mine-shaft-12px" style={{cursor: 'pointer'}} onClick={this.viewMoreDetails.bind(this)}>View More Activity</div> : '' }
          </div>
        </div>
        {loader}
        <NewSnackbar/>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {    
    isFetchingData: state.commonDataReducer.isFetchingData, 
    currentIntegrationID : state.integrationDataReducer.currentIntegrationID,    
    getActivitiesResposne : state.integrationDataReducer.getActivitiesResposne,
    getActivitiesError : state.integrationDataReducer.getActivitiesError
   }
}
const mapDispatchToProps = dispatch => ({  
  showLoader: () => dispatch({ type: actions.FETCHING_DATA_LOADER, payload: true }),  
  hideLoader: () => dispatch({ type: actions.HIDE_DATA_LOADER, payload: false }),  
  callAPIGetActivityDetails:(integrationID)=>dispatch(getActivityDetails(integrationID)),
  showSnackbar: (params) => dispatch({ type: actions.SNACKBAR_SHOW, payload: params}),
})
export default connect(mapStateToProps, mapDispatchToProps)(Activity)

class Group2633 extends React.Component {
  render() {
    const { children, className } = this.props;

    return (
      <div className={`group-2633 ${className || ""}`}>
        <div className="ellipse-15 border-3px-white"></div>
        <div className="address-ac valign-text-middle lato-normal-persian-red-13px">{children}</div>
      </div>
    );
  }
}


