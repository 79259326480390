import React, { Fragment } from "react";
import AuthService from "../../server/AuthService";
import { connect } from "react-redux";
import { Oval } from "react-loader-spinner";
import { withRouter } from "react-router-dom";
import { getDistributorPaidToInfo, getAllRanks } from "../actions";
import actions from "../../../redux/actions/CommonDataActions";
import * as Constants from '../../../constants/Constants';
import { errorHandling } from '../../utilities/Services';
import NewSnackbar from "../../utilities/NewSnackbar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import './GeneralTab.css';
import './historyTab.css';
import './DateMiscTab.css'
import './AmountFields.css'
import '../commonComponents/Commoncss.css'
import './CountryInfo.css'
import './PaidToTab.css'
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { allNullTypeCheck, isNullResponse } from "../commonComponents/UtilityFun";
import { Tooltip } from '@material-ui/core';
import externalLink from '../assets/externalLink.svg'
import { getCleintNameEnvironment } from "../commonComponents/UtilityFun";
import { numberWithCommas, getRankName } from "../../utilities/Services";
import nodatafound from "../assets/nodatafound.svg";
const envName = getCleintNameEnvironment();

class PaidToTab extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showLoaderResult: false,
            paidToData: [],
            selectedCountryInfo: [],
            personalPayouts: [],
            groupPayouts: [],
            tabSelected: 0,
            tabValue: 0,
            selectedRow: false,
            groupHeadingsPersonal: {},
            groupHeadingsGroup: {},
            groupHeadings: {},
            ranksData: [],
            paidToWaitTime: 1,
            ranksWaitTime:1
           
        }
        this.displayGroup = this.displayGroup.bind(this)
        this.handleTreeView = this.handleTreeView.bind(this)
    }
    componentDidMount() {
        const { distID,selectedRunID } = this.props;
        if (distID !== null && distID !== undefined && distID !== '' && selectedRunID !== null && selectedRunID !== undefined && selectedRunID !== '') {
            this.setState({ showLoaderResult: true });
            this.props.getPaidToData(selectedRunID, distID);   
            this.props.getAllRanks()           
        }
    }
    componentDidUpdate(nextProps) {
        const {
            distPaidToData,
            distPaidToError,
            distUplineDropdownList,
            distUplineDropdownError,
            distID,
            selectedRunID
        } = this.props;
        const { personalPayouts, groupPayouts, groupHeadingsPersonal, groupHeadingsGroup, tabValue, ranksData, paidToWaitTime, ranksWaitTime } = this.state;
        var commissionTpe = {};
        if (distPaidToData !== nextProps.distPaidToData){
            this.setState({ showLoaderResult: false });
            if(!distPaidToData.hasOwnProperty('status')){
            if(isNullResponse(distPaidToData.data)) {
                if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Get distPaidToData ", distPaidToData) }
                if (distPaidToData.error === false) {
                    if(distPaidToData.data?.paidTo?.length > 0){
                        console.log('paidToData Resposne', distPaidToData?.data)
                        // this.setState({ paidToData: distPaidToData.data, selectedCountryInfo: [] });
                       //distPaidToData.data?.paidTo.push({"paidToName":"Grand Pooh-Bah","payoutType":"Personal","orderNumber":0,"distributorId":1002,"sourceDistributorId":1001,"qualificationFlag":"q","targetBonus":17.32,"paidLevel":1,"percent":10,"conversionRate":0.77519,"targetCountryCode":"USA","commissionType":"Unilevel","commissionTypeId":3,"sourceCountryCode":"AUS","pointValue":0,"actualLevel":1,"rank":9,"sourceBonus":22.34,"qualifyingVolume":173.2,"commissionableVolume":223.42})
                        distPaidToData.data?.paidTo.forEach((element , i) => {
                            commissionTpe = element.commissionType
                            element["rowIndex"] = i                           
                            if (element.payoutType === "Personal") {                               
                                personalPayouts.push(element)
                                
                                if( Object.keys(groupHeadingsPersonal).length === 0 ) {                                    
                                    groupHeadingsPersonal[commissionTpe] = [element]
                                
                                } else {
                                     if (Object.keys(groupHeadingsPersonal).includes(commissionTpe)) {                                      
                                        let daata = groupHeadingsPersonal[commissionTpe]                                       
                                        daata.push(element)
                                        groupHeadingsPersonal[commissionTpe] = daata
                                        
                                     } else {
                                        groupHeadingsPersonal[commissionTpe] = [element]
                                    }
                                    
                                }                               
                              console.log("commissionType",  groupHeadingsPersonal)
                              
                            }
                            else {
                                groupPayouts.push(element)                                
                                if( Object.keys(groupHeadingsGroup).length === 0 ) {                                    
                                    groupHeadingsGroup[commissionTpe] = [element]
                                } else {
                                     if (Object.keys(groupHeadingsGroup).includes(commissionTpe)) {                                      
                                        let daata = groupHeadingsGroup[commissionTpe]                                       
                                        daata.push(element)
                                        groupHeadingsGroup[commissionTpe] = daata
                                        
                                     } else {
                                        groupHeadingsGroup[commissionTpe] = [element]
                                    }
                                    
                                }
                            }                          
                           
                        });                        
                         
                    }                 
                  if (tabValue === 0){
                    this.setState({ paidToData: personalPayouts, selectedCountryInfo: personalPayouts[0], groupHeadings: groupHeadingsPersonal })
                  } else {
                    this.setState({ paidToData: personalPayouts, selectedCountryInfo: personalPayouts[0], groupHeadings: groupHeadingsGroup })
                  }
                  
                }
                else {
                    this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.error, snackbarMessage: distPaidToData.messages[0] })
                }
            }else if (Object.keys(distPaidToData?.data).length === 0 && allNullTypeCheck(distPaidToData?.messages)) {
                if (distPaidToData?.messages?.length > 0 && distPaidToData?.messages[0]?.includes("Try again...")) {
                    console.log("Try again block entered : ", distPaidToData?.data);
                    this.setState({ showLoaderResult: true });
                    setTimeout(() => {
                        this.props.getPaidToData(selectedRunID, distID);
                    }, 1000 * paidToWaitTime);
                    this.setState(prevState => {
                        return { paidToWaitTime: prevState.paidToWaitTime * 2 }
                    })
                }
            } 
            else {
                this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.error, snackbarMessage: distPaidToData.messages[0] })
            }
        }else {
            if(distPaidToData.hasOwnProperty('status') && distPaidToData.status===202){
                this.setState({ showLoaderResult: true });
                    setTimeout(() => {
                        this.props.getPaidToData(selectedRunID, distID);
                    }, 1000 * paidToWaitTime);
                    this.setState(prevState => {
                        return { paidToWaitTime: prevState.paidToWaitTime * 2 }
                    })
           }
          }     
        } else if (distPaidToError !== nextProps.distPaidToError) {
            this.setState({ showLoaderResult: false });
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("distPaidToError ", distPaidToError) }
            errorHandling(distPaidToError);
        }
        if (distUplineDropdownList !== nextProps.distUplineDropdownList){
            this.setState({ showLoaderResult: false });
            if(!distUplineDropdownList.hasOwnProperty('status')){
            if(isNullResponse(distUplineDropdownList.data)) {
                if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Get distCountryInfo ", distUplineDropdownList) }
                if (distUplineDropdownList.error === false) {
                    console.log('Ranks Resposne', distUplineDropdownList.data.ranks)
                    this.setState({ranksData: distUplineDropdownList?.data?.ranks})
                    console.log('Ranks Resposne DAta in state', this.state.ranksData)                   
                }
                else {
                    this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.error, snackbarMessage: distUplineDropdownList.messages[0] })
                }
            } else {
                this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.error, snackbarMessage: distUplineDropdownList.messages[0] })
            }
            }else {
                if(distUplineDropdownList.hasOwnProperty('status') && distUplineDropdownList.status===202){
                this.setState({ showLoaderResult: true });
                    setTimeout(() => {
                        this.props.getAllRanks()
                    }, 1000 * ranksWaitTime);
                    this.setState(prevState => {
                        return { ranksWaitTime: prevState.ranksWaitTime * 2 }
                    })
            }
            }
        } else if (distUplineDropdownError !== nextProps.distUplineDropdownError) {
            this.setState({ showLoaderResult: false });
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("distCountryInfoError ", distUplineDropdownError) }
            errorHandling(distUplineDropdownError);
        }
    }
    
    selectPaidToInfo(paidTo, row, index) {
        //console.log("index selected row", index, row)
        this.setState({ selectedCountryInfo: paidTo, selectedRow: row})
    }
    handleTabChange = async (event, newValue) => {
        const { personalPayouts,  groupPayouts, groupHeadingsPersonal, groupHeadingsGroup} = this.state;
        console.log('selected tab', newValue)
       this.setState({ tabValue: newValue });
      if(newValue === 0){
        this.setState({ paidToData: personalPayouts, selectedCountryInfo: personalPayouts[0], groupHeadings: groupHeadingsPersonal  });
      }
      else{
        this.setState({ paidToData: groupPayouts, selectedCountryInfo: groupPayouts[0], groupHeadings: groupHeadingsGroup });
      }
      console.log("selected data", this.state.paidToData , this.state.selectedCountryInfo)  
    };

    handleTreeView(distributorId){
        
        const { selectedRunID, periodtype, periodDate } = this.props;
        console.log("distributorId", distributorId, selectedRunID, periodtype, periodDate)
        localStorage.setItem(envName + "-distID", JSON.stringify(distributorId));
        localStorage.setItem(
          envName + "-selectedRun",
          JSON.stringify(selectedRunID)
        );
        localStorage.setItem(envName + "-periodtype", JSON.stringify(periodtype));
        localStorage.setItem(envName + "-periodDate", JSON.stringify(periodDate));
        // window.open(URL, "/commissiontools/distributorresearch/1");
        window.open(
          "/commissions/commissiontools/distributorresearch/1",
          distributorId,
          ""
        );
    }
    
    displayGroup() {
        const { groupHeadings, selectedRow } = this.state;
        console.log("groupHEadings", groupHeadings)
        let renderTable= []       
        Object.keys(groupHeadings).length > 0 ?
        Object.keys(groupHeadings).forEach( (key,i)  => {
        renderTable.push (
            < TableRow >
                <TableCell style={{ backgroundColor: '#3333', borderRight: '1px solid #33333333',boxShadow: '2px 0px 0px 0px rgb(0 0 0 / 13%)' }} className="runmanager-table-header1">{key}</TableCell>
                <TableCell colSpan={5} style={{ backgroundColor: '#3333' }} className="runmanager-table-header1"></TableCell>
            </TableRow>)
              if(groupHeadings[key].length > 1) {
                for(var idx=0; idx < groupHeadings[key].length; idx ++) {
                     let indexValue = groupHeadings[key][idx];
                   console.log("indexValue", indexValue,i,selectedRow);
                 renderTable.push(
                 <TableRow hover selected={selectedRow === indexValue?.rowIndex}  onClick={() => this.selectPaidToInfo(indexValue, indexValue?.rowIndex, idx)} style={{cursor: 'pointer'}}>
                 <TableCell className="data-text-style" style={{ borderRight: '1px solid #33333333', boxShadow: '2px 0px 0px 0px rgb(0 0 0 / 13%)' }}>{indexValue?.distributorId}</TableCell>
                 <TableCell className="data-text-style" >{indexValue?.paidToName}</TableCell>
                 <TableCell className="data-text-style" align={'right'}>{numberWithCommas(indexValue?.qualifyingVolume?.toFixed(2))}</TableCell>
                 <TableCell className="data-text-style" align={'right'}>{numberWithCommas(indexValue?.commissionableVolume?.toFixed(2))}</TableCell>
                 <TableCell className="data-text-style" align={'right'}>{numberWithCommas(indexValue?.percent?.toFixed(2))}</TableCell>
                <TableCell className="data-text-style" align={'right'}>{numberWithCommas(indexValue?.targetBonus?.toFixed(2))}</TableCell>
              </TableRow>)
              }
                }  else {
                   // console.log("else grp heading", groupHeadings[key],i)
                    renderTable.push(
                        <TableRow hover selected={selectedRow === groupHeadings[key][0].rowIndex }  onClick={() => this.selectPaidToInfo(groupHeadings[key][0],groupHeadings[key][0].rowIndex, idx)} style={{cursor: 'pointer'}}>
                        <TableCell className="data-text-style" style={{ borderRight: '1px solid #33333333', boxShadow: '2px 0px 0px 0px rgb(0 0 0 / 13%)' }}>{groupHeadings[key][0]?.distributorId}</TableCell>
                        <TableCell className="data-text-style" >{groupHeadings[key][0]?.paidToName}</TableCell>
                        <TableCell className="data-text-style" align={'right'}>{numberWithCommas(groupHeadings[key][0]?.qualifyingVolume?.toFixed(2))}</TableCell>
                        <TableCell className="data-text-style" align={'right'}>{numberWithCommas(groupHeadings[key][0]?.commissionableVolume?.toFixed(2))}</TableCell>
                        <TableCell className="data-text-style" align={'right'}>{numberWithCommas(groupHeadings[key][0]?.percent?.toFixed(2))}</TableCell>
                       <TableCell className="data-text-style" align={'right'}>{numberWithCommas(groupHeadings[key][0]?.targetBonus?.toFixed(2))}</TableCell>
                     </TableRow> )
           }
       //   })
           
        }) : renderTable.push(<TableRow > <TableCell align='right' colSpan={2} style={{borderBottom: 'none'}}>No Data Found...</TableCell></TableRow>)
      
        return renderTable
    }
    
    render() {
        if (AuthService.isAuthenticated()) {
            let loader;
            const { showLoaderResult, paidToData, selectedCountryInfo, tabValue, ranksData } = this.state;
            const {distPaidToData, distUplineDropdownList} =  this.props
            if (showLoaderResult) {
                loader = <div id="center" style={{ position: "fixed", bottom: "40%", left: "48%" }}>
                    <Oval
                        type="Puff"
                        position="fixed"  secondaryColor="#E85557"
                        color="#E85557"
                        height={50}
                        width={50}
                        timeout={3000}
                    />
                </div>
            }
            return (
                <div className="header-line">
                  {distPaidToData.data?.paidTo?.length > 0 ? <Tabs
                        className="run-results-tabs"
                        value={tabValue}
                        indicatorColor="primary"
                        select
                        textColor="primary"
                        onChange={this.handleTabChange}
                        aria-label="disabled tabs example"
                        style={{marginLeft: '10px'}}
                    >
                        <Tab label="Personal Payout" />
                        <Tab label="Group Payout" />
                    </Tabs> : '' }
                    <div className="distres-generaltable" style={{ borderTop: '1px solid #3333' }}>{distPaidToData.data?.paidTo?.length > 0 ?
                        <Fragment>
                            <div className="col-10 distres-general-tbcontainer cell-align-right distres-tabs-scroll">
                                <TableContainer>
                                    {distPaidToData.data?.paidTo?.length > 0 ? <Table sx={{ minWidth: 650 }} aria-label="simple table" className="general-table-left">
                                        <TableHead className="distres-generaltab-header">
                                            <TableCell
                                                className="paidto-table-header2"
                                                component="th"
                                                scope="row"
                                            >
                                                {'Paid To ID'}
                                            </TableCell>
                                            <TableCell
                                                className="paidto-table-header2"
                                                component="th"
                                                scope="row"
                                            >
                                                {'Paid To Name'}
                                            </TableCell>
                                            <TableCell
                                                className="distres-history-table-header2"
                                                component="th"
                                                scope="row"
                                                colSpan={1}
                                            >
                                                {'QV'}
                                            </TableCell>
                                            <TableCell
                                                className="distres-history-table-header2"
                                                component="th"
                                                scope="row"
                                                colSpan={1}
                                            >
                                                {'CV'}
                                            </TableCell>
                                            <TableCell
                                                className="distres-history-table-header2"
                                                component="th"
                                                scope="row"
                                                colSpan={1}
                                            >
                                                {'%'}
                                            </TableCell>
                                            <TableCell
                                                className="distres-history-table-header2"
                                                component="th"
                                                scope="row"
                                                colSpan={1}
                                            >
                                                {'Bonus'}
                                            </TableCell>
                                        </TableHead>

                                        <TableBody className="distres-general-tbody">
                                            {this.displayGroup()}
                                        </TableBody>
                                    </Table> : ''}
                                </TableContainer>
                            </div>
                            <div className="col-2 paidto-datamisc-rightcontainer distres-tabs-scroll">
                                <TableContainer className='details-tab' >
                                    {paidToData.length > 0 ? <Table sx={{ minWidth: 650 }} aria-label="simple table" className="general-table-right" >
                                        <TableHead className="distres-generaltab-header">
                                        </TableHead>
                                        <TableBody className="distres-general-tbody">
                                            <TableRow style={{ paddingLeft: '10px !important' }}>
                                                {/* <TableCell className="data-text-style-couinfo">{selectedCountryInfo?.distributorId}</TableCell> */}
                                                <TableCell colSpan={2} className="data-text-style">
                                                    <div className="downline_selected">
                                                        <div className="col-4 remove_cols-padding">{selectedCountryInfo.distributorId}</div>
                                                        <div className="col-8 remove_cols-padding paidto-leftalign" onClick={() => this.handleTreeView(selectedCountryInfo?.distributorId)}><img src={externalLink} alt="" /><span className="treeview-selection">{"GO TO"}</span></div>
                                                    </div>
                                                    <div>{selectedCountryInfo?.paidToName}</div></TableCell>
                                            </TableRow>

                                            <TableRow>

                                            </TableRow>
                                            <TableRow>
                                                <TableCell className="data-text-style-couinfo cell-align-right">Type</TableCell>
                                                <TableCell className="data-text-style-couinfo cell-align-left " align={'right'}>{selectedCountryInfo?.commissionType}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className="data-text-style-couinfo cell-align-right">Source ID</TableCell>
                                                <TableCell className="data-text-style-couinfo cell-align-left" align={'right'}>{selectedCountryInfo?.sourceDistributorId ? selectedCountryInfo?.sourceDistributorId : 0.00}</TableCell>

                                            </TableRow>
                                            <TableRow>
                                                <TableCell className="data-text-style-couinfo cell-align-right">Bonus</TableCell>
                                                <TableCell className="data-text-style-couinfo cell-align-left" align={'right'}>{selectedCountryInfo?.sourceBonus ? selectedCountryInfo?.sourceBonus.toFixed(2) : '0.00'}</TableCell>

                                            </TableRow>
                                            <TableRow>
                                                <TableCell className="data-text-style-couinfo cell-align-right">Conversion</TableCell>
                                                <TableCell className="data-text-style-couinfo cell-align-left" align={'right'}>{selectedCountryInfo?.conversionRate?.toFixed(6)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className="data-text-style-couinfo cell-align-right">Actual level</TableCell>
                                                {/* {getRankName(selectedCountryInfo?.actualLevel, ranksData)} */}
                                                <Tooltip
                                                    title={selectedCountryInfo?.actualLevel}
                                                    placement="top"
                                                    arrow>

                                                    <TableCell className="data-text-style-couinfo cell-align-left" align={'right'}>{getRankName(selectedCountryInfo?.actualLevel, ranksData)}</TableCell>
                                                </Tooltip>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className="data-text-style-couinfo cell-align-right">Paid level</TableCell>
                                                <Tooltip
                                                    title={selectedCountryInfo?.paidLevel}
                                                    placement="top"
                                                    arrow>
                                                    <TableCell className="data-text-style-couinfo cell-align-left" align={'right'}>{getRankName(selectedCountryInfo?.paidLevel, ranksData)}</TableCell>
                                                </Tooltip>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className="data-text-style-couinfo cell-align-right">Rank</TableCell>
                                                <Tooltip
                                                    title={selectedCountryInfo?.rank}
                                                    placement="top"
                                                    arrow>
                                                    <TableCell className="data-text-style-couinfo cell-align-left" align={'right'}>{getRankName(selectedCountryInfo?.rank, ranksData)}</TableCell>
                                                </Tooltip>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className="data-text-style-couinfo cell-align-right">Country</TableCell>
                                                <TableCell className="data-text-style-couinfo cell-align-left" align={'right'}>{selectedCountryInfo?.sourceCountryCode}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table> : ''}
                                </TableContainer>
                            </div>
                        </Fragment>
                        : <TableContainer>
                        <Table>
                            <TableRow className="no-data-image header-line">
                                <TableCell colSpan={9} align='center' className="no-data-image">
                                    <img src={nodatafound} alt="" />
                                </TableCell>
                            </TableRow>
                        </Table>
                    </TableContainer>}</div>
                    {loader}
                    <NewSnackbar />
                </div>
            );
        }
        else {
            return <div id="center" style={{ position: "fixed", bottom: "40%", left: "48%" }}>
                <Oval
                    type="Puff"
                    position="fixed"  secondaryColor="#E85557"
                    color="#E85557"
                    height={50}
                    width={50}
                    timeout={3000}
                />
            </div>
        }
    }
}
const mapStateToProps = (state) => {
    return {
        distPaidToData: state.commissionDistResearch.distPaidToData,
        distPaidToError: state.commissionDistResearch.distPaidToError,
        distUplineDropdownList: state.distributorResearchReducer.distUplineDropdownList,
        distUplineDropdownError: state.distributorResearchReducer.distUplineDropdownError
    };
};
const mapDispatchToProps = (dispatch) => ({
    getPaidToData: (runID, distID) => dispatch(getDistributorPaidToInfo(runID, distID)),
    getAllRanks: () => dispatch(getAllRanks()),
    showSnackbar: (params) => dispatch({ type: actions.SNACKBAR_SHOW, payload: params }),
  
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(PaidToTab));
