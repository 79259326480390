import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import actions from "../../../src/redux/actions/CommonDataActions";
import Button from '@material-ui/core/Button';
import * as Constant from '../../constants/Constants';

export function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: 200,
    },
  },
}));

class NewSnackbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  handleClose= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.props.hideSnackbar()
   // this.setState({openSnackBar: false })
  };
  componentDidUpdate(nextProps){
    if (this.props.snackbarOpen !== nextProps.snackbarOpen) {
    console.log('snackbarOpen act', this.props.snackbarOpen)
    }
  }
  render() {
    return (
      <div className={useStyles}>
        <Snackbar open={this.props.snackbarOpen} autoHideDuration={this.props.snackbarType === Constant.SNACKBAR_TYPE.error ? null : 2500} onClose={this.handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
          <Alert onClose={() => {}} severity={this.props.snackbarType} action={
          <Button color="inherit" size="small" onClick={this.handleClose} style={{textTransform: 'none'}}>
            Dismiss
          </Button>
           }>
            {this.props.snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    snackbarOpen: state.snackBarDataReducer.snackbarOpen,
    snackbarType: state.snackBarDataReducer.snackbarType,
    snackbarMessage: state.snackBarDataReducer.snackbarMessage
  };
};
const mapDispatchToProps = dispatch => ({
  hideSnackbar: () => dispatch({ type: actions.SNACKBAR_HIDE, payload: false }),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewSnackbar)