import React from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import CommissionRunManager from "../CommissionsRunManager";
import CommissionTools from "../CommissionsTools";
import SettingsHome from '../Settings/SettingsHome'
import ReportsHome from '../ReportsModule/ReportsHome'
import "../../../App.css";
import "../CommissionsLanding/CommissionLanding.css"
import CEPrimaryNav from "../PrimaryNavCommissions/index";
import AuthService from '../../server/AuthService';
import * as Constants from '../../../constants/Constants'
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { BROWSER_BACK_BUTTON_HANDLETAB, getCommissionRunAPIList, DIST_RESEARCH_SELECTEDTAB } from '../actions'
//import { gettokenScopeValidation } from '../components/utilities/Services';
import { Oval } from 'react-loader-spinner';
import flexcloudLogo from '../../../assets/flexcloud-logo-logo-1@2x.png';
import Line40 from '../../../assets/line-40@2x.png';
import ellipse12 from '../../../assets/ellipse-12@2x.png';
import vector10 from '../../../assets/vector-10@2x.png';
import vector12 from '../../../assets/vector-12@2x.png';
import vector11 from '../../../assets/vector-11@2x.png';
import image3 from '../../../assets/image-3@2x.png';
import vector2 from '../../../assets/vector-2@2x.png';
import vector4 from '../../../assets/vector-4@2x.png';
import vector5 from '../../../assets/vector-5@2x.png';
import vector7 from '../../../assets/vector-7@2x.png';
import vector8 from '../../../assets/vector-8@2x.png';
import TopMenu from "../../portalv3/TopMenu";
import CommRunDetails from "../CommissionRunDetails/CommRunDetails";
import CommToolCompareRun from "../CommToolCompareRun";
import CommToolDistResearch from '../DistributionResearch'
import RunNavContainer from "../ReportsModule/ReportsNav/RunNavContainer";
import NewTabNavContainer from "../ReportsModule/ReportsNav/NewTabNavContainer";
import NotFound from "../../../Notfound";
import { gettokenScopeValidation } from "../../utilities/Services";




const Main = styled.main`
    position: relative;
    overflow: hidden;
    transition: all .15s;    
    padding: 0 20px;
`;
let hashHistory = Router.hashHistory;
class CommissionsLanding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoader: false,
    }
    this.location = {};
    this.history = {};  
    // this.onBackButtonEvent = this.onBackButtonEvent.bind(this);
  }
  componentDidMount() {
   if (AuthService.isTokenValidCheck()===true) {            
      this.setState({ isLoader: false });          
    } else {
       this.setState({ isLoader: true });
   }
   this.pageReloadHandling(window.location.pathname);
   window.addEventListener('popstate', this.onBackButtonEvent.bind(this));             
  }   
  pageReloadHandling=(pathName)=>{
    if(pathName.includes('/commissions/runmanager') || pathName.toString().includes('/home') || pathName.toString().includes('/commissions/rundetails')) {
      this.props.updateTabValue(0);
    } else if(pathName.includes('/commissions/commissiontools')){
      this.props.updateTabValue(1);
    } else if(pathName.includes('/commissions/reports')) {
      {gettokenScopeValidation("/reports") ? this.props.updateTabValue(2) : ''};
    } else if(pathName.includes('/commissions/settings')) {
      this.props.updateTabValue(3);
    }
  }
  onBackButtonEvent(e){
    e.preventDefault();
    console.log('back button clicked ',window.location.pathname);
    if (window.location.pathname === '/home' || window.location.pathname === '/commissions/runmanager'|| window.location.pathname === '/commissions/commissiontools'||window.location.pathname === '/commissions/reports'|| window.location.pathname==='/commissions/settings') {       
      if(window.location.pathname ==='/commissions/runmanager' || window.location.pathname ==='/home' || window.location.pathname ==='/commissions/rundetails' ) {
        this.props.updateTabValue(0);
      } else if(window.location.pathname === '/commissions/commissiontools'){
        this.props.updateTabValue(1);
      } else if(window.location.pathname === '/commissions/reports') {
       {gettokenScopeValidation("/reports") ? this.props.updateTabValue(2): ''} 
      } else if(window.location.pathname==='/commissions/settings') {
        this.props.updateTabValue(3);
      }
    } 
    if(window.location.pathname === '/commissions/commissiontools/distributorresearch' || window.location.pathname === '/commissions/commissiontools/distributorresearch/1'|| window.location.pathname === '/commissions/commissiontools/distributorresearch/2'|| window.location.pathname === '/commissions/commissiontools/distributorresearch/3'|| window.location.pathname === '/commissions/commissiontools/distributorresearch/4'|| window.location.pathname === '/commissions/commissiontools/distributorresearch/5'|| window.location.pathname === '/commissions/commissiontools/distributorresearch/6'|| window.location.pathname === '/commissions/commissiontools/distributorresearch/7'|| window.location.pathname === '/commissions/commissiontools/distributorresearch/8'|| window.location.pathname === '/commissions/commissiontools/distributorresearch/9'|| window.location.pathname === '/commissions/commissiontools/distributorresearch/10'|| window.location.pathname === '/commissions/commissiontools/distributorresearch/11'|| window.location.pathname === '/commissions/commissiontools/distributorresearch/12') { 
      console.log('back button dist c clicked ',window.location.pathname);
      if(window.location.pathname ==='/commissions/commissiontools/distributorresearch' || window.location.pathname === '/commissions/commissiontools/distributorresearch/1') {
        this.props.distResearchTab(0);
      } else if(window.location.pathname === '/commissions/commissiontools/distributorresearch/2'){
        this.props.distResearchTab(1);
      } else if(window.location.pathname === '/commissions/commissiontools/distributorresearch/3') {
        this.props.distResearchTab(2);
      } else if(window.location.pathname === '/commissions/commissiontools/distributorresearch/4') {
        this.props.distResearchTab(3);
      } else if(window.location.pathname === '/commissions/commissiontools/distributorresearch/5'){
        this.props.distResearchTab(4);
      } else if(window.location.pathname === '/commissions/commissiontools/distributorresearch/6') {
        this.props.distResearchTab(5);
      } else if(window.location.pathname === '/commissions/commissiontools/distributorresearch/7') {
        this.props.distResearchTab(6);
      } else if(window.location.pathname === '/commissions/commissiontools/distributorresearch/8'){
        this.props.distResearchTab(7);
      } else if(window.location.pathname === '/commissions/commissiontools/distributorresearch/9') {
        this.props.distResearchTab(8);
      } else if(window.location.pathname === '/commissions/commissiontools/distributorresearch/10') {
        this.props.distResearchTab(9);
      } else if(window.location.pathname === '/commissions/commissiontools/distributorresearch/11') {
        this.props.distResearchTab(10);
      } else if(window.location.pathname === '/commissions/commissiontools/distributorresearch/12') {
        this.props.distResearchTab(11);
      }
    }
  }

  render(){
    const { reportsParamRes } = this.props;
    console.log("result is ",reportsParamRes)
    if (this.state.isLoader) {
      return (
        <div id="center" style={{ position: 'fixed', bottom: '40%', left: '48%' }}>
          <Oval
            type="Puff"
            position="fixed"  secondaryColor="#E85557"
            color="#E85557"
            height={50} width={50}
            visible={true}
          />
        </div>);

    } 
   if (AuthService.isAuthenticated()) {
    
   return( <div  className='commission-container' style={{position: 'relative' }}>
            { (window.location.pathname==="/commissions/reports/reportsnewtab")?
            <Router basename={'/commissions'} history={hashHistory}>
              <Route render={({ location, history }) => (
                <React.Fragment>
                    <Route path="/reports/reportsnewtab" exact render={props => <NewTabNavContainer heading={"Commission Rank Advancement"} reportsParams={reportsParamRes} {...props}/>} />
                </React.Fragment>
              )}
              />
            </Router>:
            <Router basename={'/commissions'} history={hashHistory}>
              <Route render={({ location, history }) => (
                <React.Fragment>
                  <div className="grid-container">
                    <div className="fill">
                      <TopMenu {...topMenuData} />
                      <CEPrimaryNav {...primaryNavData} />
                    </div>
                  </div>
                    <Main  className="component-landpage">
                      <Route path="/" exact render={props => <CommissionRunManager {...props}/>} />
                      <Route path="/home" exact render={props => <CommissionRunManager {...props}/>} />
                      <Route path="/runmanager" exact render={props => <CommissionRunManager {...props}/>} />
                      <Route path="/rundetails" exact render={props => <CommRunDetails {...props}/>} />
                      <Route path="/commissiontools" exact render={props => <CommissionTools  {...props}/>} />
                      <Route path="/reports/:reportsId?/:periodId?" exact render={props => gettokenScopeValidation("/reports") ?<ReportsHome {...props}/> : <NotFound/>} />
                      <Route path="/reports/run/runReports/runcontainer" exact render={props => gettokenScopeValidation("/reports") ? <RunNavContainer {...props}/> : <NotFound/>} />
                      <Route path="/commissiontools/comparerun" exact render={props => <CommToolCompareRun {...props}/>} />
                      <Route path="/settings/:settingsId?/:periodId?" exact render={props => gettokenScopeValidation("/commissions") ? <SettingsHome {...props}/> : <NotFound/>} />
                      <Route path="/comparerun" exact render={props => <CommToolCompareRun {...props}/>} />
                      <Route path="/commissiontools/distributorresearch/:tabId?" exact render={props => <CommToolDistResearch {...props}/>} />
                    </Main>
                </React.Fragment>
              )}
              />
            </Router>}
          </div>
   );
  }
  return '';
  }
}
const mapStateToProps = (state) => {
  return {
    reportsParamRes: state.reportsMainReducer.reportParamRes,
  };
};
const mapDispatchToProps = dispatch => ({
  updateTabValue:(value) => dispatch({ type: BROWSER_BACK_BUTTON_HANDLETAB, payload: value }),
  distResearchTab: (selectedTab) => dispatch({type:DIST_RESEARCH_SELECTEDTAB, payload: selectedTab}),
});

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(CommissionsLanding));

const topMenuData = {
  flexcloud_Logo_Logo1: flexcloudLogo,
  line40: Line40,
  overlapGroup1: ellipse12,
  atI42779255482: "AT",
  text6: "Atomic Healthforce",
  text7: "Dev Environment",  
  vector2: vector10,
  vector3: vector12,
  vector4: vector11,
  image4: image3,
};

const group2457Data = {
  text12: "DEVELOPER PORTAL",
};

const primaryNavData = {
  text3: "INTEGRATION MANAGER",
  text5: "API DOCS",
  vector2: vector2,
  text4: "LEGACY API",
  ideI42778255518: "IDE",
  vector3: "",
  vector4: vector4,
  vector5: vector5,
  vector6: "",
  vector7: vector7,
  vector8: vector8,
  group2457Props: group2457Data,
};


