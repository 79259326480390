import React, { Fragment, useState, useEffect } from 'react';
import { isNullResponse } from '../../Commissions/commonComponents/UtilityFun';
import Switch from '@mui/material/Switch';
import greendot from '../../../assets/green-dot-status.svg';
import backArrow from '../../Commissions/assets/backArrow.svg';
import userSettings from '../../../assets/userSettings.svg';
import emailUser from '../../../assets/emailUser.svg';
import info from '../../../assets/info.svg';
import searchIcon from '../../../assets/searchIcon.svg';
import line14 from '../../../assets/Line 14.svg';
import closebtn from '../../../assets/closebtn.svg';
import './userEdit.css';
import UserSettingsModel from '../UserSettingsModel';
import { useSelector, useDispatch } from 'react-redux';
import { getAllApplicationRoles, getAllApplicationScopes, getUserInfo, postUserUpdate, } from '../actions';
import { Oval } from 'react-loader-spinner';
import { makeStyles } from "@material-ui/core/styles";
import { InputBase } from '@mui/material';
import vectorsearch from '../../Commissions/assets/vector-search.svg';
import closesvg from '../../Commissions/assets/closeicon-small.svg';

const useStyles = makeStyles((theme) => ({
    root: {
      "& > *": {
        margin: theme.spacing(0.5)
      }
    },
    tabs: {
  
      "& .MuiTabs-indicator": {
        backgroundColor: "orange",
        height: 3,
      },
      "& .MuiTab-root.Mui-selected": {
        color: 'red'
      }
    },
    search: {
      position: 'relative',
      backgroundColor: "#FFFFFF",
      width: "428px",
      height: "40px",
      justifyContent:"space-between",
      borderRadius:'4px',
      // [theme.breakpoints.up("sm")]: {
      //   marginLeft: theme.spacing(3)
      //   // width: 'auto',
      // }
    },
    searchIcon: {
      // padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      paddingLeft:'6px',
      paddingTop:'5px',
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#8782D9"
    },
    searchIcon1: {
      // padding: theme.spacing(0, 2),
      height: "100%",
      // position: "absolute",
      float:'right',
      paddingRight:'4px',
      paddingTop:'4px',
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#8782D9"
    },
    inputRoot: {
      color: "inherit",
      position:'absolute',
      width:'428px',
      height:'40px',
      border:'1px solid #DDDDDD',
      borderRadius:'4px',
    },
    inputInput: {
      // paddingLeft:'26px',
      // paddingTop:'5px',
      // paddingRight:'5px',
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon,
      // paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "80%",
      [theme.breakpoints.up("md")]: {
        width: "20ch"
      }
    }
  }));
  

export default function UserEdit({ onBack, userId }) {
    const [loader, setLoader] = useState(true);
    // const [eventsInputColor, setEventsInputColor] = useState('#DDDDDD');
    const [enableUserSettings, setEnableUserSettings] = useState(false);
    const [isRoleDropDownOpen, setIsRoleDropDownOpen] = useState(false);
    const [isScopeDropDownOpen, setIsScopeDropDownOpen] = useState(false);
    const [rolesDropDownlist, setRoleDropDownList] = useState([]);
    const [scopeDropDownList, setScopeDropDownList] = useState([]);
    const [selectedRoleList, setSelectedRoleList] = useState([]);
    const [selectedScopeList, setSelectedScopeList] = useState([]);
    const [userInfo, setUserInfo] = useState([
        { "name": "commission Research-view", "status": "Add" },
        { "name": "commission Research-Export", "status": "Already Existing" },
        { "name": "commission Reports-Edit", "status": "Add" }])
    const classes = useStyles();
    const [userRole, setUserRole] = useState([
    ]);
    const [userScope, setUserScope] = useState([
        { "name": "commission Research-view", "status": "Add" },
        { "name": "commission Research-Export", "status": "Already Existing" },
        { "name": "commission Reports-Edit", "status": "Add" }])
    const getUserRes = useSelector(state => state.usersUpdateReducer.applicationGetUserRes)
    const UpdateUserRes = useSelector(state => state.usersUpdateReducer.applicationUpdateUserRes)
    const applicationRolesRes = useSelector(state => state.usersUpdateReducer.applicationRolesRes)
    const applicationRolesError = useSelector(state => state.usersUpdateReducer.applicationRolesError)
    const applicationScopesRes = useSelector(state => state.usersUpdateReducer.applicationScopesRes)
    const applicationScopesError = useSelector(state => state.usersUpdateReducer.applicationScopesError)

    // const applicationUsersList = useSelector(state=>state.userDashboardReducer.applicationUserRes)
    console.log("roles res  & scopes res : ",applicationRolesRes,applicationScopesRes );
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getUserInfo(userId));
        dispatch(getAllApplicationRoles());
        dispatch(getAllApplicationScopes());
        return () => {
            localStorage.setItem("edituser", false);
        }
    }, [userId])
    useEffect(() => {
        if (isNullResponse(getUserRes)) {
            setLoader(false);
            if (getUserRes?.data?.user) {
                console.log("get yser data", getUserRes?.data?.user)
                setUserInfo(getUserRes?.data?.user);
                (applicationRolesRes?.data?.roles?.length>0 && getUserRes?.data?.user?.roles?.length>0)? handleRoleDropDown(applicationRolesRes?.data?.roles, getUserRes?.data?.user?.roles):'';
            }
        }
    }, [getUserRes]);
    
    useEffect(()=>{
        if(isNullResponse(applicationRolesRes) && applicationRolesRes.error===false){
            // console.log("success role occuered",applicationRolesRes?.data.roles);
            if(applicationRolesRes?.data?.roles?.length>0 && userInfo?.roles?.length>0){
                handleRoleDropDown(applicationRolesRes?.data?.roles, userInfo?.roles);
            }
            setLoader(false);

        }else{
            if(isNullResponse(applicationRolesError)){
                console.log("error roles occured: ",applicationRolesError)
            }
            setLoader(false);
        }

    },[applicationRolesRes,applicationRolesError])

    useEffect(()=>{
        if(isNullResponse(applicationScopesRes) && applicationScopesRes.error===false){
            console.log("success res scope is : ",applicationScopesRes?.data?.scopes)
            setLoader(false);
        }else{
            if(isNullResponse(applicationScopesError)){
                console.log("error scope occured: ",applicationScopesError)
            }
            setLoader(false);
        }
    },[applicationScopesRes,applicationScopesError])

    const handleRoleDropDown=(rolesList,userRoles)=>{
        console.log("roleslist and userRoles :",rolesList,userRoles);
        let newlist;
        if(rolesList){
            userRoles.forEach(userRole => {
               rolesList.filter((data,i)=>{
                    if(parseInt(data.id)===parseInt(userRole.id)){
                        return data["availability"]="Already Existing"
                    } else{ 
                        return data["availability"]="Add"
                    }})
                });
            
        }
        console.log("users list is : ",rolesList);
    }   
    const removeRole = (roleId) => {
        console.log("removed role")
    }
    const handleBack = () => {
        onBack()
    }
    const handleUserSetting = () => {
        enableUserSettings ? setEnableUserSettings(false) : setEnableUserSettings(true);
    }

    const updateApplicationUser = () => {
        useEffect(() => {
            dispatch(postUserUpdate(userInfo))
        }, [])
    }
    const openroles = () => {
        console.log("openRole dropdown", isRoleDropDownOpen)
        setIsRoleDropDownOpen(isRoleDropDownOpen=>!isRoleDropDownOpen);
    }
    const opnScopes = () => {
        console.log("openRole dropdown")
        setIsScopeDropDownOpen(isScopeDropDownOpen=>!isScopeDropDownOpen);
    }
    const removeScope = () => {

    }
    const addScope = (scope) => {
        setUserScope()
    }

    const addRole = (role) => {

        // role.status == 'Add' ? 
        // setUserRole()
    }
    const handledUserSearch=(e)=>{

    }

    console.log("userInfo ", userInfo)
    if (loader) {
        return (
            <div>
                <div id="center" style={{ position: "fixed", bottom: "40%", left: "48%" }}>
                    <Oval
                        type="Puff"
                        position="fixed"
                        secondaryColor="#E85557"
                        color="#E85557"
                        height={50}
                        width={100}
                        visible={true}
                    />{" "}
                </div>
            </div>
        );
    } else {
    return (
        <div style={{ padding: "20px 20px", flex: "0 0 100%", maxWidth: "100%" }} >
            <div className='backEdit' onClick={handleBack}>
                <img src={backArrow} alt="back" />
                <span className='btn-back' > BACK </span>
            </div>
            <div style={{ padding: "20px 20px", backgroundColor: "white", maxWidth: "100%" }}>
                <div className='displayRow'>
                    <div className='displayRow'><span className='userName'>{userInfo?.name}</span>
                        <img className="user-img-status" style={{ paddingLeft: "50px" }} src={greendot} alt="reports" />
                        <span className='paragraph-font-12 color-green'> {userInfo?.status}</span>
                    </div>
                    <div className='displayRow' onClick={() => handleUserSetting()} style={{ marginRight: "20px", cursor: 'pointer' }}>
                        <img className='' style={{ marginRight: "5px" }} src={userSettings} alt='' />
                        <span className='settingsText'> settings </span>
                    </div>
                </div>
                <div>
                    <div className="userName">
                        <span className='paragraph-font-12 color-grey'>Last Login: {userInfo?.lastLogin} </span>
                        <span className='paragraph-2-font-12'>Active Profile </span>
                        <Switch id="waiver-edit-screen-switch" size="small">
                            <i className="some-icon" />
                        </Switch>
                    </div>
                </div>
                <div className="displayRow-nojustify" style={{ marginTop: "8px" }}>
                    <img src={emailUser} alt='email' />
                    <span className='paragraph-1-font-14-red' style={{ paddingLeft: "11px" }}>{userInfo?.email}</span>
                </div>
                <div className='displayRow' style={{ marginTop: "24px", marginRight: "120px", marginBottom: "10px" }}>
                    <div className='displayRow'>
                        <span className='paragraph-1-font-14-grey'> Roles</span>
                        <img src={info} style={{ paddingLeft: "9px" }} alt="info" />
                    </div>
                    <div style={{ marginRight: "410px" }}>
                        <span className='paragraph-2-font-14-red'> View All Roles & Permissions</span>
                    </div>
                </div>
                <div style={{position:'absolute'}}>
                    {/* <div> */}
                        <div onClick={openroles} >
                            {/* <div > */}
                                {/* <img src={searchIcon} alt="" /> */}
                                <div className={'user-search-input '+classes.search} style={{ width: "100%" }}>
                                    <div className={classes.searchIcon}>
                                    <img className="report-search-icon" src={searchIcon} alt="reports" />
                                    </div>
                                    <InputBase
                                    placeholder="Search here..."
                                    className='user-input-search'
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    inputProps={{ "aria-label": "search", "className": "user-search-input-style"}}
                                    onChange={handledUserSearch}
                                    />
                                    <div hidden={true} className={classes.searchIcon1}>
                                    <img src={closesvg} alt="reports" />
                                    </div>
                                 </div> 
                            {/* </div> */}
                        </div>

                        {isRoleDropDownOpen ? <div className='roledropdown'>
                            {console.log("avialable roles: ",userInfo)}
                            {userInfo?.length>0 && userInfo?.map((role) => {
                                console.log(role)
                                return (
                                    <div style={{ display: "flex", margin: "16px" }}>
                                        <p className='role-name'>{role?.name}</p>
                                        <p className={role.id == '1' ? 'role-list-add' : 'role-list-not-add'} onClick={role.id === 1 ? addRole : ''}>{role?.id === 1 ? role.status : role.status}</p>
                                    </div>
                                )
                            })}
                        </div> : ''}
                        {/* <div className="flex-row-6-adw row">
                            {userRole.length>0 && userRole.forEach((element) => {
                                return (
                                    <div className="overlap-group4-adw column">
                                        <div className="usr-name paragraph-2--12pt-regular-lato">{element.name}</div>
                                        <div>
                                            <img className="path-22" src={closebtn} alt='' onClick={removeRole(element)} />
                                        </div>
                                    </div>
                                )
                            })}
                        </div> */}
                </div>
                <div  style={{position:'absolute',marginTop:'30px'}}>
                    <div className='displayRow' style={{ marginTop: "24px", marginRight: "120px", marginBottom: "10px" }}>
                        <div className='displayRow'>
                            <span className='paragraph-1-font-14-grey'> Scopes</span>
                            <img src={info} style={{ paddingLeft: "9px" }} alt="info" />
                        </div>

                        {/* <div style={{ marginRight: "410px" }}>
                        <span className='paragraph-2-font-14-red'> View All Roles & Permissions</span>
                    </div> */}
                    </div>
                    <div>
                        <div onClick={opnScopes} >
                            {/* <div > */}
                                {/* <img src={searchIcon} alt="" /> */}
                                <div className={'user-search-input '+classes.search} style={{ float: "left" }}>
                                        <div className={classes.searchIcon}>
                                        <img className="report-search-icon" src={searchIcon} alt="reports" />
                                        </div>
                                        <InputBase
                                        placeholder="Search here..."
                                        className='user-input-search'
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputInput,
                                        }}
                                        inputProps={{ "aria-label": "search", "className": "user-search-input-style"}}
                                        onChange={handledUserSearch}
                                        />
                                        <div hidden={true} className={classes.searchIcon1}>
                                        <img src={closesvg} alt="reports" />
                                        </div>
                                </div> 
                            {/* </div> */}
                        </div>

                        {isScopeDropDownOpen ? <div className='roledropdown'>
                            {userInfo && userInfo?.scopes?.length>0 && userInfo?.scopes?.map((scope) => {
                                console.log(scope)
                                return (
                                    <div style={{ display: "flex", margin: "16px" }}>
                                        <p className='role-name'>{scope?.description}</p>
                                        {/* <p className={scope.id === '80' ? 'role-list-add' : 'role-list-not-add'} onClick={scope.id === 80 ? addScope : ''}>{scope?.id === 80 ? 'Add' : 'Already Existing'}</p> */}
                                    </div>
                                )
                            })}
                        </div> : ''}
                    </div>
                    {/* <div className="flex-row-6-adw row">
                        {userScope.length>0 && userScope.forEach((element) => {
                            return (
                                <div className="overlap-group4-adw column">
                                    <div className="usr-name paragraph-2--12pt-regular-lato">{element.name}</div>
                                    <div>
                                        <img className="path-22" src={closebtn} alt='' onClick={removeScope(element)} />
                                    </div>
                                </div>
                            )
                        })}
                    </div> */}
                </div>
                <div style={{ marginTop: "200px" }}>
                    <img src={line14} alt="" />
                </div>
                <div style={{ marginTop: "5px", display: "flex" }}>
                    <div className='btn-cancel' style={{ alignItems: "right" }}> CANCEL
                    </div>
                    <div className="btn-update" onClick={updateApplicationUser}>UPDATE</div>
                </div>
            </div>
            <UserSettingsModel modelEnable={enableUserSettings} handleModel={handleUserSetting} />
        </div>)
    }
}