import React, { useEffect,useState } from "react";
import { connect } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import rightTick from "../assets/rightTick.svg";
import { Oval } from "react-loader-spinner";
import './GeneralTab.css';
import {useSelector, useDispatch} from 'react-redux'
import { getDownlineDistRankInfoAPI, getDownlineDistVolumes, DISTRIBUTOR_UPLINE_ERROR, DISTRIBUTOR_UPLINE_SUCCESS, 
    DOWNLINE_DIST_RANKINFO_SUCCESS, DIST_UPLINE_DROP_SUCCESS, DIST_UPLINE_DROP_ERROR, DOWNLINE_DIST_VOLUME_SUCCESS, getDistributorUpline, getAllRanks } from "../../../components/Commissions/actions";
import AuthService from '../../server/AuthService';
import { singletonAxiosInstance } from '../../utilities/Server';
import Endpoints from '../../utilities/Endpoints';
import externalLink from '../assets/externalLink.svg'
import Dropdown from "react-bootstrap/Dropdown";
import { allNullTypeCheck, getCleintNameEnvironment, isNullResponse } from "../commonComponents/UtilityFun";
import { numberWithCommas, getRankName } from "../../utilities/Services";
import { Tooltip } from '@material-ui/core';
import nodatafound from "../assets/nodatafound.svg";
import * as Constant from '../../../constants/Constants';
import actions from "../../../redux/actions/CommonDataActions";

const server = singletonAxiosInstance;
const options = {
 Authorization: 'Bearer ' + localStorage.getItem('id_token')
 //Authorization: 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjU4MjQyMTVEODhGQkE4RTEzQkZGN0FFNTk0MzAyODM5RkU1M0ZGOENSUzI1NiIsInR5cCI6ImF0K2p3dCIsIng1dCI6IldDUWhYWWo3cU9FN18zcmxsREFvT2Y1VF80dyJ9.eyJuYmYiOjE2MzM0OTA0MzksImV4cCI6MTY2NTAyNjQzOSwiaXNzIjoiaHR0cHM6Ly9kZXYtYXRvbWljLmZsZXhjbG91ZC5jb20vaWRlbnRpdHkiLCJjbGllbnRfaWQiOiIwZDkzNGI5MS03NjA3LTQ3OWUtYjMyOS0xOTAyNjhiZmRjZDIiLCJjbGllbnRfbmFtZSI6ImRldi1hdG9taWMiLCJqdGkiOiIxNTk0MTA3RDFGRjM0RTMyNDcwMTZGRjE4Q0Q2QjdERCIsImlhdCI6MTYzMzQ5MDQzOSwic2NvcGUiOlsiL2NvbW1pc3Npb25zIl19.VOq7hVNtlqz3yhuag0yYrqMXefpTfI3upweQy2DUt9mO6Wd6iHhd8S3JCamTCYY-Fr8rlxIPEXNb8Rx7fg1e4LCrcXVQoOLVg65QBL3r2xuMQE7PQ_c4MF9C61xxQWrFAC2jjvNWztrb5pcv9B73XVv1MCJVajxnYeyrKVdxP3VK8R9L_OHqEzpXzKfz6PW0BiXgvPIl92NtHDE1mvC6YXXXcVwHuTAFBu8tZdvskr_wm0iNaY7niY8QzKXvY5DkWFdknaSeGP9HJwukdGzdY-ObHg5JDl2Swb0ienhOqQ4mKrIA1_kU9tcfk6k4dAPpYkvwcwPB-WQfNECuB1qxJw'
};
const statCodes = {
  SUCCESS: 200,
  CREATE_SUCCESS: 201,
  unAuthorized: 403
};

const UplineTab =(props)=>{
  const [loader, setLoader] = useState(true);
  const [distUplineListData, setDistUplineListData] = useState({});
  const [distRankInfoList, setDistRankInfoList] = useState({});
  const [distVolumeList, setDistVolumeList] = useState({});
  const [dropdownLoading, setDropdownLoading] = useState(false);
  const [downlineLoading, setDownlineLoading] = useState(false);
  const [distUplineDropdownList, setDistUplineDropdownList] = useState({});
  const [retrydelayTime, setRetrydelayTime] = useState(1);
  const [retrydelayTime1, setRetrydelayTime1] = useState(1);
  const [retrydelayTime2, setRetrydelayTime2] = useState(1);
  const [retrydelayTime3, setRetrydelayTime3] = useState(1);
  const [dropdownValue, setDropdownValue]= useState('');
  let dropdownSpinner='',downlineSpinner='';

  const distUplineList = useSelector(state => state.distributorResearchReducer.distUplineList);
  const distUplineError = useSelector(state => state.distributorResearchReducer.distUplineError)
  const downlineDistRankInfo = useSelector(state => state.distributorResearchReducer.downlineDistRankInfo);
  const distRankInfoError = useSelector(state => state.distributorResearchReducer.downlineDistRankInfoError);
  const downlineDistVolumeList = useSelector(state => state.distributorResearchReducer.downlineDistVolumeList);
  const distVoluemError = useSelector(state => state.distributorResearchReducer.downlineDistVolumeError);
  const distUplineDropdownListData = useSelector(state => state.distributorResearchReducer.distUplineDropdownList);
  const distUplineDropdownError = useSelector(state => state.distributorResearchReducer.distUplineDropdownError);
  const [selectedValue, setSelectedValue] = useState('All');
  const [selectedRow, setselectedRow] = useState('')
  const [ranksData, setRanksData] = useState([])
  const [selected, setSelected] = useState(false)
  let initialValue = 0;
  const envName = getCleintNameEnvironment();

    const { distID,selectedRunID, periodtype, periodDate } = props;
    const dispatch = useDispatch()
    console.log("upline restult table data :",distID,selectedRunID);
    console.log("upline restult :",distUplineListData)
    console.log("upline rank  restult :",distRankInfoList);
    console.log("upline Volume  restult :",distVolumeList);
    //distUplineListData.sort((a, b) => (a.level > b.level) ? 1 : -1)

    // const getDistributorUpline = (distID,runID,uplineID) => {
    //     return dispatch => {
    //       if (AuthService.isTokenValid() === true) {
    //         server.get(Endpoints.CommissionRuns+'/'+runID+"/distributors/"+distID+"/upline?commissionTypeId="+uplineID, '', options).then((data) => {
    //           setLoader(false);
    //           if (data.status === statCodes.SUCCESS) {
    //             if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Upline Details res :', data); }
    //             dispatch({ type: DISTRIBUTOR_UPLINE_SUCCESS, payload: data.data });
    //           } else {
    //             dispatch({ type: DISTRIBUTOR_UPLINE_ERROR, payload: data });
    //           }
    //         }).catch((error) => {
    //           setLoader(false);
    //           if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
    //           if (!error.response) {
    //             dispatch({ type: DISTRIBUTOR_UPLINE_ERROR, payload: error });
    //           } else {
    //             dispatch({ type: DISTRIBUTOR_UPLINE_ERROR, payload: error.response });
    //           }
      
    //         });
    //       } else {
    //         const user = AuthService.signinSilentToken();
    //       }
    //     }
    // }
    // const getDistUplineDropdown = () => {
    //     return dispatch => {
    //       if (AuthService.isTokenValid() === true) {
    //         server.get(Endpoints.CommissionTypeSelectionURL1+'/ranks', '', options).then((data) => {
    //           // setLoader(false);
    //           if (data.status === statCodes.SUCCESS) {
    //             if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRun Details res :', data); }
    //             dispatch({ type: DIST_UPLINE_DROP_SUCCESS, payload: data.data });
    //             console.log("result: desc : ",data?.data?.data?.ranks[0]?.description);
    //             setRanksData(data?.data?.data?.ranks)
    //            // setSelectedValue(data?.data?.data?.ranks[0]?.name)
    //             //setSelectedValue(data?.data?.data?.ranks[0]?.name);
    //             // dispatch(getDistributorUpline(distID, selectedRunID, data?.data?.data?.ranks[0]?.id));
    //           } else {
    //             dispatch({ type: DIST_UPLINE_DROP_ERROR, payload: data });
    //           }
    //         }).catch((error) => {
    //           if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
    //           if (!error.response) {
    //             dispatch({ type: DIST_UPLINE_DROP_ERROR, payload: error });
    //           } else {
    //             dispatch({ type: DIST_UPLINE_DROP_ERROR, payload: error.response });
    //           }
      
    //         });
    //       } else {
    //         const user = AuthService.signinSilentToken();
    //       }
    //     }
    // }

    useEffect(() => {
      if(isNullResponse(distUplineList)&&!distUplineList.hasOwnProperty('status')){ 
      if (isNullResponse(distUplineList.data) && distUplineList?.error===false){
        console.log("got the Response: ",distUplineList);
        setDistUplineListData(distUplineList)
        setLoader(false);
        setDropdownLoading(false)
      }else {
        if(allNullTypeCheck(distUplineList?.data) && Object.keys(distUplineList?.data).length===0 && allNullTypeCheck(distUplineList?.messages)) {
          if(distUplineList?.messages?.length > 0 && distUplineList?.messages[0]?.includes("Try again")){
            console.log("Try again block entered : ",distUplineList?.data);
            setTimeout(()=>{
              dispatch(getDistributorUpline(distID,selectedRunID,dropdownValue));
            },retrydelayTime*1000)
            setRetrydelayTime(retrydelayTime*2);
        }
      }
      setLoader(false);
      setDropdownLoading(false)
    }
    }else {
        if(distUplineList.hasOwnProperty('status') && distUplineList.status===202){
          setDropdownLoading(true)
          setTimeout(()=>{
            dispatch(getDistributorUpline(distID,selectedRunID,dropdownValue));
          },retrydelayTime*1000)
          setRetrydelayTime(retrydelayTime*2);
      }
    }
    if(isNullResponse(distUplineError)&& distUplineError?.data?.error===true){
      console.log("General rank info failed :", distUplineError);
      setLoader(false);
      setDropdownLoading(false)
      if(distUplineError?.data?.error === true){
        dispatch({ type: actions.SNACKBAR_SHOW, payload: {snackbarOpen: true, snackbarType:  Constant.SNACKBAR_TYPE.error, snackbarMessage: typeof(distUplineError?.data?.messages[0])!=='string'? distUplineError?.data?.messages[0]?.code+' - '+distUplineError?.data?.messages[0]?.detail:distUplineError?.data?.messages[0]}})
      }else if(distUplineError?.message) {
        dispatch({ type: actions.SNACKBAR_SHOW, payload: {snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: distUplineError?.message }});
      }
    }
    }, [distUplineList, distUplineError]);

    useEffect(() => {
      if(isNullResponse(distUplineDropdownListData)&&!distUplineDropdownListData.hasOwnProperty('status')){ 
      if (isNullResponse(distUplineDropdownListData.data) && distUplineDropdownListData?.error===false){
        console.log("got the Response: ",distUplineDropdownListData);
        setDistUplineDropdownList(distUplineDropdownListData)
        setRanksData(distUplineDropdownListData.data?.ranks);
        setLoader(false);
      }else {
        if( allNullTypeCheck(distUplineDropdownListData?.data) && Object.keys(distUplineDropdownListData?.data).length===0 && allNullTypeCheck(distUplineDropdownListData?.messages)) {
          if(distUplineDropdownListData?.messages?.length > 0 && distUplineDropdownListData?.messages[0]?.includes("Try again")){
            console.log("Try again block entered : ",distUplineDropdownListData?.data);
            setTimeout(()=>{
              dispatch(getAllRanks());
            },retrydelayTime1*1000)
            setRetrydelayTime1(retrydelayTime1*2);
        }
      }
      setLoader(false);
    }
    }else {
      if(distUplineDropdownListData.hasOwnProperty('status') && distUplineDropdownListData.status===202){
        setLoader(true);
        setTimeout(()=>{
          dispatch(getAllRanks());
        },retrydelayTime1*1000)
        setRetrydelayTime1(retrydelayTime1*2);
    }
  }
    if(isNullResponse(distUplineDropdownError)&& distUplineDropdownError?.data?.error===true){
      console.log("General rank info failed :", distUplineDropdownError);
      setLoader(false);
      if(distUplineDropdownError?.data?.error === true){
        dispatch({ type: actions.SNACKBAR_SHOW, payload: {snackbarOpen: true, snackbarType:  Constant.SNACKBAR_TYPE.error, snackbarMessage: typeof(distUplineDropdownError?.data?.messages[0])!=='string'? distUplineDropdownError?.data?.messages[0]?.code+' - '+distUplineDropdownError?.data?.messages[0]?.detail:distUplineDropdownError?.data?.messages[0]}})
      }else if(distUplineDropdownError?.message) {
        dispatch({ type: actions.SNACKBAR_SHOW, payload: {snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: distUplineDropdownError?.message }});
      }
    }
    }, [distUplineDropdownListData, distUplineDropdownError]);
  
    useEffect(() => {
      if(isNullResponse(downlineDistRankInfo)&&!downlineDistRankInfo.hasOwnProperty('status')){ 
      if (isNullResponse(downlineDistRankInfo.data) && downlineDistRankInfo?.error===false){
        console.log("got the Response: ",downlineDistRankInfo);
        setDistRankInfoList(downlineDistRankInfo);
        setLoader(false);
        setDownlineLoading(false);
      }else {
        if( allNullTypeCheck(downlineDistRankInfo?.data) && Object.keys(downlineDistRankInfo?.data).length===0 && allNullTypeCheck(downlineDistRankInfo?.messages)) {
          if(downlineDistRankInfo?.messages?.length > 0 && downlineDistRankInfo?.messages[0]?.includes("Try again")){
            console.log("Try again block entered : ",downlineDistRankInfo?.data);
            setTimeout(()=>{
              dispatch(getDownlineDistRankInfoAPI(selectedRow, selectedRunID));
            },retrydelayTime2*1000)
            setRetrydelayTime2(retrydelayTime2*2);
        }
      }
      setDownlineLoading(false);
    }
    }else {
      if(downlineDistRankInfo.hasOwnProperty('status') && downlineDistRankInfo.status===202){
        setDownlineLoading(true);
        setTimeout(()=>{
          dispatch(getDownlineDistRankInfoAPI(selectedRow, selectedRunID));
        },retrydelayTime2*1000)
        setRetrydelayTime2(retrydelayTime2*2);
    }
  }
    if(isNullResponse(distRankInfoError)&& distRankInfoError?.data?.error===true){
      console.log("General rank info failed :", distRankInfoError);
      setLoader(false);
      setDownlineLoading(false);
      if(distRankInfoError?.data?.error === true){
        dispatch({ type: actions.SNACKBAR_SHOW, payload: {snackbarOpen: true, snackbarType:  Constant.SNACKBAR_TYPE.error, snackbarMessage: typeof(distRankInfoError?.data?.messages[0])!=='string'? distRankInfoError?.data?.messages[0]?.code+' - '+distRankInfoError?.data?.messages[0]?.detail:distRankInfoError?.data?.messages[0]}})
      }else if(distRankInfoError?.message) {
        dispatch({ type: actions.SNACKBAR_SHOW, payload: {snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: distRankInfoError?.message }});
      }
    }
    }, [downlineDistRankInfo, distRankInfoError]);

    useEffect(() => {
      if(isNullResponse(downlineDistVolumeList)&&!downlineDistVolumeList.hasOwnProperty('status')){ 
      if (isNullResponse(downlineDistVolumeList.data) && downlineDistVolumeList?.error===false){
        console.log("got the Response: ",downlineDistVolumeList);
        setDistVolumeList(downlineDistVolumeList);
        setLoader(false);
        setDownlineLoading(false);
      }else {
        if( allNullTypeCheck(downlineDistVolumeList?.data) && Object.keys(downlineDistVolumeList?.data).length===0 && allNullTypeCheck(downlineDistVolumeList?.messages)) {
          if(downlineDistVolumeList?.messages?.length > 0 && downlineDistVolumeList?.messages[0]?.includes("Try again")){
            console.log("Try again block entered : ",downlineDistVolumeList?.data);
            setTimeout(()=>{
              dispatch(getDownlineDistVolumes(selectedRow, selectedRunID));
            },retrydelayTime3*1000)
            setRetrydelayTime3(retrydelayTime3*2);
        }
      }
      setLoader(false);
      setDownlineLoading(false);
    }
    }else {
      if(downlineDistVolumeList.hasOwnProperty('status') && downlineDistVolumeList.status===202){
        setDownlineLoading(true);
        setTimeout(()=>{
          dispatch(getDownlineDistVolumes(selectedRow, selectedRunID));
        },retrydelayTime3*1000)
        setRetrydelayTime3(retrydelayTime3*2);
    }
    }
    if(isNullResponse(distVoluemError)&& distVoluemError?.data?.error===true){
      console.log("General rank info failed :", distVoluemError);
      setLoader(false);
      setDownlineLoading(false);
      if(distVoluemError?.data?.error === true){
        dispatch({ type: actions.SNACKBAR_SHOW, payload: {snackbarOpen: true, snackbarType:  Constant.SNACKBAR_TYPE.error, snackbarMessage: typeof(distVoluemError?.data?.messages[0])!=='string'? distVoluemError?.data?.messages[0]?.code+' - '+distVoluemError?.data?.messages[0]?.detail:distVoluemError?.data?.messages[0]}})
      }else if(distVoluemError?.message) {
        dispatch({ type: actions.SNACKBAR_SHOW, payload: {snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: distVoluemError?.message }});
      }
    }
    }, [downlineDistVolumeList,distVoluemError]);
  
  
    useEffect(() => {
        dispatch({ type: DISTRIBUTOR_UPLINE_SUCCESS, payload: {} });
        dispatch({ type: DIST_UPLINE_DROP_SUCCESS, payload: [] });
        dispatch({ type: DOWNLINE_DIST_RANKINFO_SUCCESS, payload: {} })
        dispatch(getDistributorUpline(distID, selectedRunID,''));
        dispatch(getAllRanks());
        return ()=>{
          dispatch({ type: DOWNLINE_DIST_RANKINFO_SUCCESS, payload: {} });
          dispatch({ type: DOWNLINE_DIST_VOLUME_SUCCESS, payload: {} });
        }
      }, []);
   const getRowDownlineDetails=(data)=>{
        dispatch({ type: DOWNLINE_DIST_RANKINFO_SUCCESS, payload: {} })
        dispatch({ type: DOWNLINE_DIST_VOLUME_SUCCESS, payload: {} })
        setselectedRow(data.distributorId);
        setSelected(true)
        setDownlineLoading(true)
        dispatch(getDownlineDistRankInfoAPI(data.distributorId, selectedRunID));
        dispatch(getDownlineDistVolumes(data.distributorId, selectedRunID));
    };
    const handleTreeView=(distributorId)=>{
      localStorage.setItem(envName + "-distID", JSON.stringify(distributorId));
      localStorage.setItem(
        envName + "-selectedRun",
        JSON.stringify(selectedRunID)
      );
      localStorage.setItem(envName + "-periodtype", JSON.stringify(periodtype));
      localStorage.setItem(envName + "-periodDate", JSON.stringify(periodDate));
      // window.open(URL, "/commissiontools/distributorresearch/1");
      window.open(
        "/commissions/commissiontools/distributorresearch/1",
        distributorId,
        ""
      );
    }
    const handleChange = (event) => {
        console.log("Select comm type", event);
        let uplineJson = filterById(uplineAllOption(distUplineDropdownList?.data?.ranks),event );
        setSelectedValue(uplineJson.name);
        setDropdownLoading(true);
        setSelected(false)
        setselectedRow('')
        setDropdownValue(event);
        dispatch(getDistributorUpline(distID,selectedRunID,event));
    };
    const getDistributorSpecificVolume=(distributor, volField)=> {
        return distributor.volumes.find(v=>v.field==volField);
    }
    const filterbasedonLevel = (data) => {
      let sorted  = data.sort((a, b) => a.level < b.level ? 1:-1)
      return sorted
    }
    const filterData = (data) => {
      if(selectedValue !== 'All'){
        return data.filter(w => w.paidRank === selectedValue);
      }
      else return data
    }
    const uplineAllOption = (data) => {
      return [{"name": 'All',"id": '0'}, ...data]
    }
   const filterById=(jsonObject, id)=> { return jsonObject.filter(function(jsonObject) {return (jsonObject['id'] == id);})[0];}; 
if(loader){   
      return <div><div id="center" style={{ position: 'fixed', bottom: '40%', left: '48%' }}>
      <Oval type="Puff" position="fixed"  secondaryColor="#E85557" color="#E85557" height={50} width={100} visible={true} /> </div></div>;
} else {
  if(dropdownLoading){
      dropdownSpinner =  (<div><div id="center" style={{ position: 'absolute', bottom: '50%', justifyContent:'center', textAlign:'center' }}>
         <Oval type="Puff" position="fixed"  secondaryColor="#E85557" color="#E85557" height={50} width={100} visible={true} /> </div></div>);
  }
  if(downlineLoading){
    downlineSpinner = (<div><div id="center" style={{ position: 'absolute', bottom: '50%', left:'20%', justifyContent:'center', textAlign:'center' }}>
    <Oval type="Puff" position="fixed"  secondaryColor="#E85557" color="#E85557" height={50} width={100} visible={true} /> </div></div>);
  }
return (
<div className="distres-generaltable">
<div className="col-10 distres-Earning-tbcontainer">
<div className="upline-title-dropdown">
     <div className="col-2 uplineview-title"><span className="earnings-tab-title">{"Upline View"}</span></div>
     <div className="row col-3 uplineAlignitems-center">
                <Dropdown className="upline-height"
                onSelect={(e)=>handleChange(e)}
                style={{
                    backgroundColor: "white",
                    marginTop: "8px",
                }}
                >
                <Dropdown.Toggle
                    id="dropdown-custom-components"
                    title="Select Type"
                >
                    {selectedValue}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {(isNullResponse(distUplineDropdownList?.data)&&  distUplineDropdownList?.data?.ranks?.length>0)? uplineAllOption(distUplineDropdownList?.data?.ranks)?.map((item) => {
                    return (
                        <Dropdown.Item
                        className="upline-primary-dropbtn"
                        eventKey={item.id}
                        >
                        {item.name}
                        </Dropdown.Item>
                    );
                    }):''}
                </Dropdown.Menu>
                </Dropdown>
        </div>
   </div>
 <div className="distres-upline-scroll">
<TableContainer>
          {dropdownLoading? <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
            }}>{ dropdownSpinner }</div>:
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            {(isNullResponse(distUplineListData?.data)&&filterData(distUplineListData?.data?.upline)?.length>0)? <><TableHead>
              <TableCell
                className="downline-table-header"
                component="th"
                scope="row"
              >
                {"ID"}
              </TableCell>
              <TableCell
                className="runmanager-table-header"
                component="th"
                scope="row" align="left"
              >
                {"Name"}
              </TableCell>
              <TableCell
                className="runmanager-table-header"
                component="th"
                scope="row" align="left"
              >
                {"Paid Rank"}
              </TableCell>
              <TableCell
                className="downline-table-header"
                component="th"
                scope="row"
              >
                {"Level"}
              </TableCell>
              {(isNullResponse(distUplineListData?.data) && distUplineListData?.data?.volumeHeaders?.length > 0) ?
                distUplineListData?.data?.volumeHeaders?.map((data) => (
                  <TableCell
                    className="downline-table-header"
                    component="th"
                    scope="row"
                  >
                    {data?.label}
                  </TableCell>)) : ''}
            </TableHead>
            <TableBody>
              {(isNullResponse(distUplineListData?.data) && distUplineListData?.data?.upline?.length > 0) ? filterbasedonLevel(filterData(distUplineListData?.data?.upline)).map((data) => {
                return (<TableRow hover selected={data.distributorId === selectedRow} onClick={() => getRowDownlineDetails(data)} style={{ cursor: 'pointer' }}>
                  <TableCell className="table-cell-data-right-alignment">{data?.distributorId}</TableCell>
                  <TableCell className="" align="left" >{data?.name}</TableCell>
                  <TableCell className="" align="left">{data?.paidRank}</TableCell>
                  <TableCell className="table-cell-data-right-alignment">{data?.level}</TableCell>
                  {(isNullResponse(distUplineListData?.data) && distUplineListData?.data?.volumeHeaders?.length > 0) ?
                    distUplineListData?.data?.volumeHeaders?.map((headers) => (
                      <TableCell className="table-cell-data-right-alignment">{numberWithCommas((getDistributorSpecificVolume(data, headers?.field)?.value)?.toFixed(2))}</TableCell>
                    )) : 'test'}
                </TableRow>
                );
              }) : 'test'}
            </TableBody>
            </>
            :<TableRow className="no-data-image">
                  <TableCell colSpan={9} align='center' className="no-data-image">
                    <img src={nodatafound} alt="" />
                  </TableCell>
                  </TableRow>}
          </Table>}
			</TableContainer>
            </div>
</div>
<div className="col-2 distres-downline-rightcontainer distres-tabs-scroll">
<TableContainer>
{downlineLoading? <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
            }}>{ downlineSpinner }</div>:
    <>
    {distRankInfoList?.data && selected?
       <Table sx={{ minWidth: 650 }}>
					<TableHead>
                        <TableRow>
                        <TableCell colSpan={2}>
                            <div className="downline_selected">
                                <div className="col-6 remove_cols-padding">{distRankInfoList?.data?.distributorId}</div>
                                <div className="col-6 remove_cols-padding goto-display" onClick={()=>handleTreeView(distRankInfoList?.data?.distributorId)} ><img src={externalLink} alr=""/><span className="treeview-selection">{"GO TO"}</span></div>
                            </div>
                            <div>{distRankInfoList?.data?.name}</div>
                            </TableCell>
                        </TableRow>
					</TableHead>
                    <TableBody className="distres-downline-tbody">                    
                        <TableRow>
                            <TableCell><span className="downline_rank_info">{"Country"}</span></TableCell>
                            <TableCell className="updowntable-cell-data-right-alignment"><span className="upline-cell-align">{distRankInfoList?.data?.countryCode ? distRankInfoList?.data?.countryCode + '(' + distRankInfoList?.data?.primaryCountryCode +')': ''}</span></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><span className="downline_rank_info">{"Status"}</span></TableCell>
                            <TableCell className="updowntable-cell-data-right-alignment"><span className="upline-cell-align">{distRankInfoList?.data?.status}</span></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><span className="downline_rank_info">{"Begin Rank"}</span></TableCell>
                            <Tooltip
                             title={distRankInfoList?.data?.beginRank?.id}
                                                placement="top"
                                                arrow>
                            <TableCell className="updowntable-cell-data-right-alignment"><span className="upline-cell-align">{getRankName(distRankInfoList?.data?.beginRank?.id, ranksData)}</span></TableCell>
                            </Tooltip>
                        </TableRow>
                        <TableRow>
                            <TableCell ><span className="downline_rank_info">{"Leg Paid Rank"}</span></TableCell>
                            <Tooltip
                             title={distRankInfoList?.data?.paidRank?.id}
                                                placement="top"
                                                arrow>
                            <TableCell className="col-6 updowntable-cell-data-right-alignment"><span className="upline-cell-align">{getRankName(distRankInfoList?.data?.paidRank?.id, ranksData)}</span></TableCell>
                            </Tooltip>
                        </TableRow>  
                        <TableRow>
                            <TableCell><span className="downline_rank_info">{"End Rank"}</span></TableCell>
                            <Tooltip
                             title={distRankInfoList?.data?.endRank?.id}
                                                placement="top"
                                                arrow>
                            <TableCell className="updowntable-cell-data-right-alignment"><span className="upline-cell-align">{getRankName(distRankInfoList?.data?.endRank?.id, ranksData)}</span></TableCell>
                            </Tooltip>
                        </TableRow>
                        {(isNullResponse(distVolumeList?.data)&&distVolumeList?.data?.volumes?.length>0)? distVolumeList?.data?.volumes?.map((volData)=>(
                        <TableRow>
                            <TableCell><span className="downline_rank_info">{volData?.label}</span></TableCell>
                            <TableCell className="updowntable-cell-data-right-alignment"><span className="upline-cell-align">{numberWithCommas((volData?.value)?.toFixed(2))}</span></TableCell>
                        </TableRow>)  
                        ):''}
                    </TableBody>
				</Table>:''}
        </>}
			</TableContainer>
    </div>
  </div>);
 }
}

export default UplineTab;