import './UndoConfirmModal.css';
import vector13 from '../../../assets/vector-13@2x.png';
import undowarning from '../../Commissions/assets/undowarning.svg';
import { Modal } from "react-bootstrap";

export const UndoConfirmModal = (props) => {
    const { title, bodyModelText, bodyModelText1, enableModel, closeSelectedRun, selectUndoRunStatus } = props;
    return (
        <Modal show={enableModel} onHide={false} >
            <Modal.Header>
                <div className="flex-row-1-ei">
                    <div className="header-name h3-header-3-18pt-bold-lato">{title}</div>
                    <img className="close-vector-3-ei" src={vector13} onClick={closeSelectedRun} alt="" />
                </div>
            </Modal.Header>
            <Modal.Body style={{ height: '140px' }}>
                <div className="row">
                    <div className="col-3">
                        <img className="warning-icon" src={undowarning} alt="" />
                    </div>
                    <div className="col-9">
                        <div className=" paragraph-1-14pt-regular-lato" style={{ marginTop: '14px' }}>{bodyModelText}</div>
                        <div className=" paragraph-1-14pt-regular-lato">
                        {bodyModelText1}</div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer style={{ marginTop: "0px" }}>
                <div className="row">
                    <div className="undocmcolb-no-btn column" onClick={closeSelectedRun}>
                        <div className="colb-no-text">NO</div>
                    </div>
                    <div className="colb-yes-btn column" onClick={selectUndoRunStatus}>
                        <div className="colb-yes-text">YES</div>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    )
}