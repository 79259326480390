import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import eyeIcon from "../assets/eye-icon.svg";
import {useSelector, useDispatch} from 'react-redux'
import AuthService from '../../server/AuthService';
import './GeneralTab.css';
import { singletonAxiosInstance } from '../../utilities/Server';
import Endpoints from '../../utilities/Endpoints';
import { Oval } from "react-loader-spinner";
import { DISTRIBUTOR_RUNINFO_SUCCESS, DISTRIBUTOR_RUNINFO_ERROR, getDistributorRankInfoAPI } from "../../../components/Commissions/actions";
import { allNullTypeCheck, isNullResponse } from "../commonComponents/UtilityFun";
import GeneralTabRankMatrix from "./GeneralTabRankMatrix";
import RankMatrixModel from "./RankMatrixModel";
import * as Constant from '../../../constants/Constants';
import actions from "../../../redux/actions/CommonDataActions";

const server = singletonAxiosInstance;
const options = {
 Authorization: 'Bearer ' + localStorage.getItem('id_token')
 //Authorization: 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjU4MjQyMTVEODhGQkE4RTEzQkZGN0FFNTk0MzAyODM5RkU1M0ZGOENSUzI1NiIsInR5cCI6ImF0K2p3dCIsIng1dCI6IldDUWhYWWo3cU9FN18zcmxsREFvT2Y1VF80dyJ9.eyJuYmYiOjE2MzM0OTA0MzksImV4cCI6MTY2NTAyNjQzOSwiaXNzIjoiaHR0cHM6Ly9kZXYtYXRvbWljLmZsZXhjbG91ZC5jb20vaWRlbnRpdHkiLCJjbGllbnRfaWQiOiIwZDkzNGI5MS03NjA3LTQ3OWUtYjMyOS0xOTAyNjhiZmRjZDIiLCJjbGllbnRfbmFtZSI6ImRldi1hdG9taWMiLCJqdGkiOiIxNTk0MTA3RDFGRjM0RTMyNDcwMTZGRjE4Q0Q2QjdERCIsImlhdCI6MTYzMzQ5MDQzOSwic2NvcGUiOlsiL2NvbW1pc3Npb25zIl19.VOq7hVNtlqz3yhuag0yYrqMXefpTfI3upweQy2DUt9mO6Wd6iHhd8S3JCamTCYY-Fr8rlxIPEXNb8Rx7fg1e4LCrcXVQoOLVg65QBL3r2xuMQE7PQ_c4MF9C61xxQWrFAC2jjvNWztrb5pcv9B73XVv1MCJVajxnYeyrKVdxP3VK8R9L_OHqEzpXzKfz6PW0BiXgvPIl92NtHDE1mvC6YXXXcVwHuTAFBu8tZdvskr_wm0iNaY7niY8QzKXvY5DkWFdknaSeGP9HJwukdGzdY-ObHg5JDl2Swb0ienhOqQ4mKrIA1_kU9tcfk6k4dAPpYkvwcwPB-WQfNECuB1qxJw'
};
const statCodes = {
  SUCCESS: 200,
  CREATE_SUCCESS: 201,
  unAuthorized: 403
};

export const GeneralTab =({ distID,selectedRunID } )=>{
    const [loader, setLoader] = useState(true);
    const [rankId,setRankID] =useState('');
    const [enableMatrix, setEnbaleMatrix] = useState(false);
    const [detailKey,setDetailKey] = useState(false);
    const [distRankInfo, setDistRankInfo] = useState({});
    const [retrydelayTime, setRetrydelayTime] = useState(1);
    const distRankInfoProp = useSelector(state => state.distributorResearchReducer.distRankInfoList)
    const distRankInfoError = useSelector(state => state.distributorResearchReducer.distRankInfoError)
    

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getDistributorRankInfoAPI(distID, selectedRunID));
      }, []);  

     useEffect(()=>{
      if(isNullResponse(distRankInfoProp)&&!distRankInfoProp.hasOwnProperty('status')){ 
        if (isNullResponse(distRankInfoProp?.data) && distRankInfoProp?.error===false){
          console.log("got the Response: ",distRankInfoProp);
          setDistRankInfo(distRankInfoProp?.data)
          setLoader(false);
        }else {
          if(allNullTypeCheck(distRankInfoProp?.data) && Object.keys(distRankInfoProp?.data).length===0 && allNullTypeCheck(distRankInfoProp?.messages)) {
            if(distRankInfoProp?.messages?.length > 0 && distRankInfoProp?.messages[0]?.includes("Try again")){
              console.log("Try again block entered : ",distRankInfoProp?.data);
              setTimeout(()=>{
                dispatch(getDistributorRankInfoAPI(distID, selectedRunID));
              },retrydelayTime*1000)
              setRetrydelayTime(retrydelayTime*2);
          }
        }
        setLoader(false);
      }
     }else {
          if(distRankInfoProp.hasOwnProperty('status') && distRankInfoProp.status===202){
            setLoader(true);
            setTimeout(()=>{
              dispatch(getDistributorRankInfoAPI(distID, selectedRunID));
            },retrydelayTime*1000)
            setRetrydelayTime(retrydelayTime*2);
        }
      }
     },[distRankInfoProp]); 
     
     useEffect(()=>{
      if(isNullResponse(distRankInfoError)&& distRankInfoError?.data?.error===true){
        console.log("General rank info failed :", distRankInfoError);
        setLoader(false);
        if(distRankInfoError?.data?.error === true){
          dispatch({ type: actions.SNACKBAR_SHOW, payload: {snackbarOpen: true, snackbarType:  Constant.SNACKBAR_TYPE.error, snackbarMessage: typeof(distRankInfoError?.data?.messages[0])!=='string'? distRankInfoError?.data?.messages[0]?.code+' - '+distRankInfoError?.data?.messages[0]?.detail:distRankInfoError?.data?.messages[0]}})
        }else if(distRankInfoError?.message) {
          dispatch({ type: actions.SNACKBAR_SHOW, payload: {snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: distRankInfoError?.message }});
        }
      }
     },[distRankInfoError])
    // const getDistributorRankInfoAPI = (distID,runID) => {
    //     return dispatch => {
    //       if (AuthService.isTokenValid() === true) {
    //         server.get(Endpoints.CommissionRuns+'/'+runID+"/distributors/"+distID+"/rankInfo", '', options).then((data) => {
    //             // setLoader(false);
    //           if (data.status === statCodes.SUCCESS) {
    //             if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRun Details res :', data); }
    //             dispatch({ type: DISTRIBUTOR_RUNINFO_SUCCESS, payload: data.data });
    //           } else {
    //             dispatch({ type: DISTRIBUTOR_RUNINFO_ERROR, payload: data });
    //           }
    //         }).catch((error) => {
    //             setLoader(false);
    //           if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
    //           if (!error.response) {
    //             dispatch({ type: DISTRIBUTOR_RUNINFO_ERROR, payload: error });
    //           } else {
    //             dispatch({ type: DISTRIBUTOR_RUNINFO_ERROR, payload: error.response });
    //           }
      
    //         });
    //       } else {
    //         const user = AuthService.signinSilentToken();
    //       }
    //     }
    // }
    const handleMatrix=(id, rankLabel, detailKey)=>{
      console.log("handled matrix ;:")
      setRankID(id);
      setDetailKey(detailKey);
      setEnbaleMatrix(true);
    }
    const closefunction=()=>{
      setEnbaleMatrix(false);
    }

if(loader){   
        return <div><div id="center" style={{ position: 'fixed', bottom: '40%', left: '48%' }}>
        <Oval type="Puff" position="fixed"  secondaryColor="#E85557" color="#E85557" height={50} width={100} visible={true} /> </div></div>;
} 
return (

<div className="distres-generaltable distres-tabs-scroll">
<div className="rectangle-2556"></div>
<div className="col-6 distres-general-tabcontainer">
<TableContainer>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
                        <TableCell
                            className="runmanager-table-header"
                            component="th"
                            scope="row"
                            colSpan={4}
                        >
                            {"Rank Info"}
                        </TableCell>
					</TableHead>
          {isNullResponse(distRankInfo)?
                    <TableBody>
                        <TableRow>
                            <TableCell>{distRankInfo?.beginRank?.label}</TableCell>
                            <TableCell className="table-cell-data-right-alignment">{distRankInfo?.beginRank?.id}</TableCell>
                            <TableCell>{distRankInfo?.beginRank?.hasDetail===true ? <img src={eyeIcon} onClick={()=>handleMatrix(distRankInfo?.beginRank?.id, distRankInfo?.beginRank?.label, distRankInfo?.beginRank?.detailKey)} alt="" style={{cursor: 'pointer'}}/>: ''}</TableCell>
                            <TableCell>{distRankInfo?.beginRank?.name+"("+distRankInfo?.beginRank?.abbreviation+")"}</TableCell>
                            
                        </TableRow>
                        {/* <TableRow>
                            <TableCell>{distRankInfo?.currentRank?.label}</TableCell>
                            <TableCell className="table-cell-data-right-alignment">{distRankInfo?.currentRank?.id}</TableCell>
                            <TableCell>{distRankInfo?.currentRank?.name+"("+distRankInfo?.currentRank?.abbreviation+")"}</TableCell>
                            <TableCell>{}</TableCell>
                        </TableRow> */}
                        <TableRow>
                            <TableCell>{distRankInfo?.endRank?.label}</TableCell>
                            <TableCell className="table-cell-data-right-alignment">{distRankInfo?.endRank?.id}</TableCell>
                            <TableCell>{distRankInfo?.endRank?.hasDetail===true ? <img src={eyeIcon} onClick={()=>handleMatrix(distRankInfo?.endRank?.id, distRankInfo?.endRank?.label, distRankInfo?.endRank?.detailKey)} alt="" style={{cursor: 'pointer'}}/>: ''}</TableCell>
                            <TableCell>{distRankInfo?.endRank?.name+"("+distRankInfo?.endRank?.abbreviation+")"}</TableCell>
                            
                        </TableRow>
                        <TableRow>
                            <TableCell>{distRankInfo?.paidRank?.label}</TableCell>
                            <TableCell className="table-cell-data-right-alignment">{distRankInfo?.paidRank?.id}</TableCell>
                            <TableCell>{distRankInfo?.paidRank?.hasDetail===true ? <img src={eyeIcon} onClick={()=>handleMatrix(distRankInfo?.paidRank?.id, distRankInfo?.paidRank?.label, distRankInfo?.paidRank?.detailKey )} alt="" style={{cursor: 'pointer'}}/>: ''}</TableCell>
                            <TableCell>{distRankInfo?.paidRank?.name+"("+distRankInfo?.paidRank?.abbreviation+")"}</TableCell>
                            
                        </TableRow>
                        <TableRow>
                            <TableCell>{"Sponsor:"}</TableCell>
                            <TableCell className="table-cell-data-right-alignment">{distRankInfo?.sponsorId}</TableCell>
                            <TableCell>{}</TableCell>
                            <TableCell>{}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{"Enroller:"}</TableCell>
                            <TableCell className="table-cell-data-right-alignment">{distRankInfo?.enrollerId}</TableCell>
                            <TableCell>{}</TableCell>
                            <TableCell>{}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{"Marketing Unit:"}</TableCell>
                            <TableCell className="table-cell-data-right-alignment">{distRankInfo?.marketingUnit}</TableCell>
                            <TableCell>{}</TableCell>
                            <TableCell>{}</TableCell>
                        </TableRow>
                    </TableBody>
                    :''}
				</Table>
			</TableContainer>
</div>

{/* <div className="col-8 distres-general-rightcontainer">
<TableContainer>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
                        <TableCell
                            className="runmanager-table-header"
                            component="th"
                            scope="row"
                            colSpan={2}
                        >
                            {"Most Valuable Info"}
                        </TableCell>
					</TableHead>
                    <TableBody className="distres-general-tbody">
                        <TableRow>
                            <TableCell>{distRankInfo.name}</TableCell>
                            <TableCell>{distRankInfo.id}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{distRankInfo.name}</TableCell>
                            <TableCell>{distRankInfo.id}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{distRankInfo.name}</TableCell>
                            <TableCell>{distRankInfo.id}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{distRankInfo.name}</TableCell>
                            <TableCell>{distRankInfo.id}</TableCell>
                        </TableRow>
                    </TableBody>
				</Table>
			</TableContainer>

    </div> */}
    {enableMatrix?<RankMatrixModel
      distributorID = {distID}
      runID = {selectedRunID}
      currentRankID = {rankId}
      detailKey = {detailKey}
     closefunction={()=>closefunction()}/>:''}
</div>);


}