import { DISTRIBUTOR_RUNINFO_SUCCESS, DISTRIBUTOR_RUNINFO_ERROR, DISTRIBUTOR_VOLUME_SUCCESS, 
    DISTRIBUTOR_VOLUME_ERROR, DISTRIBUTOR_EARNINGS_SUCCESS, DISTRIBUTOR_EARNINGS_ERROR, DIST_EARNINGSDETAILS_SUCCESS,
     DIST_EARNINGSDETAILS_ERROR, DIST_WORKFIELDS_SUCCESS, DIST_WORKFIELDS_ERROR, DISTRIBUTOR_DOWNLINE_SUCCESS, 
     DISTRIBUTOR_DOWNLINE_ERROR, DOWNLINE_DIST_RANKINFO_SUCCESS, DOWNLINE_DIST_RANKINFO_ERROR, DOWNLINE_DIST_VOLUME_SUCCESS,
      DOWNLINE_DIST_VOLUME_ERROR, DISTRIBUTOR_UPLINE_ERROR, DISTRIBUTOR_UPLINE_SUCCESS, DIST_UPLINE_DROP_SUCCESS,
      DIST_RESEARCH_WORKFIELD_DETAILS,DIST_RESEARCH_WORKFIELD_ERROR, DIST_UPLINE_DROP_ERROR, DIST_RESEARCH_SELECTEDTAB,SELECTED_COUNTRY_INFO_ERROR,
      SELECTED_COUNTRY_INFO, AMOUNTFIELD_MATRIX_DETAILS, AMOUNTFIELD_MATRIX_ERROR, VOLFIELD_MATRIX_DETAILS, VOLFIELD_MATRIX_ERROR,
      RANK_MATRIX_DETAILS_SUCCESS, RANK_MATRIX_DETAILS_ERROR, DISTRIBUTOR_QUAL_ERROR, DISTRIBUTOR_QUAL_SUCCESS  } from "../actions"

const initialState = {
   distRankInfoList : [],
   distRankInfoError : {},
   distVolumeList :[],
   distVolumeError:{},
   distEarningsList:[],
   distEarningsError:{},
   distEarningDetailsList:[],
   distEarningDetailsError:{},
   distWorkFieldList:[],
   distWorkFieldError:{},
   distDownlineList:[],
   distDownlineError:{},
   downlineDistRankInfo:{},
   downlineDistRankInfoError:{},
   downlineDistVolumeList:[],
   downlineDistVolumeError:{},
   distUplineList:[],
   distUplineError:{},
   distUplineDropdownList:[],
   distUplineDropdownError:{},
   distResearchSelectedTab:0,
   distWorkFieldDetailsList:[],
   distWorkFieldDetailsError:{},
   earningsBySelectedCountry: [],
   earningsBySelectedCountryError: {},
   amountFieldDetailsList:[],
   amountFieldDetailsError:{},
   volFieldDetailsList: [],
   volFieldDetailsError: {},
   rankMatrixDetailsList:[],
   rankMatrixDetailsError:{},
   distributorQualList:[],
   distributorQualError:{},

}

export default function distributorResearchReducer(state,action){
   if (typeof state === 'undefined') {
       return initialState;
   }  
   switch (action.type) {
       case DISTRIBUTOR_RUNINFO_SUCCESS:
           return { ...state,  distRankInfoList : action.payload };
       case DISTRIBUTOR_RUNINFO_ERROR:
           return { ...state,  distRankInfoError : action.payload };
       case DISTRIBUTOR_VOLUME_SUCCESS:
           return { ...state,  distVolumeList : action.payload };
       case DISTRIBUTOR_VOLUME_ERROR:
           return { ...state,  distVolumeError : action.payload };
       case DISTRIBUTOR_EARNINGS_SUCCESS:
           return { ...state,  distEarningsList : action.payload };
       case DISTRIBUTOR_EARNINGS_ERROR:
           return { ...state,  distEarningsError : action.payload };
       case DIST_EARNINGSDETAILS_SUCCESS:
           return { ...state,  distEarningDetailsList : action.payload };
       case DIST_EARNINGSDETAILS_ERROR:
           return { ...state,  distEarningDetailsError : action.payload };
       case DIST_WORKFIELDS_SUCCESS:
            return { ...state,  distWorkFieldList : action.payload };
       case DIST_WORKFIELDS_ERROR:
            return { ...state,  distWorkFieldError : action.payload };
       case DISTRIBUTOR_DOWNLINE_SUCCESS:
            return { ...state,  distDownlineList : action.payload };
       case DISTRIBUTOR_DOWNLINE_ERROR:
            return { ...state,  distDownlineError : action.payload };
       case DOWNLINE_DIST_RANKINFO_SUCCESS:
            return { ...state,  downlineDistRankInfo : action.payload };
       case DOWNLINE_DIST_RANKINFO_ERROR:
            return { ...state,  downlineDistRankInfoError : action.payload };
       case DOWNLINE_DIST_VOLUME_SUCCESS:
            return { ...state,  downlineDistVolumeList : action.payload };
       case DOWNLINE_DIST_VOLUME_ERROR:
            return { ...state,  downlineDistVolumeError : action.payload };  
       case DISTRIBUTOR_UPLINE_SUCCESS:
            return { ...state,  distUplineList : action.payload };
       case DISTRIBUTOR_UPLINE_ERROR:
            return { ...state,  distUplineError : action.payload };   
       case DIST_UPLINE_DROP_SUCCESS:
            return { ...state,  distUplineDropdownList : action.payload };
       case DIST_UPLINE_DROP_ERROR:
            return { ...state,  distUplineDropdownError : action.payload };  
       case DIST_RESEARCH_SELECTEDTAB:
            return {...state, distResearchSelectedTab : action.payload };
       case DIST_RESEARCH_WORKFIELD_DETAILS:  
            return {...state, distWorkFieldDetailsList : action.payload };  
       case DIST_RESEARCH_WORKFIELD_ERROR: 
            return {...state, distWorkFieldDetailsError : action.payload };
       case SELECTED_COUNTRY_INFO:
            return {...state, earningsBySelectedCountry : action.payload };
       case SELECTED_COUNTRY_INFO_ERROR:
            return {...state, earningsBySelectedCountryError : action.payload };
       case AMOUNTFIELD_MATRIX_DETAILS:  
            return {...state, amountFieldDetailsList : action.payload };  
       case AMOUNTFIELD_MATRIX_ERROR: 
            return {...state, amountFieldDetailsError : action.payload }; 
       case VOLFIELD_MATRIX_DETAILS:
            return {...state, volFieldDetailsList : action.payload };
       case VOLFIELD_MATRIX_ERROR: 
            return {...state, volFieldDetailsError : action.payload }                        
       case RANK_MATRIX_DETAILS_SUCCESS:
             return {...state, rankMatrixDetailsList : action.payload }   
       case RANK_MATRIX_DETAILS_ERROR:
             return {...state, rankMatrixDetailsError : action.payload }  
       case DISTRIBUTOR_QUAL_SUCCESS:
             return { ...state, distributorQualList:action.payload }
       case DISTRIBUTOR_QUAL_ERROR:
             return {...state, distributorQualError:action.payload } 
       
       default:
       return state;
   } 
}