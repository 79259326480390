import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import rightTick from "../assets/rightTick.svg";
import { Oval } from "react-loader-spinner";
import './GeneralTab.css';
import { useSelector, useDispatch } from 'react-redux'
import { getDistEarningDetails, DISTRIBUTOR_EARNINGS_ERROR, DISTRIBUTOR_EARNINGS_SUCCESS, DIST_EARNINGSDETAILS_SUCCESS, getDistributorEarnings } from "../../../components/Commissions/actions";
import AuthService from '../../server/AuthService';
import { singletonAxiosInstance } from '../../utilities/Server';
import Endpoints from '../../utilities/Endpoints';
import { allNullTypeCheck, isNullResponse } from "../commonComponents/UtilityFun";
import { borderBottom } from "@mui/system";
import { numberWithCommas } from "../../utilities/Services";
import nodatafound from "../assets/nodatafound.svg";
import * as Constant from '../../../constants/Constants';
import actions from "../../../redux/actions/CommonDataActions";

const server = singletonAxiosInstance;
const options = {
  Authorization: 'Bearer ' + localStorage.getItem('id_token')
  //Authorization: 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjU4MjQyMTVEODhGQkE4RTEzQkZGN0FFNTk0MzAyODM5RkU1M0ZGOENSUzI1NiIsInR5cCI6ImF0K2p3dCIsIng1dCI6IldDUWhYWWo3cU9FN18zcmxsREFvT2Y1VF80dyJ9.eyJuYmYiOjE2MzM0OTA0MzksImV4cCI6MTY2NTAyNjQzOSwiaXNzIjoiaHR0cHM6Ly9kZXYtYXRvbWljLmZsZXhjbG91ZC5jb20vaWRlbnRpdHkiLCJjbGllbnRfaWQiOiIwZDkzNGI5MS03NjA3LTQ3OWUtYjMyOS0xOTAyNjhiZmRjZDIiLCJjbGllbnRfbmFtZSI6ImRldi1hdG9taWMiLCJqdGkiOiIxNTk0MTA3RDFGRjM0RTMyNDcwMTZGRjE4Q0Q2QjdERCIsImlhdCI6MTYzMzQ5MDQzOSwic2NvcGUiOlsiL2NvbW1pc3Npb25zIl19.VOq7hVNtlqz3yhuag0yYrqMXefpTfI3upweQy2DUt9mO6Wd6iHhd8S3JCamTCYY-Fr8rlxIPEXNb8Rx7fg1e4LCrcXVQoOLVg65QBL3r2xuMQE7PQ_c4MF9C61xxQWrFAC2jjvNWztrb5pcv9B73XVv1MCJVajxnYeyrKVdxP3VK8R9L_OHqEzpXzKfz6PW0BiXgvPIl92NtHDE1mvC6YXXXcVwHuTAFBu8tZdvskr_wm0iNaY7niY8QzKXvY5DkWFdknaSeGP9HJwukdGzdY-ObHg5JDl2Swb0ienhOqQ4mKrIA1_kU9tcfk6k4dAPpYkvwcwPB-WQfNECuB1qxJw'
};
const statCodes = {
  SUCCESS: 200,
  CREATE_SUCCESS: 201,
  unAuthorized: 403
};

const Earnings = ({ distID, selectedRunID }) => {
  const [loader, setloader] = useState(true);
  const [detailsObj, setdetailsObj] = useState(true);
  const [loadingData, setLoadingData] = useState(false);
  const [distEarningsListData, setDistEarningsListData] = useState({});
  const [distEarningsDetailsList, setDistEarningsDetailsList ] = useState({})
  const [retrydelayTime, setRetrydelayTime] = useState(1);
  const distEarningsList = useSelector(state => state.distributorResearchReducer.distEarningsList);
  const distEarningsError = useSelector(state => state.distributorResearchReducer.distEarningsError)
  const distEarningsDetailList = useSelector(state => state.distributorResearchReducer.distEarningDetailsList);
  const distEarningDetailsError = useSelector(state => state.distributorResearchReducer.distEarningDetailsError);
  const [detailsTitle, setDetailTitle] = useState('');
  const [selectedRow, setselectedRow] = useState('');
  const [selectedDist, setselectedDist] = useState('1000');
  let initialValue = 0.00,spinnerOn='';
  const [displatData, setdisplatData] = useState(false);

  const dispatch = useDispatch();

  // const getDistributorEarnings = (distID, runID) => {
  //   return dispatch => {
  //     if (AuthService.isTokenValid() === true) {
  //       server.get(Endpoints.CommissionRuns + '/' + runID + "/distributors/" + distID + "/earnings", '', options).then((data) => {
  //         setloader(false);
  //         if (data.status === statCodes.SUCCESS) {
  //           if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRun Details res :', data); }
  //           dispatch({ type: DISTRIBUTOR_EARNINGS_SUCCESS, payload: data.data });
  //         } else {
  //           dispatch({ type: DISTRIBUTOR_EARNINGS_ERROR, payload: data });
  //         }
  //       }).catch((error) => {
  //         setloader(false);
  //         if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
  //         if (!error.response) {
  //           dispatch({ type: DISTRIBUTOR_EARNINGS_ERROR, payload: error });
  //         } else {
  //           dispatch({ type: DISTRIBUTOR_EARNINGS_ERROR, payload: error.response });
  //         }

  //       });
  //     } else {
  //       const user = AuthService.signinSilentToken();
  //     }
  //   }
  // }

  useEffect(() => {
    setDetailTitle('');
    dispatch(getDistributorEarnings(distID, selectedRunID));
    return ()=>{
      dispatch({ type: DISTRIBUTOR_EARNINGS_SUCCESS, payload: {} });
      dispatch({ type: DIST_EARNINGSDETAILS_SUCCESS, payload: {} });
    }
  }, []);

  useEffect(()=>{
    if(isNullResponse(distEarningsList)&&!distEarningsList.hasOwnProperty('status')){ 
    if (isNullResponse(distEarningsList.data) && distEarningsList?.error===false){
      console.log("got the Response: ",distEarningsList);
      setRetrydelayTime(1);
      setDistEarningsListData(distEarningsList);
      setloader(false);
    }else {
      if( allNullTypeCheck(distEarningsList?.data) && Object.keys(distEarningsList?.data).length===0 && allNullTypeCheck(distEarningsList?.messages)) {
        if(distEarningsList?.messages?.length > 0 && distEarningsList?.messages[0]?.includes("Try again")){
          setloader(true);
          console.log("Try again block entered : ",distEarningsList?.data);
          setTimeout(()=>{
            dispatch(getDistributorEarnings(distID, selectedRunID));
          },retrydelayTime*1000)
          setRetrydelayTime(retrydelayTime*2);
      }
      }
      setloader(false);
   }
  }else {
      if(distEarningsList.hasOwnProperty('status') && distEarningsList.status===202){
        setloader(true);
        setTimeout(()=>{
          dispatch(getDistributorEarnings(distID, selectedRunID));
        },retrydelayTime*1000)
        setRetrydelayTime(retrydelayTime*2);
      }
  }
  
 },[distEarningsList]); 
 useEffect(()=>{
  if(isNullResponse(distEarningsError)&& distEarningsError?.data?.error===true){
    console.log("General rank info failed :", distEarningsError);
    setloader(false);
    if(distEarningsError?.data?.error === true){
      dispatch({ type: actions.SNACKBAR_SHOW, payload: {snackbarOpen: true, snackbarType:  Constant.SNACKBAR_TYPE.error, snackbarMessage: typeof(distEarningsError?.data?.messages[0])!=='string'? distEarningsError?.data?.messages[0]?.code+' - '+distEarningsError?.data?.messages[0]?.detail:distEarningsError?.data?.messages[0]}})
    }else if(distEarningsError?.message) {
      dispatch({ type: actions.SNACKBAR_SHOW, payload: {snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: distEarningsError?.message }});
    }
    setloader(false);
  }
 },[distEarningsError])



  useEffect(() => {
    if(isNullResponse(distEarningsDetailList)&&!distEarningsDetailList.hasOwnProperty('status')){ 
    if (isNullResponse(distEarningsDetailList.data) && distEarningsDetailList?.error===false){
      console.log("got the Response: ",distEarningsDetailList);
      setDistEarningsDetailsList(distEarningsDetailList);
      setLoadingData(false);
    }else {
      if( allNullTypeCheck(distEarningsDetailList?.data) && Object.keys(distEarningsDetailList?.data).length===0 && allNullTypeCheck(distEarningsDetailList?.messages)) {
        if(distEarningsDetailList?.messages?.length > 0 && distEarningsDetailList?.messages[0]?.includes("Try again")){
          console.log("Try again block entered : ",distEarningsDetailList?.data);
          setTimeout(()=>{
            dispatch(getDistEarningDetails(distID, selectedRunID, selectedRow));
          },retrydelayTime*1000)
          setRetrydelayTime(retrydelayTime*2);
        }
      }
      setLoadingData(false);
    }
  }else {
      if(distEarningsDetailList.hasOwnProperty('status') && distEarningsDetailList.status===202){
        setLoadingData(true)
        setTimeout(()=>{
          dispatch(getDistributorEarnings(distID, selectedRunID));
        },retrydelayTime*1000)
        setRetrydelayTime(retrydelayTime*2);
      }
    }
  
  }, [distEarningsDetailList]);

  useEffect(()=>{
    setLoadingData(false);
    if(isNullResponse(distEarningDetailsError)&& distEarningDetailsError?.data?.error===true){
      console.log("General rank info failed :", distEarningDetailsError);
      setloader(false);
      if(distEarningDetailsError?.data?.error === true){
        dispatch({ type: actions.SNACKBAR_SHOW, payload: {snackbarOpen: true, snackbarType:  Constant.SNACKBAR_TYPE.error, snackbarMessage: typeof(distEarningDetailsError?.data?.messages[0])!=='string'? distEarningDetailsError?.data?.messages[0]?.code+' - '+distEarningDetailsError?.data?.messages[0]?.detail:distEarningDetailsError?.data?.messages[0]}})
      }else if(distEarningDetailsError?.message) {
        dispatch({ type: actions.SNACKBAR_SHOW, payload: {snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: distEarningDetailsError?.message }});
      }
    }
  },[distEarningDetailsError])


  const getRowEarnings = (data) => {
    setloader(false)
    setDetailTitle(data.description);
    setselectedRow(data.commissionTypeId);
    setdisplatData(false)
    dispatch({ type: DIST_EARNINGSDETAILS_SUCCESS, payload: [] })
    setLoadingData(true);
    dispatch(getDistEarningDetails(distID, selectedRunID, data.commissionTypeId));
  };

  const getSelectedEarningData = (object) => {
    console.log('select obj', object)
    setdisplatData(true)
    setselectedDist(object?.distributorId);
    setdetailsObj(object)
  }
  if (loader) {
    return (
      <div>
        <div id="center" style={{ position: "fixed", bottom: "40%", left: "48%" }}>
          <Oval
            type="Puff"
            position="fixed"
            secondaryColor="#E85557"
            color="#E85557"
            height={50}
            width={100}
            visible={true}
          />{" "}
        </div>
      </div>
    );
  }
  else{
    if(loadingData){
     spinnerOn =  <div><div id="center" style={{ position: 'absolute', bottom: '50%', justifyContent:'center', textAlign:'center' }}>
        <Oval type="Puff" position="fixed"  secondaryColor="#E85557" color="#E85557" height={50} width={100} visible={true} /> </div></div>;
    }
  return (
    <div className="distres-generaltable">
      <div className="col-5 distres-Earning-tbcontainer">
        <div className="earnings-title-container">
          <span className="earnings-tab-title">{"Earnings"}</span>
        </div>
        <div>
          <TableContainer className="dist-earning-container">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableCell
                  className="runmanager-table-header"
                  component="th"
                  scope="row"
                >
                  {"Type"}
                </TableCell>
                <TableCell
                  className="runmanager-table-header"
                  component="th"
                  scope="row"
                >
                  {"Description"}
                </TableCell>
                <TableCell
                  className="runmanager-table-header"
                  component="th"
                  scope="row"
                >
                  {"Qualified"}
                </TableCell>
                <TableCell
                  className="downline-table-header"
                  component="th"
                  scope="row"
                >
                  {"Earnings"}
                </TableCell>
              </TableHead>
              <TableBody>
                {(isNullResponse(distEarningsListData?.data) && distEarningsListData?.data?.distributorEarnings.length > 0) ? distEarningsListData?.data?.distributorEarnings?.map((data) => {
                  return (<TableRow hover selected={data?.commissionTypeId === selectedRow} onClick={() => getRowEarnings(data)} style={{height: '39.02px', cursor:'pointer'}}>
                    <TableCell>{data?.commissionTypeId}</TableCell>
                    <TableCell>{data?.description}</TableCell>
                    <TableCell align="center">{data?.qualified ? <img src={rightTick} alt="" /> : ''}</TableCell>
                    <TableCell className="table-cell-data-right-alignment">{numberWithCommas((data?.value)?.toFixed(2))}</TableCell>
                  </TableRow>
                  );
                }) : ''}

                <TableRow  style={{height: 58}}>
                  <TableCell colSpan={3}>
                    {distEarningsListData?.data?.distributorEarnings?.length > 0 ? <div className="earning-total"> {"Total"}</div> : ''}
                  </TableCell>
                  <TableCell className="table-cell-data-right-alignment">{
                   distEarningsListData?.data?.distributorEarnings?.length > 0 ? (isNullResponse(distEarningsListData?.data) && distEarningsListData?.data?.distributorEarnings?.length > 0) ?
                    numberWithCommas(distEarningsListData?.data?.distributorEarnings?.reduce(
                        (previousValue, currentValue) =>
                          previousValue + currentValue.value,
                        initialValue
                      )?.toFixed(2)) : '' : '' 
                  }
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>

      </div>
      <div className="col-7 distres-earnings-rightcontainer">
        <div className="earnings-title-details-container">
          <span className="earnings-tab-title">Earnings Details: <span className="selected-earnings-tab">{detailsTitle}</span></span>
        </div>
        <div className="distres-earning-detail-scroll" style={{borderBottom: '1px solid #dddddd'}}>
          <TableContainer style={{maxHeight: '257px'}}>
            {loadingData? <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
      }}>{ spinnerOn }</div>:
            <Table stickyHeader aria-label="simple table" sx={{ minWidth: 650}}>
              <TableHead>
                <TableCell
                  className="downline-table-header"
                  component="th"
                  scope="row"
                >
                  {"ID"}
                </TableCell>
                <TableCell
                  className="runmanager-table-header"
                  component="th"
                  scope="row"
                >
                </TableCell>
                <TableCell
                  className="runmanager-table-header"
                  component="th"
                  scope="row"
                >
                  {"Paid On"}
                </TableCell>
                <TableCell
                  className="downline-table-header"
                  component="th"
                  scope="row"
                >
                  {"QV"}
                </TableCell>
                <TableCell
                  className="downline-table-header"
                  component="th"
                  scope="row"
                >
                  {"CV"}
                </TableCell>
                <TableCell
                  className="downline-table-header"
                  component="th"
                  scope="row"
                >
                  {"%"}
                </TableCell>
                <TableCell
                  className="downline-table-header"
                  component="th"
                  scope="row"
                >
                  <span className="volume-last-cell-data"> {"Bonus"}</span>
                </TableCell>
              </TableHead>
              <TableBody className="distres-generaltable">
                {distEarningsDetailsList?.data?.distributorEarnings.length > 0 ? distEarningsDetailsList?.data?.distributorEarnings?.map((data) => {
                  return (<TableRow hover selected={data?.distributorId === selectedDist} onClick={()=> getSelectedEarningData(data)} style={{height: '39.02px', cursor:'pointer'}} >
                    <TableCell className="table-cell-data-right-alignment">{data?.distributorId}</TableCell>
                    <TableCell>{data?.qualified ? "q" : ""}</TableCell>
                    <TableCell>{data?.paidOn}</TableCell>
                    <TableCell className="table-cell-data-right-alignment">{numberWithCommas(data?.qualifyingVolume?.toFixed(2))}</TableCell>
                    <TableCell className="table-cell-data-right-alignment">{numberWithCommas(data?.commissionableVolume?.toFixed(2))}</TableCell>
                    <TableCell className="table-cell-data-right-alignment">{data?.percent?.toFixed(1)}</TableCell>
                    <TableCell className="table-cell-data-right-alignment"><span className="volume-last-cell-data">{numberWithCommas(data?.bonus?.toFixed(2))}</span></TableCell>
                  </TableRow>
                  );
                }) : distEarningsDetailsList?.data?.distributorEarnings.length === 0 ? (<TableRow className="no-data-image">
                  <TableCell colSpan={9} align='center' className="no-data-image">
                    <img src={nodatafound} alt="" />
                  </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
                {/* {distEarningsDetailsList?.data?.details.length>0?<TableRow><TableCell colSpan={7}></TableCell></TableRow>:''} */}
              </TableBody>
            </Table>}
          </TableContainer>
        </div>
         {displatData ? <div><Table>
            <TableRow>
              <TableCell>
                {distEarningsDetailsList?.data?.distributorEarnings.length > 0 ? <div>{"Type: "+ detailsObj?.commissionTypeId }</div> : ''}
                {distEarningsDetailsList?.data?.distributorEarnings.length > 0 ? <div className="earnings-subtable-totals">{"Point Value: " + detailsObj?.pointValue?.toFixed(5)} </div> : ''}
              </TableCell>
              <TableCell colSpan={2}>
                {distEarningsDetailsList?.data?.distributorEarnings.length > 0 ? <div>{"Country: " + detailsObj?.countryCode}</div> : ''}
                {distEarningsDetailsList?.data?.distributorEarnings.length > 0 ? <div className="earnings-subtable-totals">{"Bonus: $" + detailsObj?.localBonus?.toFixed(2)}</div> : ''}
              </TableCell>
              <TableCell colSpan={2}>
                {distEarningsDetailsList?.data?.distributorEarnings.length > 0 ? <div>{"Actual Level: " + detailsObj?.actualLevel
                }</div> : ''}
                {distEarningsDetailsList?.data?.distributorEarnings.length > 0 ? <div className="earnings-subtable-totals">{"Paid Level: " + detailsObj?.paidLevel}</div> : ''}
              </TableCell>
              <TableCell>
                {distEarningsDetailsList?.data?.distributorEarnings.length > 0 ? <div>{"Conversion: " + detailsObj?.conversion?.toFixed(5)}</div> : ''}
                {distEarningsDetailsList?.data?.distributorEarnings.length > 0 ? <div className="earnings-subtable-totals">{"Rank: " + detailsObj?.rank}</div> : ''}
              </TableCell>
            </TableRow>
          </Table> </div>: ''}
      </div>
    </div>);
  }
}

export default Earnings;