import React from 'react';
import notfound from '../src/assets/notfound.svg';
import AuthService from './components/server/AuthService';
const NotFound = props => {
    if(AuthService.isTokenValidCheck())
    return (
        <div style={{width: '100%', height: '100%', marginLeft: '35%', marginTop: '5%'}}>
        <img className=""src={notfound} alt='' />
        </div>
    );
};

export default NotFound;
