import {
    SELECTED_QUALRUN_ERROR,
    SELECTED_QUALRUN_SUCCESS,
    RUN_STATUS_SUCCESS,
    RUN_STATUS_ERROR,
    CURRENT_RUN_ID,
    SELECTED_QUALRUN_STOP_SUCCESS,
    SELECTED_QUALRUN_STOP_ERROR,
    SELECTED_QUALRUN_RESET_SUCCESS,
    SELECTED_QUALRUN_RESET_ERROR,
    SELECTED_RUN_PROGRAMS,
    SELECTED_RUN_PROGRAMS_ERROR
} from "../actions"


const initialState = {
    runQualResponse: [],
    runStatusResponse: [],
    currentRunID: '',
    runQualError: {},
    runStatusError: '',
    runQualStopResposne: {},
    runQualStopError: {},
    runQualResetResposne: {},
    runQualResetError: {},
    selectedRunPrgmResponse:{},
    selecteRunPrgmError:{},

}

export default function commissionRunDetailsReducer(state, action) {
    if (typeof state === 'undefined') {
        return initialState;
    }
    switch (action.type) {
        case SELECTED_QUALRUN_SUCCESS:
            return { ...state, runQualResponse: action.payload };
        case SELECTED_QUALRUN_ERROR:
            return { ...state, runQualError: action.payload };
        case RUN_STATUS_SUCCESS:
            return { ...state, runStatusResponse: action.payload };
        case RUN_STATUS_ERROR:
            return { ...state, runStatusError: action.payload };
        case CURRENT_RUN_ID:
            return { ...state, currentRunID: action.payload };
        case SELECTED_QUALRUN_STOP_SUCCESS:
            return { ...state, runQualStopResposne: action.payload };
        case SELECTED_QUALRUN_STOP_ERROR:
            return { ...state, runQualStopError: action.payload };
        case SELECTED_QUALRUN_RESET_SUCCESS:
            return { ...state, runQualResetResposne: action.payload };
        case SELECTED_QUALRUN_RESET_ERROR:
            return { ...state, runQualResetError: action.payload };
        case SELECTED_RUN_PROGRAMS:
            return { ...state, selectedRunPrgmResponse: action.payload };
        case SELECTED_RUN_PROGRAMS_ERROR:
            return { ...state, selecteRunPrgmError: action.payload };
    
        default:
            return state;
    }
}