import { IDENTITY_CONFIG } from '../utilities/authConst';
import jwt_decode from "jwt-decode";
import Oidc from 'oidc-client';
import { valueValidation } from '../utilities/Services';
import { allNullTypeCheck } from '../Commissions/commonComponents/UtilityFun';


class AuthService {
  
  constructor() {
    if (this.UserManager) {
      return this.UserManager;
    } 
    this.UserManager = new Oidc.UserManager({
      ...IDENTITY_CONFIG
    });
    
  }

  loginPage() {         
    if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log('login executed : '); }
    localStorage.setItem(process.env.REACT_APP_BASEURL+'confirmationmail', 'true');
    this.UserManager.signinRedirect();
  }  

  isAuthenticated(){    
    if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log('isAuthenticated called :'); }
    const id_token = localStorage.getItem('id_token');
    if (id_token !== "undefined" && allNullTypeCheck(id_token)) {
      const user = jwt_decode(id_token);
      if (new Date().getTime() < user.exp * 1000) {        
        if(!sessionStorage.getItem('oidc.user:'+process.env.REACT_APP_IDENTITY_BASE_URL+':705a1ebf-d621-4e82-9a7d-f39757de8192')){                 
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log('isAuthenticated called :',(JSON.parse(JSON.stringify(localStorage.getItem("loggedinUser"))))); } 
          sessionStorage.setItem('oidc.user:'+process.env.REACT_APP_IDENTITY_BASE_URL+':705a1ebf-d621-4e82-9a7d-f39757de8192',(JSON.parse(JSON.stringify(localStorage.getItem("loggedinUser")))));
        } 
        return true;
      }else{
        this.signinSilentToken();
      } 
    }else{
      this.loginPage();
    } 
  }

  getLoginUser(){
    return this.UserManager.getUser();

  }

  confirmationMail() {
    return localStorage.getItem(process.env.REACT_APP_BASEURL+'confirmationmail');
  }

  async renewToken() {
    try {
      return new Promise((resolve,reject)=>{
        const user =  this.UserManager.signinSilent();        
        if (user&&user.id_token) {
          user.then((result) => {
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log('RenewToken User logged in', result);}
            resolve(result.id_token);
          }).catch(error => {
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log('Catch Error : ', error);}
            reject(error.response);
          });
        } else if(user) {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log('RenewToken User not logged in');}
          this.loginPage();
        }
      });  
    }
    catch (e) {
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log('RenewToken redirect func fails !!!');}
      console.error(e);      
    }
  }

  signinSilentToken() {
    if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log('signinSilentToken Called : ');}
    try {
      return new Promise((resolve, reject) => {
        const user = this.UserManager.getUser();
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log('getuser details : ', user);}
        if (user&&user.id_token) {
          this.renewToken().then(Renew_token => {
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log('silentSignin user details :', Renew_token);}
            if (Renew_token) {
              if (localStorage.getItem('id_token')) {
                if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log('Removed the old token', localStorage.getItem('id_token'));}
                localStorage.removeItem('id_token');
              }
              if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log('SigninSilent RenewToken User logged in', Renew_token);}
              localStorage.setItem('id_token',Renew_token.id_token);
              resolve(Renew_token);
            } else {
              if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log('RenewToken User not logged in if block');}
              this.loginPage();
              reject(Renew_token);
            }
          }).catch(error => {
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log('Catch Error : ', error);}
            reject(error.response);
          });
        } else if(user){
          this.renewToken().then(Renew_token => {
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log('silentSignin user details :', Renew_token);}
            if (Renew_token) {
              if (localStorage.getItem('id_token')) {
                if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log('Removed the old token', localStorage.getItem('id_token'));}
                localStorage.removeItem('id_token');
              }
              if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('SigninSilent RenewToken User logged in', Renew_token);}
              localStorage.setItem('id_token',Renew_token.id_token);
              resolve(Renew_token);
            } else {
              if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('RenewToken User not logged in else block');}
              this.loginPage();
              reject(Renew_token);
            }
          }).catch(error => {
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log('Catch Error : ', error);}
            reject(error.response);
          });
        }
      });
    } catch (e) {
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log('signinsilent RenewToken redirect func fails !!!');}
      console.error(e);
    }
  }

  isTokenValid(){
    const id_token = localStorage.getItem('id_token');
    if (id_token !== "undefined" && allNullTypeCheck(id_token)) {
      const user = jwt_decode(id_token);
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log('Called token validation method :');}
      if (new Date().getTime() < user.exp * 1000) {
        return true;
      }else{
       return false;        
      }      
    }else{
      this.loginPage();
    } 
     
  }

  isTokenValidCheck(){
    try {
      const id_token = localStorage.getItem('id_token');
      if (valueValidation(id_token)) {
        const user = jwt_decode(id_token);
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log('Called token validation method :');}
        if (new Date().getTime() < user.exp * 1000) {
          return true;
        }else{
          this.signinSilentToken();
        }      
      }else{
        this.loginPage(); 
      }   
    } catch (error) {
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log('token valid check error',error);}
    }
  
  }






  logout(){
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('appselect')
    sessionStorage.removeItem('period')
    this.UserManager.signoutRedirect();
    this.UserManager.clearStaleState();
  }

}

export default new AuthService();
