import React from "react";
import PropTypes from "prop-types";
import AuthService from "../../server/AuthService";
import "../CommissionsRunManager/CommissionRunManager.css";
import "../CommissionRunDetails/CommRunDetails.css";
import { Oval } from "react-loader-spinner";
import { connect } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import noreports from "../assets/norunreport.svg";
import leftarrow from "../assets/leftarrow.svg";
import rightarrow from "../assets/rightarrow.svg";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { withRouter } from "react-router-dom";
import Component2 from "../Component2";
import { getCommRunDetails } from "../actions";
import backarrow from "../../../assets/backarrow.svg";
import qualprogress from "../assets/qualprogress.svg";
import preliminary from "../assets/preliminary.svg";
import analysisicon from "../assets/analysisicon.svg";
import deleteruntb from "../assets/deleteruntb.svg";
import { ConfirmModel } from "../ConfirmModel/ConfirmModel";
import * as Constants from '../commonComponents/Constants';
import tasks from '../assets/tasks.svg'
import sidearrow from '../../Commissions/assets/sidearrow.svg'
import ProgressLine from './ProgressLine';
import { DeleteRunModel } from "../CommissionsRunManager/DeleteRunModel";
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { RecapRange } from "../RecapRangeModal/RecapRange";
import runqualbutton from "../assets/runqualbutton.svg";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import DistResearchModel from "../CommissionsTools/DistResearchModel";
import { BROWSER_BACK_BUTTON_HANDLETAB } from '../actions/'
import AnalysisProgressLine from "./AnalysisProgressLine";
import Moment from 'moment';
import * as Constant from '../../../constants/Constants';
import NewSnackbar from "../../utilities/NewSnackbar";
import actions from '../../../redux/actions/CommonDataActions';
import moment from "moment";
import { allNullTypeCheck, isNullResponse } from "../commonComponents/UtilityFun";
import RetailProfit from "./RetailProfit";
import { numberWithCommas } from "../../utilities/Services";
import ShowMoreText from "react-show-more-text";

const tableHeaders = [
	{
		headernames: [
			{ displayName: "No." },
			{ displayName: "Description" },
			{ displayName: "Recipients" },
			{ displayName: "Avg. Payout($)" },
			{ displayName: "Total Payout($)" },
			{ displayName: "Percentage" },
		],
	},
	{
		headernames: [
			{
				displayName: [
					{ name: "Program ID" },
					{ name: "Description" },
					{ name: "Status" },
					{ name: "Run Date" },
					// { name: "Run Time" },
				     { name: "" },
				],
			},
			{
				displayName: [
					{ name: "Description" },
					{ name: "Transactions" },
					{ name: "Amount" },
					{ name: "Transaction Avg." },
					{ name: "Distributor Avg." },
				],
			},
			{
				displayName: [
					{ name: "Type" },
					{ name: "Description" },
					{ name: "Recipients" },
					{ name: "Avg. Payout($)" },
					{ name: "Total Payout($)" },
					{ name: "Percentage" },
				],
			},
			{
				displayName: [
					{ name: "Program ID" },
					{ name: "Description" },
					{ name: "User ID" },
					{ name: "Date" },
					{ name: "Time" },
				],
			},
			{
				displayName: [
					{ name: "Description" },
					{ name: "Distributor ID" },
					{ name: "Number" },
					{ name: "Classification" },
					// { name: "Run Time" },
					// { name: "Run Duration" },
				],
			},
			{
				displayName: [
					{ name: "Program ID" },
					{ name: "Program Name" },
					{ name: "Status" },
					{ name: "Run Date " },
					{ name: "Run Time" },
					// { name: "Run Duration" },
				],
			},
		],
	},
];
let initialValue = 0.0;
let totalPerc = 0.0
let avgPayouts= 0.0;
let percentTitle = ''
class CommRunDetails extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedValue: "Primary",
			selectPeriodVal: "00/0000",
			selectedCommissionPeriodIndex: 0,
			commissionTypes: [],
			commissionRuns: [],
			selectedCommissionType: "Primary",
			commissionPeriodData: null,
			commissionPeriods: [],
			dataObj: [],
			previousDisabled: false,
			nextDisabled: false,
			anchorEl: false,
			commissionRunDetailsData: [],
			showLoader: true,
			runCreateDate: "",
			isDisplayDeleteModel: false,
			runStatus: "Qualification",
			displayRunStatusModel: false,
			runStatusType: "Run Qual",
			runStatusTypeConfirmBody:
				"Are you sure you want to run the Qualification process?",
			runStatusTypeConfirmBody1: "Click OK to initiate the icebon1 program",
			runStatusTypeTitle: "Run Qual",
			tabValue: 0,
			tabSelected: 0,
			displayTasks: false,
			tasksEL: false,
			displayPayouts: false,
			payoutEL: false,
			isdisplayRecapRange: false,
			isResearchModelDisplay:false,
			enableTasks:true,
			isdisplayRetailProft: false,
			previousRunsObject: [],
			selectedPercentValue: '',
			selectedRowName: '',
			selectedRunID:'',
			runsWaitTime:1,
			//totalPerc: 0.00
		};
		this.closeSelectedRunProcessing = this.closeSelectedRunProcessing.bind(this);
		this.disaplySelectedRunProcessing = this.disaplySelectedRunProcessing.bind(this);
		this.tableBOdyDisplay = this.tableBOdyDisplay.bind(this);
		this.callCommissionIndexValues = this.callCommissionIndexValues.bind(this);
		this.closeSelectedRecapRange = this.closeSelectedRecapRange.bind(this);
		this.disaplyRecapRange = this.disaplyRecapRange.bind(this);
		this.viewRetailProfitView = this.viewRetailProfitView.bind(this);
		this.getCommissionRunDetailsChildControl = this.getCommissionRunDetailsChildControl.bind(this);

	}
	
	componentDidMount() {
		console.log("run id : ",this.props.location.state.runid)
		if (allNullTypeCheck(this.props?.location?.state?.runid)) { 
			console.log("run details : ",this.props?.location?.state?.runid);
			this.setState({selectedRunID: this.props?.location?.state?.runid}); 
			setTimeout(() => {
				this.props.getcommissionRunDetails(this.props?.location?.state?.runid);
			}, 2000);
			
		}
		avgPayouts = [];
	}
	getCommissionRunDetailsChildControl(){
		if (allNullTypeCheck(this.props?.location?.state?.runid)) { 
			this.props.getcommissionRunDetails(this.props?.location?.state?.runid);
		}
	}
	componentDidUpdate(nextProps) {
		const { commissionRunDetails, commissionRunDetailsError } = this.props;
		const { runsWaitTime, selectedRunID } = this.state;
		if (commissionRunDetails !== nextProps.commissionRunDetails){
			this.setState({ showLoader: false });
			console.log("comm run details", commissionRunDetails.data);
		  if(!commissionRunDetails.hasOwnProperty('status')){ 
			if(isNullResponse(commissionRunDetails.data) && commissionRunDetails.error===false){
				totalPerc = 0.0;
				this.setState({
					commissionRunDetailsData: commissionRunDetails.data,
					runCreateDate: commissionRunDetails.data.created,
					previousRunsObject: commissionRunDetails.data?.previousRuns
				});
				setTimeout(() => {
					this.setState({selectedPercentValue: percentTitle})
				}, 1000);
			}else if(Object.keys(commissionRunDetails?.data).length===0 && allNullTypeCheck(commissionRunDetails?.messages)) {
				if(commissionRunDetails?.messages?.length > 0 && commissionRunDetails?.messages[0]?.includes("Try again...")){
					console.log("Try again block entered : ",commissionRunDetails?.data);
					this.setState({ showLoader: true });
					setTimeout(() => {
						this.props.getcommissionRunDetails(selectedRunID);
					}, 1000 * runsWaitTime);
					this.setState(prevState => {
					return {runsWaitTime: prevState.runsWaitTime * 2}
				})
				}
			}
		}else {
			if(commissionRunDetails.hasOwnProperty('status') && commissionRunDetails.status===202){ 
				this.setState({ showLoader: true });
				setTimeout(() => {
					this.props.getcommissionRunDetails(selectedRunID);
				}, 1000 * runsWaitTime);
				this.setState(prevState => {
				return { runsWaitTime: prevState.runsWaitTime * 2 }
			})
			}
		}
		} else if (commissionRunDetailsError !== nextProps.commissionRunDetailsError) {
            this.setState({ showLoader: false });
			console.log("error run details ",commissionRunDetailsError?.data);
			if(commissionRunDetailsError?.data?.error===true){
            	this.props.showSnackbar({snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage:typeof(commissionRunDetailsError?.data.messages[0])!=='string'? commissionRunDetailsError?.data?.messages[0]?.code+' - '+commissionRunDetailsError?.data?.messages[0]?.detail:commissionRunDetailsError?.data.messages[0]})
			} else if(commissionRunDetailsError?.message) {
				this.props.showSnackbar({snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: commissionRunDetailsError?.message })
			}
        }		
	}
	handleBackAction() {
		const {commissionRunDetailsData} = this.state
		this.props.history.push({
			pathname: "/runmanager",
			state: {
				defaultperiod: commissionRunDetailsData?.period
			  },
		});
	}
	disaplySelectedRunProcessing() {
		this.setState({ displayRunStatusModel: !this.state.displayRunStatusModel });
	}
	closeSelectedRunProcessing() {
		this.setState({ displayRunStatusModel: !this.state.displayRunStatusModel });
	}
	toggleDisplayDeleteModel() {
		this.setState({
			isDisplayDeleteModel: !this.state.isDisplayDeleteModel,
		});
	}
	handleClose = () => {
		this.setState({ tasksEL: null, displayTasks: false });
	};
	handleClosePayouts = () => {
		this.setState({ payoutEL: null, displayPayouts: false });
	};
	// exportpayout = (event) => {
	// 	this.setState({
	// 		displayPayouts: true,
	// 		payoutEL: event.currentTarget
	// 	});
	// }
	disaplyRecapRange() {
		this.setState({ isdisplayRecapRange: !this.state.isdisplayRecapRange });
	}
	closeSelectedRecapRange() {
		this.setState({ isdisplayRecapRange: !this.state.isdisplayRecapRange });
	}

	exportpayout = (event) => {
		this.setState({
			displayPayouts: true,
			payoutEL: event.currentTarget
		});
	}

	taskDropdownClick = (event) => {
		this.setState({
			displayTasks: true,
			tasksEL: event.currentTarget
		});

		console.log('tasks selected')
	}
	handleChange = async (event, newValue) => {
		console.log("tab select value", newValue);
		this.setState({ tabSelected: newValue });
		avgPayouts = [];
		totalPerc = 0.0
	};
	handleChangePercentType = (value) => {
		console.log('value selected', value)
		totalPerc = 0.0
		avgPayouts = [];
		this.setState({ selectedPercentValue: value});
	}
	handleTabChange = async (event, newValue) => {
		this.setState({ tabValue: newValue, tabSelected: 0 });
		avgPayouts = [];
		totalPerc = 0.0
	};
	percentDropdown = () => {
		const { selectedPercentValue} = this.state
		const { commissionRunDetails } = this.props;
		if(isNullResponse(commissionRunDetails.data) && commissionRunDetails?.data?.payouts?.length>0){
			const { payouts } = commissionRunDetails?.data
			return(
			<div className="runmanager-element-width dropdown-alignitems">
								  <Dropdown
									className="runmanger"
									onSelect={this.handleChangePercentType}
									style={{
									  backgroundColor: '#DDDDDD',
									  marginRight: '0px'
									}}
								  >
									<Dropdown.Toggle
									  id="rundetails-qualify-dropdown"
									>
									  {selectedPercentValue === '' ? percentTitle: selectedPercentValue}
									</Dropdown.Toggle>
									<Dropdown.Menu className="rundetails-qualify-dropdown-menu">
									  {payouts.length > 0 ? payouts[0]?.percentBasedOn.map((item) => {
										return (
										  <Dropdown.Item
											className="primary-dropbtn"
											eventKey={item.description}
										  >
											{item.description}
										  </Dropdown.Item>
										);
									  }) : ''}
									</Dropdown.Menu>
								  </Dropdown> <span >(%)</span>
								  {/* <img src={dropdown1} alt='' /> */}
								</div>)
		}else {
			return;
		}
		
	}
	tableDataHandling = () => {
		const { tabValue, tabSelected } = this.state;
		return (
			<>
			{/* <div className="rectangle-margintop"></div> */}
			<TableContainer style={{padding: tabValue === 1 ? '8px': '', height: tabValue===1 && (tabSelected !== 2) ? 400 : ''}}>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead className="rundetails-table-header" style={{height: 38}}>
						{tabValue === 0
							? tableHeaders[tabValue].headernames.map((row,i) => (
								<TableCell 
									className={i<=1?"runresults-table-header":"runresult-table-rightheader"}
									component="th"
									scope="row"
								>
									{ i === 5 ? this.percentDropdown() : row.displayName}
								</TableCell>
							))
							: tableHeaders[tabValue].headernames[tabSelected].displayName.map(
								(row,i) => (
									<TableCell
										className={tabSelected===1? i<=0?"runresults-table-header":"runresult-table-rightheader": tabSelected!==2&&i<=2? "runresults-table-header": tabSelected===2&&i<=1? "runresults-table-header":"runresult-table-rightheader"}
										component="th"
										scope="row"
									>
										{tabSelected === 2 && i === 5 ? this.percentDropdown(): row.name}
									</TableCell>
								)
							)}
							
					</TableHead>
					
					{this.tableBOdyDisplay()}
				</Table>
			</TableContainer>
			
			</>
		);
	};
    seelctedRow = (commissionrun) => {
		console.log('commrunObj', commissionrun)
		this.setState({ isdisplayRetailProft: !this.state.isdisplayRetailProft, selectedCommissionTypeId: commissionrun?.commissionTypeId, selectedRowName: commissionrun?.description });
	}
	viewRetailProfitView = () => {
		this.setState({ isdisplayRetailProft: !this.state.isdisplayRetailProft });
	}
	tableBOdyDisplay = () => {
		const { commissionRunDetails } = this.props;
		const { tabValue, tabSelected} = this.state;
		let commissionRunSelectedObj = [];
		console.log("tabselected is : ",tabSelected,commissionRunSelectedObj)
		let selectedTab = tabValue === 0 ? tabValue : tabSelected;
		if(isNullResponse(commissionRunDetails?.data)){
		switch (selectedTab) {
			case 0:
				if (tabValue === 0 && commissionRunDetails?.data?.payouts?.length > 0) {
					totalPerc = 0.0;
					commissionRunSelectedObj = commissionRunDetails?.data?.payouts;
					console.log("tabselected is : ",tabSelected,commissionRunSelectedObj)
				} else if (
					tabValue === 1 &&
					commissionRunDetails?.data?.programs?.length > 0
				) {
					commissionRunSelectedObj = commissionRunDetails?.data?.programs;
					console.log("tabselected is : ",tabSelected,commissionRunSelectedObj)
				}
				break;
			case 1:
				if (commissionRunDetails?.data?.volumes?.length > 0) {
					commissionRunSelectedObj = commissionRunDetails?.data?.volumes;
					console.log("tabselected is : ",tabSelected,commissionRunSelectedObj)
				}

				break;
			case 2:
				if (commissionRunDetails?.data?.payouts?.length > 0) {
					totalPerc = 0.0;
					commissionRunSelectedObj = commissionRunDetails?.data?.payouts;
					console.log("tabselected is : ",tabSelected,commissionRunSelectedObj)
				}
				break;
			case 3:
				if (commissionRunDetails?.data?.processLogs?.length > 0) {
					commissionRunSelectedObj = commissionRunDetails?.data?.processLogs;
					console.log("tabselected is : ",tabSelected,commissionRunSelectedObj)
				}
				break;
			case 4:
				if (commissionRunDetails?.data?.exceptionLogs?.length > 0) {
					if(tabSelected===4){
						commissionRunSelectedObj = commissionRunDetails?.data?.exceptionLogs;
					}else{
						commissionRunSelectedObj = [];
					}
					
					
				}
				break;

			default:
				break;
		}
	    }
		else{
		return
	    }
		return (
			<>
			<TableBody>
				{commissionRunSelectedObj.map((commissionrun,i) => (
					<TableRow key={i}>
						<TableCell className="runmanager-type">
							{this.callCommissionIndexValues(commissionrun, 0)}
						</TableCell>
						<TableCell onClick={(tabValue===0 && commissionrun?.recipients > 0 && commissionRunDetails?.data?.payoutsDistributorsWithEarnings > 0) || (tabValue === 1 && tabSelected ===2 && commissionrun?.recipients > 0 && commissionRunDetails?.data?.payoutsDistributorsWithEarnings > 0) ? this.seelctedRow.bind(this, commissionrun): ''} 
						style={{cursor: (tabValue===0 && commissionrun?.recipients > 0  && commissionRunDetails?.data?.payoutsDistributorsWithEarnings > 0) || (tabValue === 1 && tabSelected ===2 && commissionrun?.recipients > 0 && commissionRunDetails?.data?.payoutsDistributorsWithEarnings>0) ?'pointer' : 'inherit'}} 
						className={tabSelected===1?"runresult-data-status":(tabSelected===0||tabSelected===3||tabSelected===4)&&tabValue!==0?"runmanager-type": commissionrun?.recipients > 0  && commissionRunDetails?.data?.payoutsDistributorsWithEarnings>0 ? "runresults-name": "runresults-name-empty"}>
							{this.callCommissionIndexValues(commissionrun, 1)}
						</TableCell>
						<TableCell className={tabValue===0?"runresult-data-status":tabSelected===1||tabSelected===2?"runresult-data-status":"runmanager-type"}>
							{numberWithCommas(this.callCommissionIndexValues(commissionrun, 2))}
						</TableCell>
						<TableCell className="runresult-data-status">
							{(tabValue===1 && tabSelected===0) || (tabValue===1 && tabSelected===3)  ? this.callCommissionIndexValues(commissionrun, 3) : this.callCommissionIndexValues(commissionrun, 3)}
						</TableCell>
						{ tabValue === 1 ? (tabSelected === 0 || tabSelected === 4) ? '' : <TableCell className="runresult-data-status"> 
							{ this.callCommissionIndexValues(commissionrun, 4)}
						</TableCell> : <TableCell className="runresult-data-status"> 
							{ numberWithCommas(this.callCommissionIndexValues(commissionrun, 4))}
						</TableCell>}
						{(tabSelected !== 1 && tabSelected !== 3 && tabSelected!==4) ?
							<TableCell className="runresult-data-status">
								{this.callCommissionIndexValues(commissionrun, 5)}
							</TableCell> : ''}
					</TableRow>
					
				))}
				{tabValue ===1 && tabSelected === 2 ?<>
				<TableRow  style ={{height:'35px'}}>
					<TableCell colSpan={3} >Total</TableCell>
					<TableCell  align = 'right'>{commissionRunDetails?.data?.payoutsDistributorsWithEarnings !==0 ? numberWithCommas((commissionRunSelectedObj.reduce(
					  (previousValue, currentValue) =>					  
						previousValue + currentValue.payout,
					  initialValue					  
					)/commissionRunDetails?.data?.payoutsDistributorsWithEarnings)?.toFixed(2)) : ''}</TableCell>
					<TableCell align="right">{numberWithCommas((commissionRunSelectedObj.reduce(
					  (previousValue, currentValue) =>					  
						previousValue + currentValue.payout,
					  initialValue					  
					)).toFixed(2))} </TableCell>
					{/* <TableCell align="right"></TableCell> */}
					<TableCell align="right"> { totalPerc?.toFixed(2)}
					</TableCell>
					</TableRow>
					<TableRow>
					<TableCell className= "no-border"></TableCell>
					<TableCell colSpan={1} className= "no-border">Distributor With Earnings</TableCell>
					<TableCell  align = 'left' className= "no-border" >{commissionRunDetails?.data?.payoutsDistributorsWithEarnings}</TableCell>
					<TableCell colSpan={1} className= "no-border">Distributor Payments</TableCell>
					<TableCell align = 'left' className= "no-border">{commissionRunDetails?.data?.payoutsDistributorsPayments}</TableCell>
					</TableRow>
				</> : ''}
			</TableBody>
			
			</>
			
		);
	};
	callCommissionIndexValues(commissionrun, index) {
		
		const { tabValue, tabSelected, selectedPercentValue } = this.state;
		switch (index) {
			case 0:
				let resValue0 = '';
				if (tabValue === 0) {
					resValue0 = commissionrun?.commissionTypeId;
				} else if (tabValue === 1) {
					if (tabSelected === 0) {
						resValue0 = commissionrun?.name;
					} else if (tabSelected === 1) {
						resValue0 = commissionrun?.description;
					} else if (tabSelected === 2) {
						resValue0 = commissionrun?.commissionTypeId;
					} else if (tabSelected === 3) {
						resValue0 = commissionrun?.programId;
					} else if(tabSelected === 4){
						resValue0 = commissionrun?.description;
					}
				}
				return resValue0;
			case 1:
				let resValue1 = '';
				if (tabValue === 0) {
					resValue1 = commissionrun?.description;
				} else if (tabValue === 1) {
					if (tabSelected === 0) {
						resValue1 = commissionrun?.description;
					} else if (tabSelected === 1) {
						resValue1 = commissionrun?.transactions;
					} else if (tabSelected === 2) {
						resValue1 = commissionrun?.description;
					} else if (tabSelected === 3) {
						resValue1 = commissionrun?.description;
					} else if(tabSelected === 4 ){
						resValue1 = commissionrun?.distributorId;
					}
				}
				return resValue1;
			case 2:
				let resValue2 = '';
				if (tabValue === 0) {
					resValue2 = commissionrun?.recipients;
				} else if (tabValue === 1) {
					if (tabSelected === 0) {
						resValue2 = commissionrun?.status;
					} else if (tabSelected === 1) {
						resValue2 = commissionrun?.amount!== 0 ? (commissionrun?.amount)?.toFixed(2) : '0.00' ;
					} else if (tabSelected === 2) {
						resValue2 = commissionrun?.recipients;						
					} else if (tabSelected === 3) {
						resValue2 = commissionrun?.userName;
					} else if(tabSelected === 4){
						resValue2 = commissionrun?.number;
					}
				}
				return resValue2;
			case 3:
				let resValue3 = '';
				if (tabValue === 0) {
					resValue3 =  commissionrun?.recipients!==0? numberWithCommas((commissionrun?.payout / commissionrun?.recipients)?.toFixed(2)):'0.00';
				} else if (tabValue === 1) {
					if (tabSelected === 0) {
						//let dataRes = commissionrun?.runDateTime;
						resValue3 = Moment(commissionrun?.runDateTime).format('MM/DD/YYYY HHH:mm:ss') !== 'Invalid date' ? Moment(commissionrun?.runDateTime).format('MM/DD/YYYY') : ''
					} else if (tabSelected === 1) {						
						resValue3 = commissionrun?.transactions!==0? numberWithCommas((commissionrun?.amount / commissionrun?.transactions)?.toFixed(2)):'0.00';
					} else if (tabSelected === 2) {
						if( commissionrun?.recipients!==0 ) {
							avgPayouts.push(commissionrun?.payout / commissionrun?.recipients)							
							resValue3 = numberWithCommas((commissionrun?.payout / commissionrun?.recipients)?.toFixed(2))
						} else {
							avgPayouts.push(0)
							resValue3 = '0.00';
						} 
					} else if (tabSelected === 3) {
						resValue3 = Moment(commissionrun?.dateTime).format('MM/DD/YYYY') !== 'Invalid date' ? Moment(commissionrun?.dateTime).format('MM/DD/YYYY HH:mm:ss') : ''
					} else if(tabSelected ===4){
						resValue3 =  commissionrun?.classification;  //Moment(commissionrun?.dateTime).format('MM/DD/YYYY') !== 'Invalid date' ? Moment(commissionrun?.dateTime).format('MM/DD/YYYY') : ''
					}
				}
				
				return resValue3;
			case 4:
				let resValue4 = '';
				if (tabValue === 0) {
					resValue4 = commissionrun?.payout!==0 ? (commissionrun?.payout)?.toFixed(2) : '0.00';
				} else if (tabValue === 1) {
					if (tabSelected === 0) {
						//let dataRes = commissionrun?.runDateTime;
						resValue4 =  Moment(commissionrun?.runDateTime).format('MM/DD/YYYY') !== 'Invalid date' ? Moment(commissionrun?.runDateTime).format('HH:mm:ss') : ''
					} else if (tabSelected === 1) {
						resValue4 = numberWithCommas(commissionrun?.distributorAverage?.toFixed(2));
					} else if (tabSelected === 2) {
						resValue4 = commissionrun?.payout!==0 ? numberWithCommas((commissionrun?.payout)?.toFixed(2)) : '0.00';
					} else if (tabSelected === 3) {
						resValue4 = (commissionrun?.duration / 60)?.toFixed(2) + 'm';
					} 
					// else if(tabSelected === 4){
					// 	resValue4 = Moment(commissionrun?.dateTime).format('MM/DD/YYYY') !== 'Invalid date' ? Moment(commissionrun?.dateTime).format('HH:mm:ss') : ''
					// }
				}
				return resValue4;
			case 5:
				let resValue5 = '';
				//console.log('selectedPercentValue ***', selectedPercentValue)
				if (tabValue === 0) {					
					commissionrun?.percentBasedOn.filter((data) => {						
						if (selectedPercentValue === '' ? data.description === commissionrun?.percentBasedOn[1]?.description: data.description === selectedPercentValue) {
							resValue5 = data?.percent!==0 ? (data?.percent)?.toFixed(2) : '0.00';
								totalPerc = totalPerc + + resValue5
								percentTitle = commissionrun?.percentBasedOn[1]?.description
						}
						
					  });
				} else if (tabValue === 1) {
					if (tabSelected === 0) {
					//	resValue5 = typeof(commissionrun?.duration)==='string'? commissionrun?.duration : (commissionrun?.duration / 60).toFixed(2) + 'm';
					} else if (tabSelected === 1) {
						resValue5 = '30m';
					} else if (tabSelected === 2) {						
						commissionrun?.percentBasedOn.filter((data) => {
							if (selectedPercentValue === '' ? data.description === commissionrun?.percentBasedOn[1]?.description: data.description === selectedPercentValue) {
								resValue5 = data?.percent!==0 ? (data?.percent)?.toFixed(2) : '0.00';
									totalPerc = totalPerc + + resValue5
									percentTitle = commissionrun?.percentBasedOn[1]?.description
							}
							
						  });
					} else if (tabSelected === 3) {
						resValue5 = commissionrun?.description;
					}
				}
				return resValue5;
			default:
				break;
		}
	}

	researchModelHandle=()=>{
		const { isResearchModelDisplay } = this.state;
		this.setState({isResearchModelDisplay:!isResearchModelDisplay});
	}
	handleTaskActions=(isEnable)=>{
		this.setState({enableTasks:isEnable});
	}
	executeOnClick(isExpanded) {
        console.log(isExpanded);
    }
	render() {

		let loader, currentDate;
		const { tabSelected, tabValue, isDisplayDeleteModel, commissionRunDetailsData, runStatus, isdisplayRecapRange, isResearchModelDisplay, enableTasks, isdisplayRetailProft, selectedCommissionTypeId, previousRunsObject } = this.state;
		const { selectedCommissionRun, commissionRunDetails} = this.props;
		const open = Boolean(this.state.tasksEL)
		const open1 = Boolean(this.state.payoutEL);
		const id = open ? 'simple-popover' : undefined;
		const id1 = open1 ? 'simple-popover' : undefined;

		if (this.state.showLoader) {
			 loader = <div className="row com-overlap-group-1">
				<div className="col-9" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
					{/* <div className="rectangle-255"></div> */}
					<div className="flex">
						<div id="center" style={{ position: 'fixed', top: '40%', left: '48%' }}>
							<Oval type="Puff" position="fixed"  secondaryColor="#E85557" color="#E85557" height={50} width={100} visible={true} duration={1500} /> </div>
					</div>
				</div>
			</div>
			return loader
		}
		else{
		if (this.state.runCreateDate !== null && this.state.runCreateDate !== '') {
			let createDate = commissionRunDetailsData?.created
			var objDate = new Date(createDate);
			currentDate =
				objDate.toLocaleString("en", { day: "numeric" }) + ' ' +
				objDate.toLocaleString("en", { month: "short" }) + ' ' +
				objDate.toLocaleString("en", { year: "numeric" });
		}
		if (AuthService.isAuthenticated()) {
			console.log("data enter to earnings:", commissionRunDetailsData)
			return (
        <div className="row com-overlap-group-1">
          {commissionRunDetails?.data !== null &&
          commissionRunDetails !== undefined ? (
            <div
              className="col-9"
              style={{ paddingLeft: "0px", paddingRight: "0px" }}
            >
              {isdisplayRetailProft ? (
                <RetailProfit
                  retprofitbackfunc={() => this.viewRetailProfitView(null)}
                  selectedRun={commissionRunDetailsData?.runId}
                  commissionTypeId={selectedCommissionTypeId}
                  periodType={commissionRunDetailsData?.commissionType}
                  commissionPeriod={commissionRunDetailsData?.period}
				  selectedPayoutName={this.state.selectedRowName}
                />
              ) : (
                <>
                  <Component2
                    mainText="Run Manager"
                    typeText={"RunDetails"}
                    detailsText={commissionRunDetailsData?.name}
                  />
                  {/* <div className="rectangle-255"></div> */}
                  <div className="flex">
                    <div className="rowalign-center commrun-detail-header-style">
                      <div className="col-8">
                        <div className="row group2733">
                          <img
                            src={backarrow}
                            style={{
                              cursor: "pointer",
                              height: "17px",
                              width: "17px",
                              marginTop: "7px",
                            }}
                            alt=""
                            onClick={this.handleBackAction.bind(this)}
                          />
                          <div className="runmgr-title-1" style={{ marginLeft: "5px" }}>
                            {commissionRunDetailsData?.name}
                          </div>
                          {isNullResponse(commissionRunDetailsData) &&
                          commissionRunDetailsData?.runType ===
                            "Preliminary" ? (
                            <img
                              src={preliminary}
                              style={{ margintop: "10px", marginLeft: "5px" }}
                              alt=""
                            />
                          ) : (
                            <img
                              src={analysisicon}
                              style={{ margintop: "10px", marginLeft: "5px" }}
                              alt=""
                            />
                          )}
                        </div>
                        <p className="run-detail-info">
                          Created on {moment(currentDate).format("ll")}
                        </p>
						<p className="run-detail-desc">
						<ShowMoreText
						/* Default options */
						lines={1}
						more="Show more"
						less="Show less"
						className="content-css"
						anchorClass="my-anchor-css-class showmore-title"
						onClick={this.executeOnClick}
						expanded={false}
						width={600}
						truncatedEndingComponent={"... "}
					   >
						{commissionRunDetailsData?.description}
					   </ShowMoreText>									
				       </p>																		
                        {/* <img src={runqualbutton} alt="" onClick={this.disaplySelectedRunProcessing.bind(this)}/> */}
                        {/* <img src={qualprogress} alt="" /> */}
                      </div>
                      <div className="col-4 padding-right-0">
                        <div className="comm-type-period-display">
                          <div>
                            <div className="runmanager-alignitems-left cm-type-title">
                              <span className="alignitems-left cm-type-title">
                                Period Type
                              </span>
                              <div className="alignitems-left title-styles">
                                {commissionRunDetailsData?.periodType}
                                {/* {"Primary"} */}
                              </div>
                            </div>
                          </div>
                          <div className="commission-period-margin">
                            <div className="runmanager-alignitems-left cm-type-title">
                              <span className="alignitems-left cm-type-title">
                                Commission Period
                              </span>
                              <div className="alignitems-left title-styles">
                                {commissionRunDetailsData?.period}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="progress-display-container">
                      {isNullResponse(commissionRunDetailsData) &&
                      commissionRunDetailsData?.runType === "Preliminary" ? (
                        <ProgressLine
                          commissionRunId={commissionRunDetailsData?.runId}
                          handleRunActions={this.handleTaskActions}
						  runName={commissionRunDetailsData.name}
						  runDetails={commissionRunDetailsData}
						  controlRunDetails ={()=> this.getCommissionRunDetailsChildControl() }
                        />
                      ) : (
                        <AnalysisProgressLine
                          commissionRunId={commissionRunDetailsData?.runId}
                          handleRunActions={this.handleTaskActions}
						  analysisPeriod={commissionRunDetailsData?.period}
						  previousRuns={previousRunsObject}
						  runName={commissionRunDetailsData.name}
						  runDetails={commissionRunDetailsData}
						  controlRunDetails ={()=> this.getCommissionRunDetailsChildControl() }
                        />
                      )}
                    </div>
                    <div className="col-12 cd-table-style">
                      <div>
                        <div
                          className="rowalign-center"
                          style={{ height: "60px" }}
                        >
                          <div className="col-8 h1title">
                            <Tabs
                              className="run-results-tabs"
                              value={tabValue}
                              indicatorColor="primary"
                              select
                              textColor="primary"
                              onChange={this.handleTabChange}
                              aria-label="disabled tabs example"
                            >
                              <Tab label="Run Results" />
                              <Tab label="More Info" />
                            </Tabs>
                          </div>
                          <div className="col-2 alignitems-center">
                            <div className="" disabled={enableTasks} onClick="">
                              {/* <img
                                src={tasks}
                                style={{ cursor: "pointer" }}
                                alt=""
                                onClick={this.taskDropdownClick}
                              /> */}
                              {this.state.displayTasks ? (
                                <Popover
                                  id={id}
                                  open={open}
                                  anchorEl={this.state.tasksEL}
                                  onClose={this.handleClose.bind(this)}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                  }}
                                  container={this.state.tasksEL.parentNode}
                                >
                                  <Typography sx={{ p: 2 }}>
                                    <div className="dots-option-style">
                                      <span
                                        className="dot-selection-style"
                                        onClick={this.exportpayout}
                                      >
                                        Export Payout{" "}
                                        <img
                                          className="arrow-style"
                                          src={sidearrow}
                                          alt=""
                                        />
                                      </span>
                                      <span
                                        className="dot-selection-style"
                                        onClick={() => ""}
                                      >
                                        Generate Checks
                                      </span>
                                      <span
                                        className="dot-selection-style"
                                        onClick={this.disaplyRecapRange.bind(
                                          this
                                        )}
                                      >
                                        Generate Recaps
                                      </span>
                                      <span
                                        className="dot-selection-style"
                                        onClick={() => ""}
                                      >
                                        Generate Commission Emails
                                      </span>
                                      <span
                                        className="dot-selection-style"
                                        onClick={() => ""}
                                      >
                                        Run CCSTREE
                                      </span>
                                      <span
                                        className="dot-selection-style"
                                        onClick={() => ""}
                                      >
                                        Send Commission Data to Data Warehouse
                                      </span>
                                      <span
                                        className="dot-selection-style"
                                        onClick={() => ""}
                                      >
                                        Award Reward Points
                                      </span>
                                    </div>
                                  </Typography>
                                </Popover>
                              ) : (
                                ""
                              )}
                              {this.state.displayPayouts ? (
                                <Popover
                                  id={id1}
                                  open={open1}
                                  anchorEl={this.state.payoutEL}
                                  onClose={this.handleClosePayouts.bind(this)}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                >
                                  <Typography sx={{ p: 2 }}>
                                    <div className="dots-option-style">
                                      <span
                                        className="dot-selection-style"
                                        onClick={() => ""}
                                      >
                                        ACH files
                                      </span>
                                      <span
                                        className="dot-selection-style"
                                        onClick={() => ""}
                                      >
                                        Hyper Wallet
                                      </span>
                                      <span
                                        className="dot-selection-style"
                                        onClick={() => ""}
                                      >
                                        i-payout
                                      </span>
                                      <span
                                        className="dot-selection-style"
                                        onClick={() => ""}
                                      >
                                        ERP - SAP
                                      </span>
                                      <span
                                        className="dot-selection-style"
                                        onClick={() => ""}
                                      >
                                        International Market Earnings {"&"}{" "}
                                        Government Filings
                                      </span>
                                    </div>
                                  </Typography>
                                </Popover>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="col-2 alignitems-rndt-right">
						  {allNullTypeCheck(commissionRunDetailsData?.lastRun)?<div
							
                              className="rundetails-research"
                              onClick={this.researchModelHandle}
                            >
                              <div className="rundetails-research-titile">
                                RESEARCH
                              </div>
                            </div> : ''}
                          </div>
                        </div>
                        {tabValue === 1 ? (
                          <div className="col-12 h1title">
                            <Tabs
                              className="more-inner-tabs"
                              value={tabSelected}
                              indicatorColor="primary"
                              select
                              textColor="primary"
                              onChange={this.handleChange}
                              aria-label="disabled tabs example"
                            >
                              <Tab label="Programs" />
                              <Tab label="Volume" />
                              <Tab label="Payout" />
                              <Tab label="Process Log" />
                              <Tab
                                label="Exception Log"
                                className="exception-tab-handling"
                              />
                            </Tabs>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
					  
                      {this.tableDataHandling()}
					  
                    </div>
                  </div>
                </>
              )}
            </div>
          ) : (
            ""
          )}
          <NewSnackbar />
          {/* {loader} */}
          {isDisplayDeleteModel ? (
            <DeleteRunModel
              title="Delete Run"
              selectedRun={commissionRunDetailsData}
              enableEyeModel={this.toggleDisplayDeleteModel.bind(this)}
            />
          ) : (
            ""
          )}
          {isdisplayRecapRange ? (
            <RecapRange
              enableModel={isdisplayRecapRange}
              closeSelectedPayout={this.closeSelectedRecapRange.bind(this)}
              selectPayout={this.disaplyRecapRange.bind(this)}
            />
          ) : (
            ""
          )}
          {isResearchModelDisplay ? (
            <DistResearchModel
              detailedRunId={commissionRunDetailsData}
              periodtype={commissionRunDetailsData.periodType}
              commissiontype={commissionRunDetailsData?.period}
              handleClose={this.researchModelHandle}
            />
          ) : (
            ""
          )}
        </div>
      );
		}
	  }
	}
}

CommRunDetails.propTypes = {
	newrunmodelbackfunc: PropTypes.func,
};
const mapStateToProps = (state) => {
	return {
		commissionRunDetails: state.commissionRunReducer.commissionRunDetails,
		commissionRunDetailsError : state.commissionRunReducer.commissionRunDetailsError,
		selectedCommissionRun: state.commissionRunReducer.selectedCommissionRun,
	};
};

const mapDispatchToProps = (dispatch) => ({
	getcommissionRunDetails: (runID) => dispatch(getCommRunDetails(runID)),
	showSnackbar: (params) => dispatch({ type: actions.SNACKBAR_SHOW, payload: params}),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(CommRunDetails));
