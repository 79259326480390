import {BROWSER_BACK_BUTTON_HANDLETAB, ISDISPLAY_EYE_MODEL } from "../actions"

const initialState = {
    tabValue : 0,
    displayModel:false,
}

export default function commissionReducer(state,action){
    if (typeof state === 'undefined') {
        return initialState;
    }  
    switch (action.type) {
        case BROWSER_BACK_BUTTON_HANDLETAB:
            return { ...state,  tabValue : action.payload };
        case ISDISPLAY_EYE_MODEL:
            return { ...state,  displayModel : action.payload };
        default:
        return state;
    } 
}