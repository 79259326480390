import { APPLICATION_UPDATE_USER_ERROR, APPLICATION_UPDATE_USER_SUCCESS, APPLICATION_USER_INFO_SUCCESS, APPLICATION_USER_INFO_ERROR, APPLICATION_ROLES_SUCCESS, APPLICATION_ROLES_ERROR, APPLICATION_SCOPES_SUCCESS, APPLICATION_SCOPES_ERROR } from "../actions"

const initialState = {
  applicationUpdateUserRes: {}  ,
  applicationUpdateUserError:{},
  applicationgetUserRes: {},
  applicationgetUserError: {},
  applicationRolesRes:{},
  applicationRolesError:{},
  applicationScopesRes:{},
  applicationScopesError:{},
  fetching: false,
  success: false,
}

export default function usersUpdateReducer (state, action) {
  if (typeof state === 'undefined') {
    return initialState
  }
  switch (action.type) {
    case APPLICATION_UPDATE_USER_SUCCESS:
        return { ...state, applicationUpdateUserRes: action.payload }
    case APPLICATION_UPDATE_USER_ERROR:
      return { ...state, applicationUpdateUserError: action.payload }
    case APPLICATION_USER_INFO_SUCCESS:
        return { ...state, applicationGetUserRes: action.payload }
    case APPLICATION_USER_INFO_ERROR:
        return { ...state, applicationGetUserError: action.payload }
    case APPLICATION_ROLES_SUCCESS:
        return { ...state, applicationRolesRes: action.payload }
    case APPLICATION_ROLES_ERROR:
        return { ...state, applicationRolesError: action.payload }
    case APPLICATION_SCOPES_SUCCESS:
        return { ...state, applicationScopesRes: action.payload }
    case APPLICATION_SCOPES_ERROR:
        return { ...state, applicationScopesError: action.payload }
          
    default:
      return state
  }
}