//import { getCleintNameEnv } from "../Commissions/utilities/services";

let Endpoints = {};

Endpoints.basePath = '/evopre/index.cfm';
Endpoints.login = process.env.REACT_APP_BASE_URL;
Endpoints.appsUrl = '/v1/portal/app';
Endpoints.getAllApps = '/v1/portal/integration';
Endpoints.createApp = '/v1/portal/app';
Endpoints.getAppDetails = '/v1/portal/app';
Endpoints.updateApp = '/v1/portal/app';
Endpoints.endpointPost = '/v1/portal/integration/validate';
Endpoints.deleteApp = '/v1/portal/app';
Endpoints.user = '/v1/identity/users';
Endpoints.collaborators = '/v1/identity/users?type=collaborators';
Endpoints.getcollaborators = '/v1/integrations/collaborators';
Endpoints.userProfile = 'v1/identity/users/profile';
Endpoints.resendInvite = '/v1/identity/users/invite';
Endpoints.integration = '/v1/integrations';
Endpoints.eventTypes = '/v1/portal/integrations/eventtypes';
Endpoints.confirm = '/v1/identity/users/confirm';
Endpoints.resetMail = 'v1/identity/users/resetpassword';
Endpoints.integrationStatus = '/v1/portal/integration/status';
Endpoints.deleteIntegration = '/v1/integrations';
Endpoints.brandingUpload = '/v1/portal/admin/branding';
Endpoints.deleteUser = '/v1/identity/users';
Endpoints.optionalFeatures = '/v1/integrations/optionalfeature';
Endpoints.optionalFeaturesPost ='/v1/integrations/feature'
Endpoints.postWebhooks = '/v1/integrations/webhooks';
Endpoints.generateKey = '/v1/integrations/generatekey';
Endpoints.generatedevtoken = '/v1/integrations/generatedevtoken';
Endpoints.activities = '/v1/integrations/activities';
Endpoints.webhooks = '/v1/integrations/webhooks';
Endpoints.webhookEvents = '/v1/integrations/eventtypes';
Endpoints.endpointValidation = '/v1/integrations/validate';
Endpoints.integrationNameValidation = '/v1/integrations/checkintegrationname';
//===========Email Templates =============
Endpoints.emailtemplate = '/v1/identity/mailtemplates';



Endpoints.integrationScope = '/integrations';
// Endpoints.roleScope = '/client/portal/admin';
Endpoints.userScope = '/identity/users';
Endpoints.userRoles ='v1/identity/roles';
Endpoints.developerUserGet = '/identity/users/get';
Endpoints.developerUserPost = '/identity/users/invite';
Endpoints.developerUserPut = '/identity/users/put/my';

Endpoints.developerWebhook = '/integrations/webhook';
Endpoints.developerFeaturesGet = '/integrations/optionalFeature/get';
Endpoints.developerFeatures = '/integrations/feature';
Endpoints.developerEventsGet = '/integrations/eventTypes/get';
Endpoints.developerGenerateKeyGet = '/integrations/generatekey/get';
Endpoints.developerValidatePost = '/integrations/validate/post';
Endpoints.developerUserPut = '/identity/users/put/my';


Endpoints.integrationScopeGet = '/integrations/get/my';
Endpoints.integrationScopePost = '/integrations/post';
Endpoints.integrationScopePut = '/integrations/put';
Endpoints.developerIntegrationScopeDelete = '/integrations/delete/my';


Endpoints.sdkScopeGet = '/sdkdocs';

Endpoints.userAccessScopeGet = '/identity/users/get';
Endpoints.userAccessScopePost = '/identity/users';
Endpoints.userAccessScopePut = '/identity/users';
Endpoints.userAccessScopeDelete = '/identity/users';
Endpoints.userprofileScope = '/identity/users/profile/put/my';

Endpoints.brandingGet = '/admin/branding/get';
Endpoints.brandinPost = '/admin/branding/post';

Endpoints.TableuScope = '/tableau';

Endpoints.settingsScope = '/settings'
Endpoints.dashboardSettingscope = '/admin/settings';
Endpoints.commissionsScope = '/commissions';
// Endpoints.commissionSettings = '/commissions/settings'
Endpoints.commissionSettings = '/commissions'
Endpoints.reports='/reports'



//===================Commission Endpoints ===================

const localhostURL = 'http://localhost:3001'
const localhostURL1 = 'https://dev-atomic.flexcloud.com'
// const postmanURL = 'https://6483c2af-adf4-4709-b2ac-7d78116d7162.mock.pstmn.io'  //users management
const postmanURL = 'https://8d073451-dd91-421c-90fb-6b8f507415bd.mock.pstmn.io'

Endpoints.CommissionPostManRuns = postmanURL+'/runs';
// Endpoints.CommissionRuns = localhostURL1+'/commissions/runs';
// Endpoints.CommissionUpdateRunLogURL = localhostURL1+'/commissions/runs/logs';
// Endpoints.CommissionTypeSelectionURL = localhostURL1+'/commissions/settings/commissionTypes';
// Endpoints.CommissionTypeSelectionURL1 = localhostURL1+'/commissions/settings';
// Endpoints.CommissionPeriodSelectionURL = localhostURL1+'/commissions/settings/periods/';

// const devLiveURL = 'https://dev-atomic.flexcloud.com/serv-commissions/v1'

// Endpoints.CommissionRuns = devLiveURL+'/runs';
// Endpoints.CommissionUpdateRunLogURL = devLiveURL+'/logs';
// Endpoints.CommissionTypeSelectionURL = devLiveURL+'/settings/commissionTypes';
// Endpoints.CommissionTypeSelectionURL1 = devLiveURL+'/settings';
// Endpoints.CommissionPeriodSelectionURL = devLiveURL+'/settings/periods/';

Endpoints.CommissionRuns = '/v1/commissions/runs';
Endpoints.CommissionUpdateRunLogURL = '/v1/commissions/runs/logs';
// Endpoints.CommissionTypeSelectionURL = '/v1/commissions/settings/commissionTypes';
Endpoints.CommissionTypeSelectionURL = '/v1/commissions/settings/periodTypes';
Endpoints.CommissionTypeSelectionURL1 = '/v1/commissions/settings';
Endpoints.CommissionPeriodSelectionURL = '/v1/commissions/settings/periods/';
Endpoints.CommissionPeriodURL = '/v1/commissions/settings/periods';


Endpoints.SettingsProgramsPrimary = '/v1/commissions/settings/programs/';

// Settings waivers page
Endpoints.SettingsWaiversActionsURL = '/v1/commissions/settings/waivers/actions'
Endpoints.SettingsDistributorWaivers = '/v1/commissions/settings/waivers/distributors'
Endpoints.SettingsDistributorGet = '/v1/distributors/'
Endpoints.SettingsPeriodTypesGet = '/v1/commissions/settings/periodTypes'

//Reports module API's 
Endpoints.ReportsBaseURl = 'https://dev-atomic.flexcloud.com/serv-reports/'

//=======================   User Management Services   ==========================

Endpoints.userMockBaseUrl = `${postmanURL}/applications/`;

export default Endpoints;
