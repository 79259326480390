import React from "react";
import Input from "./Input";
import TextArea from "./TextArea";
import Select from "./Select";
import Select1 from "./Select1";
import { allNullTypeCheck } from "../../commonComponents/UtilityFun";
import CheckBox from './CheckBox';
import Numbers from "./Numbers";

function FormikControl(props) {
  const { control, _required, ...rest } = props;
 if(allNullTypeCheck(control)){
  switch (control) {
    //  case "input":
    //    return <Input {...rest} />;
    case "String":
      return <TextArea {...rest} />;
    case "SingleChoice":
      return <Select {...rest} />;
    case "Number":
      return <Numbers {...rest} />;
    case "Boolean" :
      return <CheckBox type="checkbox" {...rest} />
    case "Date":
      return <TextArea {...rest} />;
    default:
      return null;
  }
}
else{
  return null;
}
}

export default React.memo(FormikControl);
