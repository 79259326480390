import { APPLICATION_INVITE_USER_ERROR, APPLICATION_INVITE_USER_SUCCESS } from "../actions"

const initialState = {
  applicationInviteUserRes: {}  ,
  applicationInviteUserError:{},
  fetching: false,
  success: false,
}

export default function usersInviteReducer (state, action) {
  if (typeof state === 'undefined') {
    return initialState
  }
  switch (action.type) {
    case APPLICATION_INVITE_USER_SUCCESS:
        return { ...state, applicationInviteUserRes: action.payload }
    case APPLICATION_INVITE_USER_ERROR:
      return { ...state, applicationInviteUserError: action.payload }
    default:
      return state
  }
}
