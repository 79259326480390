export const PROGRAM_DATA_FETCHING = 'PROGRAM_DATA_FETCHING'
export const PROGRAM_DATA_ERROR = 'PROGRAM_DATA_ERROR'

export const PRIMARY_PROGRAM_DATA_SUCCESS = 'PRIMARY_PROGRAM_DATA_SUCCESS'
export const SECONDARY_PROGRAM_DATA_SUCCESS = 'SECONDARY_PROGRAM_DATA_SUCCESS'
export const TERTIARY_PROGRAM_DATA_SUCCESS = 'TERTIARY_PROGRAM_DATA_SUCCESS'
  
  const initialState = {
    primaryData: {},
    secondaryData: {},
    tertiaryData: {},
  
    error: {},
    fetching: false,
    success: false
  }
  
  export default function programsReducer (state, action) {
    if (typeof state === 'undefined') {
      return initialState
    }
    switch (action.type) {
      case PROGRAM_DATA_FETCHING:
        return { ...state, fetching: true }
      case PRIMARY_PROGRAM_DATA_SUCCESS:
        return {
          ...state,
          fetching: false,
          success: true,
          primaryData: action.payload || {}
        }
      case SECONDARY_PROGRAM_DATA_SUCCESS:
        return {
          ...state,
          fetching: false,
          success: true,
          secondaryData: action.payload
          
        }
      case TERTIARY_PROGRAM_DATA_SUCCESS:
        return {
          ...state,
          fetching: false,
          success: true,
          tertiaryData: action.payload || {}
        }
      case PROGRAM_DATA_ERROR:
        return {
          ...state,
          fetching: false,
          success: false,
          errorrData: action.payload || {}
        }
  
      default:
        return state
    }
  }
  