
import { createStore, applyMiddleware , compose} from 'redux';
import rootReducer from './rootReducer';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
// const middleware = [ thunk ]
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const flexcloudStore = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk, logger)));

export default flexcloudStore;