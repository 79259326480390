import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as Constants from '../../../constants/Constants';
import actions from '../../../redux/actions/CommonDataActions';
import actions1 from '../../../redux/actions/IntegrationPopUp';
import { postCreateIntegration, getIntegrationName } from '../../../redux/thunk/integrationAPIThunk';
import { integration } from '../../../redux/actions/integrationDataActions'
import "./IntegrationName.css";
import jwt from 'jwt-decode';
import { errorHandling, valueValidation } from '../../utilities/Services';
import { Button } from 'reactstrap';
import { Oval } from "react-loader-spinner";
import vector13 from '../../../assets/vector-13@2x.png';
import line42 from '../../../assets/line-42@1x.png';
import vector40 from '../../../assets/vector-40@2x.png';
import ellipse37 from '../../../assets/ellipse-37@2x.png';
import path255 from '../../../assets/closebtn.svg';
import line45 from '../../../assets/line-45@1x.png'
import line222 from '../../../assets/line-22-2@2x.png'
import line22 from '../../../assets/line-22@2x.png'
import vector366 from '../../../assets/vector366.svg'
import vector343 from '../../../assets/vector343.svg';
import plainvectorcircle from '../../../assets/plainvectorcircle.svg';
import integrationinfo from '../../../assets/infoimage.svg';
import swarning from '../../../assets/small-warning.svg';
import checksuccess from '../../../assets/check-success.svg';

import { Input, Label } from 'reactstrap';
import NewSnackbar from "../../utilities/NewSnackbar";

class IntegrationName extends React.Component {
    constructor(props) {
        super(props)
        this.handleClose = this.handleClose.bind(this)
        this.state = {
            nameFieldValidated: false,
            integrationName: '',
            integrationDescription: '',
            nameUniqueValidation: false,
            nameLenthValidation: false,
            nameSpaceValidation: false,
            nameEmailValidation: false,
            nameCharecterLength: 0,
            descriptionMaxVlue: '',
            nameMaxVlue: '',
            descriptionTextLength: 0,
            userAuthenticationAccess: true,
            integrationCreated: false,
            showCompleteIntegration: false,
            nextButton: true,
            clientName: '',
            clientEmail: '',
            showComments: false,
            inputValue: false,
            buttonDiable: false,
            descrInputColor: '',
            nameInputcolor: '',
            checkIntegrationColor: ''
        }
    }
    componentDidMount() {
        const id_token = localStorage.getItem('id_token');
        if(id_token !== "undefined" && valueValidation(id_token)){
            const client = jwt(id_token);
            this.setState({ clientName: client.client_name })
            this.setState({ clientEmail: client.username })
        }
    }
    onEndNameChange = (event) => {
        console.log('on end call')
        var value = event.target.value;
        if (value.length > 7) {
            this.props.showLoader()
            this.props.nameIntegrationAPI(value)
        }
    }
    oneditNameChange = (event) => {
        this.setState({ showComments: true, setBorderColor: "", inputValue: true, nameInputcolor: '' })

        // console.log('target value',e.target)
        var name = event.target.name;
        var value = event.target.value;
        console.log('value length', value.length)
        if (value.length <= 30) {
            this.setState({
                nameCharecterLength: value.length
            });
            if (value.length === 30) {
                console.log('value length', value.length)
                this.setState({ nameMaxVlue: true })
            } else {
                this.setState({ nameMaxVlue: false })
            }
            if (name === 'integrationName') {
                this.setState({ integrationName: value })
                if (value.length > 0) {
                    if (value.length > 7) {
                        this.setState({
                            nameLengthValidation: true,nextButton: false
                        });
                        if (value === this.state.clientEmail) {
                            this.setState({ nameEmailValidation: false, nextButton: true, nameInputcolor: '#d93030' })
                        } else {
                            this.setState({ nameEmailValidation: true, nextButton: false })
                        }
                    } else {
                        this.setState({
                            nameLengthValidation: false,
                            nameUniqueValidation: false,
                            nameEmailValidation: false,
                            nameSpaceValidation: false,
                            nextButton: true
                        });
                    }

                }
                if (value.indexOf(' ') >= 0) {
                    this.setState({
                        nameSpaceValidation: false, nextButton: true, nameInputcolor: '#d93030'
                    });
                }
                else {
                    if (value.length > 0) {
                        this.setState({
                            nameSpaceValidation: true
                        });
                    }
                    else {
                        this.setState({
                            nameSpaceValidation: false
                        });
                    }
                }

                if (value.length === 0) {
                    this.setState({
                        nameLengthValidation: false,
                        nameUniqueValidation: false,
                        nameEmailValidation: false,
                        nameSpaceValidation: false,
                        nextButton: true,
                        showComments: false,
                        inputValue: false,
                        checkIntegrationColor: ''
                    })
                }
            }
        }
    }
    endofDescription = (event) => {
        console.log('event ateget start desc', event.target.value.length)
        var value = event.target.value;
        if (value.length > 0) {
            this.setState({ descrInputColor: '#27ae60' })
        }
        else {
            this.setState({ descrInputColor: '' })
        }
    }
    onDescriptionChange(event) {
        // console.log("description added on the integration name creation", this.state.integrationDescription)
        if (event.target.value.length <= 200) {
            this.setState({ integrationDescription: event.target.value, descriptionTextLength: event.target.value.length, nextButton: false })
            if (event.target.value.length === 200) {
                this.setState({ descriptionMaxVlue: true })
            }
            else {
                this.setState({ descriptionMaxVlue: false })
            }
        }
    }
    clearinput = (event) => {
        //  console.log('clear')
        this.setState({
            integrationName: '', nameUniqueValidation: false,
            nameLengthValidation: false,
            nameSpaceValidation: false,
            nameEmailValidation: false, nameCharecterLength: 0, nextButton: true,
            inputValue: false, showComments: false, nameInputcolor: '', nameMaxVlue: false, checkIntegrationColor: ''
        })
    }
    handleClose() {
        //  this.setState({ showModal: false })
        localStorage.removeItem("AuthType")
        this.props.hideIntegrationNamePopup()
    }
    handleBack() {
        // this.props.showAuthType()
        localStorage.setItem("AuthType", "true")
        this.props.hideIntegrationNamePopup()
        setTimeout(() => {
            this.props.showCreateIntegrationPopup()
        }, 100);

    }
    componentDidUpdate(nextProps) {
        // console.log('neext props', nextProps)
        if (this.props.createIntegrationResponse !== nextProps.createIntegrationResponse) {
            this.props.hideLoader();
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Create integration Response : ", this.props.createIntegrationResponse); }
            if (this.props.createIntegrationResponse.error === false) {
                this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.success, snackbarMessage: Constants.INTEGRATION_CREATED_SUCCESS })
                if ((this.props.createIntegrationResponse.data) && (this.props.createIntegrationResponse.data.integration)) {
                    console.log('integration res', this.props.createIntegrationResponse.data)
                    this.setState({ integrationCreated: true });
                    this.props.showCompleteIntegrationPopup()
                    this.props.hideIntegrationNamePopup()
                    this.clearinput()
                    this.props.updateCurrentIntegrationID(this.props.createIntegrationResponse.data.integration);
                }
            } else if (this.props.createIntegrationResponse.error) {
                this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.warning, snackbarMessage: Constants.ALERT_TECHNICAL_ERROR })
            }

        } else if (this.props.createIntegrationError !== nextProps.createIntegrationError) {
            this.props.hideLoader();
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("call Endpoint Error : ", this.props.createIntegrationError); }
            errorHandling(this.props.createIntegrationError);
        }
        if (this.props.integrationNameResposne !== nextProps.integrationNameResposne) {
            this.props.hideLoader();
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Create integration Response : ", this.props.createIntegrationResponse); }
            if (this.props.integrationNameResposne.error === false) {
                if (this.props.integrationNameResposne.data && this.props.integrationNameResposne.data.status === 'true') {
                    // console.log('integration name res', this.props.integrationNameResposne.data)
                    this.setState({ nameUniqueValidation: false, nextButton: true, nameInputcolor: '#d93030', checkIntegrationColor: true });
                    // tempNegativeAlert(Constants.INTEGRATION_NAME_VALIDATION, 5000, Constants.GRAY);
                }
                else {
                    // console.log('integration name res', this.props.integrationNameResposne.data)
                    if (this.state.integrationName.length > 7) {
                        this.setState({ nameUniqueValidation: true, nextButton: false, checkIntegrationColor: false });
                        if (this.state.nameSpaceValidation === true && this.state.nameEmailValidation === true) {
                            this.setState({ nameInputcolor: '#27ae60' });
                        }
                    }
                    if (this.state.integrationDescription.length > 200) {
                        this.setState({ nextButton: true })
                    }
                }
            } else if (this.props.integrationNameResposne.error) {
                this.setState({ nameUniqueValidation: false, nextButton: true });
                this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.warning, snackbarMessage: Constants.ALERT_TECHNICAL_ERROR })
            }

        } else if (this.props.integrationNameError !== nextProps.integrationNameError) {
            this.props.hideLoader();
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Integration Name Res Error : ", this.props.integrationNameError); }
            errorHandling(this.props.integrationNameError);
        }
    }
    handleCreateIntegration(e) {
        // console.log('e is', e)
        this.setState({ nextButton: true })
        if (this.state.nameUniqueValidation === true && this.state.nameLengthValidation === true && this.state.nameSpaceValidation === true && this.state.nameEmailValidation === true) {
            // e.preventDefault()
            if (this.state.integrationDescription.trim().length !== 0) {
                let newIntegrationParams = {};
                newIntegrationParams[Constants.PARAM_NAME] = this.state.integrationName //this.props.params.name;
                newIntegrationParams[Constants.PARAM_DESCRIPTION] = this.state.integrationDescription //this.props.params.description;
                newIntegrationParams[Constants.PARAM_AUTHENTICATION] = 'server' // this.props.params.authentication;
                newIntegrationParams[Constants.PARAM_CALLBACK] = '' //this.props.params.callback;
                newIntegrationParams[Constants.PARAM_COLLABORATORS] = '' //(this.props.params.collaborators).toString();
                if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("integration params : ", newIntegrationParams); }
                this.props.showLoader();
                this.props.callCreateIntegrationAPI(newIntegrationParams);
            }
            else {
                this.setState({ descrInputColor: '#d93030' })
                this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.error, snackbarMessage: Constants.VALIDATION_DESCRIPTION_NAME })
            }
        }
        else {
            this.props.hideLoader();
            this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.error, snackbarMessage: Constants.TEXT_INTEGRATION_NAME_INVALID })
        }
    }
    render() {
        //<img class="vector-1-in" src="https://anima-uploads.s3.amazonaws.com/projects/607ff5beb95afd36a6b5e6c3/releases/60814525f4556b65c033cbd7/img/vector-36@2x.png" alt="">
        // <i aria-hidden="true" class="checkmark big icon"></i>
        let loader = ''
        if (this.props.isFetchingData) {
            loader = (<div id="center" style={{ position: 'fixed', bottom: '40%', left: '48%' }}>
                <Oval type="Puff" position="fixed"  secondaryColor="#E85557" color="#E85557" height={50} width={50} visible={true} /> </div>)
        }
        return (
            <div class="container-center-horizontal">
                <div className="frame-6 screen">
                    <div className="flex-row-1-in">
                        <div className="text-1-in valign-text-middle-in">Create a New Integration</div>
                        <img
                            className="vector-4-in"
                            src={vector13} onClick={this.handleClose.bind(this)} style={{ cursor: 'pointer' }} alt="" />

                    </div>
                    <div className="overlap-group3-in" style={{ borderRadius: "8px" }}>
                        <img
                            className="line-45"
                            src={line42} alt="" />
                        <p className="text-2-in valign-text-middle">
                            What will be the name of your integration? Don't worry! You can change this later.
                        </p>
                        <h1 className="title-in valign-text-middle">Integration Name</h1>

                        <div className="overlap-group1-in" >
                            <Label className="intgration-name">Name</Label>
                            <img className="integration-info" src={integrationinfo} alt='' />
                            <div>
                                <Input type="text"  maxLength={30} style={{ borderColor: this.state.nameInputcolor }} className={`text-3-in valign-text-middle lato-regular-normal-granite-gray-14px ${this.state.nameUniqueValidation ? "border-1px-malachite" : ""}`} placeholder="Give your integration a unique name"
                                    defaultValue={this.state.integrationName} name="integrationName" onChange={this.oneditNameChange}
                                    onInputCapture={this.onEndNameChange} itemType="text" />
                            </div>
                            <img
                                className="path-225"
                                src={path255}
                                alt="" onClick={this.clearinput} style={{ cursor: "pointer" }} />
                        </div>
                        <div className="description-box" style={{ marginTop: this.state.inputValue ? "305px" : "240px" }}>
                            <Label >Description</Label> <br /><br />
                            <Input className={this.state.inputValue ? "input-text-edit" : "input-text"} type="textarea" maxLength={200} style={{ maxLength: "60", borderColor: this.state.descrInputColor }}
                                defaultValue={this.state.integrationDescription} onChange={this.onDescriptionChange.bind(this)} onInputCapture={this.endofDescription}
                                placeholder={Constants.TEXT_DESCRIPTION_PLACEHOLDER} />
                            <div className="row group2733">
                                {this.state.descriptionMaxVlue === true ? <div className="descriptio-length-text valign-text-middle lato-regular-normal-granite-gray-14px" style={{ color: '#d93030' }}>{this.state.descriptionTextLength}</div> : <div className="descriptio-length-text valign-text-middle lato-regular-normal-granite-gray-14px">{this.state.integrationDescription.length > 0 ? this.state.descriptionTextLength : this.state.descriptionTextLength}</div>}
                                {this.state.integrationDescription.length > 0 ? <div style={{ marginTop: '8px'}}>/200</div> : <div style={{ marginTop: '8px'}}>/200</div>}
                            </div>
                        </div>
                        <div className="address valign-text-middle lato-regular-normal-granite-gray-14px" style={{ color: this.state.nameMaxVlue === true ? '#d93030' : '' }}>{this.state.nameCharecterLength}</div>
                        <div className="address-count valign-text-middle lato-regular-normal-granite-gray-14px" >/30</div>
                        {this.state.showComments ? <div>
                            <div className="text-4-in valign-text-middle lato-regular-normal-granite-gray-12px">
                               {this.state.checkIntegrationColor === true ? <span style={{ color: this.state.nameUniqueValidation === true ? "green" : "#db2828" }}>Integration name is unique</span> : <span style={{ color: this.state.nameUniqueValidation === true ? "green" : "" }}>Integration name is unique</span>} 
                            </div>
                             <div className="text-5-in valign-text-middle lato-regular-normal-granite-gray-12px">
                                <span style={{ color: this.state.nameSpaceValidation === true ? "green" : "#db2828" }}>Integration has no space</span>
                            </div>
                            {/*<div className="text-6-in valign-text-middle lato-regular-normal-granite-gray-12px">
                                <span style={{ color: this.state.nameEmailValidation === true ? "green" : "" }}>Does not contain your email address</span>
                            </div> */}
                            <div className="text-7-in valign-text-middle lato-regular-normal-granite-gray-12px">
                                <span style={{ color: (this.state.nameLengthValidation === true) ? "green" : "#d93030"  }}>Contains min. 8 & max. 30 characters</span>
                            </div>
                            <img className="ellipse-37" src={this.state.nameLengthValidation === true ?  checksuccess : ellipse37 } alt='' />
                            {this.state.checkIntegrationColor === true ? <img className="ellipse-38" src={this.state.nameUniqueValidation === true ? checksuccess : swarning} alt='' /> : <img className="ellipse-38" src={this.state.nameUniqueValidation === true ? checksuccess : ellipse37} alt='' />}
                             <img className="ellipse-39" src={this.state.nameSpaceValidation === true ? checksuccess : swarning} alt='' />
                           {/* <img className="ellipse-40" src={this.state.nameEmailValidation === true ? vector40 : ellipse37} alt='' /> */}
                        </div> : null}

                        <div className="overlap-group0in">
                            <img className="line-46" src={line45} alt='' />
                            <div className="rectangle-233"></div>
                            <div className="text-8-in valign-text-middle">Authentication Method</div>
                            <div className="text-9-in valign-text-middle lato-regular-normal-granite-gray-14px">Complete Integration</div>
                            <div className="integration-name-in valign-text-middle">Integration Name</div>

                            {this.state.nameUniqueValidation === true ? <div className="success-in">
                                <img
                                    className="vector-1-in"
                                    src={vector366}
                                    alt='' /> </div> : <div><img
                                        className="vector-2-in"
                                        src={plainvectorcircle}
                                        alt='' /><div className="number-1">2</div></div>}
                            <img
                                className="vector-in"
                                src={vector343}
                                alt='' />
                            <div className="number-in">3</div>
                            <img
                                className="line-22"
                                src={line222}
                                alt='' />
                            <img
                                className="line-47"
                                src={line22}
                                alt='' />
                            <div className="group-2720-in">
                                <div className="overlap-group2-in">
                                    <div className="overlap-group6-1">

                                        <img className="vector-name-check" src={vector366} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex-row-in">

                        <div className="cancel-crtint component " onClick={this.handleBack.bind(this)} style={{ cursor: 'pointer' }}>
                            <div className="button-cncel-1 valign-text-middle">BACK</div>
                        </div>
                        {/* <SecondaryButtonDefault>CREATE</SecondaryButtonDefault> */}

                        {/* <Button className="ui  left floated small button secondary-button margin-lft profile-button "  onClick={this.handleBackAction.bind(this)}>{Constants.TEXT_INTEGRATION_EDIT_BACKBUTTON}</Button> */}
                        <Button className="primary-medium-default-in" disabled={this.state.nextButton} onClick={this.handleCreateIntegration.bind(this)} >CREATE</Button>

                    </div>
                    {/* {this.state.showCompleteIntegration? <CompleteIntegration value  = {this.state.showCompleteIntegration}></CompleteIntegration>:null } */}
                    <div>{loader} </div>
                    <NewSnackbar />
                </div>
            </div>
        );
    }
}
IntegrationName.propTypes = {
    params: PropTypes.any,
    backfunc: PropTypes.func,
};
const mapStateToProps = state => {
    return {
        userDetails: state.userDataReducer.userDetails,
        userHasError: state.userDataReducer.userHasError,
        isFetchingData: state.commonDataReducer.isFetchingData,
        localChangesExist: state.commonDataReducer.localChangesExist,
        createIntegrationResponse: state.integrationDataReducer.createIntegrationResponse,
        createIntegrationError: state.integrationDataReducer.createIntegrationError,
        integrationNameResposne: state.integrationDataReducer.integrationNameResposne,
        integrationNameError: state.integrationDataReducer.integrationNameError,
    };
};
const mapDispatchToProps = dispatch => ({
    showLoader: () => dispatch({ type: actions.FETCHING_DATA_LOADER, payload: true }),
    hideLoader: () => dispatch({ type: actions.HIDE_DATA_LOADER, payload: false }),
    localChanges: (value) => dispatch({ type: actions.CHANGES_EXISTING, payload: value }),
    callCreateIntegrationAPI: (params) => dispatch(postCreateIntegration(params)),
    nameIntegrationAPI: (params) => dispatch(getIntegrationName(params)),
    updateCurrentIntegrationID: (integrationID) => dispatch({ type: integration.CURRENT_INTEGRATIONID, payload: integrationID }),
    showCreateIntegrationPopup: () => dispatch({ type: actions1.SHOW_CREATE_INTEGRATIONS, payload: true }),
    hideCreateIntegrationPopup: () => dispatch({ type: actions1.HIDE_CREATE_INTEGRATIONS, payload: false }),
    showIntegrationNamePopup: () => dispatch({ type: actions1.SHOW_INTEGRATIONS_NAME, payload: true }),
    hideIntegrationNamePopup: () => dispatch({ type: actions1.HIDE_INTEGRATIONS_NAME, payload: false }),
    showCompleteIntegrationPopup: () => dispatch({ type: actions1.SHOW_COMPLETE_INTEGRATIONS, payload: true }),
    hideCompleteIntegrationPopup: () => dispatch({ type: actions1.HIDE_COMPLETE_INTEGRATIONS, payload: false }),
    showSnackbar: (params) => dispatch({ type: actions.SNACKBAR_SHOW, payload: params }),
});

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationName);

function SecondaryMediumDefaultBlack(props) {
    const { children } = props;
    return (
        <div className="secondary-medium-default-black">
            <div className="button-i5271039536 valign-text-middle">{children}</div>
        </div>
    );
}
// function PrimaryMediumDefault(props) {
//     const { children } = props;
//     return (
//         <div className="primary-medium-hover">
//             <div className="button-i527103863 valign-text-middle">{children}</div>
//         </div>
//     );
// }