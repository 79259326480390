import React, { useEffect, useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { useDispatch, useSelector } from 'react-redux';
import closeIcon from '../../Commissions/assets/closeicon.svg'
import '../InviteUsers/InviteNewUsers.css'
import { allNullTypeCheck, isNullResponse } from '../../Commissions/commonComponents/UtilityFun'
import * as Yup from 'yup';
import { Oval } from "react-loader-spinner";
import NewSnackbar from '../../utilities/NewSnackbar';
import { APPLICATION_INVITE_USER_SUCCESS, postUserInvite } from '../actions';
import actions from '../../../redux/actions/CommonDataActions';
import * as Constant from '../../../constants/Constants'

function InviteNewUsers({ setShowPopUp }) {
  const usersInviteRes = useSelector(state=>state.usersInviteReducer.applicationInviteUserRes)
  const dispatch = useDispatch()
  const [loader, setLoader] = useState(false);
  const [userName, setuserName] = useState('')
  useEffect(() => {
    if (isNullResponse(usersInviteRes)) {
      if (usersInviteRes?.messages?.length === 0 && usersInviteRes?.error === false) {
        //console.log('users invite', usersInviteRes?.data?.id)
        setLoader(false)
        if (allNullTypeCheck(usersInviteRes?.data)) {
          dispatch({ type: actions.SNACKBAR_SHOW, payload: { snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.success, snackbarMessage: `Invitations have been sent to ${userName} user.`} });
          dispatch({type: APPLICATION_INVITE_USER_SUCCESS, payload: {}})
          setShowPopUp(false)
        }
      }
    }
  }, [usersInviteRes]);

  const validationSchema = () => {
    return Yup.object().shape({
      name: Yup.string()
        .required('FullName is required')
        // .min(6, 'FullName must be at least 6 characters')
        .max(20, 'FullName must not exceed 20 characters'),
      email: Yup.string()
        .required('Email is required')
        .email('Email is invalid')
    });
  }
  function postDataSubmittion(values){
    return {
      "name": values.name,
      "email": values.email,
      "roles": [{"id":32},{"id":1}]
    }
  }
  const onSubmit = (values, { resetForm }) => {
    //console.log('values',values)
     setuserName(values.name)
     dispatch(postUserInvite(postDataSubmittion(values)))
     resetForm()
     setLoader(true)
  }
  if (loader) {
    return (
      <div>
        <div id="center" style={{ position: "fixed", bottom: "40%", left: "48%" }}>
          <Oval
            type="Puff"
            position="fixed"
            secondaryColor="#E85557"
            color="#E85557"
            height={50}
            width={100}
            visible={true}
          />{" "}
        </div>
      </div>
    );
  } else {
    return (
      <div className='modal-popup'>
        <div className='main-content'>
          <div className='popup-header'>
            <div className='heading'>Invite New Users</div>
            <img
              className='icon'
              onClick={() => setShowPopUp(false)}
              src={closeIcon}
              alt='X'
            />
          </div>
          <Formik initialValues={{}} validationSchema={validationSchema} onSubmit={onSubmit} >
            {formik => {
              const { dirty, setFieldValue, values, errors } = formik

              return (
                <Form>
                  <div className='popup-body'>
                    <div className='add-invite-popup-row'>
                      <div className='input-container'>
                        <div className='invite-title-style'>Full Name</div>
                        <Field maxlength="45"
                          name='name' type='text' required autocomplete="off"
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className='invite-action-popup-row'>
                      <div className='input-container'>
                        <div className='invite-title-style'>Email ID</div>
                        <Field name='email' type='text' required autocomplete="off" />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className='input-container'>
                        <div className='invite-title-style'>Role</div>
                        <Field as='select' name='roles' required
                          onChange={(e) => {
                            // setFieldValue('actionField',"")
                            // setFieldValue("periodType",e.target.value)
                            // setCommissionLastPeriod(periodsData.data[e.target.value]?.lastCommissionPeriod)
                          }}
                        >
                          <option value='Commission Research' label='User' />
                          <option value='Qualfying' label='Admin' />
                        </Field>
                        {/* <Field name='periodType' type='text' /> */}
                      </div>
                    </div>
                  </div>
                  <div className='popup-footer'>
                    <button
                      className='portal-button-2'
                      onClick={() => setShowPopUp(false)}
                      type='button'
                      disabled={!dirty}
                    >
                      CANCEL
                    </button>
                    <button
                      className='portal-button-1'
                      type='submit'
                      disabled={!dirty}
                    >
                      INVITE
                    </button>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
        <NewSnackbar/>
      </div>
    )
  }
}
export default InviteNewUsers
