import AuthService from '../../../server/AuthService';
import actions from '../../../../redux/actions/CommonDataActions';
import * as Constants from "../../../../constants/Constants"
import { singletonAxiosInstance } from '../../../utilities/Server';
import Endpoints from '../../../utilities/Endpoints';

export const PERIOD_TYPES_FETCHING = "PERIOD_TYPES_FETCHING";
export const PERIOD_TYPES_SUCCESS = "PERIOD_TYPES_SUCCESS";
export const PERIOD_TYPES_ERROR = "PERIOD_TYPES_ERROR";

export const PERIODS_FETCHING = "PERIODS_FETCHING";
export const PERIODS_SUCCESS = "PERIODS_SUCCESS";
export const PERIODS_ERROR = "PERIODS_ERROR";

export const LOADING = 'LOADING';
const server = singletonAxiosInstance
const options = {
  Authorization: 'Bearer ' + localStorage.getItem('id_token') 
};

const statCodes = {
  SUCCESS: 200,
  CREATE_SUCCESS: 201,
  unAuthorized: 403,
  RETRY: 202
};




const checkLocalhost = process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')

export const getPeriodsData = () => {
  
  console.log("commission type API call active period maintenance");
  // return dispatch => {
  //   if (AuthService.isTokenValid() === true) {
      // var config = {
      //   method: 'get',
      //   url: 'https://dev-atomic.flexcloud.com/api/v1/commissions/settings/commissionTypes',
      //   headers: {
      //     Authorization: 'Bearer ' + localStorage.getItem('id_token')

      //   }
      // };
      
      
      return dispatch => {
        dispatch({type:LOADING,payload:true})
        dispatch({type: PERIOD_TYPES_FETCHING})
        if (AuthService.isTokenValid() === true) {
          server.get(Endpoints.CommissionTypeSelectionURL, '', options).then((data) => {
            if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY ) {
              if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionType API call data :', data); }
              if (data.status === statCodes.SUCCESS){
                dispatch({ type:  PERIOD_TYPES_SUCCESS, payload: data.data.data });
                dispatch({type:LOADING, payload:false})
              }else{
                dispatch({ type:  PERIOD_TYPES_SUCCESS, payload: data })
                dispatch({type:LOADING, payload:false})
              }
              
            } else {
              dispatch({ type: PERIOD_TYPES_ERROR, payload: data });
              dispatch({type:LOADING, payload:false})
            }
          }).catch((error) => {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
            if (!error.response) {
              dispatch({ type: PERIOD_TYPES_ERROR, payload: error })
              dispatch({type:LOADING, payload:false})
            } else {
              dispatch({ type: PERIOD_TYPES_ERROR, payload: error.response })
              dispatch({type:LOADING, payload:false})
            }
    
          });
        }
      }
    };


export const getPeriodsListData = () => {

  const getPeriodTypesList = (data) => {
    try{  
      const dictionary = {}
      for(let i=0 ;i< data.length; i++) {
        if(data[i].active) {
          dictionary[data[i].title] = data[i]
        }
      }   
      return dictionary
    }catch(err){
      return {}
    }
  }
  
  console.log("commission type API call active period maintenance");
  return dispatch => {
    if (AuthService.isTokenValid() !== true) {
      const user = AuthService.signinSilentToken();
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
      if (user) {
        localStorage.setItem('id_token', user.id_token);
      }
    }
    if (AuthService.isTokenValid() === true) {
      

      dispatch({type:LOADING,payload:true})
      dispatch({type: PERIODS_FETCHING})

    server.get(Endpoints.SettingsPeriodTypesGet , '', options)
      .then(function (response) {
        const {data} = response
        console.log(typeof(data), data,"---------------periods data");
        dispatch({type: PERIODS_SUCCESS, payload:  getPeriodTypesList(data.data?.periodTypes) })
        dispatch({type:LOADING,payload:false})
      })
      .catch(function (error) {
        console.log(error.message,"active period maintenance error")
        dispatch({type: PERIODS_ERROR, payload: error.message})
        dispatch({type:LOADING,payload:false})
        if(error.hasOwnProperty("message")&&error?.message==='Network Error'){
          dispatch({ type: actions.SNACKBAR_SHOW, payload: {snackbarOpen: true, snackbarType:  Constants.SNACKBAR_TYPE.error, snackbarMessage: (typeof(error)!=='string'&&typeof(error)==='object'&& error.hasOwnProperty('message'))? error.message:error}})
        }
      });
    }
  };
};








// export const getTertiaryProgramData = () => {
//   console.log("commission type API call active period maintenance");
//   return dispatch => {
//     if (AuthService.isTokenValid() === true) {
//       var config = {
//         method: 'get',
//         url: 'https://6483c2af-adf4-4709-b2ac-7d78116d7162.mock.pstmn.io/commissions/settings/periods/activeMaintenance',
//         headers: { 
//           'x-api-key': 'PMAK-61ae3f447ddf090034f32226-302e860673975719598c24f34f8d72d71e', 
//           'x-mock-response-code': '200'
//         }
//       };

//       dispatch({type: ACTIVE_PERIOD_MAINTENANCE_FETCHING})

//       axios(config)
//       .then(function (response) {
//         const {data} = response
//         console.log(typeof(data), data.data,"---------------successful c");
//         dispatch({type: ACTIVE_PERIOD_MAINTENANCE_SUCCESS, payload: data.data})
//       })
//       .catch(function (error) {
//         console.log(error.message,"active period maintenance error")
//         dispatch({type: ACTIVE_PERIOD_MAINTENANCE_ERROR, payload: error})
//       });
//     }
//   };
// };





