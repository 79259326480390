export const FETCHING_DATA_LOADER = 'FETCHING_DATA_LOADER';
export const HIDE_DATA_LOADER = 'HIDE_DATA_LOADER';
export const FETCHING_APPSLIST ='FETCHING_APPSLIST';
export const INVITE_POPUP = 'INVITE_POPUP';

export  function toShowLoader(){
  return {
    type: FETCHING_DATA_LOADER,
    payload: true,
  };
}
export function toHideLoader(){
  return {
    type: HIDE_DATA_LOADER,
    payload: false,
  };
}
export function showLoader() {
  // Redux Thunk will inject dispatch here:
  return dispatch => {
    // Reducers may handle this to set a flag like isFetching
    dispatch(toShowLoader());
  
    // Perform the actual API call
    return new Promise(r => r('ok')).then(
      response => {
        // Reducers may handle this to show the data and reset isFetching
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log('response :',response);}
        dispatch(toHideLoader());
      },
      error => {
        // Reducers may handle this to reset isFetching
        // Rethrow so returned Promise is rejected
        throw error;
      }
    );
  };
}
  
