import React, { useEffect,useState } from "react";
import './EyeModel.css';
import closeIcon from '../assets/closeicon.svg';
import { Modal } from "react-bootstrap";
import { Oval } from "react-loader-spinner";
import NewSnackbar from "../../utilities/NewSnackbar";
import Moment from 'moment';
export const EyeModel = (props) =>{
    const { title, selectedRun, enableEyeModel, closeModel } = props;
    const [showLoaderResult, setShowLoaderResult] = useState(true);

    useEffect(() => {
        setShowLoaderResult(false)
      }, []);

    let loader;  
    if (showLoaderResult) {
            loader = <div id="center" style={{ position: "fixed", bottom: "40%", left: "48%" }}>
                <Oval
                    type="Puff"
                    position="fixed"  secondaryColor="#E85557"
                    color="#E85557"
                    height={50}
                    width={50}
                    timeout={3000}
                />
            </div>
    }
    return(
        <div>
        <Modal dialogClassName="openrun-eye-modal" show={true}>
            <Modal.Header>
                <div className="cmnr-flex-row-2-ei">
                        <div className="openrun-header-name">{title}</div>
                        <img className="cmnr-vector-ei" src={closeIcon} onClick={closeModel} alt="" />
                    </div>
                </Modal.Header>
            <Modal.Body>
                <div className='model-container'>
                    <div>
                        <div className='div-left'><span className='eyemodel-span'>Run Type:</span><h2 className='selected-values'>{selectedRun.runType}</h2></div>
                        <div className='div-right'><span className='eyemodel-span'>Name:</span><h2 className='selected-values'>{selectedRun.name}</h2></div>
                    </div> 
                    <div className='div-next'>
                        <span className='description-style-1'>Description: </span>
                        <span className="rcs-text-6">{selectedRun.description}</span>
                    </div>
                    <div>
                        <div className='div-left'><span className='eyemodel-span'>Created on:</span><h2 className='selected-values'>{Moment(selectedRun?.created).format('MM/DD/YYYY HH:mm:ss')}</h2></div>
                        <div className='div-right'><span className='eyemodel-span'>Status:</span><h2 className='selected-values'>{selectedRun?.status}</h2></div>
                    </div> 
                    <div>
                        <div className='div-left-single'><span className='eyemodel-span'>Qualification Program:</span><h2 className='selected-values'>{selectedRun?.qualificationProgram}</h2></div>
                    </div>
                    <div>
                        <div className='div-left-single'><span className='eyemodel-span'>Calculation Program:</span><h2 className='selected-values'>{selectedRun?.calculationProgram}</h2></div>
                    </div>
                    <div>
                        <div className='div-left-single'><span className='eyemodel-span'>Disbursement Program:</span><h2 className='selected-values'>{selectedRun?.disbursementProgram}</h2></div>
                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <div className="row">
                <div className="openrun-button-style" onClick={enableEyeModel} >
                        <div className="openrun-label">OPEN RUN</div>
                </div>
                </div>
            </Modal.Footer>
            {loader}
        </Modal>
        <NewSnackbar/>
        </div>
    );
}
