import { APPLICATION_USER_ERROR, APPLICATION_USER_SUCCESS, LOGINHISTORY_ERROR, LOGINHISTORY_SUCCESS } from "../actions"

const initialState = {
  applicationUserRes: {},
  applicationUserError:{},
  fetching: false,
  success: false,
  periodTypes:[]
}

export default function userDashboardReducer (state, action) {
  if (typeof state === 'undefined') {
    return initialState
  }
  switch (action.type) {
    case APPLICATION_USER_SUCCESS:
        return { ...state, applicationUserRes: action.payload }
    case APPLICATION_USER_ERROR:
      return { ...state, applicationUserError: action.payload }
    default:
      return state
  }
}
