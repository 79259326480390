import React from "react";
import SecondaryMediumDefaultBlack from "../SecondaryMediumDefaultBlack";
import PrimaryMediumDefault from "../PrimaryMediumDefault";
import "./Frame8.css";

class Frame8 extends React.Component {
  render() {
    const {
      text1,
      line45,
      line46,
      text7,
      vector2,
      vector3,
      vector4,
      text8,
      integrationName,
      number,
      number2,
      number3,
      line22,
      line47,
      spanText,
      spanText2,
      text3,
      text5,
      text4,
      group2720,
      vector5,
      vector6,
      userAuthentication,
      text6,
      secondaryMediumDefaultBlackProps,
      primaryMediumDefaultProps,
    } = this.props;

    return (
      <div class="container-center-horizontal">
        <div className="frame-8 screen">
          <div className="flex-row-1">
            <div className="text-1 valign-text-middle lato-normal-mine-shaft-18px">{text1}</div>
            <img
              className="vector-1"
              src="https://anima-uploads.s3.amazonaws.com/projects/607ff5beb95afd36a6b5e6c3/releases/60814525f4556b65c033cbd7/img/vector-13@2x.png"
            />
          </div>
          <div className="overlap-group4">
            <div className="overlap-group2">
              <div className="rectangle-233"></div>
              <img className="line-45" src={line45} />
              <img className="line-46" src={line46} />
              <div className="text-7 valign-text-middle lato-bold-mine-shaft-14px">{text7}</div>
              <img className="vector-2" src={vector2} />
              <img className="vector-3" src={vector3} />
              <img className="vector" src={vector4} />
              <div className="text-8 valign-text-middle lato-normal-granite-gray-14px">{text8}</div>
              <div className="integration-name valign-text-middle lato-normal-granite-gray-14px">{integrationName}</div>
              <div className="number lato-bold-malachite-12px">{number}</div>
              <div className="number-1 lato-bold-white-12px">{number2}</div>
              <div className="number-2 lato-bold-white-12px">{number3}</div>
              <img className="line-22" src={line22} />
              <img className="line-47" src={line47} />
            </div>
            <p className="text-2 valign-text-middle lato-normal-fuscous-gray-14px">
              <span>
                <span>{spanText}</span>
                <span className="span1 ">{spanText2}</span>
              </span>
            </p>
            <h1 className="text-3 valign-text-middle lato-bold-mine-shaft-28px">{text3}</h1>
            <div className="overlap-group border-1px-mine-shaft">
              <div className="flex-col">
                <div className="text-5 valign-text-middle lato-bold-granite-gray-18px">{text5}</div>
                <p className="text-4 lato-normal-granite-gray-14px">{text4}</p>
              </div>
              <div className="group-2720" style={{ backgroundImage: `url(${group2720})` }}>
                <div className="overlap-group3">
                  <img className="vector-5" src={vector5} />
                  <img className="vector-4" src={vector6} />
                </div>
              </div>
            </div>
            <div className="overlap-group1 border-1px-seashell">
              <div className="user-authentication valign-text-middle lato-bold-granite-gray-18px">
                {userAuthentication}
              </div>
              <p className="text-6 lato-normal-granite-gray-14px">{text6}</p>
            </div>
          </div>
          <div className="flex-row">
            <SecondaryMediumDefaultBlack>{secondaryMediumDefaultBlackProps.children}</SecondaryMediumDefaultBlack>
            <PrimaryMediumDefault>{primaryMediumDefaultProps.children}</PrimaryMediumDefault>
          </div>
        </div>
      </div>
    );
  }
}

export default Frame8;
