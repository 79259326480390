import React from 'react';
import { Button, FormGroup, Label, Input, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import * as Constants from '../../../constants/Constants';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './EventsList.css';

class EventsList extends React.Component {

  constructor(props) {
    super(props);
    this.boxRef = React.createRef();
    this.state = {  
        rightOpen: true,
        webhookEventsList:[],                
        checkedValue:'',
        labelChecked:false,
        selectedEventsData: []
            
    }
  }
  componentDidMount() {   
    this.setState({showloader:true});
    if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log("events : ",this.props.eventsList);}   
    if(this.props.eventsList.length>0){      
      this.setState({webhookEventsList:this.props.eventsList});      
    }
    this.setState({showloader:false});
  }
  
  async handleChange(event){
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;    
    await this.setState({
      [name]: value,
    }); 
    this.state.selectedEventsData.push(target.name)
    console.log("selected events", this.state.selectedEventsData)
    if(this.state.webhookEventsList.length>0){        
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log("onChange : ",target.value);}
      this.state.webhookEventsList.forEach((event)=>{
        if((event.label===target.value)&&(event.active===false)){          
          event.active=true;
          event.children.forEach((child)=>{          
          child.active=true;

          })         
        }else if((event.label===target.value)&&(event.active===true)){
          event.active=false;
          event.children.forEach((child)=>{         
          child.active=false;
          })
        }else{
            if(event.active===false){
              let i=0,parentSelection=false;
            event.children.forEach((child)=>{  
              if(event.children.length>1){
                if(child.active===true){                  
                  if(target.value===child.value){  
                    child.active=false; 
                  }else if(target.value!==child.value){ 
                    ++i;                   
                    if(event.children.length===i){
                      parentSelection=true;
                    } 
                  }
                  if(parentSelection){
                    event.active=true;
                  }
                }else if(child.active===false){
                  if(target.value===child.value){                     
                    child.active=true;
                    ++i; 
                  }
                  if(event.children.length===i){
                    parentSelection=true;
                  } 
                  if(parentSelection){
                    event.active=true
                  } 
                }                     
              }else{
                if((target.value===child.value)&&(child.active===false)){                  
                  event.active=true;
                  child.active=true;                 
                }else if((target.value===child.value)&&(child.active===true)){                  
                  event.active=false;
                  child.active=false;                 
                }
              }                  
             
            })
          }else{
            event.children.forEach((child)=>{
              if((target.value===child.value)&&(child.active===false)){
                child.active=true;
              }else if((target.value===child.value)&&(child.active===true)){
                child.active=false;
                event.active=false;
              }
            })
          }
        } 
      })     
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log("onChange selected events: ",this.state.webhookEventsList);}
      this.setState({webhookEventsList:this.state.webhookEventsList});
    } 
  }
  eventCloseAction(){
    
    this.props.selectedEvents(this.state.webhookEventsList);
    this.props.closePopup1()
  }
  webhookEventDisplay(){  
  if(this.state.showloader){
      return <div>Loading...</div>
  }  
  return (
    <div style={{height:"498px"}}>
      <div className='event_popup eventtop_align eventpopup_height' style={{width: "300px" }} ref={this.boxRef}>
           <div style={{ marginLeft: '3%', marginRight: '5%', padding:"20px 0px" }}>
            <div className="padding-10px" style={{ fontSize:'13px', fontWeight: '600' }}> <span>{Constants.TEXT_EVENTSLIST}</span>
            </div>                                
          </div>          
          <div style={{ marginLeft: '5%', marginRight: '5%' }}>            
            <div className="eventslist_display" style={{ marginTop: '5%'}}>            
              <Col>
                <FormGroup >
                 {this.state.webhookEventsList.map((data,i)=>{                 
                  return <div className="ui ml-3">
                    <Label check>
                    <Input type="checkbox" name={data.value} checked={data.active} value={data.value} onChange={this.handleChange.bind(this)} style={{marginTop: "1px"}}/>{' '}
                            {data.label}
                    </Label>
                        <div className="ui ml-3 event-text" >
                            {data.children.map((innerdata)=>{
                               return <div style={{display:"flex", marginBottom: "5px", cursor: "pointer"}}><Label check>
                                <Input type="checkbox" style={{ marginRight: "3px", position: "relative", marginTop: "1px"}} checked={innerdata.active} name={innerdata.label} value={innerdata.value} onChange={this.handleChange.bind(this)} />{' '}
                                        {innerdata.label}
                                </Label>
                                </div>
                            })}                        
                        </div>
                       {this.state.webhookEventsList.length-1>i? <hr></hr>:''}
                    </div>     
                 })}                         
                </FormGroup>
              </Col>
              <Col>
                {/* <div style={{textAlign:"center"}}> */}
                <div className="video17542006 component" onClick={this.eventCloseAction.bind(this)} style= {{left: "160px"}} >
        <div className="button-i175420066-7mXx7F valign-text-middle" style={{cursor: "pointer"}}>{Constants.DONE}</div>
      </div>         
              </Col>              
            </div>
          </div>          
        </div>      
      </div>
    );
  }
  toggleSidebar = (event) => {
    let key = `${event.currentTarget.parentNode.id}Open`;
    this.setState({ [key]: !this.state[key] });
  }
    render(){
      let rightOpen = this.state.rightOpen ? 'open' : 'closed';
      if(this.props.mobileviewLine){
       return <div id='right' className={rightOpen} >             
              <div className={`sidebar ${rightOpen}`} >                  
                  <div className='content'>                      
                     {this.state.webhookEventsList?this.webhookEventDisplay():""}
                  </div>
              </div>
          </div>
      }else{
       return this.state.webhookEventsList?this.webhookEventDisplay():""
      }
    }

}
EventsList.defaultProps  = {
  closePopup1: PropTypes.func,
};
EventsList.propTypes = {
  closePopup1: PropTypes.func,
  history:PropTypes.object,
  text:PropTypes.any,
};

export default connect(null, null)(withRouter(EventsList));