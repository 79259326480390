import React, { useEffect,useState } from "react";
import {useSelector, useDispatch} from 'react-redux'
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import eyeIcon from "../assets/eye-icon.svg";
import leftarrow from "../assets/leftarrow.svg";
import rightarrow from "../assets/rightarrow.svg";
import "./GeneralTab.css";
import {
  DIST_WORKFIELDS_ERROR,
  DIST_WORKFIELDS_SUCCESS,
  getDistributorWorkFields,
} from "../../../components/Commissions/actions";
import AuthService from "../../server/AuthService";
import { singletonAxiosInstance } from "../../utilities/Server";
import Endpoints from "../../utilities/Endpoints";
import { Oval } from "react-loader-spinner";
import TablePagination from "@mui/material/TablePagination";
import { allNullTypeCheck, isNullResponse } from "../commonComponents/UtilityFun";
import { Dropdown } from "react-bootstrap";
import { numberWithCommas } from "../../utilities/Services";
import WorkFieldDetailMatrix from "./WorkFieldDetailMatrix";
import * as Constant from '../../../constants/Constants';
import actions from "../../../redux/actions/CommonDataActions";

const server = singletonAxiosInstance;
const options = {
  Authorization: 'Bearer ' + localStorage.getItem('id_token')
  //Authorization: "Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjU4MjQyMTVEODhGQkE4RTEzQkZGN0FFNTk0MzAyODM5RkU1M0ZGOENSUzI1NiIsInR5cCI6ImF0K2p3dCIsIng1dCI6IldDUWhYWWo3cU9FN18zcmxsREFvT2Y1VF80dyJ9.eyJuYmYiOjE2MzM0OTA0MzksImV4cCI6MTY2NTAyNjQzOSwiaXNzIjoiaHR0cHM6Ly9kZXYtYXRvbWljLmZsZXhjbG91ZC5jb20vaWRlbnRpdHkiLCJjbGllbnRfaWQiOiIwZDkzNGI5MS03NjA3LTQ3OWUtYjMyOS0xOTAyNjhiZmRjZDIiLCJjbGllbnRfbmFtZSI6ImRldi1hdG9taWMiLCJqdGkiOiIxNTk0MTA3RDFGRjM0RTMyNDcwMTZGRjE4Q0Q2QjdERCIsImlhdCI6MTYzMzQ5MDQzOSwic2NvcGUiOlsiL2NvbW1pc3Npb25zIl19.VOq7hVNtlqz3yhuag0yYrqMXefpTfI3upweQy2DUt9mO6Wd6iHhd8S3JCamTCYY-Fr8rlxIPEXNb8Rx7fg1e4LCrcXVQoOLVg65QBL3r2xuMQE7PQ_c4MF9C61xxQWrFAC2jjvNWztrb5pcv9B73XVv1MCJVajxnYeyrKVdxP3VK8R9L_OHqEzpXzKfz6PW0BiXgvPIl92NtHDE1mvC6YXXXcVwHuTAFBu8tZdvskr_wm0iNaY7niY8QzKXvY5DkWFdknaSeGP9HJwukdGzdY-ObHg5JDl2Swb0ienhOqQ4mKrIA1_kU9tcfk6k4dAPpYkvwcwPB-WQfNECuB1qxJw",
};
const statCodes = {
  SUCCESS: 200,
  CREATE_SUCCESS: 201,
  unAuthorized: 403,
};
export const WorkFieldTab = ({ distID, selectedRunID }) => {
  const [loader, setLoader] = useState(true);
  const [selectedItem, setSelectedItem] = useState(0);
  const [pages, setPages] = useState([]);
  const [detailKey, setDetailKey] = useState(0);
  const [workFieldList, setWorkFieldList] = useState([]);
  const [numberOfPages, setNumberOfPages] = useState([]);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [enableMatrix, setEnbaleMatrix] = useState(false);
  const [workFieldID, setWorkFieldID] = useState(null);
  const [workFieldName, setWorkFieldName] = useState(null);
  const [retrydelayTime, setRetrydelayTime] = useState(1);
  const [workFieldListDisplay, setworkFieldListDisplay] = useState([]);
  const [distWorkFieldList, setDistWorkFieldList] = useState({});
  const distWorkFieldData = useSelector(
    (state) => state.distributorResearchReducer.distWorkFieldList
  );
  const distWorkFieldError = useSelector(
    (state) => state.distributorResearchReducer.distWorkFieldError
  );
  const dispatch = useDispatch();

  console.log("restult table data :", distID, selectedRunID);
  console.log("restult table data :", distWorkFieldList);

  // const getDistributorWorkFields = (distID, runID) => {
  //   return (dispatch) => {
  //     if (AuthService.isTokenValid() === true) {
  //       server
  //         .get(
  //           Endpoints.CommissionRuns +
  //             "/" +
  //             runID +
  //             "/distributors/" +
  //             distID +
  //             "/workFields",
  //           "",
  //           options
  //         )
  //         .then((data) => {
  //           setLoader(false);
  //           if (data.status === statCodes.SUCCESS) {
  //             if (
  //               process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes(
  //                 "localhost"
  //               )
  //             ) {
  //               console.log("WorkFields Details res :", data);
  //             }
  //             dispatch({ type: DIST_WORKFIELDS_SUCCESS, payload: data.data });
  //           } else {
  //             dispatch({ type: DIST_WORKFIELDS_ERROR, payload: data });
  //           }
  //         })
  //         .catch((error) => {
  //           setLoader(false);
  //           if (
  //             process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes("localhost")
  //           ) {
  //             console.log("Catch Error : ", error);
  //           }
  //           if (!error.response) {
  //             dispatch({ type: DIST_WORKFIELDS_ERROR, payload: error });
  //           } else {
  //             dispatch({
  //               type: DIST_WORKFIELDS_ERROR,
  //               payload: error.response,
  //             });
  //           }
  //         });
  //     } else {
  //       const user = AuthService.signinSilentToken();
  //     }
  //   };
  // };
  // const handleChangePage = (event, newPage) => {
  //   console.log("page event :", newPage);
  //   setpage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   console.log("rows per page event :", event.target.value);
  //   setrowsPerPage(event.target.value);
  //   setworkFieldListDisplay(
  //     distWorkFieldList?.data?.workFields?.slice(0, event.target.value)
  //   );
  //   setpage(0);
  // };
  // const handleChange = (event) => {
  //   console.log("result is : ", event);
  //   setSelectedItem(event);
  //   // console.log("Select comm type", event);
  //   // let uplineJson = filterById(distUplineDropdownList?.data?.ranks, event);
  //   // setSelectedValue(uplineJson.description);
  //   // setLoader(true);
  //   // dispatch(getDistributorUpline(distID, selectedRunID, event));
  // };

  useEffect(() => {
    dispatch(getDistributorWorkFields(distID, selectedRunID));
  }, []);
  useEffect(() => {
    if(!distWorkFieldData.hasOwnProperty('status')){ 
    if (isNullResponse(distWorkFieldData.data) && distWorkFieldData?.error===false){
         setDistWorkFieldList(distWorkFieldData);
         setLoader(false);
        if (distWorkFieldData?.data?.workFields?.length > 0) {
          const numberOfPages = Math.ceil(
            distWorkFieldData?.data?.workFields?.length / 20  
          );
          setWorkFieldList(distWorkFieldData?.data?.workFields?.slice(0, 20));
          setPages([]);
          for (let index = 1; index <= numberOfPages; index++) {
            var obj = {};
            obj["value"] = index.toString();
            obj["label"] = index.toString();
            pages.push(obj);
          }
          setNumberOfPages(pages);
          setSelectedItem(1);
          console.log("number of pages :", numberOfPages, pages);
        }
      }else {
        if(allNullTypeCheck(distWorkFieldData?.data) && Object.keys(distWorkFieldData?.data).length===0 && allNullTypeCheck(distWorkFieldData?.messages)) {
          if(distWorkFieldData?.messages?.length > 0 && distWorkFieldData?.messages[0]?.includes("Try again")){
            console.log("Try again block entered : ",distWorkFieldData?.data);
            setTimeout(()=>{
              dispatch(getDistributorWorkFields(distID, selectedRunID));
            },retrydelayTime*1000)
            setRetrydelayTime(retrydelayTime*2);
        }
      }
    }
  }else {
    setLoader(false);
    if(distWorkFieldData.hasOwnProperty('status') && distWorkFieldData.status===202){
      setLoader(true);
      setTimeout(()=>{
        dispatch(getDistributorWorkFields(distID, selectedRunID));
      },retrydelayTime*1000)
      setRetrydelayTime(retrydelayTime*2);
    }
  }
    if(isNullResponse(distWorkFieldError)&& distWorkFieldError?.data?.error===true){
      console.log("General rank info failed :", distWorkFieldError);
      setLoader(false)
      if(distWorkFieldError?.data?.error === true){
        dispatch({ type: actions.SNACKBAR_SHOW, payload: {snackbarOpen: true, snackbarType:  Constant.SNACKBAR_TYPE.error, snackbarMessage: typeof(distWorkFieldError?.data?.messages[0])!=='string'? distWorkFieldError?.data?.messages[0]?.code+' - '+distWorkFieldError?.data?.messages[0]?.detail:distWorkFieldError?.data?.messages[0]}})
      }else if(distWorkFieldError?.message) {
        dispatch({ type: actions.SNACKBAR_SHOW, payload: {snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: distWorkFieldError?.message }});
      }
    }
  }, [distWorkFieldData, distWorkFieldError]);
  useEffect(() => {
    setSelectedItem(1);
    console.log("result is ", workFieldList);
  }, [pages]);

  useEffect(() => {
    const i = 1;
    const start = selectedItem * 20 - 20;
    const end = selectedItem * 20;
    setWorkFieldList(
      distWorkFieldList?.data?.workFields?.slice(start, end),
      () => {}
    );
  }, [selectedItem]);

  useEffect(() => {
    console.log("result is ", workFieldList);
  }, [workFieldList]);

  const handleMatrix=(detailKeyId, labelName)=>{
    console.log("handled matrix ;:")
    setDetailKey(detailKeyId);
    setWorkFieldName(labelName);
    setEnbaleMatrix(true);
  }
  
  // useEffect(() => {
  //   console.log("called this function : ",enableMatrix);
  //   displayRankMatrix();
  // }, [enableMatrix]);

  const closefunction=()=>{
    setEnbaleMatrix(false);
  }
  // const displayRankMatrix=()=>{
  //   console.log("called this function : ",enableMatrix);
  //   if(enableMatrix){
  //     return <EyeModel/>;
  //   }
  // };

  const handleNumberChange = (e) => {
    console.log("selected values: ", e.target.value);
    setSelectedItem(e.target.value);
  };
  if (loader) {
    return (
      <div>
        <div id="center" style={{ position: "fixed", bottom: "40%", left: "48%" }}>
          <Oval
            type="Puff"
            position="fixed"
            secondaryColor="#E85557"
            color="#E85557"
            height={50}
            width={100}
            visible={true}
          />{" "}
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="distres-generaltable">
        <div className="col-6 distres-general-tbcontainer distres-tabs-scroll">
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableCell
                  className="runmanager-table-header"
                  component="th"
                  scope="row"
                >
                  {"Work Fields"}
                </TableCell>
                <TableCell
                  className="downline-table-header"
                  component="th"
                  scope="row"
                >
                  {"Value"}
                </TableCell>
                <TableCell
                  className="downline-table-header"
                  component="th"
                  scope="row"
                >
                 
                </TableCell>
              </TableHead>
              <TableBody>
                {isNullResponse(distWorkFieldList?.data) &&
                workFieldList?.length > 0
                  ? workFieldList?.slice(0, 10).map((data, i) => {
                      return (
                        <TableRow key={i}>
                          <TableCell>{data?.label}</TableCell>
                          <TableCell className="table-cell-data-right-alignment">
                          {data?.isCounter === false ? data?.value?.toFixed(2) : data?.value?.toFixed(0)}  {' '}  {' '} 
                          
                          </TableCell>
                          <TableCell className="table-cell-data-right-alignment"> {data?.hasDetail===true && data?.value > 0  ? <img src={eyeIcon} onClick={()=>handleMatrix(data?.detailKey, data?.label)} alt="" style={{cursor: 'pointer'}}/>: ''}</TableCell>
                        </TableRow>
                      );
                    })
                  : ""}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="col-6 distres-general-rightcontainer distres-tabs-scroll">
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableCell
                  className="runmanager-table-header"
                  component="th"
                  scope="row"
                >
                  {"Work Fields"}
                </TableCell>
                <TableCell
                  className="downline-table-header"
                  component="th"
                  scope="row"
                >
                  <span className="volume-last-cell-data"> {"Value"}</span>
                </TableCell>
                <TableCell className="downline-table-header"
                  component="th"
                  scope="row"></TableCell>
              </TableHead>
              <TableBody className="distres-general-tbody">
                {workFieldList?.length > 0
                  ? workFieldList?.slice(10,20).map((data, i) => {
                      return (
                        <TableRow key={i}>
                          <TableCell>{data?.label}</TableCell>
                          <TableCell className="table-cell-data-right-alignment">
                            <span className="volume-last-cell-data">
                            {(data?.isCounter)? numberWithCommas(data?.value) : numberWithCommas(data?.value?.toFixed(2))}
                            {' '}  {' '} 
                         
                            </span>
                          </TableCell>
                          <TableCell className="table-cell-data-right-alignment"> {data?.hasDetail===true && data?.value > 0 ? <img src={eyeIcon} onClick={()=>handleMatrix(data?.id, data?.label)} alt="" style={{cursor: 'pointer'}}/>: ''}</TableCell>
                        </TableRow>
                      );
                    })
                  : ""}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <div className="col-12" style={{height: '12px'}}>
        {workFieldList?.length > 0 && workFieldList?.length > 20 ? (
          <div className="workfield-select-option">
            <span className="workfield-jumptopage">Jump to Page:</span>
            {console.log("number of pages state: ", numberOfPages)}
            <select
              name="country"
              className="workfield-selectdropdown"
              onChange={handleNumberChange}
              value={selectedItem}
            >
              {numberOfPages.map((e, key) => {
                return (
                  <option key={key} value={e.value}>
                    {e.label}
                  </option>
                );
              })}
            </select>
            <div>
              <img
                src={leftarrow}
                alt=""
                style={{ marginRight: "5px", cursor: "pointer" }}
              />{" "}
              <span className="workfield-numberofpages">
                {selectedItem + " Of " + numberOfPages.length}
              </span>
              <img
                src={rightarrow}
                alt=""
                style={{ marginRight: "5px", cursor: "pointer" }}
              />{" "}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      {enableMatrix ? (
              <WorkFieldDetailMatrix
              closefunction={closefunction}
              distributorID ={distID}
              runID = {selectedRunID}
              detailKey = {detailKey}
              workFieldLabel = {workFieldName} 
              />
            ) : (
              ""
            )}
    </div>
  );
};