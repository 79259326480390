import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Oval } from "react-loader-spinner";
import { Modal } from "react-bootstrap";
import vector13 from '../../../assets/vector-13@2x.png';
import '../AddCompareRun/AddCompareModal.css'
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { getWorkFieldDetailsAPI, DIST_RESEARCH_WORKFIELD_DETAILS, DIST_RESEARCH_WORKFIELD_ERROR } from "../actions";
import {getMonthDays, numberWithCommas}  from '../../utilities/Services'
import { isNullResponse, allNullTypeCheck } from "../commonComponents/UtilityFun";
import * as Constants from '../../../constants/Constants';
import NewSnackbar from "../../utilities/NewSnackbar";
import actions from '../../../redux/actions/CommonDataActions';
import "./AmountFields.css";
import "./GeneralTab.css";
import { generateMatrixCSV } from '../CommToolCompareRun/generateCSV';
import exports from '../../../assets/exports.svg';

const fields = [
    { name: 'Paid Rank', displayName: "Dist ID", inputFilterable: true, sortable: true },
    { name: 'Bv', displayName: "Name", inputFilterable: false, exactFilterable: true, sortable: true },
    { name: 'Org Volume', displayName: "Amount", inputFilterable: false, exactFilterable: true, sortable: true },
    { name: 'Active Leg', displayName: "Level", inputFilterable: true, sortable: true }
];

class WorkFieldDetailMatrix extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoaderResult: true,
      showRemoveColModal: true,
      hideModal: false,     
      workFieldDetailsList: [],
      runsWaitTime:1
    };
  }

  componentDidMount() {
    const { distributorID, runID, detailKey } = this.props;
    if(allNullTypeCheck(distributorID) && allNullTypeCheck(runID) && allNullTypeCheck(detailKey)){
      this.props.getWorkFieldDetails(runID, distributorID, detailKey);
    }else{
      this.setState({showLoaderResult:false});
    }
  }
  componentDidUpdate(nextProps) {
    const {
      distWorkFieldDetailsList,
      distWorkFieldDetailsError,
      distributorID, runID, detailKey
    } = this.props;
    const { runsWaitTime } = this.state;
    if (nextProps.distWorkFieldDetailsList !== distWorkFieldDetailsList) {
      this.setState({ showLoaderResult: false });
      if(!distWorkFieldDetailsList.hasOwnProperty('status')){
      console.log("work field details : ",distWorkFieldDetailsList);
      if (isNullResponse(distWorkFieldDetailsList.data)) {
        if (distWorkFieldDetailsList.error === false) {
          console.log("Runs sucess : ", distWorkFieldDetailsList);
          this.setState({
            workFieldDetailsList: distWorkFieldDetailsList?.data,
          });
        } else {
          if (distWorkFieldDetailsList.error === true) {
          this.props.showSnackbar({
            snackbarOpen: true,
            snackbarType: Constants.SNACKBAR_TYPE.error,
            snackbarMessage: typeof(distWorkFieldDetailsList?.messages[0])!=='string'? distWorkFieldDetailsList?.messages[0]?.code+' - '+distWorkFieldDetailsList?.messages[0]?.detail:distWorkFieldDetailsList?.messages[0],
          });
         }
        }
      } else if(Object.keys(distWorkFieldDetailsList?.data).length===0 && allNullTypeCheck(distWorkFieldDetailsList?.messages)) {
        if(distWorkFieldDetailsList?.messages?.length > 0 && distWorkFieldDetailsList?.messages[0]?.includes("Try again...")){
            console.log("Try again block entered : ",distWorkFieldDetailsList?.data);
            this.setState({showLoaderResult: true})
            setTimeout(() => {
              this.props.getWorkFieldDetails(runID, distributorID, detailKey);
            }, 1000 * runsWaitTime);
            this.setState(prevState => {
            return {runsWaitTime: prevState.runsWaitTime * 2}
            })
            }
      }
       else {
        if (distWorkFieldDetailsList.error === true) {
        this.props.showSnackbar({
          snackbarOpen: true,
          snackbarType: Constants.SNACKBAR_TYPE.error,
          snackbarMessage: typeof(distWorkFieldDetailsList?.messages[0])!=='string'? distWorkFieldDetailsList?.messages[0]?.code+' - '+distWorkFieldDetailsList?.messages[0]?.detail:distWorkFieldDetailsList?.messages[0],
        });
       }
      }
    }else {
      if(distWorkFieldDetailsList.hasOwnProperty('status') && distWorkFieldDetailsList.status===202){
        this.setState({showLoaderResult: true})
            setTimeout(() => {
              this.props.getWorkFieldDetails(runID, distributorID, detailKey);
            }, 1000 * runsWaitTime);
            this.setState(prevState => {
            return {runsWaitTime: prevState.runsWaitTime * 2}
            })
     }
    }
    }
    if (nextProps.distWorkFieldDetailsError !== distWorkFieldDetailsError) {
      this.setState({ showLoaderResult: false });
      console.log("Error details : ",distWorkFieldDetailsError);
      if (distWorkFieldDetailsError.data.error === true) {
          this.props.showSnackbar({
            snackbarOpen: true,
            snackbarType: Constants?.SNACKBAR_TYPE?.error,
            snackbarMessage: typeof(distWorkFieldDetailsError?.data?.messages[0])!=='string'? distWorkFieldDetailsError?.data?.messages[0]?.code+' - '+distWorkFieldDetailsError?.data?.messages[0]?.detail:distWorkFieldDetailsError?.data?.messages[0],
          });
        }
          
    }
  }
 
  hideModal() {
    this.setState({ hideModal: true, showRemoveColModal: false });
    this.props.closefunction()
  }
  confirmModal() {
    this.setState({ showLoaderResult: true });
    this.hideModal();
    // this.props.selectedRun(this.state.selectedCommissionRun);
  }
  
  generateVolumeCSV = () =>{
    const { workFieldDetailsList } = this.state
    generateMatrixCSV(workFieldDetailsList?.workFieldDetails, fields, "WorkFieldsDetails")
  }
  render() {
    let loader;
    const {
      showRemoveColModal,      
      showLoaderResult,
      workFieldDetailsList,
    } = this.state;
    const { workFieldLabel } = this.props;
   
    if (showLoaderResult) {
      loader = (
        <div
          id="center"
          style={{ position: "fixed", bottom: "40%", left: "48%" }}
        >
          <Oval
            type="Puff"
            position="fixed"
            secondaryColor="#E85557"
            color="#E85557"
            height={50}
            width={50}
            timeout={3000}
          />
        </div>
      );
    }
    return (
      <div>
        <Modal
          dialogClassName="workfield-my-modal"
          show={showRemoveColModal}
          enforceFocus={false}
        >
          <Modal.Header>
            <div className="addcmpr-flex-row-1-ei" style={{minWidth:"455px"}}>
              <div className="add-cmnr-header-name cmnr-h3-header-3-18pt-bold-lato">
                {workFieldLabel} 
              </div>
              <img
                className="addcmpr-vector-ei"
                src={vector13}
                onClick={this.hideModal.bind(this)}
                alt=""
              />
            </div>
          </Modal.Header>
          <Modal.Body>
          <div className="col-12 workfield-cm-table-style" >
             <TableContainer component={Paper} style= {{maxHeight: "360px"}}>
             <Table stickyHeader sx={{ minWidth: 800 }} aria-label="simple table" className='general-table-left'>
                 <TableHead >
                     {fields.map((row) => (
                         <TableCell className={(row.displayName === "Name") || (row.displayName === "Dist ID")? "runmanager-table-header": "downline-table-header"} style= {{textAlign: (row.displayName === "Amount")? "right !important" : "left !important"}}  component="th" scope="row">
                             {row.displayName}
                         </TableCell>
                     ))}
                 </TableHead>
                 <TableBody>
                     {workFieldDetailsList?.workFieldDetails?.length > 0 ? workFieldDetailsList?.workFieldDetails?.map((workField,i) => (
                         <TableRow key={i}>
                             <TableCell className='data-text-style' style={{width: '80px'}} align="center">{workField?.distributorId}</TableCell>
                             <TableCell className='data-text-style' align="left">{workField?.distributorName}</TableCell>
                             <TableCell className='data-text-style' align="right">{ numberWithCommas(workField?.amount.toFixed(2))}</TableCell>
                             <TableCell className='data-text-style' align="right">{workField?.level}</TableCell>
                             {/* <TableCell className='data-text-style'>{commissionrun?.checkDate}</TableCell>
                             <TableCell className='data-text-style'>{commissionrun?.disbursementType}</TableCell> */}
                             {/* <TableCell className='data-text-style'>{numberWithCommas(commissionrun?.earnings?.toFixed(2))}</TableCell>
                             <TableCell className='data-text-style'>{commissionrun?.comments}</TableCell>
                             <TableCell className='data-text-styler'>{numberWithCommas(commissionrun?.poolVolume?.toFixed(2))}</TableCell>
                             <TableCell className='data-text-style'>{numberWithCommas(commissionrun?.poolFunds?.toFixed(2))}</TableCell> */}
                         </TableRow>
                     )) :
                         ''
                     }
                     {/* {commissionRunState.length > 0 ? 
                         <TableRow >
                             <TableCell className='runmanager-name'></TableCell>
                             <TableCell className='retailprofit-earnings' colSpan={5} align='right'>  Total Earnings:</TableCell>                                            
                             <TableCell  align='left' colSpan={3} className='runmanager-data-lastRun'>{numberWithCommas(commissionRunState.reduce((previousValue, currentValue) =>
                                 previousValue + currentValue.earnings,
                                 initialValue).toFixed(2))}</TableCell>
                         </TableRow>
                     :
                         ''
                     } */}
                 </TableBody>
             </Table>
         </TableContainer>
        </div>
          </Modal.Body>
          <Modal.Footer>
          <div className="add-run-type-btn  mr-auto"  style={{ color: '#D93030', cursor: "pointer", align:"left"}} onClick={this.generateVolumeCSV.bind(this)}>
            <img
                src={exports}                
                alt=""
              /></div>
            <div className="row">            
            <div style={{ backgroundColor: "#D93030" , cursor: "pointer",height: "30px", right: "0px", left: "50px",bottom: "0px" }} className=" col-6 cmnr-create-run" onClick={this.confirmModal.bind(this)}> 
             <div className="add-run-type-btn" style={{ color: 'white'}}>OK</div>              
            </div>
          </div>
          </Modal.Footer>
          <NewSnackbar />
          {loader}
        </Modal>
      </div>
    );
  }
}
WorkFieldDetailMatrix.propTypes = {
  defaultvalues: PropTypes.any,
  addCompareBackfunc: PropTypes.func,
};
const mapStateToProps = (state) => {
  return {
    distWorkFieldDetailsList: state.distributorResearchReducer.distWorkFieldDetailsList,
    distWorkFieldDetailsError: state.distributorResearchReducer.distWorkFieldDetailsError,
  };
};
const mapDispatchToProps = dispatch => ({
  getWorkFieldDetails: (runID, distributorID, workFieldID) => dispatch(getWorkFieldDetailsAPI(runID, distributorID, workFieldID)),
  showSnackbar: (params) => dispatch({ type: actions.SNACKBAR_SHOW, payload: params}),
})
export default connect(mapStateToProps, mapDispatchToProps)(WorkFieldDetailMatrix);