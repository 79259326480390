import actions from '../actions/IntegrationPopUp';

// reducer with initial state
const initialState = {
  isIntegrationLandingModel: false,
  isIntegrationNameModel: false,
  isIntegrationConfirmModel: false,
  isShowTopIntegrationOption: false,
  isViewIntegrationsShowSummary: false,
  isShowSummaryItem: false,
  isIntegrationItem: false,
  isEditIntegrationItem: false,
  isAuthTypeSelect: false
};

export default function integrationPopUpReducers(state, action) {
  if (typeof state === 'undefined') {
    return initialState;
  }
  switch (action.type) {
    case actions.SHOW_CREATE_INTEGRATIONS:
      return { ...state, isIntegrationLandingModel: true };
    case actions.HIDE_CREATE_INTEGRATIONS:
      return { ...state, isIntegrationLandingModel: false };
    case actions.SHOW_INTEGRATIONS_NAME:
      return { ...state, isIntegrationNameModel: true };
    case actions.HIDE_INTEGRATIONS_NAME:
      return { ...state, isIntegrationNameModel: false };
    case actions.SHOW_COMPLETE_INTEGRATIONS:
      return { ...state, isIntegrationConfirmModel: true };
    case actions.HIDE_COMPLETE_INTEGRATIONS:
      return { ...state, isIntegrationConfirmModel: false };
    case actions.SHOW_TOP_INTEGRATIONS:
      return { ...state, isShowTopIntegrationOption: true };
    case actions.HIDE_TOP_INTEGRATIONS:
      return { ...state, isShowTopIntegrationOption: false };
    case actions.SHOW_COMPLETE_SUMMARY_INTEGRATIONS:
      return { ...state, isViewIntegrationsShowSummary: true };
    case actions.HIDE_COMPLETE_SUMMARY_INTEGRATIONS:
      return { ...state, isViewIntegrationsShowSummary: false };
    case actions.SHOW_INTEGRAION_ITEM:
      return { ...state, isIntegrationItem: true};
    case actions.HIDE_INTEGRAION_ITEM:
      return { ...state, isIntegrationItem: false};
    case actions.SHOW_SUMMARY_ITEM:
      return {...state, isShowSummaryItem: true};
    case actions.HIDE_SUMMARY_ITEM:
      return {...state, isShowSummaryItem: false};  
    case actions.SHOW_EDITINTEGRATION_ITEM:
      return {...state, isEditIntegrationItem: true};
    case actions.HIDE_EDITINTEGRATION_ITEM:
      return {...state, isEditIntegrationItem: false};  
      case actions.SHOW_AUTHTYPE:
        return {...state, isAuthTypeSelect: true};
      case actions.HIDE_AUTHTYPE:
        return {...state, isAuthTypeSelect: false}; 
    default:
      return state;
  }

}