import React from 'react';
import './ProgressLine.css';
import progressdot from '../assets/dotgray.svg'
import { ProgressBar } from 'react-bootstrap';
import * as Constants from '../commonComponents/Constants'
import * as Constant from '../../../constants/Constants';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { previousQualRunExecussionAPICall, getRunStatusAPICall, qualRunResetAPICall, qualRunStopAPICall } from '../actions'
import {
  qualRunExecussionAPICall,
  getCurrentRunProgramsAPI,
} from "../actions";
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import { ConfirmModel } from '../ConfirmModel/ConfirmModel';
import CommissionProcess from '../CommissionProcessing/CommissionProcess';
import { UndoConfirmModal } from '../UndoConfirm/UndoConfirmModal';
import { allNullTypeCheck, isNullResponse } from '../commonComponents/UtilityFun';
import greenTick from '../assets/greenrightmark.svg';
import actions from '../../../redux/actions/CommonDataActions';
import NewSnackbar from "../../utilities/NewSnackbar";
import PreviousRunsQualModal from './PreviousRunsQualModal';
import { stringTruncate } from '../../utilities/Services';
import AuthService from "../../../../src/components/server/AuthService";
import externalLink from '../assets/externalLinkgray.svg'
import { Tooltip } from '@material-ui/core';

let runInterval = null;
class AnalysisProgressLine extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            runQualResponseState: {},
            steps: [],
            currentRunStatus: '',
            nextStepState: 0,
            currentStep: 0,
            currentPhase: '',
            dropdownEnable: true,
            currentRunID: '',
            statusUpdate: [
                { name: Constants.BUTTON_TEXT_RUN_QUAL, serviceName: Constants.RUN_QUALIFICATION_SERVICE, undoActionName: undefined, undoServiceName:undefined, dropdownOptionNames :[{ name:Constants.BUTTON_TEXT_RUN_QUAL_CALC}], title: Constants.MODEL_TITLE_RUN_QUAL},
                { name: Constants.BUTTON_RUN_CALCULATION, serviceName: Constants.RUN_CALCULATION_SERVICE, undoActionName: undefined, undoServiceName:undefined, dropdownOptionNames :[{name:Constants.BUTTON_RE_RUN_QUAL},{name:Constants.BUTTON_RE_RUN_QUAL_CALC}], title: Constants.MODEL_TITLE_RUN_CALC },
                // { name: Constants.BUTTON_TEXT_CERTIFY, serviceName: Constants.RUN_CERTIFICATION_SERVICE, undoActionName: Constants.BUTTON_UN_CERTIFY_NAME,undoServiceName: Constants.RUN_UNDO_CERTIFICATION_SERVICE },
                { name: Constants.BUTTON_TEXT_DISBURS, serviceName: Constants.RUN_DISBURSEMENT_SERVICE, undoActionName: Constants.BUTTON_UNDO_DISBURSE_NAME,undoServiceName:Constants.RUN_UNDO_DISBURSEMENT_SERVICE, dropdownOptionNames :[{name:Constants.BUTTON_RE_RUN_QUAL},{name:Constants.BUTTON_RE_RUN_QUAL_CALC}], title: Constants.MODEL_TITLE_RUN_DISBURSE },
                { name: Constants.BUTTON_TEXT_COMPLETED, serviceName: Constants.RUN_MAKELIVE_SERVICE, undoActionName: Constants.BUTTON_UNDO_LIVE_NAME, undoServiceName:Constants.RUN_UNDO_MAKELIVE_SERVICE, dropdownOptionNames :[{name:Constants.BUTTON_RE_RUN_QUAL},{name:Constants.BUTTON_RE_RUN_QUAL_CALC}], title: Constants.MODEL_TITLE_RUN_COMPLETED  },
                { name: Constants.BUTTON_TEXT_MERGEAR, serviceName: Constants.RUN_MERGEACCOUNTSRECEIVABLE_SERVICE, undoActionName: Constants.BUTTON_UN_MERGE_AR_NAME, undoServiceName: Constants.RUN_UNDO_MERGEACCOUNTRECEIVABLE_SERVICE, dropdownOptionNames :[{ name:Constants.BUTTON_RE_RUN_MAKELIVE }], title: Constants.MODEL_TITLE_RUN_MERGEAR  },
                { name: Constants.BUTTON_TEXT_NOTIFY, serviceName: Constants.RUN_NOTIFY_SERVICE, undoActionName: undefined, undoServiceName:undefined, dropdownOptionNames :[{ name:Constants.BUTTON_RE_RUN_MERGE_AR }], title: Constants.MODEL_TITLE_UNDO_MERGEAR  },
            ],
            displayRunStatusModel: false,
            displayCPModel: false,
            runStatusType: 'Run Qual',
            runStatusTypeConfirmBody: 'Are you sure you want to initiate the Qualification and Calculation process?',
            runStatusTypeConfirmBody1: 'Click OK to initiate the icebon1 and icebon2 programs.',
            runStatusTypeTitle: 'Run Qualification & Calculation',
            qualProgress: 0,
            minimizeQualProgress: 0,
            calcProgress: 0,
            certifyProgress: 0,
            disburseProgress: 0,
            makeLiveProgress: 0,
            margeARProgress: 0,
            notifyProgress: 0,
            commissionRunDetails: '',
            runQualDisabled: false,
            enableUndoAction:true,
            updateRunStatus: 'Qualifications',
            displayUndoRunStatusModal: false,
            showMinmizeUI: false,
            minimiseQualProgress: 0,
            isdisplayPreviousRuns: false,
            selectedPreviousRunId: '',
            dropdownSelectedItem:"",
            autoExecuteCalc:false,
            autoExecuteCalcStep:false,
            nextIndexValue:0,
            selectedStep:0,
            rundetailsCall:true,
            okButtonText: '',
            undoStepExecution:false,
            changeStepCalc:true,
            stepsWaitTime: 1,
            minimizeUIAutoHandle:0,
            tempCache: '',
            countText: '',
            isDisableRunStep:false
        }
      //  this.qualRunHandler = this.qualRunHandler.bind(this);
        runInterval=null;
        // this.closeSelectedRunProcessing = this.closeSelectedRunProcessing.bind(this);
        // this.disaplySelectedRunProcessing = this.disaplySelectedRunProcessing.bind(this);
        this.stopCommissionProcessing = this.stopCommissionProcessing.bind(this);
        this.continueCommissionProcessing = this.continueCommissionProcessing.bind(this);
        this.handleUndoActions = this.handleUndoActions.bind(this);
        this.closeSelectedUndoRunModal = this.closeSelectedUndoRunModal.bind(this)

    }
    disaplySelectedRunProcessing=(actionType)=> {
        // this.setState({ displayRunStatusModel: !this.state.displayRunStatusModel, displayCPModel: !this.state.displayCPModel });
        //here to call Commission Processing model
        const {nextIndexValue, statusUpdate, displayRunStatusModel, displayCPModel, selectedPreviousRunId, dropdownSelectedItem, displayUndoRunStatusModal, selectedStep, tempCache} = this.state	
        const{commissionRunId, runQualResponse } = this.props
        //console.log('service name and run id', nextIndexValue, statusUpdate[nextIndexValue]?.serviceName, dropdownSelectedItem, actionType)	
        //console.log('qual resposne cachekey',runQualResponse?.data?.cacheKey)
        if(actionType!== "Try again"){ this.setState({ displayRunStatusModel: false, displayCPModel: true, tempCache: '' });}
        
        switch (nextIndexValue) {
          case 0:
              if(dropdownSelectedItem===""){
                this.setState({ selectedStep: actionType!== "Try again"? nextIndexValue: selectedStep }, () => {});
                if(allNullTypeCheck(selectedPreviousRunId)){
                    this.props.qualRunPrerunExecussion(commissionRunId, statusUpdate[0]?.serviceName, selectedPreviousRunId, tempCache );	
                }else{
                    this.props.qualRunExecussion(commissionRunId, statusUpdate[0]?.serviceName, tempCache);
                }
              }else {
                this.setState({ selectedStep: actionType!== "Try again"? 0 : selectedStep }, () => {});
                if(allNullTypeCheck(selectedPreviousRunId)){
                    this.props.qualRunPrerunExecussion(commissionRunId, statusUpdate[0]?.serviceName, selectedPreviousRunId, tempCache);	
                    this.handleAutoExecution(nextIndexValue, dropdownSelectedItem);
                }else{
                this.props.qualRunExecussion(commissionRunId, statusUpdate[0]?.serviceName, tempCache);
                this.handleAutoExecution(nextIndexValue, dropdownSelectedItem);
                }
              }
            break;
          case 1:
            if(dropdownSelectedItem===""){
                this.setState({ selectedStep: actionType!== "Try again"? nextIndexValue: selectedStep }, () => {});
                this.props.qualRunExecussion(commissionRunId, statusUpdate[1]?.serviceName, tempCache);
              }else {
                this.setState({ selectedStep: actionType!== "Try again"? 0 : selectedStep }, () => {});
                  if(dropdownSelectedItem==="1" || dropdownSelectedItem==="2"){
                    //   this.setState({nextIndexValue:0});
                    if (allNullTypeCheck(selectedPreviousRunId)) {
                      this.props.qualRunPrerunExecussion(
                        commissionRunId,
                        statusUpdate[0]?.serviceName,
                        selectedPreviousRunId, 
                        tempCache
                      );
                    } else {
                      this.props.qualRunExecussion(
                        commissionRunId,
                        statusUpdate[0]?.serviceName,
                        tempCache
                      );
                     }
                    this.handleAutoExecution(nextIndexValue, dropdownSelectedItem);
                  }
              }
            break;
          case 2:
            if(dropdownSelectedItem===""){
                this.setState({ selectedStep: actionType!== "Try again"? nextIndexValue: selectedStep }, () => {});
                this.props.qualRunExecussion(commissionRunId, statusUpdate[2]?.serviceName, tempCache);
              }else {
                this.setState({ selectedStep: actionType!== "Try again"? 0 : selectedStep }, () => {});
                if(dropdownSelectedItem==="1" || dropdownSelectedItem==="2"){
                    if(allNullTypeCheck(selectedPreviousRunId)){
                        this.props.qualRunPrerunExecussion(commissionRunId, statusUpdate[0]?.serviceName, selectedPreviousRunId, tempCache);	
                    }else{
                    this.props.qualRunExecussion(commissionRunId, statusUpdate[0]?.serviceName, tempCache);
                    }
                    this.handleAutoExecution(nextIndexValue, dropdownSelectedItem);
                }
              }
            break;
          case 3:
            if(dropdownSelectedItem==="1" || dropdownSelectedItem==="2"){
                this.setState({ selectedStep: actionType!== "Try again"? 0 : selectedStep }, () => {});
                if(allNullTypeCheck(selectedPreviousRunId)){
                    this.props.qualRunPrerunExecussion(commissionRunId, statusUpdate[0]?.serviceName, selectedPreviousRunId, tempCache);	
                }else{
                this.props.qualRunExecussion(commissionRunId, statusUpdate[0]?.serviceName, tempCache);
                }
                this.handleAutoExecution(nextIndexValue, dropdownSelectedItem);
              }
            break;
          default:
            break;
        }
    }
    handleAutoExecution=(nextIndexValue, dropdownValue)=>{
        const { runStatusResponse } = this.props
        const { steps } = runStatusResponse?.data;
        if(nextIndexValue===0 && dropdownValue === "1"){
            this.setState({
                autoExecuteCalc: true
              });
        }else if (nextIndexValue===1 && dropdownValue === "2") {
            console.log("handle auto execution :")
            this.setState({
              autoExecuteCalc: true,
              autoExecuteCalcStep:steps[0]?.status.toLowerCase() ===
                        "completed"? true : false,
            },()=>{});
        } else if(nextIndexValue!==1 && dropdownValue === "2"){
            this.setState({
                autoExecuteCalc: true
              });
        }
    }
    dropdownRunHandler=(runID, nextIndex, dropdownItem)=>{
        console.log("commision details : ",runID,nextIndex);
        const { previousRuns, runDetails, runStatusResponse } = this.props
        this.setState({dropdownSelectedItem:""},()=>{});
        let indexValue = null
        let stepModalBody1 = '', stepModalBody = ''
        switch (nextIndex) {
          case 0:
                indexValue = nextIndex;
                stepModalBody = 'Are you sure you want to initiate the Qualification and Calculation process?'
                stepModalBody1 = 'Click Run to initiate the ' + runDetails?.qualificationProgram + ' , ' + runDetails?.calculationProgram + ' programs.'
                this.setState({
                    isdisplayPreviousRuns: !this.state.isdisplayPreviousRuns, nextIndexValue: indexValue,
                    runStatusTypeConfirmBody: stepModalBody,
                    runStatusTypeConfirmBody1: stepModalBody1,
                    runStatusTypeTitle: 'Run Qualification & Calculation',
                    dropdownSelectedItem: dropdownItem,
                    okButtonText: 'Run'

                },()=>{});
            break;
          case 1:
              if(dropdownItem==="1"){
                indexValue=nextIndex;
                stepModalBody = 'Are you sure you want to initiate the Qualification  process?'
                stepModalBody1 = 'Click Run to initiate the ' + runDetails?.qualificationProgram +' programs.'
                if(allNullTypeCheck(previousRuns) && previousRuns.length > 0){
                    this.setState({ isdisplayPreviousRuns: !this.state.isdisplayPreviousRuns, nextIndexValue: indexValue,
                        runStatusTypeConfirmBody: stepModalBody,
                        runStatusTypeConfirmBody1: stepModalBody1,
                        runStatusTypeTitle: 'Re-Run Qualification',
                        dropdownSelectedItem: dropdownItem,
                        okButtonText: 'Run'
                       });
                }
                else{
                this.setState({
                    displayRunStatusModel: !this.state.displayRunStatusModel, nextIndexValue: indexValue,
                    runStatusTypeConfirmBody: stepModalBody,
                    runStatusTypeConfirmBody1: stepModalBody1,
                    runStatusTypeTitle: 'Re-Run Qualification',
                    dropdownSelectedItem: dropdownItem,
                    okButtonText: 'Run'
                },()=>{});
               }
              }else {
                indexValue=nextIndex;
                stepModalBody = 'Are you sure you want to initiate the Qualification and Calculation process?'
                stepModalBody1 = 'Click Run to initiate the ' + runDetails?.qualificationProgram + ' , ' + runDetails?.calculationProgram + ' programs.'
                if(allNullTypeCheck(previousRuns) && previousRuns.length > 0){
                    this.setState({ isdisplayPreviousRuns: !this.state.isdisplayPreviousRuns, nextIndexValue: indexValue,
                        runStatusTypeConfirmBody: stepModalBody,
                        runStatusTypeConfirmBody1: stepModalBody1,
                        runStatusTypeTitle: 'Re-Run Qualification & Calculation',
                        dropdownSelectedItem: dropdownItem,
                        okButtonText: 'Run'
                       },()=>{});
                }
                else{
                this.setState({
                    displayRunStatusModel: !this.state.displayRunStatusModel, nextIndexValue: indexValue,
                    runStatusTypeConfirmBody: stepModalBody,
                    runStatusTypeConfirmBody1: stepModalBody1,
                    runStatusTypeTitle: 'Re-Run Qualification & Calculation',
                    dropdownSelectedItem: dropdownItem,
                    okButtonText: 'Run'
                },()=>{});
              }
              }
            break;
          case 2:
            if(dropdownItem==="1"){
                indexValue=nextIndex;
                stepModalBody = 'Are you sure you want to initiate the Qualification process?'
                stepModalBody1 = 'Click Run to initiate the ' + runDetails?.qualificationProgram +' programs.'
                if(allNullTypeCheck(previousRuns) && previousRuns.length > 0){
                    this.setState({ isdisplayPreviousRuns: !this.state.isdisplayPreviousRuns, nextIndexValue: indexValue,
                        runStatusTypeConfirmBody: stepModalBody,
                        runStatusTypeConfirmBody1: stepModalBody1,
                        runStatusTypeTitle: 'Re-Run Qualification',
                        dropdownSelectedItem: dropdownItem,
                        okButtonText: 'Run'
                       });
                }
                else{
                this.setState({
                    displayRunStatusModel: !this.state.displayRunStatusModel, nextIndexValue: indexValue,
                    runStatusTypeConfirmBody: stepModalBody,
                    runStatusTypeConfirmBody1: stepModalBody1,
                    runStatusTypeTitle: 'Re-Run Qualification',
                    dropdownSelectedItem: dropdownItem,
                    okButtonText: 'Run'
                },()=>{});
               }
              }else {
                indexValue=nextIndex;
                stepModalBody = 'Are you sure you want to initiate the Qualification and Calculation process?'
                stepModalBody1 = 'Click Run to initiate the ' + runDetails?.qualificationProgram + ' , ' + runDetails?.calculationProgram + ' programs.'
                if(allNullTypeCheck(previousRuns) && previousRuns.length > 0){
                    this.setState({ isdisplayPreviousRuns: !this.state.isdisplayPreviousRuns, nextIndexValue: indexValue,
                        runStatusTypeConfirmBody: stepModalBody,
                        runStatusTypeConfirmBody1: stepModalBody1,
                        runStatusTypeTitle: 'Re-Run Qualification & Calculation',
                        dropdownSelectedItem: dropdownItem,
                        okButtonText: 'Run'
                       });
                }
                else{
                this.setState({
                    displayRunStatusModel: !this.state.displayRunStatusModel, nextIndexValue: indexValue,
                    runStatusTypeConfirmBody: stepModalBody,
                    runStatusTypeConfirmBody1: stepModalBody1,
                    runStatusTypeTitle: 'Re-Run Qualification & Calculation',
                    dropdownSelectedItem: dropdownItem,
                    okButtonText: 'Run'
                },()=>{});
              }
              }
            break;
          case 3:
            if(dropdownItem==="1"){
                indexValue=nextIndex;
                stepModalBody = 'Are you sure you want to initiate the Qualification  process?'
                stepModalBody1 = 'Click Run to initiate the ' + runDetails?.qualificationProgram +' programs.'
                if(allNullTypeCheck(previousRuns) && previousRuns.length > 0){
                    this.setState({ isdisplayPreviousRuns: !this.state.isdisplayPreviousRuns, nextIndexValue: indexValue,
                        runStatusTypeConfirmBody: stepModalBody,
                        runStatusTypeConfirmBody1: stepModalBody1,
                        runStatusTypeTitle: 'Re-Run Qualification',
                        dropdownSelectedItem: dropdownItem,
                        okButtonText: 'Run'
                       });
                }
                else{
                this.setState({
                    displayRunStatusModel: !this.state.displayRunStatusModel, nextIndexValue: indexValue,
                    runStatusTypeConfirmBody: stepModalBody,
                    runStatusTypeConfirmBody1: stepModalBody1,
                    runStatusTypeTitle: 'Re-Run Qualification',
                    dropdownSelectedItem: dropdownItem,
                    okButtonText: 'Run'
                },()=>{});
               }
              }else {
                indexValue=nextIndex;
                stepModalBody = 'Are you sure you want to initiate the Qualification and Calculation process?'
                stepModalBody1 = 'Click Run to initiate the ' + runDetails?.qualificationProgram + ' , ' + runDetails?.calculationProgram + ' programs.'
                if(allNullTypeCheck(previousRuns) && previousRuns.length > 0){
                    this.setState({ isdisplayPreviousRuns: !this.state.isdisplayPreviousRuns, nextIndexValue: indexValue,
                        runStatusTypeConfirmBody: stepModalBody,
                        runStatusTypeConfirmBody1: stepModalBody1,
                        runStatusTypeTitle: 'Re-Run Qualification & Calculation',
                        dropdownSelectedItem: dropdownItem,
                        okButtonText: 'Run'
                       });
                }
                else{
                this.setState({
                    displayRunStatusModel: !this.state.displayRunStatusModel, nextIndexValue: indexValue,
                    runStatusTypeConfirmBody: stepModalBody,
                    runStatusTypeConfirmBody1: stepModalBody1,
                    runStatusTypeTitle: 'Re-Run Qualification & Calculation',
                    dropdownSelectedItem: dropdownItem,
                    okButtonText: 'Run'
                },()=>{});
              }
              }
            break;
  
           default:
            break;
        }
        console.log("currentstep and statusupdate : ", this.state.nextStepState, this.state.statusUpdate);
    }
    closeSelectedRunProcessing=()=> {
        this.setState({ displayRunStatusModel: !this.state.displayRunStatusModel, dropdownSelectedItem:"" });
    }
    closeSelectedUndoRunModal() {
        this.setState({ displayUndoRunStatusModal: !this.state.displayUndoRunStatusModal });
    }
    componentDidMount() {
        const {previousRuns, commissionRunId, } = this.props
        console.log("selected Analysis run id : ",this.props.commissionRunId)	
        console.log('previous runs', previousRuns)
        if(allNullTypeCheck(commissionRunId)){	
            if (AuthService.isTokenValidCheck() === true) {
            this.props.getRunStatus(commissionRunId);	
            }
        }
    }
    componentWillReceiveProps(nextProps) {
        console.log("triggered : ", nextProps.commissionRunDetails);
        this.setState({ commissionRunDetails: nextProps.commissionRunDetails.data });
    }
    stopCommissionProcessing(runID) {	
        this.setState({ displayCPModel: !this.state.displayCPModel });	
        this.props.qualRunStop(runID) 	
    }
    continueCommissionProcessing() {
        const { displayCPModel, showMinmizeUI, currentStep} = this.state
        this.setState({ displayCPModel: !displayCPModel, showMinmizeUI: !showMinmizeUI});
        console.log('current step', currentStep,'this.state.displayCPModel',displayCPModel, 'showMinmizeUI', showMinmizeUI)
        if(currentStep === 3){
            this.setState({ showMinmizeUI: false});
        }
    }
    closeCommisisonProcessModal(){
        this.setState({ displayCPModel: false, dropdownSelectedItem: "", undoStepExecution:false, autoExecuteCalc:false, autoExecuteCalcStep: false  });
    }
    hideMinmiseUI(){
        const {displayCPModel, showMinmizeUI} = this.state
        this.setState({ displayCPModel: !displayCPModel, showMinmizeUI: !showMinmizeUI});
    }
    notifyStatusRun(runID){	
        // console.log('selected run id', runID)	
         if(runID !== null && runID !== undefined){	
           //  this.props.qualRunStop(runID) 	
             this.props.qualRunReset(runID) 	
             this.setState({nextIndexValue: 0})	
         }	
     }
    componentWillUnmount(){
        if(runInterval){
            clearInterval(runInterval);
        }
    }
    handleAddPreviousRunsModal(){
        this.setState({ isdisplayPreviousRuns: !this.state.isdisplayPreviousRuns})
    }
    
    selectedPreviousRun(selectedPreviousrunId) {
        const  { commissionRunId } = this.props
        console.log('selected comm Previous runId', selectedPreviousrunId)
        console.log('Current run Id', commissionRunId)
        if (allNullTypeCheck(selectedPreviousrunId)) {
            this.setState({ displayRunStatusModel: !this.state.displayRunStatusModel, selectedPreviousRunId: selectedPreviousrunId })
           // this.props.qualRunExecussion(commissionRunId, "runQualification", selectedPreviousrunId)
        }
    }
    currentMinimizeProgressUpdate(steps, selectedStep){
        let percentProgress = 0;
        if (steps[selectedStep].status.toLowerCase() === 'processing') {
            this.setState({ minimizeQualProgress: 0 });
            if (steps[selectedStep].phases.length > 0) {
                for (let indexi = 0; indexi < steps[selectedStep]?.phases.length; indexi++) {
                    
                    if (steps[selectedStep]?.phases[indexi].status !== 'pending' && steps[selectedStep]?.phases[indexi].status !== 'completed' && steps[selectedStep]?.phases[indexi].status !== 'complete') {
                        percentProgress = allNullTypeCheck(steps[selectedStep]?.phases[indexi].currentRecord / steps[selectedStep]?.phases[indexi].totalRecords) ? Math.round((steps[selectedStep]?.phases[indexi].currentRecord / steps[selectedStep]?.phases[indexi].totalRecords) * 100) : 0
                        //console.log("each step progress is :", Math.round((steps[selectedStep]?.phases[indexi].currentRecord/steps[selectedStep]?.phases[indexi].totalRecords)*100));
                        this.setState({ minimizeQualProgress: percentProgress, countText: `${steps[selectedStep]?.phases[indexi].currentRecord} of ${steps[selectedStep]?.phases[indexi].totalRecords}`});
                    }
                    if (steps[selectedStep]?.phases[indexi].status === 'completed') {
                        percentProgress = 0 // percentProgress+percentProgress*indexi;
                        this.setState({ minimizeQualProgress: 100 });
                    }
                }
            }
        } 
    }
    componentDidUpdate(nextProps) {	
        const {
          runQualResponseState,
          currentPhase,
          currentRunID,
          currentRunStatus,
          nextStepState,
          updateRunStatus,
          isdisplayPreviousRuns,
          autoExecuteCalc,
          statusUpdate,
          autoExecuteCalcStep,
          changeStepCalc,
          stepsWaitTime,
          selectedStep,
          minimizeUIAutoHandle,
          showMinmizeUI,
          displayRunStatusModel,
          displayCPModel,
          isDisableRunStep
        } = this.state;
        const {
          runQualResponse,
          getRunStatus,
          runStatusResponse,
          selectedCommissionRun,
          runQualError,
          runStatusError,
          commissionRunDetails,
          runQualResetResposne,
          runQualStopResposne,
          commissionRunId,
          runQualStopError,
          runQualResetError,
          selecteRunPrgmError,
          selectedRunPrgmResponse,
        } = this.props;

        if (runQualResponse !== nextProps.runQualResponse){
          if(!runQualResponse.hasOwnProperty('status')){ 
            if(isNullResponse(runQualResponse?.data) && runQualResponse.error===false) {
                console.log("Run Qual response : ", runQualResponse, nextStepState, this.state.statusUpdate);
                this.setState({ runQualResponseState: runQualResponse.data, tempCache: ''});
                console.log("id : ", runQualResponseState);
                if (AuthService.isTokenValidCheck() === true) {
                getRunStatus(runQualResponse.data.runId);
                }
            } else {
                console.log("Run execution else block : ",runQualResponse?.data);
                if(Object.keys(runQualResponse?.data).length===0){
                    if(runQualResponse?.messages?.length > 0 && runQualResponse?.messages?.[0].includes("Try again")){
                        console.log("Try again block entered : ",runQualResponse?.data);
                        if (AuthService.isTokenValidCheck() === true) {
                            setTimeout(()=>{
                                this.disaplySelectedRunProcessing("Try again");
                            },1000*stepsWaitTime);
                            this.setState(prevState => {
                                return {stepsWaitTime: prevState.stepsWaitTime * 2}
                             })
                        }
                    }
                    console.log("Try again block entering failed : ",runQualResponse?.data);
                }
                console.log("Current step response and run id :",runQualResponse?.data,commissionRunId)
               // this.props.showSnackbar({snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.warning, snackbarMessage:'Current run step Response Empty'});
               if (AuthService.isTokenValidCheck() === true) { 
               getRunStatus(commissionRunId);
               }
            } 
        }else {
                if(runQualResponse.hasOwnProperty('status') && runQualResponse.status===202){
                    this.setState({ tempCache: runQualResponse?.data?.cacheKey}) 
                    console.log('runQualResponse?.data?.cacheKey',runQualResponse?.data?.cacheKey)
                    setTimeout(()=>{
                        this.disaplySelectedRunProcessing("Try again");
                    },1000*stepsWaitTime);
                    this.setState(prevState => {
                        return {stepsWaitTime: prevState.stepsWaitTime * 2}
                })
            }
        }

        }
        if (runQualError !== nextProps.runQualError) {
            console.log("Run Qual execution failed :", runQualError);
           if(runQualError?.data?.error === true){
            this.props.showSnackbar({snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: typeof(runQualError?.data?.messages[0])!=='string' && allNullTypeCheck(runQualError?.data?.messages[0]?.detail)?
            runQualError?.data?.messages[0]?.code+' - '+  runQualError?.data?.messages[0]?.detail: runQualError?.data?.messages[0]?.code+' - '+runQualError?.data?.messages[0]?.message})
            this.setState({displayCPModel: false})
           } else if(runQualError?.message) {
            this.setState({displayCPModel: false})
            this.props.showSnackbar({snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: runQualError?.message })
           }
        }
        if (runStatusResponse !== nextProps.runStatusResponse){
            if(isNullResponse(runStatusResponse?.data) && runStatusResponse?.error===false) {
                const { currentStep, nextStep, status, currentPhase, message } = runStatusResponse?.data?.runDetails;
                const { steps } = runStatusResponse?.data;
                this.setState({
                    currentRunStatus: runStatusResponse?.data?.runDetails,
                    steps: runStatusResponse.data.steps,
                    currentStep: currentStep,
                    nextStepState: nextStep === null && currentStep===null && currentPhase===null ? 0 : runStatusResponse?.data?.runDetails.nextStep,
                    currentPhase: runStatusResponse?.data?.runDetails.currentPhase
                },()=>{});

                if ((currentStep !== null) && (nextStep === null) && (status.toLowerCase() === 'completed'|| status.toLowerCase() === 'complete')) {
                    // console.log("called currentstep equalt 7 : ",currentStep);
                    this.setState({ updateRunStatus: 'Calculation Complete', enableUndoAction:false,  nextStepState: 3 })
                    this.props.handleRunActions(false);
                    steps.length>0 && steps.forEach((element,i) => {
                        if(i<=currentStep){
                            this.updateProgressStatus(i, runStatusResponse.data.steps);
                        }
                    });
                    // if(runInterval){
                    //     clearInterval(runInterval);
                    // }
                    
                } else if ((nextStep!==null) && (currentStep!==null) && (currentStep >= 0) && (currentStep <= 7)) {
                    steps[currentStep]?.name === undefined ? this.setState({ updateRunStatus: 'Qualification' }) : this.setState({ updateRunStatus: message, enableUndoAction:true });
                    if( steps[currentStep]?.status.toLowerCase()==='processing'){
                   // this.setState({ minimiseQualProgress: 0 })
                    }
                    console.log("results are calc: ",currentStep,steps[currentStep]?.status.toLowerCase(),autoExecuteCalc);
                    if (
                      currentStep === 0  &&
                      (steps[currentStep]?.status.toLowerCase() ===
                    "pending" || steps[currentStep]?.status.toLowerCase() === "processing" || steps[currentStep]?.status.toLowerCase()==='completed') &&
                      autoExecuteCalc
                    ) {
                    if(autoExecuteCalcStep && currentStep===0 && changeStepCalc ){
                        console.log("changes step called : ")
                        this.setState({ changeStepCalc: steps[currentStep]?.status.toLowerCase() ===
                         "pending" || steps[currentStep]?.status.toLowerCase() === "processing"? false: true },()=>{});
                        
                    }else {
                        console.log("else step changes step called : ")
                        if(currentStep===0 && autoExecuteCalcStep){
                            if(steps[currentStep]?.status.toLowerCase()==='completed'){
                                this.props.qualRunExecussion(commissionRunId, statusUpdate[1]?.serviceName);
                                this.setState({changeStepCalc : true, autoExecuteCalcStep:false, autoExecuteCalc:false, minimizeUIAutoHandle: 1, selectedStep:1 },()=>{});
                            }
                        }else {
                            if(autoExecuteCalc && steps[currentStep]?.status.toLowerCase()==='completed'){
                                this.props.qualRunExecussion(commissionRunId, statusUpdate[1]?.serviceName);
                                this.setState({ autoExecuteCalc: false , autoExecuteCalcStep: false, changeStepCalc:true, minimizeUIAutoHandle: 1, selectedStep:1 },()=>{});
                            }
                        }
                    }
                      steps.length > 0 &&
                        steps.forEach((element, i) => {
                          this.updateProgressStatus(i, steps);
                        });
                    } else {
                      steps.length > 0 &&
                        steps.forEach((element, i) => {
                            this.updateProgressStatus(i, steps);
                        });
                    }
                    if(steps[selectedStep].status === 'completed' || steps[selectedStep].status ==='complete'){
                      if(currentStep===0 && minimizeUIAutoHandle===0 && !autoExecuteCalc){
                        setTimeout(() => {
                            this.setState({ showMinmizeUI: false, isDisableRunStep:false});
                        }, 1000);
                      }else if(currentStep===0 && minimizeUIAutoHandle===1 && !autoExecuteCalc) {
                        setTimeout(() => {
                            this.setState({ showMinmizeUI: true, isDisableRunStep:false});
                        }, 1000);
                      }else if(currentStep > 0 && minimizeUIAutoHandle===1 && !autoExecuteCalc) {
                        setTimeout(() => {
                            this.setState({ showMinmizeUI: false, isDisableRunStep:false});
                        }, 1000);
                      }else if(currentStep > 0 && minimizeUIAutoHandle===0 && !autoExecuteCalc) {
                        setTimeout(() => {
                            this.setState({ showMinmizeUI: false, isDisableRunStep:false});
                        }, 1000);

                      }
                        
                    }
                    if(allNullTypeCheck(currentStep) && steps[currentStep]?.status.toLowerCase() === "processing") {
                        if(displayRunStatusModel===false && displayCPModel===false){
                            this.setState({ showMinmizeUI: true, isDisableRunStep:true});
                         this.currentMinimizeProgressUpdate(steps, currentStep)
                        }
                    }
 
                } else if (currentStep===null && nextStep===null && currentPhase===null) {
                    steps.length > 0 &&
                        steps.forEach((element, i) => {
                            this.updateProgressStatus(i, steps);
                        });
                    this.setState({ updateRunStatus: 'Qualifications' });
                }
                if (runInterval===null) {
                    // console.log('runInterval  : .setInterval()');
                    if (AuthService.isTokenValidCheck() === true) {
                    runInterval = setInterval(() => {
                        // console.log('RunDetailsModal.setInterval()');
                        getRunStatus(runStatusResponse.data.runId);
                    }, 5000);
                }
                }
            }
        }
        if (runStatusError !== nextProps.runStatusError) {
            if(runInterval){
                clearInterval(runInterval);
            }
            if(runStatusError?.data?.error === true){
                this.props.showSnackbar({snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: typeof(runStatusError?.data?.messages[0])!=='string'? runStatusError?.data?.messages[0]?.code+' - '+runStatusError?.data?.messages[0]?.detail:runStatusError?.data?.messages[0]})
                this.closeCommisisonProcessModal()
               }else if(runStatusError?.message) {
                this.props.showSnackbar({snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: runStatusError?.message })
                this.closeCommisisonProcessModal()
               }
        }
        if (runQualResetResposne !== nextProps.runQualResetResposne){
            if(isNullResponse(runQualResetResposne?.data) && runQualResetResposne.error===false) {
                console.log("runQualResetResposne.data.id  : ", runQualResetResposne.data.id)
                if (AuthService.isTokenValidCheck() === true) {
                getRunStatus(runQualResetResposne.data.id);
                this.props.handleRunActions(true);
                }
                this.setState({ qualProgress: 0, minimiseQualProgress: 0, certifyProgress: 0, disburseProgress: 0, makeLiveProgress: 0, notifyProgress: 0, showMinmizeUI: false, updateRunStatus: 'Qualification', runQualDisabled: false, dropdownEnable: true});
                this.props.showSnackbar({snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.success, snackbarMessage:'Run Reset Successfully'})
                if(runInterval){
                    clearInterval(runInterval);
                }
            }
        } else if(runQualResetError !== nextProps.runQualResetError){
            console.log("runQualReset error Response: ", runQualResetResposne)
        }
        if (runQualStopResposne !== nextProps.runQualStopResposne){
            if(isNullResponse(runQualStopResposne?.data) && runQualStopResposne.error===false) {
                console.log("runQualStopResposne.data.id  : ", runQualStopResposne.data.id)
                if (AuthService.isTokenValidCheck() === true) {
                getRunStatus(runQualStopResposne.data.id);
                }
            }
            this.props.showSnackbar({snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.info, snackbarMessage:'Run Stopped'})
        } else if(runQualStopError!== nextProps.runQualStopError){
            console.log("stop run qual error response: ", runQualStopError)
        }
        if(selectedRunPrgmResponse !== nextProps.selectedRunPrgmResponse){
            if(isNullResponse(selectedRunPrgmResponse?.data)) {
                console.log("runQualStopResposne.data.id  : ", selectedRunPrgmResponse.data)   
            }
        }else if(selecteRunPrgmError!==nextProps.selecteRunPrgmError){
            console.log("stop run qual error response: ", selecteRunPrgmError)
        }
    }
    updateProgressStatus(currentStep, steps) {
        const { nextStepState } = this.state;
        console.log("************* update progress called : ********")
        switch (currentStep) {
            case 0:
                if (steps[0].status === 'completed'|| steps[0].status ==='complete') {
                    this.setState({ qualProgress: 100 },()=>{
                        if(this.state.rundetailsCall){
                            this.props.controlRunDetails();
                            this.setState({ rundetailsCall:false});
                        } 
                    });
                }
                else{
                    this.setState({ qualProgress: 0 });
                }
                // this.setState({ dropdownEnable: false },()=>{});
                break;
            case 1:
                if (steps[1].status === 'completed' || steps[1].status ==='complete') {
                    this.setState({ certifyProgress: 100 },()=>{
                        if(this.state.rundetailsCall){
                            this.props.controlRunDetails();
                            this.setState({ rundetailsCall:false});
                        }
                        if(!this.state.autoExecuteCalc && this.state.showMinmizeUI){
                           // alert('updated')
                            this.setState({showMinmizeUI:false});
                        }
                    });
                }
                else{
                    this.setState({ certifyProgress: 0 });
                }
                // this.setState({ dropdownEnable: false },()=>{});
                break;
            // case 2:
            //     if (steps[2].status === 'completed' || steps[2].status ==='complete') {
            //         this.setState({ certifyProgress: 100, disburseProgress: 100 },()=>{
            //             if(this.state.rundetailsCall){
            //                 this.props.controlRunDetails();
            //                 this.setState({ rundetailsCall:false });
            //             }
            //         });
            //         setTimeout(() => {
            //             this.setState({ minimiseQualProgress: 100});
            //         }, 1000);
            //     }
            //     else{
            //         if (steps[1].status === 'completed' || steps[1].status ==='complete') {
            //             this.setState({ certifyProgress: 100, minimiseQualProgress: 100 },()=>{
            //                 if(this.state.rundetailsCall){
            //                     this.props.controlRunDetails();
            //                     this.setState({ rundetailsCall:false });
            //                 }
            //             });
            //         }
            //         else{
            //             this.setState({ certifyProgress: 0, minimiseQualProgress: 0});
            //         }
            //     }
            //     // this.setState({ dropdownEnable: false });
            //     break;
            case 2:
                if (steps[2]?.status === 'completed' || steps[2]?.status ==='complete') {
                    this.setState({ disburseProgress: 100 },()=>{
                        if(this.state.rundetailsCall){
                            this.props.controlRunDetails();
                            this.setState({ rundetailsCall:false, showMinmizeUI: false });
                        }
                       
                    });
                }
                else{
                    this.setState({ disburseProgress: 0 });
                }
                // this.setState({ dropdownEnable: false });
                break;
            
            default:
                return this.state;
        }
    }
    getNextStep() {

        return this.state.steps.findIndex((step) => {
            return (step.status.toLowerCase() === "pending");
        });
    }
    qualRunHandler = (runID, nextIndex)=> {
        const {steps, currentStep, statusUpdate} = this.state
        const {previousRuns} = this.props;
        const {runDetails} = this.props
        let indexValue = null
        let stepModalBody1 = '', stepTitle = '', stepModalBody = ''
        switch (nextIndex) {
            case 0:
                indexValue = nextIndex;
                stepModalBody = 'Are you sure you want to initiate the ' + steps[0]?.name + ' process?'
                stepModalBody1 = 'Click Run to initiate the ' + runDetails?.qualificationProgram + ' program.'
                stepTitle = statusUpdate[0]?.title
                if(allNullTypeCheck(previousRuns) && previousRuns.length > 0 && nextIndex === 0){
                    this.setState({ isdisplayPreviousRuns: !this.state.isdisplayPreviousRuns, nextIndexValue: indexValue,
                        runStatusTypeConfirmBody: stepModalBody,
                        runStatusTypeConfirmBody1: stepModalBody1,
                        runStatusTypeTitle: stepTitle,
                        okButtonText: 'Run'
                       });
                }
                else{
                    this.setState({ displayRunStatusModel: !this.state.displayRunStatusModel, nextIndexValue: indexValue,
                        runStatusTypeConfirmBody: stepModalBody,
                        runStatusTypeConfirmBody1: stepModalBody1,
                        runStatusTypeTitle: stepTitle,
                        okButtonText: 'Run'
                       });
                }
            break;
            case 1:
                indexValue = nextIndex;
                stepModalBody = 'This will run the Calculation process.'
                stepModalBody1 = 'Calculation program: ' + runDetails?.calculationProgram
                stepTitle = statusUpdate[1]?.title
                this.setState({ displayRunStatusModel: !this.state.displayRunStatusModel, nextIndexValue: indexValue,
                    runStatusTypeConfirmBody: stepModalBody,
                    runStatusTypeConfirmBody1: stepModalBody1,
                    runStatusTypeTitle: stepTitle,
                    okButtonText: 'Run'
                   });
            break;
            case 2:
                indexValue = nextIndex;
                stepModalBody = 'Are you sure you want to initiate the ' + steps[2]?.name + ' process?'
                stepModalBody1 = 'Click Disburse to initiate the ' + runDetails?.disbursementProgram + ' program.'
                stepTitle =  statusUpdate[2]?.title
                this.setState({ displayRunStatusModel: !this.state.displayRunStatusModel, nextIndexValue: indexValue,
                    runStatusTypeConfirmBody: stepModalBody,
                    runStatusTypeConfirmBody1: stepModalBody1,
                    runStatusTypeTitle: stepTitle,
                    okButtonText: 'Disburse'
                   });
            break;
            default:
                break;
        }
        console.log("currentstep and statusupdate : ", this.state.nextStepState, this.state.statusUpdate);
    }
    handleUndoActions(runID,nextIndex, preRunId) {
        this.setState({ displayUndoRunStatusModal: !this.state.displayUndoRunStatusModal });
        this.props.qualRunExecussion(runID, this.state.statusUpdate[nextIndex].undoServiceName, preRunId);
    }
    handleNotify(runID, nextIndex){	
        console.log("called handle Notify: ",runID,nextIndex)	
    }
    truncateString(runName, index) {
        return runName.length > index ? runName.substring(0, index-1) + "..." : runName;
    }
    render() {
        const {
            dropdownEnable,
            statusUpdate,
            nextStepState,
            currentRunID,
            qualProgress,
            certifyProgress,
            disburseProgress,
            makeLiveProgress,
            notifyProgress,
            currentStep,
            updateRunStatus,
            enableUndoAction,
            displayRunStatusModel,
            runStatusType,
            runStatusTypeConfirmBody,
            runStatusTypeTitle,
            runStatusTypeConfirmBody1,
            displayCPModel,
            runQualDisabled,
            displayUndoRunStatusModal,
            showMinmizeUI,
            minimiseQualProgress,
            isdisplayPreviousRuns,
            nextIndexValue,
            selectedStep,
            autoExecuteCalc,
            okButtonText,
            undoStepExecution,
            minimizeQualProgress,
            countText,
            steps,
            isDisableRunStep
          } = this.state;
          const { commissionRunDetails, commissionRunId, analysisPeriod, previousRuns, runName } = this.props;
        let undotitle = nextStepState !== null && statusUpdate[nextStepState - 1]?.undoActionName !== undefined ? statusUpdate[nextStepState - 1]?.undoActionName : '';
        return (
            <>
                <div className='progress-col-12'>
                    <div className='row col-10'>
                        <span className='run_status_styles'>{Constants.RUN_DETAILS_RUN_STATUS}</span><span className='run_status_details'>{updateRunStatus}</span>
                    </div>
                    <div className='undo-action-col-2 undo_rundetails_alignitems-center'>
                       {/* {enableUndoAction?<span className='undo-action-title' onClick={() =>  this.handleUndoActions(commissionRunId, nextStepState - 1)}>{nextStepState !== null && statusUpdate[nextStepState - 1]?.undoActionName !== undefined ? statusUpdate[nextStepState - 1]?.undoActionName : ''}</span>:''} */}
                    </div>
                </div>
                <div className="progress-col-12">
                    <div className=" col-10 row progress-container">
                        <div className="row progress-qual-root">
                            <div className='progress-item'>
                                <div>
                                {qualProgress>0? <img className="progress-dot" src={greenTick} alt='alt' />:<img className="progress-dot" src={progressdot} alt='alt' />}
                                </div>
                                <div className='col8 display-progress'>
                                    <ProgressBar percent={100} now={qualProgress} variant="success" />
                                </div>
                            </div>
                            <div>
                                <span className='progress-label'>Qualification</span>
                            </div>
                        </div>
                        <div className="row progress-calq-root">
                            <div className='progress-item'>
                                <div>
                                {certifyProgress>0? <img className="progress-dot" src={greenTick} alt='alt' />:<img className="progress-dot" src={progressdot} alt='alt' />}
                                </div>
                                <div className='display-progress'>
                                    <div>
                                       <div><ProgressBar percent={100} now={certifyProgress} variant="success" /></div>    
                                    </div>

                                </div>
                            </div>
                            <div>
                                <span className='progress-label'>Calculation</span>
                            </div>
                        </div>
                        <div className="row progress-disburse-root">
                            <div className='progress-item'>
                                <div>
                                {disburseProgress>0? <img className="progress-dot" src={greenTick} alt='alt' />:<img className="progress-dot" src={progressdot} alt='alt' />}
                                </div>
                                <div className='display-progress'>
                                    <ProgressBar percent={100} now={disburseProgress} variant="success" />
                                </div>
                            </div>
                            <div>
                                <span className='progress-label'>Disburse</span>
                            </div>
                        </div>
                         <div className="row progress-makelive-root">
                            <div className='progress-item'>
                                <div>
                                {disburseProgress>0? <img className="progress-dot" src={greenTick} alt='alt' />:<img className="progress-dot" src={progressdot} alt='alt' />}
                                </div>
                                {/* <div className='display-progress'>
                                    <ProgressBar percent={100} now={makeLiveProgress} variant="success" />
                                </div> */}
                            </div>
                            <div>
                                <span className='progress-label'>Complete</span>
                            </div>
                        </div>
                       {/* <div className="row progress-margear-root">
                            <div className='progress-item'>
                                <div>
                                    <img className="progress-dot" src={progressdot} alt='alt' />
                                </div>
                                <div className='progress-merge-display display-progress'>
                                    <ProgressBar percent={100} now={notifyProgress} variant="success" />
                                </div>
                                <div className='notify-block'>
                                    <div><span className='notify-display'>Notify</span></div>
                                    <div className="vl"></div>
                                </div>
                            </div>
                            <div>
                                <span className='progress-label'>Marge AR</span>
                            </div>
                        </div> */}
                    </div>
                    <div className="progress-col-2 rundetails_alignitems-center">
                        <Dropdown as={ButtonGroup} disabled={isDisableRunStep}>
                            <Button disabled={runQualDisabled} className={runQualDisabled ? 'runQualdisabled' : (statusUpdate[nextStepState]?.dropdownOptionNames.length>0) ? 'rundetails_button_title' : 'rundetails_only_button_title'} onClick={() =>nextStepState!==3? this.qualRunHandler(commissionRunId, nextStepState):this.handleNotify(commissionRunId, nextStepState)} variant="success">
                                <span className="run_qual_title">{statusUpdate[nextStepState]?.name !== undefined ? statusUpdate[nextStepState]?.name : Constants.RUN_DETAILS_RUN_QUAL}</span>
                            </Button>

                            {dropdownEnable && statusUpdate[nextStepState]?.dropdownOptionNames?.length>0? <>
                                <Dropdown.Toggle className='rundetails_dropdown_button' split variant="success" id="dropdown-split-basic" />
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => this.dropdownRunHandler(commissionRunId, nextStepState,"1")}>
                                        <span className="dropdown_run_qual_title">{statusUpdate[nextStepState]?.dropdownOptionNames !== undefined ? statusUpdate[nextStepState]?.dropdownOptionNames[0]?.name : Constants.BUTTON_TEXT_RUN_QUAL }</span>
                                        </Dropdown.Item>
                                        { statusUpdate[nextStepState]?.dropdownOptionNames?.length===2 && statusUpdate[nextStepState]?.dropdownOptionNames[1] ?
                                    <Dropdown.Item onClick={() => this.dropdownRunHandler(commissionRunId, nextStepState,"2")}>
                                        <span className="dropdown_run_qual_title">{statusUpdate[nextStepState]?.dropdownOptionNames !== undefined && statusUpdate[nextStepState]?.dropdownOptionNames[1] ? statusUpdate[nextStepState]?.dropdownOptionNames[1]?.name : Constants.BUTTON_TEXT_RUN_QUAL_CALC}</span>
                                        </Dropdown.Item>:''
                                    }
                                </Dropdown.Menu> </> : ''}
                        </Dropdown>
                    </div>
                    {displayRunStatusModel ? <ConfirmModel
                        title={runStatusTypeTitle}
                        bodyModelText={runStatusTypeConfirmBody}
                        bodyModelText1={runStatusTypeConfirmBody1}
                        enableModel={displayRunStatusModel}
                        okButtonTitle={okButtonText}
                        closeSelectedRun={this.closeSelectedRunProcessing(runStatusType)}
                        selectRunStatus={this.disaplySelectedRunProcessing(runStatusType)}
                    /> : ''}
                    {/* {displayUndoRunStatusModal ? <UndoConfirmModal
                        title={undotitle !== '' ? undotitle : 'Confirm'}
                        bodyModelText={runStatusTypeConfirmBody}
                        bodyModelText1={runStatusTypeConfirmBody1}
                        enableModel={displayUndoRunStatusModal}
                        closeSelectedRun={this.closeSelectedUndoRunModal.bind(this, runStatusType)}
                        selectRunStatus={this.closeSelectedUndoRunModal.bind(this, runStatusType)}
                    /> : ''} */}
                    {displayCPModel ? <CommissionProcess
                        title={runStatusTypeTitle}
                        enablecpmodel={displayCPModel}
                        closeModel={this.closeCommisisonProcessModal.bind(this)}
                        stopcommissionprocess={this.stopCommissionProcessing.bind(this)}
                        continuecomprocess={this.continueCommissionProcessing.bind(this)}
                        selectedCurrentStep={selectedStep}
                        autoExecuteCalc={autoExecuteCalc}
                        undoStepExecution={undoStepExecution}
                        controlRunDetails ={()=> this.props.controlRunDetails() }
                    /> : ''}
                    {isdisplayPreviousRuns ? (
                        <PreviousRunsQualModal
                            addCompareBackfunc={this.handleAddPreviousRunsModal.bind(this)}
                            commissionPeriod={analysisPeriod}
                            selectedRun={this.selectedPreviousRun.bind(this)}
                            previousRunsArray={previousRuns}
                        />
                    ) : (
                        ""
                    )}
                </div>
                {showMinmizeUI ?
                    <div className="row fixed-bottom">
                        <div className="col-10">&nbsp;</div>
                        <div className='col-2 cm-minmize-style'>
                            <div className="cm-minmize-ui">
                                <div className="row" style={{marginLeft:'10px'}}>
                                    <div className="col-7 cm-minmise-uititle-start" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                        {this.truncateString(runName, 15)}
                                        {/* {runName} */}
                                    </div>
                                    <div className="col-4 cm-minmise-uititle" onClick={this.continueCommissionProcessing.bind(this)} style={{ paddingLeft: '0px' }}>
                                    <img className="" src={externalLink} alt='alt' />
                                    </div>
                                    <div className="row cpm-min-text" >
                                        <div className="col-12 cpm-min-text">
                                            Processing : {this.truncateString(updateRunStatus, 15)}
                                        </div>
                                    </div>
                                    <div className="row" style={{ paddingLeft: '0px', marginTop: '5px' }}>
                                        <div className='display-progress'>
                                            <ProgressBar className='cpmin-progress' percent={100} now={minimizeQualProgress} variant="success" />
                                        </div>
                                        <div className="mincp-percent">
                                            {minimizeQualProgress}%
                                        </div>
                                        <div className='col-3'></div>
                                        <Tooltip title= {countText} placement="bottom" arrow={true}>
                                        <div className='col-9 records-count-text'>
                                        {countText}
                                        </div>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : ''}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        runQualResponse: state.commissionRunDetailsReducer.runQualResponse,
        runStatusResponse: state.commissionRunDetailsReducer.runStatusResponse,
        runStatusError: state.commissionRunDetailsReducer.runStatusError,
        runQualError: state.commissionRunDetailsReducer.runQualError,
        commissionRunDetails: state.commissionRunReducer.commissionRunDetails,
        commissionRunDetailsError : state.commissionRunReducer.commissionRunDetailsError,
        runQualStopResposne: state.commissionRunDetailsReducer.runQualStopResposne,
        runQualStopError: state.commissionRunDetailsReducer.runQualStopError,
        runQualResetResposne: state.commissionRunDetailsReducer.runQualResetResposne,
        runQualResetError: state.commissionRunDetailsReducer.runQualResetError,
        selectedRunPrgmResponse: state.commissionRunDetailsReducer.selectedRunPrgmResponse,
        selecteRunPrgmError: state.commissionRunDetailsReducer.selecteRunPrgmError
    }
}

const mapDispatchToProps = (dispatch) => ({
    qualRunExecussion: (runID, serviceName, cacheKey) => dispatch(qualRunExecussionAPICall(runID, serviceName, cacheKey)),
    qualRunPrerunExecussion : (runID, serviceName, previousRunId, cacheKey) => dispatch(previousQualRunExecussionAPICall(runID, serviceName, previousRunId, cacheKey)),
    getRunStatus: (runID) => dispatch(getRunStatusAPICall(runID)),
    qualRunStop: (runID) => dispatch(qualRunStopAPICall(runID)),
    qualRunReset: (runID) => dispatch(qualRunResetAPICall(runID)),
    getCurrentRunPrograms:(runID) => dispatch(getCurrentRunProgramsAPI(runID)),
    showSnackbar: (params) => dispatch({ type: actions.SNACKBAR_SHOW, payload: params}),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AnalysisProgressLine))

