
import React, { Fragment } from "react";
import AuthService from "../../../../src/components/server/AuthService";
import { connect } from "react-redux";
import { Oval } from "react-loader-spinner";
import { withRouter } from "react-router-dom";
import { getDistributorHistory } from "../../../components/Commissions/actions";
import actions from "../../../redux/actions/CommonDataActions";
import * as Constants from '../../../constants/Constants';
import { errorHandling } from '../../utilities/Services';
import NewSnackbar from "../../utilities/NewSnackbar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import './GeneralTab.css';
import './historyTab.css';
import { allNullTypeCheck, isNullResponse } from "../commonComponents/UtilityFun";
import nodatafound from "../assets/nodatafound.svg";
class HistoryTab extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showLoaderResult: false,
            historyInfo: [],
            historyPeriods: [],
            historyWaitTime: 1
        }
    }
    componentDidMount() {
        const { distID, selectedRunID } = this.props;
        // console.log("History tab input props ", distID, selectedRunID);
        if (allNullTypeCheck(distID) && allNullTypeCheck(selectedRunID)) {
            this.setState({ showLoaderResult: true });
            this.props.getDistHistoryDetails(selectedRunID, distID);
        }
    }
    componentDidUpdate(nextProps) {
        const {
            distHistoryData,
            distHistoryError,
            distID, selectedRunID
        } = this.props;
        const { historyWaitTime } = this.state;
        if (distHistoryData !== nextProps.distHistoryData) {
            this.setState({ showLoaderResult: false });
            if (!distHistoryData.hasOwnProperty('status')) {
                if (isNullResponse(distHistoryData.data)) {
                    if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Get commissionRunDistInfo ", distHistoryData) }
                    if (distHistoryData.error === false) {
                        this.setState({ historyInfo: distHistoryData.data.history, historyPeriods: distHistoryData.data.periods });
                        // console.log('distHistoryData Resposne', distHistoryData.data.history)
                    }
                    else {
                        this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.error, snackbarMessage: distHistoryData.messages[0] })
                    }
                } else if (Object.keys(distHistoryData?.data).length === 0 && allNullTypeCheck(distHistoryData?.messages)) {
                    if (distHistoryData?.messages?.length > 0 && distHistoryData?.messages[0]?.includes("Try again...")) {
                        console.log("Try again block entered : ", distHistoryData?.data);
                        this.setState({ showLoaderResult: true });
                        setTimeout(() => {
                            this.props.getDistHistoryDetails(selectedRunID, distID);
                        }, 1000 * historyWaitTime);
                        this.setState(prevState => {
                            return { historyWaitTime: prevState.historyWaitTime * 2 }
                        })
                    }
                }
                else {
                    this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.error, snackbarMessage: distHistoryData.messages[0] })
                }
            } else {
                if (distHistoryData.hasOwnProperty('status') && distHistoryData.status === 202) {
                    this.setState({ showLoaderResult: true });
                    setTimeout(() => {
                        this.props.getDistHistoryDetails(selectedRunID, distID);
                    }, 1000 * historyWaitTime);
                    this.setState(prevState => {
                        return { historyWaitTime: prevState.historyWaitTime * 2 }
                    })
                }
            }
        } else if ((distHistoryError !== nextProps.distHistoryError)) {
            this.setState({ showLoaderResult: false });
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("distHistoryError ", distHistoryError) }
            errorHandling(distHistoryError);
        }
    }
    getPeriodValue(historyField, period) {
        return historyField.periods.find(p => p.name == period);
    }
    render() {
        if (AuthService.isAuthenticated()) {
            let loader;
            const { showLoaderResult, historyInfo, historyPeriods } = this.state;
            if (showLoaderResult) {
                loader = <div id="center" style={{ position: "fixed", bottom: "40%", left: "48%" }}>
                    <Oval
                        type="Puff"
                        position="fixed" secondaryColor="#E85557"
                        color="#E85557"
                        height={50}
                        width={50}
                        timeout={3000}
                    />
                </div>
            }
            return (
                <div className="distres-generaltable">{historyInfo.length > 0 ? <Fragment>
                    <div className="col-3 distres-general-tbcontainer distres-tabs-scroll">
                        <TableContainer>
                            {(historyInfo.length > 0 && historyPeriods.length > 0) ? <Table sx={{ minWidth: 650 }} aria-label="simple table" className="general-table-left">
                                <TableHead className="distres-generaltab-header">
                                    <TableCell
                                        className="runmanager-table-header"
                                        component="th"
                                        scope="row"
                                        colSpan={4}
                                    >
                                        {"Data Field"}
                                    </TableCell>
                                </TableHead>
                                <TableBody>
                                    {(historyInfo.length > 0 && historyPeriods.length > 0)
                                        ? historyInfo.map(
                                            (historydata) => (
                                                <TableRow>
                                                    <TableCell className="data-text-style">{historydata?.label}</TableCell>
                                                </TableRow>
                                            )) : ''}
                                </TableBody>
                            </Table> : ''}
                        </TableContainer>

                    </div>
                    <div className="col-9 distres-history-rightcontainer">
                        <TableContainer>
                            {(historyInfo.length > 0 && historyPeriods.length > 0) ? <Table sx={{ minWidth: 650 }} aria-label="simple table" className="general-table-right">
                                <TableHead className="distres-generaltab-header">
                                    {Object.values(historyPeriods).map(
                                        (historyPeriod) => (
                                            <TableCell
                                                className="distres-history-table-header1"
                                                component="th"
                                                scope="row"
                                                colSpan={1}

                                            >
                                                {historyPeriod}
                                            </TableCell>
                                        ))}
                                </TableHead>
                                <TableBody className="distres-general-tbody">
                                    {historyInfo.map(
                                        (history, i) => (
                                            <TableRow>
                                                {historyPeriods.map(
                                                    (historyPeriod, i) => (
                                                        <TableCell className="data-text-style" align={'right'}>{this.getPeriodValue(history, historyPeriod)?.value?.toFixed(2)}</TableCell>
                                                    ))}
                                            </TableRow>
                                        ))}
                                </TableBody>

                            </Table> : 'test'}
                        </TableContainer>

                    </div>
                </Fragment> : <TableContainer>
                    <Table>
                        <TableRow className="no-data-image header-line">
                            <TableCell colSpan={9} align='center' className="no-data-image">
                                <img src={nodatafound} alt="" />
                            </TableCell>
                        </TableRow>
                    </Table>
                </TableContainer>}
                    {loader}
                    <NewSnackbar />
                </div>);

        }
        else {
            return <div id="center" style={{ position: "fixed", bottom: "40%", left: "48%" }}>
                <Oval
                    type="Puff"
                    position="fixed" secondaryColor="#E85557"
                    color="#E85557"
                    height={50}
                    width={50}
                    timeout={3000}
                />
            </div>
        }
    }
}
const mapStateToProps = (state) => {
    return {
        distHistoryData: state.commissionDistResearch.distHistoryData,
        distHistoryError: state.commissionDistResearch.distHistoryError,

    };
};
const mapDispatchToProps = (dispatch) => ({
    getDistHistoryDetails: (runID, distID) => dispatch(getDistributorHistory(runID, distID)),
    showSnackbar: (params) => dispatch({ type: actions.SNACKBAR_SHOW, payload: params }),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(HistoryTab));