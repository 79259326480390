export const CURRENCIES_FETCHING = 'CURRENCIES_FETCHING';
export const CURRENCIES_SUCCESS = 'CURRENCIES_SUCCESS';
export const CURRENCIES_ERROR = 'CURRENCIES_ERROR';
export const CURRENCIES_ADD_SUCCESS = 'CURRENCIES_ADD_SUCCESS';
export const CURRENCIES_ADD_ERROR = 'CURRENCIES_ADD_ERROR';
export const CURRENCIES_DEL_SUCCESS = 'CURRENCIES_DEL_SUCCESS';
export const CURRENCIES_DEL_ERROR = 'CURRENCIES_DEL_ERROR';
  const initialState = {
    addCurrencySuccess:[],
    data: [],
    error: {},
    fetching: false,
    success: false,
    addCurrencyError:{},
    getCurrencys: []
  }
  
  export default function currenciesReducer (state, action) {
    if (typeof state === 'undefined') {
      return initialState
    }
    switch (action.type) {
      case CURRENCIES_FETCHING:
        return { ...state, fetching: true }
      case CURRENCIES_SUCCESS:
        return {
          ...state,
          fetching: false,
          success: true,
          getCurrencys: action.payload
        }
      case CURRENCIES_ERROR:
        return {
          ...state,
          fetching: false,
          success: false,
          data: action.payload
        }
      case CURRENCIES_ADD_SUCCESS:
        return { ...state, fetching: false, success:true, addCurrencySuccess: action.payload }
      case CURRENCIES_ADD_ERROR:
        return {
          ...state,
          fetching: false,
          success: false,
          data: action.payload
        }
      case CURRENCIES_DEL_SUCCESS:
        return {
        ...state,
        fetching: false,
        success: true,
        data: action.payload
      } 
      case CURRENCIES_DEL_ERROR:
        return {
          ...state,
          fetching: false,
          success: false,
          data: action.payload
        }
  
      default:
        return state
    }
  }
  