import React from "react";
import AuthService from '../../server/AuthService';
import { Oval } from "react-loader-spinner";
import { withRouter } from 'react-router-dom';
import Component2 from "../Component2";
import './CommToolDistResearch.css'
import DistInfo from './DistInfo'
import { connect } from "react-redux";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { GeneralTab } from "./GeneralTab";
import  VolumeTab  from './VolumeTab';
import { getDistributorRankInfoAPI, DIST_RESEARCH_SELECTEDTAB, BROWSER_BACK_BUTTON_HANDLETAB } from "../../../components/Commissions/actions";
import  HistoryTab from "./HistoryTab";
import FlagsTab from "./FlagsTab";
import EarningsTab from "./EarningsTab";
import { WorkFieldTab } from "./WorkFieldTab";
import DateMiscTab from "./DateMiscTab";
import AmountFieldsTab from "./AmountFieldsTab";
import CountryInfotab from "./CountryInfotab";
import PaidToTab from "./PaidToTab";
import DownlineTab from "./DownlineTab";
import UplineTab from "./UplineTab";
import actions from '../../../redux/actions/CommonDataActions';
import { allNullTypeCheck, getCleintNameEnvironment, isNullResponse } from "../commonComponents/UtilityFun";
import * as Constant from '../../../constants/Constants';
import NewSnackbar from "../../utilities/NewSnackbar";
import GeneralTabRankMatrix from "./GeneralTabRankMatrix";
import CustomRankMatrix from "./CustomRankMatrix"

class CommToolDistResearch extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
			tabSelected: 0,
      selectedOptions:'',
      distributorRunInfoState:[],
      isLoader:false,
      enableMatrix:false,
      currentRankID : '',
      rankName:'',
      rankLabel:'',
      custRankLabel:'',
      currentCustRankID:'',
      enableCustomRankMatrix:false,
      retrydelayTime:1,
      distIDState:'', 
      periodtypeState:'',
      periodDateState:'',
      selectedRunState:''
    }
    this.envName = getCleintNameEnvironment();
  }
   
  componentDidMount() {
    let resObj = this.getValuesFromProps();
    // const {distID, selectedRun } = this.state;
    this.setState({distIDState:resObj.distID, selectedRunState:resObj.selectedRun, periodDateState: resObj.periodDate, periodtypeState:resObj.periodtype })
    if (resObj.distID !== null && resObj.distID !== undefined && resObj.distID !== '') {
      this.setState({isLoader:true });
      this.props.getDistributorRunInfo(resObj.distID,resObj.selectedRun);
      this.props.distResearchTab(0);
      this.props.updateTabValue(1);
    }

    // window.onreload = function () { console.log("refresh triggered : "); this.props.updateTabValue(1); }.bind(this);
    window.addEventListener("beforeunload", this.onUnload)
  }

  getValuesFromProps=()=>{
    let distIDVal, periodtypeVal, periodDateVal, selectedRunVal; 
    if(this.props?.location?.state){
      distIDVal =  this.props?.location?.state?.distID;
      selectedRunVal = this.props?.location?.state?.selectedRun;
      periodDateVal = this.props?.location?.state?.periodDate;
      periodtypeVal =  this.props?.location?.state?.periodtype;
    }else {
      distIDVal =  JSON.parse(localStorage.getItem(this.envName+'-distID'));
      selectedRunVal = JSON.parse(localStorage.getItem(this.envName+'-selectedRun'));
      periodDateVal = JSON.parse(localStorage.getItem(this.envName+'-periodDate'));
      periodtypeVal =  JSON.parse(localStorage.getItem(this.envName+'-periodtype'));
    }
    return {distID: distIDVal, selectedRun: selectedRunVal, periodDate: periodDateVal, periodtype: periodtypeVal}
  }

  componentWillUnmount() {
     window.removeEventListener("beforeunload", this.onUnload)
  }

  enableRankMatrix=(rankId, ranklabel,rankname)=>{
    console.log("selected id :",rankId);
    this.setState({ currentRankID : rankId, rankLabel : ranklabel, rankName : rankname});
    this.setState({enableMatrix:true})
  }
  closefunction(){
    this.setState({ enableMatrix:false, enableCustomRankMatrix: false });
  }
  enableCustomRankMatrix=(rankId, ranklabel)=>{
    console.log("selected id :",rankId);
    this.setState({ currentCustRankID : rankId, custRankLabel : ranklabel});
    this.setState({enableCustomRankMatrix:true})
  }
  onUnload=()=>{
    console.log("refresh triggered : ");
    this.props.updateTabValue(1);
  }
  componentWillReceiveProps(nextProps){
    console.log("triggered : ", nextProps.distResearchSelection);
  }
  componentDidUpdate(nextProps){
    const { distResearchSelection, commissionRunDetails, distRankInfoList, commissionRunDetailsError, distRankInfoError} = this.props;
    const { retrydelayTime, distIDState, selectedRunState } = this.state;
    console.log("result : ",distResearchSelection);
    if (commissionRunDetails !== nextProps.commissionRunDetails){
      this.setState({isLoader:false});
      if(isNullResponse(commissionRunDetails.data)) {
        this.setState({ commissionRunDetailsData: commissionRunDetails.data });
        console.log("Commission run details :", commissionRunDetails.data);
      }
    }
    if(distRankInfoList!==nextProps.distRankInfoList){
      this.setState({isLoader:false });
      if(!distRankInfoList.hasOwnProperty('status')){ 
        if (isNullResponse(distRankInfoList?.data) && distRankInfoList?.error===false){
          console.log("got the Response: ",distRankInfoList);
          this.setState({isLoader:false });
          this.setState({ distributorRunInfoState: distRankInfoList.data});
        }else {
          if(allNullTypeCheck(distRankInfoList?.data) && Object.keys(distRankInfoList?.data).length===0 && allNullTypeCheck(distRankInfoList?.messages)) {
            if(distRankInfoList?.messages?.length > 0 && distRankInfoList?.messages[0]?.includes("Try again")){
              console.log("Try again block entered : ",distRankInfoList?.data);
              setTimeout(()=>{
                this.props.getDistributorRunInfo(distIDState,selectedRunState);
              },retrydelayTime*1000)
              this.setState(prevState => {
                return {retrydelayTime: prevState.retrydelayTime * 2}
            })

            }
          }
        }
      }else {
        if(distRankInfoList.hasOwnProperty('status') && distRankInfoList.status===202){
          setTimeout(()=>{
            this.props.getDistributorRunInfo(distIDState,selectedRunState);
          },retrydelayTime*1000)
          this.setState(prevState => {
            return {retrydelayTime: prevState.retrydelayTime * 2}
         })
       }
      }
   } else if(distRankInfoError !== nextProps.distRankInfoError){
      console.log("distRankInfoError :", distRankInfoError.data);
      this.setState({isLoader:false });
      if(isNullResponse(distRankInfoError.data)){
        this.props.showSnackbar({snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: 'RankInfo: '+ distRankInfoError.data?.messages[0]?.detail})
      }
    }
  }
  handleChange = async (event, newValue) => {
		console.log("tab select value", newValue);
    let resObj = this.getValuesFromProps();
    
    let selectedPath='';
    this.props.distResearchTab(newValue);
		this.setState({ tabSelected: newValue });
    switch (newValue) {
      case 0:
        selectedPath= "/commissiontools/distributorresearch/1";
        break;
      case 1:
        selectedPath= "/commissiontools/distributorresearch/2";
        break;
      case 2:
        selectedPath= "/commissiontools/distributorresearch/3";
        break;
      case 3:
        selectedPath= "/commissiontools/distributorresearch/4";
        break;
      case 4:
        selectedPath= "/commissiontools/distributorresearch/5";
        break;
      case 5:
        selectedPath= "/commissiontools/distributorresearch/6";
        break;
      case 6:
        selectedPath= "/commissiontools/distributorresearch/7";
        break;
      case 7:
        selectedPath= "/commissiontools/distributorresearch/8";
        break;
      case 8:
        selectedPath= "/commissiontools/distributorresearch/9";
        break;
      case 9:
        selectedPath= "/commissiontools/distributorresearch/10";
        break;
      case 10:
        selectedPath= "/commissiontools/distributorresearch/11";
        break;
      case 11:
        selectedPath= "/commissiontools/distributorresearch/12";
        break;
      default: this.setState({ isRunManagerEnable: true, isToolsEnable: false, isReportsEnable: false, isSettingsnable: false })
        break;
    }
    this.props.history.push({
      pathname: selectedPath,
      state: {
        distID: resObj.distID,
        periodtype:resObj.periodtype,
        periodDate: resObj.periodDate,
        selectedRun: resObj.selectedRun
      },
    });
	};
	
  tabledataHandling=()=>{
    const { distributorRunInfoState } = this.state;
    let resObj = this.getValuesFromProps();
    const { distResearchSelectedTab } = this.props;

    console.log("tab selected value : ",distResearchSelectedTab, resObj);
    switch (distResearchSelectedTab) {
      case 0:
         return <GeneralTab enableRankMatrix={this.enableRankMatrix} distRankInfo={distributorRunInfoState} distID={resObj.distID} selectedRunID={resObj.selectedRun}/>
      case 1:
        return <VolumeTab distRankInfo={distributorRunInfoState} distID={resObj.distID} selectedRunID={resObj.selectedRun}/>
      case 2:
        return <EarningsTab distID={resObj.distID} selectedRunID={resObj.selectedRun}/>
      case 3:
        return <WorkFieldTab distID={resObj.distID} selectedRunID={resObj.selectedRun}/>
      case 4:
        return <DownlineTab distID={resObj.distID} selectedRunID={resObj.selectedRun} periodDate={resObj.periodDate} periodtype={resObj.periodtype}/>
      case 5:
        return <UplineTab distID={resObj.distID} selectedRunID={resObj.selectedRun} periodDate={resObj.periodDate} periodtype={resObj.periodtype}/>
      case 6:
        return <FlagsTab distRankInfo={distributorRunInfoState} distID={resObj.distID} selectedRunID={resObj.selectedRun}/>
      case 7:
        return <CountryInfotab distID={resObj.distID} selectedRunID={resObj.selectedRun}/>
      case 8:
        return <HistoryTab distID={resObj.distID} selectedRunID={resObj.selectedRun}/>
      case 9:
        return <PaidToTab distID={resObj.distID} selectedRunID={resObj.selectedRun} periodDate={resObj.periodDate} periodtype={resObj.periodtype}/>
      case 10:
        return <AmountFieldsTab distID={resObj.distID} selectedRunID={resObj.selectedRun}/>
      case 11:
        return <DateMiscTab distID={resObj.distID} enableRankMatrix={this.enableRankMatrix} selectedRunID={resObj.selectedRun}/>
      default:
          return this.state;
    }
  }


  render() {
    const { tabSelected, isLoader, enableMatrix, currentRankID, rankName, rankLabel, 
      enableCustomRankMatrix, currentCustRankID, custRankLabel }  = this.state;
    let resObj = this.getValuesFromProps();
    const { distResearchSelectedTab } = this.props;

    if (AuthService.isAuthenticated() && isLoader) {
      return <div><div id="center" style={{ position: 'fixed', top: '40%', left: '48%' }}>
      <Oval type="Puff" position="fixed"  secondaryColor="#E85557" color="#E85557" height={50} width={100} visible={false} /> </div></div>;
    }else if(enableMatrix) {
      return (<GeneralTabRankMatrix
        closefunction={()=>this.closefunction()}
        distributorID ={resObj.distID}
        runID = {resObj.selectedRun}
        currentRankID = {currentRankID}
        rankLabel = {rankLabel}
        rankName = {rankName}
        />
      );
    }
    //  else if(enableCustomRankMatrix){
    //   return (<CustomRankMatrix 
    //     closefunction={()=>this.closefunction()}
    //     distributorID ={distID}
    //     runID = {selectedRun}
    //     currentRankID = {currentCustRankID}
    //     rankLabel = {custRankLabel}
    //   />)
    // }
     else{
      return (
        <div className="row distres-com-overlap-group-1" style={{marginLeft: '0px'}}>
          <div className="col-12" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <Component2 mainText='Commissions Tools' typeText={"DistResearch"} />
            {/* <div className="rectangle-255"></div> */}
            <div className="flex">
              <div className="col-8 cmt-title">Distributor Research</div>
              <div className="row col-12 distres-margin-padding-set">
                <div className="col-2 distres-left-panel">
                <DistInfo runid={resObj.selectedRun} distid={resObj.distID}/>
                </div>
                <div className="col-10 distres-right-panel">
                  <div className="runs-display">
                  <div className="col-12 distres-h1title">
											<Tabs
												className="distres-tabs"
												value={distResearchSelectedTab}
												indicatorColor="primary"
												select
												textColor="primary"
												onChange={this.handleChange}
												aria-label="disabled tabs example"
											>
												<Tab className="distres-tabs-width" label="General" />
												<Tab className="distres-tabs-width" label="Vol. Fields" />
												<Tab className="distres-tabs-width"label="Earnings" />
												<Tab className="distres-tabs-width" label="Work Fields" />
												<Tab className="distres-tabs-width" label="Downline" />
                        <Tab className="distres-tabs-width" label="Upline" />
												<Tab className="distres-tabs-width" label="Flags" />
												<Tab className="distres-tabs-width" label="Cou. Info." />
												<Tab className="distres-tabs-width" label="History" />
												<Tab className="distres-tabs-width" label="Paid To" />
                        <Tab className="distres-tabs-width" label="Amt Fields" />
												<Tab className="distres-tabs-width" label="Dates/Misc" />
											</Tabs>
										</div>
                    {this.tabledataHandling()}
                 </div>
                </div>
              </div>
            </div>
          </div>
          <NewSnackbar/>
        </div>
      );
      }
    }
}


const mapStateToProps = state => {
  return {
      commissionRunList: state.commissionRunReducer.commissionRunList,
      distResearchSelection: state.commissionToolsReducer.distResearchSelection, 
      commissionRunDetails: state.commissionRunReducer.commissionRunDetails,
      commissionRunDetailsError : state.commissionRunReducer.commissionRunDetailsError,
      distRankInfoList: state.distributorResearchReducer.distRankInfoList,
      distRankInfoError: state.distributorResearchReducer.distRankInfoError,
      distResearchSelectedTab: state.distributorResearchReducer.distResearchSelectedTab,
  }
};
const mapDispatchToProps = dispatch => ({
  getDistributorRunInfo: (distID,runID) => dispatch(getDistributorRankInfoAPI(distID,runID)),
  distResearchTab: (selectedTab) => dispatch({type:DIST_RESEARCH_SELECTEDTAB, payload: selectedTab}),
  updateTabValue:(value) => dispatch({ type: BROWSER_BACK_BUTTON_HANDLETAB, payload: value }),
  showSnackbar: (params) => dispatch({ type: actions.SNACKBAR_SHOW, payload: params}),
})
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(CommToolDistResearch));