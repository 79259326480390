
export const user = {
  USERLIST_SUCCESS : 'USERLIST_SUCCESS',  
  USERLIST_HASERROR : 'USERLIST_HASERROR', 
  COLLABORATORSLIST_SUCCESS : 'COLLABORATORSLIST_SUCCESS',  
  COLLABORATORSLIST_HASERROR : 'COLLABORATORSLIST_HASERROR',
  GET_COLLABORATORS_SUCCESS : 'GET_COLLABORATORS_SUCCESS',  
  GET_COLLABORATORS_HASERROR : 'GET_COLLABORATORS_HASERROR',
  REMOVE_COLLABORATORS_SUCCESS : 'REMOVE_COLLABORATORS_SUCCESS',  
  REMOVE_COLLABORATORS_HASERROR : 'REMOVE_COLLABORATORS_HASERROR',
  POST_COLLABORATORS_SUCCESS : 'POST_COLLABORATORS_SUCCESS',  
  POST_COLLABORATORS_HASERROR : 'POST_COLLABORATORS_HASERROR',
  INVITEUSER_SUCCESS : 'INVITEUSER_SUCCESS',
  INVITEUSER_HASERROR : 'INVITEUSER_HASERROR',
  GETUSER_SUCCESS : 'GETUSER_SUCCESS',
  GETUSER_HASERROR : 'GETUSER_HASERROR',
  CONFIRMEMAIL_SUCCESS : 'CONFIRMEMAIL_SUCCESS',
  CONFIRMEMAIL_HASERROR : 'CONFIRMEMAIL_HASERROR',    
  EDITUSER_SUCCESS : 'EDITUSER_SUCCESS',
  EDITUSER_HASERROR :'EDITUSER_SUCCESS',
  RESENDINVITE_SUCCESS : 'RESENDINVITE_SUCCESS',
  RESENDINVITE_HASERROR : 'RESENDINVITE_HASERROR',
  RESETMAIL_SUCCESS : 'RESETEMAIL_SUCCESS',
  RESETMAIL_HASERROR : 'RESETMAIL_HASERROR',
  GETUSER_PROFILE_SUCCESS : 'GETUSER_PROFILE_SUCCESS',
  GETUSER_PROFILE_HASERROR : 'GETUSER_PROFILE_HASERROR',
  EDIT_PROFILE_SUCCESS : 'EDIT_PROFILE_SUCCESS',
  EDIT_PROFILE_HASERROR : 'EDIT_PROFILE_HASERROR',
  DELETE_USER_SUCCESS : 'DELETE_USER_SUCCESS',
  DELETE_USER_HASERROR : 'DELETE_USER_HASERROR',
  GETUSER_ROLES_SUCCESS : 'GETUSER_ROLES_SUCCESS',
  GETUSER_ROLES_HASERROR : 'GETUSER_ROLES_HASERROR',


};
  