import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Oval } from "react-loader-spinner";
import { Modal } from "react-bootstrap";
import vector13 from '../../../assets/vector-13@2x.png';
import penicon from '../assets/penselect.svg';
import '../CreateNewRun/CreateNewRun.css'
import TestNewRun from "./TestNewRun";
import { Input } from 'reactstrap';
import actions from '../../../redux/actions/CommonDataActions';
import NewSnackbar from "../../utilities/NewSnackbar";
import { createNewRun, getProgramsData } from '../actions'
import * as Constants from '../../../constants/Constants';
import Radio from '@material-ui/core/Radio';
import { allNullTypeCheck, isNullResponse } from "../commonComponents/UtilityFun";
import errorAlert from "../assets/errorAlert.svg";


class CreateNewRun extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoaderResult: false,
            showRemoveColModal: true,
            hideModal: false,
            createdRun: null,
            runType: 'Preliminary',
            commissionType: 'Primary',
            runID: '',
            runName: '',
            runDescription: '',
            showRunDetails: false,
            disableNewRun: true,
            selectedType: '',
            runDisbursement: '',
            runCalculation: '',
            runQualification: '',
            qualEnable: true,
            calculationEnable: true,
            disburseEnable: true,
            nameEnable: false,
            descriptionEnable: false,
            nameLength: false,
            descLength: false,
            programWaitTime: 1
        }
    }

    componentDidMount() {
        this.props.getProgramData()
    }
    componentDidUpdate(nextProps) {
        const { createNewRunResposne, createNewRunError, programDataResponse, programDataError } = this.props
        if (createNewRunResposne !== nextProps.createNewRunResposne) {
            this.setState({ showLoaderResult: false });
            console.log("createNewRunResposne  : ", createNewRunResposne)
            if (isNullResponse(createNewRunResposne?.data) && createNewRunResposne.error === false) {
                this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.success, snackbarMessage: allNullTypeCheck(createNewRunResposne.data?.message) ? createNewRunResposne.data?.message : 'Run Created Successfully' })
                this.setState({ showLoaderResult: false })
                if (createNewRunResposne?.data?.error !== "true") {
                    console.log("new run with run id : ", createNewRunResposne.data?.id);
                    this.setState({ runID: createNewRunResposne.data?.id, showRunDetails: true, showLoaderResult: false, hideModal: true, showRemoveColModal: false })
                }
            } else {
                this.setState({ showLoaderResult: false })
                if (createNewRunResposne?.error === true) {
                    this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.error, snackbarMessage: typeof (createNewRunResposne?.messages[0]) !== 'string' ? createNewRunResposne?.messages[0]?.code + ' - ' + createNewRunResposne?.messages[0]?.detail : createNewRunResposne?.messages[0] })
                }
            }
        }
        else if (createNewRunError !== nextProps.createNewRunError) {
            console.log("Create run error : ", createNewRunError.data);
            this.setState({ showLoaderResult: false });
            if (createNewRunError.data.error === true) {
                this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.error, snackbarMessage: typeof (createNewRunError?.data?.messages[0]) !== 'string' ? createNewRunError?.data.messages[0]?.code + ' - ' + createNewRunError?.data.messages[0]?.detail : createNewRunError?.data?.messages[0] })
            }
        }
        if (programDataResponse !== nextProps.programDataResponse) {
            this.setState({ showLoaderResult: false });
            if (!programDataResponse.hasOwnProperty('status')) {
                if (isNullResponse(programDataResponse.data)) {
                    if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("programDataResponse programs ", programDataResponse) }
                    if (programDataResponse.error === false) {
                        //  this.setState({ historyInfo: distHistoryData.data.history, historyPeriods: distHistoryData.data.periods });
                        console.log('programDataResponse', programDataResponse)
                        this.setState({
                            runQualification: programDataResponse?.data?.qualificationProgram,
                            runCalculation: programDataResponse?.data?.calculationProgram,
                            runDisbursement: programDataResponse?.data?.disbursementProgram
                        })
                    }
                    else {
                        this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.error, snackbarMessage: programDataResponse.messages[0] })
                    }
                } else if (Object.keys(programDataResponse?.data).length === 0 && allNullTypeCheck(programDataResponse?.messages)) {
                    if (programDataResponse?.messages?.length > 0 && programDataResponse?.messages[0]?.includes("Try again...")) {
                        console.log("Try again block entered : ", programDataResponse?.data);
                        this.setState({ showLoaderResult: true });
                        setTimeout(() => {
                            this.props.getProgramData();
                        }, 1000 * programWaitTime);
                        this.setState(prevState => {
                            return { programWaitTime: prevState.programWaitTime * 2 }
                        })
                    }
                }
                else {
                    this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.error, snackbarMessage: programDataResponse.messages[0] })
                }
            } else {
                if (programDataResponse.hasOwnProperty('status') && programDataResponse.status === 202) {
                    this.setState({ showLoaderResult: true });
                    setTimeout(() => {
                        this.props.getProgramData();
                    }, 1000 * programWaitTime);
                    this.setState(prevState => {
                        return { programWaitTime: prevState.programWaitTime * 2 }
                    })
                }
            }
        } else if ((programDataError !== nextProps.programDataError)) {
            this.setState({ showLoaderResult: false });
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("program primary error ", programDataError) }
            errorHandling(programDataError);
        }
    }

    hideModal() {
        this.setState({ hideModal: true, showRemoveColModal: false })
        this.props.newrunmodelbackfunc()
    }
    oneditRunName = (event) => {
        var value = event.target.value
        // console.log('run name', value, event.target)
        if (value.length > 0) {
            this.setState({ nameEnable: true })
            if (value.length > 50) {
                if (value.length <= 51) {
                    this.setState({ runName: value });
                }
                this.setState({ nameLength: true });
            } else if (value.length <= 50) {
                this.setState({ nameLength: false, runName: value });
            }
        }
        else {
            this.setState({ nameEnable: false, runName: value })
        }
        this.setState({ disableNewRun: false })
    };
    oneditRunDescription = (event) => {
        var value = event.target.value
        //  console.log('run name', value, event.target)
        if (value.length > 0) {
            this.setState({ descriptionEnable: true })
            if (value.length > 249) {
                if (value.length <= 251) {
                    this.setState({ runDescription: value })
                }
                this.setState({ descLength: true });
            } else if (value.length <= 250) {
                this.setState({ descLength: false, runDescription: value })
            }

        }
        else {
            this.setState({ descriptionEnable: false, runDescription: value })
        }
        this.setState({ disableNewRun: false })
    };
    oneditQualification = (event) => {
        var value = event.target.value
        // console.log('run name', value, event.target)
        this.setState({ runQualification: value })
    };
    oneditCalculation = (event) => {
        var value = event.target.value
        //  console.log('run name', value, event.target)
        this.setState({ runCalculation: value })
    };
    oneditDisbursement = (event) => {
        var value = event.target.value
        // console.log('run name', value, event.target)
        this.setState({ runDisbursement: value })
    };
    dispersmentPgm() {
        this.setState({ disburseEnable: false })
    }
    calculationPgm() {
        this.setState({ calculationEnable: false })
    }
    qualificationPgm() {
        this.setState({ qualEnable: false })
    }
    confirmModal() {
        const { selectedType, runName, runDescription, runDisbursement, runCalculation, runQualification } = this.state
        const { defaultvalues, defaultPeriodValue, programDataResponse } = this.props

        if (selectedType === null || selectedType === '') {
            this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.warning, snackbarMessage: 'Select any of the above Run Type' })
        }
        else if (runName === '' || runName.length === 0) {
            this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.warning, snackbarMessage: 'Run Name field should not be empty!' })
        }
        else if (runCalculation === '' || runCalculation.length === 0) {
            this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.warning, snackbarMessage: 'Calculation Program field should not be empty!' })
        }
        else if (runCalculation.length > 0 && runCalculation !== programDataResponse?.data?.calculationProgram) {
            this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.warning, snackbarMessage: `Calculation Program should be ${programDataResponse?.data?.calculationProgram}` })
        }
        else if (runQualification === '' || runQualification.length === 0) {
            this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.warning, snackbarMessage: 'Qualification Program field should not be empty!' })
        }
        else if (runQualification.length > 0 && runQualification !== programDataResponse?.data?.qualificationProgram) {
            this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.warning, snackbarMessage: `Qualification Program should be ${programDataResponse?.data?.qualificationProgram}` })
        }
        else if (runDisbursement === '' || runDisbursement.length === 0) {
            this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.warning, snackbarMessage: 'Disbursement Program field should not be empty!' })
        }
        else if (runDisbursement.length > 0 && runDisbursement !== programDataResponse?.data?.disbursementProgram) {
            this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.warning, snackbarMessage: `Disbursement Program should be ${programDataResponse?.data?.disbursementProgram}` })
        }
        else {
            let generalParams = {};
            generalParams["period"] = defaultPeriodValue //defaultvalues[0]?.defaultPeriod //defaultPeriodValue; //defaultvalues[0]?.defaultPeriod
            generalParams["periodType"] = defaultvalues[0]?.title;
            generalParams["runType"] = selectedType;
            generalParams["name"] = runName;
            generalParams["description"] = runDescription
            generalParams["qualificationProgram"] = runQualification
            generalParams["calculationProgram"] = runCalculation
            generalParams["dispursementProgram"] = runDisbursement
            this.setState({ showLoaderResult: true })
            this.props.createCommissionRun(generalParams)
        }
    }

    handleChange = e => {
        const { value } = e.target;
        console.log('selected type', value)
        this.setState({
            selectedType: value
        });
    };
    render() {
        let loader;
        const { showRemoveColModal, hideModal, showLoaderResult,
            disableNewRun, selectedType, showRunDetails, runID,
            runDisbursement, runCalculation, runQualification, qualEnable, calculationEnable, disburseEnable, descriptionEnable, nameEnable,
            nameLength, descLength
        } = this.state;
        const { disableprelimrun } = this.props
        if (showLoaderResult) {
            loader = <div id="center" style={{ position: "fixed", bottom: "40%", left: "48%" }}>
                <Oval
                    type="Puff"
                    position="fixed" secondaryColor="#E85557"
                    color="#E85557"
                    height={50}
                    width={50}
                    timeout={3000}
                />
            </div>
        }
        return (
            <div >
                <Modal dialogClassName="createerun-my-modal" show={showRemoveColModal}  >
                    <Modal.Header>
                        <div className="cmnr-flex-row-2-ei">
                            <div className="cmnr-header-name cmnr-h3-header-3-18pt-bold-lato">Create New Run</div>
                            <img className="cmnr-vector-ei" src={vector13} onClick={this.hideModal.bind(this)} alt="" />
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="col">
                            <div className="row cmnr-runtype">
                                <div className="basic-style-text1">Run Type:</div>
                            </div>
                            <div className="row cmnr-type-select">
                                <div className="col-5" style={{ paddingLeft: '0px' }}>
                                    <div className="form-check">
                                        {/* <input class="form-check-input" type="radio" id="Preliminary" value="Preliminary" checked={selectedType === 'Preliminary'} onChange={this.handleChange} style={{border: "10px solid #EFE5CE"}}/> */}
                                        <Radio
                                            value={'Preliminary'}
                                            defaultSelected={false}
                                            checked={selectedType === 'Preliminary' ? true : false}
                                            onChange={this.handleChange}
                                            disabled={disableprelimrun}
                                        />
                                        <label className="form-check-label basic-style-text2" for="preliminaryRadio" style={{ marginTop: '4px', opacity: disableprelimrun ? 0.6 : '' }}>
                                            Preliminary
                                        </label>
                                    </div>
                                </div>
                                <div className="col-5" style={{ paddingLeft: '0px' }}>
                                    <div className="form-check cmnr-form-check-input:checked">
                                        {/* <input class="form-check-input" type="radio" id="Analysis" value="Analysis"  checked={selectedType === 'Analysis'} onChange={this.handleChange} style={{backgroundColor: selectedType === 'Analysis' ? 'red' : 'white'}}/> */}
                                        <Radio
                                            value={'Analysis'}
                                            defaultSelected={false}
                                            checked={selectedType === 'Analysis' ? true : false}
                                            onChange={this.handleChange}
                                        />
                                        <label className="form-check-label basic-style-text2" for="runRadio" style={{ marginTop: '4px' }}>Analysis</label>
                                    </div>
                                </div>
                                <div className="col-2"></div>
                            </div>
                        </div>

                        <div class="row cmnr-name">
                            <div class="col">
                                <div class="mb-3">
                                    <label for="nameInput" class="form-label basic-style-text2"><strong>Name:</strong></label>
                                    <Input type="text" autoComplete="off" style={{ border: nameEnable === true ? '1px solid gray' : '', marginTop: '5px', opacity: nameEnable === true ? 1 : 0.7 }} className="form-control basic-style-text2" id="nameInput" placeholder="Give a name to the run" value={this.state.runName} name="exampleFormControlInput1" onChange={this.oneditRunName.bind(this)} />
                                    <span className="create-run-namelength">{nameLength ? <><img
                                        src={errorAlert}
                                        style={{ paddingRight: '6px' }}
                                        alt=""
                                    /> {"Max. of 50 characters allowed."}</> : ''}</span>
                                </div>
                            </div>
                        </div>

                        <div class="row cmnr-desc">
                            <div class="col">
                                <div class="mb-3">
                                    <label for="exampleFormControlTextarea1" className="form-label basic-style-text2"><strong>Description:</strong></label>
                                    <textarea style={{ border: descriptionEnable === true ? '1px solid gray' : '', marginTop: '5px', opacity: descriptionEnable === true ? 1 : 0.7 }} maxLength="250" className="form-control basic-style-text2" id="exampleFormControlTextarea1" placeholder="Add a meaningful description for the run" rows="3" value={this.state.runDescription} onChange={this.oneditRunDescription.bind(this)}></textarea>
                                    <span className="create-run-namelength">{descLength ? <><img
                                        src={errorAlert}
                                        style={{ paddingRight: '6px' }}
                                        alt=""
                                    /> {"Max. of 250 characters allowed."}</> : ''}</span>
                                </div>
                            </div>
                        </div>

                        <div class="row" style={{ marginTop: '10px' }}>
                            <div class="col-6">
                                <div className="row cmnr-h1-titles basic-style-text2">Qualification Program</div>
                            </div>
                            <div class="col-4 cmnr-qval">
                                <div class="mb-3">
                                    <Input type="text" autoComplete="off" style={{ border: qualEnable === false ? '1px solid gray' : '' }} disabled={qualEnable} className="form-control basic-style-text2" id="exampleFormControlInput1" placeholder="Qual Program" value={runQualification} onChange={this.oneditQualification.bind(this)} />
                                </div>
                            </div>
                            <div class="col-1 cmnr-col2">
                                <div class="mb-3">
                                    <img className="cmnr-penselect" style={{ cursor: 'pointer' }} src={penicon} onClick={this.qualificationPgm.bind(this)} alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="row" style={{ marginTop: '10px' }}>
                            <div class="col-6">
                                <div className="row cmnr-h1-titles basic-style-text2">Calculation Program</div>
                            </div>
                            <div class="col-4 cmnr-qval">
                                <div class="mb-3">
                                    <Input type="text" autoComplete="off" style={{ border: calculationEnable === false ? '1px solid gray' : '' }} disabled={calculationEnable} className="form-control basic-style-text2" id="exampleFormControlInput1" placeholder="Calc Program"
                                        value={runCalculation} onChange={this.oneditCalculation.bind(this)} />
                                </div>
                            </div>
                            <div class="col-1 cmnr-col2">
                                <div class="mb-3">
                                    <img className="cmnr-penselect" src={penicon} style={{ cursor: 'pointer' }} onClick={this.calculationPgm.bind(this)} alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="row" style={{ marginTop: '10px' }}>
                            <div class="col-6">
                                <div className="row cmnr-h1-titles basic-style-text2">Disbursement Program</div>
                            </div>
                            <div class="col-4 cmnr-qval">
                                <div class="mb-3">
                                    <Input type="text" autoComplete="off" style={{ border: disburseEnable === false ? '1px solid gray' : '' }} maxLength="50" disabled={disburseEnable} className="form-control basic-style-text2" id="exampleFormControlInput1" placeholder="Disb Program"
                                        value={runDisbursement} onChange={this.oneditDisbursement.bind(this)} />
                                </div>
                            </div>
                            <div class="col-1 cmnr-col2">
                                <div class="mb-3">
                                    <img className="cmnr-penselect" src={penicon} style={{ cursor: 'pointer' }} onClick={this.dispersmentPgm.bind(this)} alt="" />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="row" style={{ marginRight: '17px' }}>
                            <div disabled={disableNewRun} style={{ opacity: disableNewRun === true ? 0.3 : 1.0 }} className="cmnr-create-newrun" onClick={this.confirmModal.bind(this)}>
                                <div className="cmnr-btn-title">CREATE RUN</div>
                            </div>
                        </div>
                    </Modal.Footer>
                    {loader}
                </Modal>
                <NewSnackbar />
                {showRunDetails === true ? <TestNewRun runId={runID} /> : ''}
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        createNewRunResposne: state.commissionRunReducer.createNewRunResposne,
        createNewRunError: state.commissionRunReducer.createNewRunError,
        programDataResponse: state.commissionRunReducer.programDataResponse,
        programDataError: state.commissionRunReducer.programDataError
    }
};
const mapDispatchToProps = dispatch => ({
    createCommissionRun: (params) => dispatch(createNewRun(params)),
    showSnackbar: (params) => dispatch({ type: actions.SNACKBAR_SHOW, payload: params }),
    getProgramData: () => dispatch(getProgramsData())
})
export default connect(mapStateToProps, mapDispatchToProps)(CreateNewRun);