import React,{Suspense} from "react";
import AuthService from '../../server/AuthService';
import { Oval } from 'react-loader-spinner';
import { withRouter } from 'react-router-dom';
import Component2 from "../Component2";
import '../Settings/settings.css'
import LoaderComponent from "../Settings/LoaderComponent";
const ReportsContainer = React.lazy(()=> import("./ReportsContainer"));

class ReportsHome extends React.Component {
  constructor(props) {
    super(props)

    this.state = {

    }
  }
  componentDidMount() {

  }
  render() {
    if (AuthService.isAuthenticated()) {
      return (
        <div className="row"  style={{ display: 'flex','alignItems':'center',flexDirection:'column' }}>
          <div className="col-12" style={{maxWidth:'2000px'}}>
            <Component2 mainText="Reports" text19={"Reports"}/>
            <div className="rectangle-255"></div>
            <div className="flex">
              <div className="col-8 cmt-title">Reports</div>
              <div className="row" style={{ marginTop: '10px' }}>
                <div className="col-12 flex-container settings-container" >
                <Suspense fallback={<LoaderComponent/>}>
                  <ReportsContainer/>
                </Suspense>
                   
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return <div><div id="center" style={{ position: 'fixed', top: '40%', left: '48%' }}>
      <Oval type="Puff" position="fixed"  secondaryColor="#E85557" color="#E85557" height={50} width={100} visible={true} /> </div></div>;
  }
}

export default withRouter(ReportsHome);