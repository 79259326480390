import actions from "../../../../redux/actions/CommonDataActions";
import AuthService from "../../../server/AuthService";
import Endpoints from "../../../utilities/Endpoints";
import { singletonAxiosInstance } from "../../../utilities/Server";
import * as Constants from '../../../../constants/Constants'
import { getCleintNameEnv } from "../../utilities/services";

export const REPORTSLIST_FETCHING = 'REPORTSLIST_FETCHING';
export const REPORTS_LIST_SUCCES = 'REPORTS_LIST_RESPONSE';
export const REPORT_LIST_ERROR = 'REPORT_LIST_ERROR';
export const LOADING = 'LOADING'
export const REPORT_LINKS = 'REPORT_LINKS';
export const REPORT_PARAMS = 'REPORT_PARAMS';
export const REPORTS_PARAMS_SUCCESS = 'REPORTS_PARAMS_SUCCESS';
export const REPORTS_PARAMS_ERROR = 'REPORTS_PARAMS_ERROR';
export const REPORTS_POSTDATA_SUCCESS = 'REPORTS_POSTDATA_SUCCESS';
export const REPORTS_POSTDATA_ERROR = 'REPORTS_POSTDATA_ERROR';
export const REPORT_NAME = 'REPORT_NAME';
export const REPORT_CATEGORY = 'REPORT_CATEGORY';
export const UPDATED_REPORT_PARAMS = 'UPDATED_REPORT_PARAMS';
export const REPORT_HTML_PARAMS_UPDATE = 'REPORT_HTML_PARAMS_UPDATE';


const server = singletonAxiosInstance
const options = {
  Authorization: 'Bearer ' + localStorage.getItem('id_token') 
};

const statCodes = {
  SUCCESS: 200,
  CREATE_SUCCESS: 201,
  unAuthorized: 403,
  RETRY: 202
};

export const getReportLeftNavList=()=>{

    return dispatch => {
        if (AuthService.isTokenValid() !== true) {
          const user = AuthService.signinSilentToken();
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
          if (user) {
            localStorage.setItem('id_token', user.id_token);
          }
        }
        if (AuthService.isTokenValid() === true) {

          dispatch({type:LOADING,payload:true})
          dispatch({type: REPORTSLIST_FETCHING})
    
          server.get(getCleintNameEnv()+'reports', '', options).then(function (data) {
              dispatch({type:LOADING,payload:false})
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('reports list display', getCleintNameEnv()); }
            if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY ) {
            
              if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('activeMaintenance thunk response :', data.data); }
              if (data.status === statCodes.SUCCESS){
                dispatch({type: REPORTS_LIST_SUCCES, payload: data.data.data})
                dispatch({type:LOADING,payload:false})
              }else{
                dispatch({type: REPORTS_LIST_SUCCES, payload: data})
                dispatch({type:LOADING,payload:false})
              }
            } else {
              dispatch({type: REPORT_LIST_ERROR, payload: data})
              dispatch({type:LOADING,payload:false})
            }
          }).catch((error) => {
            dispatch({type:LOADING,payload:false})
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
            // if (!error.response) {
            //   dispatch({type: REPORT_LIST_ERROR, payload: error})
            //   dispatch({type:LOADING,payload:false})
            // } else {
            //   dispatch({type: REPORT_LIST_ERROR, payload: error.response})
            //   dispatch({type:LOADING,payload:false})
            // }
            dispatch({type: REPORT_LIST_ERROR, payload: {}})
            dispatch({ type: actions.SNACKBAR_SHOW, payload: {snackbarOpen: true, snackbarType:  Constants.SNACKBAR_TYPE.error, snackbarMessage: (typeof(error)!=='string'&&typeof(error)==='object'&& error.hasOwnProperty('message'))? error.message:error}})
          });
        }
      };
}

export const getSelectedReportParameters=(category,reportName)=>{

  return dispatch => {
      if (AuthService.isTokenValid() !== true) {
        const user = AuthService.signinSilentToken();
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
        if (user) {
          localStorage.setItem('id_token', user.id_token);
        }
      }
      if (AuthService.isTokenValid() === true) {

        dispatch({type:LOADING,payload:true})
  
        server.get(`${getCleintNameEnv()}reports/${encodeURI(category)}:${encodeURI(reportName)}/parameters`, '', options).then(function (data) {
          dispatch({type:LOADING,payload:false})
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('reports parameters display',getCleintNameEnv()); }
          if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY ) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('activeMaintenance thunk response :', data.data); }
            if (data.status === statCodes.SUCCESS){
              dispatch({type: REPORTS_PARAMS_SUCCESS, payload: data.data.data})
              dispatch({type:LOADING,payload:false})
            }else{
              dispatch({type: REPORTS_PARAMS_SUCCESS, payload: data})
              dispatch({type:LOADING,payload:false})
            }
          } else {
            dispatch({type: REPORTS_PARAMS_ERROR, payload: data})
            dispatch({type:LOADING,payload:false})
          }
        }).catch((error) => {
          dispatch({type:LOADING,payload:true})
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
          if (!error.response) {
            dispatch({type: REPORTS_PARAMS_ERROR, payload: error})
            dispatch({type:LOADING,payload:false})
          } else {
            dispatch({type: REPORTS_PARAMS_ERROR, payload: error.response})
            dispatch({type:LOADING,payload:false})
          }
        });
      }
    };
}

export const postReportsParameters=(category,reportName)=>{

  return dispatch => {
      if (AuthService.isTokenValid() !== true) {
        const user = AuthService.signinSilentToken();
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
        if (user) {
          localStorage.setItem('id_token', user.id_token);
        }
      }
      if (AuthService.isTokenValid() === true) {

        dispatch({type:LOADING,payload:true})
        server.post(`${getCleintNameEnv()}reports/${encodeURI(category)}:${encodeURI(reportName)}`, data, '', options).then(function (data) {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('reports parameters display', getCleintNameEnv()); }
          if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY ) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('activeMaintenance thunk response :', data.data); }
            if (data.status === statCodes.SUCCESS){
              dispatch({type: REPORTS_POSTDATA_SUCCESS, payload: data.data.data})
              dispatch({type:LOADING,payload:false})
            }else{
              dispatch({type: REPORTS_POSTDATA_SUCCESS, payload: data})
              dispatch({type:LOADING,payload:false})
            }
          } else {
            dispatch({type: REPORTS_POSTDATA_ERROR, payload: data})
            dispatch({type:LOADING,payload:false})
          }
        }).catch((error) => {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
          if (!error.response) {
            dispatch({type: REPORTS_POSTDATA_ERROR, payload: error})
            dispatch({type:LOADING,payload:false})
          } else {
            dispatch({type: REPORTS_POSTDATA_ERROR, payload: error.response})
            dispatch({type:LOADING,payload:false})
          }
        });
      }
    };
}
