import React from 'react'
import {withRouter} from 'react-router'
import ErrorImage from '../../assets/ErrorBoundaryImage.svg'

const ErrorComponent = () => {
    return <img src={ErrorImage} alt="something went wrong" />
  };
  
  class ErrorBoundary extends React.Component {

    state = {
      hasError: false,
      error: { message: "", stack: "" },
      info: { componentStack: "" }
    };

    componentDidMount = () => {
        const { history } = this.props;

        history.listen((location, action) => {
            if (this.state.hasError) {
                this.setState({
                    hasError: false,
                });
            }
        });
    }

    
  
    static getDerivedStateFromError = error => {
      return { hasError: true };
    };
  
    componentDidCatch = (error, info) => {
      this.setState({ error, info });
    };
  
    render() {
      const { hasError, error, info } = this.state;
      console.log(error, info);
      const { children } = this.props;
  
      return hasError ? <ErrorComponent /> : children;
    }
  }

export default withRouter(ErrorBoundary)