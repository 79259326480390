import actions from '../actions/CommonDataActions';


// reducer with initial state
const initialState = { 
  isFetchingData : false, 
  selectedItem:'home',
  refreshPage:true,
  headerAction:'',
  localChangesExist:false,

  
};

export default function commonDataReducer(state, action) {
  if (typeof state === 'undefined') {
    return initialState;
  }  
  switch (action.type) {    
    case actions.FETCHING_DATA_LOADER:
      return { ...state,  isFetchingData : true };
    case actions.HIDE_DATA_LOADER:
      return { ...state,  isFetchingData : false }; 
    case actions.HEADER_DROPDWON_ACTION:
      return {...state, headerAction:action.payload}; 
    case actions.CHANGES_EXISTING : 
    return {...state, localChangesExist:action.payload}     
    case actions.SELECTED_NAVITEM:            
      if(action.payload==='refresh'){              
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){ console.log('selected res ',commonDataReducer.selectedItem);}
        return {...state};
      }
      return {...state,selectedItem:action.payload};   
            
    case actions.REFRESHPAGE:
      return { ...state,  refreshPage : false };   
             
    default:
      return state;
  }

}