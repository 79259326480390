import React from "react";
// import addIcon from '../../assets/samplecme.svg';
import AuthService from "../../../../src/components/server/AuthService";
// import '../CommissionsRunManager/CommissionRunManager.css'
import { Oval } from "react-loader-spinner";
import { withRouter } from "react-router-dom";
import Component2 from "../Component2";
import * as Constants from "../commonComponents/Constants";
import closeIcon from "../assets/closeicon.svg";
import "./CommToolCompareRun.css";
import addIcon from "../assets/addIcon.svg";
import upArrow from '../assets/upArrow.svg';
import downArrow from '../assets/downArrow.svg';
import { TableCell, TableContainer, TableHead } from "@material-ui/core";
import { Table } from "react-bootstrap";
import { TableBody, TableHeader } from "semantic-ui-react";
import TableRow from "@material-ui/core/TableRow";
import { connect } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import {
  getCommissionRunAPIList,
  getCommRunDetails,
  getCommissionTypeListAPI,
} from "../../../components/Commissions/actions";
import downlinereport from "../assets/downlinereport.svg";
import showdifferences from "../assets/showdifferences.svg";
import swapruns from "../assets/swapruns.svg";
import AddCompareModal from "../AddCompareRun/AddCompareModal";
import { CSVLink } from "react-csv";
import { generateCSV } from './generateCSV';
import { allNullTypeCheck, isNullResponse } from "../commonComponents/UtilityFun";
import Moment from "moment";
import { stringTruncate, numberWithCommas } from "../../utilities/Services";
import { Tooltip } from '@material-ui/core';


const csvReport = {
  data: "data",
  headers: "headers",
  filename: 'Runcompaare_Report.csv'
};
let totalPerc = 0.0;
let percentIndex=0;
class CommToolCompareRun extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addAction1: true,
      addAction2: true,
      compareEnabled: true,
      compareTableHeaders: [
        { displayName: "No." },
        { displayName: "Description" },
        { displayName: "Run Name" },
        { displayName: "Recipients" },
        { displayName: "Avg. Payout" },
        { displayName: "Total Payout" },
        { displayName: "Percentage" },
      ],
      commissionRunState: [],
      selectedRunList: [],
      isrunCompareFromList: true,
      isdisplayCompareRuns: false,
      commissionRunDetailsData: [],
      totalAvarageFirst:0,
      totalAvarageSecond:0,
      iscommissionTypeSelected:false,
      commissionTypes:[],
      selectedValue:'',
      modelSelectedRun:'',
      selectedCompareAction:'',
      index0:0,
      index1:0,
      showValDifferences:false,
      showLoaderResult:true,
      selectedPercentValue:'',
      retryDelayTime:1,
      retryDelayTime1:1,
      retryDelayTime2:1,
      
      
    };

    this.handlAddAction = this.handlAddAction.bind(this);
    this.handleCloseAction = this.handleCloseAction.bind(this);
    this.handleAddCompareRunsModal = this.handleAddCompareRunsModal.bind(this);
    this.getTheSecondRunAvarage = this.getTheSecondRunAvarage.bind(this);
  }
  componentDidMount() {
    this.props.getCommissionRunsList();
    console.log('run objects', this.props?.location?.state?.runObjects);
    this.props.getCommissionTypes();
    //Note we are calling get service with the id bcz we are not getting proper response from the props parameter 
    if(this.props?.location?.state?.runObjects!==null&&this.props?.location?.state?.runObjects!==undefined){
      this.setState({showLoaderResult:true})
      this.props?.location?.state?.runObjects.map((data)=>{
        this.props.getcommissionRunDetails(data?.runId);
        this.setState({compareEnabled:false, iscommissionTypeSelected: true})
      })
    }
    
  }
  componentDidUpdate(nextProps) {
    const {
      commissionRunList,
      getcommissionRunDetails,
      selectedCommissionRun,
      commissionRunDetails,
      commissionTypeList,
    } = this.props;
    const { commissionRunState, selectedRunList,commissionRunDetailsData, totalAvarageFirst, totalAvarageSecond, selectedCompareAction, retryDelayTime, retryDelayTime1, retryDelayTime2
    } = this.state;
    if (commissionRunList !== nextProps.commissionRunList){
      if(!commissionRunList.hasOwnProperty('status')){
      this.setState({showLoaderResult:false });
      if(isNullResponse(commissionRunList.data) && commissionRunList?.error === false) {
        this.setState({ commissionRunState: commissionRunList.data?.runs});
        console.log("id is ", commissionRunList.data.runId);
      } else if(Object.keys(commissionRunList?.data).length===0 && allNullTypeCheck(commissionRunList?.messages)) {
        if(commissionRunList?.messages?.length > 0 && commissionRunList?.messages[0]?.includes("Try again...")){
            console.log("Try again block entered : ",commissionRunList?.data);
            setTimeout(() => {
              this.props.getCommissionRunsList();
            }, 1000 * retryDelayTime);
            this.setState(prevState => {
              return {retryDelayTime: prevState.retryDelayTime * 2}
           })
        }
      }
    } else {
        if(commissionRunList.hasOwnProperty('status') && commissionRunList.status===202){ 
          setTimeout(() => {
            this.props.getCommissionRunsList();
          }, 1000 * retryDelayTime);
          this.setState(prevState => {
            return {retryDelayTime: prevState.retryDelayTime * 2}
        })
        }
      }
    }  
    if (commissionRunDetails !== nextProps.commissionRunDetails){
      this.setState({showLoaderResult:false });
      if(!commissionRunDetails.hasOwnProperty('status')){ 
        if(isNullResponse(commissionRunDetails.data) && commissionRunDetails.error === false){
          this.setState({commissionRunDetailsData: commissionRunDetails.data});
          console.log("array list length :",commissionRunDetails.data,selectedRunList.length,this.props?.location?.state?.runObjects.length);

          if(selectedRunList.length<2){
            if(selectedCompareAction==='first'){
            selectedRunList.length===0? this.setState({ addAction1: false,index0:0 }):this.setState({ addAction1: false,index0:1 })
            }else{
              selectedRunList.length===0? this.setState({ addAction2: false,index1:0}):this.setState({ addAction2: false,index1:1})
            }
              selectedRunList.push(commissionRunDetails?.data);
            if(totalAvarageFirst===0 && selectedRunList.length>0 ){
              selectedRunList[0]?.payouts?.map((data, j) => {
                this.setState((prevState)=>({
                  totalAvarageFirst:prevState.totalAvarageFirst + + (selectedRunList[0]?.payouts[j]
                    ?.recipients !== 0
                    ? selectedRunList[0]?.payouts[j]?.payout /
                      selectedRunList[0]?.payouts[j]?.recipients
                    : 0
                  )
                    .toString()
                    .split(".")
                    .map((el, i) =>
                      i ? el.split("").slice(0, 2).join("") : el
                    )
                    .join(".")
                }))
              })
            }
            if(totalAvarageSecond===0 && selectedRunList.length>0){
              selectedRunList[1]?.payouts?.map((data, j) => {
                this.setState((prevState)=>({
                  totalAvarageSecond:prevState.totalAvarageSecond + + (selectedRunList[1]?.payouts[j]
                    ?.recipients !== 0
                    ? selectedRunList[1]?.payouts[j]?.payout /
                      selectedRunList[1]?.payouts[j]?.recipients
                    : 0
                  )
                    .toString()
                    .split(".")
                    .map((el, i) =>
                      i ? el.split("").slice(0, 2).join("") : el
                    )
                    .join(".")
                }))
              })
            }
              console.log("results : ", this.state.selectedRunList);
              this.setState({selectedRunList: this.state.selectedRunList},()=>{
                if(this.state.selectedRunList.length>1){
                  if(selectedRunList[0].payouts.length>0 && selectedRunList[1].payouts.length>0) {
                    if(selectedRunList[0]?.payouts?.length >= selectedRunList[1]?.payouts?.length && selectedRunList[0]?.payouts[0]?.percentBasedOn?.length > 0){
                      this.setState({selectedPercentValue : selectedRunList[0]?.payouts[0]?.percentBasedOn[1].description}) 
                    } else if(selectedRunList[1]?.payouts[0]?.percentBasedOn?.length > 0) {
                      this.setState({selectedPercentValue : selectedRunList[1]?.payouts[0]?.percentBasedOn[1].description}) 
                    }
                  }
                }
              });
            }
            if(this.props?.location?.state?.runObjects?.length===2&&selectedRunList.length===2){
              this.setState({ addAction1: false,addAction2:false });
            } 
          } else if(Object.keys(commissionRunDetails?.data).length===0 && allNullTypeCheck(commissionRunDetails?.messages)) {
            if(commissionRunDetails?.messages?.length > 0 && commissionRunDetails?.messages[0]?.includes("Try again...")){
                console.log("Try again block entered : ",commissionRunDetails?.data);
                setTimeout(() => {
                  this.props?.location?.state?.runObjects.map((data)=>{
                    this.props.getcommissionRunDetails(data?.runId);
                    this.setState({compareEnabled:false})
                  })
                }, 1000 * retryDelayTime1);
                this.setState(prevState => {
                  return {retryDelayTime1: prevState.retryDelayTime1 * 2}
              })
            }
          }
        }else {
          if(commissionRunDetails.hasOwnProperty('status') && commissionRunDetails.status===202){ 
            setTimeout(() => {
              this.props?.location?.state?.runObjects.map((data)=>{
                this.props.getcommissionRunDetails(data?.runId);
                this.setState({compareEnabled:false})
              })
            }, 1000 * retryDelayTime1);
            this.setState(prevState => {
              return {retryDelayTime1: prevState.retryDelayTime1 * 2}
          })
          }
        }
    }
    if(commissionTypeList!==nextProps.commissionTypeList){
      this.setState({showLoaderResult:false });
      if(!commissionTypeList.hasOwnProperty('status')){ 
        if(isNullResponse(commissionTypeList.data) && commissionTypeList.error === false){
          if(commissionTypeList?.data?.hasOwnProperty('periodTypes')){
            this.setState({commissionTypes: commissionTypeList?.data?.periodTypes.filter(data=>data.active===true),selectedValue:commissionTypeList?.data?.periodTypes[0]}); 
          }
        
        } else if(Object.keys(commissionTypeList?.data).length===0 && allNullTypeCheck(commissionTypeList?.messages)) {
          if(commissionTypeList?.messages?.length > 0 && commissionTypeList?.messages[0]?.includes("Try again...")){
              console.log("Try again block entered : ",commissionTypeList?.data);
              setTimeout(() => {
                this.props.getCommissionTypes();
              }, 1000 * retryDelayTime2);
              this.setState(prevState => {
                return {retryDelayTime2: prevState.retryDelayTime2 * 2}
            })
          }
      }
    }else {
      if(commissionTypeList.hasOwnProperty('status') && commissionTypeList.status===202){ 
        this.setState({showLoaderResult:true });
        setTimeout(() => {
          this.props.getCommissionTypes();
        }, 1000 * retryDelayTime2);
        this.setState(prevState => {
          return {retryDelayTime2: prevState.retryDelayTime2 * 2}
        })
      }
    }
   }
  
  }

  handleChangePeriodType = (value) => {
    console.log("Select comm type", value);
    const commissionObj = this.commissionsTypefindIndex(this.state.commissionTypes,value);
    console.log("rs: ",this.state.commissionTypes[commissionObj]);
    this.setState({ selectedValue: this.state.commissionTypes[commissionObj] });
    // this.getPeriods(value);
  };
  commissionsTypefindIndex=(commissiontypeList,value)=>{
    return commissiontypeList.findIndex((periodType) => {
      return (periodType.title === value);
  });
  }

  handlAddAction(num) {
    console.log("result : ", num);
    if (num === "first") {
      console.log("result0 : ", num);
      // this.setState({ addAction1: false });
      this.setState({selectedCompareAction:num});
    } else {
      console.log("result1 : ", num);
      // this.setState({ addAction2: false });
      this.setState({selectedCompareAction:num});
    }
    this.setState({
      isdisplayCompareRuns: !this.state.isdisplayCompareRuns,
    });
  }
  handleCloseAction(num) {
    const { selectedRunList } = this.state;
    if (num === "first") {
      selectedRunList.length===2? this.setState({ addAction1: true,index1: 0,totalAvarageSecond:0}):this.setState({ addAction1: true,index0: 0,totalAvarageFirst:0})
      console.log("removing array ",selectedRunList.length);
      if(selectedRunList.length===1){
        this.setState({iscommissionTypeSelected:false,compareEnabled:true});
        this.setState({selectedRunList:[]});
      }else {
        selectedRunList.splice(0,1)
      }
    } else {
      selectedRunList.length===2? this.setState({ addAction2: true,index0:0,totalAvarageSecond:0 }): this.setState({ addAction2: true,index1:0,totalAvarageSecond:0 })
      if(selectedRunList.length===1){
        this.setState({iscommissionTypeSelected:false,compareEnabled:true});
        this.setState({selectedRunList:[]});
      }else {
        selectedRunList.splice(1,1)
      console.log("removing second array:");
     }
   }
   this.setState({totalAvarageFirst:0,showValDifferences:false},()=>{this.handledTotalSwap()});
   
  }
  showRunDifferences() {
    console.log("show table difference");
    this.setState({showValDifferences:!this.state.showValDifferences});
  }
  handledTotalSwap=()=>{
    const { commissionRunDetails } = this.props;
    const { commissionRunState, selectedRunList,commissionRunDetailsData, totalAvarageFirst, totalAvarageSecond, selectedCompareAction } = this.state;
    console.log("response ",selectedRunList);
   
    if(selectedRunList.length>1){
      let temp = totalAvarageFirst;
      this.setState({totalAvarageFirst:totalAvarageSecond,totalAvarageSecond:temp});
    }else{
      if(totalAvarageFirst===0 && selectedRunList.length>0 ){
        selectedRunList[0]?.payouts.map((data, j) => {
          this.setState((prevState)=>({
            totalAvarageFirst:prevState.totalAvarageFirst + + (selectedRunList[0]?.payouts[j]
              ?.recipients !== 0
              ? selectedRunList[0]?.payouts[j]?.payout /
                selectedRunList[0]?.payouts[j]?.recipients
              : 0
            )
              .toString()
              .split(".")
              .map((el, i) =>
                i ? el.split("").slice(0, 2).join("") : el
              )
              .join(".")
          }),()=>{})
        })
        console.log("total avg first : ",totalAvarageFirst);
      }
    }
    
    this.setState({showLoaderResult:false})
  }
  swaprunsClick() {
   this.setState({showLoaderResult:true});
    const {
      selectedRunList
    } = this.state;
    console.log("before list",selectedRunList);
    let data = selectedRunList;
    let tmp = data[0];
		data[0] = data[1];
		data[1] = tmp;
    this.setState({selectedRunList: data},()=>{
        setTimeout(()=>{
          this.handledTotalSwap()
        },1000)
    })
  
		
    
  }
  gobacktoCommissionTools() {
    this.props.history.push({
      pathname: "/commissiontools",
    });
  }
  downloadReport(){
    console.log('show downloadReport')
    const {
      compareTableHeaders,
      selectedRunList,
    } = this.state;
    var myJsonString = JSON.stringify(selectedRunList);
    console.log('myjsin string', myJsonString)
    generateCSV(selectedRunList, "TestingRunsdownload",compareTableHeaders)
  }
  componentWillReceiveProps(){
    this.getTheSecondRunAvarage();
  }
  getTheSecondRunAvarage=()=>{
    const { selectedRunList } = this.state;
    selectedRunList[1]?.payouts.map((data, j) => {
      this.setState((prevState)=>({
        totalAvarageSecond:prevState.totalAvarageSecond + + (selectedRunList[1]?.payouts[j]
          ?.recipients !== 0
          ? selectedRunList[1]?.payouts[j]?.payout /
            selectedRunList[1]?.payouts[j]?.recipients
          : 0
        )
          .toString()
          .split(".")
          .map((el, i) =>
            i ? el.split("").slice(0, 2).join("") : el
          )
          .join(".")
      }))
    })
  }

  selectedRunHandler(commissionrunId){
    console.log('selected comm run', commissionrunId)
    // this.props.getcommissionRunDetails(commissionrunId);
    this.setState({showLoaderResult:true});
    if(commissionrunId!==null&&commissionrunId!==undefined&&commissionrunId!==''){
      this.setState({iscommissionTypeSelected:true,compareEnabled:false});
      this.props.getcommissionRunDetails(commissionrunId);
    }

  }
  handleAddCompareRunsModal(){
    this.setState({
      isdisplayCompareRuns: !this.state.isdisplayCompareRuns,
    });
  }

  handleChangePercentType = (value) => {
		console.log('value selected', value)
		totalPerc = 0.0;
		this.setState({ selectedPercentValue: value});
	}
  percentDropdown = (l) => {
		const { selectedPercentValue, selectedRunList} = this.state;
    if(allNullTypeCheck(selectedPercentValue) && selectedRunList.length>1) {
		return(
		<div className="runmanager-element-width dropdown-alignitems">
                <Dropdown
                  className="runmanger"
                  onSelect={this.handleChangePercentType}
                  style={{
                    backgroundColor: "white",
                    marginTop: "1px",
                    marginRight:'0px'
                  }}
                >
                  <Dropdown.Toggle
                    id="comptool-custom-dropdown-components"
                  >
                    {selectedPercentValue}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="comparetool-period-dropdown-menu">
                  {selectedRunList.length>1 && selectedRunList[0].payouts.length>0 && selectedRunList[1].payouts.length>0 && selectedRunList[l]?.payouts?.length>0 && selectedRunList[l]?.payouts[0]?.percentBasedOn?.length > 0 &&
                      selectedRunList[l]?.payouts[0]?.percentBasedOn?.map((data, j) => {
                      return (
                        <Dropdown.Item
                          className="primary-dropbtn"
                          eventKey={data.description}
                        >
                          {data.description}
                        </Dropdown.Item>
                      );
                    }) }
                  </Dropdown.Menu>
                </Dropdown> <span className="percent-styles">(%)</span>
              </div>
		)
  } else {
    return;
  }
	}
  getPercentValues =(l,firstElement,secondElement)=>{
    const { selectedRunList, selectedPercentValue, showValDifferences } = this.state;
    let resultIs='';
      if(parseFloat(selectedRunList[0]?.payouts[firstElement]?.percentBasedOn[selectedRunList[0]?.payouts[firstElement]?.percentBasedOn?.findIndex((data) =>	{				
        return data?.description === selectedPercentValue })]?.percent?.toFixed(2)) <= parseFloat(selectedRunList[1]?.payouts[secondElement]?.percentBasedOn[selectedRunList[1]?.payouts[secondElement]?.percentBasedOn?.findIndex((data) =>	{				
          return data?.description === selectedPercentValue })]?.percent?.toFixed(2))){

      if(parseFloat(selectedRunList[0]?.payouts[firstElement]?.percentBasedOn[selectedRunList[0]?.payouts[firstElement]?.percentBasedOn?.findIndex((data) =>	{				
        return data?.description === selectedPercentValue })]?.percent?.toFixed(2)) === parseFloat(selectedRunList[1]?.payouts[secondElement]?.percentBasedOn[selectedRunList[1]?.payouts[secondElement]?.percentBasedOn?.findIndex((data) =>	{				
          return data?.description === selectedPercentValue })]?.percent?.toFixed(2))){

            resultIs = <div className="comprun-without-arrows">{selectedRunList[1]?.payouts[secondElement]?.percentBasedOn?.length>0? selectedRunList[1]?.payouts[secondElement]?.percentBasedOn[selectedRunList[1]?.payouts[secondElement]?.percentBasedOn?.findIndex((data) =>	{				
          return data?.description === selectedPercentValue })]?.percent?.toFixed(2)
          :'0.00'}</div>
        } else {

          resultIs = <div className="arrow-display-content">
            <span className={showValDifferences && firstElement!==-1?"text-with-uparrow":"comprun-without-arrows"}>{selectedRunList[1]?.payouts[secondElement]?.percentBasedOn?.length>0? selectedRunList[1]?.payouts[secondElement]?.percentBasedOn[selectedRunList[1]?.payouts[secondElement]?.percentBasedOn?.findIndex((data) =>	{				
            return data?.description === selectedPercentValue })]?.percent?.toFixed(2)
            :'0.00'}</span>
            {showValDifferences && firstElement!==-1?<img
              className="uparrow-img-display"
              src={upArrow}
              alt=""
            />:''}
            </div>
        }
      } else {
        resultIs =  <div>
              {selectedRunList.length>1 && selectedRunList[1]?.payouts?.length>0?
                <><span className={showValDifferences && firstElement!==-1?"text-with-downarrow":"comprun-without-arrows"}>{selectedRunList[1]?.payouts[secondElement]?.percentBasedOn?.length>0? selectedRunList[1]?.payouts[secondElement]?.percentBasedOn[selectedRunList[1]?.payouts[secondElement]?.percentBasedOn?.findIndex((data) =>	{				
                  return data?.description === selectedPercentValue })]?.percent?.toFixed(2)
                  :'0.00'}</span>
                {showValDifferences && firstElement!==-1?<img
                  className="uparrow-img-display"
                  src={downArrow}
                  alt=""
                />:''}
                </>:''}
              </div>
      }
      return resultIs;
  }
  getAvarageValue =(l,firstElement,secondElement)=>{
    const { selectedRunList, selectedPercentValue, showValDifferences } = this.state;
    let resultIs='';
    if((parseFloat(((selectedRunList[0]?.payouts?.length>0 && selectedRunList[0]?.payouts[firstElement]?.recipients !==0)? selectedRunList[0]?.payouts[firstElement]?.payout /
            selectedRunList[0]?.payouts[firstElement]?.recipients : '0.00'
        ).toString()
          .split(".")
          .map((el, i) =>
            i ? el.split("").slice(0, 2).join("") : el
          )
          .join("."))) <= (parseFloat(((selectedRunList[1]?.payouts?.length>0 && selectedRunList[1]?.payouts[secondElement]?.recipients !==0)? selectedRunList[1]?.payouts[secondElement]?.payout /
                selectedRunList[1]?.payouts[secondElement]?.recipients : '0.00'
            ).toString()
              .split(".")
              .map((el, i) =>
                i ? el.split("").slice(0, 2).join("") : el
              )
              .join(".")))){

               if((parseFloat((( selectedRunList[0]?.payouts?.length>0 && selectedRunList[0]?.payouts[firstElement]?.recipients !==0)?
                    selectedRunList[0]?.payouts[firstElement]?.payout /
                    selectedRunList[0]?.payouts[firstElement]?.recipients : '0.00'
                  ).toString()
                    .split(".")
                    .map((el, i) =>
                      i ? el.split("").slice(0, 2).join("") : el
                    )
                    .join(".")))===(parseFloat(((selectedRunList[1]?.payouts?.length>0 && selectedRunList[1]?.payouts[secondElement]?.recipients !==0)?selectedRunList[1]?.payouts[secondElement]?.payout /
                      selectedRunList[1]?.payouts[secondElement]?.recipients : '0.00'
                  ).toString()
                    .split(".")
                    .map((el, i) =>
                      i ? el.split("").slice(0, 2).join("") : el
                    )
                    .join(".")))){

                      resultIs = <div className="comprun-without-arrows">{selectedRunList.length>1? numberWithCommas(parseFloat(((selectedRunList[1]?.payouts?.length>0 && selectedRunList[1]?.payouts[secondElement]?.recipients !==0)?(selectedRunList[1]?.payouts[secondElement]?.payout /
                            selectedRunList[1]?.payouts[secondElement]?.recipients):'0.00'
                        ).toString()
                          .split(".")
                          .map((el, i) =>
                            i ? el.split("").slice(0, 2).join("") : el
                          )
                          .join("."))?.toFixed(2)):''}</div>
                  
                  }else {
                       resultIs = <div className="arrow-display-content">
                                  {selectedRunList.length>1?
                                  <><span className={showValDifferences && firstElement!==-1?"text-with-uparrow":"comprun-without-arrows"}>{numberWithCommas(parseFloat(((selectedRunList[1]?.payouts?.length>0 && selectedRunList[1]?.payouts[secondElement]?.recipients !==0)? (selectedRunList[1]?.payouts[secondElement]?.payout /
                                        selectedRunList[1]?.payouts[secondElement]?.recipients) :'0.00'
                                    )
                                      .toString()
                                      .split(".")
                                      .map((el, i) =>
                                        i ? el.split("").slice(0, 2).join("") : el
                                      )
                                      .join("."))?.toFixed(2))}</span>
                                  {showValDifferences && firstElement!==-1?<img
                                    className="uparrow-img-display"
                                    src={upArrow}
                                    alt=""
                                  />:''}
                                  </>
                                  :''}
                                </div>
                  }

              }else {
                    resultIs = <div>
                                  <span className={showValDifferences && firstElement!==-1? "text-with-downarrow":"comprun-without-arrows"}>{numberWithCommas((parseFloat(((selectedRunList[1]?.payouts?.length>0 && selectedRunList[1]?.payouts[secondElement]?.recipients !==0)?
                                     (selectedRunList[1]?.payouts[secondElement]?.payout /
                                      selectedRunList[1]?.payouts[secondElement]?.recipients) :'0.00'
                                  )
                                    .toString()
                                    .split(".")
                                    .map((el, i) =>
                                      i ? el.split("").slice(0, 2).join("") : el
                                    )
                                    .join(".")))?.toFixed(2))}</span>
                                {showValDifferences && firstElement!==-1? <img
                                  className="uparrow-img-display"
                                  src={downArrow}
                                  alt=""
                                />:''}
                              </div>
              }
    return resultIs;
  }

  percentTotalValue=()=>{
    const { selectedRunList, showValDifferences, selectedPercentValue } = this.state;
    let resultIs='', initialValue=0.00;
    if(selectedRunList[0]?.payouts?.reduce((previousValue, currentValue) =>
           previousValue + currentValue?.percentBasedOn[currentValue.percentBasedOn.findIndex((pecentValue) => {
              return (pecentValue.description === selectedPercentValue)}
          )]?.percent,
          initialValue
        ).toFixed(2) <= selectedRunList[1]?.payouts?.reduce((previousValue, currentValue) =>
        previousValue + currentValue?.percentBasedOn[currentValue.percentBasedOn.findIndex((pecentValue) => {
            return (pecentValue.description === selectedPercentValue)}
        )]?.percent,
        initialValue
        ).toFixed(2)) {

        if(selectedRunList[0]?.payouts?.reduce((previousValue, currentValue) =>
           previousValue + currentValue?.percentBasedOn[currentValue.percentBasedOn.findIndex((pecentValue) => {
              return (pecentValue.description === selectedPercentValue)}
          )]?.percent,
          initialValue
        ).toFixed(2) === selectedRunList[1]?.payouts?.reduce((previousValue, currentValue) =>
        previousValue + currentValue?.percentBasedOn[currentValue.percentBasedOn.findIndex((pecentValue) => {
            return (pecentValue.description === selectedPercentValue)}
        )]?.percent,
        initialValue
        ).toFixed(2)){

        resultIs = <div className="comprun-without-arrows">{numberWithCommas(selectedRunList[1]?.payouts?.reduce((previousValue, currentValue) =>
          previousValue + currentValue?.percentBasedOn[currentValue.percentBasedOn.findIndex((pecentValue) => {
              return (pecentValue.description === selectedPercentValue)}
          )]?.percent,
          initialValue
          ).toFixed(2))}</div>
        }else {
          resultIs =  <div className="arrow-display-content">
                <span className={showValDifferences?"text-with-uparrow":"comprun-without-arrows"}>{numberWithCommas(selectedRunList[1]?.payouts?.reduce((previousValue, currentValue) =>
          previousValue + currentValue?.percentBasedOn[currentValue.percentBasedOn.findIndex((pecentValue) => {
              return (pecentValue.description === selectedPercentValue)}
          )]?.percent,
          initialValue
          ).toFixed(2))}</span>
                {showValDifferences?<img
                  className="uparrow-img-display"
                  src={upArrow}
                  alt=""
                />:''}
              </div>
        }
    }else {
      resultIs = <div>
      {selectedRunList.length>1?
        <><span className={showValDifferences?"text-with-downarrow":"comprun-without-arrows"}>{numberWithCommas(selectedRunList[1]?.payouts?.reduce((previousValue, currentValue) =>
          previousValue + currentValue?.percentBasedOn[currentValue.percentBasedOn.findIndex((pecentValue) => {
              return (pecentValue.description === selectedPercentValue)}
          )]?.percent,
          initialValue
          ).toFixed(2))}</span> 
      {showValDifferences?<img
        className="uparrow-img-display"
        src={downArrow}
        alt=""
      />:''}
      </>:''}
      </div>
    }
    return resultIs;
 }
  
  render() {
    const {
      addAction1,
      addAction2,
      compareEnabled,
      compareTableHeaders,
      selectedRunList,
      isrunCompareFromList,
      isdisplayCompareRuns,
      totalAvarageFirst,
      totalAvarageSecond,
      iscommissionTypeSelected,
      selectedValue,
      index0,
      index1,
      showValDifferences,
      showLoaderResult,
      selectedPercentValue,
    } = this.state;
    const { commissionRunDetails } = this.props;
    
    console.log("selected results : ", selectedRunList?.[1]);
     if(selectedRunList.length >0 && compareTableHeaders.length>0){
       csvReport.data = selectedRunList
       csvReport.headers = compareTableHeaders
     }
    let list2;
    let initialValue = 0, l = 0;
    (selectedRunList[0]?.payouts?.length >= selectedRunList[1]?.payouts?.length)? l=0 : l=1;
    // this.getTheSecondRunAvarage();  
    if (showLoaderResult) {
        return <div id="center" style={{ position: "fixed", bottom: "40%", left: "48%" }}>
            <Oval
                type="Puff"
                position="fixed"  secondaryColor="#E85557"
                color="#E85557"
                height={50}
                width={50}
                timeout={3000}
            />
        </div>
    } else {
    if (AuthService.isAuthenticated()) {
      return (
        <div className="row com-overlap-group-1">
          <div
            className="col-9"
            style={{ paddingLeft: "0px", paddingRight: "0px" }}
          >
            {/* <Component2 text19={"Commission Tools / Compare Run"} />
            <div className="comparerun-title"> */}
            <Component2 mainText='Commissions Tools' typeText={'DistTools'} />
            <div className="row comparerun-title">
              <div className="col-10"><span className="comparerun-title-style">Compare Runs</span></div>
              {iscommissionTypeSelected?<div className="col-2" >
                {/* <div className="comm-type-period-display">
                  <div className="runmanager-alignitems-left cm-type-title">
                    <span className="cm-type-title">Period Type</span>
                    <div className="runmanager-element-width alignitems-left">
                      {selectedValue?.title}
                    </div>
                  </div>
                </div> */}
              </div> :
                <div className="col-2">
                  <div className="comm-type-period-display">
                    <div className="runmanager-alignitems-left cm-type-title">
                      <span className="cm-type-title">Period Type</span>
                      <div className="runmanager-element-width alignitems-left">
                        <Dropdown className="runmanger"
                          onSelect={this.handleChangePeriodType}
                          style={{
                            backgroundColor: "white",
                            marginTop: "8px",
                          }}
                        >
                          <Dropdown.Toggle
                            id="dropdown-custom-components"
                            title="Select Type"
                          >
                            {selectedValue?.title}
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="period-dropdown-menu">
                            {this.state.commissionTypes.map((item) => {
                              return (
                                <Dropdown.Item
                                  className="primary-dropbtn"
                                  eventKey={item.title}
                                >
                                  {item.title}
                                </Dropdown.Item>
                              );
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                        {/* <img src={dropdown1} alt='' /> */}
                      </div>
                    </div>
                  </div>
              </div>}
            </div>
            <div className="commtool-comparerun-disc">
              {/* <span className={selectedRunList.length>0?"commprun-baserun-info":"comprun-run-info"}>{selectedRunList.length>0? "Base Run": Constants.COMM_TOOLS_DESCRIPTION}</span>               */}
              <div>
              {selectedRunList.length>0? 
                <div className="baserun-and-period-style">
                  <div className="commprun-baserun-info">{"Base Run"}</div>
                  <div>
                    <div className="comm-type-period-display">
                      <div className="runmanager-alignitems-left cm-type-title">
                        <span className="cm-type-title  period-type-style">Period Type</span>
                        <div className="runmanager-element-width alignitems-left">
                          {selectedValue?.title}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>:
                <div>
                  <span className="comprun-run-info">{Constants.COMM_TOOLS_DESCRIPTION}</span>
                </div>}
              </div>

            </div>
            <div className="commtool-compare-options">
              <div className="remove-col-padding">
                <div className="commtool-compare-box-ui">
                  {addAction1 ? (
                    <>
                      <div className="row commtool-close">
                      </div>
                      <div className="addrun-margintop30"
                        onClick={() => this.handlAddAction("first")}
                      >
                        <img
                          className="comm-add-icon-style"
                          src={addIcon}
                          alt=""
                        />
                        <span className="commtool-textalign">
                          {Constants.COMMTOO_TEXT_ADD_RUN_TO_COMPARE}
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="row" style={{ marginTop: "8px" }}>
                        <div className="col-6" style={{ display: 'flex',paddingRight:'0px' }}>
                        {selectedRunList[index0]?.name.length > 15?
                        <Tooltip title= {selectedRunList[index0]?.name} placement="top" arrow={true}> 
                          <span className="cmnrun-cmpr-title">
                            {stringTruncate(selectedRunList[index0]?.name, 14)}
                          </span>
                          </Tooltip>:<span className="cmnrun-cmpr-title">
                            {selectedRunList[index0]?.name}
                          </span>}
                        </div>
                        <div className="col-4">
                        {selectedRunList.length>0?<div className={selectedRunList[index0]?.runType?.toString()==='Analysis'?'cmrun-primarybtn':'cmrun-pribtn'}>
                            <span className="cmrun-pritext">{selectedRunList[index0]?.runType?.toString().toUpperCase()}</span>{" "}
                          </div>:''}
                        </div>
                        <div className="col-2">
                          <img
                            className="cmnrun-closeicon"
                            onClick={() => this.handleCloseAction("first")}
                            src={closeIcon}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="row commtool-date-label">
                        <span>{selectedRunList[index0]?.period}</span>
                      </div>
                      <div className="row commtool-desc-label">
                        {selectedRunList[index0]?.qualificationProgram + ', ' +selectedRunList[index0]?.calculationProgram + ', ' + selectedRunList[index0]?.disbursementProgram + ' '}
                        |{' Created on '+ Moment(selectedRunList[index0]?.created).format('MM/DD/YYYY HH:mm:ss')}
                        {/* {selectedRunList[index0]?.programs.map((data,i)=>(
                          ((selectedRunList[index0].programs.length-1)!==i)? ''+data.id+', ':''+data.id+' '
                        ))}|{' Created on '+ selectedRunList[index0]?.created} */}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="marginleft-commtool-box remove-col-padding">
                <div className="commtool-compare-box-ui">
                  {addAction2 ? (
                    <>
                      <div className="row commtool-close">
                      </div>
                      <div className="addrun-margintop30"
                        onClick={() => this.handlAddAction("second")}
                      >
                        <img
                          className="comm-add-icon-style"
                          src={addIcon}
                          alt=""
                        />
                        <span className="commtool-textalign">
                          {Constants.COMMTOO_TEXT_ADD_RUN_TO_COMPARE}
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="row" style={{ marginTop: "8px" }}>
                        <div className="col-6" style={{ display: 'flex',paddingRight:'0px' }}>
                        {selectedRunList[index1]?.name.length > 15?
                        <Tooltip title= {selectedRunList[index1]?.name} placement="top" arrow={true}> 
                          <span className="cmnrun-cmpr-title">
                          {stringTruncate(selectedRunList[index1]?.name, 14)}
                          </span>
                         </Tooltip> : <span className="cmnrun-cmpr-title">
                            {selectedRunList[index1]?.name}
                          </span> }
                        </div>
                        <div className="col-4">
                        {selectedRunList.length>0? <div className={selectedRunList[index1]?.runType?.toString()==='Preliminary'?'cmrun-pribtn':'cmrun-primarybtn'}>
                            <span className="cmrun-pritext">{selectedRunList[index1]?.runType?.toString().toUpperCase()}</span>{" "}
                          </div>:''}
                        </div>
                        <div className="col-2">
                          <img
                            className="cmnrun-closeicon"
                            onClick={() => this.handleCloseAction("second")}
                            src={closeIcon}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="row commtool-date-label">
                        <span>{selectedRunList[index1]?.period}</span>
                      </div>
                      <div className="row commtool-desc-label">
                      {selectedRunList[index1]?.qualificationProgram + ', ' +selectedRunList[index1]?.calculationProgram + ', ' + selectedRunList[index1]?.disbursementProgram + ' '}
                        |{' Created on '+ Moment(selectedRunList[index1]?.created).format('MM/DD/YYYY HH:mm:ss')}
                        {/* icebon1, icebon2, icebon3 | Created on 12 Jan 2020 */}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            {compareEnabled ? (
              <div>
                <span className="commrun-addrun-infotext">{Constants.COMMTOOL_ADD_RUN_COMPARE_INFO}</span>
              </div>
            ) : (
              <div className="row commtool-comparerun-table">
                <div className="comparerun-menu-view">
                  <div className={selectedRunList.length > 1 ? "row comparemenu-st1": "row comparemenu-st1 hidden-div"}>
                    <div className="row col-10">
                    <div className="col-3 comparetool-options-show">
                      <img
                        className="cmprnmenu-image"
                        onClick={this.showRunDifferences.bind(this)}
                        src={showdifferences}
                        alt=""
                      />
                    </div>
                    <div className="col-3 comparetool-options-show compare-col-adjust" >
                    <img
                    className="cmprnmenu-image"
                    onClick={this.swaprunsClick.bind(this)}
                    src={swapruns}
                    alt=""
                  />
                      </div>
                      <div className="col-4 comparetool-options-show compare-col-downloadadjust" >
                        {/* <CSVLink {...csvReport}
                        >
                          <img
                          className="cmprnmenu-image"
                          onClick={this.downloadReport.bind(this)}
                          src={downlinereport}
                          alt=""></img>
                        </CSVLink> */}
                         <img
                          className="cmprnmenu-image"
                          onClick={this.downloadReport.bind(this)}
                          src={downlinereport}
                          alt=""></img>
                      </div>
                    </div>
                    <div className="cmprun-back-btn col-2">
                      {/* {isrunCompareFromList ? (
                        <div
                          className="cmcolb-no-btn column"
                          style={{ width: "165px", cursor:'pointer' }}
                          onClick={this.gobacktoCommissionTools.bind(this)}
                        > */}
                          {/* {" "}
                          <div
                            className="colb-no-text"
                            style={{ width: "165px" }}
                          >
                            {Constants.GOTO_COMPARETOOL}
                          </div>{" "} */}
                        {/* </div>
                      ) : (
                        <div
                          className="cmcolb-no-btn column"
                          style={{ width: "68px" }}
                          onClick={this.gobacktoCommissionTools.bind(this)}
                        >
                          {" "}
                          <div
                            className="colb-no-text"
                            style={{ width: "68px" }}
                          >
                            BACK
                          </div>{" "}
                        </div>
                      )} */}
                    </div>
                  </div>
                </div>
                <TableContainer>
                {selectedRunList.length>1? <Table style={{ backgroundColor: "white" }}>
                    <TableHead className="commtool-compare-header">
                      {/* {compareTableHeaders.map((headers, i) => ( */}
                        <TableRow>
                          <TableCell className="comprun-headerset">{'No.'}</TableCell>
                          <TableCell className="comprun-headerset">{'Description'}</TableCell>
                          <TableCell className="comprun-header-description comprun-headerset">{'Run Name'}</TableCell>
                          <TableCell className={showValDifferences? "comprun-header-witharrownames comprun-headerset ":"comprun-header-runnames comprun-headerset"}>{'Recipients'}</TableCell>
                          <TableCell className={showValDifferences? "comprun-header-witharrownames comprun-headerset":"comprun-header-runnames comprun-headerset"}>{'Avg. Payout'}</TableCell>
                          <TableCell className={showValDifferences? "comprun-header-witharrownames comprun-headerset":"comprun-header-runnames comprun-headerset"}>{'Total Payout'}</TableCell>
                          <TableCell className={showValDifferences? "comprun-header-witharrownames-dropdown comprun-headerset":"comprun-header-runnames-dropdown comprun-headerset"}>{this.percentDropdown(l)}</TableCell>
                        </TableRow>

                      {/* ))} */}
                    </TableHead>
                    <TableBody>
                      {/* {console.log("resutl is : ",selectedRunList[l])} */}
                      {selectedRunList.length>1 && selectedRunList[0].payouts.length>0 && selectedRunList[1].payouts.length>0 && selectedRunList[l]?.payouts?.length>0 && 
                      selectedRunList[l]?.payouts.map((data, j) => {
                        let secondElement,firstElement;
                        if(l===0){
                          console.log("handling first is big l==0");
                          secondElement = selectedRunList[1].payouts.length>0 && selectedRunList[1].payouts.findIndex(function (payoutList) {
                            return payoutList["description"] == data?.description;
                          });
                          firstElement=j;
                        }else{
                          console.log("handling first is big l==1");
                          firstElement = selectedRunList[0].payouts.length>0 && selectedRunList[0].payouts.findIndex(function (payoutList) {
                            return payoutList["description"] == data?.description;
                          });
                          secondElement=j;
                        } 
                        console.log("result desc : ",firstElement,secondElement);
                        // totalAvarageFirst += Number(selectedRunList[0]?.payouts[j]?.recipients!==0? selectedRunList[0]?.payouts[j]?.payout/selectedRunList[0]?.payouts[j]?.recipients:0).toString().split(".").map((el,i)=>i?el.split("").slice(0,2).join(""):el).join(".");
                        return (
                          <TableRow rowSpan={2}>
                            <TableCell className="comparerun-type">
                              {selectedRunList[l]?.payouts[j]?.commissionTypeId}
                            </TableCell>
                            <TableCell className="comparerun-name">
                              {selectedRunList[l]?.payouts[j]?.description}
                            </TableCell>
                            <TableCell className="comparerun-data-runname">
                              <div className="comprun-firest-elment-name">
                                { firstElement!==-1? stringTruncate(selectedRunList[0]?.name,20):''}
                              </div>
                              <div className="comprun-second-elment-name">
                              {secondElement!==-1? <div>{stringTruncate(selectedRunList[1]?.name,20)}</div>:''}
                              </div>
                            </TableCell>
                            <TableCell className="comparerun-data-status">
                              <div className="comprun-firest-elment">
                                { firstElement!==-1? allNullTypeCheck(selectedRunList[0]?.payouts[firstElement])? selectedRunList[0]?.payouts[firstElement]?.recipients:0 : ''}
                              </div>
                              <div className="comprun-second-elment">
                                {secondElement!==-1?
                                (selectedRunList[0]?.payouts?.length>0 && selectedRunList[1]?.payouts?.length>0) && ((selectedRunList[0]?.payouts[firstElement]?.recipients) <= (selectedRunList[1]?.payouts[secondElement]?.recipients))?
                                ((selectedRunList[0]?.payouts[firstElement]?.recipients)===(selectedRunList[1]?.payouts[secondElement]?.recipients))?
                                <div className="comprun-without-arrows">{selectedRunList[1]?.payouts[secondElement]?.recipients}</div>:
                                <div className="arrow-display-content">
                                  <span className={showValDifferences && firstElement!==-1?"text-with-uparrow":"comprun-without-arrows"}>{selectedRunList[1]?.payouts[secondElement]?.recipients}</span>
                                  {showValDifferences && firstElement!==-1?<img
                                    className="uparrow-img-display"
                                    src={upArrow}
                                    alt=""
                                  />:''}
                                </div>:
                                <div>
                                  {console.log("result second element is : ",selectedRunList)}
                                   {selectedRunList.length>1 || selectedRunList[1]?.payouts?.length>0?<>
                                   <span className={showValDifferences && firstElement!==-1? "text-with-downarrow":"comprun-without-arrows"}>{selectedRunList[1]?.payouts[secondElement]?.recipients}</span>
                                   {showValDifferences && firstElement!==-1?<img
                                    className="uparrow-img-display"
                                    src={downArrow}
                                    alt=""
                                  />:''}
                                  </>:''}
                                  </div>: ''}
                              </div>
                            </TableCell>
                            <TableCell className="comparerun-data-status">
                              <div className="comprun-firest-elment">
                                { firstElement!==-1? numberWithCommas((parseFloat(((allNullTypeCheck(selectedRunList[0]?.payouts[firstElement]) && (selectedRunList[0]?.payouts?.length>0 && selectedRunList[0]?.payouts[firstElement]?.recipients !== 0))?
                                 (selectedRunList[0]?.payouts[firstElement]?.payout /
                                    selectedRunList[0]?.payouts[firstElement]?.recipients)
                                  : '0.00'
                                )
                                  .toString()
                                  .split(".")
                                  .map((el, i) =>
                                    i ? el.split("").slice(0, 2).join("") : el
                                  )
                                  .join(".")))?.toFixed(2)): ''}
                              </div>
                              <div className="comprun-second-elment">
                                {selectedRunList.length>1 && secondElement!==-1? this.getAvarageValue(l,firstElement,secondElement):''}
                                
                              </div>
                            </TableCell>
                            <TableCell className="comparerun-data-status">
                              <div className="comprun-firest-elment">
                              { firstElement!==-1? allNullTypeCheck(selectedRunList[0]?.payouts[firstElement])? numberWithCommas(selectedRunList[0]?.payouts[firstElement]?.payout?.toFixed(2)):"0.00" : ''}
                              </div>
                              <div className="comprun-second-elment">
                                {secondElement!==-1?
                                (selectedRunList.length>1 && selectedRunList[0]?.payouts?.length>0 && selectedRunList[1]?.payouts?.length>0) && (selectedRunList[0]?.payouts[firstElement]?.payout)<=(selectedRunList[1]?.payouts[secondElement]?.payout)?
                                (selectedRunList[0]?.payouts[firstElement]?.payout)===(selectedRunList[1]?.payouts[secondElement]?.payout)?
                                <div className="comprun-without-arrows">{selectedRunList.length>1? numberWithCommas(selectedRunList[1]?.payouts[secondElement]?.payout?.toFixed(2)):''}</div>:
                                <div className="arrow-display-content">
                                  <span className={showValDifferences && firstElement!==-1?"text-with-uparrow":"comprun-without-arrows"}>{selectedRunList.length>1? numberWithCommas(selectedRunList[1]?.payouts[j]?.payout?.toFixed(2)):'0.00'}</span>
                                  {showValDifferences && firstElement!==-1?<img
                                    className="uparrow-img-display"
                                    src={upArrow}
                                    alt=""
                                  />:''}
                                </div>:
                                <div>
                                  {(selectedRunList.length>1 || selectedRunList[1]?.payouts?.length>0)?
                                   <><span className={showValDifferences && firstElement!==-1?"text-with-downarrow":"comprun-without-arrows"}>{selectedRunList.length>1? numberWithCommas(selectedRunList[1]?.payouts[secondElement]?.payout?.toFixed(2)):'0.00'}</span>
                                  {showValDifferences && firstElement!==-1?<img
                                    className="uparrow-img-display"
                                    src={downArrow}
                                    alt=""
                                  />:''}
                                  </>:''}
                                  </div>:''}
                              </div>
                            </TableCell>
                            <TableCell className="comparerun-data-status">
                              <div className="comprun-firest-elment">
                              { (firstElement!==-1 && selectedPercentValue!=='')? allNullTypeCheck(selectedRunList[0]?.payouts[firstElement])? 
                                selectedRunList[0]?.payouts[firstElement]?.percentBasedOn[selectedRunList[0]?.payouts[firstElement]?.percentBasedOn?.findIndex((data) =>	{				
                                 return data?.description === selectedPercentValue })]?.percent?.toFixed(2)
                                 :'0.00':''
                              }
                              </div>
                              <div className="comprun-second-elment">  
                              { (secondElement!==-1 && selectedPercentValue!=='')? ((selectedRunList[0]?.payouts.length>0 && selectedRunList[1]?.payouts?.length>0) && selectedRunList[1]?.payouts[secondElement]?.percentBasedOn?.length>0)? this.getPercentValues(l,firstElement,secondElement):'':''}
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {selectedRunList.length>0? <TableRow rowSpan={2}>
                        <TableCell colSpan={2} className="comparerun-total">
                          {"Total"}
                        </TableCell>
                        <TableCell className="comparerun-data-runname">
                          <div className="comprun-firest-elment-name">
                            {stringTruncate(selectedRunList[0]?.name,20)}
                          </div>
                          <div className="comprun-second-elment-name">
                          <div>{ stringTruncate(selectedRunList[1]?.name,20)}</div>
                          </div>
                        </TableCell>
                        <TableCell className="comparerun-data-status">
                          <div className="comprun-firest-elment">
                            {selectedRunList[0]?.payouts?.reduce(
                              (previousValue, currentValue) =>
                                previousValue + currentValue.recipients,
                              initialValue
                            )}
                          </div>
                          <div className="comprun-second-elment">
                              {(selectedRunList[0]?.payouts?.reduce(
                                  (previousValue, currentValue) =>
                                    previousValue + currentValue.recipients,
                                  initialValue
                                ))<=(selectedRunList[1]?.payouts?.reduce(
                                  (previousValue, currentValue) =>
                                    previousValue + currentValue.recipients,
                                  initialValue
                                ))?
                                (selectedRunList[0]?.payouts?.reduce(
                                  (previousValue, currentValue) =>
                                    previousValue + currentValue.recipients,
                                  initialValue
                                ))===(selectedRunList[1]?.payouts?.reduce(
                                  (previousValue, currentValue) =>
                                    previousValue + currentValue.recipients,
                                  initialValue
                                ))?
                                <div className="comprun-without-arrows">{selectedRunList[1]?.payouts?.reduce(
                                  (previousValue, currentValue) =>
                                    previousValue + currentValue.recipients,
                                  initialValue
                                )}</div>:
                                <div className="arrow-display-content">
                                  <span className={showValDifferences?"text-with-uparrow":"comprun-without-arrows"}>{selectedRunList[1]?.payouts?.reduce(
                                    (previousValue, currentValue) =>
                                      previousValue + currentValue.recipients,
                                    initialValue
                                  )}</span>
                                  {showValDifferences?<img
                                    className="uparrow-img-display"
                                    src={upArrow}
                                    alt=""
                                  />:''}
                                </div>:
                                <div>
                                 {selectedRunList.length>1?
                                 <> <span className={showValDifferences?"text-with-downarrow":"comprun-without-arrows"}>{selectedRunList[1]?.payouts?.reduce(
                                      (previousValue, currentValue) =>
                                        previousValue + currentValue.recipients,
                                      initialValue
                                    )}</span>
                                  {showValDifferences?<img
                                    className="uparrow-img-display"
                                    src={downArrow}
                                    alt=""
                                  />:''}
                                  </>:''}
                                  </div>}

                          </div>
                        </TableCell>
                        <TableCell className="comparerun-data-status">
                          <div className="comprun-firest-elment">
                            {numberWithCommas(totalAvarageFirst?.toFixed(2))}
                          </div>
                          <div className="comprun-second-elment">
                            {(totalAvarageFirst)<=(totalAvarageSecond)?
                                (totalAvarageFirst)===(totalAvarageSecond)?
                                <div className="comprun-without-arrows">{numberWithCommas(totalAvarageSecond?.toFixed(2))}</div>:
                                <div className="arrow-display-content">
                                  <span className={showValDifferences?"text-with-uparrow":"comprun-without-arrows"}>{numberWithCommas(totalAvarageSecond?.toFixed(2))}</span>
                                  {showValDifferences?<img
                                    className="uparrow-img-display"
                                    src={upArrow}
                                    alt=""
                                  />:''}
                                </div>:
                                <div>
                                  {selectedRunList.length>1?
                                    <><span className={showValDifferences?"text-with-downarrow":"comprun-without-arrows"}>{numberWithCommas(totalAvarageSecond?.toFixed(2))}</span>
                                  {showValDifferences?<img
                                    className="uparrow-img-display"
                                    src={downArrow}
                                    alt=""
                                  />:''}
                                  </>:''}
                                  </div>}
                          </div>
                        </TableCell>
                        <TableCell className="comparerun-data-status">
                          <div className="comprun-firest-elment">
                            {numberWithCommas((selectedRunList[0]?.payouts?.reduce(
                              (previousValue, currentValue) =>
                                previousValue + currentValue.payout,
                              initialValue
                            ))?.toFixed(2))}
                          </div>
                          <div className="comprun-second-elment">
                            {(selectedRunList[0]?.payouts?.reduce(
                              (previousValue, currentValue) =>
                                previousValue + currentValue.payout,
                              initialValue
                            ))<=(selectedRunList[1]?.payouts?.reduce(
                              (previousValue, currentValue) =>
                                previousValue + currentValue.payout,
                              initialValue
                            )?.toFixed(2))?
                                (selectedRunList[0]?.payouts?.reduce(
                                  (previousValue, currentValue) =>
                                    previousValue + currentValue.payout,
                                  initialValue
                                ))===(selectedRunList[1]?.payouts?.reduce(
                                  (previousValue, currentValue) =>
                                    previousValue + currentValue.payout,
                                  initialValue
                                ))?
                                <div className="comprun-without-arrows">{numberWithCommas((selectedRunList[1]?.payouts?.reduce(
                                  (previousValue, currentValue) =>
                                    previousValue + currentValue.payout,
                                  initialValue
                                ))?.toFixed(2))}</div>:
                                <div className="arrow-display-content">
                                  <span className={showValDifferences?"text-with-uparrow":"comprun-without-arrows"}>{numberWithCommas((selectedRunList[1]?.payouts?.reduce(
                                    (previousValue, currentValue) =>
                                      previousValue + currentValue.payout,
                                    initialValue
                                  ))?.toFixed(2))}</span>
                                  {showValDifferences?<img
                                    className="uparrow-img-display"
                                    src={upArrow}
                                    alt=""
                                  />:''}
                                </div>:
                                <div>
                                  {selectedRunList.length>1?
                                  <><span className={showValDifferences?"text-with-downarrow":"comprun-without-arrows"}>{numberWithCommas((selectedRunList[1]?.payouts?.reduce(
                                      (previousValue, currentValue) =>
                                        previousValue + currentValue.payout,
                                      initialValue
                                    ))?.toFixed(2))}</span>
                                  {showValDifferences?<img
                                    className="uparrow-img-display"
                                    src={downArrow}
                                    alt=""
                                  />:''}
                                  </>:''}
                                  </div>}
                          </div>
                        </TableCell>
                        <TableCell className="comparerun-data-status">
                          <div className="comprun-firest-elment">
                            {(selectedRunList.length>0 && selectedRunList[0]?.payouts.length>0 && selectedRunList[0]?.payouts[l]?.percentBasedOn.length>0)? numberWithCommas(selectedRunList[0]?.payouts?.reduce((previousValue, currentValue) =>
                               previousValue + currentValue?.percentBasedOn[currentValue.percentBasedOn.findIndex((pecentValue) => {
                                  return (pecentValue.description === selectedPercentValue)}
                              )]?.percent,
                              initialValue
                            ).toFixed(2)):''}
                          </div>
                          <div className="comprun-second-elment">
                          {(selectedRunList.length>0 && selectedRunList[1]?.payouts.length>0 && selectedRunList[1]?.payouts[l]?.percentBasedOn.length>0)? this.percentTotalValue():''}
                          </div>
                        </TableCell>
                      </TableRow>:''}
                    </TableBody>
                  </Table>:''}
                </TableContainer>
                {/* {isdisplayCompareRuns ? (
              <AddCompareModal
               addCompareBackfunc={this.handleAddCompareRunsModal.bind(this)} 
               // newrunmodelbackfunc={this.handleNewrunCreateModel.bind(this)}
                commissionTypeValue={selectedValue}
                selectedRun={this.selectedRunHandler.bind(this)}
              />
             ) : (
              ""
            )} */}
              </div>
            )}
              {isdisplayCompareRuns ? (
              <AddCompareModal
               addCompareBackfunc={this.handleAddCompareRunsModal.bind(this)} 
               // newrunmodelbackfunc={this.handleNewrunCreateModel.bind(this)}
                commissionTypeValue={selectedValue}
                selectedRun={this.selectedRunHandler.bind(this)}
              />
             ) : (
              ""
            )}
          </div>
        </div>
      );
    }
    return (
      <div>
        <div id="center" style={{ position: "fixed", top: "40%", left: "48%" }}>
          <Oval
            type="Puff"
            position="fixed"  secondaryColor="#E85557"
            color="#E85557"
            height={50}
            width={100}
            visible={true}
          />{" "}
        </div>
      </div>
    );
  }
 }
}
const mapStateToProps = (state) => {
  return {
    commissionRunList: state.commissionRunReducer.commissionRunList,
    commissionRunDetails: state.commissionRunReducer.commissionRunDetails,
    commissionTypeError: state.commissionToolsReducer.commissionTypeError,
    commissionTypeList: state.commissionToolsReducer.commissionTypeList, 
  };
};
const mapDispatchToProps = (dispatch) => ({
  getCommissionRunsList: () => dispatch(getCommissionRunAPIList()),
  getcommissionRunDetails: (runID) => dispatch(getCommRunDetails(runID)),
  getCommissionTypes:() => dispatch(getCommissionTypeListAPI()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CommToolCompareRun));
