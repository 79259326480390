import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { Formik, Form, Field } from 'formik'
import { useDispatch, useSelector } from 'react-redux';
import './CommRankAdvancement.css'
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { Input } from 'reactstrap';
import { allNullTypeCheck, isNullResponse } from '../../commonComponents/UtilityFun'
import leftarrow from "../../assets/leftarrow.svg";
import rightarrow from "../../assets/rightarrow.svg";
import externalLink from '../../assets/externalLinkgray.svg'
import * as Constants from '../constants';
import Endpoints from '../../../utilities/Endpoints';
import { Oval } from "react-loader-spinner";
import FormikControl from '../DynamicReports/FormikControl';
import { REPORT_PARAMS, UPDATED_REPORT_PARAMS } from '../actions';
import NoRecordsFoundImage from '../../../Commissions/assets/NoRecords.svg'

function CommRankAdvancement({ heading, category, displayType, reportsPams }) {
  const [selectedCommissionPeriodIndex, setSelectedCommissionPeriodIndex] = useState(0);
  const [commissionPeriods, setCommissionPeriods] = useState(0);
  const [anchorEl, setAnchorEl] = useState(false);
  //const [paramsData, setparamsData] = useState({});
  // const heading="Commission Rank  Advancement";
  const [displayPerioPop, setDisplayPerioPop] = useState(false);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const dispatch = useDispatch()
  const data = useSelector(state => state.multiplePeriodsReducer)
  const periodsData = useSelector(state => state.periodTypesReducer)
  const htmlParamsUpdate = useSelector(state =>state.reportsMainReducer.htmlParamUpdateLeftMenu)
  const paramsData = useSelector(state => state.reportsMainReducer.reportParamRes)
  const history = useHistory();
  const [loader, setloader] = useState(true);
  const [reportHeading, setreportHeading] = useState(heading)
  const [reportCategory, setreportCategory] = useState(category)
  const [updatedParams, setUpdatedParams]=useState([]);

  
  useEffect(()=>{
    console.log("windowns parasm call",reportsPams)
    if(isNullResponse(reportsPams)){
      dispatch({ type: REPORT_PARAMS, payload: reportsPams })
    }
  },[reportsPams])

  useEffect(()=>{
    setreportHeading(heading);
    setreportCategory(category);
  },[heading,category])

useEffect(()=>{
    if(isNullResponse(htmlParamsUpdate)){
      dispatch({ type: REPORT_PARAMS, payload: htmlParamsUpdate })
    }
  },[htmlParamsUpdate])

  const handleNavLinkClick = (path) => {
    if (history.location.pathname === '/reports/') history.push(path)
    else history.push(`/reports/${path}`)
  }
  const NoRecordsFound = () => (
    <div className='no-items-found'>
      <img src={NoRecordsFoundImage}  />
    </div>
  )

  const onSubmit = (values, onSubmitProps) => {
    console.log('submitted', values)
    dispatch(updateSinglePeriodsData(values))
    onSubmitProps.resetForm({
      values: data.data
    })
  }
  const getPreviousPeriod = () => {
    setSelectedCommissionPeriodIndex(selectedCommissionPeriodIndex - 1);
    updateSelectPeriod(selectedCommissionPeriodIndex + -1);
  }
  const getNextPeriod = () => {
    setSelectedCommissionPeriodIndex(selectedCommissionPeriodIndex + 1);
    updateSelectPeriod(selectedCommissionPeriodIndex + 1);
  }
  const handleClick = (event, selectedRun) => {
    // this.setState({ errorResultIs: false });
    setAnchorEl(event.currentTarget)
    setDisplayPerioPop(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const updateSelectPeriod = (index) => {
    // const { commissionPeriods } = this.state;
    if (allNullTypeCheck(commissionPeriods) && commissionPeriods.length > 0) {
      // sessionStorage.setItem("period",getMonthDays(commissionPeriods[index]?.name))
      // this.setState({ selectPeriodVal: getMonthDays(commissionPeriods[index]?.name)});
      // this.selectPeriodIndex(getMonthDays(commissionPeriods[index]?.name));
    }
  }
  const handleExternalLink = () => {
    console.log("current path :", history.location.pathname);
    openInNewTab('/commissions/reports/reportsnewtab');
  }
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  const handleCommRankContainer = () => {
    console.log("current path :", history.location.pathname);
    console.log("reportsId :", reportHeading);
   
    history.push({
      pathname: 'run/runReports/runcontainer',
      state: {
        heading: reportHeading,
        category: reportCategory,
        navParams:paramsData
      }
    });
  }
  const handlePropChanges=(e,type,name,label)=>{
    console.log('fieldvalue check', e, type, name, label)
     // fieldValue1(name,e.target.value)
      console.log("selected values : ",e.target.value);
      setUpdatedParams([]);
      Object.keys(paramsData).map((element)=>{
     switch(type){
      case 'String':
            return (paramsData[element]._name===name||paramsData[element]._label===label)? (paramsData[element]._value = e.target.value):'';
      case 'SingleChoice':
            return (paramsData[element]._name===name||paramsData[element]._label===label)? paramsData[element]._value = e.target.value :'';
      case 'Number':
        return (paramsData[element]._name===name||paramsData[element]._label===label)? paramsData[element]._value= e.target.value :'';
      case 'Boolean': 
            return (paramsData[element]._name===name||paramsData[element]._label===label)? paramsData[element]._value = e.target.value:'';
      case 'Date':
        return (paramsData[element]._name===name||paramsData[element]._label===label)? paramsData[element]._value = e.target.value:'';
        default:
          return paramsData;
     }
   })
   console.log("parameters after update : ",paramsData);
   
  }
  useEffect(()=>{
    console.log("updated value: ",updatedParams);
    dispatch({ type: UPDATED_REPORT_PARAMS, payload: updatedParams })
    // dispatch({ type: REPORT_PARAMS, payload: updatedParams })
  },[updatedParams])
  
  const handleFilterRun =()=>{
    setUpdatedParams(paramsData);
   
  }
  
    return (
      <div className='reports-dashboard'>
        <div className='reports-heading'>
          <div className='report-titlesize' style={{ lineHeight: '22px' }}>{`${heading}`}</div>
        </div>
        {/* { Object.keys(paramsData)?.length > 0 ?  */}
        <div className='report-rank-advance-container'>
          <Formik onSubmit={onSubmit} enableReinitialize>
            {formik => {
              const { dirty, values, setFieldValue, errors } = formik
              return (
                <Form>
                  <div className='report-comm-container'>
                    {isNullResponse(paramsData) && Object.keys(paramsData).map((fields, i) => (
                      // console.log('paramsData?.data[fields]',{...paramsData[fields]}, paramsData[fields]._type)
                      <FormikControl name={paramsData[fields]._name} key={i} control={paramsData[fields]._type} onChange={handlePropChanges}
                     {...paramsData[fields]}/>
                    ))}
                    <div className='period-footer addpadding' style={{paddingLeft:'0px',borderTop: "1px solid #DDDDDD"}}>
                    <div className='checkbox-group addpadding'>
                    <button
                        className='addpadding portal-button-1'
                        type='reset'
                        onClick={heading===Constants.REPORT_LEFTNAV_TITLE? handleFilterRun: handleCommRankContainer}
                         // disabled={!dirty}
                      >
                        Run
                      </button>

                    </div>
                    {heading===Constants.REPORT_LEFTNAV_TITLE? '' : <div>
                    <img disabled={!dirty} src={externalLink} style={{cursor:'pointer'}} onClick={handleExternalLink} alr=""/>
                    </div>}
                    {/* <div className='button-group'>
                      <button
                        className='portal-button-2'
                        type='reset'
                        disabled={!dirty}
                      >
                        cancel
                      </button>
                      <button
                        className='portal-button-1'
                        type='submit'
                        disabled={!dirty}
                      >
                        GENERATE
                      </button>
                    </div> */}
                  </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
          </div>
        {/* </div> : <NoRecordsFound/>} */}
      </div>
    )
}
export default React.memo(CommRankAdvancement);