import { valueValidation } from "../../utilities/Services";
import jwt from 'jwt-decode';
import { allNullTypeCheck } from "../commonComponents/UtilityFun";
import AuthService from "../../server/AuthService";

export function getLoginCleintName() {
    const id_token = localStorage.getItem('id_token');
    if (id_token !== "undefined" && valueValidation(id_token)) {
      const client = jwt(id_token);
     return client.hasOwnProperty('username')? client.username: '';
    }
    else {
      console.log('Token is empty')
    }
  }

  export const getCleintNameEnv=()=> {
    const id_token = localStorage.getItem('id_token');
    if (AuthService.isTokenValid() !== true) {
      const user = AuthService.signinSilentToken();
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
      if (user) {
        localStorage.setItem('id_token', user.id_token);
      }
    }
    if (AuthService.isTokenValid() === true && allNullTypeCheck(id_token)) {
      const client = jwt(id_token);
      console.log("token values res: ",client)
      // this.setState({ logoName: this.nameProfileText(client.client_name) })
      return `https://${client.client_name}.flexcloud.com/serv-reports/`
    }
  }