export const IDENTITY_CONFIG = {
  authority: process.env.REACT_APP_IDENTITY_BASE_URL,
  client_id: process.env.REACT_APP_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_IDENTITY_REDIRECT_URL+'/callback',
  response_type: 'id_token',
  scope: 'openid profile',
  post_logout_redirect_uri: process.env.REACT_APP_IDENTITY_REDIRECT_URL+'/callback',
  silent_redirect_uri: process.env.REACT_APP_IDENTITY_REDIRECT_URL+'/renew',


};
