import userDashboardReducer from "./userDashboardReducer";
import userSettingsReducer from "./userSettingsReducer";
import usersInviteReducer from "./usersInviteReducer";
import usersUpdateReducer from "./usersUpdateReducer";

const userModuleReducer = {
    userDashboardReducer,
    userSettingsReducer,
    usersInviteReducer,
    usersUpdateReducer   
}
export default userModuleReducer
