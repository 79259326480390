import React from 'react';
import '../ViewRunLog/ViewRunLog.css'
import PropTypes from 'prop-types';
import AuthService from '../../../../src/components/server/AuthService';
import '../CommissionsRunManager/CommissionRunManager.css';
import { Oval } from 'react-loader-spinner';
import { connect } from 'react-redux';
import { Modal } from "react-bootstrap";
import closeIcon from '../assets/closeicon.svg';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withRouter } from 'react-router-dom';
import { getDistributorVolumes, getRankMatrixInfoAPI } from '../actions';
import * as Constant from '../../../constants/Constants';
import { allNullTypeCheck, isNullResponse } from '../commonComponents/UtilityFun';
import NewSnackbar from "../../utilities/NewSnackbar";
import actions from '../../../redux/actions/CommonDataActions';
import { numberWithCommas } from "../../utilities/Services";
import vector13 from '../../../assets/vector-13@2x.png';
import "./GeneralTab.css";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

let initialValue = 0.0;

const fields = [
    { name: 'ChequeNo', displayName: "Paid Rank", inputFilterable: true, sortable: true },
    { name: 'DistID', displayName: "Bv", inputFilterable: false, exactFilterable: true, sortable: true },
    { name: 'Name', displayName: "Org Volume", inputFilterable: false, exactFilterable: true, sortable: true },
    { name: 'CountryCode', displayName: "Active Leg", inputFilterable: true, sortable: true },
    { name: 'ChequeDate', displayName: "Org Volume", inputFilterable: false, exactFilterable: true, sortable: true },
    { name: 'DistType', displayName: "Org Volume-1", inputFilterable: false, exactFilterable: true, sortable: true },
];
class GeneralTabRankMatrix extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedValue: 'Primary',
            selectPeriodVal: '00/0000',
            selectedCommissionPeriodIndex: 0,
            commissionTypes: [],
            commissionRuns: [],
            selectedCommissionType: 'Primary',
            commissionPeriodData: null,
            commissionPeriods: [],
            dataObj: [],
            previousDisabled: false,
            nextDisabled: false,
            anchorEl: false,
            rankMatrixListState: [],
            selectedRunInfo: [],
            showLoaderResult: false,
            rankSelectionType:'',
            fieldMatrixListState:[],
            tabValue: 0,
            voluemListState:[],
            retryDelayTime:1,

        }
        //  this.viewLogTableDisplay = this.viewLogTableDisplay.bind(this);
    }

    componentDidMount() {
        const { distributorID, runID } = this.props;
        if (allNullTypeCheck(distributorID) && allNullTypeCheck(runID)) {
            this.setState({showLoaderResult: true, rankSelectionType:"option1"});
            this.props.getRankMatrix(runID);
            this.props.getVolumeList(distributorID, runID);
        }
    }
    componentDidUpdate(nextProps) {
        const { rankMatrixDetailsList, rankMatrixDetailsError, distVolumeList, distVolumeError, distributorID, runID } = this.props;
        const { retryDelayTime } = this.state;
        if (rankMatrixDetailsList !== nextProps.rankMatrixDetailsList){
            console.log("response is : ",rankMatrixDetailsList);
            this.setState({showLoaderResult: false });
            if(isNullResponse(rankMatrixDetailsList?.data) && rankMatrixDetailsList?.error === false) {
                this.setState({ rankMatrixListState: rankMatrixDetailsList?.data?.ranks, fieldMatrixListState : rankMatrixDetailsList?.data?.qualificationFields});
            } else if (Object.keys(rankMatrixDetailsList?.data).length === 0 && allNullTypeCheck(rankMatrixDetailsList?.messages)) {
                if (rankMatrixDetailsList?.messages?.length > 0 && rankMatrixDetailsList?.messages[0]?.includes("Try again...")) {
                    console.log("Try again block entered : ", rankMatrixDetailsList?.data);
                    this.setState({ showLoaderResult: true });
                    setTimeout(() => {
                        this.props.getRankMatrix(runID);
                    }, 1000 * retryDelayTime);
                    this.setState(prevState => {
                        return { retryDelayTime: prevState.retryDelayTime * 2 }
                    })
                }
            }
           else if(rankMatrixDetailsList.error === true){
              this.setState({showLoaderResult: false });
                this.props.showSnackbar({snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: rankMatrixDetailsList?.messages[0]?.code +' - '+rankMatrixDetailsList?.messages[0]?.detail})
            }
        }
        else if(rankMatrixDetailsError !== nextProps.rankMatrixDetailsError){
            this.setState({showLoaderResult: false });
            console.log("error is : ",rankMatrixDetailsError)
            if(rankMatrixDetailsError?.data?.error === true){
                this.props.showSnackbar({snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: typeof(rankMatrixDetailsError?.data?.messages[0])!== 'string'? rankMatrixDetailsError?.data?.messages[0]?.code +' - '+rankMatrixDetailsError?.data?.messages[0]?.detail : rankMatrixDetailsError?.data?.messages[0]})
               }
        }
        if (distVolumeList !== nextProps.distVolumeList){
            console.log("response is : ",distVolumeList);
            this.setState({showLoaderResult: false });
            if(!distVolumeList.hasOwnProperty('status')){ 
            if(isNullResponse(distVolumeList?.data) && distVolumeList?.error === false) {
                this.setState({ voluemListState: distVolumeList?.data?.volumes });
            } else if (Object.keys(distVolumeList?.data).length === 0 && allNullTypeCheck(distVolumeList?.messages)) {
                if (distVolumeList?.messages?.length > 0 && distVolumeList?.messages[0]?.includes("Try again...")) {
                    console.log("Try again block entered : ", distVolumeList?.data);
                    this.setState({ showLoaderResult: true });
                    setTimeout(() => {
                        this.props.getVolumeList(distributorID, runID);
                    }, 1000 * retryDelayTime);
                    this.setState(prevState => {
                        return { retryDelayTime: prevState.retryDelayTime * 2 }
                    })
                }
            }
           else if(distVolumeList.error === true){
              this.setState({showLoaderResult: false });
                this.props.showSnackbar({snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: distVolumeList?.messages[0]?.code +' - '+distVolumeList?.messages[0]?.detail})
            }
        }else {
            if(distVolumeList.hasOwnProperty('status') && distVolumeList.status===202){
                this.setState({ showLoaderResult: true });
                setTimeout(() => {
                    this.props.getVolumeList(distributorID, runID);
                }, 1000 * retryDelayTime);
                this.setState(prevState => {
                    return { retryDelayTime: prevState.retryDelayTime * 2 }
                })
          }
        }
        }
        else if(distVolumeError !== nextProps.distVolumeError){
            this.setState({showLoaderResult: false });
            console.log("error is : ",distVolumeError)
            if(distVolumeError?.data?.error === true){
                this.props.showSnackbar({snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: typeof(distVolumeError?.data?.messages[0])!== 'string'? distVolumeError?.data?.messages[0]?.code +' - '+distVolumeError?.data?.messages[0]?.detail : distVolumeError?.data?.messages[0]})
               }
        }
    }

    hideModal() {
        this.props.closefunction()
      }
      confirmModal() {
        this.setState({ showLoaderResult: true });
        this.hideModal();
        this.props.selectedRun(this.state.selectedCommissionRun);
      }  
    handleOnChange=(e)=>{
        const { rankMatrixListState, rankSelectionType } = this.state;
        const { rankMatrixDetailsList, currentRankID } = this.props;
        this.setState({rankSelectionType : e.target.value},()=>{});
        console.log("selected value is : ",e.target.value);
         if(isNullResponse(rankMatrixDetailsList?.data)){
             if(e.target.value === "option2"){
                this.setState({rankMatrixListState : rankMatrixDetailsList?.data?.ranks?.filter((elment)=>(elment.rankId>currentRankID))},()=>{});
             }else {
                this.setState({rankMatrixListState : rankMatrixDetailsList?.data?.ranks});
             }
         }
    }
    handleTabChange = async (event, newValue) => {
        const { rankMatrixDetailsList, currentRankID } = this.props;
		this.setState({ tabValue: newValue});
        if(isNullResponse(rankMatrixDetailsList?.data)){
            if(newValue === 1){
               this.setState({rankMatrixListState : rankMatrixDetailsList?.data?.ranks?.filter((elment)=>(elment.rankId>currentRankID))},()=>{});
            }else {
               this.setState({rankMatrixListState : rankMatrixDetailsList?.data?.ranks});
            }
        }
	};
    colorDesider=(data, qualVal)=>{
        const { voluemListState } = this.state;
        let resultIs = false;
        console.log("map list is ",data,qualVal,voluemListState);
        voluemListState.length>0 && voluemListState.forEach((volume,i)=>{
          if(volume?.label===qualVal?.label){
              console.log("result is ",parseFloat(volume?.value),parseFloat(data?.qualificationValues[data?.qualificationValues?.findIndex((val) =>{
                return val?.name === qualVal?.name
            })]?.value),parseFloat(volume?.value) <= parseFloat(data?.qualificationValues[data?.qualificationValues?.findIndex((val) =>{
                return val?.name === qualVal?.name
            })]?.value))
           if(parseFloat(volume?.value) >= parseFloat(data?.qualificationValues[data?.qualificationValues?.findIndex((val) =>{
                    return val?.name === qualVal?.name
                })]?.value)){
                    resultIs = true;
             }
         }})
         return resultIs;
        
    }
    render() {
        let loader;
        const { showLoaderResult,rankMatrixListState, fieldMatrixListState, tabValue, voluemListState } = this.state;
        if (AuthService.isAuthenticated()) {
        if (showLoaderResult) {
            loader = <div id="center" style={{ position: "fixed", bottom: "40%", left: "48%" }}>
                <Oval
                    type="Puff"
                    position="fixed"  secondaryColor="#E85557"
                    color="#E85557"
                    height={50}
                    width={50}
                    timeout={3000}
                />
            </div>
        }
            return (
                <div className='row com-overlap-group-1'>
                <div className='col-9'>
                <div className="flex">
                    <div className="rowalign-center" style={{ height: '80px' }}>
                    <div className="col-8 h2title" style={{top:"30px"}}>
                           <span>{"Rank Matrix"}</span>
                           <div>
                           <div style={{marginTop:'20px'}}>
                               <div className='rank-matrix-details'><lable className='rank-matrix-label'>{"Distributor ID"}</lable><span className='rank-matrix-values'>{this.props?.distributorID}</span></div>
                               <div className='rank-matrix-details'><lable className='rank-matrix-label'>{"Name"}</lable><span className='rank-matrix-values'>{this.props.rankName}</span></div>
                               <div className='rank-matrix-details'><lable className='rank-matrix-label'>{"Current Rank"}</lable><span className='rank-matrix-values'>{this.props?.currentRankID}</span></div>
                           </div>
                           <div className="row add-item-style rank-radio-container">
                           <div>
                            <Tabs
                                className="rank-matrix-tabs"
                                value={tabValue}
                                indicatorColor="primary"
                                select
                                textColor="primary"
                                onChange={this.handleTabChange}
                                aria-label="disabled tabs example"
                                >
                                <Tab label="All Ranks" />
                                <Tab label="Next Ranks" />
                            </Tabs>
                            </div>
                          </div>
                           </div>
                        </div>
                        <div className="col-4 padding-right-0">
                        </div>
                    </div>
                    <img className="updatelg-close-icon" onClick={()=>this.props.closefunction()} src={closeIcon} alt='' style={{ cursor: 'pointer' }} />
                    <div className="col-12 rank-matrix-table-style" style={{display:"flex"}} >
                      <div className="col-3 rankmatrix-general-tabcontainer">
                        <TableContainer component={Paper} className = 'retail-height'>
                        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table" className='general-table-left'>
                                <TableHead  className='retailpf-table-header2'>
                                    {fieldMatrixListState?.length > 0?
                                        <TableCell className='rankmatrix-table-header' component="th" scope="row">
                                            {this.props?.rankLabel}
                                        </TableCell>
                                    :''}
                                </TableHead>
                                <TableBody>  
                                {rankMatrixListState?.length > 0? <TableRow>
                                            <TableCell className='rank-matrix-left-myqualcell'>{"My Qual"}</TableCell>
                                    </TableRow>:''}                                  
                                    {rankMatrixListState?.length > 0 ?
                                        rankMatrixListState?.map((data, i)=>
                                            <TableRow key={i}>
                                            <TableCell className='rank-matrix-left-tabelcell'>{ allNullTypeCheck(data?.label)? data?.label: data?.rankId}</TableCell>
                                        </TableRow>
                                            )
                                        :''
                                    }
                                    {rankMatrixListState?.length > 0? <TableRow>
                                            <TableCell className='rank-matrix-left-tabelcell shortage-style'>{"Shortage"}</TableCell>
                                    </TableRow>:''}
                                </TableBody>
                            </Table>
                            </TableContainer>
                            </div>
                            <hr className='line'/>
                            <div className="col-9 distres-general-rightcontainer">
                            <TableContainer component={Paper} className = 'retail-height'>
                            {fieldMatrixListState?.length > 0 ? <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table" className='general-table-left'>
                                <TableHead  className='rank-matrix-table-header'>
                                    {fieldMatrixListState?.length > 0? fieldMatrixListState?.map((row) => (
                                        <TableCell className='rank-matrix-table-header' component="th" scope="row">
                                            {row.label}
                                        </TableCell>
                                    )):''}
                                </TableHead>
                                <TableBody> 
                                    <TableRow>
                                        {voluemListState?.length > 0 ?
                                        voluemListState?.map((data,k)=>
                                        data.label!=="Cv"?<TableCell className='rank-matrix-tabelcell'>{data.value}</TableCell>:''):''}
                                        <TableCell className='rank-matrix-tabelcell'>{0}</TableCell>
                                        <TableCell className='rank-matrix-tabelcell'>{0}</TableCell>
                                    </TableRow>                             
                                    {rankMatrixListState?.length > 0 ?
                                        rankMatrixListState?.map((data, i)=>
                                            <TableRow key={i}>
                                            {fieldMatrixListState.map((qualVal,j)=>
                                            <TableCell className={this.colorDesider(data, qualVal)? 'rank-matrix-tabelcell-green' : 'rank-matrix-tabelcell shortage-value-style'}
                                            >{data?.qualificationValues[data?.qualificationValues?.findIndex((val) =>{
                                               return val?.name === qualVal?.name
                                            })]?.value}</TableCell>)}
                                        </TableRow>
                                            )
                                        :''
                                    }
                                    <TableRow>
                                            <TableCell className='rank-matrix-tabelcell shortage-value-style'>{}</TableCell>
                                            <TableCell className='rank-matrix-tabelcell shortage-value-style'>{}</TableCell> 
                                            <TableCell className='rank-matrix-tabelcell shortage-value-style'>{}</TableCell>
                                            <TableCell className='rank-matrix-tabelcell shortage-value-style'>{}</TableCell>
                                            <TableCell className='rank-matrix-tabelcell shortage-value-style'>{}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>: <div className="row noreports data-text-style">
                            {/* <img src={noreports} alt='' /> */}
                            No Data Available
                        </div>}
                        </TableContainer>
                        </div>
                        {/* {rankMatrixListState?.length === 0 ? <div className="row noreports data-text-style">
                            {/* <img src={noreports} alt='' /> */}
                            {/* No Data Available */}
                        {/* </div> : ''} */}
                        {loader}
                        <NewSnackbar/>
                    </div>
                </div>
                </div>
            </div>
                );
        }
    }
}

GeneralTabRankMatrix.propTypes = {
    retprofitbackfunc: PropTypes.func
};
const mapStateToProps = (state) => {
    return {
        rankMatrixDetailsList: state.distributorResearchReducer.rankMatrixDetailsList,
        rankMatrixDetailsError: state.distributorResearchReducer.rankMatrixDetailsError,
        distVolumeList:state.distributorResearchReducer.distVolumeList,
        distVolumeError:state.distributorResearchReducer.distVolumeError,
    }
}

const mapDispatchToProps = (dispatch) => ({
    getRankMatrix: (selectedRunID) => dispatch(getRankMatrixInfoAPI(selectedRunID)),
    showSnackbar: (params) => dispatch({ type: actions.SNACKBAR_SHOW, payload: params}),
    getVolumeList:(distID,runID) => dispatch(getDistributorVolumes(distID,runID))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GeneralTabRankMatrix));