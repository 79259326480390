import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AuthService from '../../server/AuthService';
import { Oval } from "react-loader-spinner";
import './GeneralTab.css';
import {useSelector, useDispatch} from 'react-redux'
import { DISTRIBUTOR_VOLUME_SUCCESS, DISTRIBUTOR_VOLUME_ERROR, getDistributorVolumes } from "../../../components/Commissions/actions";
import { singletonAxiosInstance } from '../../utilities/Server';
import Endpoints from '../../utilities/Endpoints';
import { allNullTypeCheck, isNullResponse } from "../commonComponents/UtilityFun";
import eyeIcon from "../assets/eye-icon.svg";
import { numberWithCommas } from "../../utilities/Services";
import VolRankMatrix from "./VolRankMatrix";
import * as Constant from '../../../constants/Constants';
import actions from "../../../redux/actions/CommonDataActions";

const server = singletonAxiosInstance;
const options = {
 Authorization: 'Bearer ' + localStorage.getItem('id_token')
 //Authorization: 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjU4MjQyMTVEODhGQkE4RTEzQkZGN0FFNTk0MzAyODM5RkU1M0ZGOENSUzI1NiIsInR5cCI6ImF0K2p3dCIsIng1dCI6IldDUWhYWWo3cU9FN18zcmxsREFvT2Y1VF80dyJ9.eyJuYmYiOjE2MzM0OTA0MzksImV4cCI6MTY2NTAyNjQzOSwiaXNzIjoiaHR0cHM6Ly9kZXYtYXRvbWljLmZsZXhjbG91ZC5jb20vaWRlbnRpdHkiLCJjbGllbnRfaWQiOiIwZDkzNGI5MS03NjA3LTQ3OWUtYjMyOS0xOTAyNjhiZmRjZDIiLCJjbGllbnRfbmFtZSI6ImRldi1hdG9taWMiLCJqdGkiOiIxNTk0MTA3RDFGRjM0RTMyNDcwMTZGRjE4Q0Q2QjdERCIsImlhdCI6MTYzMzQ5MDQzOSwic2NvcGUiOlsiL2NvbW1pc3Npb25zIl19.VOq7hVNtlqz3yhuag0yYrqMXefpTfI3upweQy2DUt9mO6Wd6iHhd8S3JCamTCYY-Fr8rlxIPEXNb8Rx7fg1e4LCrcXVQoOLVg65QBL3r2xuMQE7PQ_c4MF9C61xxQWrFAC2jjvNWztrb5pcv9B73XVv1MCJVajxnYeyrKVdxP3VK8R9L_OHqEzpXzKfz6PW0BiXgvPIl92NtHDE1mvC6YXXXcVwHuTAFBu8tZdvskr_wm0iNaY7niY8QzKXvY5DkWFdknaSeGP9HJwukdGzdY-ObHg5JDl2Swb0ienhOqQ4mKrIA1_kU9tcfk6k4dAPpYkvwcwPB-WQfNECuB1qxJw'
};
const statCodes = {
  SUCCESS: 200,
  CREATE_SUCCESS: 201,
  unAuthorized: 403
};

const VolumeTab =({ distRankInfo,distID,selectedRunID })=>{
  const [loader, setLoader] = useState(true);
  const [enableMatrix, setEnbaleMatrix] = useState(false);
  const [volFieldID, setVolFieldID] = useState(null);
  const [detailKey,setDetailKey] = useState('');
  const [volFieldLabel, setvolFieldLabel] = useState(null);
  const [distVolumeListData, setDistVolumeListData] = useState({});
  const [retrydelayTime, setRetrydelayTime] = useState(1);
  const distVolumeList = useSelector(state => state.distributorResearchReducer.distVolumeList)
  const distVolumeError = useSelector(state => state.distributorResearchReducer.distVolumeError)
    
    const dispatch = useDispatch();
    console.log("volume restult table data :",distRankInfo,distID,selectedRunID);
    console.log("volume restult :",distVolumeListData);
    
    useEffect(() => {
        dispatch(getDistributorVolumes(distID,selectedRunID))
      }, []);

    useEffect(()=>{
      if(isNullResponse(distVolumeList)&&!distVolumeList.hasOwnProperty('status')){ 
      if (isNullResponse(distVolumeList?.data) && distVolumeList?.error===false){
        console.log("got the Response: ",distVolumeList);
        setDistVolumeListData(distVolumeList?.data)
        setLoader(false);
      }else {
        if( allNullTypeCheck(distVolumeList?.data) && Object.keys(distVolumeList?.data)?.length===0 && allNullTypeCheck(distVolumeList?.messages)) {
          if(distVolumeList?.messages?.length > 0 && distVolumeList?.messages[0]?.includes("Try again")){
            console.log("Try again block entered : ",distVolumeList?.data);
            setTimeout(()=>{
              dispatch(getDistributorVolumes(distID,selectedRunID));
            },retrydelayTime*1000)
            setRetrydelayTime(retrydelayTime*2);
          }
        }
        setLoader(false);
      }
    }else {
        if(distVolumeList.hasOwnProperty('status') && distVolumeList.status===202){
          setLoader(true);
          setTimeout(()=>{
            dispatch(getDistributorVolumes(distID,selectedRunID));
          },retrydelayTime*1000)
          setRetrydelayTime(retrydelayTime*2);
      }
    }
    if(isNullResponse(distVolumeError)&& distVolumeError?.data?.error===true){
      console.log("General rank info failed :", distVolumeError);
      setLoader(false);
      if(distVolumeError?.data?.error === true){
        dispatch({ type: actions.SNACKBAR_SHOW, payload: {snackbarOpen: true, snackbarType:  Constant.SNACKBAR_TYPE.error, snackbarMessage: typeof(distVolumeError?.data?.messages[0])!=='string'? distVolumeError?.data?.messages[0]?.code+' - '+distVolumeError?.data?.messages[0]?.detail:distVolumeError?.data?.messages[0]}})
      }else if(distVolumeError?.message) {
        dispatch({ type: actions.SNACKBAR_SHOW, payload: {snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: distVolumeError?.message }});
      }
    }
   },[distVolumeList,distVolumeError]); 

    const closefunction=()=>{
      setEnbaleMatrix(false);
    }
    const openRankMatrix= (detailKeyVol, volFieldLabel) => {
      console.log("handled matrix ;:")
      setVolFieldID(detailKeyVol);
      setvolFieldLabel(volFieldLabel)
      setDetailKey(detailKeyVol);
      setEnbaleMatrix(true);
    }
    // const getDistributorVolumes = (distID,runID) => {
    //     return dispatch => {
    //       if (AuthService.isTokenValid() === true) {
    //         server.get(Endpoints.CommissionRuns+'/'+runID+"/distributors/"+distID+"/volumes", '', options).then((data) => {
    //             setLoader(false);
    //           if (data.status === statCodes.SUCCESS) {
    //             if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRun Details res :', data); }
    //             dispatch({ type: DISTRIBUTOR_VOLUME_SUCCESS, payload: data.data });
    //           } else {
    //             dispatch({ type: DISTRIBUTOR_VOLUME_ERROR, payload: data });
    //           }
    //         }).catch((error) => {
    //             setLoader(false);
    //           if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
    //           if (!error.response) {
    //             dispatch({ type: DISTRIBUTOR_VOLUME_ERROR, payload: error });
    //           } else {
    //             dispatch({ type: DISTRIBUTOR_VOLUME_ERROR, payload: error.response });
    //           }
      
    //         });
    //       } else {
    //         const user = AuthService.signinSilentToken();  
    //       }
    //     } 
    // }
if(loader){   
        return <div><div id="center" style={{ position: 'fixed', bottom: '40%', left: '48%' }}>
        <Oval type="Puff" position="fixed"  secondaryColor="#E85557" color="#E85557" height={50} width={100} visible={true} /> </div></div>;
} 
return (
<div className="col-12 distres-general-tbcontainer distres-tabs-scroll">
<div className="rectangle-2556"></div>
<TableContainer>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
                        <TableCell 
                            className="runmanager-table-header"
                            component="th"
                            scope="row"
                        >
                            {"Volume Fields"}
                        </TableCell>
                        <TableCell
                            className="downline-table-header"
                            component="th"
                            scope="row"
                        >
                            {"Pre-period"}
                        </TableCell>
                        <TableCell 
                            className="downline-table-header"
                            component="th"
                            scope="row"
                        >
                            {"Period"}
                        </TableCell>
                        <TableCell colSpan={2}
                            className="downline-table-header"
                            component="th"
                            scope="row"
                        >
                        <span className="volume-last-cell-data"> {"Post-period"}</span>
                        </TableCell>
					</TableHead>
                    <TableBody>
                    {(isNullResponse(distVolumeListData)&&distVolumeListData?.volumes?.length>0)? distVolumeListData?.volumes?.map((data,i)=>{
                        return(<TableRow key={i}>
                            <TableCell>{data?.label}</TableCell>
                            <TableCell className="table-cell-data-right-alignment">{numberWithCommas(data?.preValue?.toFixed(2))}</TableCell>
                            <TableCell className="table-cell-data-right-alignment">{numberWithCommas(data?.value?.toFixed(2))}</TableCell>
                            <TableCell>{data?.hasDetail===true ? <img src={eyeIcon} alt="" onClick= {()=>openRankMatrix(data?.detailKey, data?.label)} style={{cursor: 'pointer'}}/>: ''}</TableCell>
                            <TableCell className="table-cell-data-right-alignment"><span className="volume-last-cell-data">{numberWithCommas(data?.postValue?.toFixed(2))}</span></TableCell>
                        </TableRow>)}):''}
                    </TableBody>
				</Table>
			</TableContainer>
      {enableMatrix? <VolRankMatrix  closefunction={closefunction}
              distributorID ={distID}
              runID = {selectedRunID}
              volFieldID = {volFieldID} 
              detailKey = {detailKey}
              volFieldLabel = {volFieldLabel}
              />: ''}
</div>
);
}
export default VolumeTab;