import React from "react";
import "./Component2.css";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import line42 from '../../../assets/line-42@1x.png';
import { withRouter } from "react-router";

class Component2 extends React.Component {

  handledRoute(selectedPath){
    console.log("selected path : ",selectedPath);
    if(selectedPath === 'Run Manager'){
      this.props.history.push("/runmanager");
    }else if("Commissions Tools"===selectedPath){
      this.props.history.push('/commissiontools');
    }else if("Settings"===selectedPath){
      this.props.history.push('/settings');
    }else if("Reports"===selectedPath){
      console.log("selectedPath",selectedPath);
      this.props.history.push("/reports");
    }
  }
  render() {
    const { mainText, typeText, detailsText } = this.props;
    let highlighText = ''
    let textEnable = false
    switch (typeText) {
      case "RunDetails":
        highlighText = detailsText
        textEnable = true
        break;
      case "DistResearch":
        highlighText = 'Distributor Research'
        textEnable = true
        break;
      case "DistTools":
        highlighText = 'Compare Runs'
        textEnable = true
        break;
      case "Settings":
        highlighText = detailsText
        textEnable = true
        break;
      default:
        highlighText = ''
        textEnable = false
        break;
    }
    return (
      <div className="component-2">
        <div className="breadcrumb">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="comm-new-breadcrumb-styles breadcrumb-item" style={{cursor: 'pointer'}} onClick={()=>this.handledRoute(mainText)}>{mainText}</li>
              {textEnable === true ? <li className={textEnable === true ? "comm-new-breadcrumb-styles breadcrumb-item active1" : "comm-new-breadcrumb-styles breadcrumb-item"}>{highlighText}</li> : ''}
            </ol>
          </nav>
        </div>
        {textEnable === true ? <img className="col-12 cm-line-42-1" src={line42} alt='' /> : ''}
      </div>
    );
  }
}

export default withRouter(Component2);
