import React from "react";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import AuthService from "../../../../src/components/server/AuthService";
import "../CommissionsRunManager/CommissionRunManager.css";
import {Oval} from "react-loader-spinner";
import { connect } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import noreports from "../assets/norunreport.svg";
import leftarrow from "../assets/leftarrow.svg";
import rightarrow from "../assets/rightarrow.svg";
import gotoarrow from "../assets/gotoarrow.svg";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { withRouter } from "react-router-dom";
import Component2 from "../Component2";
import eyeIcon from "../assets/eye-icon.svg";
import threeDots from "../assets/three-dots.svg";
import liveIcon from "../assets/live-on.svg";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { getCommissionRunAPIList, COMMISSION_SELECTED_RUN, getCommissionPeriodListAPI } from "../actions";
import { EyeModel } from "./EyeModel";
import CreateNewRun from "../CreateNewRun/CreateNewRun";
import ViewRunLog from "../ViewRunLog/ViewRunLog";
import { DeleteRunModel } from "./DeleteRunModel";
import * as Constants from "../commonComponents/Constants";
import closeIcon from "../assets/closeicon.svg";
import { BROWSER_BACK_BUTTON_HANDLETAB } from '../actions'
import {getCommissionTypeListAPI} from "../../../components/Commissions/actions";
import { Input } from 'reactstrap';
import {getMonthDays, stringTruncate}  from '../../utilities/Services'
import { allNullTypeCheck, isNullResponse } from "../commonComponents/UtilityFun";
import * as Constant from '../../../constants/Constants';
import NewSnackbar from "../../utilities/NewSnackbar";
import actions from '../../../redux/actions/CommonDataActions';

import Moment from "moment";
import { Tooltip } from '@material-ui/core';
import errorAlert from "../assets/errorAlert.svg";
import addruncmpr from "../assets/addruncmpr.svg";

const fields = [
  { name: "Type", displayName: "Type", inputFilterable: true, sortable: true },
  {
    name: "name",
    displayName: "Name",
    inputFilterable: false,
    exactFilterable: true,
    sortable: true,
  },
  {
    name: "createdOn",
    displayName: "Created On",
    inputFilterable: false,
    exactFilterable: true,
    sortable: true,
  },
  {
    name: "lastRunDate",
    displayName: "Last Run Date",
    inputFilterable: true,
    sortable: true,
  },
  {
    name: "status",
    displayName: "Status",
    inputFilterable: true,
    sortable: true,
  },
  {
    name: "actions",
    displayName: "Actions",
    inputFilterable: false,
    exactFilterable: true,
    sortable: true,
  },
];

class CommissionRunManager extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedValue: "Primary",
      selectPeriodVal: "00/0000",
      selectedCommissionPeriodIndex: 0,
      commissionTypes: [],
      commissionRuns: [],
      selectedCommissionType: "Primary",
      commissionPeriodData: null,
      commissionPeriods: [],
      dataObj: [],
      previousDisabled: false,
      nextDisabled: false,
      anchorEl: false,
      commissionRunState: [],
      eyeEnable: false,
      selectedValueRun: {},
      isdisplayEyeModel: false,
      displayThreeDots: false,
      displayPerioPop: false,
      isdisplayNewrunModel: false,
      isViewLogRunModal: false,
      isDisplayDeleteModel: false,
      selectedRunDetails: {},
      isSelectedRunLogOpened: false,
      selectedOptions: "",
      logtitle: "",
      isCompareModelOn: false,
      addToCompareButtonList: [],
      selectCompareRuns: [],
      showLoader: false,
      periodValue: "",
      totalRuns: [],
      selectTypeIndex: 0,
      errorResultIs:false,
      periodNotFound:false,
      runsWaitTime: 1,
      periodWaitTime: 1,
      commTypeWaitTime: 1,
      commissionUpdatedRuns:[],
      selectPeriodIndex:'',
      prelimRunDisable: false,
    };
    this.handleEyeOptionModel = this.handleEyeOptionModel.bind(this);
    this.toggleDisplayEye = this.toggleDisplayEye.bind(this);
    this.handleThreeDots = this.handleThreeDots.bind(this);
    this.createNewRunModel = this.createNewRunModel.bind(this);
    this.closeRunLog = this.closeRunLog.bind(this);
    this.viewlogScreen = this.viewlogScreen.bind(this);
  }

  componentDidMount() {
   // const { selectedCommissionType } = this.state;
    this.setState({ showLoader: true });
    this.props.getCommissionTypes();
    this.props.getCommissionRunList();
   // this.props.getCommissionPeriod(selectedCommissionType);
  }
  componentDidUpdate(nextProps) {
    const {
      commissionRunList,
      commissionTypeList,
      commissionPeriodList,
      commissionRunListError,
      commissionPeriodError,
      commissionTypeError,
      selectedCompareRunsList,
    } = this.props;
    const {selectedCommissionType, runsWaitTime, periodWaitTime, commTypeWaitTime} = this.state
    if (commissionRunList !== nextProps.commissionRunList) {
      this.setState({ showLoader: false });
      if(!commissionRunList.hasOwnProperty('status')){ 
      console.log('runs response', commissionRunList)
      if (commissionRunList?.error === false){ 
        if(isNullResponse(commissionRunList?.data)) {
          this.setState({ commissionRunState: []});
          let sessionPeriod = sessionStorage.getItem("period");
          if (allNullTypeCheck(sessionPeriod)) {
            this.selectPeriodIndex(sessionPeriod);
          }
          else if (allNullTypeCheck(selectedCommissionType?.defaultPeriod)) {
            this.selectPeriodIndex(selectedCommissionType?.defaultPeriod);
          }
        } else if(Object.keys(commissionRunList?.data).length===0 && allNullTypeCheck(commissionRunList?.messages)) {
          if(commissionRunList?.messages?.length > 0 && commissionRunList?.messages[0]?.includes("Try again...")){
              console.log("Try again block entered : ",commissionRunList?.data);
              this.setState({ showLoader: true });
              setTimeout(() => {
                this.props.getCommissionRunList();
              }, 1000 * runsWaitTime);
              this.setState(prevState => {
                return {runsWaitTime: prevState.runsWaitTime * 2}
             })
          }
        }else {
          this.props.showSnackbar({snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: Constant.ALERT_TECHNICAL_ERROR })       
        }
      } else if(commissionRunList?.error === true){
        this.props.showSnackbar({
          snackbarOpen: true,
          snackbarType: Constant.SNACKBAR_TYPE.error,
          snackbarMessage: typeof(commissionRunList?.messages[0])!=='string'? commissionRunList?.messages[0].code+' - '+commissionRunList?.messages[0].detail:commissionRunList?.messages[0],
        });
     } else {
       this.props.showSnackbar({snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: Constant.ALERT_TECHNICAL_ERROR })       
     }
    }else {
      if(commissionRunList.hasOwnProperty('status') && commissionRunList.status===202){ 
        this.setState({ showLoader: true });
        setTimeout(() => {
          this.props.getCommissionRunList();
        }, 1000 * runsWaitTime);
        this.setState(prevState => {
          return {runsWaitTime: prevState.runsWaitTime * 2}
       })
      }
    }
    } else if (commissionRunListError !== nextProps.commissionRunListError) {
     // console.log("run list error : ", commissionRunListError);
     if(commissionRunListError?.error === true){
      this.props.showSnackbar({
        snackbarOpen: true,
        snackbarType: Constant.SNACKBAR_TYPE.error,
        snackbarMessage: typeof(commissionRunListError?.messages[0])!=='string'? commissionRunListError?.messages[0].code+' - '+commissionRunListError?.messages[0].detail:commissionRunListError?.messages[0],
      });
    }
    }
    if (commissionTypeList !== nextProps.commissionTypeList) {
      this.setState({ showLoader: false });
      if(!commissionTypeList.hasOwnProperty('status')){ 
        if (isNullResponse(commissionTypeList.data) && commissionTypeList.error===false) {
          console.log("period type API :",commissionTypeList.data);
          if(commissionTypeList?.data?.hasOwnProperty('periodTypes')){
          this.setState(
            { commissionTypes: commissionTypeList?.data?.periodTypes.filter(data=>data.active===true), commTypeWaitTime: 1 },
            () => {
              
              console.log(
                "commission Period success : ",
                // commissionTypeList?.data?.types[0]?.title
              );
              //here we have to pass session storage period value
                this.setState(
                  {
                    selectedCommissionType: {
                      title: commissionTypeList?.data?.periodTypes[0]?.title,
                      defaultPeriod:
                      commissionTypeList?.data?.periodTypes[0]?.defaultPeriod,
                    }
                  },
                  () => {
                    this.props.getCommissionPeriod(
                      commissionTypeList?.data?.periodTypes[0]?.title
                    );
                  }
                )
            }
          );
          }else{
            console.log("key does not exist: ");
          }
        }
        else if(Object.keys(commissionTypeList?.data).length===0 && allNullTypeCheck(commissionTypeList?.messages)) {
          if(commissionTypeList?.messages?.length > 0 && commissionTypeList?.messages[0]?.includes("Try again...")){
              console.log("Try again block entered : ",commissionTypeList?.data);
              this.setState({ showLoader: true });
              setTimeout(() => {
                this.props.getCommissionTypes();
              }, 1000 * commTypeWaitTime);
              this.setState(prevState => {
                return {commTypeWaitTime: prevState.commTypeWaitTime * 2}
            })
          }
        }
      }else {
        if(commissionTypeList.hasOwnProperty('status') && commissionTypeList.status===202){ 
          this.setState({ showLoader: true });
              setTimeout(() => {
                this.props.getCommissionTypes();
              }, 1000 * commTypeWaitTime);
              this.setState(prevState => {
                return {commTypeWaitTime: prevState.commTypeWaitTime * 2}
            })
        }
      }
    }
    else if(commissionTypeError !== nextProps.commissionTypeError){
      this.setState({ showLoader: false });
      if(commissionTypeError?.data?.error === true){
        this.props.showSnackbar({snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: typeof(commissionTypeError?.data?.messages[0])!=='string'? commissionTypeError?.data?.messages[0]?.code+' - '+commissionTypeError?.data?.messages[0]?.detail:commissionTypeError?.data?.messages[0]})
       }else if(commissionTypeError?.message) {
        this.props.showSnackbar({snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: commissionTypeError?.message })
       }
    }
    if (commissionPeriodList !== nextProps.commissionPeriodList) {
      this.setState({ showLoader: false });
      console.log("period log 202 ditected : ",commissionPeriodList)
      if(!commissionPeriodList.hasOwnProperty('status')){ 
          if (
            isNullResponse(commissionPeriodList.data) && commissionPeriodList.error === false && this.state.isViewLogRunModal === false
          ) {
            if(isNullResponse(commissionPeriodList?.data?.periods)){
              let commPeriods = null;
              commPeriods =
                commissionPeriodList?.data?.periods[
                  this.state.selectedCommissionType?.title
                    ?.toString()
                    .toLocaleLowerCase()
                ];
              this.setState(
                {
                  commissionPeriods: commPeriods, periodWaitTime: 1
                },
                () => {
                  let sessionPeriod = sessionStorage.getItem("period");
                  if(
                    allNullTypeCheck(commPeriods) &&
                    commPeriods?.length > 0 && allNullTypeCheck(sessionPeriod)){
                    this.selectPeriodIndex(sessionPeriod);
                  }
                  else if (
                    allNullTypeCheck(commPeriods) &&
                    commPeriods?.length > 0 &&
                    allNullTypeCheck(selectedCommissionType?.defaultPeriod)
                  ) {
                    this.selectPeriodIndex(selectedCommissionType?.defaultPeriod);
                  } else {
                  //  this.setState({ selectedCommissionPeriodIndex: 0, commissionRunState: [] });
                  }
                }
              );
            }
            else{
              this.setState({
                commissionPeriods: [],
              });
            }
          }
          else if(Object.keys(commissionPeriodList?.data).length===0 && allNullTypeCheck(commissionPeriodList?.messages)) {
            if(commissionPeriodList?.messages?.length > 0 && commissionPeriodList?.messages[0]?.includes("Try again...")){
                console.log("Try again block entered : ",commissionPeriodList?.data);
                this.setState({
                  commissionPeriods: [],
                });
                this.setState({ showLoader: true });
                setTimeout(() => {
                  this.props.getCommissionPeriod(
                    commissionTypeList?.data?.periodTypes[0]?.title
                  );
                }, 1000 * periodWaitTime);
                this.setState(prevState => {
                  return {periodWaitTime: prevState.periodWaitTime * 2}
              })
            }
      }
     }else {
      if(commissionPeriodList.hasOwnProperty('status') && commissionPeriodList.status === 202 ){
        console.log("period log 202 ditected : ");
          this.setState({ showLoader: true });
          setTimeout(() => {
            this.props.getCommissionPeriod(
              selectedCommissionType?.title
            );
          }, 1000 * periodWaitTime);
          this.setState(prevState => {
            return {periodWaitTime: prevState.periodWaitTime * 2}
        })
       }
     }
    }
     else if (commissionPeriodError !== nextProps.commissionPeriodError) {
      console.log("Period list Error : ", commissionPeriodError);
      this.setState({ showLoader: false });
      if(commissionPeriodError?.data?.error === true){
        this.props.showSnackbar({snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: typeof(commissionPeriodError?.data?.messages[0])!=='string'? commissionPeriodError?.data?.messages[0]?.code+' - '+commissionPeriodError?.data?.messages[0]?.detail:commissionPeriodError?.data?.messages[0]})
       }else if(commissionPeriodError?.message) {
        this.props.showSnackbar({snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: commissionPeriodError?.message })
       }
    }
      if (selectedCompareRunsList !== nextProps.selectedCompareRunsList) {
        console.log("selected compare runs  : ", selectedCompareRunsList);
        // this.setState({})
      }
      }
  commissionsTypefindIndex = (commissiontypeList, value) => {
    return commissiontypeList.findIndex((periodType) => {
      return periodType.title === value;
    });
  };
  handleCommissionTypeChange = (value) => {
    console.log("Select comm type", value);
    this.setState({ selectedRun: "Select Run", selectedCompletRun: "" });
    sessionStorage.removeItem("period")
    const commissionObj = this.commissionsTypefindIndex(this.state.commissionTypes,value);
    console.log("rs: ", this.state.commissionTypes[commissionObj]);
    if (commissionObj !== -1) {
      this.setState({
        selectedCommissionType: this.state.commissionTypes[commissionObj],
        commissionRunState: [],selectTypeIndex: commissionObj
      });
      this.props.getCommissionPeriod(value);
    } else {
      this.props.getCommissionPeriod(value);
    }
  };
  
  // Set selected period value and set selected period index value
  selectPeriodIndex(value) {
    const { totalRuns, commissionPeriods, commissionUpdatedRuns, selectCompareRuns, selectPeriodIndex } = this.state;
    const { commissionRunList, selectedCompareRunsList } = this.props;
    this.setState({ totalRuns: [], commissionRunState: [], commissionUpdatedRuns: [], selectedPeriod: value }, () => {});
    if(selectCompareRuns.length > 0){ this.setState({isCompareModelOn: true});}
    console.log("selected runs list is :",selectCompareRuns)
    commissionRunList?.data?.runs?.forEach((element,i) => {
      let runJson  = element;      
      if(selectCompareRuns.length>=1 && selectCompareRuns[0]?.data?.actionStatus?.index===element.runId){
        runJson.actionStatus = {
          index: element.runId,
          name: "ADD TO COMPARE",
          removeaction:"REMOVE",
          actionset: true,
        }
      }else if(selectCompareRuns.length>=1 && selectCompareRuns[1]?.data?.actionStatus?.index===element.runId){
        runJson.actionStatus = {
          index: element.runId,
          name: "ADD TO COMPARE",
          removeaction:"REMOVE",
          actionset: true,
        }
      }else{
        runJson.actionStatus = {
          index: element.runId,
          name: "ADD TO COMPARE",
          removeaction:"REMOVE",
          actionset: false,
        }
      }
      commissionUpdatedRuns.push(runJson)
    });
    console.log("final run list is : ",commissionUpdatedRuns);
    console.log("commission periods : ", commissionPeriods);
    console.log("commission run list : ", commissionRunList);
    if (isNullResponse(commissionPeriods) && commissionPeriods?.length > 0 && value !== '00/0000') {
      const indexValue = commissionPeriods.findIndex((data) => {
        return getMonthDays(data.name) === value;
      });
      console.log("selected index : ", indexValue, commissionRunList);
      if (allNullTypeCheck(indexValue) && indexValue !== -1) {
        this.setState({ selectedCommissionPeriodIndex: indexValue }, () => {});
        this.setState({prelimRunDisable: false}, () => {})
        commissionUpdatedRuns.filter((data) => {
          if (data.period === value) {
            totalRuns.push(data);
            if(data.runType === Constants.PRILIMINARY && (data.message === Constants.RUNSTATUS_CERTIFICATION
            || data.message === Constants.RUNSTATUS_COMPLETED || data.message === Constants.RUNSTATUS_DISBURSEMENT || data.live === true)){
              this.setState({prelimRunDisable: true})
             }
          }
        });
        this.setState({ commissionRunState: [] }, () => {
          this.setState({ commissionRunState: totalRuns });
        });
      } else if (indexValue === -1) {
        this.setState({ selectedCommissionPeriodIndex: 0, commissionRunState: []}, () => {});
        // commissionUpdatedRuns.filter((data) => {
        //   if (data.period === value) {
        //     totalRuns.push(data);
        //   }
        // });
        // this.setState({ commissionRunState: [] }, () => {
        //   this.setState({ commissionRunState: totalRuns });
        // });
      }
    }
    else{
      this.setState({ commissionRunState: [], selectedCommissionPeriodIndex: 0 })
    }
  }
  handleKeyPress = (event) => {
    if (event.key === "Enter") {
        this.onJumpSelectPeriod();
    }
  };
  // Period select input jump to values
  handlePeriodInput = (input) => {
    const { value } = input.target;
    
    this.setState({errorResultIs:false, periodNotFound:false},()=>{});
    let finlValue = '';
    if(value.includes("/")){
      var match = value.split('/')[0];
      console.log("selected value",match);
      if(match > 9 && match < 13){
        finlValue = value.length === 3 && !value.includes("/")
        ? `${value.substring(0, 2)}/${value.substring(2)}`
        : value;
      }else if(match > 0 && match <= 9){
        if(match.includes("0")){
          finlValue = value.length === 3 && !value.includes("/")
          ? `${value.substring(0, 2)}/${value.substring(2)}`
          : value;
        }else {
          finlValue = value.length === 3 && !value.includes("/")
          ? `${value.substring(0, 2)}/${value.substring(2)}`
          : value;
          console.log("final value is ",finlValue);
        }
      }else {
        finlValue = value;
        this.setState({errorResultIs:true},()=>{});
      }   
    }else {
      finlValue = value.length === 3 && !value.includes("/")
      ? `${value.substring(0, 2)}/${value.substring(2)}`
      : value;
    }
    if (allNullTypeCheck(finlValue)) {
      // console.log("values : ",value);
      this.setState({ periodValue: finlValue });
       sessionStorage.setItem("period",value)
      // this.runsFilterbySelectedPeriod(value)
    } else {
      this.setState({ periodValue: "" });
    }
  };
  // Next arrrow action after enter Period seelction
  onJumpSelectPeriod = () => {
    const { periodValue, errorResultIs, commissionRunList } = this.state;
    this.setState({errorResultIs:false, periodNotFound:false},()=>{});
    let finlValue = '';
    console.log("second value is ",periodValue.split("/")[1]?.toString().length===4)
    if(periodValue.includes("/") && periodValue.split("/")[1]?.toString().length===4){
      var match = periodValue.split('/')[0];
      console.log("selected value",match);
      if(match > 9 && match < 13){
        finlValue = periodValue;
      }else if(match > 0 && match <= 9){
        if(match.includes("0")){
          finlValue = periodValue;
        }else {
          finlValue = 0+match+'/'+periodValue.split('/')[1];
          console.log("final value is ",finlValue);
        }
      }else {
        finlValue = periodValue;
        this.setState({errorResultIs:true},()=>{});
      }   
    }else {
      finlValue = periodValue;
      this.setState({errorResultIs:true},()=>{});
    }
    this.setState({periodValue: finlValue},()=>{});
    if(!errorResultIs && finlValue){
        this.setState({ anchorEl: false, displayPerioPop: false });
        if (periodValue) {
          sessionStorage.setItem("period", periodValue);
          this.selectPeriodIndex(periodValue);
        } else {
          this.setState({ periodValue: "" });
          this.setState({ commissionRunState: commissionRunList.data?.runs });
        }
    }else {
      this.setState({periodNotFound:true});
    }
  };

  periodVerified=(periodVal)=>{
    const { selectedValue } = this.state;
    let cmperiods = this.periodFilterByType(selectedValue);
    if (cmperiods?.length > 0) {
      if(cmperiods?.findIndex((data) =>
         getMonthDays(data.name) === periodVal)!==-1){
           return true;
         }else { 
           return false;
         }
      }
  }

  updateSelectPeriod(index) {
    const { commissionPeriods } = this.state;
    if (allNullTypeCheck(commissionPeriods) && commissionPeriods.length > 0) {
      sessionStorage.setItem("period",getMonthDays(commissionPeriods[index]?.name))
      this.setState({ selectPeriodVal: getMonthDays(commissionPeriods[index]?.name)});
      this.selectPeriodIndex(getMonthDays(commissionPeriods[index]?.name));
    }
  }
  getNextPeriod() {
    const { selectedCommissionPeriodIndex } = this.state;
    this.setState((prevState, props) => ({
      selectedCommissionPeriodIndex:
        prevState.selectedCommissionPeriodIndex + 1,
    }));
    this.updateSelectPeriod(selectedCommissionPeriodIndex + 1);
  }
  getPreviousPeriod() {
    const { selectedCommissionPeriodIndex } = this.state;
    this.setState((prevState, props) => ({
      selectedCommissionPeriodIndex:
        prevState.selectedCommissionPeriodIndex - 1,
    }));
    this.updateSelectPeriod(selectedCommissionPeriodIndex + -1);
  }

  // Periods API call based on Commission Type changes
  selectCommissionType(commissionType) {
    this.setState({ selectedCommissionType: commissionType });
    this.getPeriods(commissionType);
  }
  // Live status icon
  getLiveIcon(result, date) {
    return result === true ? (
      <>
        <img className="runmager-live-on" src={liveIcon} alt="" />
        <span>Live on {date}</span>{" "}
      </>
    ) : (
      ""
    );
  }
  viewlogScreen(logOption,commissionrun) {
    //here to implement view log screen
    console.log("selected run id : ",this.state.selectedRunDetails, commissionrun);
    if (logOption !== null) {
      this.props.selectedRunIndex(this.state.selectedRunDetails);
      this.setState({
        isViewLogRunModal: !this.state.isViewLogRunModal,
        selectedOptions: logOption,
        displayThreeDots: false,
        logtitle:
          logOption === Constants.VIEW_RUN_LOG_DISPLAY
            ? this.state.selectedRunDetails
            : Constants.LOG_TITLE,
      },()=>{});
    } else {
      console.log("else block entered :")
     // this.props.selectedRunIndex(this.state.selectedRunDetails);
      this.setState({
        isViewLogRunModal: !this.state.isViewLogRunModal,
        displayThreeDots: false,
      });
    }
  }
  selectedPeriodDetails() {
    //here need to show selected period details with list
    this.setState({ anchorEl: true });
  }
  handleClick = (event, selectedRun) => {
    this.setState({ errorResultIs: false });
    if (event.currentTarget.name === "threedots") {
      this.setState({
        selectedRunDetails: selectedRun,
        anchorEl: event.currentTarget,
        displayThreeDots: true,
      });
      this.props.selectedRunIndex(selectedRun);
    } else {
      this.setState({ anchorEl: event.currentTarget, displayPerioPop: true });
    }
  };
  handleClose = () => {
    this.setState({
      compareAnchorEl: null,
      anchorEl: null,
      displayThreeDots: false,
      displayPerioPop: false,
    });
  };
  toggleDisplayEye(selectedAction) {
    this.setState({
      isdisplayEyeModel: !this.state.isdisplayEyeModel,
    });
    //T0 DO - here we have to pass run ID to get run view
    if (Constants.OPEN_ACTION === selectedAction) {
      this.props.history.push({
        pathname: "/rundetails",
        state: {
          runid: this.state.selectedValueRun.runId,
        },
      });
    }
  }
  openRunDetails() {
    console.log("selected run id open run: ", this.state.selectedRunDetails);
   // sessionStorage.setItem("period", this.state.selectPeriodVal);
    this.props.history.push({
      pathname: "/rundetails",
      state: {
        runid: this.state.selectedRunDetails.runId,
      },
    });
  }
  openSelectedRunDetails(id) {
  //  sessionStorage.setItem("period", this.state.selectPeriodVal);
    this.props.history.push({
      pathname: "/rundetails",
      state: {
        runid: id,
      },
    });
  }
  createNewRunModel() {
    this.setState({
      isdisplayNewrunModel: !this.state.isdisplayNewrunModel,
    });
  }
  toggleDisplayDeleteModel() {
    this.setState({
      isDisplayDeleteModel: !this.state.isDisplayDeleteModel,
    });
    console.log("run deleted");
    //window.location.reload(false);
  }
  closeDeleteModal() {
    this.setState({
      isDisplayDeleteModel: !this.state.isDisplayDeleteModel,
    });
    console.log("run deleted");
    window.location.reload(false);
  }

  handleEyeOptionModel(run) {
    this.setState({
      selectedValueRun: run,
      isdisplayEyeModel: !this.state.isdisplayEyeModel,
    });
    this.props.selectedRunIndex(run);
  }
  handleThreeDots(event) {
    this.setState({ anchorEl: event.currentTarget });
  }
  handleNewrunCreateModel() {
    this.setState({ isdisplayNewrunModel: !this.state.isdisplayNewrunModel });
  }
  handleDeleteRun(selectedRun) {
    console.log("delete selected : ", selectedRun);
    this.setState({
      isDisplayDeleteModel: !this.state.isDisplayDeleteModel,
      displayThreeDots: false,
    });
  }
  closeRunLog() {
    this.setState({
      isSelectedRunLogOpened: !this.state.isSelectedRunLogOpened,
    });
  }
  handleAddToCompareModel(selectRun) {
    const { addToCompareButtonList, selectCompareRuns, commissionRunState } = this.state;
    console.log("selectCompareRuns", selectCompareRuns);
    
    if (selectRun?.actionStatus?.actionset) {
      this.setState(prevState => ({
        commissionRunState: prevState.commissionRunState.map(student =>
          student.runId === selectRun.runId
            ? {
                ...student,
                actionStatus: {
                  index: student.runId,
                  name: "ADD TO COMPARE",
                  removeaction: "REMOVE",
                  actionset: false
              },
              }
            : student,
        ),
      }));
      if ( selectCompareRuns.length >= 1 ){
        let index = selectCompareRuns.findIndex((data)=>data?.data?.actionStatus?.index===selectRun.runId)
        console.log("removing index is : ",index);
        selectCompareRuns.splice(index, 1);
        this.setState({
          selectCompareRuns: selectCompareRuns,
        });
      }else {
        
          this.setState({
            selectCompareRuns: [], isCompareModelOn: false
          });
        
       
      }
     
    } else if ( selectCompareRuns.length <= 1 ) {
     
      this.setState({ isCompareModelOn: true });
      this.setState(prevState => ({
        commissionRunState: prevState.commissionRunState.map(student =>
          student.runId === selectRun.runId
            ? {
                ...student,
                actionStatus: {
                  index: student.runId,
                  name: "ADD TO COMPARE",
                  removeaction: "REMOVE",
                  actionset: true
              },
              }
            : student,
        ),
      }));
      console.log("updated state value: ",commissionRunState);
      selectCompareRuns.push({index:selectRun,data:selectRun});
      // this.props.addSelectedcompareRuns(selectCompareRuns);
  }
}

  commissionsRunListfindIndex = (commissiontypeList, value) => {
    return commissiontypeList.findIndex((periodType) => {
      return periodType.runId === value.runId;
    });
  }
  
  closeSelctedrun(runId, j) {
    console.log("onclose select run",runId);
    const { addToCompareButtonList, selectCompareRuns, selectedPeriod } = this.state;
    
    let index = selectCompareRuns.findIndex((data)=>data?.data?.actionStatus?.index===runId)
    console.log("selected index :",index);
    selectCompareRuns.splice(index, 1);
    this.setState({
      selectCompareRuns: selectCompareRuns,
    });
    this.selectPeriodIndex(selectedPeriod);
  }
  handleCompareMethod() {
    const { selectCompareRuns } = this.state;
    return (
      <>
        {selectCompareRuns.length > 0 ? (
          <div className="row col-12 fixed-bottom">
            <div className="col-2">&nbsp;</div>
            <div className="col-8 compare-options">
              <div className="col-5" style={{paddingLeft: '0px'}}>
                {selectCompareRuns.length > 0 ? (
                  <div className="compare-box-ui">
                    <div className="row" style={{ marginTop: "8px" }}>
                      <div className="comparerun-title-width" style={{display: 'flex'}}>
                      {selectCompareRuns[0].data?.name.length > 15?
                        <Tooltip title= {selectCompareRuns[0].data?.name} placement="top" arrow={true}> 
                        <span className="cmnrun-cmpr-title">
                          {stringTruncate(selectCompareRuns[0].data?.name,15)}
                        </span>
                        </Tooltip>:<span className="cmnrun-cmpr-title">
                          {selectCompareRuns[0].data?.name}
                        </span>}
                      </div>
                      <div className="comparerun-type-width">
                        <div
                          className={
                            selectCompareRuns[0].data?.runType === "Analysis"
                              ? "cmrun-primarybtn"
                              : "cmrun-pribtn"
                          }
                        >
                          <span className="cmrun-pritext">
                            {selectCompareRuns[0].data?.runType === "Analysis"
                              ? "Analysis"
                              : "Preliminary"}
                          </span>{" "}
                        </div>
                      </div>
                      <div className="comparerun-close-width">
                        <img style={{cursor: 'pointer'}}
                          className="cmnrun-closeicon"
                          onClick={() =>
                            this.closeSelctedrun(selectCompareRuns[0]?.data?.actionStatus?.index, 0)
                          }
                          src={closeIcon}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="row cmrun-cmpr-text">
                      {selectCompareRuns[0].data?.qualificationProgram},&nbsp;
                      {selectCompareRuns[0].data?.calculationProgram},&nbsp;
                      {selectCompareRuns[0].data?.disbursementProgram}
                      &nbsp;| Created on:&nbsp;
                      { Moment(selectCompareRuns[0].data?.created).format('MM/DD/YYYY HH:mm:ss')}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-5" style={{paddingLeft: '0px'}}>
                {selectCompareRuns.length > 1 ? (
                  <div className="compare-box-ui">
                    <div className="row" style={{ marginTop: "8px" }}>
                      <div className="comparerun-title-width" style={{display: 'flex'}}>
                      {selectCompareRuns[1].data?.name.length > 15?
                        <Tooltip title= {selectCompareRuns[1].data?.name} placement="top" arrow={true}> 
                        <span className="cmnrun-cmpr-title">
                          {stringTruncate(selectCompareRuns[1].data?.name,15)}
                        </span>
                        </Tooltip>:<span className="cmnrun-cmpr-title">
                          {selectCompareRuns[1].data?.name}
                        </span>}
                      </div>
                      <div className="comparerun-type-width">
                        <div
                          className={
                            selectCompareRuns[1].data?.runType === "Analysis"
                              ? "cmrun-primarybtn"
                              : "cmrun-pribtn"
                          }
                        >
                          <span className="cmrun-pritext">
                            {selectCompareRuns[1].data?.runType === "Analysis"
                              ? "Analysis"
                              : "Preliminary"}
                          </span>{" "}
                        </div>
                      </div>
                      <div className="comparerun-close-width">
                        <img style={{cursor: 'pointer'}}
                          className="cmnrun-closeicon"
                          onClick={() =>
                            this.closeSelctedrun(selectCompareRuns[1]?.data?.actionStatus?.index, 1)
                          }
                          src={closeIcon}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="row cmrun-cmpr-text">
                      {selectCompareRuns[1].data?.qualificationProgram},&nbsp;
                      {selectCompareRuns[1].data?.calculationProgram},&nbsp;
                      {selectCompareRuns[1].data?.disbursementProgram}
                      &nbsp;| Created on:&nbsp;
                      {Moment(selectCompareRuns[1].data?.created).format('MM/DD/YYYY HH:mm:ss')}
                    </div>
                  </div>
                ) : (
                  <div className="compare-box-ui">
                  <div className="cmnrun-cmpr-title1"><img
                  src={addruncmpr}
                  alt=""
                /> <span style={{marginLeft: '5px'}}>Add Run to Compare</span></div>
                </div>
                )}
              </div>
              <div className="col-2 rowalign-center" style={{paddingRight: '0px'}}>
                {selectCompareRuns.length === 2 ? (
                  <div className="cmrun-cmrunbtn">
                    <span
                      className="cmrun-cmrunbtntext"
                      onClick={() => this.gotoCompareRun()}
                    >
                      Compare Runs
                    </span>
                  </div>
                ) : (
                  <div className="cmrun-cmrunbtn-disable">
                    <span
                      className="cmrun-cmrunbtntext" style={{color: 'GrayText'}}
                    >
                      Compare Runs
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="col-2">&nbsp;</div>
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
  gotoCompareRun() {
    this.props.updateTabValue(1);
    this.props.history.push({
      pathname: "/commissiontools/comparerun",
      state: {
        runObjects: [
          this.state.selectCompareRuns[0].data,
          this.state.selectCompareRuns[1].data,
        ],
      },
    });
  }
  addToCompareButtonHandle(i) {
    const { addToCompareButtonList } = this.state;
    addToCompareButtonList.push({
      index: i,
      name: "ADD TO COMPARE",
      actionset: false,
    });
    return  (allNullTypeCheck(addToCompareButtonList[i])) ? addToCompareButtonList[i].name : ''; // addToCompareButtonList[i].name: addToCompareButtonList[i].name
  }

  render() {
    const {
      anchorEl,
      isdisplayEyeModel,
      selectedValueRun,
      displayThreeDots,
      displayPerioPop,
      isDisplayDeleteModel,
      selectedRunDetails,
      isdisplayNewrunModel,
      isViewLogRunModal,
      selectedOptions,
      logtitle,
      isCompareModelOn,
      addToCompareButtonList,
      commissionRunState,
      showLoader,
      selectPeriodVal,
      selectedCommissionType,
      periodValue,
      commissionTypes,
      selectedCommissionPeriodIndex,
      commissionPeriods,
      selectTypeIndex,
      errorResultIs,
      periodNotFound,
      prelimRunDisable
    } = this.state;
    const {commissionRunList, commissionTypeList} = this.props
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    let loader;
    if (AuthService.isAuthenticated()) {
      if (showLoader) {
        loader = (
          <div>
            <div
              id="center"
              style={{ position: "fixed", top: "40%", left: "48%" }}
            >
              <Oval
                color="#E85557"
                height={50}
                width={60}
                visible={true}
                secondaryColor="#E85557"
                strokeWidth={2}
              />{" "}
            </div>
          </div>
        );
      }
      return (
        <div className="row com-overlap-group-1">
          <div
            className="col-9"
            style={{ paddingLeft: "0px", paddingRight: "0px" }}
          >
            {isViewLogRunModal ? (
              <ViewRunLog
                vewrunlogbackfunc={() => this.viewlogScreen(null,'')}
                selectedRun={logtitle}
                selectedOption={selectedOptions}
                periodType={ selectedCommissionType }
                commissionPeriod={ selectedCommissionPeriodIndex }
              />
            ) : (
              <>
                {" "}
                <Component2 mainText="Run Manager" typeText={""} />
                <div className="rectangle-255"></div>
                <div className="flex">
                  <div className="rowalign-center" style={{ height: "80px" }}>
                    <div className="col-8 h2title">Run Manager</div>
                    <div className="col-4 padding-right-0">
                      <div className="comm-type-period-display">
                        <div>
                          <div className="runmanager-alignitems-left cm-type-title">
                            <span className="alignitems-left cm-type-title">
                              Period Type
                            </span>
                            <div className="runmanager-element-width alignitems-left">
                              <Dropdown
                                className="runmanger"
                                onSelect={this.handleCommissionTypeChange}
                                style={{
                                  backgroundColor: "white",
                                  marginTop: "8px",
                                }}
                              >
                                <Dropdown.Toggle
                                  id="dropdown-custom-components"
                                  title="Select Type"
                                >
                                  {allNullTypeCheck(selectedCommissionType?.title) ? selectedCommissionType?.title :  selectedCommissionType}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="period-dropdown-menu">
                                  { commissionTypes?.length > 0 && commissionTypes.map((item,i) => {
                                    return (
                                      <Dropdown.Item
                                        className="primary-dropbtn"
                                        eventKey={item.title}
                                        key={i}
                                      >
                                        {item.title}
                                      </Dropdown.Item>
                                    );
                                  })}
                                </Dropdown.Menu>
                              </Dropdown>
                              {/* <img src={dropdown1} alt='' /> */}
                            </div>
                          </div>
                        </div>
                        <div className="runmanager-period-margin">
                          <div className="runmanager-alignitems-left cm-type-title">
                            <span className="alignitems-left cm-type-title">
                              Commission Period
                            </span>
                            <div className="runmanager-element-width alignitems-left">
                              <div
                                className="cd-template"
                                style={{ marginTop: "8px" }}
                              >
                                <div className="cd-text-temp">
                                  <div
                                    onClick={this.getPreviousPeriod.bind(
                                      this,
                                      selectedCommissionPeriodIndex
                                    )}
                                    disabled={
                                      selectedCommissionPeriodIndex > 0
                                        ? false
                                        : true
                                    }
                                  >
                                    <img
                                      src={leftarrow}
                                      alt=""
                                      style={{
                                        marginRight: "5px",
                                        cursor: "pointer",
                                      }}
                                    />{" "}
                                  </div>
                                  <span
                                    aria-describedby={id}
                                    name="periodvalues"
                                    variant="contained"
                                    onClick={this.handleClick}
                                    style={{
                                      marginBottom: "2px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {allNullTypeCheck(commissionPeriods) && commissionPeriods?.length > 0 ? getMonthDays(
                                            this.state.commissionPeriods[
                                              this.state.selectedCommissionPeriodIndex
                                            ]?.name
                                          )
                                        : "00/0000"}
                                  </span>
                                  <div
                                    onClick={this.getNextPeriod.bind(
                                      this,
                                      selectedCommissionPeriodIndex
                                    )}
                                    disabled={
                                      selectedCommissionPeriodIndex ===
                                      commissionPeriods?.length - 1
                                        ? true
                                        : false
                                    }
                                  >
                                    <img
                                      src={rightarrow}
                                      alt=""
                                      style={{
                                        marginLeft: "5px",
                                        cursor: "pointer",
                                      }}
                                    />{" "}
                                  </div>
                                </div>
                                {displayPerioPop &&
                                commissionPeriods?.length > 0 ? (
                                  <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={this.state.anchorEl}
                                    onClose={this.handleClose.bind(this)}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "center",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "center",
                                    }}
                                  >
                                    <Typography sx={{ p: 2 }} component={'span'} variant={'body2'}>
                                      {commissionPeriods.length > 0 ? ( 
                                        <div className="selectcpdetails">
                                          <div className="cmperiod-select">
                                            Last Commission Period:{" "}
                                            { isNullResponse(commissionTypeList) ? commissionTypeList.data.periodTypes[selectTypeIndex]?.lastCommissionPeriod : ''}
                                          </div>
                                              <div className="cmperiod-select">
                                                Start Date:{" "}
                                                {Moment(commissionPeriods[
                                                  selectedCommissionPeriodIndex
                                                ].start).format('MM/DD/YYYY') !== 'Invalid date' ? Moment(commissionPeriods[
                                                  selectedCommissionPeriodIndex
                                                ].start).format('MM/DD/YYYY') : ''}
                                              </div>
                                          <div className="cmperiod-select">
                                            End Date:{" "}
                                            {Moment(commissionPeriods[
                                                  selectedCommissionPeriodIndex
                                                ].end).format('MM/DD/YYYY') !== 'Invalid date' ? Moment(commissionPeriods[
                                                  selectedCommissionPeriodIndex
                                                ].end).format('MM/DD/YYYY') : ''}
                                          </div>
                                          <div className="cmperiod-select">
                                            Default Check Date:{" "}
                                            {Moment(commissionPeriods[
                                                selectedCommissionPeriodIndex
                                              ].checkDate).format('MM/DD/YYYY') !== 'Invalid date' ? Moment(commissionPeriods[
                                                selectedCommissionPeriodIndex
                                              ].checkDate).format('MM/DD/YYYY') : ''}
                                            {" "}
                                          </div>
                                          <div className="cmperiod-line"></div>
                                          <div className="jumpto_runmanager">
                                            <div style={{display:'flex'}}>
                                            <span style={{marginTop:"5px"}}>{"Jump To:"}</span>
                                            <Input
                                              className="col-4 jumpperiod-options"
                                              name="periodvalue"
                                              type="text"
                                              style={{borderColor:(errorResultIs||periodNotFound)?"#FF3A3A":"#5D5A5A"}}
                                              placeholder="MM/YYYY"
                                              value={periodValue}
                                              maxLength={7}
                                              onChange={this.handlePeriodInput.bind(
                                                this
                                              )}
                                              onKeyPress={this.handleKeyPress}
                                            ></Input>
                                            <img
                                              src={gotoarrow}
                                              style={{
                                                marginLeft: "8px",
                                                cursor: "pointer",
                                              }}
                                              alt=""
                                              onClick={this.onJumpSelectPeriod}
                                            />
                                            </div>
                                            <div style={{display:'flex', textAlign:'center', paddingLeft:'65px',paddingTop:'5px'}}>{errorResultIs?<> <img
                                              src={errorAlert}
                                              alt=""
                                            /><span className="periodError">{"Please enter valid period"}</span> </>: (!errorResultIs && periodNotFound)? <><img
                                              src={errorAlert}
                                              alt=""
                                            /><span className="periodError">{"Period doesn't exist"}</span></>:'' }</div>
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </Typography>
                                  </Popover>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 cm-table-style">
                    <div className="rowalign-center" style={{ height: "70px" }}>
                      <div className="col-8 h1title">Commission Runs</div>
                      <div className="col-2 alignitems-end">
                        <div
                          className="viewlog"
                          // onClick={() =>
                          //   this.viewlogScreen(Constants.VIEW_LOG_DISPLAY)
                          // }
                        >
                          {/* <div className="viewlog-text">VIEW LOG</div> */}
                        </div>
                      </div>
                      <div className="col-2 alignitems-right">
                      {commissionRunState?.length >= commissionRunList?.data?.maxRuns ? <div
                          className="createrun-disable"
                        >
                          <div className="createtitile">CREATE RUN</div>
                        </div> :
                        <div
                          className="createrun"
                          onClick={this.createNewRunModel}
                        >
                          <div className="createtitile">CREATE RUN</div>
                        </div>}
                      </div>
                    </div>
                    <div className="runmanager-tbcontainer">
                      <div className="rectangle-2556"></div>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            {fields.map((row) => (
                              <TableCell
                                className="runmanager-table-header"
                                component="th"
                                scope="row"
                              >
                                {row.displayName}
                              </TableCell>
                            ))}
                            <TableCell />
                            <TableCell />
                          </TableHead>
                          <TableBody>
                            {commissionRunState.length > 0
                              ? commissionRunState.map((commissionrun, i) => (
                                  <TableRow key={i}>
                                    <TableCell className="runmanager-type letterspacing">
                                      {commissionrun.runType === "Analysis"
                                        ? "A"
                                        : "P"}
                                    </TableCell>
                                    {commissionrun?.name?.length >= 15 ?
                                    <Tooltip title= {commissionrun?.name} placement="top" arrow={true}> 
                                    <TableCell className="letterspacing"
                                      onClick={() =>
                                        this.openSelectedRunDetails(
                                          commissionrun.runId
                                        )
                                      }
                                    >
                                    <span className="runmanager-name">{stringTruncate(commissionrun?.name, 15)}</span> - ({commissionrun?.recordNumber})
                                    </TableCell>
                                    </Tooltip> : <TableCell className="letterspacing"
                                      onClick={() =>
                                        this.openSelectedRunDetails(
                                          commissionrun.runId
                                        )
                                      }
                                    >
                                    <span className="runmanager-name">{commissionrun?.name}</span> - ({commissionrun?.recordNumber})
                                    </TableCell>}
                                    <TableCell className="runmanager-data-status letterspacing">
                                      {Moment(commissionrun.created).format('MM/DD/YYYY') !== 'Invalid date' ? Moment(commissionrun.created).format('MM/DD/YYYY') : ''}
                                    </TableCell>
                                    <TableCell className="runmanager-data-status letterspacing">
                                    {Moment(commissionrun.lastRun).format('MM/DD/YYYY') !== 'Invalid date' ? Moment(commissionrun.lastRun).format('MM/DD/YYYY') : ''}
                                    </TableCell>
                                    <TableCell className="runmanager-data-status letterspacing">
                                      {commissionrun?.live === true ? this.getLiveIcon(commissionrun?.live, Moment(commissionrun.lastRun).format('MM/DD/YYYY')) : commissionrun?.message}
                                    </TableCell>
                                    <TableCell className="runmngr-cell-type letterspacing">
                                      { allNullTypeCheck(this.addToCompareButtonHandle(i)) && allNullTypeCheck(commissionrun?.lastRun) 
                                      && commissionrun?.message?.toLocaleLowerCase() !== Constants.RUNSTATUS_NOT_STARTED.toLocaleLowerCase() 
                                      && commissionrun?.message?.toLocaleLowerCase() !== Constants.RUNSTATUS_QUALIFICATION_PHASE.toLocaleLowerCase() 
                                      && commissionrun?.message?.toLocaleLowerCase() !== Constants.RUNSTATUS_QUALIFICATION.toLocaleLowerCase()
                                      ? <Button
                                        className={`runmanager-compare-button ${
                                          commissionrun.actionStatus.actionset===true
                                            ? "remove-compare-button"
                                            : ""
                                        }`}
                                        onClick={() =>
                                          this.handleAddToCompareModel(
                                            commissionrun
                                          )
                                        }
                                      >
                                        {commissionrun.actionStatus.actionset===true? commissionrun?.actionStatus?.removeaction: commissionrun?.actionStatus?.name}
                                      </Button> : <Button className="runmanager-compare-disable-button" >{commissionrun.actionStatus.actionset===true? commissionrun?.actionStatus?.removeaction: commissionrun?.actionStatus?.name}</Button>}
                                    </TableCell>
                                    <TableCell className="runmngr-cell-type letterspacing">
                                      <img
                                        className="eye-icon-style"
                                        src={eyeIcon}
                                        alt=""
                                        onClick={() =>
                                          this.handleEyeOptionModel(
                                            commissionrun
                                          )
                                        }
                                        style={{ pointerEvents: "all" }}
                                      />
                                    </TableCell>
                                    <TableCell className="runmngr-cell-type letterspacing">
                                      <img
                                        className="threedots-icon-style"
                                        name="threedots"
                                        src={threeDots}
                                        alt=""
                                        onClick={(e) =>
                                          this.handleClick(e, commissionrun)
                                        }
                                        style={{ pointerEvents: "all" }}
                                      />{" "}
                                      {displayThreeDots ? (
                                        <Popover
                                          id={id}
                                          className="dots-container-view"
                                          open={open}
                                          anchorEl={this.state.anchorEl}
                                          onClose={this.handleClose}
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                          }}
                                        >
                                          <Typography sx={{ p: 2 }} component={'span'} variant={'body2'}>
                                            <div className="runmngr-dots-option-style">
                                              <span
                                                className="runmngr-dot-selection-style"
                                                onClick={() =>
                                                  this.openRunDetails()
                                                }
                                              >
                                                Open Run
                                              </span>
                                              <span
                                                className="runmngr-dot-selection-style"
                                                onClick={() =>
                                                  this.viewlogScreen(
                                                    Constants.VIEW_RUN_LOG_DISPLAY,commissionrun
                                                  )
                                                }
                                              >
                                                View Run Log
                                              </span>
                                              <span
                                                className="runmngr-dot-selection-style"
                                                onClick={() =>
                                                  this.handleDeleteRun(
                                                    commissionrun
                                                  )
                                                }
                                              >
                                                Delete Run
                                              </span>
                                            </div>
                                          </Typography>
                                        </Popover>
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  </TableRow>
                                ))
                              : ""}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                    {commissionRunState.length === 0 ? (
                      <div className="row noreports">
                        <img src={noreports} alt="" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </>
            )}
            {isdisplayEyeModel ? (
              <EyeModel
                title="Run Info"
                selectedRun={selectedValueRun}
                closeModel={this.toggleDisplayEye.bind(
                  this,
                  Constants.CLOSE_ACTION
                )}
                enableEyeModel={this.toggleDisplayEye.bind(
                  this,
                  Constants.OPEN_ACTION
                )}
              />
            ) : (
              ""
            )}
            {isdisplayNewrunModel && commissionPeriods.length > 0 ? (
              <CreateNewRun
                newrunmodelbackfunc={this.handleNewrunCreateModel.bind(this)}
                defaultvalues={commissionTypes}
                disableprelimrun={prelimRunDisable}
                defaultPeriodValue={
                  selectedCommissionPeriodIndex > 1
                    ? getMonthDays(
                        commissionPeriods[selectedCommissionPeriodIndex].name
                      )
                    : allNullTypeCheck(
                        commissionTypes[selectTypeIndex]?.defaultPeriod
                      )
                }
              />
            ) : (
              ""
            )}

            {isDisplayDeleteModel ? (
              <DeleteRunModel
                title="Delete Run"
                selectedRun={selectedRunDetails}
                enableEyeModel={this.toggleDisplayDeleteModel.bind(this)}
                closeDeleteModal = {this.closeDeleteModal.bind(this)}
              />
            ) : (
              ""
            )}
            {isCompareModelOn ? this.handleCompareMethod() : ""}
          </div>
          {loader}
          <NewSnackbar />
        </div>
      );
    } else
      return (
        <div>
          <div
            id="center"
            style={{ position: "fixed", top: "40%", left: "48%" }}
          >
            <Oval
              position="fixed"
              secondaryColor="#E85557"
              color="#E85557"
              height={80}
              width={80}
              visible={true}
            />{" "}
          </div>
        </div>
      );
  }
}

CommissionRunManager.propTypes = {
  newrunmodelbackfunc: PropTypes.func,
};
const mapStateToProps = (state) => {
  return {
    commissionRunList: state.commissionRunReducer.commissionRunList,
    commissionTypeError: state.commissionToolsReducer.commissionTypeError,
    commissionTypeList: state.commissionToolsReducer.commissionTypeList,
    commissionRunListError: state.commissionRunReducer.commissionRunListError,
    commissionPeriodList: state.commissionToolsReducer.commissionPeriodList,
    commissionPeriodError: state.commissionToolsReducer.commissionPeriodError,
    selectedCompareRunsList: state.commissionToolsReducer.selectedCompareRunsList
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCommissionRunList: () => dispatch(getCommissionRunAPIList()),
  selectedRunIndex: (selectedRun) =>
    dispatch({ type: COMMISSION_SELECTED_RUN, payload: selectedRun }),
  updateTabValue:(value) => dispatch({ type: BROWSER_BACK_BUTTON_HANDLETAB, payload: value }),
  getCommissionPeriod:(periodtype) =>  dispatch(getCommissionPeriodListAPI(periodtype)),
  getCommissionTypes:() => dispatch(getCommissionTypeListAPI()),
  showSnackbar: (params) => dispatch({ type: actions.SNACKBAR_SHOW, payload: params}),
  // addSelectedcompareRuns:(selectedList) =>  dispatch({ type: actions.FETCH_SELECTED_RUN_LIST, payload: selectedList}),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CommissionRunManager));
