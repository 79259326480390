import React from "react";
import Component1 from "../Component1";
import "./IntegrationManager.css";
import { Oval } from "react-loader-spinner";
import AllIntegrations from "../AllIntegrations/index";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import actions from '../../../redux/actions/CommonDataActions';
import { getIntegrationsList } from '../../../redux/thunk/integrationAPIThunk'
import { errorHandling } from '../../../components/utilities/Services'
import AuthService from '../../server/AuthService';
import * as Constants from '../../../constants/Constants';
import actions1 from '../../../redux/actions/IntegrationPopUp'
import CreateIntegration from '../CreateIntegrations/index'
import IntegrationName from '../IntegrationName/index'
import CompleteIntegration from '../CompleteIntegration/index'
import '../PrimaryLargeDefault/PrimaryLargeDefault.css'
import { Modal } from 'react-bootstrap'
import '../CompleteIntegration/CompleteIntegration.css'
import "../IntegrationName/IntegrationName.css";
import '../SecondaryButtonDefault/SecondaryButtonDefault.css'
import landingImage from '../../../assets/landingImage.svg';
import ViewSummary from '../ViewSummary/index'
import NewSnackbar from "../../utilities/NewSnackbar";

class IntegrationManager extends React.Component {
  constructor(props) {
    super(props)
    this.landingHandleComponentBack = this.landinghandleComponentBack.bind(this);
    this.state = {
      showImage: false,
      allIntegrations: true,
      AppsList: [],
      showModal: false,
      showIntegrationName: false,
      ShowCompleteIntegration: false,
      showLoader: true,
      show: false,
      showIntegrations: true,
      defaultTitle: 'My Integrations',
      showSummaryPage: false,
      tempValue: '',
      summaryShow: false,
      summaryText: 'Integrationname',
      editShow: false
    }
    this.selectSummary = PropTypes.object;
    this.wrapperRef = React.createRef();
    this.location = Object;
    this.history = Object;
  }
  componentDidMount() {
    if (AuthService.isTokenValidCheck() === true) {
      this.props.getIntegrations();
    }
  }
  createIntegrationPopUp() {
    this.setState({ show: true })
    // localStorage.removeItem("testintegration")
    console.log('count', this.state.AppsList.length)
    if (this.state.AppsList.length > 0) {
      localStorage.removeItem("testintegration")
      this.setState({ showSummaryPage: false })
      localStorage.setItem("LandingIntegrations", "AllIntegrations")
    }
    else {
      localStorage.removeItem("LandingIntegrations")
    }
    this.props.showCreateIntegrationPopup()
  }
  componentDidUpdate(nextProps) {
    if (this.props.isShowTopIntegrationOption !== nextProps.isShowTopIntegrationOption) {
      console.log('top Integration Option', this.props.isShowTopIntegrationOption)
      this.setState({ showIntegrations: this.props.isShowTopIntegrationOption })
    }
    if (this.props.isIntegrationLandingModel !== nextProps.isIntegrationLandingModel) {
      console.log('isIntegrationLandingModel', this.props.isShowTopIntegrationOption)

      this.setState({ showModal: this.props.isIntegrationLandingModel })
      if (this.props.isIntegrationLandingModel === true) {
        this.setState({ ShowCompleteIntegration: false, show: true })
      }
      else {
        this.setState({ show: false })
      }
    }
    if (this.props.isIntegrationNameModel !== nextProps.isIntegrationNameModel) {
      this.setState({ showIntegrationName: this.props.isIntegrationNameModel })
      if (this.props.isIntegrationNameModel === true) {
        this.setState({ show: true })
      }
      else {
        this.setState({ show: false })
      }
    }
    if (this.props.isIntegrationConfirmModel !== nextProps.isIntegrationConfirmModel) {
      this.setState({ ShowCompleteIntegration: this.props.isIntegrationConfirmModel })
      if (this.props.isIntegrationConfirmModel === true) {
        this.setState({ showModal: false, showIntegrationName: false, show: true })
      }
      else {
        this.setState({ show: false })
      }
    }
    if (this.props.isViewIntegrationsShowSummary !== nextProps.isViewIntegrationsShowSummary) {
      console.log('this.props.isViewIntegrationsShowSummary', this.props.isViewIntegrationsShowSummary)
      if (this.props.isViewIntegrationsShowSummary === true) {
        this.setState({ defaultTitle: 'My Integrations', summaryText: this.props.currentIntegrationName, summaryShow: true, editShow: false })
      }
      else if (this.props.isEditIntegrationItem === true) {
        this.setState({ defaultTitle: 'My Integrations', editShow: true,  })
      }
      else {
        this.setState({ defaultTitle: 'My Integrations', editShow: false, summaryShow: false, summaryText: '' })
      }
    }
    if (this.props.getIntegrationResponse !== nextProps.getIntegrationResponse) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Get integration ", this.props.getIntegrationResponse) }
      if (this.props.getIntegrationResponse.error === false) {
        console.log("first integration Details", this.props.getIntegrationResponse.data[0]);
        // if(this.props.isViewIntegrationsShowSummary === true){
        let summaryData = localStorage.getItem("testintegration")
        if (summaryData === "summarypage") {
          this.setState({ showSummaryPage: true, showIntegrations: false })
        }
      }
    } else if ((this.props.getIntegrationError !== nextProps.getIntegrationError)) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Get integration error ", this.props.getIntegrationError) }
      this.setState({ showLoaderResult: false });
      errorHandling(this.props.getIntegrationError);
    }
    if (this.props.integrationList !== nextProps.integrationList) {
      this.props.showTopIntegrationOption()
      if (this.props.integrationList.error === false) {
        this.setState({ showLoader: false })
        if (this.props.integrationList.data.length > 0) {
          //  console.log('integrations list',this.props.integrationList.data)
          this.setState({ AppsList: this.props.integrationList.data, showLoader: false, showSummaryPage: false })
        }
      }
      else if (this.props.integrationList.error) {
        this.setState({ showLoader: false })
        //tempNegativeAlert(this.props.integrationList.messages[0], 5000, Constants.RED_COLOR);
        this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.warning, snackbarMessage: this.props.integrationList.messages[0] })
      }
    } else if (this.props.integrationError !== nextProps.integrationError) {
      this.setState({ showLoader: false })
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("integration Manager Details", this.props.integrationError); }
      errorHandling(this.props.integrationError);
    }
  }
  landinghandleComponentBack(name, params) {
    // console.log('name:',name,'params:',params)
    this.setState({ showSummaryPage: false, showIntegrations: true })
    this.setState({ defaultTitle: 'My Integrations' })
    localStorage.removeItem("testintegration")
    localStorage.removeItem("LandingIntegrations")
    this.props.getIntegrations();
  }
  // this.handleComponentClick(name,this.state.results);
  render() {
    const {
      title,
      text1,
      text8,
    } = this.props;

    let loader = ''
    if (this.state.showLoader) {
      return <div className="overlap-group">
        <div id="center" style={{ position: "fixed", bottom: "40%", left: "48%" }}>
          <Oval
            type="Puff"
            position="fixed"  secondaryColor="#E85557"
            color="#E85557"
            height={50}
            width={50}
            timeout={30000}
          />
        </div>
      </div>
    }
    let integrationPopupSelect = <Modal show={this.state.show}>
      {this.state.showModal ? <CreateIntegration></CreateIntegration> : null}
      {this.state.showIntegrationName ? <IntegrationName></IntegrationName> : null}
      {this.state.ShowCompleteIntegration ? <CompleteIntegration></CompleteIntegration> : null}
      <Modal.Body>
      </Modal.Body>
    </Modal>
    return (
      <div className="overlap-group">
        <Component1 text19={this.state.defaultTitle} summary={this.state.summaryShow} edit={this.state.editShow} summarytext={this.state.summaryText}/>
        {this.state.showIntegrations ? <div className="flex-row">
          <div className="flex-col">
            <div>
              <h1 className="title lato-bold-mine-shaft-32px">{title}</h1>
              <p className="text-1 lato-normal-mine-shaft-14px">{text1}</p> </div>
          </div>
          <div className="primary-large-default-1111" onClick={this.createIntegrationPopUp.bind(this)}>
            <div className="button-i42756375 valign-text-middle lato-bold-white-14px" >CREATE INTEGRATION</div>
          </div>
        </div> : ''}
        {integrationPopupSelect}
        {/* {this.state.showModal ? <CreateIntegration  val= {this.state.showModal}></CreateIntegration>:null} */}
        {/* <img className="line-42" src={line42} /> */}
        {this.state.showSummaryPage
          ? <ViewSummary func={this.handleComponentClick} params={this.props.currentIntegrationID} backfunc={this.landingHandleComponentBack} options='LandingPage' />
          : [
            (this.state.AppsList.length > 0
              ? <AllIntegrations appslist={this.state.AppsList} status="true" /> : <div>
                <img className="x16733701286071-6326271892083n" src={landingImage} alt='' />
                <p className="text-8">{text8}</p>
              </div>
            )
          ]
        }
        {loader}
        <NewSnackbar />
      </div>
    );
  }
}
IntegrationManager.propTypes = {
  appslist: PropTypes.any,
  backfunc: PropTypes.func,
  options: PropTypes.any,
  params: PropTypes.any,
};
const mapStateToProps = state => {
  return {
    isFetchingData: state.commonDataReducer.isFetchingData,
    integrationList: state.integrationDataReducer.integrationList,
    integrationError: state.integrationDataReducer.integrationError,
    isIntegrationLandingModel: state.integrationPopUpReducers.isIntegrationLandingModel,
    isIntegrationNameModel: state.integrationPopUpReducers.isIntegrationNameModel,
    isIntegrationConfirmModel: state.integrationPopUpReducers.isIntegrationConfirmModel,
    isShowTopIntegrationOption: state.integrationPopUpReducers.isShowTopIntegrationOption,
    isViewIntegrationsShowSummary: state.integrationPopUpReducers.isViewIntegrationsShowSummary,
    isEditIntegrationItem: state.integrationPopUpReducers.isEditIntegrationItem,
    getIntegrationResponse: state.integrationDataReducer.getIntegrationResponse,
    getIntegrationError: state.integrationDataReducer.getIntegrationError,
    currentIntegrationID: state.integrationDataReducer.currentIntegrationID,
    currentIntegrationName: state.integrationDataReducer.currentIntegrationName, 
  }
};
const mapDispatchToProps = dispatch => ({
  // showLoader: () => dispatch({ type: actions.FETCHING_DATA_LOADER, payload: true }),
  // hideLoader: () => dispatch({ type: actions.HIDE_DATA_LOADER, payload: false }),
  getIntegrations: () => dispatch(getIntegrationsList()),
  showCreateIntegrationPopup: () => dispatch({ type: actions1.SHOW_CREATE_INTEGRATIONS, payload: true }),
  hideCreateIntegrationPopup: () => dispatch({ type: actions1.HIDE_CREATE_INTEGRATIONS, payload: false }),
  showIntegrationNamePopup: () => dispatch({ type: actions1.SHOW_INTEGRATIONS_NAME, payload: true }),
  hideIntegrationNamePopup: () => dispatch({ type: actions1.HIDE_INTEGRATIONS_NAME, payload: false }),
  showCompleteIntegrationPopup: () => dispatch({ type: actions1.SHOW_COMPLETE_INTEGRATIONS, payload: true }),
  hideCompleteIntegrationPopup: () => dispatch({ type: actions1.HIDE_COMPLETE_INTEGRATIONS, payload: false }),
  showTopIntegrationOption: () => dispatch({ type: actions1.SHOW_TOP_INTEGRATIONS, payload: true }),
  hideTopIntegrationOption: () => dispatch({ type: actions1.HIDE_TOP_INTEGRATIONS, payload: false }),
  hideViewIntegrationsShowSummary: () => dispatch({ type: actions1.HIDE_COMPLETE_SUMMARY_INTEGRATIONS, payload: false }),
  showSnackbar: (params) => dispatch({ type: actions.SNACKBAR_SHOW, payload: params }),
});
export default connect(mapStateToProps, mapDispatchToProps)(IntegrationManager);