export const ACTIVE_PERIOD_MAINTENANCE_FETCHING = 'ACTIVE_PERIOD_MAINTENANCE_FETCHING';
export const ACTIVE_PERIOD_MAINTENANCE_SUCCESS = 'ACTIVE_PERIOD_MAINTENANCE_SUCCESS';
export const ACTIVE_PERIOD_MAINTENANCE_ERROR = 'ACTIVE_PERIOD_MAINTENANCE_ERROR';
export const OPEN_NEW_COMM_PERIOD_SUCCESS = 'OPEN_NEW_COMM_PERIOD_SUCCESS';
export const OPEN_NEW_COMM_PERIOD_ERROR = 'OPEN_NEW_COMM_PERIOD_ERROR';
export const GET_ALL_SETTINGS_PERIODS = 'GET_ALL_SETTINGS_PERIODS';
export const GET_ALL_SETTINGS_ERROR = 'GET_ALL_SETTINGS_ERROR';

const initialState = {
  activePeriodResponse: {
    periodTypes: {
      primary: {
        start: '',
        end: '',
        default: '',
        checkDate: '',
        last: '',
        maximum: ''
      },
      secondary: {
        checkDate: '',
        last: '',
        maximum: '',
        default:''
      },
      tertiary: {
        checkDate: '',
        last: '',
        maximum: '',
        default:''
      }
    },
    prePrintedChecks: false,
    directDeposit: false
  },
  activePeriodError: {},
  openNewCommissionResponse:{},
  openNewCommissionError:{},
  getAllSettingPeriodResponse:{},
  getAllSettingPeriodError:{},
  fetching: false,
  success: false,
  periodTypes:[]
}

export default function activePeriodMaintenanceReducer (state, action) {
  if (typeof state === 'undefined') {
    return initialState
  }
  switch (action.type) {
    case ACTIVE_PERIOD_MAINTENANCE_FETCHING:
        return { ...state, fetching: true }
    case ACTIVE_PERIOD_MAINTENANCE_SUCCESS:
      return { ...state, fetching:false, success:true, activePeriodResponse: action.payload }
    case ACTIVE_PERIOD_MAINTENANCE_ERROR:
      return { ...state, fetching: false, success:false, activePeriodError: action.payload }
    case OPEN_NEW_COMM_PERIOD_SUCCESS:
        return { ...state, fetching: false, success:true, openNewCommissionResponse: action.payload }  
    case OPEN_NEW_COMM_PERIOD_ERROR:
        return { ...state, fetching: false, success:false, openNewCommissionError: action.payload }      
    case GET_ALL_SETTINGS_PERIODS: 
        return { ...state, fetching: false, success:true, getAllSettingPeriodResponse: action.payload }      
    case GET_ALL_SETTINGS_ERROR:
        return { ...state, fetching: false, success:false, getAllSettingPeriodError: action.payload }      
    default:
      return state
  }
}
