import React, { useEffect,useState } from "react";
import './DeleteRunModel.css';
import closeIcon from '../assets/closeicon.svg';
import deleteIcon from '../assets/deleterun.svg';
import * as Constants from '../commonComponents/Constants'
import AuthService from '../../server/AuthService';
import { singletonAxiosInstance } from '../../utilities/Server';
import Endpoints from '../../utilities/Endpoints';
import { isNullResponse } from "../commonComponents/UtilityFun";
import {useSelector, useDispatch} from 'react-redux'
import { Oval } from 'react-loader-spinner';
import {COMMRUN_DELETERUN_SUCCESS, COMMRUN_DELETERUN_ERROR } from "../../../components/Commissions/actions";
import * as Constant from '../../../constants/Constants';
import actions from "../../../redux/actions/CommonDataActions";
import { Modal} from "react-bootstrap";


const server = singletonAxiosInstance;
const options = {
 Authorization: 'Bearer ' + localStorage.getItem('id_token')
 // Authorization: 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjU4MjQyMTVEODhGQkE4RTEzQkZGN0FFNTk0MzAyODM5RkU1M0ZGOENSUzI1NiIsInR5cCI6ImF0K2p3dCIsIng1dCI6IldDUWhYWWo3cU9FN18zcmxsREFvT2Y1VF80dyJ9.eyJuYmYiOjE2MzM0OTA0MzksImV4cCI6MTY2NTAyNjQzOSwiaXNzIjoiaHR0cHM6Ly9kZXYtYXRvbWljLmZsZXhjbG91ZC5jb20vaWRlbnRpdHkiLCJjbGllbnRfaWQiOiIwZDkzNGI5MS03NjA3LTQ3OWUtYjMyOS0xOTAyNjhiZmRjZDIiLCJjbGllbnRfbmFtZSI6ImRldi1hdG9taWMiLCJqdGkiOiIxNTk0MTA3RDFGRjM0RTMyNDcwMTZGRjE4Q0Q2QjdERCIsImlhdCI6MTYzMzQ5MDQzOSwic2NvcGUiOlsiL2NvbW1pc3Npb25zIl19.VOq7hVNtlqz3yhuag0yYrqMXefpTfI3upweQy2DUt9mO6Wd6iHhd8S3JCamTCYY-Fr8rlxIPEXNb8Rx7fg1e4LCrcXVQoOLVg65QBL3r2xuMQE7PQ_c4MF9C61xxQWrFAC2jjvNWztrb5pcv9B73XVv1MCJVajxnYeyrKVdxP3VK8R9L_OHqEzpXzKfz6PW0BiXgvPIl92NtHDE1mvC6YXXXcVwHuTAFBu8tZdvskr_wm0iNaY7niY8QzKXvY5DkWFdknaSeGP9HJwukdGzdY-ObHg5JDl2Swb0ienhOqQ4mKrIA1_kU9tcfk6k4dAPpYkvwcwPB-WQfNECuB1qxJw'
  ,
  data:{
    reason:"Deleting this run"
  } 
};
const statCodes = {
  SUCCESS: 200,
  CREATE_SUCCESS: 201,
  unAuthorized: 403
};
export const DeleteRunModel = (props) => {
    const { title, selectedRun, enableEyeModel, closeDeleteModal } = props;
    const [loader, setLoader] = useState(false);
    const commRunDeleted = useSelector(state => state.commissionRunReducer.deleteRunResponse);
    const commRunError = useSelector(state => state.commissionRunReducer.deleteRunError)
    console.log("result : ",selectedRun);

    const dispatch = useDispatch();
    const handleDeleteRun=()=>{
      
      // window.open(URL, '_blank');
      setLoader(true);
      dispatch(callDeleteService(selectedRun.runId));
      
  }
  if(isNullResponse(commRunDeleted)){
    props.closeDeleteModal();
    dispatch({ type: actions.SNACKBAR_SHOW, payload: {snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.success, snackbarMessage:'Run Deleted Successfully'}});
  }
  const callDeleteService = (runID) => {
    return dispatch => {
      if (AuthService.isTokenValid() === true) {
        server.delete(Endpoints.CommissionRuns+'/'+runID, {reason:"delete selected run "}, options).then((data) => {
          setLoader(false);
          if (data.status === statCodes.SUCCESS) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRun delete res :', data); }
            dispatch({ type: COMMRUN_DELETERUN_SUCCESS, payload: data.data });
          } else {
            dispatch({ type: COMMRUN_DELETERUN_ERROR, payload: data });
          }
          props.closeDeleteModal()
         // props.enableEyeModel()
        }).catch((error) => {
         
          setLoader(false);
          props.enableEyeModel()
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
          if (!error.response) {
            dispatch({ type: COMMRUN_DELETERUN_ERROR, payload: error });
          } else {
            dispatch({ type: COMMRUN_DELETERUN_ERROR, payload: error.response });
          }
          dispatch({ type: actions.SNACKBAR_SHOW, payload: {snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage:'Something Went Wrong'}});
         
        });
        
      } else {
        const user = AuthService.signinSilentToken();
      }
      
    }
}
  if(loader){   
    return <div><div id="center" style={{ position: 'fixed', top: '40%', left: '48%' }}>
    <Oval type="Puff" position="fixed"  secondaryColor="#E85557" color="#E85557" height={50} width={100} visible={true} /> </div></div>;
  } 
    return(
      //   <div className='popup' style={{ opacity: '1.0'}}>
      //   <div className='deletePopup_view'>
      //     <div style={{minHeight:'268px'}}>
      //       <div style={{display:'flex'}}>
      //           <h2 className='title-style'>{title}</h2>
      //           <img className="close-icon" onClick={enableEyeModel} src={closeIcon} alt=''  />
      //       </div>
      //       <hr style={{stroke:'solid #DDDDDD'}}/>
      //       <div className='delte-model-container'>
      //          <div style={{display:'flex'}}>
      //            <div>
      //             <img className="delete-icon" onClick={enableEyeModel} src={deleteIcon} alt=''  /></div>
      //             <div>
      //               <h2 className='delete-confirm-style'>{Constants.DELETE_CONFIRMATION_TEXT}{selectedRun.name+' ?'}</h2>
      //               <span className='delete-action-style delete-model-span'>{Constants.DELETE_ACTIONS_TEXT}</span>
      //             </div>
                  
      //          </div>
      //       </div>
      //       <div>
      //           <hr style={{stroke:'solid #DDDDDD'}}/>
      //           {/* <Button className="openrun-button-style">OPEN RUN</Button> */}
      //           <div className='delete-button-layout'>
      //             <div className="cancel-button-style" onClick={props.enableEyeModel} >
      //                 <div className="cancelrun-label">CANCEL</div>
      //             </div>
      //             <div className="deleterun-button-style" onClick={handleDeleteRun} >
      //                 <div className="deleterun-label" >DELETE RUN</div>
      //             </div>
      //           </div>
      //       </div>
      //     </div>
      //   </div>
      // </div>
      <div>
      <Modal dialogClassName="delete-model" show={enableEyeModel} onHide={false} >
      <Modal.Header>
          <div className="delte-model-container">
            <div className="header-name-modal h3-header-3-18pt-bold-lato">{title}</div>
            <img className="close-vector-3-ei" src={closeIcon} onClick={enableEyeModel} alt="" />
          </div>
      </Modal.Header>
      <Modal.Body style={{height: '140px'}}>
        <div style={{display:'flex',paddingLeft:'20px',paddingTop:'8px'}}>
                    <div>
                    <img className="delete-icon" onClick={enableEyeModel} src={deleteIcon} alt=''  /></div>
                    <div>
                      <h2 className='delete-confirm-style'>{Constants.DELETE_CONFIRMATION_TEXT}{selectedRun.name+' ?'}</h2>
                      <span className='delete-action-style delete-model-span'>{Constants.DELETE_ACTIONS_TEXT}</span>
                    </div>
                    
        </div>
      </Modal.Body>
      <Modal.Footer style={{marginTop: "0px"}}>
        <div className= "row">
        <div className="cmcolb-no-btn column" onClick={props.enableEyeModel}>
        <div className="colb-no-text">CANCEL</div>
        </div>
        <div className="confirm-yes-btn column" onClick={handleDeleteRun}>
          <div className="confirm-yes-text">{'DELETE RUN'}</div>
        </div>
        </div>
      </Modal.Footer>
    </Modal>
      {/* <NewSnackbar/> */}
      </div>
    );
    
}