import jwt from 'jwt-decode';

export const allNullTypeCheck=(value)=>{
    if (value !== null && value !== undefined && value !== '') {
        return true;
    } else {
        return false;
    }
}
export const isNullResponse=(value)=>{
    if(value!==null && value!==undefined && value!=='' && typeof(value) !== "string" && Object.keys(value).length!==0){
        return true;
    }else {
        return false;
    }
}
export const getCleintNameEnvironment=()=> {
    const id_token = localStorage.getItem('id_token');
    if (id_token !== "undefined" && allNullTypeCheck(id_token)) {
     return jwt(id_token)?.client_name;
    }
    else {
      console.log('Token is empty')
    }
  }


        