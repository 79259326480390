import { REPORTSLIST_FETCHING, REPORTS_LIST_SUCCES, REPORT_CATEGORY, REPORT_HTML_PARAMS_UPDATE, REPORT_LINKS, REPORT_LIST_ERROR, REPORT_NAME, REPORT_PARAMS, UPDATED_REPORT_PARAMS } from "../actions"


const initialState = {
    reportsListResponse:{},
    reportsListError:{},
    reportLink:{},
    reportParamRes: {},
    reportName:'',
    reportCategory:'',
    updatedReportParams:[],
    htmlParamUpdateLeftMenu:[],
    fetching: false,
    success: false,
  }
export default function reportsMainReducer (state, action) {
    if (typeof state === 'undefined') {
      return initialState
    }
    switch (action.type) {
      case REPORTSLIST_FETCHING:
          return { ...state, fetching: true }
      case REPORTS_LIST_SUCCES:
          return { ...state, fetching:false, success:true, reportsListResponse: action.payload }    
      case REPORT_LIST_ERROR:
          return { ...state, fetching:false, success:false, reportsListError: action.payload }    
      case REPORT_LINKS:
        return { ...state, reportLink: action.payload }    
      case REPORT_PARAMS:
          return { ...state, fetching:false, success:true, reportParamRes: action.payload }  
      case REPORT_NAME:
          return { ...state, reportName:action.payload }
      case REPORT_CATEGORY:    
          return { ...state, reportCategory:action.payload }
      case UPDATED_REPORT_PARAMS: 
          return { ...state, updatedReportParams:action.payload }
      case REPORT_HTML_PARAMS_UPDATE:
          return { ...state, htmlParamUpdateLeftMenu:action.payload }    
      default:
        return state
    }
  }
  