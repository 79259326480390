import AuthService from '../../server/AuthService';
import { singletonAxiosInstance } from '../../utilities/Server';
import Endpoints from '../../utilities/Endpoints';
import axios from 'axios';
import { allNullTypeCheck, isNullResponse } from '../commonComponents/UtilityFun';
import actions from '../../../redux/actions/CommonDataActions';
import * as Constants from '../../../constants/Constants'
import { PRIMARY_PROGRAM_DATA_SUCCESS, PROGRAM_DATA_ERROR } from '../Settings/reducers/manageProgramsReducer';
// import * as polly from 'polly-js';

export const BROWSER_BACK_BUTTON_HANDLETAB = 'BROWSER_BACK_BUTTON_HANDLETAB';
export const COMMISSIONRUNLIST_SUCCESS = 'COMMISSIONRUNLIST_SUCCESS';
export const COMMISSIONRUNLIST_ERROR = 'COMMISSIONRUNLIST_ERROR';
export const COMMISSIONRUNLOGS_SUCCESS = 'COMMISSIONRUNLOGS_SUCCESS';
export const COMMISSIONRUNLOGS_ERROR = 'COMMISSIONRUNLOGS_ERROR';
export const COMMISSIONSRUNDETAILS_SUCCESS = 'COMMISSIONSRUNDETAILS_SUCCESS';
export const COMMISSIONSRUNDETAILS_ERROR = 'COMMISSIONSRUNDETAILS_ERROR';
export const COMMISSION_PREVIOUS_RUNDETAILS_SUCCESS = 'COMMISSION_PREVIOUS_RUNDETAILS_SUCCESS';
export const COMMISSION_PREVIOUS_RUNDETAILS_ERROR = 'COMMISSION_PREVIOUS_RUNDETAILS_ERROR';
export const ISDISPLAY_EYE_MODEL = "ISDISPLAY_EYE_MODEL";
export const COMMISSION_SELECTED_RUN = 'COMMISSION_SELECTED_RUN';
export const COMMISSIONSELECTED_RUN_SUCCESS = 'COMMISSIONSELECTED_RUN_SUCCESS';
export const COMMISSIONSELECTED_RUN_ERROR = 'COMMISSIONSELECTED_RUN_ERROR';
export const SELECTED_QUALRUN_SUCCESS = 'SELECTED_QUALRUN_SUCCESS';
export const SELECTED_QUALRUN_ERROR = 'SELECTED_QUALRUN_ERROR';
export const RUN_STATUS_SUCCESS ='RUN_STATUS_SUCCESS';
export const RUN_STATUS_ERROR ='RUN_STATUS_ERROR';
export const CURRENT_RUN_ID = 'CURRENT_RUN_ID';
export const COMMISSION_TYPE_SUCCESS = 'COMMISSION_TYPE_SUCCESS';
export const COMMISSION_TYPE_ERROR = 'COMMISSION_TYPE_ERROR';
export const RUNINFODIST_SUCCESS = 'RUNINFODIST_SUCCESS';
export const RUNINFODIST_ERROR = 'RUNINFODIST_ERROR';
export const COMMISSION_PERIOD_SUCCESS = 'COMMISSION_PERIOD_SUCCESS';
export const COMMISSION_PERIOD_ERROR = 'COMMISSION_PERIOD_ERROR';
export const DIST_RESEARCH_SELECTEDOPTIONS = 'DIST_RESEARCH_SELECTEDOPTIONS';
export const DISTRIBUTOR_RUNINFO_SUCCESS = 'DISTRIBUTOR_RUNINFO_SUCCESS';
export const DISTRIBUTOR_RUNINFO_ERROR = 'DISTRIBUTOR_RUNINFO_ERROR';
export const DISTRIBUTOR_VOLUME_SUCCESS = 'DISTRIBUTOR_VOLUME_SUCCESS';
export const DISTRIBUTOR_VOLUME_ERROR = 'DISTRIBUTOR_VOLUME_ERROR';
export const DISTRIBUTOR_HISTORY_SUCCESS = 'DISTRIBUTOR_HISTORY_SUCCESS';
export const DISTRIBUTOR_HISTORY_ERROR = 'DISTRIBUTOR_HISTORY_ERROR';
export const DISTRIBUTOR_FLAGS_SUCCESS = 'DISTRIBUTOR_FLAGS_SUCCESS';
export const DISTRIBUTOR_FLAGS_ERROR = 'DISTRIBUTOR_FLAGS_ERROR';
export const DISTRIBUTOR_EARNINGS_SUCCESS = 'DISTRIBUTOR_EARNINGS_SUCCESS';
export const DISTRIBUTOR_EARNINGS_ERROR = 'DISTRIBUTOR_EARNINGS_ERROR';
export const DIST_EARNINGSDETAILS_SUCCESS = 'DIST_EARNINGSDETAILS_SUCCESS';
export const DIST_EARNINGSDETAILS_ERROR = 'DIST_EARNINGSDETAILS_ERROR';
export const DIST_WORKFIELDS_SUCCESS = 'DIST_WORKFIELDS_SUCCESS';
export const DIST_WORKFIELDS_ERROR = 'DIST_WORKFIELDS_ERROR';
export const DISTRIBUTOR_DATEMISC_SUCCESS = 'DISTRIBUTOR_DATEMISC_SUCCESS';
export const DISTRIBUTOR_DATEMISC_ERROR = 'DISTRIBUTOR_DATEMISC_ERROR';
export const DISTRIBUTOR_AMTFIELDS_SUCCESS = 'DISTRIBUTOR_AMTFIELDS_SUCCESS';
export const DISTRIBUTOR_AMTFIELDS_ERROR = 'DISTRIBUTOR_AMTFIELDS_ERROR';
export const DISTRIBUTOR_COUNTRYINFO_SUCCESS = 'DISTRIBUTOR_COUNTRYINFO_SUCCESS';
export const DISTRIBUTOR_COUNTRYINFO_ERROR = 'DISTRIBUTOR_COUNTRYINFO_ERROR';
export const DISTRIBUTOR_PAIDTO_SUCCESS = 'DISTRIBUTOR_PAIDTO_SUCCESS';
export const DISTRIBUTOR_PAIDTO_ERROR = 'DISTRIBUTOR_PAIDTO_ERROR';
export const DISTRIBUTOR_DOWNLINE_ERROR = 'DISTRIBUTOR_DOWNLINE_ERROR';
export const DISTRIBUTOR_DOWNLINE_SUCCESS = 'DISTRIBUTOR_DOWNLINE_SUCCESS';
export const DOWNLINE_DIST_RANKINFO_SUCCESS = 'DOWNLINE_DIST_RANKINFO_SUCCESS';
export const DOWNLINE_DIST_RANKINFO_ERROR = 'DOWNLINE_DIST_RANKINFO_ERROR';
export const DOWNLINE_DIST_VOLUME_SUCCESS = 'DOWNLINE_DIST_VOLUME_SUCCESS';
export const DOWNLINE_DIST_VOLUME_ERROR = 'DOWNLINE_DIST_VOLUME_ERROR';
export const DISTRIBUTOR_UPLINE_ERROR = 'DISTRIBUTOR_UPLINE_ERROR';
export const DISTRIBUTOR_UPLINE_SUCCESS = 'DISTRIBUTOR_UPLINE_SUCCESS';
export const DIST_UPLINE_DROP_SUCCESS = 'DIST_UPLINE_DROP_SUCCESS';
export const DIST_UPLINE_DROP_ERROR = 'DIST_UPLINE_DROP_ERROR';
export const DIST_RESEARCH_SELECTEDTAB = 'DIST_RESEARCH_SELECTEDTAB';
export const DIST_RESEARCH_WORKFIELD_DETAILS = 'DIST_RESEARCH_WORKFIELD_DETAILS';
export const DIST_RESEARCH_WORKFIELD_ERROR = 'DIST_RESEARCH_WORKFIELD_ERROR';
export const RANK_MATRIX_DETAILS_SUCCESS ='RANK_MATRIX_DETAILS_SUCCESS';
export const RANK_MATRIX_DETAILS_ERROR = 'RANK_MATRIX_DETAILS_ERROR';

export const SELECTED_QUALRUN_STOP_SUCCESS = 'SELECTED_QUALRUN_STOP_SUCCESS';
export const SELECTED_QUALRUN_STOP_ERROR = 'SELECTED_QUALRUN_STOP_ERROR';
export const SELECTED_QUALRUN_RESET_SUCCESS = 'SELECTED_QUALRUN_RESET_SUCCESS';
export const SELECTED_QUALRUN_RESET_ERROR = 'SELECTED_QUALRUN_RESET_ERROR';
export const COMMISSION_CREATE_RUN_SUCCESS = 'COMMISSION_CREATE_RUN_SUCCESS';
export const COMMISSION_CREATE_RUN_ERROR = 'COMMISSION_CREATE_RUN_ERROR';
export const COMMISSIONSRETAILPROFIT_SUCCESS = 'COMMISSIONSRETAILPROFIT_SUCCESS';
export const COMMISSIONSRETAILPROFIT_ERROR = 'COMMISSIONSRETAILPROFIT_ERROR';
export const COMMRUN_DELETERUN_SUCCESS = 'COMMRUN_DELETERUN_SUCCESS';
export const COMMRUN_DELETERUN_ERROR = 'COMMRUN_DELETERUN_ERROR';
export const SELECTED_RUN_PROGRAMS = "SELECTED_RUN_PROGRAMS";
export const SELECTED_RUN_PROGRAMS_ERROR = "SELECTED_RUN_PROGRAMS_ERROR";
export const SELECTED_COUNTRY_INFO = "SELECTED_COUNTRY_INFO";
export const SELECTED_COUNTRY_INFO_ERROR = "SELECTED_COUNTRY_INFO_ERROR";
export const AMOUNTFIELD_MATRIX_DETAILS = "AMOUNTFIELD_MATRIX_DETAILS";
export const AMOUNTFIELD_MATRIX_ERROR = "AMOUNTFIELD_MATRIX_ERROR";
export const VOLFIELD_MATRIX_DETAILS = "VOLFIELD_MATRIX_DETAILS";
export const VOLFIELD_MATRIX_ERROR = "VOLFIELD_MATRIX_ERROR";
export const DISTRIBUTOR_QUAL_SUCCESS = "DISTRIBUTOR_QUAL_SUCCESS";
export const DISTRIBUTOR_QUAL_ERROR = "DISTRIBUTOR_QUAL_ERROR";
export const SELECTEDRUN_LOGS_SUCCESS = "SELECTEDRUN_LOGS_SUCCESS";
export const SELECTEDRUN_LOGS_ERROR = "SELECTEDRUN_LOGS_ERROR";
export const PROGRAMDATA_SUCCESS = "PROGRAMDATA_SUCCESS";
export const PROGRAMDATA_ERROR = "PROGRAMDATA_ERROR";



const server = singletonAxiosInstance;
const options = {
 Authorization: 'Bearer ' + localStorage.getItem('id_token')
// Authorization: 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjU4MjQyMTVEODhGQkE4RTEzQkZGN0FFNTk0MzAyODM5RkU1M0ZGOENSUzI1NiIsInR5cCI6ImF0K2p3dCIsIng1dCI6IldDUWhYWWo3cU9FN18zcmxsREFvT2Y1VF80dyJ9.eyJuYmYiOjE2MzM0OTA0MzksImV4cCI6MTY2NTAyNjQzOSwiaXNzIjoiaHR0cHM6Ly9kZXYtYXRvbWljLmZsZXhjbG91ZC5jb20vaWRlbnRpdHkiLCJjbGllbnRfaWQiOiIwZDkzNGI5MS03NjA3LTQ3OWUtYjMyOS0xOTAyNjhiZmRjZDIiLCJjbGllbnRfbmFtZSI6ImRldi1hdG9taWMiLCJqdGkiOiIxNTk0MTA3RDFGRjM0RTMyNDcwMTZGRjE4Q0Q2QjdERCIsImlhdCI6MTYzMzQ5MDQzOSwic2NvcGUiOlsiL2NvbW1pc3Npb25zIl19.VOq7hVNtlqz3yhuag0yYrqMXefpTfI3upweQy2DUt9mO6Wd6iHhd8S3JCamTCYY-Fr8rlxIPEXNb8Rx7fg1e4LCrcXVQoOLVg65QBL3r2xuMQE7PQ_c4MF9C61xxQWrFAC2jjvNWztrb5pcv9B73XVv1MCJVajxnYeyrKVdxP3VK8R9L_OHqEzpXzKfz6PW0BiXgvPIl92NtHDE1mvC6YXXXcVwHuTAFBu8tZdvskr_wm0iNaY7niY8QzKXvY5DkWFdknaSeGP9HJwukdGzdY-ObHg5JDl2Swb0ienhOqQ4mKrIA1_kU9tcfk6k4dAPpYkvwcwPB-WQfNECuB1qxJw'
};
const statCodes = {
  SUCCESS: 200,
  CREATE_SUCCESS: 201,
  unAuthorized: 403,
  RETRY:202,
  Scope_unAuthorized: 401
};
const mockoptions = {
  'x-api-key': 'PMAK-61ae3f447ddf090034f32226-302e860673975719598c24f34f8d72d71e',
  'x-mock-response-code':'200',
}
const mockstatusoptions = {
  'x-api-key': 'PMAK-61ae3f447ddf090034f32226-302e860673975719598c24f34f8d72d71e',
  'x-mock-response-code':'200',
  'flexcloud-mock':'false',
  'Content-Type': 'application/json'
}
export const getCommissionRunAPIList = () => {
  return dispatch => {
    if (AuthService.isTokenValid() === true) {
      server.get(Endpoints.CommissionRuns, '', options).then((data) => {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('calling user list endpoint', Endpoints.emailtemplate); }
        if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY ) {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('user redux thunk response :', data.data); }
          if (data.status === statCodes.SUCCESS){
            dispatch({ type: COMMISSIONRUNLIST_SUCCESS, payload: data.data });
          }else{
            dispatch({ type: COMMISSIONRUNLIST_SUCCESS, payload: data });
          }
        } else {
          dispatch({ type: COMMISSIONRUNLIST_ERROR, payload: data });
        }
      }).catch((error) => {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
        if (!error.response) {
          dispatch({ type: COMMISSIONRUNLIST_ERROR, payload: error });
        } else {
          dispatch({ type: COMMISSIONRUNLIST_ERROR, payload: error.response });
        }

      });
    } else {
      const user = AuthService.signinSilentToken();
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
      if (user) {
        localStorage.setItem('id_token', user.id_token);
        server.get(Endpoints.CommissionRuns, '', options).then((data) => {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('calling user list endpoint', Endpoints.user); }
          if (data.status === statCodes.SUCCESS) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("user redux thunk response :", data.data); }
            dispatch({ type: COMMISSIONRUNLIST_SUCCESS, payload: data.data });
          } else {
            dispatch({ type: COMMISSIONRUNLIST_ERROR, payload: data });
          }
        }).catch((error) => {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
          if (!error.response) {
            dispatch({ type: COMMISSIONRUNLIST_ERROR, payload: error });
          } else {
            dispatch({ type: COMMISSIONRUNLIST_ERROR, payload: error.response });
          }
        });
      }
    }
  };
};

export const getCommissionUpdateRunLogs = () => {
  return dispatch => {
    if (AuthService.isTokenValid() === true) {
      server.get(Endpoints.CommissionUpdateRunLogURL, '', options).then((data) => {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('calling commissions Update Logs', Endpoints.CommissionUpdateRunLogURL); }
        if (data.status === statCodes.SUCCESS) {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Commissions Update Logs response :', data.data); }
          dispatch({ type: COMMISSIONRUNLOGS_SUCCESS, payload: data.data });
        } else {
          dispatch({ type: COMMISSIONRUNLOGS_ERROR, payload: data });
        }
      }).catch((error) => {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
        if (!error.response) {
          dispatch({ type: COMMISSIONRUNLOGS_ERROR, payload: error });
        } else {
          dispatch({ type: COMMISSIONRUNLOGS_ERROR, payload: error.response });
        }

      });
    } else {
      const user = AuthService.signinSilentToken();
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
      if (user) {
        localStorage.setItem('id_token', user.id_token);
        server.get(Endpoints.CommissionUpdateRunLogURL, '', options).then((data) => {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('calling commissions Update Logs', Endpoints.CommissionUpdateRunLogURL); }
          if (data.status === statCodes.SUCCESS) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Commissions Update Logs redux thunk response :", data.data); }
            dispatch({ type: COMMISSIONRUNLOGS_SUCCESS, payload: data.data });
          } else {
            dispatch({ type: COMMISSIONRUNLIST_ERROR, payload: data });
          }
        }).catch((error) => {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
          if (!error.response) {
            dispatch({ type: COMMISSIONRUNLOGS_ERROR, payload: error });
          } else {
            dispatch({ type: COMMISSIONRUNLOGS_ERROR, payload: error.response });
          }
        });
      }
    }
  };
};

export const getCommRunDetails = (runID) => {
  return dispatch => {
    if (AuthService.isTokenValid() === true) {
      server.get(Endpoints.CommissionRuns + '/' + runID, '', options).then((data) => {
        if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRun Details res :', data); }
          if (data.status === statCodes.SUCCESS){
            dispatch({ type: COMMISSIONSRUNDETAILS_SUCCESS, payload: data.data });
          }else {
            dispatch({ type: COMMISSIONSRUNDETAILS_SUCCESS, payload: data });
          }
        } else {
          dispatch({ type: COMMISSIONSRUNDETAILS_ERROR, payload: data });
        }
      }).catch((error) => {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
        if (!error.response) {
          dispatch({ type: COMMISSIONSRUNDETAILS_ERROR, payload: error });
        } else {
          dispatch({ type: COMMISSIONSRUNDETAILS_ERROR, payload: error.response });
        }

      });
    } else {
      const user = AuthService.signinSilentToken();
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
      if (user) {
        localStorage.setItem('id_token', user.id_token);
        server.get(Endpoints.CommissionRuns + '/' + runID, '', options).then((data) => {
          if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRun Details res :', data.data); }
            dispatch({ type: COMMISSIONSRUNDETAILS_SUCCESS, payload: data.data });
          } else {
            dispatch({ type: COMMISSIONSRUNDETAILS_ERROR, payload: data });
          }
        }).catch((error) => {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
          if (!error.response) {
            dispatch({ type: COMMISSIONSRUNDETAILS_ERROR, payload: error });
          } else {
            dispatch({ type: COMMISSIONSRUNDETAILS_ERROR, payload: error.response });
          }
        });
      }
    }
  };
};

export const getCommPreviousRunDetails = (runID) => {
  return dispatch => {
    if (AuthService.isTokenValid() === true) {
      server.get(Endpoints.CommissionRuns + '/' + runID, '', options).then((data) => {
        if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY ) {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRun Details res :', data); }
          if (data.status === statCodes.SUCCESS){
            dispatch({ type: COMMISSION_PREVIOUS_RUNDETAILS_SUCCESS, payload: data.data });
          }else{
            dispatch({ type: COMMISSION_PREVIOUS_RUNDETAILS_SUCCESS, payload: data });
          }
        } else {
          dispatch({ type: COMMISSION_PREVIOUS_RUNDETAILS_ERROR, payload: data });
        }
      }).catch((error) => {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
        if (!error.response) {
          dispatch({ type: COMMISSION_PREVIOUS_RUNDETAILS_ERROR, payload: error });
        } else {
          dispatch({ type: COMMISSION_PREVIOUS_RUNDETAILS_ERROR, payload: error.response });
        }

      });
    } else {
      const user = AuthService.signinSilentToken();
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
      if (user) {
        localStorage.setItem('id_token', user.id_token);
        server.get(Endpoints.CommissionRuns + '/' + runID, '', options).then((data) => {
          if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY ) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRun Details res :', data.data); }
            dispatch({ type: COMMISSION_PREVIOUS_RUNDETAILS_SUCCESS, payload: data.data });
          } else {
            dispatch({ type: COMMISSION_PREVIOUS_RUNDETAILS_ERROR, payload: data });
          }
        }).catch((error) => {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
          if (!error.response) {
            dispatch({ type: COMMISSION_PREVIOUS_RUNDETAILS_ERROR, payload: error });
          } else {
            dispatch({ type: COMMISSION_PREVIOUS_RUNDETAILS_ERROR, payload: error.response });
          }
        });
      }
    }
  };
};

export const getSelectedRunLogAPI = (runID) => {
  return dispatch => {
    if (AuthService.isTokenValid() === true) {
      server.get(Endpoints.CommissionRuns+ '/' + runID + '/logs', '', options).then((data) => {
        if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRun Details res :', data); }
          if(data.status === statCodes.SUCCESS){
            dispatch({ type: COMMISSIONSELECTED_RUN_SUCCESS, payload: data.data });
          }else {
            dispatch({ type: COMMISSIONSELECTED_RUN_SUCCESS, payload: data });
          }
        } else {
          dispatch({ type: COMMISSIONSELECTED_RUN_ERROR, payload: data });
        }
      }).catch((error) => {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
        if (!error.response) {
          dispatch({ type: COMMISSIONSELECTED_RUN_ERROR, payload: error });
        } else {
          dispatch({ type: COMMISSIONSELECTED_RUN_ERROR, payload: error.response });
        }

      });
    } else {
      const user = AuthService.signinSilentToken();
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
      if (user) {
        localStorage.setItem('id_token', user.id_token);
        server.get(Endpoints.CommissionRuns+ '/' + runID + '/logs', '', options).then((data) => {
          if (data.status === statCodes.SUCCESS) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRun Details res :', data.data); }
            dispatch({ type: COMMISSIONSELECTED_RUN_SUCCESS, payload: data.data });
          } else {
            dispatch({ type: COMMISSIONSELECTED_RUN_ERROR, payload: data });
          }
        }).catch((error) => {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
          if (!error.response) {
            dispatch({ type: COMMISSIONSELECTED_RUN_ERROR, payload: error });
          } else {
            dispatch({ type: COMMISSIONSELECTED_RUN_ERROR, payload: error.response });
          }
        });
      }
    }
  };
};
export const qualRunExecussionAPICall = (runID,command, cacheKey) => {
  let headers = {}
 // cacheKey = 'commissions.runqualification.eyJwZXJpb2RUeXBlIjoiUHJpbWFyeSIsInBlcmlvZCI6IjA0LzIwMjEiLCJydW5LZXkiOjM5M30='
  if(allNullTypeCheck(cacheKey)){
    headers = {
      'flexcloud-api-cachekey': cacheKey,
      Authorization: 'Bearer ' + localStorage.getItem('id_token'),
     };
  }else{
    headers = {
      Authorization: 'Bearer ' + localStorage.getItem('id_token')
    }
  }
  console.log("Qual run executed : ",runID, headers);
  return dispatch => {
    if (AuthService.isTokenValid() === true) {
      // axios(config22).then((data) => {
      server.post(Endpoints.CommissionRuns+ '/' + runID + '/'+command, '','', headers).then((data) => {
        if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY ) {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('run qual Details res :', data); }
          if (data.status === statCodes.SUCCESS){
            dispatch({ type: SELECTED_QUALRUN_SUCCESS, payload: data.data });
          }else {
            dispatch({ type: SELECTED_QUALRUN_SUCCESS, payload: data });
          }
        } else {
          dispatch({ type: SELECTED_QUALRUN_ERROR, payload: data });
        }
      }).catch((error) => {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
        if (!error.response) {
          dispatch({ type: SELECTED_QUALRUN_ERROR, payload: error });
        } else {
          dispatch({ type: SELECTED_QUALRUN_ERROR, payload: error.response });
        }

      });
    } else {
      const user = AuthService.signinSilentToken();
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
      if (user) {
        localStorage.setItem('id_token', user.id_token);
        server.post(Endpoints.CommissionRuns+ '/' + runID + '/'+command, '','', headers).then((data) => {
          if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY ) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('run qual Details res :', data); }
            if (data.status === statCodes.SUCCESS){
              dispatch({ type: SELECTED_QUALRUN_SUCCESS, payload: data.data });
            }else {
              dispatch({ type: SELECTED_QUALRUN_SUCCESS, payload: data });
            }
          } else {
            dispatch({ type: SELECTED_QUALRUN_ERROR, payload: data });
          }
        }).catch((error) => {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
          if (!error.response) {
            dispatch({ type: SELECTED_QUALRUN_ERROR, payload: error });
          } else {
            dispatch({ type: SELECTED_QUALRUN_ERROR, payload: error.response });
          }
        });
      }
    }
  };
};
export const previousQualRunExecussionAPICall = (runID,command,previousRunId, cacheKey) => {
  let bodyObj = {"previousRunId" : previousRunId}
  let headers = {}
 // cacheKey = 'commissions.runqualification.eyJwZXJpb2RUeXBlIjoiUHJpbWFyeSIsInBlcmlvZCI6IjA0LzIwMjEiLCJydW5LZXkiOjM5M30='
  if(allNullTypeCheck(cacheKey)){
    headers = {
      'flexcloud-api-cachekey': cacheKey,
      Authorization: 'Bearer ' + localStorage.getItem('id_token'),
     };
  }else{
    headers = {
      Authorization: 'Bearer ' + localStorage.getItem('id_token')
    }
  }
 console.log("Qual run executed : ",runID, headers);
  return dispatch => {
    if (AuthService.isTokenValid() === true) {
      server.post(Endpoints.CommissionRuns+ '/' + runID + '/'+command, bodyObj,'', headers).then((data) => {
        if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY ) {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('run qual Details res :', data); }
          if (data.status === statCodes.SUCCESS){
            dispatch({ type: SELECTED_QUALRUN_SUCCESS, payload: data.data });
          }else{
            dispatch({ type: SELECTED_QUALRUN_SUCCESS, payload: data });
          }
        } else {
          dispatch({ type: SELECTED_QUALRUN_ERROR, payload: data });
        }
      }).catch((error) => {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
        if (!error.response) {
          dispatch({ type: SELECTED_QUALRUN_ERROR, payload: error });
        } else {
          dispatch({ type: SELECTED_QUALRUN_ERROR, payload: error.response });
        }

      });
    } else {
      const user = AuthService.signinSilentToken();
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
      if (user) {
        localStorage.setItem('id_token', user.id_token);
        server.post(Endpoints.CommissionRuns+ '/' + runID + '/' +command,'', bodyObj, headers).then((data) => {
          if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY ) {
            if (data.status === statCodes.SUCCESS){
              dispatch({ type: SELECTED_QUALRUN_SUCCESS, payload: data.data });
            }else{
              dispatch({ type: SELECTED_QUALRUN_SUCCESS, payload: data });
            }
          } else {
            dispatch({ type: SELECTED_QUALRUN_ERROR, payload: data });
          }
        }).catch((error) => {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
          if (!error.response) {
            dispatch({ type: SELECTED_QUALRUN_ERROR, payload: error });
          } else {
            dispatch({ type: SELECTED_QUALRUN_ERROR, payload: error.response });
          }
        });
      }
    }
  };
};
export const getRunStatusAPICall = (runID) => {
  console.log("run status api call ",runID);
  return dispatch => {
    if (AuthService.isTokenValid() === true) {
      server.get(Endpoints.CommissionRuns+ '/' + runID + '/status', '', options).then((data) => {
        if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY ) {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRun Details res :', data); }
          dispatch({ type: RUN_STATUS_SUCCESS, payload: data.data });
        } else {
          dispatch({ type: RUN_STATUS_ERROR, payload: data });
        }
      }).catch((error) => {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
        if (!error.response) {
          dispatch({ type: RUN_STATUS_ERROR, payload: error });
        } else if (error?.response?.status === statCodes.Scope_unAuthorized && typeof (error?.response?.data?.messages[0]) === 'string' && error?.response?.data?.messages[0].includes("The JSON Web Token is expired")) {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('token error', error); }
          const user = AuthService.signinSilentToken();
          if (user) {
            localStorage.setItem('id_token', user.id_token);
          }
        }
        else {
          dispatch({ type: RUN_STATUS_ERROR, payload: error.response });
        }
      });
    } else {
      const user = AuthService.signinSilentToken();
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
      if (user) {
        localStorage.setItem('id_token', user.id_token);
        server.get(Endpoints.CommissionRuns+ '/' + runID + '/status', '', options).then((data) => {
          if (data.status === statCodes.SUCCESS) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRun Details res :', data.data); }
            dispatch({ type: RUN_STATUS_SUCCESS, payload: data.data });
          } else {
            dispatch({ type: RUN_STATUS_ERROR, payload: data });
          }
        }).catch((error) => {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
          if (!error.response) {
            dispatch({ type: RUN_STATUS_ERROR, payload: error });
          } else {
            dispatch({ type: RUN_STATUS_ERROR, payload: error.response });
          }
        });
      }
    }
  };
};

export const getCommissionTypeListAPI = () => {
  console.log("commission type API call");
  return dispatch => {
    if (AuthService.isTokenValid() === true) {
      server.get(Endpoints.CommissionTypeSelectionURL, '', options).then((data) => {
        if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY ) {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionType API call data :', data); }
          if (data.status === statCodes.SUCCESS){
            dispatch({ type: COMMISSION_TYPE_SUCCESS, payload: data.data });
          }else{
            dispatch({ type: COMMISSION_TYPE_SUCCESS, payload: data });
          }
          
        } else {
          dispatch({ type: COMMISSION_TYPE_ERROR, payload: data });
        }
      }).catch((error) => {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
        if (!error.response) {
          dispatch({ type: COMMISSION_TYPE_ERROR, payload: error });
        } else {
          if(error.hasOwnProperty("response") && isNullResponse(error?.response)){
            dispatch({ type: COMMISSION_TYPE_ERROR, payload: error.response });
            if(error.hasOwnProperty("message")&&error?.message==='Network Error'){
            dispatch({ type: actions.SNACKBAR_SHOW, payload: {snackbarOpen: true, snackbarType:  Constants.SNACKBAR_TYPE.error, snackbarMessage: (typeof(error)!=='string'&&typeof(error)==='object'&& error.hasOwnProperty('message'))? error.message:error}})
            }
          }
        }

      });
    } else {
      const user = AuthService.signinSilentToken();
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
      if (user) {
        localStorage.setItem('id_token', user.id_token);
        server.get(Endpoints.CommissionTypeSelectionURL, '', options).then((data) => {
          if (data.status === statCodes.SUCCESS) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionType API call else data:', data.data); }
            dispatch({ type: COMMISSION_TYPE_SUCCESS, payload: data.data });
          } else {
            dispatch({ type: COMMISSION_TYPE_ERROR, payload: data });
          }
        }).catch((error) => {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
          if (!error.response) {
            dispatch({ type: COMMISSION_TYPE_ERROR, payload: error });
          } else {
            dispatch({ type: COMMISSION_TYPE_ERROR, payload: error.response });
          }
        });
      }
    }
  };
};
//"/runs/"+runId+"/distributors/"+distributorId+"/rankInfo"
export const getRankDistInfo = (runId,distributorId) => {
  console.log("Ranks info API call");
  return dispatch => {
    if (AuthService.isTokenValid() === true) {
      server.get(Endpoints.CommissionRuns+ '/' + runId +'/distributors/'+ distributorId + '/rankInfo', '', options).then((data) => {
        if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRunInfoDistributor data :', data); }
          if (data.status === statCodes.SUCCESS){
            dispatch({ type: RUNINFODIST_SUCCESS, payload: data.data });
          }else{
            dispatch({ type: RUNINFODIST_SUCCESS, payload: data });
          }
         
        } else {
          dispatch({ type: RUNINFODIST_ERROR, payload: data });
        }
      }).catch((error) => {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
        if (!error.response) {
          dispatch({ type: RUNINFODIST_ERROR, payload: error });
        } else {
          dispatch({ type: RUNINFODIST_ERROR, payload: error.response });
        }
      });
    } else {
      const user = AuthService.signinSilentToken();
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
      if (user) {
        localStorage.setItem('id_token', user.id_token);
        server.get(Endpoints.CommissionRuns+ '/' + runId +'/distributors/'+ distributorId + '/rankInfo', '', options).then((data) => {
          if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRunInfoDistributor data:', data.data); }
            dispatch({ type: RUNINFODIST_SUCCESS, payload: data.data });
          } else {
            dispatch({ type: RUNINFODIST_ERROR, payload: data });
          }
        }).catch((error) => {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
          if (!error.response) {
            dispatch({ type: RUNINFODIST_ERROR, payload: error });
          } else {
            dispatch({ type: RUNINFODIST_ERROR, payload: error.response });
          }
        });
      }
    }
  };
};

            

export const getCommissionPeriodListAPI = (periodtype) => {
  console.log("commission type API call");
  return dispatch => {
    if (AuthService.isTokenValid() === true) {
    
      server.get(Endpoints.CommissionPeriodSelectionURL+periodtype, '', options)
      .then((data) => {
        if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY ) { 
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionType API call data :', data); }
          if(data.status === statCodes.SUCCESS){
            dispatch({ type: COMMISSION_PERIOD_SUCCESS, payload: data.data });
          }else {
            dispatch({ type: COMMISSION_PERIOD_SUCCESS, payload: data });
          }
         
        } else {
          dispatch({ type: COMMISSION_PERIOD_ERROR, payload: data });
        }
      }).catch((error) => {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
        if (!error.response) {
          dispatch({ type: COMMISSION_PERIOD_ERROR, payload: error });
        } else {
          dispatch({ type: COMMISSION_PERIOD_ERROR, payload: error.response });
        }

      });
    } else {
      const user = AuthService.signinSilentToken();
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
      if (user) {
        localStorage.setItem('id_token', user.id_token);
        server.get(Endpoints.CommissionPeriodSelectionURL+periodtype, '', options).then((data) => {
          if (data.status === statCodes.SUCCESS) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionType API call else data:', data.data); }
            dispatch({ type: COMMISSION_PERIOD_SUCCESS, payload: data.data });
          } else {
            dispatch({ type: COMMISSION_PERIOD_ERROR, payload: data });
          }
        }).catch((error) => {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
          if (!error.response) {
            dispatch({ type: COMMISSION_PERIOD_ERROR, payload: error });
          } else {
            dispatch({ type: COMMISSION_PERIOD_ERROR, payload: error.response });
          }
        });
      }
    }
  };
};
export const getDistributorRankInfoAPI = (distID,runID) => {
  return dispatch => {
    if (AuthService.isTokenValid() === true) {
      server.get(Endpoints.CommissionRuns+'/'+runID+"/distributors/"+distID+"/rankInfo", '', options).then((data) => {
        if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRun Details res :', data); }
          if(data.status === statCodes.SUCCESS){
            dispatch({ type: DISTRIBUTOR_RUNINFO_SUCCESS, payload: data.data });
          }else{
            dispatch({ type: DISTRIBUTOR_RUNINFO_SUCCESS, payload: data });
          }
          
        } else {
          dispatch({ type: DISTRIBUTOR_RUNINFO_ERROR, payload: data });
        }
      }).catch((error) => {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
        if (!error.response) {
          dispatch({ type: DISTRIBUTOR_RUNINFO_ERROR, payload: error });
        } else {
          dispatch({ type: DISTRIBUTOR_RUNINFO_ERROR, payload: error.response });
        }

      });
    } else {
      const user = AuthService.signinSilentToken();
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
      if (user) {
        localStorage.setItem('id_token', user.id_token);
        server.get(Endpoints.CommissionRuns+'/'+runID+"/distributors/"+distID+"/rankInfo", '', options).then((data) => {
          if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRun Details res :', data.data); }
            dispatch({ type: DISTRIBUTOR_RUNINFO_SUCCESS, payload: data.data });
          } else {
            dispatch({ type: DISTRIBUTOR_RUNINFO_ERROR, payload: data });
          }
        }).catch((error) => {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
          if (!error.response) {
            dispatch({ type: DISTRIBUTOR_RUNINFO_ERROR, payload: error });
          } else {
            dispatch({ type: DISTRIBUTOR_RUNINFO_ERROR, payload: error.response });
          }
        });
      }
    }
  };
};

let retryRes = {
  "data": {
      "data": {},
      "error": false,
      "checkBack": 0,
      "messages": [
          "Worker result not ready. Try again...",
          "This result is received because the backend did not respond within 6000ms."
      ],
      "cacheKey": "",
      "fromCache": true,
      "runTime": 28
  },
  "status": 202,
  "statusText": "OK",
  "headers": {
      "content-length": "214",
      "content-type": "application/json;charset=utf-8"
  },
  "config": {
      "url": "/v1/commissions/runs/eyJwZXJpb2RUeXBlIjoiUHJpbWFyeSIsInBlcmlvZCI6IjAzLzIwMjEiLCJydW5LZXkiOjE1MH0=/runQualification",
      "method": "post",
      "data": "{\"previousRunId\":\"eyJwZXJpb2RUeXBlIjoiUHJpbWFyeSIsInBlcmlvZCI6IjAyLzIwMjEiLCJydW5LZXkiOjE4M30=\"}",
      "headers": {
          "Accept": "application/json, text/plain, */*",
          "Content-Type": "application/json;charset=utf-8",
          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjU4MjQyMTVEODhGQkE4RTEzQkZGN0FFNTk0MzAyODM5RkU1M0ZGOENSUzI1NiIsInR5cCI6IkpXVCIsIng1dCI6IldDUWhYWWo3cU9FN18zcmxsREFvT2Y1VF80dyJ9.eyJuYmYiOjE2NTEwNTgwMzcsImV4cCI6MTY1MTA3MjQzNywiaXNzIjoiaHR0cHM6Ly9kZXYtYXRvbWljLmZsZXhjbG91ZC5jb20vaWRlbnRpdHkiLCJhdWQiOiI3MDVhMWViZi1kNjIxLTRlODItOWE3ZC1mMzk3NTdkZTgxOTIiLCJub25jZSI6IjU4YTc3NGNhMWRjMTRhM2FhMDhjNjA5MjFjN2Y4ZDU1IiwiaWF0IjoxNjUxMDU4MDM3LCJzX2hhc2giOiJLcjFlc2FxempTZ2dhdGF6TVVRNktRIiwic2lkIjoiQTc4OUQwQjcwMzY3N0QxQTIwRDAwRDhCNDE0QkExMTQiLCJzdWIiOiIzYTIxODZiOC05NzI3LTQxNTUtOTliZi0zNTYyMzVkZDEwMGYiLCJhdXRoX3RpbWUiOjE2NTEwMjcyMTcsImlkcCI6ImxvY2FsIiwiY2xpZW50X25hbWUiOiJkZXYtYXRvbWljIiwiY19pZCI6IjI4OTVjMmMxLTI5ZTQtNDlhZi05ZWUyLTU3YzczMzUzZDNhYiIsImFwcGxpY2F0aW9uX2lkIjoiNzA1YTFlYmYtZDYyMS00ZTgyLTlhN2QtZjM5NzU3ZGU4MTkyIiwidXNlcm5hbWUiOiJBZG1pbjEiLCJ1c2VyX3Njb3BlcyI6Ii9pbnRlZ3JhdGlvbnMgL2lkZW50aXR5L3VzZXJzIC9hZG1pbi9tYWludGVuYW5jZSAvYWRtaW4vc2V0dGluZ3MgL2FkbWluL2JyYW5kaW5nL2dldCAvYWRtaW4vYnJhbmRpbmcvcG9zdCAvaWRlbnRpdHkvcm9sZXNjb3BlcyAvaWRlbnRpdHkvYXBwc2NvcGVzIC9pZGVudGl0eS91c2Vyc2NvcGVzIC9pZGVudGl0eS9yb2xlcyAvdGFibGVhdSAvaWRlbnRpdHkvbWFpbHRlbXBsYXRlcyAvc2RrZG9jcyAvZGlzdHJpYnV0b3JzIC90cmFuc2FjdGlvbnMgL3NldHRpbmdzIC9jb21taXNzaW9ucyIsImFtciI6WyJwd2QiXX0.cGJhlK1J6t1J63BI2Gf90ztvoFGvSLl8esgGBMSd8lQ4H4glTp9wOplmaqE4yaTc0zuDRz2Old6iLUz6mZM8wXsOqD3rOehvpFB9QLCbLlRe8XLGv07a9woZ_ZYmciPvIf3VifGAcoYL6CVHlynOf8EyZvBlBN6hI7sc5I2mUQrc7jrKZ4Fb0AHLk5D66nIyb4nNv1TD-p8aUYL2ws-B6C5v6BF6Yf9Xnb9MYMq2vnt1u9Z_uHFb4D_w4F9NfUZWxYiUdB0TzxvUPftw4hVRyekLVvloP4Qf-KBNxXp0QA4AJkpUJ0wReTXXHcWFdA0o2RNcrZUMZ34Qm8F77Ij3tg"
      },
      "params": "",
      "baseURL": "https://dev-atomic.flexcloud.com/api",
      "transformRequest": [
          null
      ],
      "transformResponse": [
          null
      ],
      "timeout": 0,
      "xsrfCookieName": "XSRF-TOKEN",
      "xsrfHeaderName": "X-XSRF-TOKEN",
      "maxContentLength": -1,
      "maxBodyLength": -1,
      "axios-retry": {
          "retryCount": 0,
          "lastRequestTime": 1651065109020
      }
  },
  "request": {}
}

export const getDistributorVolumes = (distID,runID) => {
  return dispatch => {
    if (AuthService.isTokenValid() === true) {
      server.get(Endpoints.CommissionRuns+'/'+runID+"/distributors/"+distID+"/volumes", '', options).then((data) => {
        // data = retryRes;
        if (data.status === statCodes.SUCCESS || data.status ===statCodes.RETRY) {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRun Details res :', data); }
          if (data.status === statCodes.SUCCESS){
            dispatch({ type: DISTRIBUTOR_VOLUME_SUCCESS, payload: data.data });
          }else{
            dispatch({ type: DISTRIBUTOR_VOLUME_SUCCESS, payload: data });
          }
          
        } else {
          dispatch({ type: DISTRIBUTOR_VOLUME_ERROR, payload: data });
        }
      }).catch((error) => {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
        if (!error.response) {
          dispatch({ type: DISTRIBUTOR_VOLUME_ERROR, payload: error });
        } else {
          dispatch({ type: DISTRIBUTOR_VOLUME_ERROR, payload: error.response });
        }

      });
    } else {
      const user = AuthService.signinSilentToken();
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
      if (user) {
        localStorage.setItem('id_token', user.id_token);
        server.get(Endpoints.CommissionRuns+'/'+runID+"/distributors/"+distID+"/volumes", '', options).then((data) => {
          if (data.status === statCodes.SUCCESS || data.status ===statCodes.RETRY) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRun Details res :', data.data); }
            dispatch({ type: DISTRIBUTOR_VOLUME_SUCCESS, payload: data.data });
          } else {
            dispatch({ type: DISTRIBUTOR_VOLUME_ERROR, payload: data });
          }
        }).catch((error) => {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
          if (!error.response) {
            dispatch({ type: DISTRIBUTOR_VOLUME_ERROR, payload: error });
          } else {
            dispatch({ type: DISTRIBUTOR_VOLUME_ERROR, payload: error.response });
          }
        });
      }
    }
  };
};

export const getDistributorQualification = (distID, runID, detailKey) => {
  return dispatch => {
    if (AuthService.isTokenValid() === true) {
      server.get(Endpoints.CommissionRuns+'/'+runID+"/distributorQualifications/"+distID+"/detailKey/"+detailKey, '', options).then((data) => {
        if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRun Details res :', data); }
          if (data.status === statCodes.SUCCESS){
            dispatch({ type: DISTRIBUTOR_QUAL_SUCCESS, payload: data.data });
          }else{
            dispatch({ type: DISTRIBUTOR_QUAL_SUCCESS, payload: data });
          }
        } else {
          dispatch({ type: DISTRIBUTOR_QUAL_ERROR, payload: data });
        }
      }).catch((error) => {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
        if (!error.response) {
          dispatch({ type: DISTRIBUTOR_QUAL_ERROR, payload: error });
        } else {
          dispatch({ type: DISTRIBUTOR_QUAL_ERROR, payload: error.response });
        }

      });
    } else {
      const user = AuthService.signinSilentToken();
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
      if (user) {
        localStorage.setItem('id_token', user.id_token);
        server.get(Endpoints.CommissionRuns+'/'+runID+"/distributorQualifications/"+distID+"/detailKey/"+detailKey, '', options).then((data) => {
          if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRun Details res :', data.data); }
            dispatch({ type: DISTRIBUTOR_QUAL_SUCCESS, payload: data.data });
          } else {
            dispatch({ type: DISTRIBUTOR_QUAL_ERROR, payload: data });
          }
        }).catch((error) => {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
          if (!error.response) {
            dispatch({ type: DISTRIBUTOR_QUAL_ERROR, payload: error });
          } else {
            dispatch({ type: DISTRIBUTOR_QUAL_ERROR, payload: error.response });
          }
        });
      }
    }
  };
};

export const getDistributorHistory = (runID, distID) => {
  return dispatch => {
    if (AuthService.isTokenValid() === true) {
      server.get(Endpoints.CommissionRuns+'/'+runID+"/distributors/"+distID+"/history", '', options).then((data) => {
        if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Dist history res :', data); }
          if (data.status === statCodes.SUCCESS){
            dispatch({ type: DISTRIBUTOR_HISTORY_SUCCESS, payload: data.data });
          }else{
            dispatch({ type: DISTRIBUTOR_HISTORY_SUCCESS, payload: data });
          }
        } else {
          dispatch({ type: DISTRIBUTOR_HISTORY_ERROR, payload: data });
        }
      }).catch((error) => {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
        if (!error.response) {
          dispatch({ type: DISTRIBUTOR_HISTORY_ERROR, payload: error });
        } else {
          dispatch({ type: DISTRIBUTOR_HISTORY_ERROR, payload: error.response });
        }

      });
    } else {
      const user = AuthService.signinSilentToken();
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
      if (user) {
        localStorage.setItem('id_token', user.id_token);
        server.get(Endpoints.CommissionRuns+'/'+runID+"/distributors/"+distID+"/history", '', options).then((data) => {
          if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Dist history res :', data.data); }
            dispatch({ type: DISTRIBUTOR_HISTORY_SUCCESS, payload: data.data });
          } else {
            dispatch({ type: DISTRIBUTOR_HISTORY_ERROR, payload: data });
          }
        }).catch((error) => {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
          if (!error.response) {
            dispatch({ type: DISTRIBUTOR_HISTORY_ERROR, payload: error });
          } else {
            dispatch({ type: DISTRIBUTOR_HISTORY_ERROR, payload: error.response });
          }
        });
      }
    }
  };
};

export const getDistributorEarnings = (distID, runID) => {
  return dispatch => {
    if (AuthService.isTokenValid() === true) {
      server.get(Endpoints.CommissionRuns + '/' + runID + "/distributors/" + distID + "/earnings", '', options).then((data) => {
        if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRun Details res :', data); }
          if (data.status === statCodes.SUCCESS){
            dispatch({ type: DISTRIBUTOR_EARNINGS_SUCCESS, payload: data.data });
          }else{
            dispatch({ type: DISTRIBUTOR_EARNINGS_SUCCESS, payload: data });
          }
          
        } else {
          dispatch({ type: DISTRIBUTOR_EARNINGS_ERROR, payload: data });
        }
      }).catch((error) => {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
        if (!error.response) {
          dispatch({ type: DISTRIBUTOR_EARNINGS_ERROR, payload: error });
        } else {
          dispatch({ type: DISTRIBUTOR_EARNINGS_ERROR, payload: error.response });
        }

      });
    } else {
      const user = AuthService.signinSilentToken();
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
      if (user) {
        localStorage.setItem('id_token', user.id_token);
        server.get(Endpoints.CommissionRuns + '/' + runID + "/distributors/" + distID + "/earnings", '', options).then((data) => {
          if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRun Details res :', data); }
            dispatch({ type: DISTRIBUTOR_EARNINGS_SUCCESS, payload: data.data });
          } else {
            dispatch({ type: DISTRIBUTOR_EARNINGS_ERROR, payload: data });
          }
        }).catch((error) => {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
          if (!error.response) {
            dispatch({ type: DISTRIBUTOR_EARNINGS_ERROR, payload: error });
          } else {
            dispatch({ type: DISTRIBUTOR_EARNINGS_ERROR, payload: error.response });
          }
  
        });
      }
    }
  }
}

export const getDistEarningDetails = (distID,runID,earningID) => {
  return dispatch => {
    if (AuthService.isTokenValid() === true) {
      server.get(Endpoints.CommissionRuns+'/'+runID+"/distributors/"+distID+"/earnings/"+earningID, '', options).then((data) => {
        if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRun Details res :', data); }
          if (data.status === statCodes.SUCCESS){
            dispatch({ type: DIST_EARNINGSDETAILS_SUCCESS, payload: data.data });
          }else{
            dispatch({ type: DIST_EARNINGSDETAILS_SUCCESS, payload: data });
          }
        } else {
          dispatch({ type: DIST_EARNINGSDETAILS_ERROR, payload: data });
        }
      }).catch((error) => {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
        if (!error.response) {
          dispatch({ type: DIST_EARNINGSDETAILS_ERROR, payload: error });
        } else {
          dispatch({ type: DIST_EARNINGSDETAILS_ERROR, payload: error.response });
        }

      });
    } else {
      const user = AuthService.signinSilentToken();
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
      if (user) {
        localStorage.setItem('id_token', user.id_token);
        server.get(Endpoints.CommissionRuns+'/'+runID+"/distributors/"+distID+"/earnings/"+earningID, '', options).then((data) => {
          if (data.status === statCodes.SUCCESS) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRun Details res :', data.data); }
            dispatch({ type: DIST_EARNINGSDETAILS_SUCCESS, payload: data.data });
          } else {
            dispatch({ type: DIST_EARNINGSDETAILS_ERROR, payload: data });
          }
        }).catch((error) => {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
          if (!error.response) {
            dispatch({ type: DIST_EARNINGSDETAILS_ERROR, payload: error });
          } else {
            dispatch({ type: DIST_EARNINGSDETAILS_ERROR, payload: error.response });
          }
        });
      }
    }
  };
};

export const getDistributorWorkFields = (distID, runID) => {
  return (dispatch) => {
    if (AuthService.isTokenValid() === true) {
      server
        .get(
          Endpoints.CommissionRuns +
            "/" +
            runID +
            "/distributors/" +
            distID +
            "/workFields",
          "",
          options
        )
        .then((data) => {
          if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
            if (
              process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes(
                "localhost"
              )
            ) {
              console.log("WorkFields Details res :", data);
            }
            if (data.status === statCodes.SUCCESS){
              dispatch({ type: DIST_WORKFIELDS_SUCCESS, payload: data.data });
            }else{
              dispatch({ type: DIST_WORKFIELDS_SUCCESS, payload: data });
            }
            
          } else {
            dispatch({ type: DIST_WORKFIELDS_ERROR, payload: data });
          }
        })
        .catch((error) => {
          if (
            process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes("localhost")
          ) {
            console.log("Catch Error : ", error);
          }
          if (!error.response) {
            dispatch({ type: DIST_WORKFIELDS_ERROR, payload: error });
          } else {
            dispatch({
              type: DIST_WORKFIELDS_ERROR,
              payload: error.response,
            });
          }
        });
    } else {
      const user = AuthService.signinSilentToken();
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
      if (user) {
        localStorage.setItem('id_token', user.id_token);
        server
        .get(
          Endpoints.CommissionRuns +
            "/" +
            runID +
            "/distributors/" +
            distID +
            "/workFields",
          "",
          options
        )
        .then((data) => {
          if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
            if (
              process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes(
                "localhost"
              )
            ) {
              console.log("WorkFields Details res :", data);
            }
            dispatch({ type: DIST_WORKFIELDS_SUCCESS, payload: data.data });
          } else {
            dispatch({ type: DIST_WORKFIELDS_ERROR, payload: data });
          }
        })
        .catch((error) => {
          if (
            process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes("localhost")
          ) {
            console.log("Catch Error : ", error);
          }
          if (!error.response) {
            dispatch({ type: DIST_WORKFIELDS_ERROR, payload: error });
          } else {
            dispatch({
              type: DIST_WORKFIELDS_ERROR,
              payload: error.response,
            });
          }
        });
      }
    }
  };
};

export const getDistributorDownline = (distID,runID) => {
  return dispatch => {
    if (AuthService.isTokenValid() === true) {
      server.get(Endpoints.CommissionRuns+'/'+runID+"/distributors/"+distID+"/downline", '', options).then((data) => {
        // data = retryRes;
        if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRun Details res :', data); }
          if (data.status === statCodes.SUCCESS){
            dispatch({ type: DISTRIBUTOR_DOWNLINE_SUCCESS, payload: data.data });
          }else{
            dispatch({ type: DISTRIBUTOR_DOWNLINE_SUCCESS, payload: data });
          }
        } else {
          dispatch({ type: DISTRIBUTOR_DOWNLINE_ERROR, payload: data });
        }
      }).catch((error) => {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
        if (!error.response) {
          dispatch({ type: DISTRIBUTOR_DOWNLINE_ERROR, payload: error });
        } else {
          dispatch({ type: DISTRIBUTOR_DOWNLINE_ERROR, payload: error.response });
        }

      });
    } else {
      const user = AuthService.signinSilentToken();
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
      if (user) {
        localStorage.setItem('id_token', user.id_token);
        server.get(Endpoints.CommissionRuns+'/'+runID+"/distributors/"+distID+"/downline", '', options).then((data) => {
          if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRun Details res :', data); }
            dispatch({ type: DISTRIBUTOR_DOWNLINE_SUCCESS, payload: data.data });
          } else {
            dispatch({ type: DISTRIBUTOR_DOWNLINE_ERROR, payload: data });
          }
        }).catch((error) => {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
          if (!error.response) {
            dispatch({ type: DISTRIBUTOR_DOWNLINE_ERROR, payload: error });
          } else {
            dispatch({ type: DISTRIBUTOR_DOWNLINE_ERROR, payload: error.response });
          }
  
        });
      }
    }
  }
}

export const getDistributorUpline = (distID,runID,uplineID) => {
  return dispatch => {
    if (AuthService.isTokenValid() === true) {
      server.get(Endpoints.CommissionRuns+'/'+runID+"/distributors/"+distID+"/upline?commissionTypeId="+uplineID, '', options).then((data) => {
        if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Upline Details res :', data); }
          if (data.status === statCodes.SUCCESS){
            dispatch({ type: DISTRIBUTOR_UPLINE_SUCCESS, payload: data.data });
          }else{
            dispatch({ type: DISTRIBUTOR_UPLINE_SUCCESS, payload: data });
          }
          
        } else {
          dispatch({ type: DISTRIBUTOR_UPLINE_ERROR, payload: data });
        }
      }).catch((error) => {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
        if (!error.response) {
          dispatch({ type: DISTRIBUTOR_UPLINE_ERROR, payload: error });
        } else {
          dispatch({ type: DISTRIBUTOR_UPLINE_ERROR, payload: error.response });
        }

      });
    } else {
      const user = AuthService.signinSilentToken();
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
      if (user) {
        localStorage.setItem('id_token', user.id_token);
        server.get(Endpoints.CommissionRuns+'/'+runID+"/distributors/"+distID+"/upline?commissionTypeId="+uplineID, '', options).then((data) => {
          if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Upline Details res :', data); }
            dispatch({ type: DISTRIBUTOR_UPLINE_SUCCESS, payload: data.data });
          } else {
            dispatch({ type: DISTRIBUTOR_UPLINE_ERROR, payload: data });
          }
        }).catch((error) => {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
          if (!error.response) {
            dispatch({ type: DISTRIBUTOR_UPLINE_ERROR, payload: error });
          } else {
            dispatch({ type: DISTRIBUTOR_UPLINE_ERROR, payload: error.response });
          }
  
        });
      }
    }
  }
}

export const getDistributorFlags = (runID, distID) => {
  return dispatch => {
    if (AuthService.isTokenValid() === true) {
      server.get(Endpoints.CommissionRuns+'/'+runID+"/distributors/"+distID+"/flags", '', options).then((data) => {
        if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Dist flags res :', data); }
          if (data.status === statCodes.SUCCESS){
            dispatch({ type: DISTRIBUTOR_FLAGS_SUCCESS, payload: data.data });
          }else{
            dispatch({ type: DISTRIBUTOR_FLAGS_SUCCESS, payload: data });
          }
          
        } else {
          dispatch({ type: DISTRIBUTOR_FLAGS_ERROR, payload: data });
        }
      }).catch((error) => {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
        if (!error.response) {
          dispatch({ type: DISTRIBUTOR_FLAGS_ERROR, payload: error });
        } else {
          dispatch({ type: DISTRIBUTOR_FLAGS_ERROR, payload: error.response });
        }

      });
    } else {
      const user = AuthService.signinSilentToken();
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
      if (user) {
        localStorage.setItem('id_token', user.id_token);
        server.get(Endpoints.CommissionRuns+'/'+runID+"/distributors/"+distID+"/flags", '', options).then((data) => {
          if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Dist flags res :', data.data); }
            dispatch({ type: DISTRIBUTOR_FLAGS_SUCCESS, payload: data.data });
          } else {
            dispatch({ type: DISTRIBUTOR_FLAGS_ERROR, payload: data });
          }
        }).catch((error) => {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
          if (!error.response) {
            dispatch({ type: DISTRIBUTOR_FLAGS_ERROR, payload: error });
          } else {
            dispatch({ type: DISTRIBUTOR_FLAGS_ERROR, payload: error.response });
          }
        });
      }
    }
  };
};

            
export const getDownlineDistRankInfoAPI = (distID,runID) => {
    return dispatch => {
      if (AuthService.isTokenValid() === true) {
        server.get(Endpoints.CommissionRuns+'/'+runID+"/distributors/"+distID+"/rankInfo", '', options).then((data) => {
          if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRun Details res :', data); }
            if (data.status === statCodes.SUCCESS){
              dispatch({ type: DOWNLINE_DIST_RANKINFO_SUCCESS, payload: data.data });
            }else{
              dispatch({ type: DOWNLINE_DIST_RANKINFO_SUCCESS, payload: data });
            }
            
          } else {
            dispatch({ type: DOWNLINE_DIST_RANKINFO_ERROR, payload: data });
          }
        });
      } else {
        const user = AuthService.signinSilentToken();
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
        if (user) {
          localStorage.setItem('id_token', user.id_token);
          server.get(Endpoints.CommissionRuns+'/'+runID+"/distributors/"+distID+"/rankInfo", '', options).then((data) => {
            if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
              if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRun Details res :', data.data); }
              dispatch({ type: DOWNLINE_DIST_RANKINFO_SUCCESS, payload: data.data });
            } else {
              dispatch({ type: DOWNLINE_DIST_RANKINFO_ERROR, payload: data });
            }
          }).catch((error) => {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
            if (!error.response) {
              dispatch({ type: DOWNLINE_DIST_RANKINFO_ERROR, payload: error });
            } else {
              dispatch({ type: DOWNLINE_DIST_RANKINFO_ERROR, payload: error.response });
            }
          });
        }
      }
    };
  };

export const getDistributorDateMisc = (runID, distID) => {
  return dispatch => {
    if (AuthService.isTokenValid() === true) {
      server.get(Endpoints.CommissionRuns+'/'+runID+"/distributors/"+distID+"/rankDates", '', options).then((data) => {
        if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Dist rankDates res :', data); }
          if (data.status === statCodes.SUCCESS){
            dispatch({ type: DISTRIBUTOR_DATEMISC_SUCCESS, payload: data.data });
          }else{
            dispatch({ type: DISTRIBUTOR_DATEMISC_SUCCESS, payload: data });
          }
        } else {
          dispatch({ type: DISTRIBUTOR_DATEMISC_ERROR, payload: data });
        }
      }).catch((error) => {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
        if (!error.response) {
          dispatch({ type: DISTRIBUTOR_DATEMISC_ERROR, payload: error });
        } else {
          dispatch({ type: DISTRIBUTOR_DATEMISC_ERROR, payload: error.response });
        }

      });
    } else {
      const user = AuthService.signinSilentToken();
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
      if (user) {
        localStorage.setItem('id_token', user.id_token);
        server.get(Endpoints.CommissionRuns+'/'+runID+"/distributors/"+distID+"/rankDates", '', options).then((data) => {
          if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Dist rankDates res :', data.data); }
            dispatch({ type: DISTRIBUTOR_DATEMISC_SUCCESS, payload: data.data });
          } else {
            dispatch({ type: DISTRIBUTOR_FLAGS_ERROR, payload: data });
          }
        }).catch((error) => {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
          if (!error.response) {
            dispatch({ type: DISTRIBUTOR_DATEMISC_ERROR, payload: error });
          } else {
            dispatch({ type: DISTRIBUTOR_DATEMISC_ERROR, payload: error.response });
          }
        });
      }
    }
  };
};

export const getDistributorAmountFields = (runID, distID) => {
  return dispatch => {
    if (AuthService.isTokenValid() === true) {
      server.get(Endpoints.CommissionRuns+'/'+runID+"/distributors/"+distID+"/amountFields", '', options).then((data) => {
        if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Dist AMTFIELDS res :', data); }
          if (data.status === statCodes.SUCCESS){
            dispatch({ type: DISTRIBUTOR_AMTFIELDS_SUCCESS, payload: data.data });
          }else{
            dispatch({ type: DISTRIBUTOR_AMTFIELDS_SUCCESS, payload: data });
          }
          
        } else {
          dispatch({ type: DISTRIBUTOR_AMTFIELDS_ERROR, payload: data });
        }
      }).catch((error) => {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
        if (!error.response) {
          dispatch({ type: DISTRIBUTOR_AMTFIELDS_ERROR, payload: error });
        } else {
          dispatch({ type: DISTRIBUTOR_AMTFIELDS_ERROR, payload: error.response });
        }

      });
    } else {
      const user = AuthService.signinSilentToken();
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
      if (user) {
        localStorage.setItem('id_token', user.id_token);
        server.get(Endpoints.CommissionRuns+'/'+runID+"/distributors/"+distID+"/amountFields", '', options).then((data) => {
          if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Dist AMTFIELDS res :', data.data); }
            dispatch({ type: DISTRIBUTOR_AMTFIELDS_SUCCESS, payload: data.data });
          } else {
            dispatch({ type: DISTRIBUTOR_AMTFIELDS_ERROR, payload: data });
          }
        }).catch((error) => {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
          if (!error.response) {
            dispatch({ type: DISTRIBUTOR_AMTFIELDS_ERROR, payload: error });
          } else {
            dispatch({ type: DISTRIBUTOR_AMTFIELDS_ERROR, payload: error.response });
          }
        });
      }
    }
  };
};
  
export const getDownlineDistVolumes = (distID,runID) => {
    return dispatch => {
      if (AuthService.isTokenValid() === true) {
        server.get(Endpoints.CommissionRuns+'/'+runID+"/distributors/"+distID+"/volumes", '', options).then((data) => {
          if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY ) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRun Details res :', data); }
            if (data.status === statCodes.SUCCESS){
              dispatch({ type: DOWNLINE_DIST_VOLUME_SUCCESS, payload: data.data });
            }else{
              dispatch({ type: DOWNLINE_DIST_VOLUME_SUCCESS, payload: data });
            }
            
          } else {
            dispatch({ type: DOWNLINE_DIST_VOLUME_ERROR, payload: data });
          }
        }).catch((error) => {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
          if (!error.response) {
            dispatch({ type: DOWNLINE_DIST_VOLUME_ERROR, payload: error });
          } else {
            dispatch({ type: DOWNLINE_DIST_VOLUME_ERROR, payload: error.response });
          }
  
        });
      } else {
        const user = AuthService.signinSilentToken();
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
        if (user) {
          localStorage.setItem('id_token', user.id_token);
          server.get(Endpoints.CommissionRuns+'/'+runID+"/distributors/"+distID+"/volumes", '', options).then((data) => {
            if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
              if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRun Details res :', data.data); }
              dispatch({ type: DOWNLINE_DIST_VOLUME_SUCCESS, payload: data.data });
            } else {
              dispatch({ type: DOWNLINE_DIST_VOLUME_ERROR, payload: data });
            }
          }).catch((error) => {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
            if (!error.response) {
              dispatch({ type: DOWNLINE_DIST_VOLUME_ERROR, payload: error });
            } else {
              dispatch({ type: DOWNLINE_DIST_VOLUME_ERROR, payload: error.response });
            }
          });
        }
      }
    };
};

export const getDistributorCountryInfo = (runID, distID) => {
  return dispatch => {
    if (AuthService.isTokenValid() === true) {
      server.get(Endpoints.CommissionRuns+'/'+runID+"/distributors/"+distID+"/countryInfo", '', options).then((data) => {
        if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Dist countryInfo res :', data); }
          if (data.status === statCodes.SUCCESS){
            dispatch({ type: DISTRIBUTOR_COUNTRYINFO_SUCCESS, payload: data.data });
          }else{
            dispatch({ type: DISTRIBUTOR_COUNTRYINFO_SUCCESS, payload: data });
          }
          
        } else {
          dispatch({ type: DISTRIBUTOR_COUNTRYINFO_ERROR, payload: data });
        }
      }).catch((error) => {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
        if (!error.response) {
          dispatch({ type: DISTRIBUTOR_COUNTRYINFO_ERROR, payload: error });
        } else {
          dispatch({ type: DISTRIBUTOR_COUNTRYINFO_ERROR, payload: error.response });
        }

      });
    } else {
      const user = AuthService.signinSilentToken();
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
      if (user) {
        localStorage.setItem('id_token', user.id_token);
        server.get(Endpoints.CommissionRuns+'/'+runID+"/distributors/"+distID+"/countryInfo", '', options).then((data) => {
          if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Dist countryInfo res :', data.data); }
            dispatch({ type: DISTRIBUTOR_COUNTRYINFO_SUCCESS, payload: data.data });
          } else {
            dispatch({ type: DISTRIBUTOR_COUNTRYINFO_ERROR, payload: data });
          }
        }).catch((error) => {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
          if (!error.response) {
            dispatch({ type: DISTRIBUTOR_COUNTRYINFO_ERROR, payload: error });
          } else {
            dispatch({ type: DISTRIBUTOR_COUNTRYINFO_ERROR, payload: error.response });
          }
        });
      }
    }
  };
};

export const getDistributorPaidToInfo = (runID, distID) => {
  return dispatch => {
    if (AuthService.isTokenValid() === true) {
      server.get(Endpoints.CommissionRuns+'/'+runID+"/distributors/"+distID+"/paidTo", '', options).then((data) => {
        if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Dist PAIDTO res :', data); }
          if (data.status === statCodes.SUCCESS){
            dispatch({ type: DISTRIBUTOR_PAIDTO_SUCCESS, payload: data.data });
          }else{
            dispatch({ type: DISTRIBUTOR_PAIDTO_SUCCESS, payload: data });
          }
          
        } else {
          dispatch({ type: DISTRIBUTOR_PAIDTO_ERROR, payload: data });
        }
      }).catch((error) => {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
        if (!error.response) {
          dispatch({ type: DISTRIBUTOR_PAIDTO_ERROR, payload: error });
        } else {
          dispatch({ type: DISTRIBUTOR_PAIDTO_ERROR, payload: error.response });
        }

      });
    } else {
      const user = AuthService.signinSilentToken();
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
      if (user) {
        localStorage.setItem('id_token', user.id_token);
        server.get(Endpoints.CommissionRuns+'/'+runID+"/distributors/"+distID+"/paidTo", '', options).then((data) => {
          if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Dist PAIDTO res :', data.data); }
            dispatch({ type: DISTRIBUTOR_PAIDTO_SUCCESS, payload: data.data });
          } else {
            dispatch({ type: DISTRIBUTOR_PAIDTO_ERROR, payload: data });
          }
        }).catch((error) => {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
          if (!error.response) {
            dispatch({ type: DISTRIBUTOR_PAIDTO_ERROR, payload: error });
          } else {
            dispatch({ type: DISTRIBUTOR_PAIDTO_ERROR, payload: error.response });
          }
        });
      }
    }
  };
};

export const qualRunStopAPICall = (runID,command) => {
  console.log("Qual run stop : ",runID);
  let config23 = {
    method: 'post',
    // url: 'https://dev-atomic.flexcloud.com/commissions/runs/eyB0eXBlOidwcmltYXJ5JywgcGVyaW9kOiAnMDMvMjAyMScsIGlkOiAyOX0K/stop',
    url:Endpoints.CommissionRuns+ '/' + runID + '/stop',
    headers: { 
      'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjU4MjQyMTVEODhGQkE4RTEzQkZGN0FFNTk0MzAyODM5RkU1M0ZGOENSUzI1NiIsInR5cCI6ImF0K2p3dCIsIng1dCI6IldDUWhYWWo3cU9FN18zcmxsREFvT2Y1VF80dyJ9.eyJuYmYiOjE2MzM0OTA0MzksImV4cCI6MTY2NTAyNjQzOSwiaXNzIjoiaHR0cHM6Ly9kZXYtYXRvbWljLmZsZXhjbG91ZC5jb20vaWRlbnRpdHkiLCJjbGllbnRfaWQiOiIwZDkzNGI5MS03NjA3LTQ3OWUtYjMyOS0xOTAyNjhiZmRjZDIiLCJjbGllbnRfbmFtZSI6ImRldi1hdG9taWMiLCJqdGkiOiIxNTk0MTA3RDFGRjM0RTMyNDcwMTZGRjE4Q0Q2QjdERCIsImlhdCI6MTYzMzQ5MDQzOSwic2NvcGUiOlsiL2NvbW1pc3Npb25zIl19.VOq7hVNtlqz3yhuag0yYrqMXefpTfI3upweQy2DUt9mO6Wd6iHhd8S3JCamTCYY-Fr8rlxIPEXNb8Rx7fg1e4LCrcXVQoOLVg65QBL3r2xuMQE7PQ_c4MF9C61xxQWrFAC2jjvNWztrb5pcv9B73XVv1MCJVajxnYeyrKVdxP3VK8R9L_OHqEzpXzKfz6PW0BiXgvPIl92NtHDE1mvC6YXXXcVwHuTAFBu8tZdvskr_wm0iNaY7niY8QzKXvY5DkWFdknaSeGP9HJwukdGzdY-ObHg5JDl2Swb0ienhOqQ4mKrIA1_kU9tcfk6k4dAPpYkvwcwPB-WQfNECuB1qxJw'
    }
  }
  
  return dispatch => {
    if (AuthService.isTokenValid() === true) {
      server.post(Endpoints.CommissionRuns+ '/' + runID + '/stop', '','', options).then((data) => {
        if (data.status === statCodes.SUCCESS) {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('run qual stop res :', data); }
          dispatch({ type: SELECTED_QUALRUN_STOP_SUCCESS, payload: data.data });
        } else {
          dispatch({ type: SELECTED_QUALRUN_STOP_ERROR, payload: data });
        }
      }).catch((error) => {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
        if (!error.response) {
          dispatch({ type: SELECTED_QUALRUN_STOP_ERROR, payload: error });
        } else {
          dispatch({ type: SELECTED_QUALRUN_STOP_ERROR, payload: error.response });
        }

      });
    } else {
      const user = AuthService.signinSilentToken();
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
      if (user) {
        localStorage.setItem('id_token', user.id_token);
        server.post(Endpoints.CommissionRuns+ '/' + runID + '/stop', '','', options).then((data) => {
          if (data.status === statCodes.SUCCESS) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('run qual stop res :', data.data); }
            dispatch({ type: SELECTED_QUALRUN_STOP_SUCCESS, payload: data.data });
          } else {
            dispatch({ type: SELECTED_QUALRUN_STOP_ERROR, payload: data });
          }
        }).catch((error) => {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
          if (!error.response) {
            dispatch({ type: SELECTED_QUALRUN_STOP_ERROR, payload: error });
          } else {
            dispatch({ type: SELECTED_QUALRUN_STOP_ERROR, payload: error.response });
          }
        });
      }
    }
  };
};

export const qualRunResetAPICall = (runID,command) => {
  // let config24 = {
  //   method: 'post',
  //   // url: 'https://dev-atomic.flexcloud.com/commissions/runs/eyB0eXBlOidwcmltYXJ5JywgcGVyaW9kOiAnMDMvMjAyMScsIGlkOiAyOX0K/runReset',
  //   url:Endpoints.CommissionRuns+ '/' + runID + '/runReset',
  //   headers: { 
  //     'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjU4MjQyMTVEODhGQkE4RTEzQkZGN0FFNTk0MzAyODM5RkU1M0ZGOENSUzI1NiIsInR5cCI6ImF0K2p3dCIsIng1dCI6IldDUWhYWWo3cU9FN18zcmxsREFvT2Y1VF80dyJ9.eyJuYmYiOjE2MzM0OTA0MzksImV4cCI6MTY2NTAyNjQzOSwiaXNzIjoiaHR0cHM6Ly9kZXYtYXRvbWljLmZsZXhjbG91ZC5jb20vaWRlbnRpdHkiLCJjbGllbnRfaWQiOiIwZDkzNGI5MS03NjA3LTQ3OWUtYjMyOS0xOTAyNjhiZmRjZDIiLCJjbGllbnRfbmFtZSI6ImRldi1hdG9taWMiLCJqdGkiOiIxNTk0MTA3RDFGRjM0RTMyNDcwMTZGRjE4Q0Q2QjdERCIsImlhdCI6MTYzMzQ5MDQzOSwic2NvcGUiOlsiL2NvbW1pc3Npb25zIl19.VOq7hVNtlqz3yhuag0yYrqMXefpTfI3upweQy2DUt9mO6Wd6iHhd8S3JCamTCYY-Fr8rlxIPEXNb8Rx7fg1e4LCrcXVQoOLVg65QBL3r2xuMQE7PQ_c4MF9C61xxQWrFAC2jjvNWztrb5pcv9B73XVv1MCJVajxnYeyrKVdxP3VK8R9L_OHqEzpXzKfz6PW0BiXgvPIl92NtHDE1mvC6YXXXcVwHuTAFBu8tZdvskr_wm0iNaY7niY8QzKXvY5DkWFdknaSeGP9HJwukdGzdY-ObHg5JDl2Swb0ienhOqQ4mKrIA1_kU9tcfk6k4dAPpYkvwcwPB-WQfNECuB1qxJw'
  //   }
  // }
  console.log("Qual run stop : ",runID);
  return dispatch => {
    if (AuthService.isTokenValid() === true) {
      server.post(Endpoints.CommissionRuns+ '/' + runID + '/runReset', '','', options).then((data) => {
        if (data.status === statCodes.SUCCESS) {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('run qual reset res :', data); }
          dispatch({ type: SELECTED_QUALRUN_RESET_SUCCESS, payload: data.data });
        } else {
          dispatch({ type: SELECTED_QUALRUN_RESET_ERROR, payload: data });
        }
      }).catch((error) => {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
        if (!error.response) {
          dispatch({ type: SELECTED_QUALRUN_RESET_ERROR, payload: error });
        } else {
          dispatch({ type: SELECTED_QUALRUN_RESET_ERROR, payload: error.response });
        }

      });
    } else {
      const user = AuthService.signinSilentToken();
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
      if (user) {
        localStorage.setItem('id_token', user.id_token);
        server.post(Endpoints.CommissionRuns+ '/' + runID + '/runReset', '','', options).then((data) => {
          if (data.status === statCodes.SUCCESS) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('run qual reset res :', data.data); }
            dispatch({ type: SELECTED_QUALRUN_RESET_SUCCESS, payload: data.data });
          } else {
            dispatch({ type: SELECTED_QUALRUN_RESET_ERROR, payload: data });
          }
        }).catch((error) => {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
          if (!error.response) {
            dispatch({ type: SELECTED_QUALRUN_RESET_ERROR, payload: error });
          } else {
            dispatch({ type: SELECTED_QUALRUN_RESET_ERROR, payload: error.response });
          }
        });
      }
    }
  };
};
export const createNewRun=(params)=>{
  return dispatch => {    
    if(AuthService.isTokenValid()===true){
      server.post(Endpoints.CommissionRuns,'', params, options).then((data) => {                          
        if (data.status === statCodes.SUCCESS||data.status === statCodes.RETRY) {   
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Create New run res :',data);}
          dispatch({type:COMMISSION_CREATE_RUN_SUCCESS,payload:data.data});
        }else{
          dispatch({type:COMMISSION_CREATE_RUN_ERROR,payload:data});
        }
      }).catch((error) => {
        if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}
        if(!error.response){
          dispatch({type:COMMISSION_CREATE_RUN_ERROR,payload:error}); 
        }else{
          dispatch({type:COMMISSION_CREATE_RUN_ERROR,payload:error.response}); 
        }
                   
      });
    }else{
      const user = AuthService.signinSilentToken();
      if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('signinSilentToken user :',user);}
      if(user){
        localStorage.setItem('id_token', user.id_token); 
        server.post(Endpoints.CommissionRuns,'', params, options).then((data) => {                      
          if (data.status === statCodes.SUCCESS) {   
            if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Create New run res :',data.data);}
            dispatch({type:COMMISSION_CREATE_RUN_SUCCESS,payload:data.data});
          }else{
            dispatch({type:COMMISSION_CREATE_RUN_ERROR,payload:data});
          }
        }).catch((error) => {
          if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log('Catch Error : ', error);}
          if(!error.response){
            dispatch({type:COMMISSION_CREATE_RUN_ERROR,payload:error}); 
          }else{
            dispatch({type:COMMISSION_CREATE_RUN_ERROR,payload:error.response}); 
          }            
        });
      }       
    }   
  };
}        
  
export const getRetailProfit = (runID,commTypeID) => {
  return dispatch => {
    if (AuthService.isTokenValid() === true) {
      server.get(Endpoints.CommissionRuns + '/' + runID + '/earnings/' + commTypeID, '', options).then((data) => {
        if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('getRetailProfit res :', data); }
          if (data.status === statCodes.SUCCESS){
            dispatch({ type: COMMISSIONSRETAILPROFIT_SUCCESS, payload: data.data });
          }else{
            dispatch({ type: COMMISSIONSRETAILPROFIT_SUCCESS, payload: data });
          }
          
        } else {
          dispatch({ type: COMMISSIONSRETAILPROFIT_ERROR, payload: data });
        }
      }).catch((error) => {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
        if (!error.response) {
          dispatch({ type: COMMISSIONSRETAILPROFIT_ERROR, payload: error });
        } else {
          dispatch({ type: COMMISSIONSRETAILPROFIT_ERROR, payload: error.response });
        }

      });
    } else {
      const user = AuthService.signinSilentToken();
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
      if (user) {
        localStorage.setItem('id_token', user.id_token);
        server.get(Endpoints.CommissionRuns + '/' + runID + '/earnings/' + commTypeID, '', options).then((data) => {
          if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('getRetailProfit res :', data.data); }
            dispatch({ type: COMMISSIONSRETAILPROFIT_SUCCESS, payload: data.data });
          } else {
            dispatch({ type: COMMISSIONSRETAILPROFIT_ERROR, payload: data });
          }
        }).catch((error) => {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
          if (!error.response) {
            dispatch({ type: COMMISSIONSRETAILPROFIT_ERROR, payload: error });
          } else {
            dispatch({ type: COMMISSIONSRETAILPROFIT_ERROR, payload: error.response });
          }
        });
      }
    }
  };
};

export const getCurrentRunProgramsAPI = (runID) => {
  console.log("run status api call ",runID);
  return dispatch => {
    if (AuthService.isTokenValid() === true) {
      server.get(Endpoints.CommissionRuns+ '/' + runID + '/programs', '', options).then((data) => {
        if (data.status === statCodes.SUCCESS) {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRun Details res :', data); }
          dispatch({ type: SELECTED_RUN_PROGRAMS, payload: data.data });
        } else {
          dispatch({ type: SELECTED_RUN_PROGRAMS_ERROR, payload: data });
        }
      }).catch((error) => {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
        if (!error.response) {
          dispatch({ type: SELECTED_RUN_PROGRAMS_ERROR, payload: error });
        } else {
          dispatch({ type: SELECTED_RUN_PROGRAMS_ERROR, payload: error.response });
        }

      });
    } else {
      const user = AuthService.signinSilentToken();
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
      if (user) {
        localStorage.setItem('id_token', user.id_token);
        server.get(Endpoints.CommissionRuns+ '/' + runID + '/programs', '', options).then((data) => {
          if (data.status === statCodes.SUCCESS) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRun Details res :', data.data); }
            dispatch({ type: SELECTED_RUN_PROGRAMS, payload: data.data });
          } else {
            dispatch({ type: RUN_STATUS_ERROR, payload: data });
          }
        }).catch((error) => {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
          if (!error.response) {
            dispatch({ type: SELECTED_RUN_PROGRAMS_ERROR, payload: error });
          } else {
            dispatch({ type: SELECTED_RUN_PROGRAMS_ERROR, payload: error.response });
          }
        });
      }
    }
  };
};

export const getEarningsbyCountryAPI = (runID, DistID, Country) => {
  console.log("run status api call ",runID);
  return dispatch => {
    if (AuthService.isTokenValid() === true) {
      server.get(Endpoints.CommissionRuns+ '/' + runID + '/distributors/' + DistID + '/earnings?countryCode=' + Country, '', options).then((data) => {
        if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRun Details res :', data); }
          if (data.status === statCodes.SUCCESS){
            dispatch({ type: SELECTED_COUNTRY_INFO, payload: data.data });
          }else{
            dispatch({ type: SELECTED_COUNTRY_INFO, payload: data });
          }
          
        } else {
          dispatch({ type: SELECTED_COUNTRY_INFO_ERROR, payload: data });
        }
      }).catch((error) => {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
        if (!error.response) {
          dispatch({ type: SELECTED_COUNTRY_INFO_ERROR, payload: error });
        } else {
          dispatch({ type: SELECTED_COUNTRY_INFO_ERROR, payload: error.response });
        }

      });
    } else {
      const user = AuthService.signinSilentToken();
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
      if (user) {
        localStorage.setItem('id_token', user.id_token);
        server.get(Endpoints.CommissionRuns+ '/' + runID + 'distributors/' + DistID + '/earnings?country=' + Country, '', options).then((data) => {
          if (data.status === statCodes.SUCCESS) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRun Details res :', data.data); }
            dispatch({ type: SELECTED_COUNTRY_INFO, payload: data.data });
          } else {
            dispatch({ type: SELECTED_COUNTRY_INFO_ERROR, payload: data });
          }
        }).catch((error) => {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
          if (!error.response) {
            dispatch({ type: SELECTED_COUNTRY_INFO_ERROR, payload: error });
          } else {
            dispatch({ type: SELECTED_COUNTRY_INFO_ERROR, payload: error.response });
          }
        });
      }
    }
  };
};

export const getWorkFieldDetailsAPI = (runID, DistID, workFieldID) => {
  console.log("run status api call ",runID);
  return dispatch => {
    if (AuthService.isTokenValid() === true) {
      server.get(Endpoints.CommissionRuns+ '/' + runID + '/distributors/' + DistID + '/workFieldDetails/' + workFieldID, '', options).then((data) => {
        if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRun Details res :', data); }
          if (data.status === statCodes.SUCCESS){
            dispatch({ type: DIST_RESEARCH_WORKFIELD_DETAILS, payload: data.data });
          }else{
            dispatch({ type: DIST_RESEARCH_WORKFIELD_DETAILS, payload: data });
          }
          
        } else {
          dispatch({ type: DIST_RESEARCH_WORKFIELD_ERROR, payload: data });
        }
      }).catch((error) => {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
        if (!error.response) {
          dispatch({ type: DIST_RESEARCH_WORKFIELD_ERROR, payload: error });
        } else {
          dispatch({ type: DIST_RESEARCH_WORKFIELD_ERROR, payload: error.response });
        }

      });
    } else {
      const user = AuthService.signinSilentToken();
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
      if (user) {
        localStorage.setItem('id_token', user.id_token);
        server.get(Endpoints.CommissionRuns+ '/' + runID + '/distributors/' + DistID + '/workFieldDetails/' + workFieldID, '', options).then((data) => {
          if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRun Details res :', data.data); }
            dispatch({ type: DIST_RESEARCH_WORKFIELD_DETAILS, payload: data.data });
          } else {
            dispatch({ type: DIST_RESEARCH_WORKFIELD_ERROR, payload: data });
          }
        }).catch((error) => {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
          if (!error.response) {
            dispatch({ type: DIST_RESEARCH_WORKFIELD_ERROR, payload: error });
          } else {
            dispatch({ type: DIST_RESEARCH_WORKFIELD_ERROR, payload: error.response });
          }
        });
      }
    }
  };
};

export const getAmountFieldDetailsMatrixAPI = (runID, DistID, amountFieldID) => {
  console.log("run status api call ",runID);
  return dispatch => {
    if (AuthService.isTokenValid() === true) {
      server.get(Endpoints.CommissionRuns+ '/' + runID + '/distributors/' + DistID + '/amountFieldDetails/' + amountFieldID, '', options).then((data) => {
        if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRun Details res :', data); }
          if (data.status === statCodes.SUCCESS){
            dispatch({ type: AMOUNTFIELD_MATRIX_DETAILS, payload: data.data });
          }else{
            dispatch({ type: AMOUNTFIELD_MATRIX_DETAILS, payload: data });
          }
        } else {
          dispatch({ type: AMOUNTFIELD_MATRIX_ERROR, payload: data });
        }
      }).catch((error) => {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
        if (!error.response) {
          dispatch({ type: AMOUNTFIELD_MATRIX_ERROR, payload: error });
        } else {
          dispatch({ type: AMOUNTFIELD_MATRIX_ERROR, payload: error.response });
        }

      });
    } else {
      const user = AuthService.signinSilentToken();
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
      if (user) {
        localStorage.setItem('id_token', user.id_token);
        server.get(Endpoints.CommissionRuns+ '/' + runID + '/distributors/' + DistID + '/amountFieldDetails/' + amountFieldID, '', options).then((data) => {
          if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRun Details res :', data.data); }
            dispatch({ type: AMOUNTFIELD_MATRIX_DETAILS, payload: data.data });
          } else {
            dispatch({ type: AMOUNTFIELD_MATRIX_ERROR, payload: data });
          }
        }).catch((error) => {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
          if (!error.response) {
            dispatch({ type: AMOUNTFIELD_MATRIX_ERROR, payload: error });
          } else {
            dispatch({ type: AMOUNTFIELD_MATRIX_ERROR, payload: error.response });
          }
        });
      }
    }
  };
};

export const getVolumeFieldDetailsMatrixAPI = (runID, DistID, volFieldID) => {
  console.log("run status api call ",runID);
  return dispatch => {
    if (AuthService.isTokenValid() === true) {
      server.get(Endpoints.CommissionRuns+ '/' + runID + '/distributors/' + DistID + '/volumeDetails/' + volFieldID, '', options).then((data) => {
        if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRun Details res :', data); }
          if (data.status === statCodes.SUCCESS){
            dispatch({ type: VOLFIELD_MATRIX_DETAILS, payload: data.data });
          }else{
            dispatch({ type: VOLFIELD_MATRIX_DETAILS, payload: data });
          }
          
        } else {
          dispatch({ type: VOLFIELD_MATRIX_ERROR, payload: data });
        }
      }).catch((error) => {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
        if (!error.response) {
          dispatch({ type: VOLFIELD_MATRIX_ERROR, payload: error });
        } else {
          dispatch({ type: VOLFIELD_MATRIX_ERROR, payload: error.response });
        }

      });
    } else {
      const user = AuthService.signinSilentToken();
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
      if (user) {
        localStorage.setItem('id_token', user.id_token);
        server.get(Endpoints.CommissionRuns+ '/' + runID + '/distributors/' + DistID + '/volumeDetails/' + volFieldID, '', options).then((data) => {
          if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('volume Details res :', data.data); }
            dispatch({ type: VOLFIELD_MATRIX_DETAILS, payload: data.data });
          } else {
            dispatch({ type: VOLFIELD_MATRIX_ERROR, payload: data });
          }
        }).catch((error) => {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
          if (!error.response) {
            dispatch({ type: VOLFIELD_MATRIX_ERROR, payload: error });
          } else {
            dispatch({ type: VOLFIELD_MATRIX_ERROR, payload: error.response });
          }
        });
      }
    }
  };
};

export const getRankMatrixInfoAPI = (runID, detailKey) => {
  console.log("run status api call ",runID);
  return dispatch => {
    if (AuthService.isTokenValid() === true) {
      server.get(Endpoints.CommissionTypeSelectionURL1+ '/' + runID + '/rankMatrix/'+detailKey, '', options).then((data) => {
        if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRun Details res :', data); }
          if (data.status === statCodes.SUCCESS){
            dispatch({ type: RANK_MATRIX_DETAILS_SUCCESS, payload: data.data });
          }else{
            dispatch({ type: RANK_MATRIX_DETAILS_SUCCESS, payload: data });
          }
        } else {
          dispatch({ type: RANK_MATRIX_DETAILS_ERROR, payload: data });
        }
      }).catch((error) => {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
        if (!error.response) {
          dispatch({ type: RANK_MATRIX_DETAILS_ERROR, payload: error });
        } else {
          dispatch({ type: RANK_MATRIX_DETAILS_ERROR, payload: error.response });
        }
      });
    } else {
      const user = AuthService.signinSilentToken();
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
      if (user) {
        localStorage.setItem('id_token', user.id_token);
        server.get(Endpoints.CommissionTypeSelectionURL1+ '/' + runID + '/rankMatrix/'+detailKey, '', options).then((data) => {
          if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRun Details res :', data.data); }
            dispatch({ type: RANK_MATRIX_DETAILS_SUCCESS, payload: data.data });
          } else {
            dispatch({ type: RANK_MATRIX_DETAILS_ERROR, payload: data });
          }
        }).catch((error) => {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
          if (!error.response) {
            dispatch({ type: RANK_MATRIX_DETAILS_ERROR, payload: error });
          } else {
            dispatch({ type: RANK_MATRIX_DETAILS_ERROR, payload: error.response });
          }
        });
      }
    }
  };
};

export const getAllRanks =() =>{
    return dispatch => {
      if (AuthService.isTokenValid() === true) {
        server.get(Endpoints.CommissionTypeSelectionURL1+ '/ranks', '', options).then((data) => {
          if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Ranks res :', data); }
            if (data.status === statCodes.SUCCESS){
              dispatch({ type: DIST_UPLINE_DROP_SUCCESS, payload: data.data });
            }else{
              dispatch({ type: DIST_UPLINE_DROP_SUCCESS, payload: data });
            }
            
          } else {
            dispatch({ type: DIST_UPLINE_DROP_ERROR, payload: data });
          }
        }).catch((error) => {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
          if (!error.response) {
            dispatch({ type: DIST_UPLINE_DROP_ERROR, payload: error });
          } else {
            dispatch({ type: DIST_UPLINE_DROP_ERROR, payload: error.response });
          }
        });
      } else {
        const user = AuthService.signinSilentToken();
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
        if (user) {
          localStorage.setItem('id_token', user.id_token);
          server.get(Endpoints.CommissionTypeSelectionURL1+ '/ranks' , '', options).then((data) => {
            if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
              if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Ranks res :', data.data); }
              dispatch({ type: DIST_UPLINE_DROP_SUCCESS, payload: data.data });
            } else {
              dispatch({ type: DIST_UPLINE_DROP_ERROR, payload: data });
            }
          }).catch((error) => {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
            if (!error.response) {
              dispatch({ type: DIST_UPLINE_DROP_ERROR, payload: error });
            } else {
              dispatch({ type: DIST_UPLINE_DROP_ERROR, payload: error.response });
            }
          });
        }
      }
    };
}

export const getCommSelectedRunLog =(runID) =>{
  return dispatch => {
    if (AuthService.isTokenValid() === true) {
      server.get(Endpoints.CommissionRuns+"/"+ runID+'/logs', '', options).then((data) => {
        if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Ranks res :', data); }
          dispatch({ type: SELECTEDRUN_LOGS_SUCCESS, payload: data.data });
        } else {
          dispatch({ type: SELECTEDRUN_LOGS_ERROR, payload: data });
        }
      }).catch((error) => {
        if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
        if (!error.response) {
          dispatch({ type: SELECTEDRUN_LOGS_ERROR, payload: error });
        } else {
          dispatch({ type: SELECTEDRUN_LOGS_ERROR, payload: error.response });
        }
      });
    } 
    else {
      const user = AuthService.signinSilentToken();
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
      if (user) {
        localStorage.setItem('id_token', user.id_token);
        server.get(Endpoints.CommissionRuns+"/"+ runID+'/logs' , '', options).then((data) => {
          if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY) {
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Ranks res :', data.data); }
            dispatch({ type: SELECTEDRUN_LOGS_SUCCESS, payload: data.data });
          } else {
            dispatch({ type: SELECTEDRUN_LOGS_ERROR, payload: data });
          }
        }).catch((error) => {
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
          if (!error.response) {
            dispatch({ type: SELECTEDRUN_LOGS_ERROR, payload: error });
          } else {
            dispatch({ type: SELECTEDRUN_LOGS_ERROR, payload: error.response });
          }
        });
      }
    }
  };
}

export const getProgramsData = () => {
  return dispatch => {
    if (AuthService.isTokenValid() === true) {
      server.get(Endpoints.SettingsProgramsPrimary + 'primary', '', options)
        .then((response)=> {
          if (response.status === statCodes.SUCCESS || response.status === statCodes.RETRY) {
            if (response.status === statCodes.SUCCESS) {
              dispatch({ type: PROGRAMDATA_SUCCESS, payload: response.data })
            } else {
              dispatch({ type: PROGRAMDATA_SUCCESS, payload: response });
            }
          } else {
            dispatch({ type: PROGRAMDATA_ERROR, payload: error })
          }
        })
        .catch((error)=> {
          console.log(error.message, 'active period maintenance error')
          if (!error.response) {
            dispatch({ type: PROGRAMDATA_ERROR, payload: error })
          } else {
            dispatch({ type: PROGRAMDATA_ERROR, payload: error.response })
          }
        })
    }
    else {
      const user = AuthService.signinSilentToken();
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
      if (user) {
        localStorage.setItem('id_token', user.id_token);
        server.get(Endpoints.SettingsProgramsPrimary + 'primary', '', options).then((response) => {
          if (response.status === statCodes.SUCCESS || response.status === statCodes.RETRY) {
            if (response.status === statCodes.SUCCESS) {
              dispatch({ type: PROGRAMDATA_SUCCESS, payload: response.data })
            } else {
              dispatch({ type: PROGRAMDATA_SUCCESS, payload: response });
            }
          } else {
            dispatch({ type: PROGRAMDATA_ERROR, payload: error })
          }
        })
        .catch((error)=> {
          console.log(error.message, 'active period maintenance error')
          if (!error.response) {
            dispatch({ type: PROGRAMDATA_ERROR, payload: error })
          } else {
            dispatch({ type: PROGRAMDATA_ERROR, payload: error.response })
          }
        })
      }
    }
  }
}