import AuthService from "../../server/AuthService";
import Endpoints from "../../utilities/Endpoints";
import { singletonAxiosInstance } from "../../utilities/Server";
import { getApplicationId } from "../../utilities/Services";

export const LOGINHISTORY_SUCCESS = 'LOGINHISTORY_SUCCESS';
export const LOGINHISTORY_ERROR = 'LOGINHISTORY_ERROR';
export const APPLICATION_USER_SUCCESS = 'APPLICATION_USER_SUCCESS';
export const APPLICATION_USER_ERROR = 'APPLICATION_USER_ERROR';
export const APPLICATION_INVITE_USER_SUCCESS = 'APPLICATION_INVITE_USER_SUCCESS';
export const APPLICATION_INVITE_USER_ERROR = 'APPLICATION_INVITE_USER_ERROR';
export const APPLICATION_UPDATE_USER_SUCCESS = 'APPLICATION_UPDATE_USER_SUCCESS';
export const APPLICATION_UPDATE_USER_ERROR = 'APPLICATION_UPDATE_USER_ERROR';
export const APPLICATION_USER_INFO_SUCCESS = 'APPLICATION_USER_INFO_SUCCESS';
export const APPLICATION_USER_INFO_ERROR = 'APPLICATION_USER_INFO_ERROR';
export const APPLICATION_ROLES_SUCCESS = 'APPLICATION_ROLES_SUCCESS';
export const APPLICATION_ROLES_ERROR = 'APPLICATION_ROLES_ERROR';
export const APPLICATION_SCOPES_SUCCESS = 'APPLICATION_SCOPES_SUCCESS';
export const APPLICATION_SCOPES_ERROR = 'APPLICATION_SCOPES_ERROR';

const server = singletonAxiosInstance
// const options = {
//   Authorization: 'Bearer ' + localStorage.getItem('id_token') 
// };
const mockoption = {
    // 'x-api-key': 'PMAK-61ae3f447ddf090034f32226-302e860673975719598c24f34f8d72d71e',
    // 'x-api-key': 'PMAK-632b3467edf3880909d4157a-bb7c3598609562035fe1f586518b028499',
    'x-api-key': 'PMAK-632269590f28874eb89b79cd-5667e8bf1a9d649876876a72e6cb0eb600',
    Authorization: 'Bearer ' + localStorage.getItem('id_token'),
}

const statCodes = {
  SUCCESS: 200,
  CREATE_SUCCESS: 201,
  unAuthorized: 403,
  RETRY: 202
};
const applicationId = getApplicationId();

export const getloginHistoryAPI=(userId)=>{
    return dispatch => {
        if (AuthService.isTokenValid() !== true) {
            const user = AuthService.signinSilentToken();
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
            if (user) {
              localStorage.setItem('id_token', user.id_token);
            }
        }
        if (AuthService.isTokenValid() === true) {
          server.get(Endpoints.userMockBaseUrl+applicationId+'/users/'+userId+'/loginHistory', '', mockoption).then((data) => {
            if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY ) {
                if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionType API call data :', data); }
                if (data.status === statCodes.SUCCESS){
                  dispatch({ type:  LOGINHISTORY_SUCCESS, payload: data?.data });
                }else{
                  dispatch({ type:  LOGINHISTORY_SUCCESS, payload: data })
                }
                
              } else {
                dispatch({ type: LOGINHISTORY_ERROR, payload: data });
              }
            }).catch((error) => {
              if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
              if (!error.response) {
                dispatch({ type: LOGINHISTORY_ERROR, payload: error })
              } else {
                dispatch({ type: LOGINHISTORY_ERROR, payload: error.response })
              }
          })
        }
    }
}

export const getApplicationUserList=()=>{
    return dispatch=>{
        if (AuthService.isTokenValid() !== true) {
            const user = AuthService.signinSilentToken();
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
            if (user) {
              localStorage.setItem('id_token', user.id_token);
            }
          }
          if (AuthService.isTokenValid() === true) {
            server.get(Endpoints.userMockBaseUrl+applicationId+'/users', '', mockoption).then((data) => {
              if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY ) {
                  if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionType API call data :', data); }
                  if (data.status === statCodes.SUCCESS){
                    dispatch({ type:  APPLICATION_USER_SUCCESS, payload: data?.data });
                  }else{
                    dispatch({ type:  APPLICATION_USER_SUCCESS, payload: data })
                  }
                } else {
                  dispatch({ type: APPLICATION_USER_ERROR, payload: data });
                }
              }).catch((error) => {
                if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
                if (!error.response) {
                  dispatch({ type: APPLICATION_USER_ERROR, payload: error })
                } else {
                  dispatch({ type: APPLICATION_USER_ERROR, payload: error.response })
                }
            })
          }
      }
}
export const postUserInvite=(postObj)=>{
  return dispatch=>{
      if (AuthService.isTokenValid() !== true) {
          const user = AuthService.signinSilentToken();
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
          if (user) {
            localStorage.setItem('id_token', user.id_token);
          }
        }
        if (AuthService.isTokenValid() === true) {
          server.post(Endpoints.userMockBaseUrl+applicationId+'/users/invite', postObj, '', mockoption).then((data) => {
            if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY ) {
                if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Invite Users data :', data); }
                if (data.status === statCodes.SUCCESS){
                  dispatch({ type:  APPLICATION_INVITE_USER_SUCCESS, payload: data?.data });
                }else{
                  dispatch({ type:  APPLICATION_INVITE_USER_SUCCESS, payload: data })
                }
              } else {
                dispatch({ type: APPLICATION_INVITE_USER_ERROR, payload: data });
              }
            }).catch((error) => {
              if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
              if (!error.response) {
                dispatch({ type: APPLICATION_INVITE_USER_ERROR, payload: error })
              } else {
                dispatch({ type: APPLICATION_INVITE_USER_ERROR, payload: error.response })
              }
          })
        }
    }
}

export const getUserInfo=(userId)=>{
  return dispatch=>{
      if (AuthService.isTokenValid() !== true) {
          const user = AuthService.signinSilentToken();
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
          if (user) {
            localStorage.setItem('id_token', user.id_token);
          }
        }
        if (AuthService.isTokenValid() === true) {
          console.log("user id info ", userId)
          server.get(Endpoints.userMockBaseUrl+applicationId+'/users/'+ userId, '', mockoption).then((data) => {
            if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY ) {
                if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Invite Users data :', data); }
                if (data.status === statCodes.SUCCESS){
                  dispatch({ type:  APPLICATION_USER_INFO_SUCCESS, payload: data?.data });
                }else{
                  dispatch({ type:  APPLICATION_USER_INFO_SUCCESS, payload: data })
                }
              } else {
                dispatch({ type: APPLICATION_USER_INFO_ERROR, payload: data });
              }
            }).catch((error) => {
              if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
              if (!error.response) {
                dispatch({ type: APPLICATION_USER_INFO_ERROR, payload: error })
              } else {
                dispatch({ type: APPLICATION_USER_INFO_ERROR, payload: error.response })
              }
          })
        }
    }
}

export const postUserUpdate=(postObj)=>{
  return dispatch=>{
      if (AuthService.isTokenValid() !== true) {
          const user = AuthService.signinSilentToken();
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
          if (user) {
            localStorage.setItem('id_token', user.id_token);
          }
        }
        if (AuthService.isTokenValid() === true) {
          server.post(Endpoints.userMockBaseUrl+applicationId+'/users', postObj, '', mockoption).then((data) => {
            if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY ) {
                if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Invite Users data :', data); }
                if (data.status === statCodes.SUCCESS){
                  dispatch({ type:  APPLICATION_UPDATE_USER_SUCCESS, payload: data?.data });
                }else{
                  dispatch({ type:  APPLICATION_UPDATE_USER_SUCCESS, payload: data })
                }
              } else {
                dispatch({ type: APPLICATION_UPDATE_USER_ERROR, payload: data });
              }
            }).catch((error) => {
              if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
              if (!error.response) {
                dispatch({ type: APPLICATION_UPDATE_USER_ERROR, payload: error })
              } else {
                dispatch({ type: APPLICATION_UPDATE_USER_ERROR, payload: error.response })
              }
          })
        }
    }
}

export const getAllApplicationRoles=()=>{
  return dispatch=>{
      if (AuthService.isTokenValid() !== true) {
          const user = AuthService.signinSilentToken();
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
          if (user) {
            localStorage.setItem('id_token', user.id_token);
          }
        }
        if (AuthService.isTokenValid() === true) {
          server.get(Endpoints.userMockBaseUrl+'0D934B91-7607-479E-B329-190268BFDCD2'+'/roles', '', mockoption).then((data) => {
            if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY ) {
                if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Roles API call data :', data); }
                if (data.status === statCodes.SUCCESS){
                  dispatch({ type:  APPLICATION_ROLES_SUCCESS, payload: data?.data });
                }else{
                  dispatch({ type:  APPLICATION_ROLES_SUCCESS, payload: data })
                }
              } else {
                dispatch({ type: APPLICATION_ROLES_ERROR, payload: data });
              }
            }).catch((error) => {
              if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
              if (!error.response) {
                dispatch({ type: APPLICATION_ROLES_ERROR, payload: error })
              } else {
                dispatch({ type: APPLICATION_ROLES_ERROR, payload: error.response })
              }
          })
        }
    }
}

export const getAllApplicationScopes=()=>{
  return dispatch=>{
      if (AuthService.isTokenValid() !== true) {
          const user = AuthService.signinSilentToken();
          if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('signinSilentToken user :', user); }
          if (user) {
            localStorage.setItem('id_token', user.id_token);
          }
        }
        if (AuthService.isTokenValid() === true) {
          server.get(Endpoints.userMockBaseUrl+applicationId+'/scopes', '', mockoption).then((data) => {
            if (data.status === statCodes.SUCCESS || data.status === statCodes.RETRY ) {
                if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Scopes API call data :', data); }
                if (data.status === statCodes.SUCCESS){
                  dispatch({ type:  APPLICATION_SCOPES_SUCCESS, payload: data?.data });
                }else{
                  dispatch({ type:  APPLICATION_SCOPES_SUCCESS, payload: data })
                }
              } else {
                dispatch({ type: APPLICATION_SCOPES_ERROR, payload: data });
              }
            }).catch((error) => {
              if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
              if (!error.response) {
                dispatch({ type: APPLICATION_SCOPES_ERROR, payload: error })
              } else {
                dispatch({ type: APPLICATION_SCOPES_ERROR, payload: error.response })
              }
          })
        }
    }
}
