import React from "react";
import "./App.css";

import TopMenu from "../src/components/portalv3/TopMenu";
import PrimaryNav from "../src/components/portalv3/PrimaryNav";
import AuthService from '../src/components/server/AuthService';
import * as Constants from '../src/constants/Constants'
//import { gettokenScopeValidation } from '../components/utilities/Services';
import { Oval} from 'react-loader-spinner'
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import flexcloudLogo from './assets/flexcloud-logo-logo-1@2x.png';
import Line40 from './assets/line-40@2x.png';
import ellipse12 from './assets/ellipse-12@2x.png';
import vector10 from './assets/vector-10@2x.png';
import vector12 from './assets/vector-12@2x.png';
import vector11 from './assets/vector-11@2x.png';
import image3 from './assets/image-3@2x.png';
import vector2 from './assets/vector-2@2x.png';
import vector4 from './assets/vector-4@2x.png';
import vector5 from './assets/vector-5@2x.png';
import vector7 from './assets/vector-7@2x.png';
import vector8 from './assets/vector-8@2x.png';
import line42 from './assets/line-42@1x.png';
import noList from './assets/167337012-860712567818400-4952636326271892083-n@2x.png';

let hashHistory = Router.hashHistory;

class PortalLanding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoader: false,
    }
    this.location = {};
    this.history = {};  
  }
  componentDidMount() {
   if (AuthService.isTokenValidCheck()===true) {            
      this.setState({ isLoader: false });          
    } else {
       this.setState({ isLoader: true });
   }               
  }   
  render(){
    let loader = ''
    if (this.state.isLoader) {
      return (
        <div id="center" style={{ position: 'fixed', bottom: '40%', left: '48%' }}>
          <Oval
            position="fixed"  secondaryColor="#E85557"
            color="#E85557"
            height={50} width={50}
            visible={true}
          />
        </div>);

    } 
   if (AuthService.isAuthenticated()) {
    return (
            <div className="container-center-horizontal">
              <div className="screen">
                <TopMenu {...topMenuData} />
                <PrimaryNav {...primaryNavData} />
              </div>
            </div>
    )
  }
  return '';
  }
}
export default PortalLanding;

const topMenuData = {
  flexcloud_Logo_Logo1: flexcloudLogo,
  line40: Line40,
  overlapGroup1: ellipse12,
  atI42779255482: "AT",
  text6: "Atomic Healthforce",
  text7: "Dev Environment",  
  vector2: vector10,
  vector3: vector12,
  vector4: vector11,
  image4: image3,
};

const group2457Data = {
  text12: "DEVELOPER PORTAL",
};

const primaryNavData = {
  text3: "INTEGRATION MANAGER",
  text5: "API DOCS",
  vector2: vector2,
  text4: "LEGACY API",
  ideI42778255518: "IDE",
  vector3: "",
  vector4: vector4,
  vector5: vector5,
  vector6: "",
  vector7: vector7,
  vector8: vector8,
  group2457Props: group2457Data,
};

const component1Data = {
  text19: "My Integrations",
  line42: line42,
};

const primaryLargeDefaultData = {
  children: "CREATE INTEGRATION",
};

const integrationManagerData = {
  title: "My Integrations",
  text1: "This page contains all the integrations that are available to you. You can also create new integrations here.",
  line42: line42,
  x167337012_860712567818400_49526363: noList,
  text8: "Looks like you do not have any integrations created.",
  topMenuProps: topMenuData,
  primaryNavProps: primaryNavData,
  component1Props: component1Data,
  primaryLargeDefaultProps: primaryLargeDefaultData,
};

