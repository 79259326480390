
export const AM_WAVIERS_DATA_FETCHING = 'AM_WAVIERS_DATA_FETCHING';
export const AM_DISTRIBUTOR_DETAILS = 'AM_DISTRIBUTOR_DETAILS';
export const AM_WAVIERS_DATA_SUCCESS = 'AM_WAVIERS_DATA_SUCCESS';
export const AM_WAVIERS_DATA_ERROR = 'AM_WAVIERS_DATA_ERROR';
export const AM_WAVIERS_PERIODS_DATA_SUCCESS = 'AM_WAVIERS_PERIODS_DATA_SUCCESS';
export const AM_WAVIERS_PERIODS_DATA_RESET = 'AM_WAVIERS_PERIODS_DATA_RESET';

  
  const initialState = {
    data: [],
    distributorDetails:{},
    periodsData:{periods:{}},
    error: {},
    fetching: false,
    success: false
  }
  
  export default function amWaviersReducer (state, action) {
    if (typeof state === 'undefined') {
      return initialState
    }
    switch (action.type) {
      case AM_DISTRIBUTOR_DETAILS:
        return {...state,fetching:false,success:true,distributorDetails:action.payload}
      case AM_WAVIERS_DATA_FETCHING:
        return { ...state, fetching: true }
      case AM_WAVIERS_DATA_SUCCESS:
        return {
          ...state,
          fetching: false,
          success: true,
          data: action.payload
        }
      case AM_WAVIERS_PERIODS_DATA_SUCCESS:
        return {
          ...state,
          fetching: false,
          success: true,
          periodsData: action.payload
        }
      case AM_WAVIERS_DATA_ERROR:
        return {
          ...state,
          fetching: false,
          success: false,
          data: action.payload
        }

      case AM_WAVIERS_PERIODS_DATA_RESET:
        return initialState
      
  
      default:
        return state
    }
  }
  
 export const resetAMWaiversData = () => dispatch => dispatch({type:AM_WAVIERS_PERIODS_DATA_RESET})