import actions from "../../../redux/actions/CommonDataActions";
import {COMMISSION_TYPE_SUCCESS, COMMISSION_TYPE_ERROR, COMMISSION_PERIOD_ERROR,
     COMMISSION_PERIOD_SUCCESS, DIST_RESEARCH_SELECTEDOPTIONS } from "../actions"

const initialState = {
    commissionTypeList : [],
    commissionTypeError : {},
    commissionPeriodList:[],
    commissionPeriodError:{},
    distResearchSelection:{},
    selectedCompareRunsList:[]
}

export default function commissionToolsReducer(state,action){
    if (typeof state === 'undefined') {
        return initialState;
    }  
    switch (action.type) {
        case COMMISSION_TYPE_SUCCESS:
            return { ...state,  commissionTypeList : action.payload };
        case COMMISSION_TYPE_ERROR:
            return { ...state,  commissionTypeError : action.payload };
        case COMMISSION_PERIOD_SUCCESS:    
            return { ...state,  commissionPeriodList : action.payload };
        case COMMISSION_PERIOD_ERROR:    
            return { ...state,  commissionPeriodError : action.payload };
        case DIST_RESEARCH_SELECTEDOPTIONS:
            return { ...state,  distResearchSelection : action.payload };
        case actions.FETCH_SELECTED_RUN_LIST:
            return { ...state, selectedCompareRunsList: action.payload };
            
        default:
        return state;
    } 
}