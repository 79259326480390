import React from "react";
import Group2457 from "../Group2457";
import IntegrationManager from "../IntegrationManager/index";
import APIDocs from "../TopbarNavItems/APIDocs"
import IDE from "../TopbarNavItems/IDE"
import LegacyAPI from "../TopbarNavItems/LegacyAPI"
import vectorsearch from "../../../assets/vector-search.svg";
import "./PrimaryNav.css";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

class PrimaryNav extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      value: 0,
      isIntegrationEnable: true,
      isAPIdocsEnable: false,
      isLegacyAPIEnable: false,
      isIDEEnable: false
    }
  }
  openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  handleChange = async (event, newValue) => {
    this.setState({ value: 0 })
    console.log('tab select value', newValue)
    switch (newValue) {
      case 0:
        this.setState({ isIntegrationEnable: true, isAPIdocsEnable: false, isLegacyAPIEnable: false, isIDEEnable: false })
        break;
      // case 1:
      //   this.openInNewTab('https://documenter.getpostman.com/view/4818079/TzskD2uv')
      //   // this.setState({ isAPIdocsEnable: false, isLegacyAPIEnable: false, isIntegrationEnable: true, isIDEEnable: false})
      //   break;
      case 1:
        this.openInNewTab('https://documenter.getpostman.com/view/10028920/TzkyLzMJ')
        // this.setState({ isLegacyAPIEnable: true, isIntegrationEnable: false, isAPIdocsEnable: false, isIDEEnable: false })
        break;
      case 2:
        this.setState({ isIDEEnable: true, isIntegrationEnable: false, isAPIdocsEnable: false, isLegacyAPIEnable: false })
        break;
      default: this.setState({ isIntegrationEnable: true, isAPIdocsEnable: false, isLegacyAPIEnable: false, isIDEEnable: false })
        break;
    }
  }
  render() {
    const {
      vector3,
      vector4,
      vector5,
      vector6,
      vector7,
      vector8,
      group2457Props,
    } = this.props;

    return (
      <div>
      <div className="primary-nav" style={{marginTop:"0px"}}>
        <div className="flex-row-1">
          <Group2457 text12={group2457Props.text12} className="col-4"/>
          <div  className="text-4 lato-bold-granite-gray-14px col-8">
                  <Tabs
                    value={this.state.value}
                    indicatorColor="primary"
                    select
                    textColor="primary"
                    onChange={this.handleChange}
                    aria-label="disabled tabs example"
                  >
                    <Tab label="INTEGRATION MANAGER" />
                    {/* <Tab label="API DOCS"/> */}
                    <Tab label="LEGACY API"/>
                    {/* <Tab label="IDE" /> */}
                  </Tabs>
                </div>
          {/* <div className="layer-2">
            <div className="overlap-group2-1">
              <img className="vector-6" src={vectorsearch} alt='' />
            </div>
          </div>
          <div className="group-2737">
            <div className="overlap-group3">
              <img className="vector-10" src={vector6} alt=''/>
              <img className="vector-8" src={vector7} alt=''/>
              <img className="vector-7" src={vector8} alt=''/>
            </div>
          </div> */}
          
        </div>
        {/* <div className="rectangle-255"></div> */}
      </div>
      { this.state.isIntegrationEnable ? <IntegrationManager {...integrationManagerData} />: null} 
      {/* { this.state.isAPIdocsEnable ?  this.openInNewTab('https://documenter.getpostman.com/view/4818079/TzskD2uv') : ''}  */}
      { this.state.isIDEEnable ? <IDE />: null} 
      {/* { this.state.isLegacyAPIEnable ?   this.openInNewTab('https://documenter.getpostman.com/view/10028920/TzkyLzMJ'): ''}  */}
      </div>
    );
  }
}

export default PrimaryNav;

const component1Data = {
  text19: "My Integrations",
  line42: "https://anima-uploads.s3.amazonaws.com/projects/607ff5beb95afd36a6b5e6c3/releases/60814525f4556b65c033cbd7/img/line-42@1x.png",
};

const primaryLargeDefaultData = {
  children: "CREATE INTEGRATION",
};

const integrationManagerData = {
  title: "My Integrations",
  text1: "This page contains all the integrations that are available to you. You can also create new integrations here.",
  line42: "https://anima-uploads.s3.amazonaws.com/projects/607ff5beb95afd36a6b5e6c3/releases/60814525f4556b65c033cbd7/img/line-42@1x.png",
  x167337012_860712567818400_49526363: "https://anima-uploads.s3.amazonaws.com/projects/607ff5beb95afd36a6b5e6c3/releases/60814525f4556b65c033cbd7/img/167337012-860712567818400-4952636326271892083-n@2x.png",
  text8: "Looks like you do not have any integrations created.",
  component1Props: component1Data,
  primaryLargeDefaultProps: primaryLargeDefaultData,
};
