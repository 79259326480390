import React from "react";
import "../../portalv3/IntegrationManager/IntegrationManager.css"
import Component1 from "../Component1";

const component1Data = {
  text19: "Legacy API",
  line42: "https://anima-uploads.s3.amazonaws.com/projects/607ff5beb95afd36a6b5e6c3/releases/60814525f4556b65c033cbd7/img/line-42@1x.png",
};
class LegacyAPI extends React.Component {
  render() {

    return (
      <div className="overlap-group">
      {/* <Component1 text19={component1Data.text19} line42={component1Data.line42} /> */}
      <h1> Legacy API </h1>
    <div className="flex-row">
      <div className="flex-col">
      </div>
      </div>
      </div>
    );
  }
}

export default LegacyAPI;