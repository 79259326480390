import React, { Fragment } from "react";
import AuthService from "../../server/AuthService";
import { connect } from "react-redux";
import { Oval } from "react-loader-spinner";
import { withRouter } from "react-router-dom";
import { getDistributorAmountFields, getDistributorDateMisc } from "../actions";
import actions from "../../../redux/actions/CommonDataActions";
import * as Constants from '../../../constants/Constants';
import { errorHandling, numberWithCommas } from '../../utilities/Services';
import NewSnackbar from "../../utilities/NewSnackbar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import './GeneralTab.css';
import './historyTab.css';
import './DateMiscTab.css'
import './AmountFields.css'
import '../commonComponents/Commoncss.css'
import TablePagination from '@mui/material/TablePagination';
import { allNullTypeCheck, isNullResponse } from "../commonComponents/UtilityFun";
import leftarrow from "../assets/leftarrow.svg";
import rightarrow from "../assets/rightarrow.svg";
import eyeIcon from "../assets/eye-icon.svg";
import AmountFieldsMatrix from "./AmountFieldMatrix";
import nodatafound from "../assets/nodatafound.svg";

class AmountFieldsTab extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showLoaderResult: false,
            amountFieldsData: [],
            pages: [],
            rowsPerPage: 5,
            numberOfPages:[],
            showRankMatrix: false,
            amtFieldId: '',
            labelName: '',
            amtFieldsWaitTime: 1,
            detailKey:''
        }
    }
    componentDidMount() {
        const { distID, selectedRunID } = this.props;
        const { selectedItem, amountFieldsData } = this.state;
        // console.log("History tab input props ", distID, selectedRunID);
        if (allNullTypeCheck(distID) && allNullTypeCheck(selectedRunID)) {
            this.setState({ showLoaderResult: true });
            this.props.getAmountFieldsData(selectedRunID, distID);
        }
        // const start = selectedItem * 8 - 8;
        //                 const end = selectedItem * 8;
        //                 this.setState({amountFieldsData:
        //                     distAmtfieldsData?.data?.amountFields?.slice(start, end)},
        //                   () => {}
        //                 );
    }
    componentDidUpdate(nextProps) {
        const {
            distAmtfieldsData,
            distAmtfieldsError,
            distID, selectedRunID
        } = this.props;
        const { pages, amtFieldsWaitTime } = this.state;
        if (distAmtfieldsData !== nextProps.distAmtfieldsData){
            this.setState({ showLoaderResult: false });
            if(!distAmtfieldsData.hasOwnProperty('status')){
            if(isNullResponse(distAmtfieldsData.data)) {
                if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Get distAmtfieldsData ", distAmtfieldsData) }
                if (distAmtfieldsData.error === false) {
                    console.log('distHistoryData Resposne', distAmtfieldsData.data)
                    // this.setState({ amountFieldsData: distAmtfieldsData.data.amountFields });
                    if (distAmtfieldsData?.data?.amountFields?.length > 0) {
                        const numberOfPages = Math.ceil(
                            distAmtfieldsData?.data?.amountFields?.length / 8  
                        );
                        this.setState({amountFieldsData: distAmtfieldsData?.data?.amountFields?.slice(0, 8)});
                        this.setState({pages:[]});
                        for (let index = 1; index <= numberOfPages; index++) {
                          var obj = {};
                          obj["value"] = index.toString();
                          obj["label"] = index.toString();
                          pages.push(obj);
                        }
                        this.setState({numberOfPages:pages,selectedItem:1});
                        console.log("number of pages :", numberOfPages, pages);
                      }
                } else {
                    this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.error, snackbarMessage: distAmtfieldsData.messages[0] })
                }
            } else if (Object.keys(distAmtfieldsData?.data).length === 0 && allNullTypeCheck(distAmtfieldsData?.messages)) {
              if (distAmtfieldsData?.messages?.length > 0 && distAmtfieldsData?.messages[0]?.includes("Try again...")) {
                  console.log("Try again block entered : ", distAmtfieldsData?.data);
                  this.setState({ showLoaderResult: true });
                  setTimeout(() => {
                    this.props.getAmountFieldsData(selectedRunID, distID);
                  }, 1000 * amtFieldsWaitTime);
                  this.setState(prevState => {
                      return { amtFieldsWaitTime: prevState.amtFieldsWaitTime * 2 }
                  })
              }
          }
        }else {
          if(distAmtfieldsData.hasOwnProperty('status') && distAmtfieldsData.status===202){
            this.setState({ showLoaderResult: true });
                  setTimeout(() => {
                    this.props.getAmountFieldsData(selectedRunID, distID);
                  }, 1000 * amtFieldsWaitTime);
                  this.setState(prevState => {
                      return { amtFieldsWaitTime: prevState.amtFieldsWaitTime * 2 }
                  })
         }
        }
        } else if (distAmtfieldsError !== nextProps.distAmtfieldsError) {
            this.setState({ showLoaderResult: false });
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("distAmtfieldsError ", distAmtfieldsError) }
            errorHandling(distAmtfieldsError);
        }
    }
    handleChangePage = (event, newPage) => {
        this.setState({page: newPage});
      };
    
    handleChangeRowsPerPage = (event) => {
        this.setState({rowsPerPage: +event.target.value, page: 0});
      };
    handleNumberChange = (e) => {
        const { selectedItem, amountFieldsData, amtFieldId } = this.state;
        const { distAmtfieldsData } = this.props;
        console.log("selected values: ", e.target.value);
        this.setState({selectedItem: e.target.value},()=>{});
        const i = 1;
        const start = e.target.value * 8 - 8;
        const end = e.target.value * 8;
        this.setState({amountFieldsData:
            distAmtfieldsData?.data?.amountFields?.slice(start, end)},()=>{});

    };  
    handleOpenMatrix = (detailKeyId, labelName) => {
      this.setState({ showRankMatrix: true, detailKey: detailKeyId, labelName: labelName})
    }
    closefunction= () =>{
      this.setState({ showRankMatrix: false})
    }
    
    render() {
        if (AuthService.isAuthenticated()) {
            let loader;
            const { showLoaderResult, amountFieldsData, rowsPerPage, pages, numberOfPages, selectedItem, showRankMatrix, amtFieldId, labelName, detailKey } = this.state;
            const {distID, selectedRunID} = this.props
            if (showLoaderResult) {
                loader = <div id="center" style={{ position: "fixed", bottom: "40%", left: "48%" }}>
                    <Oval
                        type="Puff"
                        position="fixed"  secondaryColor="#E85557"
                        color="#E85557"
                        height={50}
                        width={50}
                        timeout={3000}
                    />
                </div>
            }
            return (
              <div className="header-line">
                <div className="distres-generaltable">
                 {amountFieldsData.length > 0 ? <Fragment>
                  <div className="col-6 distres-general-tbcontainer distres-tabs-scroll">
                    <TableContainer>
                      {amountFieldsData.length > 0 ? (
                        <Table
                          sx={{ minWidth: 650 }}
                          aria-label="simple table"
                          className="general-table-left"
                        >
                          <TableHead className="distres-generaltab-header">
                            <TableCell 
                              className="runmanager-table-header1"
                              component="th"
                              scope="row"
                            >
                              {"Amount Fields"}
                            </TableCell>
                            <TableCell
                              className="runmanager-table-header12"
                              component="th"
                              scope="row"
                            >
                              {"Value"}
                            </TableCell>
                            <TableCell
                              className="runmanager-table-header13"
                              component="th"
                              scope="row"
                            >
                              {"View"}
                            </TableCell>
                          </TableHead>
                          <TableBody className="distres-general-tbody">
                            {amountFieldsData.length > 0
                              ? amountFieldsData.slice(0, 10).map((amtfield) => (
                                  <TableRow hover>
                                    <TableCell className="data-text-style">
                                      {amtfield?.label}
                                    </TableCell>
                                    
                                    <TableCell
                                      className="data-text-style"
                                      align={"right"}
                                    >
                                      {numberWithCommas(amtfield?.value?.toFixed(2))}
                                    </TableCell>
                                    <TableCell align="center">{amtfield?.hasDetail===true && amtfield?.value > 0? <img src={eyeIcon} onClick= {this.handleOpenMatrix.bind(this, amtfield?.detailKey, amtfield?.label)}alt="" style={{cursor: 'pointer'}}/>: ''}</TableCell>
                                  </TableRow>
                                ))
                              : ""}
                          </TableBody>
                        </Table>
                      ) : (
                        ""
                      )}
                    </TableContainer>
                    
                  </div>
                  <div className="col-6 distres-datamisc-rightcontainer distres-tabs-scroll">
                    <TableContainer>
                      {amountFieldsData.length > 0 ? (
                        <Table
                          sx={{ minWidth: 650 }}
                          aria-label="simple table"
                          className="general-table-right"
                        >
                          <TableHead className="distres-generaltab-header">
                            <TableCell
                              className="runmanager-table-header1"
                              component="th"
                              scope="row"
                             
                            >
                              {"Amount Fields"}
                            </TableCell>
                            <TableCell
                              className="runmanager-table-header12"
                              component="th"
                              scope="row"
                            >
                              {"Value"}
                            </TableCell>
                            <TableCell
                              className="runmanager-table-header13"
                              component="th"
                              scope="row"
                            >
                              {"View"}
                            </TableCell>
                            <TableCell className="runmanager-table-header1"></TableCell>
                          </TableHead>
                          <TableBody className="distres-general-tbody">
                            {amountFieldsData?.length>=2? amountFieldsData.slice(10).map((amtfield) => (
                              <TableRow>
                                <TableCell className="data-text-style">
                                  {amtfield?.label}
                                </TableCell>
                                
                                <TableCell
                                  className="data-text-style"
                                  align={"right"}
                                >
                                  {numberWithCommas(amtfield?.value?.toFixed(2))}
                                </TableCell>
                                <TableCell align="center">{amtfield?.hasDetail===true && amtfield?.value > 0 ? <img src={eyeIcon} onClick = {this.handleOpenMatrix.bind(this, amtfield.id, amtfield.label)} alt="" style={{cursor: 'pointer'}}/>: ''}</TableCell>
                              </TableRow>
                            )):''}
                          </TableBody>
                        </Table>
                      ) : (
                        ""
                      )}
                    </TableContainer>
                  </div>
                  </Fragment> : amountFieldsData.length === 0 ? <TableContainer>
                    <Table>
                        <TableRow className="no-data-image header-line">
                            <TableCell colSpan={9} align='center' className="no-data-image">
                                <img src={nodatafound} alt="" />
                            </TableCell>
                        </TableRow>
                    </Table>
                </TableContainer> : ''} 
                </div>
                {/* <div className="col-12">
                    {amountFieldsData.length > 0 ?  <TablePagination style={{paddingRight: '15px'}}
                            colSpan={3}
                                    rowsPerPageOptions={[10, 20, 40]}
                                    component="div"
                                    count={amountFieldsData.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={this.handleChangePage}
                                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                                /> : ''}
                    </div> */}
                <div className="col-12">
                  {amountFieldsData?.length > 0 && amountFieldsData?.length > 8 ? (
                    <div className="workfield-select-option">
                      <span className="workfield-jumptopage">
                        Jump to Page:
                      </span>
                      {console.log("number of pages state: ", numberOfPages)}
                      <select
                        name="country"
                        className="workfield-selectdropdown"
                        onChange={this.handleNumberChange}
                        value={selectedItem}
                      >
                        {numberOfPages.map((e, key) => {
                          return (
                            <option key={key} value={e.value}>
                              {e.label}
                            </option>
                          );
                        })}
                      </select>
                      <div>
                        <img
                          src={leftarrow}
                          alt=""
                          style={{ marginRight: "5px", cursor: "pointer" }}
                        />{" "}
                        <span className="workfield-numberofpages">
                          {selectedItem + " Of " + numberOfPages.length}
                        </span>
                        <img
                          src={rightarrow}
                          alt=""
                          style={{ marginRight: "5px", cursor: "pointer" }}
                        />{" "}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                {loader}
                <NewSnackbar />
                {showRankMatrix ? (
              <AmountFieldsMatrix
              closefunction={this.closefunction.bind(this)}
              distributorID ={distID}
              runID = {selectedRunID}
              detailKey = {detailKey}
              amountFieldName = {labelName}
              />
            ) : (
              ""
            )}
              </div>
            );
        }
        else {
            return <div id="center" style={{ position: "fixed", bottom: "40%", left: "48%" }}>
                <Oval
                    type="Puff"
                    position="fixed"  secondaryColor="#E85557"
                    color="#E85557"
                    height={50}
                    width={50}
                    timeout={3000}
                />
            </div>
        }
    }
}
const mapStateToProps = (state) => {
    return {
        distAmtfieldsData: state.commissionDistResearch.distAmtfieldsData,
        distAmtfieldsError: state.commissionDistResearch.distAmtfieldsError,
    };
};
const mapDispatchToProps = (dispatch) => ({
    getAmountFieldsData: (runID, distID) => dispatch(getDistributorAmountFields(runID, distID)),
    showSnackbar: (params) => dispatch({ type: actions.SNACKBAR_SHOW, payload: params }),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AmountFieldsTab));
