import React from 'react';
import '../ViewRunLog/ViewRunLog.css'
import PropTypes from 'prop-types';
import AuthService from '../../../../src/components/server/AuthService';
import '../CommissionsRunManager/CommissionRunManager.css'
import { Oval } from "react-loader-spinner";
import { connect } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown'
import noreports from '../assets/norunreport.svg'
import leftarrow from '../assets/leftarrow.svg'
import closeIcon from '../assets/closeicon.svg';
import rightarrow from '../assets/rightarrow.svg'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withRouter } from 'react-router-dom';
import Moment from 'moment';

import {
  getCommissionUpdateRunLogs,
  getSelectedRunLogAPI,
  getCommissionTypeListAPI,
  getCommissionPeriodListAPI,
  getCommSelectedRunLog,
} from "../actions";
import * as Constants from "../commonComponents/Constants";
import {
  allNullTypeCheck,
  isNullResponse,
} from "../commonComponents/UtilityFun";
import { getMonthDays } from "../../utilities/Services";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import gotoarrow from "../assets/gotoarrow.svg";
import actions from '../../../redux/actions/CommonDataActions';
import NewSnackbar from "../../utilities/NewSnackbar";
import * as Constant from '../../../constants/Constants';

const fields = [
  { name: "Type", displayName: "Type", inputFilterable: true, sortable: true },
  {
    name: "name",
    displayName: "Name",
    inputFilterable: false,
    exactFilterable: true,
    sortable: true,
  },
  {
    name: "createdOn",
    displayName: "Created On",
    inputFilterable: false,
    exactFilterable: true,
    sortable: true,
  },
  {
    name: "lastRunDate",
    displayName: "Last Opened On",
    inputFilterable: true,
    sortable: true,
  },
  {
    name: "Activity",
    displayName: "Activity",
    inputFilterable: false,
    exactFilterable: true,
    sortable: true,
  },
  {
    name: "user",
    displayName: "User",
    inputFilterable: false,
    exactFilterable: true,
    sortable: true,
  },
];
const selectedRunfields = [
  { displayName: "Date" },
  { displayName: "Time" },
  { displayName: "Activity" },
  { displayName: "User" },
];
class ViewRunLog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedValue: "Primary",
      selectPeriodVal: "00/0000",
      selectedCommissionPeriodIndex: 0,
      commissionTypes: [],
      commissionRuns: [],
      selectedCommissionType: "Primary",
      commissionPeriodData: null,
      commissionPeriods: [],
      dataObj: [],
      previousDisabled: false,
      nextDisabled: false,
      anchorEl: false,
      commissionRunState: [],
      selectedRunInfo: [],
      selectedCommissionType: "",
      commissionPeriods: [],
      selectTypeIndex: "",
      commTypeWaitTime:1,
      runsWaitTime:1,
      periodWaitTime:1,
      logWaitTime:1,
      commTypeWaitTime:1
    };
    this.viewLogTableDisplay = this.viewLogTableDisplay.bind(this);
    this.selectedRunLogDisplay = this.selectedRunLogDisplay.bind(this);
  }

  componentDidMount() {
    const { selectedCommissionRun, selectedOption, periodType, commissionPeriod } = this.props;
    console.log("selected run :", selectedCommissionRun?.runId, commissionPeriod, periodType);
    if(selectedOption===Constants.VIEW_RUN_LOG_DISPLAY && allNullTypeCheck(selectedCommissionRun?.runId)){
      this.props.getSelectedRunLog(selectedCommissionRun?.runId); 
    }else {
        this.props.getCommissionsUpdateLogs();
    }
    this.props.getCommissionTypes();
    this.setState({ dataObj: [] });
  }
  componentDidUpdate(nextProps) {
    const {
      commissionRunUpdateLogs,
      commissionSelectedRunDetaiils,
      commissionRunLogError,
      commissionSelectedRunDError,
      commissionTypeList,
      commissionPeriodList,
      commissionPeriodError,
      selectedRunLogResponse,
      selectedRunLogError,
      selectedCommissionRun,
      periodType,
      commissionPeriod
    } = this.props;
    const { selectedCommissionType, runsWaitTime, periodWaitTime, logWaitTime, commTypeWaitTime } = this.state;
    if (commissionTypeList !== nextProps.commissionTypeList) {
      this.setState({ showLoaderResult: false });
      if(!commissionTypeList.hasOwnProperty('status')){
      if (isNullResponse(commissionTypeList.data)) {
        if(commissionTypeList?.data?.hasOwnProperty('periodTypes')){
        console.log("commissionTypeList.data", commissionTypeList.data);
        this.setState(
          { commissionTypes: commissionTypeList?.data?.periodTypes.filter(data=>data.active===true) },
          () => {
            console.log(
              "commission Period success : ",
              commissionTypeList.data.periodTypes[0]?.title
            );
             //here we have to pass session storage period value
              this.setState(
                {
                  selectedCommissionType: {
                    title: periodType?.title,
                    defaultPeriod:
                    commissionPeriod,
                  }
                },
                () => {
                  this.props.getCommissionPeriod(
                    periodType?.title
                  );
                }
              )
          }
        );
       }
     }
    }else {
      if(commissionTypeList.hasOwnProperty('status') && commissionTypeList.status===202){ 
        this.setState({ showLoader: true });
        setTimeout(() => {
          this.props.getCommissionTypes();
        }, 1000 * commTypeWaitTime);
        this.setState(prevState => {
          return {commTypeWaitTime: prevState.commTypeWaitTime * 2}
       })
      }
    }
    }
    if (commissionPeriodList !== nextProps.commissionPeriodList) {
      if(!commissionPeriodList.hasOwnProperty('status')){
      this.setState({ showLoaderResult: false });
      if (isNullResponse(commissionPeriodList.data) && commissionPeriodList?.error===false) {
        if(isNullResponse(commissionPeriodList?.data?.periods)){
        let commPeriods = null;
        console.log("selected period type ", selectedCommissionType);
        commPeriods =
          commissionPeriodList?.data?.periods[
            selectedCommissionType?.title?.toString()?.toLocaleLowerCase()
          ];
        this.setState(
          {
            commissionPeriods: commPeriods,
          },
          () => {
            if (
              allNullTypeCheck(commPeriods) &&
              commPeriods.length > 0 &&
              allNullTypeCheck(selectedCommissionType?.defaultPeriod)
            ) {
              this.setState({ selectedCommissionPeriodIndex: commissionPeriod });
              // this.selectPeriodIndex(selectedCommissionType?.defaultPeriod);
            } else {
              this.setState({ selectedCommissionPeriodIndex: 0 });
            }
          }
        );
        }else{
          this.setState({
            commissionPeriods: [],
          });
        }
      } else {
        console.log("Getting empty periods or error : ", commissionPeriodList);
        this.setState({
          commissionPeriods: [],
        });
      }
    } else {
      if(commissionPeriodList.hasOwnProperty('status') && commissionPeriodList.status===202){ 
        this.setState({
          commissionPeriods: [],
        });
        this.setState({ showLoader: true });
        setTimeout(() => {
          this.props.getCommissionPeriod(
            commissionTypeList?.data?.periodTypes[0]?.title
          );
        }, 1000 * periodWaitTime);
        this.setState(prevState => {
          return {periodWaitTime: prevState.periodWaitTime * 2}
       })
      }
    }
    } else if (commissionPeriodError !== nextProps.commissionPeriodError) {
      console.log("Period list Error : ", commissionPeriodError);
    }
    if (commissionRunUpdateLogs !== nextProps.commissionRunUpdateLogs) {
      this.setState({ showLoader: false });
      if(!commissionRunUpdateLogs.hasOwnProperty('status')){
        if (isNullResponse(commissionRunUpdateLogs.data)) {
          this.setState({ commissionRunState: commissionRunUpdateLogs.data });
        }
      } else {
        if(commissionRunUpdateLogs.hasOwnProperty('status') && commissionRunUpdateLogs.status===202){ 
          this.setState({ showLoader: true });
          setTimeout(() => {
            this.props.getCommissionsUpdateLogs();
          }, 1000 * logWaitTime);
          this.setState(prevState => {
            return {logWaitTime: prevState.logWaitTime * 2}
         })
        }
      } 
    } else if (commissionRunLogError !== nextProps.commissionRunLogError) {
      console.log("commission Run log error : ", commissionRunLogError);
      this.props.showSnackbar({
        snackbarOpen: true,
        snackbarType: Constant.SNACKBAR_TYPE.error,
        snackbarMessage: commissionRunLogError.data?.messages[0]?.detail
      });
    }

    // if (
    //   commissionSelectedRunDetaiils !== nextProps.commissionSelectedRunDetaiils
    // ) {
    //   if (isNullResponse(commissionSelectedRunDetaiils.data)) {
    //     console.log("selected run info : ", commissionSelectedRunDetaiils);
    //     this.setState({ selectedRunInfo: commissionSelectedRunDetaiils.data });
    //   }
    // } else if (
    //   commissionSelectedRunDError !== nextProps.commissionSelectedRunDError
    // ) {
    //   console.log("selected run info : ", commissionSelectedRunDError);
    // }

  if (selectedRunLogResponse !== nextProps.selectedRunLogResponse) {
      this.setState({ showLoader: false });
      if(!selectedRunLogResponse.hasOwnProperty('status')){
        console.log('runs response', selectedRunLogResponse)
        if(selectedRunLogResponse?.error === false) {
        if (isNullResponse(selectedRunLogResponse?.data)){
          console.log("selected run log details :",selectedRunLogResponse);
          this.setState({ selectedRunInfo: selectedRunLogResponse.data });
          } else if(Object.keys(selectedRunLogResponse?.data).length===0 && allNullTypeCheck(selectedRunLogResponse?.messages)) {
            if(selectedRunLogResponse?.messages?.length > 0 && selectedRunLogResponse?.messages[0]?.includes("Try again...")){
                console.log("Try again block entered : ",selectedRunLogResponse?.data);
                this.setState({ showLoader: true });
                setTimeout(() => {
                  this.props.getSelectedRunLog(selectedCommissionRun?.runId); 
                }, 1000 * runsWaitTime);
                this.setState(prevState => {
                  return {runsWaitTime: prevState.runsWaitTime * 2}
              })
            }
          } else {
            this.props.showSnackbar({snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: Constant.ALERT_TECHNICAL_ERROR })       
          }
        } else if(selectedRunLogResponse?.error === true){
          this.props.showSnackbar({
            snackbarOpen: true,
            snackbarType: Constant.SNACKBAR_TYPE.error,
            snackbarMessage: typeof(selectedRunLogResponse?.messages[0])!=='string'? selectedRunLogResponse?.messages[0].code+' - '+selectedRunLogResponse?.messages[0].detail:selectedRunLogResponse?.messages[0],
          });
        } else {
          this.props.showSnackbar({snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: Constant.ALERT_TECHNICAL_ERROR })       
        }
      } else {
        if(selectedRunLogResponse.hasOwnProperty('status') && selectedRunLogResponse.status===202){ 
          this.setState({ showLoader: true });
          setTimeout(() => {
            this.props.getSelectedRunLog(selectedCommissionRun?.runId) 
          }, 1000 * logWaitTime);
          this.setState(prevState => {
            return {logWaitTime: prevState.logWaitTime * 2}
         })
        }
      }
     
    } else if (selectedRunLogError !== nextProps.selectedRunLogError) {
     // console.log("run list error : ", commissionRunListError);
     if(selectedRunLogError?.error === true){
      this.props.showSnackbar({
        snackbarOpen: true,
        snackbarType: Constant.SNACKBAR_TYPE.error,
        snackbarMessage: typeof(selectedRunLogError?.messages[0])==='string'? selectedRunLogError?.messages[0].code+' - '+selectedRunLogError?.messages[0].detail:selectedRunLogError?.messages[0],
      });
    }
    }

  }
  selectPeriodIndex(value) {
    const { totalRuns, commissionPeriods } = this.state;
    const { commissionRunList } = this.props;
    this.setState({ totalRuns: [], commissionRunState: [] }, () => {});
    console.log("commission periods : ", commissionPeriods);
    console.log("commission run list : ", commissionRunList);
    if (isNullResponse(commissionPeriods) && commissionPeriods.length > 0 && value !== '00/0000') {
      const indexValue = commissionPeriods.findIndex((data) => {
        return getMonthDays(data.name) === value;
      });
      console.log("selected index : ", indexValue, commissionRunList);
      if (allNullTypeCheck(indexValue) && indexValue !== -1) {
        this.setState({ selectedCommissionPeriodIndex: indexValue }, () => {});
        commissionRunList?.data?.runs?.filter((data) => {
          if (data.period === value) {
            totalRuns.push(data);
          }
        });
        this.setState({ commissionRunState: [] }, () => {
          this.setState({ commissionRunState: totalRuns });
        });
      } else if (indexValue === -1) {
        this.setState({ selectedCommissionPeriodIndex: 0, commissionRunState: [] }, () => {});
        // commissionRunList?.data?.runs?.filter((data) => {
        //   if (data.period === value) {
        //     totalRuns.push(data);
        //   }
        // });
        // this.setState({ commissionRunState: [] }, () => {
        //   this.setState({ commissionRunState: totalRuns });
        // });
      }
    }
    else{
      this.setState({ commissionRunState: [], selectedCommissionPeriodIndex: 0 })
    }
  }
  viewlogScreen() {
    //here to implement view log screen
    this.setState({
      isViewLogRunModal: !this.state.isViewLogRunModal,
    });
  }

  selectedPeriodDetails() {
    //here need to show selected period details with list
    this.setState({ anchorEl: true });
  }

  handleClick = (event, selectedRun) => {
    if (event.currentTarget.name === "threedots") {
      this.setState({
        deleteRunSelected: selectedRun,
        anchorEl: event.currentTarget,
        displayThreeDots: true,
      });
    } else {
      this.setState({ anchorEl: event.currentTarget, displayPerioPop: true });
    }
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      displayThreeDots: false,
      displayPerioPop: false,
    });
  };
  handlePeriodInput = (input) => {
    if (allNullTypeCheck(input.target)) {
      console.log("input is", input.target);
      const { value } = input.target;
      if (value) {
        this.setState({ periodValue: value });
      } else {
        this.setState({ periodValue: "" });
      }
    }
  };
  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.onJumpSelectPeriod();
    }
  };
  onJumpSelectPeriod = () => {
    const { periodValue, commissionRunList } = this.state;
    this.setState({ anchorEl: false });
    if (periodValue) {
      this.selectPeriodIndex(periodValue);
    } else {
      this.setState({ periodValue: "" });
      this.setState({ commissionRunState: commissionRunList.data?.runs });
    }
  };
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  getNextPeriod() {
    this.setState((prevState, props) => ({
      selectedCommissionPeriodIndex:
        prevState.selectedCommissionPeriodIndex + 1,
    }));
    this.updateSelectPeriod(this.state.selectedCommissionPeriodIndex + 1);
  }
  updateSelectPeriod(index) {
    const { commissionPeriods } = this.state;
    if (allNullTypeCheck(commissionPeriods) && commissionPeriods.length > 0) {
      this.selectPeriodIndex(getMonthDays(commissionPeriods[index]?.name));
    }
  }
  getPreviousPeriod() {
    this.setState((prevState, props) => ({
      selectedCommissionPeriodIndex:
        prevState.selectedCommissionPeriodIndex - 1,
    }));
    this.updateSelectPeriod(this.state.selectedCommissionPeriodIndex + -1);
  }
  handleCommissionTypeChange = (value) => {
    console.log("Select comm type", value);
    const commissionObj = this.commissionsTypefindIndex(
      this.state.commissionTypes,
      value
    );
    console.log("rs: ", this.state.commissionTypes[commissionObj]);
    if (commissionObj !== -1) {
      this.setState({
        selectedCommissionType: this.state.commissionTypes[commissionObj],
        commissionRunState: [],
      });
      this.props.getCommissionPeriod(value);
    } else {
      this.setState({
        selectedCommissionType: this.state.commissionTypes[0],
      });
      this.props.getCommissionPeriod(value);
    }
  };
  commissionsTypefindIndex = (commissiontypeList, value) => {
    return commissiontypeList.findIndex((periodType) => {
      return periodType.title === value;
    });
  };
  viewLogTableDisplay() {
    console.log("display options :");
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            {fields.map((row) => (
              <TableCell
                className="runmanager-table-header"
                component="th"
                scope="row"
              >
                {row.displayName}
              </TableCell>
            ))}
          </TableHead>
          <TableBody>
            {this.state.commissionRunState?.length > 0
              ? this.state.commissionRunState?.map((commissionrun) => (
                  <TableRow key={commissionrun.name}>
                    <TableCell className="runmanager-list-type">
                      {commissionrun.type}
                    </TableCell>
                    <TableCell className="runmanager-name">
                      {commissionrun.name}
                    </TableCell>
                    <TableCell className="runmanager-data-created">
                      {commissionrun.created}
                    </TableCell>
                    <TableCell className="runmanager-data-lastRun">
                      {commissionrun.lastRun}
                    </TableCell>
                    <TableCell className="runmanager-data-status">
                      {commissionrun.status}
                    </TableCell>
                    <TableCell className="runmanager-data-user">
                      {commissionrun.user}
                    </TableCell>
                  </TableRow>
                ))
              : ""}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  selectedRunLogDisplay() {
    console.log("run display options :",selectedRunfields,this.state.selectedRunInfo);

    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            {selectedRunfields?.length > 0 && selectedRunfields.map((row,i) => (
              <TableCell
                className="runmanager-table-header"
                component="th"
                scope="row"
                key={i}
              >
                {row?.displayName}
              </TableCell>
            ))}
          </TableHead>
          <TableBody>
            {this.state.selectedRunInfo.length > 0
              ? this.state.selectedRunInfo.map((commissionrun) => (
                  <TableRow key={commissionrun.name}>
                    <TableCell className="runmanager-data-created">
                      {commissionrun.created}
                    </TableCell>
                    <TableCell className="runmanager-data-lastRun">
                      {commissionrun.lastRun}
                    </TableCell>
                    <TableCell className="runmanager-data-status">
                      {commissionrun.status}
                    </TableCell>
                    <TableCell className="runmanager-data-user">
                      {commissionrun.user}
                    </TableCell>
                  </TableRow>
                ))
              : ""}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  render() {
    const {
      vewrunlogbackfunc,
      selectedOption,
      selectedRun,
      periodType,
      commissionPeriod,
    } = this.props;
    const {
      displayPerioPop,
      selectedCommissionType,
      selectedCommissionPeriodIndex,
      anchorEl,
      commissionTypes,
      periodValue,
      commissionPeriods,
      selectPeriodVal,
    } = this.state;
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    if (AuthService.isAuthenticated()) {
      return (
        <div className="flex">
          <div className="rowalign-center commrun-detail-header-style">
            <div className="col-8 h2title">
              {selectedOption === Constants.VIEW_LOG_DISPLAY
                ? selectedRun
                : selectedRun?.name}
              {selectedOption === Constants.VIEW_LOG_DISPLAY ? (
                ""
              ) : (
                <span className="selected-run-log">
                  {selectedRun.qualificationProgram + ",  "}{" "}
                  {selectedRun.calculationProgram + ",  "}
                  {selectedRun.disbursementProgram + ",  |  "}
                  {"Created on " + Moment(selectedRun?.created).format('MM/DD/YYYY') !== 'Invalid date' ? Moment(selectedRun?.created).format('MM/DD/YYYY HH:mm:ss') : ''}
                </span>
              )}
            </div>
            {selectedOption === Constants.VIEW_RUN_LOG_DISPLAY ? (
              <div className="comm-type-viewlogperiod-display">
                <div>
                  <div className="runmanager-alignitems-left cm-type-title">
                    <span className="alignitems-left cm-type-title">
                      Period Type
                    </span>
                    <div className="runmanager-element-width alignitems-left">
                      <Dropdown
                        className="runmanger"
                        onSelect={this.handleCommissionTypeChange}
                        style={{
                          backgroundColor: "white",
                          marginTop: "8px",
                        }}
                      >
                        <Dropdown.Toggle
                          id="dropdown-custom-components"
                          title="Select Type"
                        >
                          {allNullTypeCheck(selectedCommissionType?.title) ? selectedCommissionType?.title :  selectedCommissionType}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="period-dropdown-menu">
                          {this.state.commissionTypes.map((item) => {
                            return (
                              <Dropdown.Item
                                className="primary-dropbtn"
                                eventKey={item.title}
                              >
                                {item.title}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                      {/* <img src={dropdown1} alt='' /> */}
                    </div>
                  </div>
                </div>
                <div className="runmanager-period-margin">
                  <div className="runmanager-alignitems-left cm-type-title">
                    <span className="alignitems-left cm-type-title">
                      Commission Period
                    </span>
                    <div className="runmanager-element-width alignitems-left">
                      <div className="cd-template" style={{ marginTop: "8px" }}>
                        <div className="cd-text-temp">
                          <div
                            onClick={this.getPreviousPeriod.bind(
                              this,
                              selectedCommissionPeriodIndex
                            )}
                            disabled={
                              selectedCommissionPeriodIndex > 0 ? false : true
                            }
                          >
                            <img
                              src={leftarrow}
                              alt=""
                              style={{ marginRight: "5px", cursor: "pointer" }}
                            />{" "}
                          </div>
                          <span
                            aria-describedby={id}
                            name="periodvalues"
                            variant="contained"
                            onClick={this.handleClick}
                            style={{ marginBottom: "2px", cursor: "pointer" }}
                          >
                            {commissionPeriods?.length > 0
                              ? getMonthDays(
                                  commissionPeriods[
                                    this.state.selectedCommissionPeriodIndex
                                  ]?.name
                                )
                              : "00/0000"}
                          </span>
                          <div
                            onClick={this.getNextPeriod.bind(
                              this,
                              selectedCommissionPeriodIndex
                            )}
                            disabled={
                              selectedCommissionPeriodIndex ===
                              commissionPeriods?.length - 1
                                ? true
                                : false
                            }
                          >
                            <img
                              src={rightarrow}
                              alt=""
                              style={{ marginLeft: "5px", cursor: "pointer" }}
                            />{" "}
                          </div>
                        </div>
                        {displayPerioPop && commissionPeriods?.length > 0 ? (
                          <Popover
                            id={id}
                            open={open}
                            anchorEl={this.state.anchorEl}
                            onClose={this.handleClose.bind(this)}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                          >
                            <Typography sx={{ p: 2 }} component={'span'} variant={'body2'}>
                              {commissionPeriods?.length > 0 ? (
                                <div className="selectcpdetails">
                                  <div className="cmperiod-select">
                                    Last Commission Period:{" "}
                                    {selectedCommissionType?.defaultPeriod}
                                  </div>
                                  <div className="cmperiod-select">
                                    Start Date:{" "}
                                    {
                                      commissionPeriods[
                                        selectedCommissionPeriodIndex
                                      ]?.start
                                    }
                                  </div>
                                  <div className="cmperiod-select">
                                    End Date:{" "}
                                    {
                                      commissionPeriods[
                                        selectedCommissionPeriodIndex
                                      ]?.end
                                    }
                                  </div>
                                  <div className="cmperiod-select">
                                    Default Check Date:{" "}
                                    {
                                      commissionPeriods[
                                        selectedCommissionPeriodIndex
                                      ]?.checkDate
                                    }{" "}
                                  </div>
                                  <div className="cmperiod-line"></div>
                                  <div className="jumpto">
                                    Jump To:
                                    <input
                                      className="col-4 jumpperiod-options"
                                      name="periodvalue"
                                      type="text"
                                      placeholder="MM/YYYY"
                                      value={periodValue}
                                      onChange={this.handlePeriodInput.bind(
                                        this
                                      )}
                                      onKeyPress={this.handleKeyPress}
                                    ></input>
                                    <img
                                      src={gotoarrow}
                                      style={{
                                        marginLeft: "8px",
                                        cursor: "pointer",
                                      }}
                                      alt=""
                                      onClick={this.onJumpSelectPeriod}
                                    />
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </Typography>
                          </Popover>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-4 padding-right-0">
                <div className="comm-type-period-display">
                  <div>
                    <div className="runmanager-alignitems-left cm-type-title">
                      <span className="alignitems-left cm-type-title">
                        Period Type
                      </span>
                      <div className="alignitems-left title-styles">
                        {periodType?.title}
                      </div>
                    </div>
                  </div>
                  <div className="commission-period-margin">
                    <div className="runmanager-alignitems-left cm-type-title">
                      <span className="alignitems-left cm-type-title">
                        Commission Period
                      </span>
                      <div className="alignitems-left title-styles">
                        { "commissionPeriod" }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <img
            className="updatelg-close-icon"
            onClick={vewrunlogbackfunc}
            src={closeIcon}
            alt=""
            style={{ cursor: "pointer" }}
          />
          <div className="col-12 cm-table-style">
            {selectedOption === Constants.VIEW_LOG_DISPLAY
              ? this.viewLogTableDisplay()
              : this.selectedRunLogDisplay()}
            {this.state.commissionRunState?.length === 0 ? (
              <div className="row noreports">
                <img src={noreports} alt="" />
              </div>
            ) : (
              ""
            )}
          </div>
          <NewSnackbar/>
        </div>
      );
    } else
      return (
        <div>
          <div
            id="center"
            style={{ position: "fixed", top: "40%", left: "48%" }}
          >
            <Oval
              type="Puff"
              position="fixed"
              secondaryColor="#E85557"
              color="#E85557"
              height={50}
              width={100}
              visible={true}
            />{" "}
          </div>
        </div>
      );
  }
}

ViewRunLog.propTypes = {
  vewrunlogbackfunc: PropTypes.func,
};
const mapStateToProps = (state) => {
  return {
    commissionRunUpdateLogs: state.commissionRunReducer.commissionRunUpdateLogs,
    commissionRunLogError: state.commissionRunReducer.commissionRunLogError,
    selectedCommissionRun: state.commissionRunReducer.selectedCommissionRun,
    commissionSelectedRunDetaiils:
      state.commissionRunReducer.commissionSelectedRunDetaiils,
    commissionSelectedRunDError:
      state.commissionRunReducer.commissionSelectedRunDError,
    commissionTypeList: state.commissionToolsReducer.commissionTypeList,
    commissionPeriodList: state.commissionToolsReducer.commissionPeriodList,
    commissionPeriodError: state.commissionToolsReducer.commissionPeriodError,
    selectedRunLogResponse: state.commissionRunReducer.selectedRunLogResponse,
    selectedRunLogError: state.commissionRunReducer.selectedRunLogError
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCommissionsUpdateLogs: () => dispatch(getCommissionUpdateRunLogs()),
  getSelectedRunLogs: (selectedRunID) =>
    dispatch(getSelectedRunLogAPI(selectedRunID)),
  getCommissionPeriod: (periodtype) =>
    dispatch(getCommissionPeriodListAPI(periodtype)),
  getCommissionTypes: () => dispatch(getCommissionTypeListAPI()),
  getSelectedRunLog: (runID) => dispatch(getCommSelectedRunLog(runID)),
  showSnackbar: (params) => dispatch({ type: actions.SNACKBAR_SHOW, payload: params}),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ViewRunLog));