import React, { Suspense, useEffect, useState, useCallback } from 'react'
import { useHistory, useParams } from 'react-router'
// import ReportsNav from './ReportsNav/ReportsNav'
import reportsImg from '../../assets/reportsImg.svg'
import LoaderComponent from '../../Settings/LoaderComponent'
import "../../Settings/settings-dashboard.css"
import backarrow from "../../../../assets/backarrow.svg";
import printer from "../../assets/printer.svg";
import exportimg from "../../assets/exportimg.svg";
import threedots from "../../assets/three-dots.svg";
import { useSelector } from 'react-redux'
import NewSnackBar from "../../../utilities/NewSnackbar"
import { useDispatch } from 'react-redux';
// import ReportsMain from './ReportsMain'
import CommRankAdvancement from './CommRankAdvancement'
import {RunMainContainer} from './RunMainContainer'
import Component2 from '../../Component2'
import * as Constants from '../constants';
import { useLocation } from "react-router-dom";
import Endpoints from '../../../utilities/Endpoints'
import { allNullTypeCheck, isNullResponse } from '../../commonComponents/UtilityFun'
import actions from '../../../../redux/actions/CommonDataActions'
import * as Constant from '../../../../constants/Constants'
import { LOADING, REPORT_PARAMS } from '../actions'
import { getCleintNameEnv } from '../../utilities/services'

function RunNavContainer() {
  const location = useLocation();
  const utilData = useSelector(state => state.loadingStateReducer)
  const paramsData = useSelector(state => state.reportsMainReducer.reportParamRes)
  const dispatch = useDispatch();
  
  const history = useHistory();
  const [reportsParams, setReportsParams] = useState(location.state.navParams)
  const [paramRep, setParamRep] = useState([])

  const reportName =  location.state.heading //localStorage.getItem("heading");
  const reportCategory = location.state.category //localStorage.getItem("category");
  
            

  
  const handleBackAction = () => {
    dispatch({ type: REPORT_PARAMS, payload: [] })
    console.log("back handling :");
    history.push({
      pathname: '/reports',
      state: {
        heading: reportName,
      }
    });
  }
  useEffect(()=>{
    return()=>{
      dispatch({ type: REPORT_PARAMS, payload: [] })
    }
  },[])


  function Print() {
    //console.log('print');  
    //let printContents = document.getElementById('prints').innerHTML;
    var printContent = document.getElementById('print');
    var WinPrint = window.open('', '', 'width=900,height=650');
    WinPrint.document.write(printContent.innerHTML);
    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
    WinPrint.close();
  }
  const downloadCSV = () => {
    console.log("download csv file:");
      fetch(`${getCleintNameEnv()}reports/${encodeURI(reportCategory)}:${encodeURI(reportName)}/csv`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(paramsData),
      }).then((response) => response.text())
      .then((res) => {
        //console.log("Parameters", res);
        console.log('CSV data', res);
        if (typeof (res) === 'string') {
          downloadReportCSV(res, `${localStorage.getItem("heading")}-report.csv`);
        } else {
          console.log("error response : ", res, '---', typeof (res))
          if (typeof (res) === 'string') {
            let resp = JSON.parse(res);
            dispatch({ type: actions.SNACKBAR_SHOW, payload: { snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: (typeof (resp) !== 'string' && resp.hasOwnProperty('messages')) ? resp.messages[0] : resp } })
          }
        }
      }).catch((e) => {
        console.log("Getting service error: ", e);
        dispatch({ type: actions.SNACKBAR_SHOW, payload: { snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: (typeof (e) !== 'string' && typeof (e) === 'object' && e.hasOwnProperty('message')) ? e.message : e } })
      })
  }
  function renderCSV(data) {
    // export csv for download
    downloadReportCSV(data, `${reportName}-report.csv`);
  }
  function downloadReportCSV(csv, filename) {
    var csvFile;
    var downloadLink;

    csvFile = new Blob([csv], { type: "text/csv" });
    downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }
  //To update the html params changes with this functions
  // const htmlParamsUpdate=(params)=>{
  //   setReportsParams(params)
  // }
  const { reportsId } = useParams()
  const display = utilData.loading ? 'none' : 'block'
  return (
    <>
      <NewSnackBar />
      <div className="row" style={{ display: 'flex', 'alignItems': 'center', flexDirection: 'column' }}>
        <div className="col-12" style={{ maxWidth: '2000px' }}>
          <Component2 mainText="Reports" typeText={"RunDetails"}
            detailsText={reportName} />
          <div className="flex">
            <div className='report-main-content-header'>
              <div className="col-8 cmt-title">
                <img
                  src={backarrow}
                  style={{
                    cursor: "pointer",
                    height: "16px",
                    width: "16px",
                    marginTop: "7px",
                    paddingTop:'2px'
                  }}
                  alt=""
                  onClick={handleBackAction}
                />
                <span className='report-titlesize'>{reportName}</span>
              </div>
              <div>
                <img
                  src={printer}
                  style={{
                    cursor: "pointer",
                    height: "17px",
                    width: "17px",
                    marginTop: "7px",
                  }}
                  alt=""
                  onClick={Print}
                />
                <img
                  src={exportimg}
                  style={{
                    marginLeft: '10px',
                    cursor: "pointer",
                    height: "17px",
                    width: "17px",
                    marginTop: "7px",
                  }}
                  alt=""
                  onClick={downloadCSV}
                />
                <img
                  src={threedots}
                  style={{
                    marginLeft: '10px',
                    cursor: "pointer",
                    height: "17px",
                    width: "17px",
                    marginTop: "7px",
                  }}
                  alt=""
                // onClick={handleBackAction}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 flex-container settings-container" >
                <Suspense fallback={<LoaderComponent />}>
                  <div className='reports-sidenav'>
                    <CommRankAdvancement details={Constants.REPORTS_LEFTNAV} heading={Constants.REPORT_LEFTNAV_TITLE} displayType={"Internal"} reportsPams={reportsParams} />
                  </div>
                  <div className='reports-main' style={{ alignItems: "baseline" }}>
                    {utilData.loading && <LoaderComponent />}
                    {/* { reportsId && links[reportsId] ? <ReportsDashboard loading={utilData.loading} heading={links[reportsId].text} Component={links[reportsId].component} /> : <img src={reportsImg} alt="reports" />} */}
                    {/* <RunMainContainer heading={reportName} category={reportCategory} displayType={"Internal"} reportsPams={reportsParams} htmlParamsUpdate={htmlParamsUpdate}/> */}
                    <RunMainContainer heading={reportName} category={reportCategory} displayType={"Internal"} reportsPams={reportsParams}/>
                  </div>
                </Suspense>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const ReportsDashboard = ({ heading, category, Component }) => <Component heading={heading} category={category} />




export default React.memo(RunNavContainer)
