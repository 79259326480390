import { integration as integrationsActions } from '../actions/integrationDataActions';

// reducer with initial state
const initialState = {
  integrationList: [],
  integrationError: '',
  endpointResponse: {},
  endpointError: '',
  callBackURLResponse: {},
  callBackURLError: '',
  eventsTypeResponse: {},
  eventsTypeError: '',
  createIntegrationResponse: {},
  createIntegrationError: '',
  getIntegrationResponse: {},
  getIntegrationError: '',
  integrationStatusResponse: {},
  integrationStatusError: '',
  integrationDeleteResponse: {},
  integrationDeleteError: '',
  optionalFeatureResponse: {},
  optionalFeatureError: '',
  featuresPostResponse: {},
  featurePostError: '',
  currentIntegrationID: '',
  currentIntegrationName :'',
  createWebhookResponse: {},
  createWebhookError: '',
  currentIntegrationRes: {},
  generateKeyResponse: {},
  generateKeyHasError: '',
  webhookPostResponse: {},
  webhookPostHasError: '',
  editIntegrationResponse: {},
  editIntegrationError: '',
  editWebhookResponse: {},
  editWebhookError: '',
  integrationNameResposne: {},
  integrationNameError: '',
  getwebhookIntegrationResposne: [],
  getwebhookIntegrationError: '',
  getActivitiesResposne: {},
  getActivitiesError: '',
  devtokenResposne: {},
  devtokenError: ''
};

export default function integrationDataReducer(state, action) {
  if (typeof state === 'undefined') {
    return initialState;
  }
  switch (action.type) {
    case integrationsActions.INTEGRATIONS_SUCCESS:
      return { ...state, integrationList: action.payload };
    case integrationsActions.INTEGRATIONS_HASERROR:
      return { ...state, integrationError: action.payload };
    case integrationsActions.ENDPOINTRESULT_SUCCESS:
      return { ...state, endpointResponse: action.payload };
    case integrationsActions.ENDPOINTRESULT_HASERROR:
      return { ...state, endpointError: action.payload };
    case integrationsActions.CALLBACKURL_SUCCESS:
      return { ...state, callBackURLResponse: action.payload };
    case integrationsActions.CALLBACKURL_HASERROR:
      return { ...state, callBackURLError: action.payload };
    case integrationsActions.EVENTSTYPELIST_SUCCESS:
      return { ...state, eventsTypeResponse: action.payload };
    case integrationsActions.EVENTSTYPELIST_HASERROR:
      return { ...state, eventsTypeError: action.payload };
    case integrationsActions.CREATEINTEGRATION_SUCCESS:
      return { ...state, createIntegrationResponse: action.payload };
    case integrationsActions.CREATEINTEGRATION_HASERROR:
      return { ...state, createIntegrationError: action.payload };
    case integrationsActions.GETINTEGRATION_SUCCESS:
      return { ...state, getIntegrationResponse: action.payload };
    case integrationsActions.GETINTEGRATION_HASERROR:
      return { ...state, getIntegrationError: action.payload };
    case integrationsActions.INTEGRATION_STATUS_SUCCESS:
      return { ...state, integrationStatusResponse: action.payload };
    case integrationsActions.INTEGRATION_STATUS_HASERROR:
      return { ...state, integrationStatusError: action.payload };
    case integrationsActions.INTEGRATION_DELETE_SUCCESS:
      return { ...state, integrationDeleteResponse: action.payload };
    case integrationsActions.INTEGRATION_DELETE_HASERROR:
      return { ...state, integrationDeleteError: action.payload };
    case integrationsActions.OPTIONAL_FEATURE_SUCCESS:
      return { ...state, optionalFeatureResponse: action.payload };
    case integrationsActions.OPTIONAL_FEATURE_HASERROR:
      return { ...state, optionalFeatureError: action.payload };
    case integrationsActions.FEATURES_POST_SUCCESS:
      return { ...state, featuresPostResponse: action.payload };
    case integrationsActions.FEATURES_POST_HASERROR:
      return { ...state, featurePostError: action.payload };
    case integrationsActions.CURRENT_INTEGRATIONID:
      return { ...state, currentIntegrationID: action.payload };
    case integrationsActions.CURRENT_INTEGRATION_NAME:
      return { ...state, currentIntegrationName: action.payload };
    case integrationsActions.WEBHOOK_SUCCESS:
      return { ...state, createWebhookResponse: action.payload };
    case integrationsActions.WEBHOOK_HASERROR:
      return { ...state, createWebhookError: action.payload };
    case integrationsActions.CURRENT_INTEGRATION_RES:
      return { ...state, currentIntegrationRes: action.payload };
    case integrationsActions.GENERATEKEY_SUCCESS:
      return { ...state, generateKeyResponse: action.payload };
    case integrationsActions.GENERATEKAY_HASERROR:
      return { ...state, generateKeyHasError: action.payload };
    case integrationsActions.EDIT_INTEGRATION_SUCCESS:
      return { ...state, editIntegrationResponse: action.payload };
    case integrationsActions.EDIT_INTEGRATION_HASERROR:
      return { ...state, editIntegrationError: action.payload };
    case integrationsActions.EDIT_WEBHOOK_SUCCESS:
      return { ...state, editWebhookResponse: action.payload };
    case integrationsActions.EDIT_WEBHOOK_HASERROR:
      return { ...state, editWebhookError: action.payload };
    case integrationsActions.POST_INTEGRATION_NAME_SUCCESS:
      return { ...state, integrationNameResposne: action.payload };
    case integrationsActions.POST_INTEGRATION_NAME_ERROR:
      return { ...state, integrationNameError: action.payload };
    case integrationsActions.GETWEBHOOK_INTEGRATION_SUCCESS:
      return { ...state, getwebhookIntegrationResposne: action.payload };
    case integrationsActions.GETWEBHOOK_INTEGRATION_HASERROR:
      return { ...state, getwebhookIntegrationError: action.payload };
    case integrationsActions.ACTIVITY_DETAILS_SUCCESS:
      return { ...state, getActivitiesResposne: action.payload };
    case integrationsActions.ACTIVITY_DETAILS_ERROR:
      return { ...state, getActivitiesError: action.payload };
    case integrationsActions.POST_DEVTOKEN_SUCCESS:
      return { ...state, devtokenResposne: action.payload };
    case integrationsActions.POST_DEVTOKEN_ERROR:
      return { ...state, devtokenError: action.payload };
    default:
      return state;
  }
}