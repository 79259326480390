import { combineReducers } from 'redux';
import commonDataReducer from './reducers/CommonDataReducer';
// import authReducer from './reducers/authReducer';
import appsReducer from './reducers/appsReducer';
import userDataReducer from './reducers/userDataReducer';
import integrationDataReducer from './reducers/integrationDataReducer';
import emailTemplateDataReducer from './reducers/emailTemplateDataReducer';
import integrationPopUpReducers from './reducers/IntegrationPopUpReducers';
import snackBarDataReducer from './reducers/snackBarDataReducer'
import commissionReducer from '../components/Commissions/reducers/commissionReducer';
import commissionRunReducer from '../components/Commissions/reducers/commissionRunReducer';
import commissionRunDetailsReducer from '../components/Commissions/reducers/commissionRunDetailsReducer';
import commissionToolsReducer from '../components/Commissions/reducers/commissionToolsReducer';
import commissionDistResearch from '../components/Commissions/reducers/commissionDistResearch';
import distributorResearchReducer from '../components/Commissions/reducers/distributorResearchReducer';
import settingsReducers from '../components/Commissions/Settings/reducers';
import reportsReducers from '../components/Commissions/ReportsModule/reducers';
import userModuleReducer from '../components/usermanagement/reducers';

export default combineReducers({ 
  commonDataReducer:commonDataReducer,
  // authReducer:authReducer,
  appsReducer:appsReducer,
  userDataReducer:userDataReducer,
  integrationDataReducer:integrationDataReducer,
  emailTemplateDataReducer:emailTemplateDataReducer,
  integrationPopUpReducers: integrationPopUpReducers,
  snackBarDataReducer: snackBarDataReducer,  
  commissionReducer : commissionReducer,
  commissionRunReducer : commissionRunReducer,
  commissionRunDetailsReducer : commissionRunDetailsReducer,
  commissionToolsReducer : commissionToolsReducer,
  commissionDistResearch : commissionDistResearch,
  distributorResearchReducer:distributorResearchReducer,
  ...settingsReducers,
  ...reportsReducers,
  ...userModuleReducer,
});