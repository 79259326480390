import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Oval } from "react-loader-spinner";
import { Modal } from "react-bootstrap";
import vector13 from '../../../assets/vector-13@2x.png';
import '../AddCompareRun/AddCompareModal.css'
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { getAmountFieldDetailsMatrixAPI } from "../actions";
import {numberWithCommas}  from '../../utilities/Services'
import { isNullResponse, allNullTypeCheck } from "../commonComponents/UtilityFun";
import * as Constants from '../../../constants/Constants';
import NewSnackbar from "../../utilities/NewSnackbar";
import actions from '../../../redux/actions/CommonDataActions';
import "./GeneralTab.css";
import { generateMatrixCSV } from '../CommToolCompareRun/generateCSV';
import exports from '../../../assets/exports.svg';

const fields = [
    { name: 'Paid Rank', displayName: "Dist ID", inputFilterable: true, sortable: true },
    { name: 'Bv', displayName: "Name", inputFilterable: false, exactFilterable: true, sortable: true },
    { name: 'Org Volume', displayName: "Amount", inputFilterable: false, exactFilterable: true, sortable: true },
    { name: 'Active Leg', displayName: "Level", inputFilterable: true, sortable: true }  
];

class AmountFieldMatrix extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoaderResult: true,
      showRemoveColModal: true,
      hideModal: false,      
      workFieldDetailsList: [],
      runsWaitTime:1
    };
  }

  componentDidMount() {
    const { distributorID, runID, detailKey } = this.props;
    if(allNullTypeCheck(distributorID) && allNullTypeCheck(runID) && allNullTypeCheck(detailKey)){
      this.props.getAmountFieldDetailsMatrixAPI(runID, distributorID, detailKey);
    }else{
      this.setState({showLoaderResult:false});
    }
  }
  componentDidUpdate(nextProps) {
    const {
        amountFieldDetailsList,
        amountFieldDetailsError,
        distributorID, runID, detailKey
    } = this.props;
    const { runsWaitTime } = this.state;
    if (nextProps.amountFieldDetailsList !== amountFieldDetailsList) {
      this.setState({ showLoaderResult: false });
      console.log("work field details : ",amountFieldDetailsList);
      if(!amountFieldDetailsList.hasOwnProperty('status')){
      if (isNullResponse(amountFieldDetailsList.data)) {
        if (amountFieldDetailsList.error === false) {
          console.log("Runs sucess : ", amountFieldDetailsList);
          this.setState({
            workFieldDetailsList: amountFieldDetailsList?.data.amountFieldDetails
          });
        } else {
          if (amountFieldDetailsList.error === true) {
          this.props.showSnackbar({
            snackbarOpen: true,
            snackbarType: Constants.SNACKBAR_TYPE.error,
            snackbarMessage: typeof(amountFieldDetailsList?.messages[0])!=='string'? amountFieldDetailsList?.messages[0]?.code+' - '+amountFieldDetailsList?.messages[0]?.detail:amountFieldDetailsList?.messages[0],
          });
         }
        }
      }else if(Object.keys(amountFieldDetailsList?.data).length===0 && allNullTypeCheck(amountFieldDetailsList?.messages)) {
        if(amountFieldDetailsList?.messages?.length > 0 && amountFieldDetailsList?.messages[0]?.includes("Try again...")){
            console.log("Try again block entered : ",amountFieldDetailsList?.data);
            this.setState({ showLoader: true });
            setTimeout(() => {
              this.props.getAmountFieldDetailsMatrixAPI(runID, distributorID, detailKey);
            }, 1000 * runsWaitTime);
            this.setState(prevState => {
              return {runsWaitTime: prevState.runsWaitTime * 2}
           })
        }
      }
       else {
        if (amountFieldDetailsList.error === true) {
        this.props.showSnackbar({
          snackbarOpen: true,
          snackbarType: Constants.SNACKBAR_TYPE.error,
          snackbarMessage: typeof(amountFieldDetailsList?.messages[0])!=='string'? amountFieldDetailsList?.messages[0]?.code+' - '+amountFieldDetailsList?.messages[0]?.detail:amountFieldDetailsList?.messages[0],
        });
       }
      }
    }else {
      if(amountFieldDetailsList.hasOwnProperty('status') && amountFieldDetailsList.status===202){
        this.setState({ showLoader: true });
            setTimeout(() => {
              this.props.getAmountFieldDetailsMatrixAPI(runID, distributorID, detailKey);
            }, 1000 * runsWaitTime);
            this.setState(prevState => {
              return {runsWaitTime: prevState.runsWaitTime * 2}
           })
     }
    }
    }
    if (nextProps.amountFieldDetailsError !== amountFieldDetailsError) {
      this.setState({ showLoaderResult: false });
      console.log("Error details : ",amountFieldDetailsError);
      if (amountFieldDetailsError.data.error === true) {
          this.props.showSnackbar({
            snackbarOpen: true,
            snackbarType: Constants?.SNACKBAR_TYPE?.error,
            snackbarMessage: typeof(amountFieldDetailsError?.data?.messages[0])!=='string'? amountFieldDetailsError?.data?.messages[0]?.code+' - '+amountFieldDetailsError?.data?.messages[0]?.detail:amountFieldDetailsError?.data?.messages[0],
          });
        }
          
    }
  }
  
  
  hideModal() {
    this.setState({ hideModal: true, showRemoveColModal: false });
    this.props.closefunction()
  }
  confirmModal() {
    this.setState({ showLoaderResult: true });
    this.hideModal();    
  }
  
  generateVolumeCSV = () =>{
    const { workFieldDetailsList } = this.state
    generateMatrixCSV(workFieldDetailsList, fields, "AmountFieldDetails")
  }
  render() {
    let loader;
    const {
      showRemoveColModal,      
      showLoaderResult,
      workFieldDetailsList,
    } = this.state;
    const { amountFieldName } = this.props;
    //const open = Boolean(anchorEl);
    //const id = open ? "simple-popover" : undefined;
    if (showLoaderResult) {
      loader = (
        <div
          id="center"
          style={{ position: "fixed", bottom: "40%", left: "48%" }}
        >
          <Oval
            type="Puff"
            position="fixed"
            secondaryColor="#E85557"
            color="#E85557"
            height={50}
            width={50}
            timeout={3000}
          />
        </div>
      );
    }
    return (
      <div>
        <Modal
          dialogClassName="workfield-my-modal"
          show={showRemoveColModal}
          enforceFocus={false}
        >
          <Modal.Header>
            <div className="modal-header-height">
              <div className="add-cmnr-header-name cmnr-h3-header-3-18pt-bold-lato">
              {amountFieldName} Details
              </div>
              <img
                className="addcmpr-vector-ei"
                src={vector13}
                onClick={this.hideModal.bind(this)}
                alt=""
              />
            </div>
          </Modal.Header>
          <Modal.Body>
          <div className="col-12 workfield-cm-table-style" >
             <TableContainer component={Paper} className= 'modal-height'>
             <Table stickyHeader sx={{ minWidth: 800 }} aria-label="simple table" className='general-table-left'>
                 <TableHead >
                     {fields.map((row) => (
                        <TableCell className={(row.displayName === "Name") || (row.displayName === "Dist ID")? "runmanager-table-header": "downline-table-header"} style= {{textAlign: (row.displayName === "Amount")? "right !important" : "left !important"}}  component="th" scope="row">
                             {row.displayName}
                         </TableCell>
                     ))}
                 </TableHead>
                 <TableBody>
                     {workFieldDetailsList?.length > 0 ? workFieldDetailsList?.map((workField,i) => (
                         <TableRow key={i}>
                             <TableCell className='data-text-style'  style={{width: '80px'}} align="center">{workField?.distributorId}</TableCell>
                             <TableCell className='data-text-style' align="left">{workField?.distributorName}</TableCell>
                             <TableCell className='data-text-style' align="right">{numberWithCommas((workField?.amount).toFixed(2))}</TableCell>
                             <TableCell className='data-text-style' align="right">{workField?.level}</TableCell>                            
                         </TableRow>
                     )) :
                         ''
                     }
                    
                 </TableBody>
             </Table>
         </TableContainer>
        </div>
          </Modal.Body>
          <Modal.Footer>
          <div className="add-run-type-btn  mr-auto"  style={{ color: '#D93030', cursor: "pointer", align:"left" }} onClick={this.generateVolumeCSV.bind(this)}><img
                src={exports}                
                alt=""
              /></div>
            <div className="row">
            
              <div style={{ backgroundColor: "#D93030" , cursor: "pointer", height: "30px", right: "0px", left: "50px",bottom: "0px" }} className=" col-6 cmnr-create-run" onClick={this.confirmModal.bind(this)}> 
               <div className="add-run-type-btn" style={{ color: 'white'}}>OK</div>              
              </div>
            </div>
          </Modal.Footer>
          <NewSnackbar />
          {loader}
        </Modal>
      </div>
    );
  }
}
AmountFieldMatrix.propTypes = {
  defaultvalues: PropTypes.any,
  addCompareBackfunc: PropTypes.func,
};
const mapStateToProps = (state) => {
  return {
    amountFieldDetailsList: state.distributorResearchReducer.amountFieldDetailsList,
    amountFieldDetailsError: state.distributorResearchReducer.amountFieldDetailsError,
  };
};
const mapDispatchToProps = dispatch => ({
    getAmountFieldDetailsMatrixAPI: (runID, distributorID, amountFieldID) => dispatch(getAmountFieldDetailsMatrixAPI(runID, distributorID, amountFieldID)),
  showSnackbar: (params) => dispatch({ type: actions.SNACKBAR_SHOW, payload: params}),
})
export default connect(mapStateToProps, mapDispatchToProps)(AmountFieldMatrix);