import React, { Fragment } from "react";
import AuthService from "../../server/AuthService";
import { connect } from "react-redux";
import { Oval } from "react-loader-spinner";
import { withRouter } from "react-router-dom";
import { getDistributorCountryInfo, getEarningsbyCountryAPI } from "../actions";
import actions from "../../../redux/actions/CommonDataActions";
import * as Constants from '../../../constants/Constants';
import { errorHandling } from '../../utilities/Services';
import NewSnackbar from "../../utilities/NewSnackbar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import './GeneralTab.css';
import './historyTab.css';
import './DateMiscTab.css'
import './AmountFields.css'
import '../commonComponents/Commoncss.css'
import './CountryInfo.css'
import { allNullTypeCheck, isNullResponse } from "../commonComponents/UtilityFun";
import { numberWithCommas } from "../../utilities/Services";
import nodatafound from "../assets/nodatafound.svg";

let initialValue = 0.0
class CountryInfoTab extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showLoaderResult: false,
            countryInfoData: [],
            selectedCountryInfo: [],
            selcetedcountryInfoData: [],
            selectedRow: false,
            totalConBonus: 0,
            totalBonus:0,
            countryInfoWaitTime: 1
        }
    }
    componentDidMount() {
        const { distID, selectedRunID } = this.props;
        // console.log("History tab input props ", distID, selectedRunID);
        if (distID !== null && distID !== undefined && distID !== '' && selectedRunID !== null && selectedRunID !== undefined && selectedRunID !== '') {
            this.setState({ showLoaderResult: true });
            this.props.getCountryInfoData(selectedRunID, distID);
        }
    }
    componentDidUpdate(nextProps) {
        const {
            distCountryInfoData,
            distCountryInfoError,
            earningsBySelectedCountry,
            earningsBySelectedCountryError,
            selectedRunID,
            distID
        } = this.props;
        const {countryInfoWaitTime, selectedCountryInfo } = this.state;
        //const {}
        if (distCountryInfoData !== nextProps.distCountryInfoData){
            this.setState({ showLoaderResult: false });
            if(!distCountryInfoData.hasOwnProperty('status')){
            if(isNullResponse(distCountryInfoData.data)) {
                if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Get distCountryInfo ", distCountryInfoData) }
                if (distCountryInfoData.error === false) {
                    console.log('distCountryInfoData Resposne', distCountryInfoData.data)

                    this.setState({ countryInfoData: distCountryInfoData.data.countries, selectedCountryInfo: distCountryInfoData.data.countries[0] });
                    let total = 0
                    if(distCountryInfoData.data.countries.length > 0) {
                        distCountryInfoData.data.countries.map(ele => {
                        total += ele?.convertedBonus
                    })
                    }
                    this.setState({totalConBonus: total })  
                    // console.log("country data inof", this.state.countryInfoData )
                    // this.props.getEarningsbyCountryAPI(selectedRunID, distID, distCountryInfoData.data.countries[0].id);                 
                }
                else {
                    this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.error, snackbarMessage: distCountryInfoData.messages[0] })
                }
            } else if (Object.keys(distCountryInfoData?.data).length === 0 && allNullTypeCheck(distCountryInfoData?.messages)) {
                if (distCountryInfoData?.messages?.length > 0 && distCountryInfoData?.messages[0]?.includes("Try again...")) {
                    console.log("Try again block entered : ", distCountryInfoData?.data);
                    this.setState({ showLoaderResult: true });
                    setTimeout(() => {
                        this.props.getCountryInfoData(selectedRunID, distID);
                    }, 1000 * countryInfoWaitTime);
                    this.setState(prevState => {
                        return { countryInfoWaitTime: prevState.countryInfoWaitTime * 2 }
                    })
                }
            }
             else {
                this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.error, snackbarMessage: distCountryInfoData.messages[0] })
            }
        }else {
            if(distCountryInfoData.hasOwnProperty('status') && distCountryInfoData.status===202){
                this.setState({ showLoaderResult: true });
                setTimeout(() => {
                    this.props.getCountryInfoData(selectedRunID, distID);
                }, 1000 * countryInfoWaitTime);
                this.setState(prevState => {
                    return { countryInfoWaitTime: prevState.countryInfoWaitTime * 2 }
                })
           }
          }   
        } else if (distCountryInfoError !== nextProps.distCountryInfoError) {
            this.setState({ showLoaderResult: false });
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("distCountryInfoError ", distCountryInfoError) }
            errorHandling(distCountryInfoError);
        }
        if (earningsBySelectedCountry !== nextProps.earningsBySelectedCountry){
            this.setState({ showLoaderResult: false });
            if(!earningsBySelectedCountry.hasOwnProperty('status')){
            if(isNullResponse(earningsBySelectedCountry.data)) {
                if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Get earningsBySelectedCountry ", earningsBySelectedCountry) }
                if (earningsBySelectedCountry.error === false) {
                     this.setState({ selcetedcountryInfoData: earningsBySelectedCountry.data.distributorEarnings }, ()=>{});
                     console.log('earningsBySelectedCountry Resposne', this.state.selcetedcountryInfoData, earningsBySelectedCountry.data.distributorEarnings)
                     let total = 0
                     if(earningsBySelectedCountry.data.distributorEarnings.length > 0) {
                        earningsBySelectedCountry.data.distributorEarnings.map(ele => {
                         total += ele?.bonus
                     })
                     }
                     this.setState({totalBonus: total })                   
                }
                else {
                    this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.error, snackbarMessage: earningsBySelectedCountry.messages[0] })
                }
            } else {
                this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.error, snackbarMessage: earningsBySelectedCountry.messages[0] })
            }
        }else {
            if(earningsBySelectedCountry.hasOwnProperty('status') && earningsBySelectedCountry.status===202){
                this.setState({ showLoaderResult: true });
                setTimeout(() => {
                    this.props.getEarningsbyCountryAPI(selectedRunID, distID, selectedCountryInfo.id); 
                }, 1000 * countryInfoWaitTime);
                this.setState(prevState => {
                    return { countryInfoWaitTime: prevState.countryInfoWaitTime * 2 }
                })
           }
          } 
        } else if (earningsBySelectedCountryError !== nextProps.earningsBySelectedCountryError) {
            this.setState({ showLoaderResult: false });
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("distCountryInfoError ", earningsBySelectedCountryError) }
            errorHandling(earningsBySelectedCountryError);
        }

    }
    
    selectCountryInfo(country, row) { 
        const {selectedRunID, distID } = this.props
        console.log("selected country", selectedRunID, distID, country ) 
        this.setState({ selectedRow: row, selectedCountryInfo: country})
        this.props.getEarningsbyCountryAPI(selectedRunID, distID, country.id);                  
    }
    render() {
        if (AuthService.isAuthenticated()) {
            let loader;
            const { showLoaderResult, countryInfoData, selectedCountryInfo, selectedRow, selcetedcountryInfoData} = this.state;
            
            if (showLoaderResult) {
                loader = <div id="center" style={{ position: "fixed", bottom: "40%", left: "48%" }}>
                    <Oval
                        type="Puff"
                        position="fixed"  secondaryColor="#E85557"
                        color="#E85557"
                        height={50}
                        width={50}
                        timeout={3000}
                    />
                </div>
            }
            return (
                
                <div className="header-line">
                    <div className="distres-generaltable">
                        {countryInfoData.length > 0 ?<Fragment>
                        <div className="col-6 distres-general-tbcontainer distres-tabs-scroll">
                            <TableContainer>
                                {countryInfoData.length > 0 ? <Table sx={{ minWidth: 650 }} aria-label="simple table" className="general-table-left">
                                    <TableHead className="distres-generaltab-header">
                                        <TableCell
                                            className="runmanager-table-header1"
                                            component="th"
                                            scope="row"
                                        >
                                            {'Mkt'}
                                        </TableCell>
                                        <TableCell
                                            className="runmanager-table-header1"
                                            component="th"
                                            scope="row"
                                        >
                                            {'Cou'}
                                        </TableCell>
                                        <TableCell
                                            className="distres-history-table-header1"
                                            component="th"
                                            scope="row"
                                            colSpan={1}
                                        >
                                            {'Bonus'}
                                        </TableCell>
                                        <TableCell
                                            className="distres-history-table-header1"
                                            component="th"
                                            scope="row"
                                            colSpan={1}
                                        >
                                            {'Conv.Bonus'}
                                        </TableCell>
                                        <TableCell
                                            className="distres-history-table-header1"
                                            component="th"
                                            scope="row"
                                            colSpan={1}
                                        >
                                            {'% of Total'}
                                        </TableCell>
                                    </TableHead>
                                    <TableBody className="distres-general-tbody">
                                        {countryInfoData.length > 0
                                            ? countryInfoData.map(
                                                (couInfo, i) => (                                                                                              
                                                    <TableRow hover selected={selectedRow === i} onClick={()=> this.selectCountryInfo(couInfo, i)} style={{cursor: 'pointer'}}>
                                                        <TableCell className="data-text-style">{couInfo?.market}</TableCell>
                                                        <TableCell className="data-text-style" >{couInfo?.id}</TableCell>
                                                        <TableCell className="data-text-style"align={'right'}>{numberWithCommas((couInfo?.bonus)?.toFixed(2))}</TableCell>
                                                        <TableCell className="data-text-style" align={'right'}>{numberWithCommas(couInfo?.convertedBonus?.toFixed(2))}</TableCell>
                                                        <TableCell className="data-text-style" align={'right'}>{(couInfo?.convertedBonus) !== 0 ? 
                                                        <span>{numberWithCommas(((couInfo?.convertedBonus/this.state.totalConBonus) * 100)?.toFixed(2)) +'%'}</span> : '0.00' }</TableCell>
                                                    </TableRow>
                                                )) : ''}
                                        {countryInfoData.length > 0 ? <TableRow>
                                            <TableCell className="data-text-style">Total</TableCell>
                                            <TableCell className="data-text-style" colSpan={3} align='right'>{numberWithCommas(countryInfoData.reduce(
                                                (previousValue, currentValue) =>
                                                    previousValue + currentValue.convertedBonus,
                                                initialValue
                                            )?.toFixed(2))}</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow> : ''}
                                    </TableBody>
                                </Table> : ''}
                            </TableContainer>
                        </div>
                        <div className="col-6 distres-datamisc-rightcontainer distres-tabs-scroll">
                           <div className="row"></div>
                           <TableContainer>
                                {countryInfoData.length > 0 ? <Table sx={{ minWidth: 650 }} style={{backgroundColor: '#F1F1F1'}} aria-label="simple table" className="general-table-right">
                                    <TableHead className="distres-generaltab-header">
                                    </TableHead>
                                    <TableBody className="distres-general-tbody">
                                                <TableRow>
                                                   <TableCell  className="data-text-style-couinfo">Begin Balance</TableCell>
                                                    <TableCell className="data-text-style-couinfo" align={'right'}>{selectedCountryInfo?.beginBalance?.toFixed(2)}</TableCell>
                                                    <TableCell className="data-text-style-couinfo"></TableCell>
                                                    <TableCell className="data-text-style-couinfo" align={'right'}></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                   <TableCell  className="data-text-style-couinfo">Period Balance</TableCell>
                                                    <TableCell className="data-text-style-couinfo" align={'right'}>{selectedCountryInfo?.periodBalance?.toFixed(2)}</TableCell>
                                                    <TableCell className="data-text-style-couinfo"></TableCell>
                                                    <TableCell className="data-text-style-couinfo" align={'right'}></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                   <TableCell  className="data-text-style-couinfo">Conversion Rate</TableCell>
                                                    <TableCell className="data-text-style-couinfo" align={'right'}>{selectedCountryInfo?.conversionRate?.toFixed(6)}</TableCell>
                                                    <TableCell className="data-text-style-couinfo"></TableCell>
                                                    <TableCell className="data-text-style-couinfo" align={'right'}></TableCell>
                                                </TableRow>
                                                {/* <TableRow>
                                                   <TableCell  className="data-text-style-couinfo">Reb-bv-cou</TableCell>
                                                    <TableCell className="data-text-style-couinfo" align={'right'}>{selectedCountryInfo?.convertedBonus ? selectedCountryInfo?.convertedBonus : 0.00}</TableCell>
                                                    <TableCell className="data-text-style-couinfo">Reb-adj</TableCell>
                                                    <TableCell className="data-text-style-couinfo" align={'right'}>0.00</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                   <TableCell  className="data-text-style-couinfo">Reb-cv-cou</TableCell>
                                                    <TableCell className="data-text-style-couinfo" align={'right'}>0.00</TableCell>
                                                    <TableCell className="data-text-style-couinfo">P1-cou</TableCell>
                                                    <TableCell className="data-text-style-couinfo" align={'right'}>0.00</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                   <TableCell  className="data-text-style-couinfo">Reb-fs-cou</TableCell>
                                                    <TableCell className="data-text-style-couinfo" align={'right'}>0.00</TableCell>
                                                    <TableCell className="data-text-style-couinfo">P1-as-cou</TableCell>
                                                    <TableCell className="data-text-style-couinfo" align={'right'}>0.00</TableCell>
                                                </TableRow> */}
                                    </TableBody>
                                </Table> : ''}
                            </TableContainer>
                            <TableContainer>                            
                                {selcetedcountryInfoData.length > 0 ? <Table sx={{ minWidth: 650 }}  aria-label="simple table" className="general-table-right">
                                    <TableHead className="distres-generaltab-header">
                                    <TableCell
                                            className="runmanager-table-header1"
                                            component="th"
                                            scope="row"
                                        >
                                            {'Description'}
                                        </TableCell>
                                        <TableCell
                                            className="distres-history-table-header1"
                                            component="th"
                                            scope="row"
                                            colSpan={1}
                                        >
                                            {'Volume'}
                                        </TableCell>
                                        <TableCell
                                            className="distres-history-table-header1"
                                            component="th"
                                            scope="row"
                                            colSpan={1}
                                        >
                                            {'Bonus'}
                                        </TableCell>
                                        <TableCell
                                            className="distres-history-table-header1"
                                            component="th"
                                            scope="row"
                                            colSpan={1}
                                        >
                                            {'% of Total'}
                                        </TableCell>
                                    </TableHead>                                
                                    
                                          <TableBody className="distres-general-tbody">
                                          {selcetedcountryInfoData.length > 0 ? selcetedcountryInfoData.map((coun) => (
                                            //    { console.log("selcetedcountryInfoData initial", selcetedcountryInfoData) 
                                            <TableRow >
                                               
                                            <TableCell  className="data-text-style">{coun.description}</TableCell>
                                            <TableCell className="data-text-style" align={'right'}>{numberWithCommas(coun?.volume?.toFixed(2))}</TableCell>
                                            <TableCell className="data-text-style" align={'right'}>{numberWithCommas(coun?.bonus?.toFixed(2))}</TableCell>
                                            <TableCell className="data-text-style" align={'right'}>{(coun?.bonus) !== 0 ? 
                                                        <span>{numberWithCommas(((coun?.bonus/this.state.totalBonus) * 100)?.toFixed(2)) +'%'}</span> : '0.00' }</TableCell>
                                        </TableRow>
                                          )) 
                                          : ''}
                                           
                                    </TableBody> 
                                </Table> : ''}
                            </TableContainer>
                           
                        </div>
                        </Fragment> : <TableContainer>
                        <Table>
                            <TableRow className="no-data-image header-line">
                                <TableCell colSpan={9} align='center' className="no-data-image">
                                    <img src={nodatafound} alt="" />
                                </TableCell>
                            </TableRow>
                        </Table> 
                        </TableContainer>}
                    </div>
                    {loader}
                    <NewSnackbar />
                </div>
            );
        }
        else {
            return <div id="center" style={{ position: "fixed", bottom: "40%", left: "48%" }}>
                <Oval
                    type="Puff"
                    position="fixed"  secondaryColor="#E85557"
                    color="#E85557"
                    height={50}
                    width={50}
                    timeout={3000}
                />
            </div>
        }
    }
}
const mapStateToProps = (state) => {
    return {
        distCountryInfoData: state.commissionDistResearch.distCountryInfoData,
        distCountryInfoError: state.commissionDistResearch.distCountryInfoError,
        earningsBySelectedCountry: state.distributorResearchReducer.earningsBySelectedCountry,
        earningsBySelectedCountryError: state.distributorResearchReducer.earningsBySelectedCountryError
    };
};
const mapDispatchToProps = (dispatch) => ({
    getCountryInfoData: (runID, distID) => dispatch(getDistributorCountryInfo(runID, distID)),
    getEarningsbyCountryAPI: (runId, distID, country) => dispatch(getEarningsbyCountryAPI(runId, distID, country)),
    showSnackbar: (params) => dispatch({ type: actions.SNACKBAR_SHOW, payload: params }),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CountryInfoTab));
