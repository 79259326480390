import React from "react";
// import addIcon from '../../assets/samplecme.svg';
import AuthService from '../../server/AuthService';
import { Oval } from "react-loader-spinner";
import { withRouter } from 'react-router-dom';
import './CommToolDistResearch.css'
import { connect } from "react-redux";
import { getCommRunDetails, getRankDistInfo } from "../../../components/Commissions/actions";
import ChangeRunModal from "./ChangeRunModal";
import actions from "../../../redux/actions/CommonDataActions";
import * as Constants from '../../../constants/Constants';
import * as Constant from '../commonComponents/Constants'
import { errorHandling, stringTruncate } from '../../utilities/Services';
import NewSnackbar from "../../utilities/NewSnackbar";
import { distStatusColor } from '../../utilities/Services'
import Moment from 'moment';
import changerunIcon from '../assets/changerunicon.svg'
import { allNullTypeCheck, isNullResponse } from "../commonComponents/UtilityFun";
import { Tooltip } from '@material-ui/core';


class DistInfo extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isdisplayChangeRuns: false,
            selectedValue: "Primary",
            commissionRunDetailsData: [],
            rankdistInfoData: [],
            showLoaderResult: false,
            distIDValue : '',
            retrydelayTime:1,
            runsWaitTime:1

        }
        this.changeRunClick = this.changeRunClick.bind(this);
    }
    componentDidMount() {
        if (this.props.runid !== null && this.props.runid !== undefined && this.props.runid !== '') {
            this.setState({ showLoaderResult: true })
            this.props.getcommissionRunDetails(this.props.runid)
            if (this.props.distid !== null && this.props.distid !== undefined && this.props.distid !== '') {
                this.props.getRankInfodistributor(this.props.runid, this.props.distid)
            }
        }
    }
    componentDidUpdate(nextProps) {
        const {
            commissionRunDetails,
            commissionRunDistInfo,
            commissionRunDistInfoError,
            commissionRunDetailsError,
            runid,
            distid
        } = this.props;
        const { retrydelayTime, runsWaitTime } = this.state;
        
        if (commissionRunDetails !== nextProps.commissionRunDetails){
            this.setState({showLoaderResult: false});
         if(!commissionRunDetails.hasOwnProperty('status')){ 
            if(isNullResponse(commissionRunDetails.data)) {
                this.setState({ commissionRunDetailsData: commissionRunDetails.data});
                this.setState({
                  selectedValue: {
                    title: commissionRunDetails?.data?.periodType,
                    defaultPeriod: commissionRunDetails?.data?.period,
                  },
                });
                console.log("Commission run details :", commissionRunDetails.data);
            }
         }else {
                if(commissionRunDetails.hasOwnProperty('status') && commissionRunDetails.status===202){ 
                    this.setState({ showLoaderResult: true });
                    setTimeout(() => {
                        this.props.getcommissionRunDetails(runid);
                    }, 1000 * runsWaitTime);
                    this.setState(prevState => {
                    return {runsWaitTime: prevState.runsWaitTime * 2}
                })
            }
         }
        }
        else if (commissionRunDetailsError !== nextProps.commissionRunDetailsError) {
            this.setState({ showLoaderResult: false });
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("commissionRunDetailsError ", commissionRunDetailsError?.data?.error) }
          
        }
        if (commissionRunDistInfo !== nextProps.commissionRunDistInfo){
            if(!commissionRunDistInfo.hasOwnProperty('status')){
            if(isNullResponse(commissionRunDistInfo.data)) {
                if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Get commissionRunDistInfo ", commissionRunDistInfo) }
                if (commissionRunDistInfo.error === false) {
                    this.setState({ showLoaderResult: false });
                    this.setState({ rankdistInfoData: commissionRunDistInfo?.data, distIDValue: commissionRunDistInfo.data?.distributorId});
                    console.log('commissionRunDistInfo Resposne', commissionRunDistInfo.data)
                }
                else {
                    this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.error, snackbarMessage: commissionRunDistInfo.messages[0] })
                }
            } else {
                this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.error, snackbarMessage: commissionRunDistInfo.messages[0] })
            }
        }else {
            if(commissionRunDistInfo.hasOwnProperty('status') && commissionRunDistInfo.status===202){
              setTimeout(()=>{
                this.props.getRankInfodistributor(runid, distid)
              },retrydelayTime*1000)
              this.setState(prevState => {
                return {retrydelayTime: prevState.retrydelayTime * 2}
             })
           }
          }
        } else if ((commissionRunDistInfoError !== nextProps.commissionRunDistInfoError)) {
            this.setState({ showLoaderResult: false });
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("commissionRunDistInfoError ", commissionRunDistInfoError) }
            errorHandling(commissionRunDistInfoError);
        }
    }
    selectedRunHandler(commissionrunId) {
        // this.props.getcommissionRunDetails(commissionrunId);
        if (allNullTypeCheck(commissionrunId)) {
            const { commissionRunDetailsData, distIDValue } = this.state
            this.props.history.push({
                pathname: '/commissiontools/distributorresearch/1',
                state: {
                    distID: distIDValue,
                    periodtype: commissionRunDetailsData?.periodType,
                    periodDate: commissionRunDetailsData?.period,
                    selectedRun: commissionrunId
                },
            });
            window.location.reload(false)
        }
    }
    changeRunClick() {
        const {distIDValue} = this.state
        console.log('dist is',distIDValue)
        if(distIDValue !== ''){
            this.setState({
                isdisplayChangeRuns: !this.state.isdisplayChangeRuns,
            });
        }
        else{
            this.props.showSnackbar({snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.warning, snackbarMessage:'dist Id should not be empty'});
        }
      
    }
    handleDistInput= (e) => {
        if(allNullTypeCheck(e.target.value)){
            this.setState({
                distIDValue:  e.target.value,
            });
        }
        else{
            this.setState({
                distIDValue:  ''
            });
        }
    }
    onendInputValue = (e) => {
        if (allNullTypeCheck(e.target.value)) {
            if (e.key === 'Enter') {
                //  console.log('ended',val.target.value)
                const { commissionRunDetailsData } = this.state
                this.props.history.push({
                    pathname: '/commissiontools/distributorresearch/1',
                    state: {
                        distID: e.target.value,
                        periodtype: commissionRunDetailsData?.periodType,
                        periodDate: commissionRunDetailsData?.period,
                        selectedRun: commissionRunDetailsData?.runId
                    },
                });
                window.location.reload(false)
            }
        }
    }
    render() {
        const { isdisplayChangeRuns, selectedValue, commissionRunDetailsData, rankdistInfoData, showLoaderResult, distIDValue } = this.state
        if (AuthService.isAuthenticated()) {
            let loader;
            if (showLoaderResult === true) {
                loader = <div><div id="center" style={{ position: 'fixed', top: '40%', left: '6%' }}>
                    <Oval type="Puff" position="fixed"  secondaryColor="#E85557" color="#E85557" height={50} width={100} visible={true} /> </div></div>;
            }
            return (
                <div>
                    <div className="distributor-display">
                        <div className="row">
                            <div className="col distcard-align paragraph-2--12pt-regular-lato">Distributor ID</div>
                            <div className="col distcard-align paragraph-2--12pt-regular-lato">Status</div>
                        </div>
                        <div className="row">
                            <div className="col distcard-align"><input type='text' style={{ height: '32px' }} className="form-control paragraph-1--14pt-bold-lato" id="exampleFormControlInput1"  
                            placeholder="Dist ID" value={distIDValue} onChange={this.handleDistInput.bind(this)} onKeyPress={this.onendInputValue.bind(this)}/></div>
                            <div className="col distcard-align"><span className="badge dist-status-flag" style={{ backgroundColor: distStatusColor(rankdistInfoData?.statusCode) }}>
                                <div className="dist-status-text paragraph-1--14pt-bold-lato">{rankdistInfoData?.statusCode}</div></span>
                                {/* here://status now changed to statusCode */}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col distcard-align paragraph-2--12pt-regular-lato">Name</div>
                        </div>
                        <div className="row">
                            <div className="col distcard-align">  <input type="text" style={{ width: '95%', height: '32px' }} className="form-control paragraph-1--14pt-bold-lato" id="exampleFormControlInput1" placeholder="Dist Name" value={rankdistInfoData?.name} readonly /></div>
                        </div>
                        <div className="row">
                            <div className="col distcard-align paragraph-2--12pt-regular-lato" style={{paddingRight: 0}}>Entry Date</div>
                            <div className="col distcard-align paragraph-2--12pt-regular-lato country-code-text">Country (Primary)</div>
                        </div>
                        <div className="row">
                            <div className="col distcard-align paragraph-1--14pt-bold-lato">{Moment(rankdistInfoData?.entryDate).format('MM/DD/YYYY')}</div>
                            <div className="col distcard-align paragraph-1--14pt-bold-lato">{ rankdistInfoData?.countryCode ? rankdistInfoData?.countryCode + '('+ rankdistInfoData?.primaryCountryCode +')': ''}</div>
                        </div>
                        <div className="row">
                            <div className="col"><hr /></div>
                        </div>
                        <div className="row">
                            <div className="col paragraph-2--12pt-regular-lato" style={{ marginLeft: '10px' }}>Run Name</div>
                            <div className="col changerun-btn paragraph-2--12pt-bold-lato" onClick={this.changeRunClick.bind(this)}>
                                <img className="distinfo-changerun" src={changerunIcon} alt='alt' /> <span>CHANGE RUN</span></div>
                        </div>
                        <div className="row">
                        {commissionRunDetailsData?.name?.length > 20?
                        <Tooltip title= {commissionRunDetailsData?.name} placement="top" arrow={true}> 
                            <div className="col distcard-align paragraph-1--14pt-bold-lato">{stringTruncate(commissionRunDetailsData?.name,20)}</div>
                            </Tooltip>: <div className="col distcard-align paragraph-1--14pt-bold-lato">{commissionRunDetailsData?.name}</div>}
                        </div>
                        <div className="row" style={{ marginTop: '10px' }}>
                            <div className="col paragraph-2--12pt-regular-lato" style={{  marginLeft: '10px'}}>Description</div>
                        </div>
                        <div className="row">
                        {commissionRunDetailsData?.description?.length > 20?
                        <Tooltip title= {commissionRunDetailsData?.description} placement="top" arrow={true}> 
                            <div className="col paragraph-1--14pt-bold-lato" style={{ marginLeft: '10px', marginTop: '10px'  }}>{stringTruncate(commissionRunDetailsData?.description,20)}</div>
                            </Tooltip>: <div className="col paragraph-1--14pt-bold-lato" style={{ marginLeft: '10px', marginTop: '10px'  }}>{commissionRunDetailsData?.description}</div>}
                        </div>

                        <div className="row" style={{ marginTop: '15px' }}>
                            <div className="col  paragraph-2--12pt-regular-lato" style={{ marginLeft: '10px' }}>Period</div>
                            <div className="col  paragraph-2--12pt-regular-lato" style={{ marginRight: '5px' }}>Period Type</div>
                        </div>
                        <div className="row">
                            <div className="col distcard-align paragraph-1--14pt-bold-lato">{commissionRunDetailsData?.period}</div>
                            <div className="col  paragraph-1--14pt-bold-lato" style={{ marginTop: '10px', marginRight: '5px' }}>{commissionRunDetailsData?.periodType}</div>
                        </div>

                        <div className="row" style={{ marginTop: '15px' }}>
                            <div className="col  paragraph-2--12pt-regular-lato" style={{ marginLeft: '10px' }}>Record No.</div>
                            <div className="col  paragraph-2--12pt-regular-lato" style={{ marginRight: '5px' }}>Run Type</div>
                        </div>
                        <div className="row">
                            <div className="col distcard-align paragraph-1--14pt-bold-lato">{commissionRunDetailsData?.recordNumber}</div>
                            <div className="col distcard-align" style={{ marginRight: '15px' }}>
                            <span style={{ textTransform: 'uppercase', color: 'white', backgroundColor: commissionRunDetailsData?.runType === Constant.ANALYSIS ? '#5D5FEF' :'#F2994A' }} className="badge rounded-pill runtype-status">{commissionRunDetailsData?.runType}</span></div>
                        </div>
                    </div>
                    {isdisplayChangeRuns ? (
                        <ChangeRunModal
                            changeRunsBackfunc={this.changeRunClick.bind(this)}
                            commissionTypeValue={selectedValue}
                            selectedRun={this.selectedRunHandler.bind(this)}
                        />
                    ) : (
                        ""
                    )}
                    <NewSnackbar />
                    {loader}
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        commissionRunDetails: state.commissionRunReducer.commissionRunDetails,
        commissionRunDetailsError : state.commissionRunReducer.commissionRunDetailsError,
        commissionRunDistInfo: state.commissionDistResearch.commissionRunDistInfo,
        commissionRunDistInfoError: state.commissionDistResearch.commissionRunDistInfoError,
    };
};
const mapDispatchToProps = (dispatch) => ({
    getcommissionRunDetails: (runID) => dispatch(getCommRunDetails(runID)),
    getRankInfodistributor: (runID, distID) => dispatch(getRankDistInfo(runID, distID)),
    showSnackbar: (params) => dispatch({ type: actions.SNACKBAR_SHOW, payload: params }),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DistInfo));