import React from "react";
import "./PrimaryMediumDefault.css";
import IntegrationName from '../IntegrationName/index';
import CompleteIntegration from '../CompleteIntegration/index';

class PrimaryMediumDefault extends React.Component {
  constructor(props){
    super(props)
    this.integrationName = this.integrationName.bind(this)
    this.state = {
      showIntegrationModal: this.props.val,     
    }
  }
  integrationName() {
    console.log("props",this.props.val, this.state.showIntegrationModal)
    if(this.state.showIntegrationModal != true){
    
    this.setState({showIntegrationModal: true,
      })
    }
   //  this.integrationCallBack= 
  }

  render() {
    const { children } = this.props;
    
    return (
      <div className="primary-medium-default">
        <div className="button-i527103263 valign-text-middle lato-bold-white-16px" style={{color: "white"}} onClick={this.integrationName}>{children}</div>
        {this.state.showIntegrationModal? <IntegrationName val = {this.state.showIntegrationModal} ></IntegrationName>: null}
        {/* {this.state.showCompleteIntegration? <CompleteIntegration value  = {this.state.showCompleteIntegration}></CompleteIntegration>:null } */}
      </div>
    );
  }
}

export default PrimaryMediumDefault;
