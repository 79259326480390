import React, { useEffect,useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Oval } from "react-loader-spinner";
import './GeneralTab.css';
import {useSelector, useDispatch} from 'react-redux'
import { getDownlineDistRankInfoAPI, getDownlineDistVolumes, DISTRIBUTOR_DOWNLINE_ERROR,
     DISTRIBUTOR_DOWNLINE_SUCCESS, DOWNLINE_DIST_RANKINFO_SUCCESS, DIST_UPLINE_DROP_SUCCESS, DIST_UPLINE_DROP_ERROR, getDistributorDownline, getAllRanks, DOWNLINE_DIST_VOLUME_SUCCESS } from "../../../components/Commissions/actions";
import AuthService from '../../server/AuthService';
import { singletonAxiosInstance } from '../../utilities/Server';
import Endpoints from '../../utilities/Endpoints';
import externalLink from '../assets/externalLink.svg'
import { isNullResponse, getCleintNameEnvironment, allNullTypeCheck } from "../commonComponents/UtilityFun";
import { numberWithCommas, getRankName } from "../../utilities/Services";
import { Tooltip } from '@material-ui/core';
import * as Constant from '../../../constants/Constants';
import actions from "../../../redux/actions/CommonDataActions";

const server = singletonAxiosInstance;
const options = {
 Authorization: 'Bearer ' + localStorage.getItem('id_token')
 //Authorization: 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjU4MjQyMTVEODhGQkE4RTEzQkZGN0FFNTk0MzAyODM5RkU1M0ZGOENSUzI1NiIsInR5cCI6ImF0K2p3dCIsIng1dCI6IldDUWhYWWo3cU9FN18zcmxsREFvT2Y1VF80dyJ9.eyJuYmYiOjE2MzM0OTA0MzksImV4cCI6MTY2NTAyNjQzOSwiaXNzIjoiaHR0cHM6Ly9kZXYtYXRvbWljLmZsZXhjbG91ZC5jb20vaWRlbnRpdHkiLCJjbGllbnRfaWQiOiIwZDkzNGI5MS03NjA3LTQ3OWUtYjMyOS0xOTAyNjhiZmRjZDIiLCJjbGllbnRfbmFtZSI6ImRldi1hdG9taWMiLCJqdGkiOiIxNTk0MTA3RDFGRjM0RTMyNDcwMTZGRjE4Q0Q2QjdERCIsImlhdCI6MTYzMzQ5MDQzOSwic2NvcGUiOlsiL2NvbW1pc3Npb25zIl19.VOq7hVNtlqz3yhuag0yYrqMXefpTfI3upweQy2DUt9mO6Wd6iHhd8S3JCamTCYY-Fr8rlxIPEXNb8Rx7fg1e4LCrcXVQoOLVg65QBL3r2xuMQE7PQ_c4MF9C61xxQWrFAC2jjvNWztrb5pcv9B73XVv1MCJVajxnYeyrKVdxP3VK8R9L_OHqEzpXzKfz6PW0BiXgvPIl92NtHDE1mvC6YXXXcVwHuTAFBu8tZdvskr_wm0iNaY7niY8QzKXvY5DkWFdknaSeGP9HJwukdGzdY-ObHg5JDl2Swb0ienhOqQ4mKrIA1_kU9tcfk6k4dAPpYkvwcwPB-WQfNECuB1qxJw'
};
const statCodes = {
  SUCCESS: 200,
  CREATE_SUCCESS: 201,
  unAuthorized: 403
};

const DownlineTab =(props)=>{
  const [loader, setLoader] = useState(true);
  const [downlineLoading, setDownlineLoading] = useState(false);
  const [distDownlineListData, setDistDownlineListData ] = useState({});
  const [retrydelayTime, setRetrydelayTime] = useState(1);
  const [retrydelayTime1, setRetrydelayTime1] = useState(1);
  const [retrydelayTime2, setRetrydelayTime2] = useState(1);
  const [retrydelayTime3, setRetrydelayTime3] = useState(1);
  const [distRankInfoList, setDistRankInfoList] = useState({});
  const [distVolumeList, setDistVolumeList] = useState({});
  const distDownlineList = useSelector(state => state.distributorResearchReducer.distDownlineList);
  const distdownlineError = useSelector(state => state.distributorResearchReducer.distDownlineError)
  const downlineDistRankInfo = useSelector(state => state.distributorResearchReducer.downlineDistRankInfo);
  const distRankInfoError = useSelector(state => state.distributorResearchReducer.downlineDistRankInfoError);
  const downlineDistVolumeList = useSelector(state => state.distributorResearchReducer.downlineDistVolumeList);
  const distVoluemError = useSelector(state => state.distributorResearchReducer.downlineDistVolumeError);
  const distUplineDropdownList = useSelector(state => state.distributorResearchReducer.distUplineDropdownList);
  const [displayRightContent, setDisplayRightContent] = useState(false);
  
  
  const [selectedRow, setselectedRow] = useState('')
  const envName = getCleintNameEnvironment();
  const [ranksData, setRanksData] = useState([]);
  // const ranksData 
  console.log("env result: ",envName);
  const [detailsTitle, setDetailTitle] = useState('');
  let initialValue = 0,downlineSpinner='';

    const { distID,selectedRunID, periodDate, periodtype } = props;
    const dispatch = useDispatch()
    console.log("downline restult table data :",distID,selectedRunID);
    console.log("downline restult :",distDownlineListData)
    console.log("downline rank  restult :",distRankInfoList);
    console.log("downline Volume  restult :",distVolumeList);
    

    // const getDistributorDownline = (distID,runID) => {
    //     return dispatch => {
    //       if (AuthService.isTokenValid() === true) {
    //         server.get(Endpoints.CommissionRuns+'/'+runID+"/distributors/"+distID+"/downline", '', options).then((data) => {
    //           setLoader(false);
    //           if (data.status === statCodes.SUCCESS) {
    //             if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRun Details res :', data); }
    //             dispatch({ type: DISTRIBUTOR_DOWNLINE_SUCCESS, payload: data.data });
    //           } else {
    //             dispatch({ type: DISTRIBUTOR_DOWNLINE_ERROR, payload: data });
    //           }
    //         }).catch((error) => {
    //             setLoader(false);
    //           if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
    //           if (!error.response) {
    //             dispatch({ type: DISTRIBUTOR_DOWNLINE_ERROR, payload: error });
    //           } else {
    //             dispatch({ type: DISTRIBUTOR_DOWNLINE_ERROR, payload: error.response });
    //           }
      
    //         });
    //       } else {
    //         const user = AuthService.signinSilentToken();
    //       }
    //     }
    // }
  //   const getAllRAnksList = () => {
  //     return dispatch => {
  //       if (AuthService.isTokenValid() === true) {
  //         server.get(Endpoints.CommissionTypeSelectionURL1+'/ranks', '', options).then((data) => {
  //           // setLoader(false);
  //           if (data.status === statCodes.SUCCESS) {
  //             if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('CommissionRun Details res :', data); }
  //             dispatch({ type: DIST_UPLINE_DROP_SUCCESS, payload: data.data });
  //             console.log("result: desc : ",data?.data?.data?.ranks);
  //             setRanksData(data?.data?.data?.ranks)
  //             //setSelectedValue(data?.data?.data?.ranks);
  //            // dispatch(getDistributorUpline(distID, selectedRunID, data?.data?.data?.ranks[0]?.id));
  //           } else {
  //             dispatch({ type: DIST_UPLINE_DROP_ERROR, payload: data });
  //           }
  //         }).catch((error) => {
  //           if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('Catch Error : ', error); }
  //           if (!error.response) {
  //             dispatch({ type: DIST_UPLINE_DROP_ERROR, payload: error });
  //           } else {
  //             dispatch({ type: DIST_UPLINE_DROP_ERROR, payload: error.response });
  //           }
    
  //         });
  //       } else {
  //         const user = AuthService.signinSilentToken();
  //       }
  //     }
  // }

  useEffect(() => {
    if(isNullResponse(distDownlineList)&&!distDownlineList.hasOwnProperty('status')){ 
    if (isNullResponse(distDownlineList.data) && distDownlineList?.error===false){
      console.log("got the Response: ",distDownlineList);
      // dispatch({ type: DOWNLINE_DIST_RANKINFO_SUCCESS, payload: {} });
      // dispatch({ type: DOWNLINE_DIST_VOLUME_SUCCESS, payload: {} });
      setDistDownlineListData(distDownlineList)
      setLoader(false);
    }else {
      if(allNullTypeCheck(distDownlineList?.data) && Object.keys(distDownlineList?.data).length===0 && allNullTypeCheck(distDownlineList?.messages)) {
        if(distDownlineList?.messages?.length > 0 && distDownlineList?.messages[0]?.includes("Try again")){
          console.log("Try again block entered : ",distDownlineList?.data);
          setTimeout(()=>{
            dispatch(getDistributorDownline(distID,selectedRunID));
          },retrydelayTime*1000)
          setRetrydelayTime(retrydelayTime*2);
      }
    }
    setLoader(false);
  }
  }else {
    if(distDownlineList.hasOwnProperty('status') && distDownlineList.status===202){
      setLoader(true);
      setTimeout(()=>{
        dispatch(getDistributorDownline(distID,selectedRunID));
      },retrydelayTime*1000)
      setRetrydelayTime(retrydelayTime*2);
    }
  }
  if(isNullResponse(distdownlineError)&& distdownlineError?.data?.error===true){
    console.log("General rank info failed :", distdownlineError);
    setLoader(false);
    if(distdownlineError?.data?.error === true){
      dispatch({ type: actions.SNACKBAR_SHOW, payload: {snackbarOpen: true, snackbarType:  Constant.SNACKBAR_TYPE.error, snackbarMessage: typeof(distdownlineError?.data?.messages[0])!=='string'? distdownlineError?.data?.messages[0]?.code+' - '+distdownlineError?.data?.messages[0]?.detail:distdownlineError?.data?.messages[0]}})
    }else if(distdownlineError?.message) {
      dispatch({ type: actions.SNACKBAR_SHOW, payload: {snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: distdownlineError?.message }});
    }
  }
  }, [distDownlineList,distdownlineError]);

  useEffect(() => {
    if(isNullResponse(downlineDistRankInfo)&&!downlineDistRankInfo.hasOwnProperty('status')){ 
    if (isNullResponse(downlineDistRankInfo.data) && downlineDistRankInfo?.error===false){
      console.log("got the Response: ",downlineDistRankInfo);
      setDistRankInfoList(downlineDistRankInfo)
      setLoader(false);
      setDownlineLoading(false);
    }else {
      if( allNullTypeCheck(downlineDistRankInfo?.data) && Object.keys(downlineDistRankInfo?.data).length===0 && allNullTypeCheck(downlineDistRankInfo?.messages)) {
        if(downlineDistRankInfo?.messages?.length > 0 && downlineDistRankInfo?.messages[0]?.includes("Try again")){
          setDownlineLoading(true);
          setTimeout(()=>{
            dispatch(getDownlineDistRankInfoAPI(selectedRow,selectedRunID));
          },retrydelayTime1*1000)
          setRetrydelayTime1(retrydelayTime1*2);
      }
    }
    setLoader(false);
    setDownlineLoading(false);
  }
  }else {
      if(downlineDistRankInfo.hasOwnProperty('status') && downlineDistRankInfo.status===202){
        setDownlineLoading(true);
        setTimeout(()=>{
          dispatch(getDownlineDistRankInfoAPI(selectedRow,selectedRunID));
        },retrydelayTime1*1000)
        setRetrydelayTime1(retrydelayTime1*2);
    }
  }
  if(isNullResponse(distRankInfoError)&& distRankInfoError?.data?.error===true){
    console.log("General rank info failed :", distRankInfoError);
    setLoader(false);
    setDownlineLoading(false);
    if(distRankInfoError?.data?.error === true){
      dispatch({ type: actions.SNACKBAR_SHOW, payload: {snackbarOpen: true, snackbarType:  Constant.SNACKBAR_TYPE.error, snackbarMessage: typeof(distRankInfoError?.data?.messages[0])!=='string'? distRankInfoError?.data?.messages[0]?.code+' - '+distRankInfoError?.data?.messages[0]?.detail:distRankInfoError?.data?.messages[0]}})
    }else if(distRankInfoError?.message) {
      dispatch({ type: actions.SNACKBAR_SHOW, payload: {snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: distRankInfoError?.message }});
    }
  }
  }, [downlineDistRankInfo,distRankInfoError]);
  
  useEffect(() => {
    if(isNullResponse(distUplineDropdownList)&&!distUplineDropdownList.hasOwnProperty('status')){ 
    if (isNullResponse(distUplineDropdownList.data) && distUplineDropdownList?.error===false){
      console.log("got the Response: ",distUplineDropdownList);
      setRanksData(distUplineDropdownList.data?.ranks);
      setLoader(false);
    }else {
      if( allNullTypeCheck(distUplineDropdownList?.data) && Object.keys(distUplineDropdownList?.data).length===0 && allNullTypeCheck(distUplineDropdownList?.messages)) {
        if(distUplineDropdownList?.messages?.length > 0 && distUplineDropdownList?.messages[0]?.includes("Try again")){
          console.log("Try again block entered : ",distUplineDropdownList?.data);
          setTimeout(()=>{
            dispatch(getAllRanks());
          },retrydelayTime2*1000)
          setRetrydelayTime2(retrydelayTime2*2);
      }
    }
    setLoader(false);
  }
  }else {    
    if(distUplineDropdownList.hasOwnProperty('status') && distUplineDropdownList.status===202){
      setLoader(true);
      setTimeout(()=>{
        dispatch(getAllRanks());
      },retrydelayTime2*1000)
      setRetrydelayTime2(retrydelayTime2*2);
  }
  }
  if(isNullResponse(distRankInfoError)&& distRankInfoError?.data?.error===true){
    console.log("General rank info failed :", distRankInfoError);
    setLoader(false);
    if(distRankInfoError?.data?.error === true){
      dispatch({ type: actions.SNACKBAR_SHOW, payload: {snackbarOpen: true, snackbarType:  Constant.SNACKBAR_TYPE.error, snackbarMessage: typeof(distRankInfoError?.data?.messages[0])!=='string'? distRankInfoError?.data?.messages[0]?.code+' - '+distRankInfoError?.data?.messages[0]?.detail:distRankInfoError?.data?.messages[0]}})
    }else if(distRankInfoError?.message) {
      dispatch({ type: actions.SNACKBAR_SHOW, payload: {snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: distRankInfoError?.message }});
    }
  }
  }, [distUplineDropdownList,distRankInfoError]);

  useEffect(() => {
    if(isNullResponse(downlineDistVolumeList)&&!downlineDistVolumeList.hasOwnProperty('status')){ 
    if (isNullResponse(downlineDistVolumeList.data) && downlineDistVolumeList?.error===false){
      console.log("got the Response: ",downlineDistVolumeList);
      setDistVolumeList(downlineDistVolumeList)
      setLoader(false);
      setDownlineLoading(false);
    }else {
      if( allNullTypeCheck(downlineDistVolumeList?.data) && Object.keys(downlineDistVolumeList?.data).length===0 && allNullTypeCheck(downlineDistVolumeList?.messages)) {
        if(downlineDistVolumeList?.messages?.length > 0 && downlineDistVolumeList?.messages[0]?.includes("Try again")){
          console.log("Try again block entered : ",downlineDistVolumeList?.data);
          setDownlineLoading(true);
          setTimeout(()=>{
            dispatch(getDownlineDistVolumes(selectedRow, selectedRunID));
          },retrydelayTime3*1000)
          setRetrydelayTime3(retrydelayTime3*2);
      }
    }
    setLoader(false);
    setDownlineLoading(false);
  }
  }else {
    if(downlineDistVolumeList.hasOwnProperty('status') && downlineDistVolumeList.status===202){
      setDownlineLoading(true);
      setTimeout(()=>{
        dispatch(getDownlineDistVolumes(selectedRow, selectedRunID));
      },retrydelayTime3*1000)
      setRetrydelayTime3(retrydelayTime3*2);
  }
  }
  if(isNullResponse(distVoluemError)&& distVoluemError?.data?.error===true){
    console.log("General rank info failed :", distVoluemError);
    setLoader(false);
    setDownlineLoading(false);
    if(distVoluemError?.data?.error === true){
      dispatch({ type: actions.SNACKBAR_SHOW, payload: {snackbarOpen: true, snackbarType:  Constant.SNACKBAR_TYPE.error, snackbarMessage: typeof(distVoluemError?.data?.messages[0])!=='string'? distVoluemError?.data?.messages[0]?.code+' - '+distVoluemError?.data?.messages[0]?.detail:distVoluemError?.data?.messages[0]}})
    }else if(distVoluemError?.message) {
      dispatch({ type: actions.SNACKBAR_SHOW, payload: {snackbarOpen: true, snackbarType: Constant.SNACKBAR_TYPE.error, snackbarMessage: distVoluemError?.message }});
    }
  }
  }, [downlineDistVolumeList,distVoluemError]);

    useEffect(() => {
        setDetailTitle('');
        dispatch({ type: DOWNLINE_DIST_RANKINFO_SUCCESS, payload: {} });
        dispatch(getDistributorDownline(distID,selectedRunID));
        dispatch(getAllRanks());
        return ()=>{
          dispatch({ type: DOWNLINE_DIST_RANKINFO_SUCCESS, payload: {} });
        }
      }, []);
   const getRowDownlineDetails=(data)=>{
        setDetailTitle(data.description);
        setselectedRow(data.distributorId);
        setDisplayRightContent(true)
        setDownlineLoading(true);
        dispatch({ type: DOWNLINE_DIST_RANKINFO_SUCCESS, payload: {} })
        dispatch(getDownlineDistRankInfoAPI(data.distributorId,selectedRunID));
        dispatch(getDownlineDistVolumes(data.distributorId,selectedRunID));
    };
    const handleTreeView=(distributorId)=>{
      localStorage.setItem(envName + "-distID", JSON.stringify(distributorId));
      localStorage.setItem(
        envName + "-selectedRun",
        JSON.stringify(selectedRunID)
      );
      localStorage.setItem(envName + "-periodtype", JSON.stringify(periodtype));
      localStorage.setItem(envName + "-periodDate", JSON.stringify(periodDate));
      // window.open(URL, "/commissiontools/distributorresearch/1");
      window.open(
        "/commissions/commissiontools/distributorresearch/1",
        distributorId,
        ""
      );
    }
    const getDistributorSpecificVolume=(distributor, volField)=> {
        return distributor.volumes.find(v=>v.field==volField);
    }   
if(loader){   
      return <div><div id="center" style={{ position: 'fixed', bottom: '40%', left: '48%' }}>
      <Oval type="Puff" position="fixed"  secondaryColor="#E85557" color="#E85557" height={50} width={100} visible={true} /> </div></div>;
}else{ 
  if(downlineLoading){
    downlineSpinner = (<div><div id="center" style={{ position: 'absolute', bottom: '50%', left:'20%', justifyContent:'center', textAlign:'center' }}>
    <Oval type="Puff" position="fixed"  secondaryColor="#E85557" color="#E85557" height={50} width={100} visible={true} /> </div></div>);
  }
return (
<div className="distres-generaltable">
<div className="col-10 distres-Earning-tbcontainer distres-tabs-scroll">

<TableContainer>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
                        <TableCell
                            className="downline-table-header"
                            component="th"
                            scope="row" 
                        >
                            {"ID"}
                        </TableCell>
                        <TableCell
                            className="runmanager-table-header"
                            component="th"
                            scope="row"
                        >
                            {"Name"}
                        </TableCell>
                        {(isNullResponse(distDownlineListData?.data)&&distDownlineListData?.data?.volumeHeader?.length>0)?
                        distDownlineListData?.data?.volumeHeader?.map((data)=>(
                        <TableCell
                            className="downline-table-header"
                            component="th"
                            scope="row"
                        >
                            {data?.label}
                        </TableCell>)):''}
					</TableHead>
                    <TableBody>
                    {(isNullResponse(distDownlineListData?.data)&&distDownlineListData?.data?.downline?.length>0)? distDownlineListData?.data?.downline?.map((data)=>{
                        return(<TableRow hover selected={data.distributorId===selectedRow} onClick={()=>getRowDownlineDetails(data)} style={{cursor: 'pointer'}}>
                            <TableCell className="table-cell-data-right-alignment">{data?.distributorId}</TableCell>
                            <TableCell>{data?.name}</TableCell>
                            {distDownlineListData?.data?.volumeHeader.length>0?
                            distDownlineListData?.data?.volumeHeader?.map((headers)=>(
                                <TableCell className="table-cell-data-right-alignment">{numberWithCommas((getDistributorSpecificVolume(data,headers?.field)?.value)?.toFixed(2))}</TableCell>
                            )):''}                            
                        </TableRow>
                        );
                    }):''} 
                    </TableBody>
				</Table>
			</TableContainer>

</div>
<div className="col-2 distres-downline-rightcontainer">
<TableContainer  style={{maxHeight: '352px'}}>
      {downlineLoading? <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100vh',
                  }}>{ downlineSpinner }</div>:
    <>
    {(displayRightContent&&isNullResponse(distRankInfoList?.data)&&distRankInfoList?.data)? <Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
                        <TableRow>
                        <TableCell colSpan={2}>
                            <div className="downline_selected">
                                <div className="col-4 remove_cols-padding">{distRankInfoList?.data?.distributorId}</div>
                                <div className="col-8 remove_cols-padding treeview-display" onClick={()=>handleTreeView(distRankInfoList?.data?.distributorId)}><img src={externalLink} alt=""/><span className="treeview-selection">{"GO TO"}</span></div>
                            </div>
                            <div>{distRankInfoList?.data?.name}</div>
                            </TableCell>
                        </TableRow>
					</TableHead>
                    <TableBody className="distres-downline-tbody">                    
                        <TableRow>
                            <TableCell><span className="downline_rank_info">{"Country"}</span></TableCell>
                            <TableCell align={"right"} className="updowntable-cell-data-right-alignment">{ distRankInfoList?.data?.countryCode ? distRankInfoList?.data?.countryCode + '('+ distRankInfoList?.data?.primaryCountryCode +')': ''}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><span className="downline_rank_info">{"Status"}</span></TableCell>
                            <TableCell className="updowntable-cell-data-right-alignment">{distRankInfoList?.data?.status}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><span className="downline_rank_info">{"Begin Rank"}</span></TableCell>
                            <Tooltip
                             title={distRankInfoList?.data?.beginRank?.id}
                                                placement="top"
                                                arrow>
                            <TableCell className="updowntable-cell-data-right-alignment">{getRankName(distRankInfoList?.data?.beginRank?.id, ranksData)}</TableCell>
                            </Tooltip>
                        </TableRow>
                        <TableRow>
                            <TableCell ><span className="downline_rank_info">{"Leg Paid Rank"}</span></TableCell>
                            <Tooltip
                             title={distRankInfoList?.data?.paidRank?.id}
                                                placement="top"
                                                arrow>
                            <TableCell className="updowntable-cell-data-right-alignment">{getRankName(distRankInfoList?.data?.paidRank?.id, ranksData)}</TableCell>
                            </Tooltip>
                        </TableRow>  
                        <TableRow>
                            <TableCell><span className="downline_rank_info">{"End Rank"}</span></TableCell>
                            <Tooltip
                             title={distRankInfoList?.data?.endRank?.id}
                                                placement="top"
                                                arrow>
                            <TableCell className="updowntable-cell-data-right-alignment">{getRankName(distRankInfoList?.data?.endRank?.id, ranksData)}</TableCell>
                            </Tooltip>
                        </TableRow>
                        {(isNullResponse(distVolumeList?.data)&&distVolumeList?.data?.volumes?.length>0)? distVolumeList?.data?.volumes?.map((volData)=>(
                        <TableRow>
                            <TableCell><span className="downline_rank_info">{volData?.label}</span></TableCell>
                            <TableCell className="updowntable-cell-data-right-alignment">{(volData?.value)?.toFixed(2)}</TableCell>
                        </TableRow>)  
                        ):''}
                    </TableBody>
				</Table>:''}
        </>}
			</TableContainer>
    </div>
</div>);
}
}

export default DownlineTab;