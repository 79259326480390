import {
  PERIOD_TYPES_FETCHING,
  PERIOD_TYPES_SUCCESS,
  PERIOD_TYPES_ERROR
} from '../actions'

const initialState = {
   periodTypeData: {     
        "periodTypes": [
            {
                "title": "",
                "active": false ,
                "defaultPeriod": "",
                "lastCommissionPeriod": ""
            },
            {
                "title": "",
                "active": false,
                "defaultPeriod": "",
                "lastCommissionPeriod": ""
            },
            {
                "title": "",
                "active": false
            }
        ]
    }
}

export default function periodTypesReducer (state, action) {
  if (typeof state === 'undefined') {
    return initialState
  }
  switch (action.type) {
    case PERIOD_TYPES_FETCHING:
      return { ...state, fetching: true }
    case PERIOD_TYPES_SUCCESS:
      return { ...state, fetching: false, success: true, periodTypeData: action.payload }
    case PERIOD_TYPES_ERROR:
      return {
        ...state,
        fetching: false,
        success: false,
        error: action.payload
      }
    default:
      return state
  }
}
