import React from "react";
import "./Group2457.css";
import vector2x from '../../../assets/vector@2x.png';

class Group2457 extends React.Component {
  render() {
    const { text12 } = this.props;

    return (
      <div className="group-2457">
        <img
          className="vector-12"
          src={vector2x}
        />
        <div className="text-2 valign-text-middle asap-normal-mine-shaft-15px">{text12}</div>
      </div>
    );
  }
}

export default Group2457;
