
import React from "react";
import AuthService from "../../../../src/components/server/AuthService";
import { connect } from "react-redux";
import { Oval } from "react-loader-spinner";
import { withRouter } from "react-router-dom";
import { getDistributorFlags } from "../../../components/Commissions/actions";
import actions from "../../../redux/actions/CommonDataActions";
import * as Constants from '../../../constants/Constants';
import { errorHandling } from '../../utilities/Services';
import NewSnackbar from "../../utilities/NewSnackbar";
import './GeneralTab.css';
import './historyTab.css';
import './FlagsTab.css'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { allNullTypeCheck, isNullResponse } from "../commonComponents/UtilityFun";

class FlagsTab extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showLoaderResult: false,
            flagCategories: [],
            flagsData: [],
            tempFlags: null,
            selectedCategory: null,
            categoryFlags: null,
            expanded: false,
            flagsWaitTime: 1
        }
    }
    componentDidMount() {
        const { distID, selectedRunID, distRankInfo } = this.props;
        // console.log("History tab input props ", distID, selectedRunID);
        if (allNullTypeCheck(distRankInfo) && allNullTypeCheck(distID) && allNullTypeCheck(selectedRunID)) {
            this.setState({ showLoaderResult: true });
            this.props.getDistFlagDetails(selectedRunID, distID);
        }
    }
    componentDidUpdate(nextProps) {
        const {
            distFlagsData,
            distFlagsError,
            distID, 
            selectedRunID
        } = this.props;
        let { flagCategories, selectedCategory, categoryFlags, flagsWaitTime } = this.state
        if (distFlagsData !== nextProps.distFlagsData){
            this.setState({ showLoaderResult: false });
         if(!distFlagsData.hasOwnProperty('status')){
            if(isNullResponse(distFlagsData.data)) {
                if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Get Dist Flags ", distFlagsData) }
                if (distFlagsData.error === false) {
                    this.setState({ showLoaderResult: false });
                    if( distFlagsData.data?.distributorFlags.length > 0){
                        this.setState({ flagsData: distFlagsData.data.distributorFlags });
                        // console.log('distFlagsData Resposne', distFlagsData.data)
                        //this.setState({ tempFlags: distFlagsData.data.flags});
                        flagCategories = [...new Set(distFlagsData.data.distributorFlags.map(x => x.category))];  // Get distinct categories
                        selectedCategory = flagCategories[0];
                        categoryFlags = this.getCategoryFlags(flagCategories[0]);
                        this.setState({ flagCategories: flagCategories, categoryFlags: categoryFlags, selectedCategory: selectedCategory });
                        console.log('flagCategories', categoryFlags, selectedCategory)
                    }
                    
                }
                else {
                    this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.error, snackbarMessage: distFlagsData.messages[0] })
                }
            } else if (Object.keys(distFlagsData?.data).length === 0 && allNullTypeCheck(distFlagsData?.messages)) {
                if (distFlagsData?.messages?.length > 0 && distFlagsData?.messages[0]?.includes("Try again...")) {
                    console.log("Try again block entered : ", distFlagsData?.data);
                    this.setState({ showLoaderResult: true });
                    setTimeout(() => {
                        this.props.getDistFlagDetails(selectedRunID, distID);;
                    }, 1000 * flagsWaitTime);
                    this.setState(prevState => {
                        return { flagsWaitTime: prevState.flagsWaitTime * 2 }
                    })
                }
            }
             else {
                this.props.showSnackbar({ snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.error, snackbarMessage: distFlagsData.messages[0] })
            }
        }else {
            if(distFlagsData.hasOwnProperty('status') && distFlagsData.status===202){
                this.setState({ showLoaderResult: true });
                setTimeout(() => {
                    this.props.getDistFlagDetails(selectedRunID, distID);;
                }, 1000 * flagsWaitTime);
                this.setState(prevState => {
                    return { flagsWaitTime: prevState.flagsWaitTime * 2 }
                })
           }
          }
        } else if ((distFlagsError !== nextProps.distFlagsError)) {
            this.setState({ showLoaderResult: false });
            if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("distFlagsError ", distFlagsError) }
            errorHandling(distFlagsError);
        }
    }
    getCategoryFlags(category) {
        let tmpFlags = [];
        //  console.log('flagsData', this.state.flagsData)
        this.props.distFlagsData.data.distributorFlags.forEach(flag => {
            if (flag.category == category) {
                tmpFlags.push(flag);
            }
            //   console.log('temp flags array', this.state.flagsData)
        })
        return this.splitIntoChunk(tmpFlags, 4)
    }
    // program to split array into smaller chunks

    splitIntoChunk(arr, chunk) {
        let chunked = []
        while (arr.length > 0) {

            let tempArray;
            tempArray = arr?.splice(0, chunk);
            //   console.log('temp array',tempArray);
            chunked.push(tempArray)
        }
        //  console.log('chunk data',chunked);
        //   console.log('chunk length',chunked.length);
        return chunked;
    }
    onSelectedCategory(category) {
        let selectedonecategory = this.getCategoryFlags(category)
        this.setState({ selectedCategory: category, categoryFlags: selectedonecategory })
        console.log('selectedCategory=' + selectedonecategory)
    }
    handleAccordianChange = (panel) => (event, isExpanded) => {
        this.setState({ expanded: isExpanded ? panel : false, selectedCategory: panel })
        let tempFlags = this.getCategoryFlags(panel)
        this.setState({ categoryFlags: tempFlags })
    };
    render() {
        if (AuthService.isAuthenticated()) {
            let loader;
            const { showLoaderResult, categoryFlags, flagCategories, selectedCategory } = this.state;
            console.log('flags', flagCategories)
            if (showLoaderResult) {
                loader = <div id="center" style={{ position: "fixed", bottom: "40%", left: "48%" }}>
                    <Oval
                        type="Puff"
                        position="fixed"  secondaryColor="#E85557"
                        color="#E85557"
                        height={50}
                        width={50}
                        timeout={3000}
                    />
                </div>
            }
            return (
                <div className="distres-generaltable distres-tabs-scroll">
                    <div className="col-12 according-align">
                    <div className="rectangle-2556"></div>
                        {flagCategories.length > 0
                            ? flagCategories.map(
                                (category) => (
                                    <Accordion ransitionProps={{ unmountOnExit: true }} expanded={selectedCategory === category} onChange={this.handleAccordianChange(category)}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>
                                                {selectedCategory === category ?
                                                    <span className="fw-bold flag-title-style" style={{ color: '#D93030' }}>{category} Flags</span> :
                                                    <span className="fw-bold text-dark flag-title-style" >{category} Flags</span>}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography onChange={this.handleAccordianChange(category)}>
                                                <table class="table">
                                                    {categoryFlags.map(
                                                        (cf, index) => (
                                                            <tr style={{height: '25px'}}>
                                                               {cf.length > 0 ? <td className="flags-data-text-style">
                                                                    <div className="badge flag-badge" style={{color: cf[0].value ? 'white': '', marginTop: '1px'}}>{cf[0].value ? cf[0].value : ' - '}</div>&nbsp;<i style={{padding: '6px'}}>{cf[0].label}</i>
                                                                </td> : '' }
                                                                {cf.length > 1 ? <td className="flags-data-text-style">
                                                                    <span className="badge flag-badge" style={{color: cf[1].value ? 'white': '', marginTop: '1px'}}>{cf[1].value ? cf[1].value : ' - '}</span>&nbsp;<i style={{padding: '6px'}}>{cf[1].label}</i>
                                                                </td> : '' }
                                                                {cf.length > 2 ? <td className="flags-data-text-style">
                                                                    <span className="badge flag-badge" style={{color: cf[2].value ? 'white': '', marginTop: '1px'}}>{cf[2].value ? cf[2].value : ' - '}</span>&nbsp;<i style={{padding: '6px'}}>{cf[2].label}</i>
                                                                </td> : ''}
                                                                {cf.length > 3 ? <td className="flags-data-text-style">
                                                                    <span className="badge flag-badge" style={{color: cf[3].value ? 'white': '', marginTop: '1px'}}>{cf[3].value ? cf[3].value : ' - '}</span>&nbsp;<i style={{padding: '6px'}}>{cf[3].label}</i>
                                                                </td> : ''}
                                                            </tr>
                                                        ))}
                                                </table>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                )) : ''}

                    </div>
                    {loader}
                    <NewSnackbar />
                </div>);

        }
        else {
            return <div id="center" style={{ position: "fixed", bottom: "40%", left: "48%" }}>
                <Oval
                    type="Puff"
                    position="fixed"  secondaryColor="#E85557"
                    color="#E85557"
                    height={50}
                    width={50}
                    timeout={3000}
                />
            </div>
        }
    }
}
const mapStateToProps = (state) => {
    return {
        distFlagsData: state.commissionDistResearch.distFlagsData,
        distFlagsError: state.commissionDistResearch.distFlagsError,

    };
};
const mapDispatchToProps = (dispatch) => ({
    getDistFlagDetails: (runID, distID) => dispatch(getDistributorFlags(runID, distID)),
    showSnackbar: (params) => dispatch({ type: actions.SNACKBAR_SHOW, payload: params }),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(FlagsTab));