import React from "react";
import './IntegrationDetails.css';
// import Switch from 'react-toggle-switch';
import Switch from '@mui/material/Switch';
// import 'react-toggle-switch/dist/css/switch.min.css';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { errorHandling, valueValidation } from '../../utilities/Services';
import actions from '../../../redux/actions/CommonDataActions';
import { putIntegrationAPI, getIntegrationAPI } from '../../../redux/thunk/integrationAPIThunk';
import { getCollaboratorsByIntegrationID } from '../../../redux/thunk/userManagementAPIThunk';
import AuthService from '../../server/AuthService';
import * as Constants from '../../../constants/Constants';
import jwt from 'jwt-decode';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import NewSnackbar from "../../utilities/NewSnackbar";

class IntegrationDeailts extends React.Component {
  constructor() {

    super()
    this.state = {
      integrationswitched: false,
      integrationName: 'Integrations',
      description: 'Integrations Summary',
      integrationDate: '',
      collaborators: [],
      integrationID: '',
      options: []
    }
  }
  componentDidMount() {
    // console.log('this.props.currentIntegrationRes',this.props.currentIntegrationRes)
    if (AuthService.isTokenValidCheck() === true) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("current integrationID : ", this.props.currentIntegrationID) }
      // this.props.callGetIntegrationAPI(this.props.currentIntegrationID);
      // console.log('this.props.currentIntegrationRes', this.props.currentIntegrationRes)
       this.props.callGetIntegrationAPI(this.props.tempIntegrationID);
       this.props.getCollaboratorsList(this.props.tempIntegrationID)
    }
  }
  integrationtoggleSwitch() {
    this.setState(prevState => {
      console.log('switch state', prevState)
      return {
        integrationswitched: !prevState.integrationswitched
      };
    });
    console.log('switch state', this.state.integrationswitched)
    localStorage.setItem("switchstate",this.state.integrationswitched)
    const id_token = localStorage.getItem('id_token');
    if (id_token !== "undefined" && valueValidation(id_token)) {
      var user = jwt(id_token).sub.toUpperCase();
    }
    let generalParams = {};
    generalParams[Constants.PARAM_INTEGRATIONID] = this.state.integrationID;
    generalParams[Constants.PARAM_NAME] = this.state.integrationName;
    generalParams[Constants.PARAM_DESCRIPTION] = this.state.description;
    generalParams[Constants.PARAM_COLLABORATORS] = this.state.options.filter(item => item !== user).toString();
    generalParams[Constants.PARAM_TYPE] = 'general';
    generalParams[Constants.PARAM_STATUS] = this.state.integrationswitched ? 1 : 0;
    if (AuthService.isTokenValidCheck() === true) {
    this.props.callIntegrationStatus(this.state.integrationID, generalParams);
    }
  }
  componentDidUpdate(nextProps) {
    //console.log('integrationStatusResponse nextpros',this.props.integrationStatusResponse)
   // console.log('this.state.collaborators', this.state.collaborators)
   if (this.props.getIntegrationResponse !== nextProps.getIntegrationResponse) {
    if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log("Get integration ", this.props.getIntegrationResponse)}
    if (this.props.getIntegrationResponse.error===false) {  
      if(this.props.getIntegrationResponse.data && this.props.getIntegrationResponse.data.length > 0){
        console.log("get integration Details", this.props.getIntegrationResponse.data[0]); 
        this.setState({
          integrationID: this.props.getIntegrationResponse.data[0].integrationID,
          description: this.props.getIntegrationResponse.data[0].description,
          integrationName: this.props.getIntegrationResponse.data[0].name,
          integrationDate: this.props.getIntegrationResponse.data[0].createdDate,
        });
        if (this.props.getIntegrationResponse.data[0].active === 0) {
          this.setState({ integrationswitched: true })
        } else {
          this.setState({ integrationswitched: false })
        }
      }   
    }
    this.setState({showLoaderResult:false});
  } else if ((this.props.getIntegrationError !== nextProps.getIntegrationError)) {    
    if(process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')){console.log("Get integration error ", this.props.getIntegrationError)}
    this.setState({showLoaderResult:false});  
    errorHandling(this.props.getIntegrationError);
  }
    if (this.props.integrationStatusResponse !== nextProps.integrationStatusResponse) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Get integration ", this.props.getIntegrationResponse) }
      if (this.props.integrationStatusResponse.error === false) {
        console.log('integrationStatusResponse nextpros', this.props.integrationStatusResponse)
      }
    } else if ((this.props.getIntegrationError !== nextProps.getIntegrationError)) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("Get integration error ", this.props.getIntegrationError) }
      errorHandling(this.props.getIntegrationError);
    }
    if (this.props.collaboratorsDetails !== nextProps.collaboratorsDetails) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log('NewGeneric collaboratorsDetails', this.props.collaboratorsDetails.data); }
      if ((this.props.collaboratorsDetails.error === false) && (this.props.collaboratorsDetails.data.length > 0)) {
        let duplicateResult = false;
       // let listobj = []
        this.props.collaboratorsDetails.data.forEach((users, i) => {
          duplicateResult = false;
          this.props.collaboratorsDetails.data.forEach((user, j) => {
            if ((i !== j) && (this.props.collaboratorsDetails.data[i].userID === this.props.collaboratorsDetails.data[j].userID)) {
              console.log('duplicate users : ', user.name);
              duplicateResult = true;
              return false;
            }
          });
          if (!duplicateResult) {
            let data = { userID: users.userID, userName: users.name, userRole: users.roleName, userEmail: users.userName };
            //  this.state.options.push(data);
           // console.log('this.props.currentIntegrationRes.createdBy', this.props.currentIntegrationRes.createdBy)
            if (this.props.currentIntegrationRes.createdBy !== undefined) {
              this.state.collaborators.map((item) => {
                if (item === data.userID) {
                  this.state.options.push(data.userName)
               //   console.log('names', this.state.options)
                }
               return this.state.options
              })
            }
          }
        });
        console.log('collaboratrs options', this.state.options)
        this.setState({collaborators : this.state.options})
      } else if (this.props.collaboratorsDetails.error) {
        this.props.showSnackbar({snackbarOpen: true, snackbarType: Constants.SNACKBAR_TYPE.warning, snackbarMessage: Constants.ALERT_TECHNICAL_ERROR})
      }
      //this.props.hideLoader();
    } else if (this.props.collaboratorsHasError !== nextProps.collaboratorsHasError) {
      // this.props.hideLoader();           
      errorHandling(this.props.collaboratorsHasError);
    }
    if (this.props.getCollaboratorsDetails !== nextProps.getCollaboratorsDetails) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("getCollaboratorsDetails ", this.props.getCollaboratorsDetails) }
      if (this.props.getCollaboratorsDetails.error === false) {
       // console.log('getCollaboratorsDetails', this.props.getCollaboratorsDetails)
        if(this.props.getCollaboratorsDetails.data && this.props.getCollaboratorsDetails.data.length > 0){
          this.setState({collaborators : this.props.getCollaboratorsDetails.data})
        }
      }
    } else if ((this.props.getCollaboratorsHasError !== nextProps.getCollaboratorsHasError)) {
      if (process.env.REACT_APP_IDENTITY_REDIRECT_URL.includes('localhost')) { console.log("getCollaboratorsDetails error ", this.props.getCollaboratorsHasError) }
      errorHandling(this.props.getCollaboratorsHasError);
    }
  }
  nameProfileText = (str) => {
    let initials = ''
    if(str.split(" ").length > 1){
      const fullName = str.split(' ');
      initials =  fullName.shift().charAt(0) + fullName.pop().charAt(0);
    }
    else{
      const fullName = str.split(' ');
      initials =  fullName.shift().charAt(0)
    }
    return initials.toUpperCase();
  }

  render() {
    // const { integrationshow } = this.state;
    let dateFormat = ''
   // console.log('this.state.integrationDate',this.state.integrationDate)
    if(this.state.integrationDate !== null &&  this.state.integrationDate !== ''){
      console.log('this.state.integrationDate',this.props.getIntegrationResponse.data)
      let date = this.props.getIntegrationResponse.data[0].createdDate.split(" ")
     dateFormat = (date[0]+ " "+ date[1] + " '" + date[2].substring(2)).replace(",", " ")
    }
    return (
      <div className="group-2743" style={{ marginTop: '25px' }}>
        <div className="overlap-group-id border-1px-seashell">
          <div className="flex-col-id">
            <div className="newintegration-id valign-text-middle lato-bold-mine-shaft-18px">
              <h2>{this.state.integrationName} </h2>
            </div>
            <p className="text-1-id lato-normal-granite-gray-12px">
              Created on {dateFormat} by You
            </p>
            <p className="text-2-id lato-normal-mine-shaft-14px">
              {this.state.description}
            </p>
          </div>
          <div className="flex-col-1-id">
            <div className="group-2628">
              {this.state.integrationswitched ? <div className="enable"> Disable </div> : <div className="enable">Enable </div>}
              <div className="group-42-style">
                <div className="overlap-group1" style={{ cursor: 'pointer'}}>
                  {/* <Col sm={5} style={{flex:'1'}} className="newGeneral-inputedit"> */}
                  {/* <Switch onClick={this.integrationtoggleSwitch.bind(this)} on={!this.state.integrationswitched} >
                    <i className="some-icon" />
                  </Switch> */}
                  <Switch onClick={this.integrationtoggleSwitch.bind(this)}
                     id="waiver-edit-screen-switch" checked={!this.state.integrationswitched}>
                                  <i className="some-icon" />
                  </Switch>                                  
                </div>
              </div>
            </div>
            <div className="group-2631">
              {this.state.collaborators.length > 0 ?
                <AvatarGroup max={3} className="overlap-group1-1" style={{marginLeft: '15px'}}>
                  {this.state.collaborators.map((item) => {
                    let name = ''
                    console.log('item',item)
                    if(item.name !== null && item.name !== ''){
                      name = this.nameProfileText(item.name)
                    }
                    // return <Avatar alt={name} src="/static/images/avatar/1.jpg" />
                    return <Avatar src="/static/images/avatar/1.jpg">{name} </Avatar>
                  })}
                </AvatarGroup> :
                <div className="overlap-group1-1" style={{width: '110px'}}>
                 <span> </span>
                </div>}
            </div>
          </div>
        </div>
        <NewSnackbar/>
      </div>
    );
  }
}
IntegrationDeailts.propTypes = {
  params: PropTypes.any,
  name: PropTypes.string,
  description: PropTypes.string,
  collaborators: PropTypes.any,
  integrationdate: PropTypes.string,
  integrationid: PropTypes.string,
  pagetype: PropTypes.string
}
const mapStateToProps = state => {
  return {
    currentIntegrationID: state.integrationDataReducer.currentIntegrationID,
    currentIntegrationRes: state.integrationDataReducer.currentIntegrationRes,
    getIntegrationResponse: state.integrationDataReducer.getIntegrationResponse,
    getIntegrationError: state.integrationDataReducer.getIntegrationError,
    integrationStatusResponse: state.integrationDataReducer.integrationStatusResponse,
    integrationStatusError: state.integrationDataReducer.integrationStatusError,
    collaboratorsDetails: state.userDataReducer.collaboratorsDetails,
    collaboratorsHasError: state.userDataReducer.collaboratorsHasError,
    getCollaboratorsDetails: state.userDataReducer.getCollaboratorsDetails,
    getCollaboratorsHasError: state.userDataReducer.getCollaboratorsHasError,
  };
};
const mapDispatchToProps = dispatch => ({
  callIntegrationStatus: (integrationID, params) => dispatch(putIntegrationAPI(integrationID, params)),
  callGetIntegrationAPI: (integrationID) => dispatch(getIntegrationAPI(integrationID)),
  //getCollaboratorsList: () => dispatch(getCollaboratorsListapi()),
  getCollaboratorsList: (integrationID) => dispatch(getCollaboratorsByIntegrationID(integrationID)), 
  showSnackbar: (params) => dispatch({ type: actions.SNACKBAR_SHOW, payload: params}),
});
export default connect(mapStateToProps, mapDispatchToProps)(IntegrationDeailts);

class Group2185 extends React.Component {
  render() {
    const { children } = this.props;

    return (
      <div className="group-2185">
        <div className="overlap-group2-id border-2px-curious-blue">
          <div className="text-3-id lato-medium-curious-blue-14px">{children}</div>
        </div>
      </div>
    );
  }
}

class Group2629 extends React.Component {
  render() {
    const { children } = this.props;

    return (
      <div className="group-2629">
        <div className="overlap-group4-id border-1px-white">
          <div className="st lato-medium-white-14px">{children}</div>
        </div>
      </div>
    );
  }
}
